import { ApiResponse } from 'apisauce';
import {
    IContest,
    IContestResponse,
    IGetContestDetailsSuccess,
    IGetContestResponsesResponse,
    IGetContestsListResponse,
    IGetContestListApiParam,
} from 'entities/contests';
import { GatewayResponse } from './types/types';

import {
    IContestsGateway,
    GetContestsParams,
    CreateContestParams,
    DeleteContestParams,
    GetContestDetailsParams,
    EditContestDetailsParams,
    GetContestResponsesParams,
    GetExportResponsesParams,
} from './ContestsBase';

const getHeaders = (authToken: string) => ({
    headers: {
        Authorization: `Bearer ${authToken}`,
    },
});

export default class ContestsGateway extends IContestsGateway {
    async getContests(params: GetContestsParams): GatewayResponse<IContest[]> {
        const { type, status } = params;
        const response: ApiResponse<IContest[]> = await this.api.get('/v1/contests', { type, status }, getHeaders(params.authToken));
        return this.process(response);
    }

    async createContest(params: CreateContestParams): GatewayResponse<IContest[]> {
        const response: ApiResponse<IContest[]> = await this.api.post('/v1/contests', params.data, getHeaders(params.authToken));
        return this.process(response);
    }

    async deleteContest(params: DeleteContestParams): GatewayResponse<string> {
        const response: ApiResponse<string> = await this.api.delete(`/v1/contests/${params._id}`, {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async getContestDetails(params: GetContestDetailsParams): GatewayResponse<IGetContestDetailsSuccess> {
        const { id, type } = params;

        const response: ApiResponse<IGetContestDetailsSuccess> = await this.api.get(`/v1/contests/${id}`, { type }, getHeaders(params.authToken));
        return this.process(response);
    }

    async editContestDetails(params: EditContestDetailsParams): GatewayResponse<null> {
        const response: ApiResponse<null> = await this.api.put('/v1/contests', params.data, getHeaders(params.authToken));
        return this.process(response);
    }

    async getContestDetailsResponses(params: GetContestResponsesParams): GatewayResponse<IGetContestResponsesResponse> {
        const { contestId, index, type } = params;
        const response: ApiResponse<IGetContestResponsesResponse> = await this.api.get('/v1/contests/responses', { contestId, index, type }, getHeaders(params.authToken));
        return this.process(response);
    }

    async getExportResponses(params: GetExportResponsesParams): GatewayResponse<IContestResponse[]> {
        const response: ApiResponse<IContestResponse[]> = await this.api.get(`v1/contests/exportResponses?contestId=${params.contestId}&type=${params.type}`, {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async getQuizList(param: IGetContestListApiParam): GatewayResponse<IGetContestsListResponse> {
        const { authToken, ...getContestListParams } = param;
        const response: ApiResponse<IGetContestsListResponse> = await this.api.get('v1/contests', getContestListParams, getHeaders(authToken));
        return this.process(response);
    }

    async getGamesList(param: IGetContestListApiParam): GatewayResponse<IGetContestsListResponse> {
        const { authToken, ...getContestListParams } = param;
        const response: ApiResponse<IGetContestsListResponse> = await this.api.get('v1/games', getContestListParams, getHeaders(authToken));
        return this.process(response);
    }
}
