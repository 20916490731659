import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';
import { IPNCMessageDetails, ISetPNCMessage } from 'entities/PNCMessage';

export interface GetPNCMessageParams {
    authToken: string;
}

export interface SetPNCMessageParams {
    authToken: string;
    title: string;
    message: string;
    file?: File;
    clearImage: string;
}

export abstract class IPNCMessageGateway extends Gateway {
    abstract getPNCMessage(params: GetPNCMessageParams): GatewayResponse<IPNCMessageDetails>;

    abstract setPNCMessage(params: SetPNCMessageParams): GatewayResponse<null>;
}
