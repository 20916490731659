import React, { FunctionComponent } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import IQOSModal from 'components/IQOSModal';
import Button from 'components/Button';
import Flex from 'components/Flex';

import Colors from 'assets/themes/Colors';

interface DeleteFaqCardModalProps {
    header?: string;
    isOpen: boolean;
    toggle: () => void;
    onConfirmed: () => void;
}

const DeleteFaqCardModal: FunctionComponent<DeleteFaqCardModalProps> = (props: DeleteFaqCardModalProps) => {
    const { header, isOpen, toggle, onConfirmed } = props;

    const deleteHandler = () => {
        onConfirmed();
        toggle();
    };

    return (
        <IQOSModal isOpen={isOpen} toggle={toggle} header={header || 'Confirm to delete this card?'}>
            <Flex gap='.5rem'>
                <StyledButton onClick={deleteHandler} css={styles.deleteButton}>Delete</StyledButton>
                <StyledButton onClick={toggle} css={styles.cancelButton}>Cancel</StyledButton>
            </Flex>
        </IQOSModal>
    );
};

DeleteFaqCardModal.defaultProps = {
    header: '',
};

interface StyledCSSProps {
    css?: FlattenSimpleInterpolation;
}

const StyledButton = styled.button<StyledCSSProps>`
    padding: 0.3rem 1.5rem;
    border-radius:20px;
    border-style:solid;
    ${props => props.css}
`;

const styles = {
    cancelButton: css`
        background-color: ${Colors.white};
        border: 1px solid rgba(0,0,0,0.5);

        &:hover {
            opacity: 0.7;
        }
    `,
    deleteButton: css`
        background-color: ${Colors.iqosPrimary};
        border: 1px solid ${Colors.iqosPrimary};
        color: ${Colors.white};

        &:hover {
            background-color: ${Colors.white};
            color: ${Colors.iqosPrimary};
        }

        &:disabled{
            background-color: ${Colors.lightGrey};
            border-color: ${Colors.lightGrey};
            cursor: not-allowed;

            &:hover {
                color: ${Colors.white};
            }
        }
    `,
};

export default DeleteFaqCardModal;
