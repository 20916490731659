import { push, replace, goBack } from 'redux-first-history';

import { store } from 'redux/store';

const navPush = (routeName: string) => {
    store.dispatch(push(routeName));
};

const navReplace = (routeName: string) => {
    store.dispatch(replace(routeName));
};

const navBack = (): void => {
    store.dispatch(goBack());
};

const navResetToLogin = (): void => navReplace('/login');

const navToHome = (): void => navPush('/faq');
const navToAgeBot = (): void => navPush('/agebot');
const navToAnalytics = (): void => navPush('/analytics');
const navToContests = (): void => navPush('/contests');

const navToNewContestsScreen = (): void => navPush('/contests');
const navToContestsQuiz = (): void => navPush('/contests/quiz');
const navToContestQuizDetail = (id: string):void => navPush(`/contests/quiz/${id}`);

const navToContestsGame = (): void => navPush('/contests/game');
const navToContestGameDetail = (id: string):void => navPush(`/contests/game/${id}`);

const navToPncMessage = (): void => navPush('/pncmessage');
const navToBroadcast = (): void => navPush('/broadcast');

const navToECommerce = (): void => navPush('/ecommerce');
const navToEcommerceAnalyticsClicksReport = (): void => navPush('/ecommerceAnalytics/clicks');
const navToEcommerceAnalyticsClicksProductReport = (id: string): void => navPush(`/ecommerceAnalytics/productClicks/${id}`);
const navToEcommerceAnalyticsBuyNowReport = (): void => navPush('/ecommerceAnalytics/buyNowClicks');
const navToEcommerceAnalyticsBuyNowProductReport = (id: string): void => navPush(`/ecommerceAnalytics/buyNowProductClicks/${id}`);
const navToEcommerceAnalyticsActivitiesReport = (): void => navPush('/ecommerceAnalytics/activities');
const navToEcommerceAnalyticsFunnelReport = (): void => navPush('/ecommerceAnalytics/funnel');

const navToProductList = (categoryId: string): void => navPush(`/ecommerce/${categoryId}`);
const navToProductDetails = (id1: string, id2: string): void => navPush(`/ecommerce/${id1}/${id2}`);
const navToContestDetail = (id: string): void => navPush(`/contest/${id}`);
const navToGameDetails = (id: string): void => navPush(`/game/${id}`);
const navToSummaryDashboard = (): void => navPush('/analytics/summaryDashboard');
const navToFollowersScreen = (): void => navPush('/analytics/followers');
const navToFullFollowersScreen = (): void => navPush('/analytics/followers/fullFollowersList');
const navToAnalyticsPosts = (): void => navPush('/analytics/posts');

export default {
    navBack,

    navResetToLogin,
    navToHome,
    navToAgeBot,
    navToAnalytics,
    navToContests,
    navToBroadcast,
    navToPncMessage,

    navToECommerce,
    navToEcommerceAnalyticsClicksReport,
    navToEcommerceAnalyticsClicksProductReport,
    navToEcommerceAnalyticsBuyNowReport,
    navToEcommerceAnalyticsBuyNowProductReport,
    navToEcommerceAnalyticsActivitiesReport,
    navToEcommerceAnalyticsFunnelReport,

    navToProductList,
    navToProductDetails,
    navToContestDetail,
    navToGameDetails,
    navToSummaryDashboard,
    navToFollowersScreen,
    navToFullFollowersScreen,

    navToNewContestsScreen,
    navToContestsQuiz,
    navToContestQuizDetail,
    navToContestsGame,
    navToContestGameDetail,

    navToAnalyticsPosts,
};
