import React, { FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import SVG from 'react-inlinesvg';
import { format } from 'date-fns';

import Fonts from 'assets/themes/Fonts';
import Icons from 'assets/icons/Index';
import Text from 'components/Text';

interface MonthPickerProps {
    month: number;
    year: number;
    setMonth(month: number): void;
    setYear(year: number): void;
    minWidth?: number;
    isYtd?: boolean;
}

const MonthPicker: FunctionComponent<MonthPickerProps> = ({
    month,
    year,
    setMonth,
    setYear,
    minWidth,
    isYtd,
    ...props
}: MonthPickerProps) => {
    const dayjsMonthValue = dayjs().month(month - 1).toDate();
    const dayjsToFNSdate = format(dayjsMonthValue, 'MMM');

    const MIN_YEAR_IN_YEAR_PICKER = 2021;
    const CURRENT_YEAR = (new Date().getFullYear());
    const CURRENT_MONTH = (new Date().getMonth() + 1);

    const prevMonth = () => {
        if (month > 1) {
            setMonth(month - 1);
        } else if (month < 2 && year > MIN_YEAR_IN_YEAR_PICKER) {
            setMonth(12);
            setYear(year - 1);
        }
    };

    const nextMonth = () => {
        if (month < 12 && year < CURRENT_YEAR) {
            setMonth(month + 1);
        } else if (month > 11 && year < CURRENT_YEAR) {
            setMonth(1);
            setYear(year + 1);
        } else if (month < 12 && month < CURRENT_MONTH && year === CURRENT_YEAR) {
            setMonth(month + 1);
        }
    };

    return (
        <MonthWrapper minWidth={minWidth?.toString()}>
            <DateWrapper>
                <StyledButton onClick={() => (isYtd === false && prevMonth())} isYtd={isYtd}>
                    <SVG
                        style={{
                            fill: `${isYtd === true ? 'grey' : 'black'}`,
                            width: '13px',
                            strokeWidth: '0.1px',
                            transform: 'rotate(270deg)',
                            cursor: `${isYtd === true ? 'not-allowed' : 'pointer'}`,
                        }}
                        src={Icons.ChoosingArrow}
                        id='editIcon'
                    />
                </StyledButton>
                <StyledText style={{ fontSize: '18px', fontFamily: `${Fonts.secondary}`, minWidth: '100px', textAlign: 'center', paddingTop: '6px', color: `${isYtd === true ? 'grey' : 'black'}` }}>
                    {/* {`${dayjs(month.toString()).format('MMM')} ${dayjs(year.toString()).format('YYYY')}`} */}
                    {`${dayjsToFNSdate} ${dayjs(year.toString()).format('YYYY')}`}

                </StyledText>
                <StyledButton onClick={() => (isYtd === false && nextMonth())} isYtd={isYtd}>
                    <SVG
                        style={{
                            fill: `${isYtd === true ? 'grey' : 'black'}`,
                            width: '13px',
                            strokeWidth: '0.1px',
                            transform: 'rotate(90deg)',
                            cursor: `${isYtd === true ? 'not-allowed' : 'pointer'}`,
                        }}
                        src={Icons.ChoosingArrow}
                        id='editIcon'
                    />
                </StyledButton>
            </DateWrapper>
        </MonthWrapper>
    );
};

const MonthWrapper = styled.div<{minWidth?: string}>`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    min-width: ${props => (props.minWidth !== undefined ? `${props.minWidth}px` : '600px')};
`;

const DateWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-item: center;

    width: 260px;

    gap: 30px;
`;

const StyledButton = styled.button<{isYtd?: boolean}>`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F1F6F8;

    border-radius: 5px;
    border: ${props => ((props.isYtd === true) ? '1px solid grey' : '1px solid black')};
    cursor: ${props => ((props.isYtd === true) ? 'not-allowed !important' : 'pointer !important')};

    width: 40px;
    height: 40px;
`;

const StyledText = styled(Text)`
    text-align: left;
    font-size: 18px;
    font-family: ${Fonts.secondary}
    min-width: 100px;
    text-align: center;
    padding-top: 6px;
`;

MonthPicker.defaultProps = {
    isYtd: false,
};

export default MonthPicker;
