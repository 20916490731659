import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    LanguageEnum,
    ProductCategoryDetails,
    ProductListDetails,
    ProductListingMessageDetails,
    PromotionalMessageDetails,
    StatusTypeEnum,
    WelcomeMessageDetails,
    CTAButtonDetails,
} from 'entities/ecommerce';
import {
    CreateProductCategoryParams,
    CreateProductListParams,
    CreateProductVariantParams,
    EditProductVariantParams,
    DeleteProductVariantParams,
    DeleteUsingIdParams,
    ECommerceReduxState,
    EditProductCategoryParams,
    EditProductDetailsParams,
    GetProductListParams,
    SetPromotionalMessageParams,
    SetWelcomeMessageParams,
    SetProductListingMessageParams,
    SetCTAButtonDetailsParams,
} from './types';

const initialState: ECommerceReduxState = {
    actions: {
        getPromotionalMessage: false,
        setPromotionalMessage: false,
        getWelcomeMessage: false,
        setWelcomeMessage: false,
        getProductListingMessage: false,
        setProductListingMessage: false,
        getCategory: false,
        createCategory: false,
        editCategory: false,
        deleteCategory: false,
        getProduct: false,
        createProduct: false,
        deleteProduct: false,
        getProductDetails: false,
        editProductDetails: false,
        createProductVariant: false,
        editProductVariant: false,
        deleteProductVariant: false,
        getCTAButtonDetails: false,
        setCTAButtonDetails: false,
    },
    productCategoryData: [
        {
            _id: '',
            categoryName: '',
            createdAt: '',
            status: StatusTypeEnum.All,
        },
    ],
    productListData: [],
    productDetailsData: [],
    promotionalMessage: [
        {
            title: '',
            message: '',
            file: undefined,
            mediaUrl: '',
            language: LanguageEnum.EN,
        },
        {
            title: '',
            message: '',
            file: undefined,
            mediaUrl: '',
            language: LanguageEnum.BM,
        },
    ],
    welcomeMessage: [
        {
            title: '',
            message: '',
            language: LanguageEnum.EN,
        },
        {
            title: '',
            message: '',
            language: LanguageEnum.BM,
        },
    ],
    productListingMessage: [
        {
            title: '',
            message: '',
            language: LanguageEnum.EN,
        },
        {
            title: '',
            message: '',
            language: LanguageEnum.BM,
        },
    ],
    CTAButtonDetails: [
        {
            title: '',
            message: '',
            file: undefined,
            mediaUrl: '',
            buttonText: '',
            link: '',
            language: LanguageEnum.EN,
        },
        {
            title: '',
            message: '',
            file: undefined,
            mediaUrl: '',
            buttonText: '',
            link: '',
            language: LanguageEnum.BM,
        },
    ],
    productCategoryId: '',
    createCategoryModalIsOpen: false,
    createProductModalIsOpen: false,
    createVariantModalIsOpen: false,
    editVariantModalIsOpen: false,
    error: {
        getPromotionalMessage: '',
        setPromotionalMessage: '',
        getWelcomeMessage: '',
        setWelcomeMessage: '',
        getProductListingMessage: '',
        setProductListingMessage: '',
        getCategory: '',
        createCategory: '',
        editCategory: '',
        deleteCategory: '',
        getProduct: '',
        createProduct: '',
        deleteProduct: '',
        getProductDetails: '',
        editProductDetails: '',
        createProductVariant: '',
        editProductVariant: '',
        deleteProductVariant: '',
        getCTAButtonDetails: '',
        setCTAButtonDetails: '',
    },
};

const ECommerceSlice = createSlice({
    name: 'eCommerce',
    initialState,
    reducers: {
        // ? Promotional Message
        // ! get
        getPromotionalMessageAttempt: (state) => {
            state.actions.getPromotionalMessage = true;
            state.error.getPromotionalMessage = '';
        },
        getPromotionalMessageSuccess: (state, action: PayloadAction<PromotionalMessageDetails[]>) => {
            state.actions.getPromotionalMessage = false;
            state.error.getPromotionalMessage = '';

            if (action.payload) {
                state.promotionalMessage = action.payload;
            }
        },
        getPromotionalMessageFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getPromotionalMessage = false;

            if (action.payload) {
                state.error.getPromotionalMessage = action.payload;
            }
        },
        // ! set
        setPromotionalMessageAttempt: (state, _action: SetPromotionalMessageParams) => {
            state.actions.setPromotionalMessage = true;
            state.error.setPromotionalMessage = '';
        },
        setPromotionalMessageSuccess: (state) => {
            state.actions.setPromotionalMessage = false;
            state.error.setPromotionalMessage = '';
        },
        setPromotionalMessageFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setPromotionalMessage = false;

            if (action.payload) {
                state.error.setPromotionalMessage = action.payload;
            }
        },
        // ? Welcome Message
        // ! get
        getWelcomeMessageAttempt: (state) => {
            state.actions.getPromotionalMessage = true;
            state.error.getPromotionalMessage = '';
        },
        getWelcomeMessageSuccess: (state, action: PayloadAction<WelcomeMessageDetails[]>) => {
            state.actions.getWelcomeMessage = false;
            state.error.getWelcomeMessage = '';

            if (action.payload) {
                state.welcomeMessage = action.payload;
            }
        },
        getWelcomeMessageFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getWelcomeMessage = false;

            if (action.payload) {
                state.error.getWelcomeMessage = action.payload;
            }
        },
        // ! set
        setWelcomeMessageAttempt: (state, _action: SetWelcomeMessageParams) => {
            state.actions.setWelcomeMessage = true;
            state.error.setWelcomeMessage = '';
        },
        setWelcomeMessageSuccess: (state) => {
            state.actions.setWelcomeMessage = false;
            state.error.setWelcomeMessage = '';
        },
        setWelcomeMessageFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setWelcomeMessage = false;

            if (action.payload) {
                state.error.setWelcomeMessage = action.payload;
            }
        },
        // ? Product Listing Message
        // ! get
        getProductListingMessageAttempt: (state) => {
            state.actions.getPromotionalMessage = true;
            state.error.getPromotionalMessage = '';
        },
        getProductListingMessageSuccess: (state, action: PayloadAction<ProductListingMessageDetails[]>) => {
            state.actions.getProductListingMessage = false;
            state.error.getProductListingMessage = '';

            if (action.payload) {
                state.productListingMessage = action.payload;
            }
        },
        getProductListingMessageFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getProductListingMessage = false;

            if (action.payload) {
                state.error.getProductListingMessage = action.payload;
            }
        },
        // ! set
        setProductListingMessageAttempt: (state, _action: SetProductListingMessageParams) => {
            state.actions.setProductListingMessage = true;
            state.error.setProductListingMessage = '';
        },
        setProductListingMessageSuccess: (state) => {
            state.actions.setProductListingMessage = false;
            state.error.setProductListingMessage = '';
        },
        setProductListingMessageFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setProductListingMessage = false;

            if (action.payload) {
                state.error.setProductListingMessage = action.payload;
            }
        },
        // ? product Category list
        // ! get
        getProductCategoryAttempt: (state) => {
            state.actions.getCategory = true;
            state.error.getCategory = '';
        },
        getProductCategorySuccess: (state, action: PayloadAction<ProductCategoryDetails[]>) => {
            state.actions.getCategory = false;
            state.error.getCategory = '';

            if (action.payload) {
                state.productCategoryData = action.payload;
            }
        },
        getProductCategoryFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getCategory = false;

            if (action.payload) {
                state.error.getCategory = action.payload;
            }
        },
        // ! create
        createProductCategoryAttempt: (state, _action: CreateProductCategoryParams) => {
            state.actions.createCategory = true;
            state.error.createCategory = '';
        },
        createProductCategorySuccess: (state) => {
            state.actions.createCategory = false;
            state.error.createCategory = '';
        },
        createProductCategoryFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.createCategory = false;

            if (action.payload) {
                state.error.createCategory = action.payload;
            }
        },
        // ! edit
        editProductCategoryAttempt: (state, _action: EditProductCategoryParams) => {
            state.actions.editCategory = true;
            state.error.editCategory = '';
        },
        editProductCategorySuccess: (state) => {
            state.actions.editCategory = false;
            state.error.editCategory = '';
        },
        editProductCategoryFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.editCategory = false;

            if (action.payload) {
                state.error.editCategory = action.payload;
            }
        },
        // ! delete
        deleteProductCategoryAttempt: (state, _action: DeleteUsingIdParams) => {
            state.actions.deleteCategory = true;
            state.error.deleteCategory = '';
        },
        deleteProductCategorySuccess: (state) => {
            state.actions.deleteCategory = false;
            state.error.deleteCategory = '';
        },
        deleteProductCategoryFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.deleteCategory = false;

            if (action.payload) {
                state.error.deleteCategory = action.payload;
            }
        },
        // ? product list
        // ! get
        getProductListAttempt: (state, _action: GetProductListParams) => {
            state.actions.getProduct = true;
            state.error.getProduct = '';
        },
        getProductListSuccess: (state, action: PayloadAction<ProductListDetails[]>) => {
            state.actions.getProduct = false;
            state.error.getProduct = '';

            if (action.payload) {
                state.productListData = action.payload;
            }
        },
        getProductListFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getProduct = false;

            if (action.payload) {
                state.error.getProduct = action.payload;
            }
        },
        // ! create
        createProductListAttempt: (state, _action: CreateProductListParams) => {
            state.actions.createProduct = true;
            state.error.createProduct = '';
        },
        createProductListSuccess: (state) => {
            state.actions.createProduct = false;
            state.error.createProduct = '';
        },
        createProductListFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.createProduct = false;

            if (action.payload) {
                state.error.createProduct = action.payload;
            }
        },
        // ? Product Details
        // ! get
        getProductDetailsAttempt: (state, _action: PayloadAction<{ productId: string }>) => {
            state.actions.getProductDetails = true;
            state.error.getProductDetails = '';
        },
        getProductDetailsSuccess: (state, action: PayloadAction<ProductListDetails[]>) => {
            state.actions.getProductDetails = false;
            state.error.getProductDetails = '';

            if (action.payload) {
                state.productDetailsData = action.payload;
            }
        },
        getProductDetailsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getProductDetails = false;

            if (action.payload) {
                state.error.editProductDetails = action.payload;
            }
        },
        // ! edit
        editProductDetailsAttempt: (state, _action: EditProductDetailsParams) => {
            state.actions.editProductDetails = true;
            state.error.editProductDetails = '';
        },
        editProductDetailsSuccess: (state) => {
            state.actions.editProductDetails = false;
            state.error.editProductDetails = '';
        },
        editProductDetailsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.editProductDetails = false;

            if (action.payload) {
                state.error.editProductDetails = action.payload;
            }
        },
        // ! delete
        deleteProductDetailsAttempt: (state, _action: DeleteUsingIdParams) => {
            state.actions.deleteProduct = true;
            state.error.deleteProduct = '';
        },
        deleteProductDetailsSuccess: (state) => {
            state.actions.deleteProduct = false;
            state.error.deleteProduct = '';
        },
        deleteProductDetailsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.deleteProduct = false;

            if (action.payload) {
                state.error.deleteProduct = action.payload;
            }
        },
        // ? variant
        // ! create
        createProductVariantAttempt: (state, _action: CreateProductVariantParams) => {
            state.actions.createProductVariant = true;
            state.error.createProductVariant = '';
        },
        createProductVariantSuccess: (state) => {
            state.actions.createProductVariant = false;
            state.error.createProductVariant = '';
        },
        createProductVariantFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.createProductVariant = false;

            if (action.payload) {
                state.error.createProductVariant = action.payload;
            }
        },
        // ! edit
        editProductVariantAttempt: (state, _action: EditProductVariantParams) => {
            state.actions.editProductVariant = true;
            state.error.editProductVariant = '';
        },
        editProductVariantSuccess: (state) => {
            state.actions.editProductVariant = false;
            state.error.editProductVariant = '';
        },
        editProductVariantFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.editProductVariant = false;

            if (action.payload) {
                state.error.editProductVariant = action.payload;
            }
        },
        // ! delete
        deleteProductVariantAttempt: (state, _action: DeleteProductVariantParams) => {
            state.actions.deleteProductVariant = true;
            state.error.deleteProductVariant = '';
        },
        deleteProductVariantSuccess: (state) => {
            state.actions.deleteProductVariant = false;
            state.error.deleteProductVariant = '';
        },
        deleteProductVariantFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.deleteProductVariant = false;

            if (action.payload) {
                state.error.deleteProductVariant = action.payload;
            }
        },
        // ? category id to create Product
        setProductCategoryId: (state, action: PayloadAction<string>) => {
            state.productCategoryId = action.payload;
        },
        // ? Modal State
        setCreateCategoryModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.createCategoryModalIsOpen = action.payload;
        },
        setCreateProductModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.createProductModalIsOpen = action.payload;
        },
        setCreateVariantModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.createVariantModalIsOpen = action.payload;
        },
        setEditVariantModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.editVariantModalIsOpen = action.payload;
        },
        // ? Welcome Message
        // ! get
        getCTAButtonDetailsAttempt: (state) => {
            state.actions.getCTAButtonDetails = true;
            state.error.getCTAButtonDetails = '';
        },
        getCTAButtonDetailsSuccess: (state, action: PayloadAction<CTAButtonDetails[]>) => {
            state.actions.getCTAButtonDetails = false;
            state.error.getCTAButtonDetails = '';

            if (action.payload) {
                state.CTAButtonDetails = action.payload;
            }
        },
        getCTAButtonDetailsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getCTAButtonDetails = false;

            if (action.payload) {
                state.error.getCTAButtonDetails = action.payload;
            }
        },
        // ! set
        setCTAButtonDetailsAttempt: (state, _action: SetCTAButtonDetailsParams) => {
            state.actions.setCTAButtonDetails = true;
            state.error.setCTAButtonDetails = '';
        },
        setCTAButtonDetailsSuccess: (state) => {
            state.actions.setCTAButtonDetails = false;
            state.error.setCTAButtonDetails = '';
        },
        setCTAButtonDetailsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setCTAButtonDetails = false;

            if (action.payload) {
                state.error.setCTAButtonDetails = action.payload;
            }
        },
    },
});

export type ECommerceState = typeof initialState;

export default {
    actions: ECommerceSlice.actions,
    reducers: ECommerceSlice.reducer,
};
