import React, { FunctionComponent, useState } from 'react';
import styled, { css } from 'styled-components';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { connect } from 'react-redux';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { AppDispatch, RootState } from 'redux/store';

import Colors from 'assets/themes/Colors';
import Fonts from 'assets/themes/Fonts';

import { BroadcastHistoryFilter, BroadcastTypesEnum } from 'entities/broadcast';

import Title from 'components/Title';
import Button from 'components/Button';
import CreateBroadcastModal from 'components/broadcast/CreateBroadcastModal';
import MonthPicker from 'components/MonthPicker';
import Overview from './BroadcastOverview';
import RecurringPost from './RecurringPost';
import ScheduledPost from './ScheduledPost';
import HistoryPost from './HistoryPost';

interface BroadcastProps {
    setBroadcastType(data: BroadcastTypesEnum): void;
    setCreateBroadcastModalIsOpen(data: boolean): void;

    getCreateBroadcastModalIsOpen: boolean;
}

const Broadcast: FunctionComponent<BroadcastProps> = (props: BroadcastProps) => {
    const {
        setBroadcastType,
        setCreateBroadcastModalIsOpen,

        getCreateBroadcastModalIsOpen,
    } = props;

    dayjs.extend(utc);

    const [getMonth, setGetMonth] = useState<number>((dayjs().month()) + 1);
    const [getYear, setGetYear] = useState<number>(dayjs().year());

    const toggleCreateModal = (isBroadcast: boolean) => {
        if (isBroadcast) {
            setCreateBroadcastModalIsOpen(true);
        }
    };

    const broadcastHeader = () => {
        return (
            <HeaderWrapper>
                <Title label='Broadcast' css={styles.mainTitle} />

                <Button css={styles.createButton} primary onClick={() => [toggleCreateModal(true), setBroadcastType(1)]}>
                    Create New Post
                </Button>

                {getCreateBroadcastModalIsOpen && (
                    <CreateBroadcastModal />
                )}
            </HeaderWrapper>
        );
    };

    return (
        <MainWrapper>
            <div style={{ width: '100%' }}>
                {broadcastHeader()}

                <Overview />
                <RecurringPost />
                <ScheduledPost />
                <HistoryPost />
            </div>
        </MainWrapper>
    );
};

const MainWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: space-evenly;

    background-color: #F1F6F8;
    border-radius: 20px;

    padding-top: 1%;
    padding-left: 0.5%;
    padding-bottom: 1%;

    width: 71%;
    height: 100%;
    
    margin-bottom: 5%;
    margin-right: 7%;
`;

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 5%;
    align-items: center;
`;

const StyledText = styled.text`
    font-size: 20px;
    color: #27242E;
    font-family: ${Fonts.primary};
    font-weight: bold;
`;

const styles = {
    createButton: css`
        background-color: ${Colors.primary};
        color: white !important;
        font-size: 18px;
        font-weight: lighter;
        padding: 10px 30px;
        border-radius: 30px 30px 30px 30px;

        &:hover {
            background-color: ${Colors.secondary};
        }
    `,
    mainTitle: css`
        font-size: 100px;
    `,
};

const mapStateToProps = (state: RootState) => ({
    getBroadcastId: Selectors.getBroadcastBroadcastId(state),
    getExportBroadcastDetailsId: Selectors.getBroadcastExportBroadcastDetailsId(state),
    broadcastOverviewData: Selectors.getBroadcastGetBroadcastOverview(state),
    getCreateBroadcastModalIsOpen: Selectors.getBroadcastCreateBroadcastModalIsOpen(state),
    getEditRecurringBroadcastModalIsOpen: Selectors.getBroadcastEditRecurringBroadcastModalIsOpen(state),
    getEditScheduleBroadcastModalIsOpen: Selectors.getBroadcastEditScheduleBroadcastModalIsOpen(state),
    getDuplicateBroadcastModalIsOpen: Selectors.getBroadcastDuplicateBroadcastModalIsOpen(state),
    getRecallBroadcastMessageModalIsOpen: Selectors.getBroadcastRecallBroadcastMessageModalIsOpen(state),
    getBroadcastDetailsModalIsOpen: Selectors.getBroadcastBroadcastDetailsModalIsOpen(state),
    isHistoryExportLoading: Selectors.getBroadcastHistoryPostExportAttempting(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setBroadcastType: (data: BroadcastTypesEnum) => dispatch(Actions.setBroadcastType(data)),
    getBroadcastOverview: (date: string) => dispatch(Actions.getBroadcastOverviewAttempt({ date })),
    setCreateBroadcastModalIsOpen: (data: boolean) => dispatch(Actions.setCreateBroadcastModalIsOpen(data)),
    setEditRecurringBroadcastModalIsOpen: (data: boolean) => dispatch(Actions.setEditRecurringBroadcastModalIsOpen(data)),
    setEditScheduleBroadcastModalIsOpen: (data: boolean) => dispatch(Actions.setEditScheduleBroadcastModalIsOpen(data)),
    setDuplicateBroadcastModalIsOpen: (data: boolean) => dispatch(Actions.setDuplicateBroadcastModalIsOpen(data)),
    setRecallBroadcastMessageModalIsOpen: (data: boolean) => dispatch(Actions.setRecallBroadcastMessageModalIsOpen(data)),
    setBroadcastDetailsModalIsOpen: (data: boolean) => dispatch(Actions.setBroadcastDetailsModalIsOpen(data)),
    getHistoryPostExport: (type: BroadcastHistoryFilter) => dispatch(Actions.getHistoryPostExportAttempt({ type })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Broadcast);
