import authSlice from 'redux/slices/auth';
import faqSlice from 'redux/slices/faq';
import analyticsSlice from 'redux/slices/analytics';
import contestsSlice from 'redux/slices/contests';
import gamesSlice from 'redux/slices/games';
import broadcastSlice from 'redux/slices/broadcast';
import followersSlice from 'redux/slices/followers';
import postsSlice from 'redux/slices/posts';
import summaryDashboardSlice from 'redux/slices/summaryDashboard';
import pncMessageSlice from 'redux/slices/pncMessage';
import eCommerceSlice from 'redux/slices/eCommerce';
import eCommerceAnalyticsSlice from 'redux/slices/eCommerceAnalytics';

export default {
    ...analyticsSlice.actions,
    ...authSlice.actions,
    ...broadcastSlice.actions,
    ...contestsSlice.actions,
    ...faqSlice.actions,
    ...followersSlice.actions,
    ...gamesSlice.actions,
    ...broadcastSlice.actions,
    ...postsSlice.actions,
    ...summaryDashboardSlice.actions,
    ...pncMessageSlice.actions,
    ...eCommerceSlice.actions,
    ...eCommerceAnalyticsSlice.actions,
};
