import React, { useEffect, useState } from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import { connect } from 'react-redux';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import FollowersHeader from 'components/analytics/FollowersHeader';
import ListTable from 'components/contests/ListTable';
import ContentWrapper from 'components/ContentWrapper';
import AnalyticsHeader from 'components/analytics/AnalyticsHeader';
import Flex from 'components/Flex';
import { ButtonStyles } from 'components/styles';

import Icons from 'assets/icons/Index';

import { IContestList, IGetContestsReduxParam, ContestListTypesEnum, IContestStatusEnum, ICreateQuizModalState } from 'entities/contests';
import { useLocation } from 'react-router';
import { ICreateGame } from 'entities/games';
import CreateGameModal from './CreateGameModal';
import DeleteContestModal from './DeleteContestModal';
import EditGameModal from './EditGameModal';

interface OverviewProps {
    isLanu: boolean;

    activeGamesList: IContestList;
    activeGamesListError: string;
    activeGamesListIsLoading: boolean;
    activeGamesListMaxPage: number;
    activeGamesListCurrentPage:number ;
    activeGamesListCurrentIndex:number ;

    inactiveGamesList: IContestList;
    inactiveGamesListError: string;
    inactiveGamesListIsLoading: boolean;
    inactiveGamesListMaxPage: number;
    inactiveGamesListCurrentPage:number ;
    inactiveGamesListCurrentIndex: number;

    setActiveGamesListIndex: (param: number) => void;
    setInactiveGamesListIndex: (param: number) => void;

    getActiveGames: (param:IGetContestsReduxParam) => void;
    getInactiveGames: (param:IGetContestsReduxParam) => void;

    setCreateGameModal: (param: ICreateQuizModalState) => void;
}

const Overview:React.FunctionComponent<OverviewProps> = ({
    isLanu,

    activeGamesList,
    activeGamesListError,
    activeGamesListIsLoading,
    activeGamesListMaxPage,
    activeGamesListCurrentPage,
    activeGamesListCurrentIndex,

    inactiveGamesList,
    inactiveGamesListError,
    inactiveGamesListIsLoading,
    inactiveGamesListMaxPage,
    inactiveGamesListCurrentPage,
    inactiveGamesListCurrentIndex,

    setActiveGamesListIndex,
    setInactiveGamesListIndex,

    getActiveGames,
    getInactiveGames,

    setCreateGameModal,
}) => {
    const tableHeaders = ['Name of Contest', 'Total Players', 'Total Clicks', 'Actions'];

    const contestType = ContestListTypesEnum.Game;

    const { pathname } = useLocation();

    const [render, setRender] = useState<boolean>(false);

    useEffect(() => {
        getActiveGames({ index: activeGamesListCurrentIndex });
        getInactiveGames({ index: inactiveGamesListCurrentIndex });
    }, [isLanu, activeGamesListCurrentIndex, inactiveGamesListCurrentIndex]);

    useEffect(() => {
        setRender(true);
        setActiveGamesListIndex(1);
        setInactiveGamesListIndex(1);
        getActiveGames({ index: activeGamesListCurrentIndex });
        getInactiveGames({ index: inactiveGamesListCurrentIndex });
    }, [isLanu]);

    useEffect(() => {
        setRender(false);
    }, [render === true]);

    const capitalizeFirstLetter = (title:string) => {
        return title[0].toUpperCase() + title.slice(1);
    };

    return (

        <ContentWrapper>
            <Flex alignItems='center' gap='1rem' justifyContent='space-between' flexWrap='wrap'>
                <AnalyticsHeader
                    header={`Contest - ${capitalizeFirstLetter(contestType)}`}
                    size='lg'
                />
                <StyledButton type='button' css={ButtonStyles.primary} onClick={() => setCreateGameModal({ isOpen: true, type: ContestListTypesEnum.Game })}>
                    Create New
                    {` ${capitalizeFirstLetter(contestType)}`}
                </StyledButton>
            </Flex>
            <OverviewContainer>
                <FollowersHeader
                    headerContent={`Current Active ${contestType.charAt(0).toUpperCase() + contestType.slice(1)}`}
                    headerSize='sm'
                    tooltipIconSize='1rem'
                    tooltipId={`contest-${contestType}-tooltip-active`}
                    tooltipIconSrc={Icons.QuestionMark}
                    tooltipContent='There can only be 1 active game or quiz across multiple channels.'
                />

                <ListTable
                    tableId={`active-${contestType}`}
                    contestStatus={IContestStatusEnum.Active}
                    contestType={ContestListTypesEnum.Game}
                    headers={tableHeaders}
                    isLoading={activeGamesListIsLoading}
                    contestList={activeGamesList}
                    contestError={activeGamesListError}
                    contestMaxPage={activeGamesList.maxIndex}
                    contestCurrentPage={activeGamesListCurrentPage}
                    contestCurrentIndex={activeGamesListCurrentIndex}
                    setContestIndex={setActiveGamesListIndex}
                    tablePathname={pathname}
                    renderState={render}
                />

                <FollowersHeader
                    headerContent={`${contestType.charAt(0).toUpperCase() + contestType.slice(1)} History (Inactive)`}
                    headerSize='sm'
                    tooltipIconSize='1rem'
                    tooltipId={`contest-${contestType}-tooltip-inactive`}
                    tooltipIconSrc={Icons.QuestionMark}
                    tooltipContent='Details of inactive games that has been created in the past.'
                />
                <ListTable
                    tableId={`inactive-${contestType}`}
                    contestStatus={IContestStatusEnum.Inactive}
                    contestType={ContestListTypesEnum.Game}
                    headers={tableHeaders}
                    isLoading={inactiveGamesListIsLoading}
                    contestList={inactiveGamesList}
                    contestError={inactiveGamesListError}
                    contestMaxPage={inactiveGamesList.maxIndex}
                    contestCurrentPage={inactiveGamesListCurrentPage}
                    contestCurrentIndex={inactiveGamesListCurrentIndex}
                    setContestIndex={setInactiveGamesListIndex}
                    tablePathname={pathname}
                    renderState={render}
                />

                <CreateGameModal />
                <DeleteContestModal />
                <EditGameModal />
            </OverviewContainer>
        </ContentWrapper>
    );
};

interface CustomStylingProps {
    css?: FlattenSimpleInterpolation;
}

const StyledButton = styled.button<CustomStylingProps>`
    ${props => props.css}
`;

const OverviewContainer = styled.div`
    margin:1rem 0;
`;

const mapStateToProps = (state: RootState) => ({
    isLanu: Selectors.getFaqGetIsLANU(state),

    activeGamesList: Selectors.contestGetActiveGamesList(state),
    activeGamesListError: Selectors.contestGetActiveGamesListError(state),
    activeGamesListIsLoading: Selectors.contestGetActiveGamesListAttempting(state),
    activeGamesListMaxPage: Selectors.contestGetActiveGamesListMaxIndex(state),
    activeGamesListCurrentPage: Selectors.contestGetActiveGamesListPage(state),
    activeGamesListCurrentIndex: Selectors.contestGetActiveGamesListIndex(state),

    inactiveGamesList: Selectors.contestGetInactiveGamesList(state),
    inactiveGamesListError: Selectors.contestGetInactiveGamesListError(state),
    inactiveGamesListIsLoading: Selectors.contestGetInactiveGamesListAttempting(state),
    inactiveGamesListMaxPage: Selectors.contestGetInactiveGamesListMaxIndex(state),
    inactiveGamesListCurrentPage: Selectors.contestGetInactiveGamesListPage(state),
    inactiveGamesListCurrentIndex: Selectors.contestGetInactiveGamesListIndex(state),

});

const mapDispatchToProps = (dispatch: AppDispatch) => ({

    setActiveQuizListIndex: (param: number) => dispatch(Actions.setActiveQuizListIndex(param)),
    setActiveGamesListIndex: (param: number) => dispatch(Actions.setActiveGamesListIndex(param)),

    setInactiveQuizListIndex: (param: number) => dispatch(Actions.setInactiveQuizListIndex(param)),
    setInactiveGamesListIndex: (param: number) => dispatch(Actions.setInactiveGamesListIndex(param)),

    getActiveGames: (param: IGetContestsReduxParam) => dispatch(Actions.getActiveGamesListAttempt(param)),
    getActiveQuiz: (param: IGetContestsReduxParam) => dispatch(Actions.getActiveQuizListAttempt(param)),

    getInactiveGames: (param: IGetContestsReduxParam) => dispatch(Actions.getInactiveGamesListAttempt(param)),
    getInactiveQuiz: (param: IGetContestsReduxParam) => dispatch(Actions.getInactiveQuizListAttempt(param)),

    createGame: (data: ICreateGame) => dispatch(Actions.createGameAttempting(data)),

    setCreateGameModal: (param: ICreateQuizModalState) => dispatch(Actions.contestSetCreateQuizModalState(param)),

});

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
