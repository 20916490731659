import React, { FunctionComponent, useState, useEffect, CSSProperties, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';

import Icons from 'assets/icons/Index';
import Fonts from 'assets/themes/Fonts';
import Colors from 'assets/themes/Colors';
import Button from './Button';
import ButtonText from './ButtonText';
import Flex from './Flex';
import AppVersion from './AppVersion';

export interface HeaderProps {
    isLanu: boolean;
    setIsLanu: (state: boolean) => void;
    logout: () => void;
}

const Header: FunctionComponent<HeaderProps> = (props: HeaderProps) => {
    const {
        isLanu,
        setIsLanu,
        logout,
    } = props;

    const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false);

    const location = useLocation();

    useEffect(() => {
        setIsLanu(isLanu);
    }, [isLanu]);

    const excludedPaths = [
        'broadcast',
        'pncmessage',
        'ecommerce',
    ];

    const isExcludedPath = useMemo(() => {
        const { pathname } = location;
        return excludedPaths.some((path) => pathname.includes(path));
    }, [excludedPaths, location.pathname]);

    return (
        <StyledWrapper>
            <div style={{ backgroundColor: 'white', top: 0, left: 0, width: '19.5%', height: '100%', display: 'flex', justifyContent: 'center' }}>
                <IQOSLogo src={Icons.IqosIcon} />
            </div>

            <div style={{ backgroundColor: 'white', display: 'flex', justifyContent: 'space-between', width: '70%', height: '100%', marginLeft: '2.4%', alignItems: 'center' }}>

                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
                    {isExcludedPath ? null
                        : (
                            <>
                                <ButtonText label='LAU' onClick={() => setIsLanu(false)} isSelected={!isLanu} style={containerStyles.buttonText} />
                                <ButtonText label='LANU' onClick={() => setIsLanu(true)} isSelected={isLanu} style={containerStyles.buttonText} />
                            </>
                        )}
                </div>
                <Flex alignItems='center'>
                    <div style={{ color: Colors.black }}>
                        <AppVersion />
                    </div>
                    <UserProfileWrapper>
                        <Dropdown isOpen={dropdownIsOpen} toggle={() => setDropdownIsOpen(!dropdownIsOpen)}>
                            <DropdownToggle caret style={styles.filterButton}>
                                <UserProfileIcon src='https://i.pinimg.com/originals/3f/94/70/3f9470b34a8e3f526dbdb022f9f19cf7.jpg' />
                                <div style={{ overflowX: 'hidden', fontSize: '18px' }}>
                                    {localStorage.getItem('userName')}
                                </div>
                            </DropdownToggle>
                            <DropdownMenu container='body' className='filter-menu-item'>
                                {/* <DropdownItem onClick={() => onLogout()}> */}
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Button label='Log Out' onClick={() => logout()} css={styles.logoutButton} />
                                </div>
                                {/* </DropdownItem> */}
                            </DropdownMenu>
                        </Dropdown>
                    </UserProfileWrapper>
                </Flex>
            </div>
        </StyledWrapper>
    );
};

interface StylesDictionary {
    [Key: string]: CSSProperties;
}

const containerStyles: StylesDictionary = {
    buttonText: {
        height: '46px',
        width: '126px',
    },
};

const StyledWrapper = styled.div`    
    background: white;
    color: white;
    border-bottom: 1px solid #959595;

    font-size: 20px;
    font-family: ${Fonts.primary};

    width: 100%;
    top: 0;
    z-index: 99;

    padding: 20px 0px 20px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: sticky;
`;

const UserProfileWrapper = styled.div`
    overflow-x: hidden;
    border-radius: 10px;
`;

const IQOSLogo = styled.img`
    width: 58px;
    height: 62px;
`;

const UserProfileIcon = styled.img`
    width: 40px;
    height: 40px;
`;

const styles = {
    filterButton: {
        backgroundColor: 'white',
        fontSize: '18',
        fontFamily: Fonts.primary,
        borderRadius: '10px',
        width: '160px',
        height: '46px',
        color: 'black',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '5px',
        paddingLeft: '3px',
    },
    logoutButton: css`
        background-color: #DB3624 !important;
        color: white !important;
        border: none !important;
        width: 90%;
        height: 46px !important;
        font-family: ${Fonts.secondary};
        font-size: 18px;
    `,
};

const mapStateToProps = (state: RootState) => ({
    isLanu: Selectors.getFaqGetIsLANU(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setIsLanu: (state: boolean) => dispatch(Actions.setIsLanu(state)),
    logout: () => dispatch(Actions.authLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
