import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { GatewayResponseStatus } from 'api/types/types';
import ContestsGateway from 'api/Contests';
import { IChannelTypeEnum, IContestStatusEnum, IGetContestsReduxParam } from 'entities/contests';
import { PayloadAction } from '@reduxjs/toolkit';

export default function* watchGetInactiveQuizList(api: ContestsGateway): SagaWatcherReturnType {
    yield takeEvery('contests/getInactiveQuizListAttempt', handleGetInactiveQuizList, api);
}

function* handleGetInactiveQuizList(api: ContestsGateway, data: PayloadAction<IGetContestsReduxParam>) {
    const { index } = data.payload;

    const authToken = yield* select(Selectors.getAuthAuthToken);
    const isLanu = yield* select(Selectors.getFaqGetIsLANU);
    const type = isLanu ? IChannelTypeEnum.LANU : IChannelTypeEnum.LAU;

    const response = yield* call([api, api.getQuizList], { authToken, type, status: IContestStatusEnum.Inactive, index });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.getInactiveQuizListFailure(response.message || ''));
    } if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.getInactiveQuizListSuccess(response.data));
    }
}
