import React, { FunctionComponent, useState, CSSProperties, useEffect } from 'react';
import { css } from 'styled-components';
import SVG from 'react-inlinesvg';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import InnerCollapsible from 'components/InnerCollapsible';
import TextArea from 'components/TextArea';
import Text from 'components/Text';
import Button from 'components/Button';
import Input from 'components/Input';

import Colors from 'assets/themes/Colors';
import Icons from 'assets/icons/Index';
import { IFaq } from 'entities/faq';
import Validators from 'lib/Validators';

interface WelcomeMessageCollapsibleProps {
    loading: boolean;
    getWelcomeMessage: IFaq | null;
    setWelcomeMessage(value: string): void;
}

interface StylesDictionary {
    [Key: string]: CSSProperties;
}

const WelcomeMessageCollapsible: FunctionComponent<WelcomeMessageCollapsibleProps> = (props: WelcomeMessageCollapsibleProps) => {
    const { loading, getWelcomeMessage, setWelcomeMessage } = props;

    const [isOpen, setIsOpen] = useState(true);
    const [content, setContent] = useState('');
    const [title, setTitle] = useState('');
    const [initialContent, setInitialContent] = useState('');
    const [initialTitle, setInitialTitle] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [error, setError] = useState('');
    const [hasChange, setHasChange] = useState<boolean>(false);

    useEffect(() => {
        if (getWelcomeMessage) {
            setTitle(getWelcomeMessage.title);

            const messagesText = getWelcomeMessage.messages.map(item => item.text);

            setContent(messagesText.join(', '));

            setInitialTitle(title);
            setInitialContent(content);
        }
    }, [getWelcomeMessage]);

    const toggleCollapsible = () => {
        setIsOpen(!isOpen);
    };

    const saveClickHandler = () => {
        const welcomeMsgError = Validators.ValidateWelcomeMessage(content);
        setError(welcomeMsgError);

        if (!welcomeMsgError.length) {
            setWelcomeMessage(content);
            setIsEditing(false);
            setError('');
            setHasChange(false);
        }
    };

    const cancelClickHandler = () => {
        if (getWelcomeMessage) {
            setContent(initialContent);
            setTitle(initialTitle);
            setError('');
            setIsEditing(false);
            setHasChange(false);
        }
    };

    const renderTopContainer = () => {
        return (
            <div style={containerStyles.topContainer}>
                {!isEditing && (

                    <div style={containerStyles.pressableContainer}>
                        <Button
                            onClick={() => (isOpen ? setIsEditing(true) : [setIsOpen(!isOpen), setIsEditing(true)])}
                            css={styles.pencilIcon}
                        >
                            <SVG src={Icons.PencilIcon} id='pencil' fill={Colors.iqosPrimary} />
                        </Button>
                    </div>
                )}
            </div>
        );
    };

    const renderCard = () => {
        return (
            <div style={containerStyles.card}>
                <div style={{ height: '100%', display: 'flex' }}>
                    {isEditing ? (
                        <div style={{ width: '100%', padding: '0 0 0 12px' }}>
                            <TextArea value={content} onChange={(e) => [setContent(e.target.value), setHasChange(true)]} css={styles.inputContent} />
                            <div style={{ display: 'flex', justifyContent: 'space-between', transition: '0.5s', marginTop: '.5rem' }}>
                                <Text>
                                    {`Number of characters ${title.length + content.length}/4094`}
                                </Text>
                                <div style={{ display: 'flex' }}>
                                    <Button
                                        label='Save'
                                        onClick={saveClickHandler}
                                        css={styles.saveButton}
                                        disabled={!hasChange}
                                    />
                                    <Button
                                        label='Cancel'
                                        onClick={cancelClickHandler}
                                        css={styles.cancelButton}
                                    />
                                </div>
                            </div>
                            <Text style={{ color: 'red' }}>
                                {error}
                            </Text>
                        </div>
                    ) : (
                        <Text style={{
                            width: '100%',
                            height: '100%',
                            paddingLeft: '1.2rem',
                            paddingRight: '1.2rem',
                            paddingTop: 0,
                            paddingBottom: 0,
                            alignSelf: 'center',
                            fontSize: '16px',
                        }}
                        >
                            {content}
                        </Text>
                    )}
                </div>
            </div>
        );
    };

    if (loading) return <Skeleton count={1} height={30} />;

    return (
        <InnerCollapsible isOpen={isOpen} title={renderTopContainer()} content={renderCard()} />
    );
};

const containerStyles: StylesDictionary = {
    stripEditing: {
        backgroundColor: '#DCDCDC',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '5px',
        width: '100%',
    },
    stripIdle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '5px',
        width: '90%',
    },
    card: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '5px',
    },
    topContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'transparent',
        marginTop: '3px',
        position: 'relative',
    },
    pressableContainer: {
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        right: '1rem',
        top: '26px',
        bottom: '0',

    },
    indicatorOdd: {
        height: '100%',
        backgroundColor: Colors.secondary,
        position: 'absolute',
        left: '0px',
    },
    indicatorEven: {
        height: '100%',
        backgroundColor: Colors.primary,
        position: 'absolute',
        left: '0px',
    },
};

const styles = {
    saveButton: css`
        background-color: #1976d2;
        color: white;
        font-size: 18px; 
        border-radius: 50px;
        width: 100px;
        margin-right: 10px;

        &:hover {
            background-color: #1976d2;
            color: white;
        }

        &:active {
            background-color: #004ba0;
            color: white;
        }
    `,
    cancelButton: css`
        background-color: #C4C4C4;
        color: black;
        font-size: 18px; 
        border-radius: 50px;
        width: 100px;

        &:hover {
            background-color: #d32f2f;
            color: white;
        }

        &:active {
            background-color: #9a0007;
            color: white;
        }
    `,
    plusIcon: css`
        background-color: transparent;
        width: 35px;
        height: 35px;
        
        #plus {
            width: 20px;
            height: 20px;
            &:hover{
                color: #388e3c;
            }
        }
    `,
    trashIcon: css`
        background-color: transparent;
        width: 35px;
        height: 35px;

        #trash {
            width: 20px;
            height: 20px;
            &:hover{
                color: #d32f2f;
            }
        }
    `,
    pencilIcon: css`
        background-color: transparent;
        margin-left: 10px;
        margin-right: 10px;
        width: 35px;
        height: 35px;
        border-color: transparent !important;
        background-color: transparent !important;

        #pencil {
            width: 20px;
            height: 20px;
            &:hover{
                color: #1976d2;
            }
        }
    `,
    upIcon: css`
        background-color: transparent;
        width: 35px;
        height: 35px;

        #up {
            width: 20px;
            height: 20px;
            &:hover{
                color: ${Colors.secondary};
            }
        }
    `,
    downIcon: css`
        background-color: transparent;
        width: 35px;
        height: 35px;
        #down {
            width: 20px;
            height: 20px;
            &:hover{
                color: ${Colors.secondary};
            }
        }
    `,
    inputTitle: css`
        background-color: #DCDCDC;
        outline: none;
        border: none;
        font-size: 23px;
        height: 100%;
        padding-left: 20px;
        border-radius: 50px;

        &:hover {
            background-color: #C4C4C4;
        }
    `,
    inputContent: css`
        height: 150px;
        background-color: #F7F7F7;
    `,
    chevronRight: css`
        background-color: transparent;
        margin-right: 100px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        text {
            margin-left: 10px;
        }

        #chevron {
            width: 20px;
            height: 20px;
            margin-left: 10px;
        }

        &:hover {
            #chevron {
                color: ${Colors.secondary};
                transition: 0.5s;
            }
        }
    `,
    chevronDown: css`
        background-color: transparent;
        margin-right: 100px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        text {
            margin-left: 10px;
        }

        #chevron {
            transform: rotate(90deg) scale(1);
            width: 20px;
            height: 20px;
            margin-left: 10px;
        }

        &:hover {
            #chevron {
                color: ${Colors.secondary};
                transition: 0.5s;
            }
        }
    `,
};

const mapStateToProps = (state: RootState) => ({
    getWelcomeMessage: Selectors.getFaqWelcomeMsg(state),
    loading: Selectors.getFaqSetWelcomeMsgAttempting(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setWelcomeMessage: (value: string) => dispatch(Actions.updateWelcomeMsgAttempt(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeMessageCollapsible);
