import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { toast } from 'react-toastify';
import dayjs from 'dayjs';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import BroadcastGateway from 'api/Broadcast';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';
import { CreateBroadcastPayload } from 'redux/slices/broadcast/types';
import { BroadcastHistoryFilter, StatusTypeEnum } from 'entities/broadcast';

export default function* watchCreateBroadcastPost(api: BroadcastGateway): SagaWatcherReturnType {
    yield takeEvery('broadcast/createBroadcastPostAttempt', handleCreateBroadcastPost, api);
}

function* handleCreateBroadcastPost(api: BroadcastGateway, data: CreateBroadcastPayload) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.createBroadcastPost], {
        data: data.payload,
        authToken,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.createBroadcastPostFailure('Failed to create broadcast!'));
        toast.error(response.message);
        return;
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.createBroadcastPostSuccess());
        yield put(Actions.getRecurringPostAttempt({ index: 1, status: StatusTypeEnum.All }));
        yield put(Actions.getSchedulePostAttempt({ index: 1, status: StatusTypeEnum.All }));
        yield put(Actions.getHistoryPostAttempt({ index: 1, status: BroadcastHistoryFilter.All }));
        yield put(Actions.getBroadcastOverviewAttempt({ date: dayjs().toISOString() }));

        yield put(Actions.setCreateBroadcastModalIsOpen(false));
        yield put(Actions.setDuplicateBroadcastModalIsOpen(false));

        toast.success('New Broadcast created');
    }
}
