import React, { FunctionComponent, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { AppDispatch } from 'redux/store';
import { connect } from 'react-redux';
import Actions from 'redux/Actions';

import 'components/StyledComponent.css';
import Fonts from 'assets/themes/Fonts';

import Text from 'components/Text';
import PostsOverview from './PostsOverview';
import RateByCategories from './RateByCategories';
import RateByPostingTime from './RateByPostingTime';
import RateByPost from './RateByPost';
import PostsReport from './PostsReport';
import PerformingPosts from './PerformingPosts';

interface AnalyticsPostsProps {
    getUserClaims(): void;
}

const AnalyticsPosts: FunctionComponent<AnalyticsPostsProps> = (props: AnalyticsPostsProps) => {
    const {
        getUserClaims,
    } = props;

    useEffect(() => {
        getUserClaims();
    });

    const [tabNumber, setTabNumber] = useState<number>(1);

    const renderHeader = () => {
        return (
            <StyledHeaderTab>
                <StyledHeaderButton
                    onClick={() => [setTabNumber(1)]}
                    isSelected={tabNumber === 1}
                >
                    Posts Overview
                </StyledHeaderButton>

                <StyledHeaderButton
                    onClick={() => [setTabNumber(2)]}
                    isSelected={tabNumber === 2}
                >
                    Average Rate by Categories
                </StyledHeaderButton>

                <StyledHeaderButton
                    onClick={() => [setTabNumber(3)]}
                    isSelected={tabNumber === 3}
                >
                    Average Rate by Posting Time
                </StyledHeaderButton>

                <StyledHeaderButton
                    onClick={() => [setTabNumber(4)]}
                    isSelected={tabNumber === 4}
                >
                    Average Rate by Number of Post
                </StyledHeaderButton>

                <StyledHeaderButton
                    onClick={() => [setTabNumber(5)]}
                    isSelected={tabNumber === 5}
                >
                    Posts Report
                </StyledHeaderButton>

                <StyledHeaderButton
                    onClick={() => [setTabNumber(6)]}
                    isSelected={tabNumber === 6}
                >
                    Top & Least Performing Posts
                </StyledHeaderButton>
            </StyledHeaderTab>
        );
    };

    return (
        <MainWrapper>
            <StyledPageHeader>
                <StyledText style={{ fontSize: '30px', fontFamily: `${Fonts.secondary}` }}>
                    Posts
                </StyledText>
            </StyledPageHeader>
            <BodyWrapper>
                {renderHeader()}
                <div style={{ minWidth: '100%', maxWidth: '100%', paddingBottom: '30px' }}>
                    {tabNumber === 1 && (
                        <PostsOverview />
                    )}
                    {tabNumber === 2 && (
                        <RateByCategories />
                    )}
                    {tabNumber === 3 && (
                        <RateByPostingTime />
                    )}
                    {tabNumber === 4 && (
                        <RateByPost />
                    )}
                    {tabNumber === 5 && (
                        <PostsReport />
                    )}
                    {tabNumber === 6 && (
                        <PerformingPosts />
                    )}
                </div>
            </BodyWrapper>
        </MainWrapper>
    );
};

const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    background-color: white;
    border-radius: 20px;

    padding-top: 1%;
    padding-left: 0.5%;
    padding-bottom: 1%;

    width: 71%;
    height: 100%;
    
    margin-bottom: 5%;
    margin-right: 7%;
    
`;

const StyledPageHeader = styled.div`
    padding: 0px 0px 20px 0px;
    width: 100%;
`;

const StyledHeaderButton = styled.button<{isSelected: boolean}>`
    border: none;
    font-size: 17px;
    padding: 10px 10px 5px 10px;
    transition: 0.3s;
    width: 100%;
    height: 100%;
    color: black;
    background-color: lightgrey;
    border-radius: 20px 20px 0px 0px;

    ${props => (props.isSelected === true) && css`
        background-color: #F1F6F8;
        color: black;
        border: none;
    `}
`;

const StyledHeaderTab = styled.div`
    border: none;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-content: space-around;
    width: 100%;
    height: 60px;
    background-color: lightgrey;
    align-items: center;
    border-radius: 20px 20px 0px 0px;
`;

const BodyWrapper = styled.div`
    width: 100%;
    height: 100%;
    background-color: #F1F6F8;
    border-radius: 20px 20px 0px 0px;
`;

const StyledText = styled(Text)`
    text-align: left;
    font-size: 18px;
`;

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getUserClaims: () => dispatch(Actions.getUserClaimsAttempt()),
});

export default connect(null, mapDispatchToProps)(AnalyticsPosts);
