import React, { useEffect } from 'react';

import styled from 'styled-components';
import { connect } from 'react-redux';

import { ECommFunnelReportData } from 'entities/ecommerceAnalytics';

import Subtitle from 'components/Subtitle';
import Loading from 'components/analytics/common/Loading';
import Error from 'components/analytics/common/Error';

import Fonts from 'assets/themes/Fonts';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { AppDispatch, RootState } from 'redux/store';

interface FunnelTableProps {
    categoryId: string;
    categoryName: string;

    loading: boolean;
    error: string | null;
    data: ECommFunnelReportData | null;

    selectedDates: { dateFrom: Date, dateTo: Date };

    getFunnelReport: (productCategoryId: string) => void;
}

const FunnelTable = (props: FunnelTableProps): JSX.Element => {
    const { categoryId, categoryName, loading, error, data, selectedDates, getFunnelReport } = props;

    const { dateFrom, dateTo } = selectedDates;

    useEffect(() => {
        getFunnelReport(categoryId);
    }, [categoryId, dateFrom, dateTo]);

    const prepareData = () => {
        const rows: { [key: string]: string | number }[] = [];

        if (!data) return [];

        const convertToPercentage = (clicks: number, total: number): string => {
            let fraction = clicks / total;
            if (clicks === 0 || total === 0) fraction = 0;

            return `(${(fraction * 100).toFixed(0)}%)`;
        };

        // First, we add in product category
        rows.push({
            category: categoryName,
            product: '',
            variant: '',
            clicks: data.totalClicks,
            buyNowClicks: data.buyNowClicks,
        });

        data.products.forEach((product) => {
            // Now, we add in products
            rows.push({
                category: '',
                product: product.name,
                variant: '',
                clicks: product.clicks,
                buyNowClicks: product.buyNowClicks,
            });

            // Finally, the variants
            product.variants.forEach((variant) => {
                rows.push({
                    category: '',
                    product: '',
                    variant: variant.name,
                    clicks: `${variant.clicks} ${convertToPercentage(variant.clicks, product.clicks)}`,
                    buyNowClicks: `${variant.buyNowClicks} ${convertToPercentage(variant.buyNowClicks, variant.clicks)}`,
                });
            });
        });

        return rows;
    };

    const renderData = (): JSX.Element => {
        if (loading) return <Loading height={150} />;
        if (error || !data) return <Error height={150} errorMessage={error} />;

        const tableHeaders = ['Product Category', 'Product', 'Variants', 'Clicks', 'Buy Now Clicks'];
        const tableData = prepareData();

        return (
            <StyledTable>
                <StyledTHead>
                    <tr>
                        {tableHeaders.map((item) => (
                            <StyledTh key={item}>
                                {item}
                            </StyledTh>
                        ))}
                    </tr>
                </StyledTHead>
                <StyledTableBody>
                    {tableData.map((obj) => (
                        <StyledTr key={obj.toString()}>
                            {Object.values(obj).map((item) => {
                                return (
                                    <StyledTd key={obj.toString()}>
                                        {item}
                                    </StyledTd>
                                );
                            })}
                        </StyledTr>
                    ))}
                </StyledTableBody>
            </StyledTable>
        );
    };

    return (
        <Container>
            <Row>
                <Subtitle>
                    Number of Activities
                </Subtitle>

                <Text>
                    {`Viewing: ${categoryName}`}
                </Text>
            </Row>

            {renderData()}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;

    flex: 1;

    width: 100%;

    @media (max-width: 1024px) {
        width: 100%;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;

    width: 100%;

    justify-content: space-between;
    align-item: center;
`;

const Text = styled.text`
    font-family: ${Fonts.secondary};
    font-size: 14px;
    color: #A2A2A2;

    align-self: center;
`;

const StyledTable = styled.table`
    min-width: 100%;
    background-color: #F6FCFC;
    border-collape: collapse;
    table-layout: fixed;
    
    margin-top: 20px;
    margin-bottom: 3%;
`;

const StyledTHead = styled.thead`
    background-color: white;
    text-align: center;
    border-bottom: 2px solid #959595;
`;

const StyledTableBody = styled.tbody`
    text-align: center;
`;

const StyledTh = styled.th`
    padding: 16px;
    border-bottom: 1px solid rgb(0,0,0,0,3);
    font-size: 18px;
    color: #959595;
    font-weight: lighter;
`;

const StyledTr = styled.tr`
    &:not(:nth-of-type(2n + 1)){
        background-color: white;
    }

    &:not(:last-of-type){
        border-bottom: 5px solid #ffffff;
    }
`;

const StyledTd = styled.td`
    padding: 20px;
    font-size: 16px;
    max-width: 100%;
    whiteSpace: wrap;
    overflow: hidden;
    text-Overflow: ellipsis;
    text-align: center;
`;

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getECommerceAnalFunnelReportAttempting(state),
    error: Selectors.getECommerceAnalFunnelReportFailure(state),
    data: Selectors.getECommerceAnalFunnelReportData(state),

    selectedDates: Selectors.getECommerceAnalDatePicker(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getFunnelReport: (productCategoryId: string) => dispatch(Actions.ecommAnalGetFunnelReportAttempt({ productCategoryId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(FunnelTable);
