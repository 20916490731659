import React, { FunctionComponent } from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import Fonts from 'assets/themes/Fonts';

export interface TitleProps {
    label: string;
    css?: FlattenSimpleInterpolation;
}

const Title: FunctionComponent<TitleProps> = ({
    label,
    ...props
}: TitleProps) => {
    return (
        <StyleBodyText {...props}>
            {label}
        </StyleBodyText>
    );
};

const StyleBodyText = styled.div`
    margin-top: 2%;
    background-color: #F1F6F8;
    font-size: 32px;
    color: #27242E;
    padding-right: 30px;
    font-family: ${Fonts.secondary};
    font-weight: bold;

    @media (max-width: 1580px) {
        font-size: 27px;
    }
`;

export default Title;
