import { put, call, select, takeEvery } from 'typed-redux-saga/macro';
import { PayloadAction } from '@reduxjs/toolkit';

import { SagaWatcherReturnType } from 'sagas/types';

import Actions from 'redux/Actions';
import { GatewayResponseStatus } from 'api/types/types';

import Selectors from 'redux/Selectors';
import { toast } from 'react-toastify';
import PostsGateway from 'api/Posts';
import { CategoryIdEnum, EditPostsReport } from 'entities/posts';
import { IChannelTypeEnum } from 'entities/contests';

export default function* watchEditPostsReport(api: PostsGateway): SagaWatcherReturnType {
    yield takeEvery('posts/editPostsReportAttempt', hanldeEditPostsReport, api);
}

function* hanldeEditPostsReport(api: PostsGateway, data: PayloadAction<EditPostsReport>) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const isLanu = yield* select(Selectors.getFaqGetIsLANU);
    const dateFrom = yield* select(Selectors.getPostsGetDateFrom);
    const dateTo = yield* select(Selectors.getPostsGetDateTo);
    const selectedCategory = yield* select(Selectors.getPostsGetSelectedCategory);
    const currentPostReportIndex = yield* select(Selectors.getPostsGetPostsReportIndex);

    const response = yield* call([api, api.editPostsReport], {
        authToken,
        data: data.payload,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.editPostsReportFailure(response.message));
        toast.error(response.message);
        return;
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.editPostsReportSuccess(response.data));
        yield put(Actions.getPostsReportAttempt({ channelType: `${isLanu ? IChannelTypeEnum.LANU : IChannelTypeEnum.LAU}`, dateFrom: dateFrom.toISOString(), dateTo: dateTo.toISOString(), category: selectedCategory, index: currentPostReportIndex }));
        toast.success('Successfully edited!');
    }
}
