import { ApiResponse } from 'apisauce';
import { GatewayResponse } from 'api/types/types';
import {
    IGetFollowersTablesInfoAPIParam,
    IGetFollowersUsersListAPIParam,
    IGetFollowersUsersListResponse,
    IFollowersTableInfo,
    IFollowersChartsResponse,
    IGetFollowersChartsDataAPIParam,
    IGetFullFollowersUsersListAPIParam,
    IGetFullFollowersUsersListResponse,
    IExportFullFollowersListToExcelAPIParam,
    IExportFullFollowersListToExcelResponse,
} from 'entities/followers';

import IFollowersGateway from './FollowersBase';

const getHeaders = (authToken: string) => ({
    headers: {
        Authorization: `Bearer ${authToken}`,
    },
});

const getHeadersWithTimeout = (authToken: string, offTimer: number) => ({
    timeout: offTimer,
    headers: {
        Authorization: `Bearer ${authToken}`,
    },
});

export default class FollowersGateway extends IFollowersGateway {
    async getFollowersUsersList(params: IGetFollowersUsersListAPIParam): GatewayResponse<IGetFollowersUsersListResponse> {
        const { authToken, dateFrom, dateTo, channelType, index } = params;
        const response: ApiResponse<IGetFollowersUsersListResponse> = await this.api.get('/v1/analytics/followers/followersList', { dateFrom, dateTo, channelType, index }, getHeaders(authToken));
        return this.process(response);
    }

    async getFollowersTablesInfo(params: IGetFollowersTablesInfoAPIParam): GatewayResponse<IFollowersTableInfo> {
        const { authToken, ...getFollowersTablesInfoParam } = params;
        const response: ApiResponse<IFollowersTableInfo> = await this.api.get('/v1/analytics/followers/followersTable', getFollowersTablesInfoParam, getHeaders(authToken));
        return this.process(response);
    }

    async getFollowersChartsData(params: IGetFollowersChartsDataAPIParam): GatewayResponse<IFollowersChartsResponse> {
        const { authToken, ...getFollowersChartsParam } = params;
        const response: ApiResponse<IFollowersChartsResponse> = await this.api.get('/v1/analytics/followers/graph', getFollowersChartsParam, getHeaders(authToken));
        return this.process(response);
    }

    async getFullFollowersUsersList(params: IGetFullFollowersUsersListAPIParam): GatewayResponse<IGetFullFollowersUsersListResponse> {
        const { authToken, ...getFullFollowersParam } = params;
        const response: ApiResponse<IGetFullFollowersUsersListResponse> = await this.api.get('/v1/analytics/followers/fullFollowersList', getFullFollowersParam, getHeaders(authToken));
        return this.process(response);
    }

    async exportFullFollowersListToExcel(params: IExportFullFollowersListToExcelAPIParam): GatewayResponse<IExportFullFollowersListToExcelResponse> {
        const { authToken, channelType, dateTo, dateFrom } = params;
        const response: ApiResponse<IExportFullFollowersListToExcelResponse> = await this.api.get('/v1/analytics/followers/exportFullFollowersList', { channelType, dateTo, dateFrom }, getHeadersWithTimeout(authToken, 20000));
        return this.process(response);
    }
}
