import React, { CSSProperties, FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';

import CustomLoadingOverlay from 'components/LoadingOverlay';

import Selectors from 'redux/Selectors';

import SideMenu from 'components/analytics/SideMenu';
import { useSelector } from 'react-redux';
import Header from '../../components/Header';

export interface StylesDictionary {
    [Key: string]: CSSProperties;
}

const HomeScreen: FunctionComponent = () => {
    const editing = useSelector(Selectors.getFaqFaqsEditing);

    return (
        <div style={styles.background}>
            <SideMenu />
            <Header />
            <CustomLoadingOverlay active={editing} />
            <div style={styles.bodyContainer}>
                <Outlet />
            </div>
        </div>
    );
};

const styles: StylesDictionary = {
    background: {
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end',
        width: '100%',
        height: '100%',
    },
    bodyContainer: {
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end',
        width: '100%',
        height: '100%',
        paddingTop: '2%',
    },
    card: {
        position: 'absolute',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        width: '40%',
        height: '60%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0px auto',
        borderRadius: 5,
    },
};

export default HomeScreen;
