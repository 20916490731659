import React, { useState, useEffect, FunctionComponent } from 'react';
// ? Redux Toolkit
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import SVG from 'react-inlinesvg';
import { toast } from 'react-toastify';
import FsLightbox from 'fslightbox-react';
import styled, { FlattenSimpleInterpolation, css } from 'styled-components';

import Icons from 'assets/icons/Index';
import Fonts from 'assets/themes/Fonts';
import Colors from 'assets/themes/Colors';

import Title from 'components/Title';
import ListTable from 'components/ecommerce/ListTable';
import CollapsibleCard from 'components/ecommerce/CollapsibleCard';

import {
    ISetProductListingMessage,
    ISetPromotionalMessage,
    ISetWelcomeMessage,
    LanguageEnum,
    MessageCategoryEnum,
    ProductCategoryDetails,
    ProductListingMessageDetails,
    PromotionalMessageDetails,
    TableTypeEnum,
    WelcomeMessageDetails,
    CTAButtonDetails,
    ISetCTAButtonDetails,
} from 'entities/ecommerce';

import CreateCategoryModal from './CreateCategoryModal';

// ! interface zone
// * props interface
export interface ECommerceProps {
    // ? Promotional Message Props
    promotionalMessage: PromotionalMessageDetails[];
    getPromotionalMessage: () => void;
    setPromotionalMessage: (data: ISetPromotionalMessage) => void;
    // ? Welcome Message Props
    welcomeMessage: WelcomeMessageDetails[];
    getWelcomeMessage: () => void;
    setWelcomeMessage: (data: ISetWelcomeMessage) => void;
    // ? Product Category Props
    productCategory: ProductCategoryDetails[];
    getProductCategory: () => void;
    // ? Create Category Modal Props
    setCreateCategoryModalIsOpen: (state: boolean) => void;
    // ? Trade Button Link Props
    CTAButtonDetail: CTAButtonDetails[];
    getCTAButtonDetails: () => void;
    setCTAButtonDetails: (data: ISetCTAButtonDetails) => void;
}

// ! JSX function component
const ECommerce: FunctionComponent<ECommerceProps> = (props: ECommerceProps) => {
    // ! destructuring zone
    // * props destructured
    const {
        promotionalMessage,
        getPromotionalMessage,
        setPromotionalMessage,
        welcomeMessage,
        getWelcomeMessage,
        setWelcomeMessage,
        productCategory,
        getProductCategory,
        setCreateCategoryModalIsOpen,
        CTAButtonDetail,
        getCTAButtonDetails,
        setCTAButtonDetails,
    } = props;
    // ? splitting object according to language
    const enPromotionalMessage = promotionalMessage.find(item => item.language === LanguageEnum.EN);
    const bmPromotionalMessage = promotionalMessage.find(item => item.language === LanguageEnum.BM);
    const enWelcomeMessage = welcomeMessage.find(item => item.language === LanguageEnum.EN);
    const bmWelcomeMessage = welcomeMessage.find(item => item.language === LanguageEnum.BM);
    const enCTAButtonDetails = CTAButtonDetail.find(item => item.language === LanguageEnum.EN);
    const bmCTAButtonDetails = CTAButtonDetail.find(item => item.language === LanguageEnum.BM);
    // ? deconstructed splitted object
    const {
        title: promoTitleEN,
        message: promoDescriptionEN,
        mediaUrl: promoMediaUrlEN,
    } = enPromotionalMessage || { title: '', message: '', mediaUrl: '' };
    const {
        title: promoTitleBM,
        message: promoDescriptionBM,
        mediaUrl: promoMediaUrlBM,
    } = bmPromotionalMessage || { title: '', message: '', mediaUrl: '' };
    const {
        title: WmTitleEN,
        message: WmDescriptionEN,
    } = enWelcomeMessage || { title: '', message: '' };
    const {
        title: WmTitleBM,
        message: WmDescriptionBM,
    } = bmWelcomeMessage || { title: '', message: '' };
    const {
        title: CTATitleEN,
        message: CTAMessageEN,
        buttonText: CTATextEN,
        link: CTALinkEN,
        mediaUrl: CTAMediaUrlEN,
    } = enCTAButtonDetails || { title: '', message: '', buttonText: '', mediaUrl: '', link: '' };
    const {
        title: CTATitleBM,
        message: CTAMessageBM,
        buttonText: CTATextBM,
        link: CTALinkBM,
        mediaUrl: CTAMediaUrlBM,
    } = bmCTAButtonDetails || { title: '', message: '', buttonText: '', mediaUrl: '', link: '' };

    // ! useState Zone
    // ? English Input
    const [enPromoTitle, setEnPromoTitle] = useState<string>('');
    const [enPromoDescription, setEnPromoDescription] = useState<string>('');
    const [enWmTitle, setEnWmTitle] = useState<string>('');
    const [enWmDescription, setEnWmDescription] = useState<string>('');
    const [enCTATitle, setEnCTATitle] = useState<string>('');
    const [enCTAMessage, setEnCTAMessage] = useState<string>('');
    const [enCTAText, setEnCTAText] = useState<string>('');
    const [enCTALink, setEnCTALink] = useState<string>('');
    // * English Media Upload/preview
    const [enPromoViewMedia, setEnPromoViewMedia] = useState<string>('');
    const [enPromoUploadedImageFile, setEnPromoUploadedImageFile] = useState<File>();
    const [enPromoUploadedImageFileName, setEnPromoUploadedImageFileName] = useState<string>('');
    const [enCTAViewMedia, setEnCTAViewMedia] = useState<string>('');
    const [enCTAUploadedImageFile, setEnCTAUploadedImageFile] = useState<File>();
    const [enCTAUploadedImageFileName, setEnCTAUploadedImageFileName] = useState<string>('');
    // ? Bahasa Melayu Input
    const [bmPromoTitle, setBmPromoTitle] = useState<string>('');
    const [bmPromoDescription, setBmPromoDescription] = useState<string>('');
    const [bmWmTitle, setBmWmTitle] = useState<string>('');
    const [bmWmDescription, setBmWmDescription] = useState<string>('');
    const [bmCTATitle, setBmCTATitle] = useState<string>('');
    const [bmCTAMessage, setBmCTAMessage] = useState<string>('');
    const [bmCTAText, setBmCTAText] = useState<string>('');
    const [bmCTALink, setBmCTALink] = useState<string>('');
    // * Bahasa Melayu Media Upload/preview
    const [bmPromoViewMedia, setBmPromoViewMedia] = useState<string>('');
    const [bmPromoUploadedImageFile, setBmPromoUploadedImageFile] = useState<File>();
    const [bmPromoUploadedImageFileName, setBmPromoUploadedImageFileName] = useState<string>('');
    const [bmCTAViewMedia, setBmCTAViewMedia] = useState<string>('');
    const [bmCTAUploadedImageFile, setBmCTAUploadedImageFile] = useState<File>();
    const [bmCTAUploadedImageFileName, setBmCTAUploadedImageFileName] = useState<string>('');
    // ? Toggle for view media
    const [toggler, setToggler] = useState<boolean>(false);
    const [lightboxMediaUrl, setLightboxMediaUrl] = useState<string>('');

    // ! useEffect zone
    // ? first api call
    useEffect(() => {
        getPromotionalMessage();
        getWelcomeMessage();
        getProductCategory();
        getCTAButtonDetails();
    }, []);

    // ? called to display existing data
    useEffect(() => {
        if (promotionalMessage) {
            setEnPromoTitle(promoTitleEN);
            setEnPromoDescription(promoDescriptionEN);
            setEnPromoUploadedImageFileName(formatFileName(promoMediaUrlEN));
            setEnPromoViewMedia(promoMediaUrlEN);

            setBmPromoTitle(promoTitleBM);
            setBmPromoDescription(promoDescriptionBM);
            setBmPromoUploadedImageFileName(formatFileName(promoMediaUrlBM));
            setBmPromoViewMedia(promoMediaUrlBM);
        }
        if (welcomeMessage) {
            setEnWmTitle(WmTitleEN);
            setEnWmDescription(WmDescriptionEN);

            setBmWmTitle(WmTitleBM);
            setBmWmDescription(WmDescriptionBM);
        }
        if (CTAButtonDetail) {
            setEnCTATitle(CTATitleEN);
            setEnCTAMessage(CTAMessageEN);
            setEnCTAText(CTATextEN);
            setEnCTALink(CTALinkEN);
            setEnCTAUploadedImageFileName(formatFileName(CTAMediaUrlEN));
            setEnCTAViewMedia(CTAMediaUrlEN);

            setBmCTATitle(CTATitleBM);
            setBmCTAMessage(CTAMessageBM);
            setBmCTAText(CTATextBM);
            setBmCTALink(CTALinkBM);
            setBmCTAUploadedImageFileName(formatFileName(CTAMediaUrlBM));
            setBmCTAViewMedia(CTAMediaUrlBM);
        }
    }, [promotionalMessage, welcomeMessage, CTAButtonDetail]);

    // ! functions
    const formatFileName = (url: string) => {
        if (url) {
            const { pathname } = new URL(url as string);
            const fileName = pathname.substring(pathname.lastIndexOf('/') + 1);
            return fileName;
        }
        return '';
    };
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, language: LanguageEnum, type: MessageCategoryEnum) => {
        const { value } = e.target;
        if (language === LanguageEnum.EN) {
            if (type === MessageCategoryEnum.PromoTitle) {
                setEnPromoTitle(value);
            } else if (type === MessageCategoryEnum.PromoDescription) {
                setEnPromoDescription(value);
            } else if (type === MessageCategoryEnum.WmTitle) {
                setEnWmTitle(value);
            } else if (type === MessageCategoryEnum.WmDescription) {
                setEnWmDescription(value);
            } else if (type === MessageCategoryEnum.CTATitle) {
                setEnCTATitle(value);
            } else if (type === MessageCategoryEnum.CTAMessage) {
                setEnCTAMessage(value);
            } else if (type === MessageCategoryEnum.CTAText) {
                setEnCTAText(value);
            } else if (type === MessageCategoryEnum.CTALink) {
                setEnCTALink(value);
            }
        } else if (language === LanguageEnum.BM) {
            if (type === MessageCategoryEnum.PromoTitle) {
                setBmPromoTitle(value);
            } else if (type === MessageCategoryEnum.PromoDescription) {
                setBmPromoDescription(value);
            } else if (type === MessageCategoryEnum.WmTitle) {
                setBmWmTitle(value);
            } else if (type === MessageCategoryEnum.WmDescription) {
                setBmWmDescription(value);
            } else if (type === MessageCategoryEnum.CTATitle) {
                setBmCTATitle(value);
            } else if (type === MessageCategoryEnum.CTAMessage) {
                setBmCTAMessage(value);
            } else if (type === MessageCategoryEnum.CTAText) {
                setBmCTAText(value);
            } else if (type === MessageCategoryEnum.CTALink) {
                setBmCTALink(value);
            }
        }
    };
    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>, language: LanguageEnum, category: MessageCategoryEnum) => {
        const fileUploaded = event.target.files?.[0];

        if (!fileUploaded) {
            return;
        }

        const { type, size, name } = fileUploaded;
        const documentExtension = {
            'image/jpg': '.jpg',
            'image/jpeg': '.jpeg',
            'image/png': '.png',
        }[type];

        if (!documentExtension) {
            event.target.value = ''; // Reset the file input field
            if (language === LanguageEnum.EN) {
                setEnPromoUploadedImageFile(undefined);
                setEnCTAUploadedImageFile(undefined);
                if (!promoMediaUrlEN || !CTAMediaUrlEN) {
                    setEnPromoUploadedImageFileName('');
                    setEnPromoViewMedia('');
                    setEnCTAUploadedImageFileName('');
                    setEnCTAViewMedia('');
                }
            } else if (language === LanguageEnum.BM) {
                setBmPromoUploadedImageFile(undefined);
                setBmCTAUploadedImageFile(undefined);
                if (!promoMediaUrlBM || !CTAMediaUrlBM) {
                    setBmPromoUploadedImageFileName('');
                    setBmPromoViewMedia('');
                    setBmCTAUploadedImageFileName('');
                    setBmCTAViewMedia('');
                }
            }
            toast.error('Unsupported file type!');
            return;
        }

        if (size > 5242880) {
            event.target.value = '';
            if (language === LanguageEnum.EN) {
                setEnPromoUploadedImageFile(undefined);
                setEnCTAUploadedImageFile(undefined);
                if (!promoMediaUrlEN || !CTAMediaUrlEN) {
                    setEnPromoUploadedImageFileName('');
                    setEnPromoViewMedia('');
                    setEnCTAUploadedImageFileName('');
                    setEnCTAViewMedia('');
                }
            } else if (language === LanguageEnum.BM) {
                setBmPromoUploadedImageFile(undefined);
                setBmCTAUploadedImageFile(undefined);
                if (!promoMediaUrlBM || !CTAMediaUrlBM) {
                    setBmPromoUploadedImageFileName('');
                    setBmPromoViewMedia('');
                    setBmCTAUploadedImageFileName('');
                    setBmCTAViewMedia('');
                }
            }
            toast.error('Limit size for image file is 5mb');
            return;
        }

        if (language === LanguageEnum.EN) {
            if (category === MessageCategoryEnum.PromotionalMessage) {
                setEnPromoUploadedImageFile(fileUploaded);
                setEnPromoUploadedImageFileName(name);
                setEnPromoViewMedia(URL.createObjectURL(fileUploaded));
            } else if (category === MessageCategoryEnum.CTAButtonDetails) {
                setEnCTAUploadedImageFile(fileUploaded);
                setEnCTAUploadedImageFileName(name);
                setEnCTAViewMedia(URL.createObjectURL(fileUploaded));
            }
        } else if (language === LanguageEnum.BM) {
            if (category === MessageCategoryEnum.PromotionalMessage) {
                setBmPromoUploadedImageFile(fileUploaded);
                setBmPromoUploadedImageFileName(name);
                setBmPromoViewMedia(URL.createObjectURL(fileUploaded));
            } else if (category === MessageCategoryEnum.CTAButtonDetails) {
                setBmCTAUploadedImageFile(fileUploaded);
                setBmCTAUploadedImageFileName(name);
                setBmCTAViewMedia(URL.createObjectURL(fileUploaded));
            }
        }

        toast.success('File uploaded!');
    };
    const resetImage = (language: LanguageEnum) => {
        if (language === LanguageEnum.EN) {
            setEnPromoUploadedImageFile(undefined);
            setEnPromoUploadedImageFileName('');
            setEnPromoViewMedia('');
            setEnCTAUploadedImageFile(undefined);
            setEnCTAUploadedImageFileName('');
            setEnCTAViewMedia('');
        } else if (language === LanguageEnum.BM) {
            setBmPromoUploadedImageFile(undefined);
            setBmPromoUploadedImageFileName('');
            setBmPromoViewMedia('');
            setBmCTAUploadedImageFile(undefined);
            setBmCTAUploadedImageFileName('');
            setBmCTAViewMedia('');
        }
    };
    const handleResetButton = (category:MessageCategoryEnum) => {
        if (category === MessageCategoryEnum.PromotionalMessage) {
            setEnPromoTitle(promoTitleEN);
            setEnPromoDescription(promoDescriptionEN);
            setEnPromoViewMedia(promoMediaUrlEN);
            setEnPromoUploadedImageFile(undefined);
            setEnPromoUploadedImageFileName(formatFileName(promoMediaUrlEN));

            setBmPromoTitle(promoTitleBM);
            setBmPromoDescription(promoDescriptionBM);
            setBmPromoViewMedia(promoMediaUrlBM);
            setBmPromoUploadedImageFile(undefined);
            setBmPromoUploadedImageFileName(formatFileName(promoMediaUrlBM));
        } else if (category === MessageCategoryEnum.WelcomeMessage) {
            setEnWmTitle(WmTitleEN);
            setEnWmDescription(WmDescriptionEN);
            setBmWmTitle(WmTitleBM);
            setBmWmDescription(WmDescriptionBM);
        } else if (category === MessageCategoryEnum.CTAButtonDetails) {
            setEnCTATitle(CTATitleEN);
            setEnCTAMessage(CTAMessageEN);
            setEnCTAText(CTATextEN);
            setEnCTALink(CTALinkEN);
            setEnCTAUploadedImageFile(undefined);
            setEnCTAUploadedImageFileName(formatFileName(CTAMediaUrlEN));
            setEnCTAViewMedia(CTAMediaUrlEN);

            setBmCTATitle(CTATitleBM);
            setBmCTAMessage(CTAMessageBM);
            setBmCTAText(CTATextBM);
            setBmCTALink(CTALinkBM);
            setBmCTAUploadedImageFile(undefined);
            setBmCTAUploadedImageFileName(formatFileName(CTAMediaUrlBM));
            setBmCTAViewMedia(CTAMediaUrlBM);
        }
    };
    const handleSaveButton = (category: MessageCategoryEnum) => {
        const setPromotionalMessageHelper = (
            title: string,
            message: string,
            file: File | undefined,
            clearImage: 'true' | 'false',
            language: LanguageEnum,
        ) => {
            setPromotionalMessage({ title, message, file, clearImage, language });
        };

        const setWelcomeMessageHelper = (title: string, message: string, language: LanguageEnum) => {
            setWelcomeMessage({ title, message, language });
        };

        const setCTAButtonDetailsHelper = (
            title: string,
            message: string,
            file: File | undefined,
            clearImage: 'true' | 'false',
            buttonText: string,
            link: string,
            language: LanguageEnum,
        ) => {
            setCTAButtonDetails({ title, message, file, clearImage, buttonText, link, language });
        };

        if (category === MessageCategoryEnum.PromotionalMessage) {
            if (enPromoUploadedImageFileName === '' && bmPromoUploadedImageFileName === '') {
                setPromotionalMessageHelper(enPromoTitle, enPromoDescription, enPromoUploadedImageFile, 'true', LanguageEnum.EN);
                setPromotionalMessageHelper(bmPromoTitle, bmPromoDescription, bmPromoUploadedImageFile, 'true', LanguageEnum.BM);
            } else if (enPromoUploadedImageFileName === '') {
                setPromotionalMessageHelper(enPromoTitle, enPromoDescription, enPromoUploadedImageFile, 'true', LanguageEnum.EN);
                setPromotionalMessageHelper(bmPromoTitle, bmPromoDescription, bmPromoUploadedImageFile, 'false', LanguageEnum.BM);
            } else if (bmPromoUploadedImageFileName === '') {
                setPromotionalMessageHelper(enPromoTitle, enPromoDescription, enPromoUploadedImageFile, 'false', LanguageEnum.EN);
                setPromotionalMessageHelper(bmPromoTitle, bmPromoDescription, bmPromoUploadedImageFile, 'true', LanguageEnum.BM);
            } else {
                setPromotionalMessageHelper(enPromoTitle, enPromoDescription, enPromoUploadedImageFile, 'false', LanguageEnum.EN);
                setPromotionalMessageHelper(bmPromoTitle, bmPromoDescription, bmPromoUploadedImageFile, 'false', LanguageEnum.BM);
            }
        } else if (category === MessageCategoryEnum.WelcomeMessage) {
            setWelcomeMessageHelper(enWmTitle, enWmDescription, LanguageEnum.EN);
            setWelcomeMessageHelper(bmWmTitle, bmWmDescription, LanguageEnum.BM);
        } else if (category === MessageCategoryEnum.CTAButtonDetails) {
            if (enCTAUploadedImageFileName === '' && bmCTAUploadedImageFileName === '') {
                setCTAButtonDetailsHelper(enCTATitle, enCTAMessage, enCTAUploadedImageFile, 'true', enCTAText, enCTALink, LanguageEnum.EN);
                setCTAButtonDetailsHelper(bmCTATitle, bmCTAMessage, bmCTAUploadedImageFile, 'true', bmCTAText, bmCTALink, LanguageEnum.BM);
            } else if (enCTAUploadedImageFileName === '') {
                setCTAButtonDetailsHelper(enCTATitle, enCTAMessage, enCTAUploadedImageFile, 'true', enCTAText, enCTALink, LanguageEnum.EN);
                setCTAButtonDetailsHelper(bmCTATitle, bmCTAMessage, bmCTAUploadedImageFile, 'false', bmCTAText, bmCTALink, LanguageEnum.BM);
            } else if (bmCTAUploadedImageFileName === '') {
                setCTAButtonDetailsHelper(enCTATitle, enCTAMessage, enCTAUploadedImageFile, 'false', enCTAText, enCTALink, LanguageEnum.EN);
                setCTAButtonDetailsHelper(bmCTATitle, bmCTAMessage, bmCTAUploadedImageFile, 'true', bmCTAText, bmCTALink, LanguageEnum.BM);
            } else {
                setCTAButtonDetailsHelper(enCTATitle, enCTAMessage, enCTAUploadedImageFile, 'false', enCTAText, enCTALink, LanguageEnum.EN);
                setCTAButtonDetailsHelper(bmCTATitle, bmCTAMessage, bmCTAUploadedImageFile, 'false', bmCTAText, bmCTALink, LanguageEnum.BM);
            }
        }
    };
    const handleViewMediaToggle = (mediaUrl: string) => {
        setToggler(!toggler);
        setLightboxMediaUrl(mediaUrl);
    };
    // ! render zone
    const renderMediaPreview = (language: LanguageEnum, category: MessageCategoryEnum) => {
        if (category === MessageCategoryEnum.PromotionalMessage) {
            const uploadedPromoImageFile = language === LanguageEnum.EN ? enPromoUploadedImageFile : bmPromoUploadedImageFile;
            const uploadedPromoImageFileName = language === LanguageEnum.EN ? enPromoUploadedImageFileName : bmPromoUploadedImageFileName;
            const uploadedPromoMediaUrl = language === LanguageEnum.EN ? promoMediaUrlEN : promoMediaUrlBM;

            if (uploadedPromoImageFile !== undefined) {
                return (
                    <ImageContainer>
                        <StyledImg src={URL.createObjectURL(uploadedPromoImageFile)} alt='uploadedPromoImage' />
                    </ImageContainer>
                );
            }

            if (uploadedPromoImageFileName === '') {
                return (
                    <ImageContainer default>
                        No media
                    </ImageContainer>
                );
            }

            if (uploadedPromoMediaUrl !== '' && uploadedPromoImageFileName !== '') {
                return (
                    <ImageContainer>
                        <StyledImg src={uploadedPromoMediaUrl} alt='uploadedImage' />
                    </ImageContainer>
                );
            }
        }

        if (category === MessageCategoryEnum.CTAButtonDetails) {
            const uploadedCTAImageFile = language === LanguageEnum.EN ? enCTAUploadedImageFile : bmCTAUploadedImageFile;
            const uploadedCTAImageFileName = language === LanguageEnum.EN ? enCTAUploadedImageFileName : bmCTAUploadedImageFileName;
            const uploadedCTAMediaUrl = language === LanguageEnum.EN ? CTAMediaUrlEN : CTAMediaUrlBM;

            if (uploadedCTAImageFile !== undefined) {
                return (
                    <ImageContainer>
                        <StyledImg src={URL.createObjectURL(uploadedCTAImageFile)} alt='uploadedCTAImage' />
                    </ImageContainer>
                );
            }

            if (uploadedCTAImageFileName === '') {
                return (
                    <ImageContainer default>
                        No media
                    </ImageContainer>
                );
            }

            if (uploadedCTAMediaUrl !== '' && uploadedCTAImageFileName !== '') {
                return (
                    <ImageContainer>
                        <StyledImg src={uploadedCTAMediaUrl} alt='uploadedImage' />
                    </ImageContainer>
                );
            }
        }
        return (
            <ImageContainer default>
                No media
            </ImageContainer>
        );
    };
    const renderReminder = (MessageType: MessageCategoryEnum) => {
        const reminderMessage = (
            <ReminderContainer>You have made some changes! Click save to save your changes.</ReminderContainer>
        );
        // ? check for Promotional Message input changes
        if (MessageType === MessageCategoryEnum.PromotionalMessage) {
            if (
                (enPromoTitle !== promoTitleEN || enPromoDescription !== promoDescriptionEN)
            || (bmPromoTitle !== promoTitleBM || bmPromoDescription !== promoDescriptionBM)
            || (enPromoUploadedImageFileName !== formatFileName(promoMediaUrlEN))
            || (bmPromoUploadedImageFileName !== formatFileName(promoMediaUrlBM))
            ) {
                return reminderMessage;
            }
        }
        // ? check for Welcome Message input changes
        if (MessageType === MessageCategoryEnum.WelcomeMessage) {
            if (
                (enWmTitle !== WmTitleEN || enWmDescription !== WmDescriptionEN)
            || (bmWmTitle !== WmTitleBM || bmWmDescription !== WmDescriptionBM)
            ) {
                return reminderMessage;
            }
        }
        // ? check for CTA Message input changes
        if (MessageType === MessageCategoryEnum.CTAButtonDetails) {
            if (
                (enCTATitle !== CTATitleEN || enCTAMessage !== CTAMessageEN || enCTAText !== CTATextEN || enCTALink !== CTALinkEN)
            || (bmCTATitle !== CTATitleBM || bmCTAMessage !== CTAMessageBM || bmCTAText !== CTATextBM || bmCTALink !== CTALinkBM)
            || (enCTAUploadedImageFileName !== formatFileName(CTAMediaUrlEN))
            || (bmCTAUploadedImageFileName !== formatFileName(CTAMediaUrlBM))
            ) {
                return reminderMessage;
            }
        }
        return null;
    };

    // * main render
    return (
        <MainWrapper>
            <MainContent>
                <Title label='E-Commerce' css={styles.mainTitle} />
                <ContentContainer>
                    <ButtonContainer>
                        <StyledTitle>Product Categories</StyledTitle>
                        <CreateCategoryModal />
                        <StyledActionButton
                            type='button'
                            css={styles.primary}
                            onClick={() => setCreateCategoryModalIsOpen(true)}
                        >
                            Create New Product Category
                        </StyledActionButton>
                    </ButtonContainer>
                    <ListTable
                        tableType={TableTypeEnum.Categories}
                        tableData={productCategory}
                    />
                </ContentContainer>
                <ContentContainer>
                    <StyledTitle>Promotional Message</StyledTitle>
                    <Content>
                        <CollapsibleCard
                            index='1.0'
                            title='English'
                            titleContent={enPromoTitle}
                            descriptionContent={enPromoDescription}
                            handleTitleChange={(e) => handleInputChange(e, LanguageEnum.EN, MessageCategoryEnum.PromoTitle)}
                            handleDescriptionChange={(e) => handleInputChange(e, LanguageEnum.EN, MessageCategoryEnum.PromoDescription)}
                            isOpenByDefault
                            mediaPreview
                        >
                            <MediaContainer>
                                <StyledText style={{ color: '#A2A2A2' }}>
                                    Upload Media (Optional)
                                </StyledText>
                                <StyledUploadImageWrapper>
                                    <StyledLabelWrapper htmlFor='imageFileInputPromoEn' style={{ justifyContent: 'center', gap: '5%' }}>
                                        {(enPromoUploadedImageFile || enPromoUploadedImageFileName) ? (
                                            <StyledText style={{ fontWeight: '500', color: '#00D1D2', fontSize: '16px', paddingTop: '1.5px', paddingBottom: '1.5px', fontStyle: 'italic' }}>
                                                {enPromoUploadedImageFileName}
                                            </StyledText>
                                        ) : (
                                            <>
                                                <StyledText style={{ fontWeight: '500' }}>Image</StyledText>
                                                <img src={Icons.UploadImage} alt='uploadImage' style={{ width: '20px' }} />
                                            </>
                                        )}
                                        <input
                                            id='imageFileInputPromoEn'
                                            type='file'
                                            name='file'
                                            style={{ display: 'none' }}
                                            accept='.jpeg, .jpg, .png'
                                            onChange={(e) => {
                                                handleImageChange(e, LanguageEnum.EN, MessageCategoryEnum.PromotionalMessage);
                                            }}
                                        />
                                    </StyledLabelWrapper>
                                    {enPromoViewMedia
                                        ? (
                                            <StyledButton
                                                onClick={() => handleViewMediaToggle(enPromoViewMedia)}
                                                style={{ width: '25px', padding: '0', justifyContent: 'center' }}
                                            >
                                                <SVG
                                                    style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        fill: '#00D1D2',
                                                        strokeWidth: '0.1px',
                                                    }}
                                                    src={Icons.ViewImage}
                                                    id='viewImageIconCTAEn'
                                                />
                                            </StyledButton>
                                        ) : null}
                                    {enPromoUploadedImageFileName && (
                                        <StyledButton onClick={() => resetImage(LanguageEnum.EN)} style={{ fontSize: '16px' }}>Reset</StyledButton>
                                    )}
                                </StyledUploadImageWrapper>
                                <StyledText style={{ color: '#A2A2A2', marginTop: '30px' }}>
                                    Media Preview
                                </StyledText>
                                {renderMediaPreview(LanguageEnum.EN, MessageCategoryEnum.PromotionalMessage)}
                            </MediaContainer>
                        </CollapsibleCard>
                        <CollapsibleCard
                            index='2.0'
                            title='Bahasa Melayu'
                            titleContent={bmPromoTitle}
                            descriptionContent={bmPromoDescription}
                            handleTitleChange={(e) => handleInputChange(e, LanguageEnum.BM, MessageCategoryEnum.PromoTitle)}
                            handleDescriptionChange={(e) => handleInputChange(e, LanguageEnum.BM, MessageCategoryEnum.PromoDescription)}
                            mediaPreview
                        >
                            <MediaContainer>
                                <StyledText style={{ color: '#A2A2A2' }}>
                                    Upload Media (Optional)
                                </StyledText>
                                <StyledUploadImageWrapper>
                                    <StyledLabelWrapper htmlFor='imageFileInputPromoBm' style={{ justifyContent: 'center', gap: '5%' }}>
                                        {(bmPromoUploadedImageFile || bmPromoUploadedImageFileName) ? (
                                            <StyledText style={{ fontWeight: '500', color: '#00D1D2', fontSize: '16px', paddingTop: '1.5px', paddingBottom: '1.5px', fontStyle: 'italic' }}>
                                                {bmPromoUploadedImageFileName}
                                            </StyledText>
                                        ) : (
                                            <>
                                                <StyledText style={{ fontWeight: '500' }}>Image</StyledText>
                                                <img src={Icons.UploadImage} alt='uploadImage' style={{ width: '20px' }} />
                                            </>
                                        )}
                                        <input
                                            id='imageFileInputPromoBm'
                                            type='file'
                                            name='file'
                                            style={{ display: 'none' }}
                                            accept='.jpeg, .jpg, .png'
                                            onChange={(e) => handleImageChange(e, LanguageEnum.BM, MessageCategoryEnum.PromotionalMessage)}
                                        />
                                    </StyledLabelWrapper>
                                    {bmPromoViewMedia
                                        ? (
                                            <StyledButton
                                                onClick={() => handleViewMediaToggle(bmPromoViewMedia)}
                                                style={{ width: '25px', padding: '0', justifyContent: 'center' }}
                                            >
                                                <SVG
                                                    style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        fill: '#00D1D2',
                                                        strokeWidth: '0.1px',
                                                    }}
                                                    src={Icons.ViewImage}
                                                    id='viewImageIconCTABm'
                                                />
                                            </StyledButton>
                                        ) : null }
                                    {bmPromoUploadedImageFileName && (
                                        <StyledButton onClick={() => resetImage(LanguageEnum.BM)} style={{ fontSize: '16px' }}>Reset</StyledButton>
                                    )}
                                </StyledUploadImageWrapper>
                                <StyledText style={{ color: '#A2A2A2', marginTop: '30px' }}>
                                    Media Preview
                                </StyledText>
                                {renderMediaPreview(LanguageEnum.BM, MessageCategoryEnum.PromotionalMessage)}
                            </MediaContainer>
                        </CollapsibleCard>
                    </Content>
                    <div style={{ marginTop: '20px' }}>
                        {renderReminder(MessageCategoryEnum.PromotionalMessage)}
                        <ButtonWrapper>
                            <StyledActionButton
                                type='button'
                                css={styles.primary}
                                onClick={() => handleSaveButton(MessageCategoryEnum.PromotionalMessage)}
                            >
                                Save
                            </StyledActionButton>
                            <StyledActionButton
                                type='button'
                                css={styles.secondary}
                                onClick={() => handleResetButton(MessageCategoryEnum.PromotionalMessage)}
                            >
                                Reset
                            </StyledActionButton>
                        </ButtonWrapper>
                    </div>
                </ContentContainer>
                <ContentContainer>
                    <StyledTitle>Message to be sent after promotional message</StyledTitle>
                    <Content>
                        <CollapsibleCard
                            index='1.0'
                            title='English'
                            titleContent={enWmTitle}
                            descriptionContent={enWmDescription}
                            handleTitleChange={(e) => handleInputChange(e, LanguageEnum.EN, MessageCategoryEnum.WmTitle)}
                            handleDescriptionChange={(e) => handleInputChange(e, LanguageEnum.EN, MessageCategoryEnum.WmDescription)}
                            isOpenByDefault
                        />
                        <CollapsibleCard
                            index='2.0'
                            title='Bahasa Melayu'
                            titleContent={bmWmTitle}
                            descriptionContent={bmWmDescription}
                            handleTitleChange={(e) => handleInputChange(e, LanguageEnum.BM, MessageCategoryEnum.WmTitle)}
                            handleDescriptionChange={(e) => handleInputChange(e, LanguageEnum.BM, MessageCategoryEnum.WmDescription)}
                        />
                    </Content>
                    <div style={{ marginTop: '20px' }}>
                        {renderReminder(MessageCategoryEnum.WelcomeMessage)}
                        <ButtonWrapper>
                            <StyledActionButton
                                type='button'
                                css={styles.primary}
                                onClick={() => handleSaveButton(MessageCategoryEnum.WelcomeMessage)}
                            >
                                Save
                            </StyledActionButton>
                            <StyledActionButton
                                type='button'
                                css={styles.secondary}
                                onClick={() => handleResetButton(MessageCategoryEnum.WelcomeMessage)}
                            >
                                Reset
                            </StyledActionButton>
                        </ButtonWrapper>
                    </div>
                </ContentContainer>
                <ContentContainer>
                    <StyledTitle>Additional CTA</StyledTitle>
                    <Content>
                        <CollapsibleCard
                            index='1.0'
                            title='English'
                            titleContent={enCTATitle}
                            descriptionContent={enCTAMessage}
                            textContent={enCTAText}
                            linkContent={enCTALink}
                            handleTitleChange={(e) => handleInputChange(e, LanguageEnum.EN, MessageCategoryEnum.CTATitle)}
                            handleDescriptionChange={(e) => handleInputChange(e, LanguageEnum.EN, MessageCategoryEnum.CTAMessage)}
                            handleTextChange={(e) => handleInputChange(e, LanguageEnum.EN, MessageCategoryEnum.CTAText)}
                            handleLinkChange={(e) => handleInputChange(e, LanguageEnum.EN, MessageCategoryEnum.CTALink)}
                            renderButtonText
                            renderLink
                            isOpenByDefault
                            mediaPreview
                        >
                            <MediaContainer>
                                <StyledText style={{ color: '#A2A2A2' }}>
                                    Upload Media (Optional)
                                </StyledText>
                                <StyledUploadImageWrapper>
                                    <StyledLabelWrapper htmlFor='imageFileInputCTAEn' style={{ justifyContent: 'center', gap: '5%' }}>
                                        {(enCTAUploadedImageFile || enCTAUploadedImageFileName) ? (
                                            <StyledText style={{ fontWeight: '500', color: '#00D1D2', fontSize: '16px', paddingTop: '1.5px', paddingBottom: '1.5px', fontStyle: 'italic' }}>
                                                {enCTAUploadedImageFileName}
                                            </StyledText>
                                        ) : (
                                            <>
                                                <StyledText style={{ fontWeight: '500' }}>Image</StyledText>
                                                <img src={Icons.UploadImage} alt='uploadImage' style={{ width: '20px' }} />
                                            </>
                                        )}
                                        <input
                                            id='imageFileInputCTAEn'
                                            type='file'
                                            name='file'
                                            style={{ display: 'none' }}
                                            accept='.jpeg, .jpg, .png'
                                            onChange={(e) => {
                                                handleImageChange(e, LanguageEnum.EN, MessageCategoryEnum.CTAButtonDetails);
                                            }}
                                        />
                                    </StyledLabelWrapper>
                                    {enCTAViewMedia
                                        ? (
                                            <StyledButton
                                                onClick={() => handleViewMediaToggle(enCTAViewMedia)}
                                                style={{ width: '25px', padding: '0', justifyContent: 'center' }}
                                            >
                                                <SVG
                                                    style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        fill: '#00D1D2',
                                                        strokeWidth: '0.1px',
                                                    }}
                                                    src={Icons.ViewImage}
                                                    id='viewImageIconCTAEn'
                                                />
                                            </StyledButton>
                                        ) : null}
                                    {enCTAUploadedImageFileName && (
                                        <StyledButton onClick={() => resetImage(LanguageEnum.EN)} style={{ fontSize: '16px' }}>Reset</StyledButton>
                                    )}
                                </StyledUploadImageWrapper>
                                <StyledText style={{ color: '#A2A2A2', marginTop: '30px' }}>
                                    Media Preview
                                </StyledText>
                                {renderMediaPreview(LanguageEnum.EN, MessageCategoryEnum.CTAButtonDetails)}
                            </MediaContainer>
                        </CollapsibleCard>
                        <CollapsibleCard
                            index='2.0'
                            title='Bahasa Melayu'
                            titleContent={bmCTATitle}
                            descriptionContent={bmCTAMessage}
                            textContent={bmCTAText}
                            linkContent={bmCTALink}
                            handleTitleChange={(e) => handleInputChange(e, LanguageEnum.BM, MessageCategoryEnum.CTATitle)}
                            handleDescriptionChange={(e) => handleInputChange(e, LanguageEnum.BM, MessageCategoryEnum.CTAMessage)}
                            handleTextChange={(e) => handleInputChange(e, LanguageEnum.BM, MessageCategoryEnum.CTAText)}
                            handleLinkChange={(e) => handleInputChange(e, LanguageEnum.BM, MessageCategoryEnum.CTALink)}
                            renderButtonText
                            renderLink
                            mediaPreview
                        >
                            <MediaContainer>
                                <StyledText style={{ color: '#A2A2A2' }}>
                                    Upload Media (Optional)
                                </StyledText>
                                <StyledUploadImageWrapper>
                                    <StyledLabelWrapper htmlFor='imageFileInputCTABm' style={{ justifyContent: 'center', gap: '5%' }}>
                                        {(bmCTAUploadedImageFile || bmCTAUploadedImageFileName) ? (
                                            <StyledText style={{ fontWeight: '500', color: '#00D1D2', fontSize: '16px', paddingTop: '1.5px', paddingBottom: '1.5px', fontStyle: 'italic' }}>
                                                {bmCTAUploadedImageFileName}
                                            </StyledText>
                                        ) : (
                                            <>
                                                <StyledText style={{ fontWeight: '500' }}>Image</StyledText>
                                                <img src={Icons.UploadImage} alt='uploadImage' style={{ width: '20px' }} />
                                            </>
                                        )}
                                        <input
                                            id='imageFileInputCTABm'
                                            type='file'
                                            name='file'
                                            style={{ display: 'none' }}
                                            accept='.jpeg, .jpg, .png'
                                            onChange={(e) => handleImageChange(e, LanguageEnum.BM, MessageCategoryEnum.CTAButtonDetails)}
                                        />
                                    </StyledLabelWrapper>
                                    {bmCTAViewMedia
                                        ? (
                                            <StyledButton
                                                onClick={() => handleViewMediaToggle(bmCTAViewMedia)}
                                                style={{ width: '25px', padding: '0', justifyContent: 'center' }}
                                            >
                                                <SVG
                                                    style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        fill: '#00D1D2',
                                                        strokeWidth: '0.1px',
                                                    }}
                                                    src={Icons.ViewImage}
                                                    id='viewImageIconCTABm'
                                                />
                                            </StyledButton>
                                        ) : null}
                                    {bmCTAUploadedImageFileName && (
                                        <StyledButton onClick={() => resetImage(LanguageEnum.BM)} style={{ fontSize: '16px' }}>Reset</StyledButton>
                                    )}
                                </StyledUploadImageWrapper>
                                <StyledText style={{ color: '#A2A2A2', marginTop: '30px' }}>
                                    Media Preview
                                </StyledText>
                                {renderMediaPreview(LanguageEnum.BM, MessageCategoryEnum.CTAButtonDetails)}
                            </MediaContainer>
                        </CollapsibleCard>
                    </Content>
                    <div style={{ marginTop: '20px' }}>
                        {renderReminder(MessageCategoryEnum.CTAButtonDetails)}
                        <ButtonWrapper>
                            <StyledActionButton
                                type='button'
                                css={styles.primary}
                                onClick={() => handleSaveButton(MessageCategoryEnum.CTAButtonDetails)}
                            >
                                Save
                            </StyledActionButton>
                            <StyledActionButton
                                type='button'
                                css={styles.secondary}
                                onClick={() => handleResetButton(MessageCategoryEnum.CTAButtonDetails)}
                            >
                                Reset
                            </StyledActionButton>
                        </ButtonWrapper>
                    </div>
                </ContentContainer>
                <FsLightbox
                    toggler={toggler}
                    sources={[lightboxMediaUrl]}
                />
            </MainContent>
        </MainWrapper>
    );
};

// ! styled-components
interface StyledButtonProps {
    css?: FlattenSimpleInterpolation;
}
const MainWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: space-evenly;

    background-color: #F1F6F8;
    border-radius: 20px;

    padding-top: 1%;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 1%;

    width: 71%;
    height: 100%;

    margin-bottom: 5%;
    margin-right: 7%;
`;
const MainContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    gap: 20px;
`;
const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
    /* border: 1px solid blueviolet; */
`;
const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const StyledTitle = styled.h2`
    font-size: 20px;
    color: #27242E;
    font-family: ${Fonts.primary};
    font-weight: bold;
`;
const StyledActionButton = styled.button<StyledButtonProps>`
    cursor:pointer;
    ${props => props.css}
`;
const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap:10px;
    margin-top: 20px;
`;
const MediaContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-inline: 30px;
    margin: 40px 0 20px 0;
`;
const StyledText = styled.div`
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
const StyledLabelWrapper = styled.label`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 70%;
    border: 1px solid #A2A2A2;
    border-radius: 5px;

    padding: 5px 10px;
`;
const StyledUploadImageWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    width: 100%;
`;
const StyledButton = styled.button`
    display: flex;
    align-items: center;
    border: none;
    background: transparent;
    height: 75%;
    width: 10%;
    transition: all 0.1s ease;
    font-size: 11px;

    &:hover {
        color: red;
    }

    &:active {
        color: green;
    }
`;
const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    gap: 20px;
`;
const ReminderContainer = styled.div`
    color: ${Colors.iqosError};
    font-style: italic;
`;
const ImageContainer = styled.div<{default?: boolean}>`
    width: 250px;
    max-height: 200px;

    ${props => props.default && `
        border: 1px solid ${Colors.black};
        width: 70%;
        height: 125px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    `}
`;
const StyledImg = styled.img`
width: auto;
height: auto;
max-width: 100%;
max-height: 100%;
`;
const styles = {
    mainTitle: css`
        font-size: 100px;
    `,
    primary: css`
        min-width:150px;
        color: ${Colors.white};
        background-color: ${Colors.iqosPrimary};
        font-size: 18px;
        height:46px;
        border: 1px solid ${Colors.iqosPrimary};
        font-family: ${Colors.secondary};
        border-radius: 20px;
        font-weight: 600;
        color: white;
        padding-inline: 30px;
    `,
    secondary: css`
    min-width:150px;
    color: ${Colors.primary};
    background-color: ${Colors.white};
    font-size: 18px;
    height:46px;
    border: 1px solid ${Colors.primary};
    font-family: ${Colors.secondary};
    border-radius: 20px;
    color: black;
`,
};

// ! redux toolkit
// * Selectors
const mapStateToProps = (state: RootState) => ({
    // ? Promotional Message
    promotionalMessage: Selectors.getECommerceGetPromotionalMessage(state),
    // ? Welcome Message
    welcomeMessage: Selectors.getECommerceGetWelcomeMessage(state),
    // ? Product Category
    productCategory: Selectors.getECommerceGetProductCategory(state),
    // ? Trade Button Link
    CTAButtonDetail: Selectors.getECommerceGetCTAButtonDetails(state),
});
// * Dispatch
const mapDispatchToProps = (dispatch: AppDispatch) => ({
    // ? Promotional Message
    getPromotionalMessage: () => dispatch(Actions.getPromotionalMessageAttempt()),
    setPromotionalMessage: (data: ISetPromotionalMessage) => dispatch(Actions.setPromotionalMessageAttempt(data)),
    // ? Welcome Message
    getWelcomeMessage: () => dispatch(Actions.getWelcomeMessageAttempt()),
    setWelcomeMessage: (data: ISetWelcomeMessage) => dispatch(Actions.setWelcomeMessageAttempt(data)),
    // ? Product Category
    getProductCategory: () => dispatch(Actions.getProductCategoryAttempt()),
    setCreateCategoryModalIsOpen: (state: boolean) => dispatch(Actions.setCreateCategoryModalIsOpen(state)),
    // ? Trade Button Link
    getCTAButtonDetails: () => dispatch(Actions.getCTAButtonDetailsAttempt()),
    setCTAButtonDetails: (data: ISetCTAButtonDetails) => dispatch(Actions.setCTAButtonDetailsAttempt(data)),
});

// * Connection
export default connect(mapStateToProps, mapDispatchToProps)(ECommerce);
