import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Spinner } from 'reactstrap';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Label, ResponsiveContainer, Text } from 'recharts';

import { connect } from 'react-redux';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { AppDispatch, RootState } from 'redux/store';

import 'components/StyledComponent.css';
import TextComponent from 'components/Text';
import MonthPicker from 'components/MonthPicker';
import { IChannelTypeEnum } from 'entities/contests';
import { PostsOverview } from 'entities/posts';
import Fonts from 'assets/themes/Fonts';
import { format } from 'date-fns';

interface PostsOverviewProps {
    isLanu: boolean;
    postOverviewData: PostsOverview;
    isLoading: boolean;
    isFailure: string;

    getPostsOverview(channelType: string, month: string): void;
}

const CustomXAxisTick = ({ x, y, payload }: any) => {
    const renderPayloadValue = (date: string) => {
        switch (date) {
            case 'January': return 'Jan';
            case 'February': return 'Feb';
            case 'March': return 'Mar';
            case 'April': return 'Apr';
            case 'May': return 'May';
            case 'June': return 'Jun';
            case 'July': return 'Jul';
            case 'August': return 'Aug';
            case 'September': return 'Sep';
            case 'October': return 'Oct';
            case 'November': return 'Nov';
            case 'December': return 'Dec';

            default: return '';
        }
    };

    if (payload && payload.value) {
        return (
            <Text
                fontSize='14px'
                width='12px'
                x={x - 20}
                y={y}
                textAnchor='middle'
                verticalAnchor='middle'
            >
                {renderPayloadValue(payload.value)}
            </Text>
        );
    }
    return null;
};

const PostsOverviewTab: FunctionComponent<PostsOverviewProps> = (props: PostsOverviewProps) => {
    const {
        isLanu,
        postOverviewData,
        isLoading,
        isFailure,

        getPostsOverview,
    } = props;

    const [getMonth, setGetMonth] = useState<number>((dayjs().month()) + 1);
    const [getYear, setGetYear] = useState<number>(dayjs().year());

    const dayjsMonthValue = dayjs().month(getMonth - 1).toDate();
    const dayjsToFNSdate = format(dayjsMonthValue, 'MM');

    useEffect(() => {
        getPostsOverview(`${(isLanu) ? IChannelTypeEnum.LANU : IChannelTypeEnum.LAU}`, `${dayjsToFNSdate}/${dayjs(getYear.toString()).format('YYYY')}`);
    }, [isLanu, getMonth, getYear]);

    const colors: string[] = [];

    for (let i = 1; i <= postOverviewData.months.length; i += 1) {
        if (i % 2 === 0) {
            colors.push('#DB3624');
        } else {
            colors.push('#00D1D2');
        }
    }

    const postsOverviewGraph = () => {
        if (isLoading) {
            return (
                <div style={{ textAlign: 'center' }}>
                    <Spinner />
                </div>
            );
        }

        if (isFailure.length) {
            return (
                <div style={{ textAlign: 'center' }}>
                    <TextComponent style={{ color: 'red' }}>Something went wrong. Please try again.</TextComponent>
                </div>
            );
        }

        return (
            <ResponsiveContainer width='99%' height={400}>
                <BarChart
                    width={600}
                    height={390}
                    layout='vertical'
                    data={postOverviewData.months}
                    barSize={30}
                    margin={{
                        top: 42,
                        right: 100,
                        left: 20,
                        bottom: 20,
                    }}
                >
                    <CartesianGrid strokeDasharray='none' vertical={false} horizontal={false} />
                    <XAxis tickLine={false} type='number' fontSize='13px'>
                        <Label value='Posts' offset={30} position='right' />
                    </XAxis>
                    <YAxis tickLine={false} type='category' fontSize='13px' dataKey='month' tick={<CustomXAxisTick />}>
                        <Label value='Month' offset={30} position='top' />
                    </YAxis>
                    <Tooltip />
                    <Bar
                        name='Total Posts'
                        dataKey='posts'
                    >
                        {postOverviewData.months.map((entry, index) => (
                            <Cell key={Math.random()} fill={colors[index % 20]} />
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        );
    };

    return (
        <TabBodyWrapper>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    padding: '50px 20px 5px 20px',
                }}
            >
                <StyledText style={{ fontFamily: `${Fonts.secondary}`, fontSize: '22px' }}>
                    Total Posts Sent In Total
                </StyledText>
                <MonthPicker
                    month={getMonth}
                    year={getYear}
                    setMonth={setGetMonth}
                    setYear={setGetYear}
                />
            </div>
            <StyledOverview>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
                    <OverviewWrapper>
                        <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between', gap: '10px' }}>
                            <StyledText style={{ color: 'white', fontFamily: `${Fonts.secondary}` }}>
                                {`Total Post Sent ( ${dayjs(getMonth.toString()).format('MMMM')} )`}
                            </StyledText>
                            <StyledText style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'baseline', gap: '10px', color: 'white', fontFamily: `${Fonts.secondary}`, fontSize: '60px' }}>
                                {postOverviewData.totalPostsMonth}
                                <StyledText style={{ color: 'grey' }}>
                                    posts
                                </StyledText>
                            </StyledText>
                        </div>
                    </OverviewWrapper>

                    <OverviewWrapper>
                        <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between', gap: '10px' }}>
                            <StyledText style={{ color: 'white', fontFamily: `${Fonts.secondary}` }}>
                                Total Post Sent ( Year To Date )
                            </StyledText>
                            <StyledText style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'baseline', gap: '10px', color: 'white', fontFamily: `${Fonts.secondary}`, fontSize: '60px' }}>
                                {postOverviewData.totalPostsYtd}
                                <StyledText style={{ color: 'grey' }}>
                                    posts
                                </StyledText>
                            </StyledText>
                        </div>
                    </OverviewWrapper>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100%', width: '100%', backgroundColor: 'white', justifyContent: 'center', borderRadius: '10px' }}>
                    {postsOverviewGraph()}
                </div>
            </StyledOverview>
        </TabBodyWrapper>
    );
};

const TabBodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%; 
`;

const StyledText = styled(TextComponent)`
    text-align: left;
    font-size: 18px;
`;

const StyledOverview = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3%;

    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 3%;
    margin-top: 1%;

    width: 100%;
    align-items: center;
`;

const OverviewWrapper = styled.div`
    background-color: #003333;
    min-height: 203px;
    border-radius: 15px;
    padding: 35px 0px 20px 45px;
`;

const mapStateToProps = (state: RootState) => ({
    isLanu: Selectors.getFaqGetIsLANU(state),
    postOverviewData: Selectors.getPostsGetPostsOverview(state),
    isLoading: Selectors.getPostsGetPostsOverviewAttempting(state),
    isFailure: Selectors.getPostsGetPostsOverviewFailure(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getPostsOverview: (channelType: string, month: string) => dispatch(Actions.getPostsOverviewAttempt({ channelType, month })),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostsOverviewTab);
