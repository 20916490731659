import { put, call, select, takeEvery } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import PostsGateway from 'api/Posts';
import { RateByPostingTimeExportPayloadParams } from 'redux/slices/posts/types';
import xlsx, { IContent } from 'json-as-xlsx';
import Utils from 'lib/Utils';
import dayjs from 'dayjs';

export default function* watchGetRateByPostingTimeExport(api: PostsGateway): SagaWatcherReturnType {
    yield takeEvery('posts/getRateByPostingTimeExportAttempt', handleRateByPostingTimeExport, api);
}

function* handleRateByPostingTimeExport(api: PostsGateway, data: RateByPostingTimeExportPayloadParams) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.getRateByPostingTimeExport], {
        authToken,
        hour: data.payload.hour,
        channelType: data.payload.channelType,
        dateFrom: data.payload.dateFrom,
        dateTo: data.payload.dateTo,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.getRateByPostingTimeExportFailure(response.message || ''));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        const { data: postsResponse } = response;

        const massageData = postsResponse.map((item) => {
            return {
                ...item,
                date: dayjs(item.date).format('DD-MMM-YYYY'),
                viewRate: (item.viewRate !== undefined) ? Utils.Posts.rateConverter(item.viewRate) : '-',
                engagementRate: (item.engagementRate !== undefined) ? Utils.Posts.rateConverter(item.engagementRate) : '-',
            };
        });

        interface StringDictionary {
            [key: string]: any;
        }

        const excelColumns = [{
            label: 'Post ID',
            value: 'postId',
        }, {
            label: 'Title',
            value: 'title',
        }, {
            label: 'Media Url',
            value: 'mediaUrl',
        }, {
            label: 'Date',
            value: 'date',
        }, {
            label: 'Views',
            value: 'views',
        }, {
            label: 'View Rate ( % )',
            value: 'viewRate',
        }, {
            label: 'Engagement',
            value: 'engagement',
        }, {
            label: 'Engagement Rate ( % )',
            value: 'engagementRate',
        }];

        let rateByPostingTimeDataToExport: IContent[] = [];
        rateByPostingTimeDataToExport = massageData as unknown as StringDictionary[];

        const rateByPostingTimeToDownload = [{
            sheet: 'Post Data',
            columns: excelColumns,
            content: rateByPostingTimeDataToExport,
        }];

        xlsx(rateByPostingTimeToDownload, { fileName: `Rate By Posting Time From ${Utils.Posts.hourNumberToStringConverter(data.payload.hour)}` });

        yield put(Actions.getRateByPostingTimeExportSuccess(response.data));
    }
}
