import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { useParams } from 'react-router';

import SVG from 'react-inlinesvg';
import { connect } from 'react-redux';

import { ECommClicksProductReportData } from 'entities/ecommerceAnalytics';

import AnalyticsContainer from 'components/analytics/common/AnalyticsContainer';
import AnalyticsContainerHeader from 'components/analytics/common/AnalyticsContainerHeader';
import Title from 'components/Title';
import Text from 'components/Text';
import Subtitle from 'components/Subtitle';
import StatCard from 'components/summaryDashboard/StatCardPercentage';
import Loading from 'components/analytics/common/Loading';
import Error from 'components/analytics/common/Error';
import Icons from 'assets/icons/Index';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import NavActions from 'lib/NavActions';
import Fonts from 'assets/themes/Fonts';
import DatePicker from 'components/analytics/common/DatePicker';
import ClicksProductVariantReport from './ClicksProductVariantReport';

interface ClicksProductReportProps {
    loading: boolean;
    error: string | null;

    selectedDates: { dateFrom: Date, dateTo: Date };
    data: ECommClicksProductReportData | null;

    setDatePicker: (dateFrom: Date, dateTo: Date) => void;
    getClicksProductReportData: (id: string) => void;
}

const ClicksProductReport = (props: ClicksProductReportProps): JSX.Element => {
    const { loading, error, selectedDates, setDatePicker, data, getClicksProductReportData } = props;

    const { id: productCategoryId } = useParams();
    const { dateTo, dateFrom } = selectedDates;

    const [selectedProductId, setSelectedProductId] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (productCategoryId) getClicksProductReportData(productCategoryId);
    }, [dateFrom, dateTo]);

    const renderData = () => {
        if (loading) {
            return <Loading />;
        }

        if (error || !data) {
            return <Error errorMessage={error} />;
        }

        const { productCategoryName, products } = data;

        return (
            <Col>
                <TitleRow>
                    <Title label={productCategoryName} />

                    <BackButton onClick={NavActions.navToEcommerceAnalyticsClicksReport}>
                        <SVG src={Icons.ChevronLeft} id='clockIcon' style={{ height: 40, fill: '#27242E', marginRight: 12 }} />
                        <Text style={{ fontFamily: Fonts.secondary }}>Back to Main Page</Text>
                    </BackButton>
                </TitleRow>

                <SubtitleRow>
                    <Subtitle>Number of Clicks Based on Products</Subtitle>

                    <DatePicker
                        dateFrom={dateFrom}
                        dateTo={dateTo}
                        onChange={setDatePicker}
                    />
                </SubtitleRow>

                <GridWrapper>
                    {products.map((item) => (
                        <StatCard
                            key={item.id}
                            id={item.id}
                            label={item.name}
                            current={item.clicks}
                            subtitle={`${item.uniqueClicks} unique clicks`}
                            onClick={setSelectedProductId}
                            isYtd={false}
                        />
                    ))}
                </GridWrapper>

                <ClicksProductVariantReport
                    productId={selectedProductId}
                    productName={products.find((item) => item.id === selectedProductId)?.name}
                />
            </Col>
        );
    };

    return (
        <AnalyticsContainer>
            <AnalyticsContainerHeader>
                {renderData()}
            </AnalyticsContainerHeader>
        </AnalyticsContainer>
    );
};

const Col = styled.div`
    display: flex;
    flex-direction: column;

    justify-content: flex-start;
    align-items: flex-start;

    flex: 1;

    gap: 20px;
`;

const SubtitleRow = styled.div`
    display: flex;
    flex-direction: row;

    width: 100%;

    align-items: center;
    justify-content: space-between;
`;

const TitleRow = styled.div`
    display: flex;
    flex-direction: row;

    width: 100%;

    align-items: center;
`;

const BackButton = styled.button`
    height: 46px;
    width: 220px;
    margin-top: 1%; // <Title> has a built in margin, I need to match it here

    border-radius: 30px;
    border: 1px solid #A2A2A2;
    background-color: white;

    margin-left: 12px;

    display: flex;
    flex-direction: row;
    
    justify-content: center;
    align-items: center;
`;

const GridWrapper = styled.div`
    display: grid;
    grid-template-columns: auto auto auto;
    
    gap: 20px;
    width: 100%;
`;

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getECommerceAnalClicksProductReportAttempting(state),
    error: Selectors.getECommerceAnalClicksProductReportFailure(state),

    selectedDates: Selectors.getECommerceAnalDatePicker(state),
    data: Selectors.getECommerceAnalClicksProductReportData(state),
});

const mapDispatchToProps = (dispatch:AppDispatch) => ({
    setDatePicker: (dateFrom: Date, dateTo: Date) => dispatch(Actions.ecommAnalSetDatePicker({ dateFrom, dateTo })),
    getClicksProductReportData: (productCategoryId: string) => dispatch(Actions.ecommAnalGetClicksProductReportAttempt({ productCategoryId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClicksProductReport);
