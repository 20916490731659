import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import styled from 'styled-components';

interface IconTooltipProps {
    tooltipId: string;
    iconSrc: string;
    bgColor?: string;
    fontColor?: string;
    size?: string;
    maxWidth?: string;
}

const IconTooltip:React.FunctionComponent<IconTooltipProps> = ({
    tooltipId,
    iconSrc,
    bgColor,
    fontColor,
    size,
    children,
    maxWidth,
}) => {
    return (
        <>
            <SVGWrapper
                src={iconSrc}
                alt='tooltip'
                id={tooltipId}
                size={size}
                bgColor={bgColor}
            />
            <StyledTooltip
                placement='right'
                target={tooltipId}
                fontColor={fontColor}
                bgColor={bgColor}
                maxWidth={maxWidth}
            >
                {children}
            </StyledTooltip>
        </>
    );
};

interface SVGWrapperProps {
    bgColor?: string;
    size?: string;
}

const SVGWrapper = styled.img<SVGWrapperProps>`
    width: 14px;
    height: ${props => props.size}; 
    width: ${props => props.size};
    border: 1px solid ${props => props.bgColor};
    border-radius: 60px;
    padding: 2px;
    background-color: ${props => props.bgColor};
`;

interface StyledTooltipProps {
    bgColor?: string;
    fontColor?: string;
    maxWidth?: string;
}

const StyledTooltip = styled(UncontrolledTooltip)<StyledTooltipProps>`

    font-size: 10px; 
    text-align: justify; 
    padding: 10px;

    .tooltip-inner {
        max-width: ${props => props.maxWidth} !important;
        color: ${props => props.fontColor};
        background-color: ${props => props.bgColor} ;
    }

    & > .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, 
    & > .bs-tooltip-end .tooltip-arrow::before {
        border-right-color: ${props => props.bgColor} ;
    }

`;

// IconTooltip.defaultProps = {
//     bgColor: Colors.iqosPrimary,
//     fontColor: Colors.primary,
//     size: '20px',
// };

export default IconTooltip;
