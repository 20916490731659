import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import BroadcastGateway from 'api/Broadcast';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { toast } from 'react-toastify';
import { GetHistoryDetailsParams } from 'redux/slices/broadcast/types';

export default function* watchGetHistoryDetailsStatus(api: BroadcastGateway): SagaWatcherReturnType {
    yield takeEvery('broadcast/getHistoryDetailsAttempt', handleGetHistoryDetails, api);
}

function* handleGetHistoryDetails(api: BroadcastGateway, data: GetHistoryDetailsParams) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.getHistoryDetails], {
        authToken,
        id: data.payload.id,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.getHistoryDetailsFailure(response.message || ''));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.getHistoryDetailsSuccess(response.data));
    }
}
