import { IPNCMessageDetails } from 'entities/PNCMessage';
import { PNCMessageState } from '.';

const getPNCMessageAttempt = (state: PNCMessageState): boolean => state.actions.getPNCMessage || false;
const getPNCMessageFailure = (state: PNCMessageState): string => state.error.getPNCMessage || '';
const getPNCMessageSuccess = (state: PNCMessageState): IPNCMessageDetails => state.PNCMessage;

const editPNCMessageAttempt = (state: PNCMessageState): boolean => state.actions.setPNCMessage || false;
const editPNCMessageFailure = (state: PNCMessageState): string => state.error.setPNCMessage || '';

export default {
    getPNCMessageAttempt,
    getPNCMessageFailure,
    getPNCMessageSuccess,

    editPNCMessageAttempt,
    editPNCMessageFailure,
};
