import React, { FunctionComponent, useEffect, useState } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { Modal, ModalBody, Spinner } from 'reactstrap';
import SVG from 'react-inlinesvg';
import FsLightbox from 'fslightbox-react';
import dayjs from 'dayjs';

import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';
import { connect } from 'react-redux';
import Actions from 'redux/Actions';

import TextArea from 'components/TextArea';
import ButtonText from 'components/ButtonText';
import Icons from 'assets/icons/Index';

import 'components/StyledComponent.css';

import {
    BroadcastTypesEnum,
    ICreateBroadcastPost,
    RecipientListEnum,
    HistoryDetails,
    BroadcastHistoryPost,
} from 'entities/broadcast';

interface BroadcastDetailsModalProps {
    setBroadcastDetailsModalIsOpen(data: boolean): void;
    getHistoryDetails(id: string): void;
    getHistoryDetailsExport(id: string): void;

    historyDetailsData: HistoryDetails;
    broadcastDetailsId: string;
    exportBroadcastDetailsId: string;
    getRecipientListData: BroadcastHistoryPost;

    getBroadcastDetailsModalIsOpen: boolean;

    isHistoryDetailsExportLoading: boolean;
}

const BroadcastDetailsModal: FunctionComponent<BroadcastDetailsModalProps> = (props: BroadcastDetailsModalProps) => {
    const {
        setBroadcastDetailsModalIsOpen,
        getHistoryDetails,
        getHistoryDetailsExport,

        historyDetailsData,
        broadcastDetailsId,
        exportBroadcastDetailsId,
        getRecipientListData,

        getBroadcastDetailsModalIsOpen,

        isHistoryDetailsExportLoading,
    } = props;

    useEffect(() => {
        getHistoryDetails(broadcastDetailsId);
    }, [broadcastDetailsId]);

    const renderHeader = ['Telegram Id', 'Error Issues'];
    const recipients = getRecipientListData.data.find((item) => item.broadcastId === broadcastDetailsId);
    const totalRecipient = (recipients !== undefined && recipients.numOfRecipients + recipients.failedRecipientList.length);

    const [titleContent, setTitleContent] = useState<string>('');
    const [messageContent, setMessageContent] = useState<string>('');
    const [mediaUrl, setMediaUrl] = useState<string>('');

    const [toggler, setToggler] = useState<boolean>(false);

    const [receiverCount, setReceiverCount] = useState<number>(0);

    const [selectState, setSelectState] = useState<RecipientListEnum>(RecipientListEnum.Custom);

    const failedRecipientCount = getRecipientListData.data.find((item) => item.broadcastId === broadcastDetailsId)?.failedRecipientList;

    useEffect(() => {
        if (historyDetailsData.broadcastId === broadcastDetailsId) {
            setTitleContent(historyDetailsData.title);
            setMessageContent(historyDetailsData.message);
            setReceiverCount(historyDetailsData.recipientList.length);

            if (historyDetailsData !== undefined && historyDetailsData.mediaUrl !== undefined) {
                setMediaUrl(historyDetailsData.mediaUrl);
            }

            if (historyDetailsData.recipientListEnum.toString() === RecipientListEnum.LAU) {
                setSelectState(RecipientListEnum.LAU);
            } else if (historyDetailsData.recipientListEnum.toString() === RecipientListEnum.LANU) {
                setSelectState(RecipientListEnum.LANU);
            } else if (historyDetailsData.recipientListEnum.toString() === RecipientListEnum.Users) {
                setSelectState(RecipientListEnum.Users);
            }
        }
    }, [historyDetailsData]);

    const renderExportButtonText = () => {
        if (isHistoryDetailsExportLoading) {
            return (<Spinner size='sm' />);
        }
        return 'Export Data';
    };

    const historyExport = () => {
        getHistoryDetailsExport(exportBroadcastDetailsId);
    };

    const broadcastDetailsToggle = () => {
        setBroadcastDetailsModalIsOpen(!getBroadcastDetailsModalIsOpen);
    };

    const renderFailedTable = () => {
        return (
            getRecipientListData && (
                getRecipientListData.data.find((item) => item.broadcastId === broadcastDetailsId)?.failedRecipientList.map((newItem) => {
                    return (
                        <StyledTr>
                            <StyledTd>
                                {newItem.telegramId}
                            </StyledTd>
                            <StyledTd>
                                {newItem.errorMessage}
                            </StyledTd>
                        </StyledTr>
                    );
                })
            )
        );
    };

    const renderMediaUrl = () => {
        if (mediaUrl === undefined || mediaUrl === '') {
            return (
                <div style={{ marginTop: '10px', border: '1px solid black', width: '100px', height: '125px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    No media
                </div>
            );
        }
        if (mediaUrl.endsWith('.mp4')) {
            return (
                <div style={{ width: '250px', height: '125px' }}>
                    <video style={{ marginTop: '10px', width: 'auto', maxWidth: '100%', height: 'auto', maxHeight: '100%' }}>
                        <source src={mediaUrl} type='video/mp4' />
                        <track kind='captions' />
                    </video>
                </div>
            );
        }
        return (
            <div style={{ width: '250px', height: '125px' }}>
                <img src={mediaUrl} alt='uploadedImage' style={{ marginTop: '10px', width: 'auto', maxWidth: '100%', height: 'auto', maxHeight: '100%' }} />
            </div>
        );
    };

    const renderBody = () => {
        return (
            <MainWrapper>
                <StyledHeader>
                    {`View Broadcast Details (ID: ${broadcastDetailsId})`}

                    <StyledExportButton onClick={() => historyExport()} css={styles.exportButton} type='button'>
                        {renderExportButtonText()}
                    </StyledExportButton>
                </StyledHeader>

                <StyledGridWrapper>
                    <StyledInsideFlexWrapper>
                        <StyledContainerWrapper style={{ marginBottom: '0.7%' }}>
                            <StyledText style={{ fontSize: '18px', color: '#A2A2A2' }}>
                                Receivers
                            </StyledText>
                            {(selectState === RecipientListEnum.LANU || selectState === RecipientListEnum.LAU || selectState === RecipientListEnum.Users) && (
                                <StyledText style={{ fontSize: '16px', color: '#A2A2A2', paddingLeft: '1%', display: 'inline-flex', alignItems: 'center', width: '77%' }}>
                                    {'(Total receivers selected: '}
                                    <StyledText style={{ fontSize: '16px', color: 'black', textAlign: 'center', paddingLeft: '5px' }}>{`${receiverCount}`}</StyledText>
                                    <StyledText style={{ paddingRight: '2%' }}>{' )'}</StyledText>
                                </StyledText>
                            )}

                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '80%', marginTop: '2%', marginBottom: '2%', gap: '5px' }}>
                                {selectState === RecipientListEnum.LAU && (
                                    <ButtonText label='All LAU' isSelected={selectState === RecipientListEnum.LAU} />
                                )}
                                {selectState === RecipientListEnum.LANU && (
                                    <ButtonText label='All LANU' isSelected={selectState === RecipientListEnum.LANU} />
                                )}
                                {selectState === RecipientListEnum.Users && (
                                    <ButtonText label='All Users' isSelected={selectState === RecipientListEnum.Users} />
                                )}
                                {selectState === RecipientListEnum.Custom && (
                                    <ButtonText label='Custom Recipient' isSelected={selectState === RecipientListEnum.Custom} />
                                )}
                            </div>
                        </StyledContainerWrapper>

                        <StyledContainerWrapper>
                            <StyledText style={{ fontSize: '18px', color: '#A2A2A2' }}>
                                Title
                            </StyledText>
                            <TextArea
                                readOnly
                                placeholder=''
                                value={titleContent}
                                css={styles.inputTitle}
                            />
                            <StyledText style={{ fontSize: '18px', color: '#A2A2A2', display: 'inline-flex', alignItems: 'center', width: '100%' }}>
                                <StyledText style={{ paddingRight: '2%' }}>
                                    Message
                                </StyledText>
                            </StyledText>
                            <div>
                                <TextArea
                                    readOnly
                                    placeholder=''
                                    value={messageContent}
                                    css={styles.inputMessageBox}
                                />
                            </div>
                        </StyledContainerWrapper>
                    </StyledInsideFlexWrapper>

                    <StyledInsideFlexWrapper>
                        <StyledContainerWrapper style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', minHeight: '186px' }}>
                            <div style={{ minWidth: '250px', maxWidth: '250px', minHeight: '162px', maxHeight: '162px' }}>
                                <StyledText style={{ color: '#A2A2A2', display: 'inline-flex', justifyContent: 'flex-start', width: '100%', gap: '2%', alignItems: 'center' }}>
                                    Media Uploaded
                                    {(mediaUrl !== '') ? (
                                        <>
                                            <StyledButton onClick={() => setToggler(!toggler)}>
                                                <SVG
                                                    style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        fill: '#00D1D2',
                                                        strokeWidth: '0.1px',
                                                    }}
                                                    src={Icons.ViewImage}
                                                    id='viewImageIcon'
                                                />
                                            </StyledButton>
                                            <FsLightbox
                                                toggler={toggler}
                                                sources={[mediaUrl]}
                                            />
                                        </>
                                    ) : (<> </>)}
                                </StyledText>

                                {renderMediaUrl()}
                            </div>
                            <div style={{ padding: '0px 0px 0px 40px', minWidth: '247px', maxWidth: '247px', minHeight: '162px', maxHeight: '162px', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'flex-start' }}>
                                <StyledText style={{ color: 'green' }}>{`Success Recipients: ${(recipients !== undefined && `${recipients.numOfRecipients}`)}`}</StyledText>
                                <StyledText style={{ color: 'red' }}>{`Failed Recipients: ${(recipients !== undefined && `${recipients.failedRecipientList.length}`)}`}</StyledText>
                                <StyledText>{`Total Recipients: ${totalRecipient}`}</StyledText>
                            </div>
                        </StyledContainerWrapper>

                        <StyledContainerWrapper>
                            <StyledText style={{ color: '#A2A2A2' }}>
                                Posting Details
                            </StyledText>
                            <div
                                style={{
                                    border: '1px solid white',
                                    borderRadius: '5px',
                                    backgroundColor: '#E3F9F9',
                                    outlineColor: '#96bfff',
                                    width: '100%',
                                    height: '249px',
                                    padding: '30px 30px 20px 30px',
                                    marginTop: 5,
                                }}
                            >
                                <StyledTextBody>
                                    <StyledText style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                        {dayjs(historyDetailsData.interval.startDate).format('dddd,')}
                                    </StyledText>

                                    <StyledText style={{ fontSize: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        {dayjs(historyDetailsData.interval.startDate).format('DD-MM-YY')}

                                        <StyledText style={{ fontSize: '20px', paddingLeft: '15%', display: 'inline-flex', alignItems: 'center' }}>
                                            <SVG src={Icons.Clock} id='clockIcon' style={{ width: '30px', paddingRight: '10%', fill: '#009899' }} />
                                            {dayjs(historyDetailsData.interval.startDate).format('h:mm A')}
                                        </StyledText>
                                    </StyledText>
                                </StyledTextBody>
                            </div>
                        </StyledContainerWrapper>
                    </StyledInsideFlexWrapper>
                </StyledGridWrapper>
                {(failedRecipientCount && failedRecipientCount?.length > 0) && (
                    <StyledTable>
                        <StyledTHead>
                            <tr>
                                {renderHeader.map((item) => (
                                    <StyledTh key={item}>
                                        {item}
                                    </StyledTh>
                                ))}
                            </tr>
                        </StyledTHead>
                        <StyledTableBody>
                            {renderFailedTable()}
                        </StyledTableBody>
                    </StyledTable>
                )}
            </MainWrapper>
        );
    };

    return (
        <Modal isOpen={getBroadcastDetailsModalIsOpen} toggle={broadcastDetailsToggle} centered size='xl' fullscreen='xl'>
            <ModalBody>
                {renderBody()}
            </ModalBody>
        </Modal>
    );
};

const MainWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StyledHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 90%;
    height: 55px;
    font-size: 25px;
    border-bottom: 2px solid #A2A2A2;
`;

const StyledGridWrapper = styled.div`
    width: 90%;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    column-gap: 3%;

    margin-top: 2%;
`;

const StyledInsideFlexWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-item: flex-start;
`;

const StyledContainerWrapper = styled.div`
    min-width: 100%;
    max-width: 100%;
    max-height: 100%;
`;

const StyledTextBody = styled.div`
    width: 100%;
    height: 100%;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const StyledButton = styled.button`
    display: flex;
    align-items: center;
    border: none;
    background: transparent;
    height: 75%;
    width: 10%;
    font-size: 11px;
`;

type StyledCSSProps = {
    css?: FlattenSimpleInterpolation
}

const StyledExportButton = styled.button<StyledCSSProps>`
    ${props => props.css}
`;

const StyledText = styled.text`
    font-size: 18px;
`;

const StyledTr = styled.tr`
    &:not(:last-of-type){
        border-bottom: 5px solid #ffffff;
    }

    &:nth-last-of-type(1){
        border-radius: 0px 0px 10px 10px !important;
    }

    &:nth-of-type(odd){
        background-color: #F6FCFC;
    }
`;

const StyledTd = styled.td`
    padding: 20px;
    font-size: 16px;
    whiteSpace: nowrap;
    overflow: hidden;
    text-Overflow: ellipsis;
    text-align: left;

    &:first-of-type {
        max-width: 249px;
        text-align: center;
    }
`;

const StyledTable = styled.table`
    min-width: 90%;
    background-color: white;
    border-collapes: collapse;
    table-layout: fixed;
    
    margin-top: 20px;
    border-radius: 10px !important;
`;

const StyledTHead = styled.thead`
    text-align: center;
    border-bottom: 1px solid #C5C5C5;
`;

const StyledTableBody = styled.tbody`
    text-align: center;
`;

const StyledTh = styled.th`
    padding: 20px;
    border-bottom: 1px solid rgb(0,0,0,0,3);
    font-size: 15px;
    color: #959595;
    font-weight: lighter;
    text-align: left;
    
    &:first-of-type {
        text-align: center;
        max-width: 249px;
    }
`;

const styles = {
    inputReceiver: css`
        background-color: #F4F9F9;
        height: 47.5px;
        margin-bottom: 4.22%;
        resize: none;
        overflow: auto;
        cursor: not-allowed;
        text-align: center;
        color: #00D1D2;
    `,

    inputTitle: css`
        background-color: #F4F9F9;
        height: 47.5px;
        margin-bottom: 4.22%;
        resize: none;
        overflow: auto;
        cursor: default;
        margin-top: 5px;
    `,

    exportButton: css`
        background-color: #00D1D2;
        color: white;

        width: 163px;
        height: 42px;
        
        border: none;
        border-radius: 20px;

        font-size: 17px;
    `,

    inputMessageBox: css`
        background-color: #F4F9F9;
        height: 249px;
        resize: none;
        cursor: default;
        margin-top: 5px;
    `,

    inputPostingDetails: css`
        height: 141px;
        background-color: #E3F9F9;
        color: black;
    `,
};

const mapStateToProps = (state: RootState) => ({
    getRecipientListData: Selectors.getBroadcastHistoryPost(state),
    historyDetailsData: Selectors.getBroadcastHistoryDetails(state),

    isCreateBroadcastPostLoading: Selectors.createBroadcastBroadcastPostAttempting(state),

    getBroadcastDetailsModalIsOpen: Selectors.getBroadcastBroadcastDetailsModalIsOpen(state),

    getRecipientList: Selectors.getBroadcastGetRecipientList(state),

    recurringIndex: Selectors.getBroadcastRecurringPaginatorIndex(state),
    scheduleIndex: Selectors.getBroadcastSchedulePaginatorIndex(state),
    historyIndex: Selectors.getBroadcastHistoryPaginatorIndex(state),

    isHistoryDetailsExportLoading: Selectors.getBroadcastHistoryDetailsExportAttempting(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    createBroadcastPost: (data: ICreateBroadcastPost) => dispatch(Actions.createBroadcastPostAttempt(data)),

    setBroadcastDetailsModalIsOpen: (data: boolean) => dispatch(Actions.setBroadcastDetailsModalIsOpen(data)),
    setBroadcastType: (data: BroadcastTypesEnum) => dispatch(Actions.setBroadcastType(data)),
    setRecipientList: (data: RecipientListEnum) => dispatch(Actions.setRecipientList(data)),

    getHistoryDetails: (id: string) => dispatch(Actions.getHistoryDetailsAttempt({ id })),
    getHistoryDetailsExport: (id: string) => dispatch(Actions.getHistoryDetailsExportAttempt({ id })),
});

export default connect(mapStateToProps, mapDispatchToProps)(BroadcastDetailsModal);
