import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICreateGame, IDeleteGame } from 'entities/games';

import { GamesReduxState, GetGamesPayload, EditGameDetailsPayload, GetGameDetailsResponsesPayload, GetGameDetailsPayload, ExportGameResponsesPayload, GetGameDetailsParamsPayload, GetGameDetailsResponsesParams, GetGamesTypePayload } from './types';

const initialState: GamesReduxState = {
    actions: {
        getGames: false,
        createGame: false,
        deleteGame: false,
        editGame: false,
        getGameDetails: false,
        getGameDetailsResponses: false,
        gameExportResponses: false,
    },
    games: [],
    gameDetails: {
        id: '',
        name: '',
        status: 0,
        totalResponses: 0,
        createdAt: '',
    },
    gameResponses: {
        index: 1,
        maxIndex: 0,
        data: [],
    },
    page: 1,
    paginatorIndex: 1,
    error: {
        getGames: '',
        createGame: '',
        deleteGame: '',
        editGame: '',
        getGameDetails: '',
        getGameDetailsResponses: '',
    },
};

const gameSlice = createSlice({
    name: 'games',
    initialState,
    reducers: {
        getGamesAttempting: (state, _action: GetGamesTypePayload) => {
            state.actions.getGames = true;
            state.error.getGames = '';
        },
        getGamesSuccess: (state, action: GetGamesPayload) => {
            state.actions.getGames = false;
            if (action.payload) {
                state.games = action.payload;
            }
            state.error.getGames = '';
        },
        getGamesFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getGames = false;
            if (action.payload) {
                state.error.getGames = action.payload;
            }
        },

        createGameAttempting: (state, _action: PayloadAction<ICreateGame>) => {
            state.actions.createGame = true;
            state.error.createGame = '';
        },
        createGameSuccess: (state) => {
            state.actions.createGame = false;
            state.error.createGame = '';
        },
        createGameFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.createGame = false;
            if (action.payload) {
                state.error.createGame = action.payload;
            }
        },
        gamesClearCreateGamesError: (state) => {
            state.error.createGame = '';
        },

        deleteGameAttempting: (state, _action:PayloadAction<IDeleteGame>) => {
            state.actions.deleteGame = true;
            state.error.deleteGame = '';
        },
        deleteGameSuccess: (state) => {
            state.actions.deleteGame = false;
            state.error.deleteGame = '';
        },
        deleteGameFailure: (state, action: PayloadAction<string| undefined>) => {
            state.actions.deleteGame = false;
            if (action.payload) {
                state.error.deleteGame = action.payload;
            }
        },

        getGameDetailsAttempting: (state, _action: GetGameDetailsParamsPayload) => {
            state.actions.getGameDetails = true;
            state.error.getGameDetails = '';
        },
        getGameDetailsSuccess: (state, action: GetGameDetailsPayload) => {
            state.actions.getGameDetails = false;
            state.gameDetails = action.payload;
            state.error.getGameDetails = '';
        },
        getGameDetailsFailure: (state, action: PayloadAction<string>) => {
            state.actions.getGameDetails = false;
            state.error.getGameDetails = action.payload;
        },

        editGameAttempting: (state, _action: EditGameDetailsPayload) => {
            state.actions.editGame = true;
            state.error.editGame = '';
        },
        editGameSuccess: (state, action: EditGameDetailsPayload) => {
            state.actions.editGame = false;
            state.error.editGame = '';
            state.gameDetails = {
                ...state.gameDetails,
                ...action.payload,
            };
        },
        editGameFailure: (state, action: PayloadAction<string>) => {
            state.actions.editGame = false;
            if (action.payload) {
                state.error.editGame = action.payload;
            }
        },

        setGamePaginatorIndex: (state, action: PayloadAction<number>) => {
            state.gameResponses.index = action.payload;
        },
        setGamePage: (state, action: PayloadAction<number>) => {
            state.page = action.payload;
        },

        getGameDetailsResponsesAttempting: (state, _actions: GetGameDetailsResponsesParams) => {
            state.actions.getGameDetailsResponses = true;
            state.error.getGameDetailsResponses = '';
        },
        getGameDetailsResponsesSuccess: (state, actions: GetGameDetailsResponsesPayload) => {
            state.actions.getGameDetailsResponses = false;
            state.gameResponses = actions.payload;
            state.error.getGameDetailsResponses = '';
        },
        getGameDetailsResponsesFailure: (state, actions: PayloadAction<string>) => {
            state.actions.getGameDetailsResponses = false;
            state.error.getGameDetailsResponses = actions.payload;
        },

        gameExportResponsesAttempting: (state, _actions: ExportGameResponsesPayload) => {
            state.actions.gameExportResponses = true;
        },
        gameExportResponsesSuccess: (state) => {
            state.actions.gameExportResponses = false;
        },
        gameExportResponsesFailure: (state) => {
            state.actions.gameExportResponses = false;
        },
    },
});

export type GamesState = typeof initialState;

export default {
    actions: gameSlice.actions,
    reducers: gameSlice.reducer,
};
