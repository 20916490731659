import React, { useState, useEffect, FunctionComponent } from 'react';
// ? Redux Toolkit
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import SVG from 'react-inlinesvg';
import { useParams } from 'react-router';
import styled, { FlattenSimpleInterpolation, css } from 'styled-components';

import Icons from 'assets/icons/Index';
import Fonts from 'assets/themes/Fonts';
import Colors from 'assets/themes/Colors';

import Input from 'components/Input';
import TextArea from 'components/TextArea';
import VariantsList from 'components/ecommerce/VariantsList';
import CollapsibleCard from 'components/ecommerce/CollapsibleCard';

import {
    IEditProductDetails,
    LanguageEnum,
    MessageCategoryEnum,
    ProductListDetails,
    StatusTypeEnum,
    VariantsListDetails,
} from 'entities/ecommerce';

import { toast } from 'react-toastify';
import CreateVariantModal from './CreateVariantModal';

// ! interface zone
// * props interface
export interface ProductDetailsProps {
    // ? product details props
    productDetails: ProductListDetails[];
    getProductDetails: (productId: string) => void;
    editProductDetails: (data: IEditProductDetails) => void;
    // ? create variant modal props
    setCreateVariantModalIsOpen: (state: boolean) => void;
}

// ! JSX function component
const ProductDetails: FunctionComponent<ProductDetailsProps> = (props: ProductDetailsProps) => {
    // ! destructuring zone
    // * props destructured
    const {
        productDetails,
        getProductDetails,
        editProductDetails,
        setCreateVariantModalIsOpen,
    } = props;
    // ? get product id from params
    const { id } = useParams();

    // ! useState Zone
    // ? existing product details state
    const [_id, setId] = useState<string>('');
    const [productName, setProductName] = useState<string>('');
    const [productTitleEN, setProductTitleEN] = useState<string>('');
    const [productDescEN, setProductDescEN] = useState<string>('');
    const [productTitleBM, setProductTitleBM] = useState<string>('');
    const [productDescBM, setProductDescBM] = useState<string>('');
    const [productPrice, setProductPrice] = useState<string>('0');
    const [promotionalPrice, setPromotionalPrice] = useState<string>('0');
    const [productLinkEN, setProductLinkEN] = useState<string>('');
    const [productLinkBM, setProductLinkBM] = useState<string>('');
    const [productVariant, setProductVariant] = useState<VariantsListDetails[]>([]);
    const [status, setStatus] = useState<StatusTypeEnum>(StatusTypeEnum.All);
    // ? product name state
    const [editedProductName, setEditedProductName] = useState<string>('');
    const [productNameIsEdit, setProductNameIsEdit] = useState<boolean>(false);
    // ? EN input state
    const [enPdTitle, setEnPdTitle] = useState<string>('');
    const [enPdDescription, setEnPdDescription] = useState<string>('');
    // ? BM input state
    const [bmPdTitle, setBmPdTitle] = useState<string>('');
    const [bmPdDescription, setBmPdDescription] = useState<string>('');
    // ? other state
    const [productPriceState, setProductPriceState] = useState<string>('0');
    const [promotionalPriceState, setPromotionalPriceState] = useState<string>('0');
    const [enProductLinkState, setEnProductLinkState] = useState<string>('');
    const [bmProductLinkState, setBmProductLinkState] = useState<string>('');
    const [enProductLinkErrorMessage, setEnProductLinkErrorMessage] = useState<string>('');
    const [bmProductLinkErrorMessage, setBmProductLinkErrorMessage] = useState<string>('');

    // ! useEffect zone
    // ? used to get product details
    useEffect(() => {
        if (id) {
            getProductDetails(id);
        }
    }, []);
    // ? used to redirect user to ecommerce page if id not valid
    useEffect(() => {
        if (id) {
            if (productDetails.length) {
                const currentProduct = productDetails.find((item) => item._id === id) as ProductListDetails;
                if (currentProduct) {
                    // from api
                    setId(currentProduct._id);
                    setProductName(currentProduct.productName);
                    setProductTitleEN(currentProduct.productTitleEN);
                    setProductDescEN(currentProduct.productDescEN);
                    setProductTitleBM(currentProduct.productTitleBM);
                    setProductDescBM(currentProduct.productDescBM);
                    setProductPrice(Number(currentProduct.productPrice).toFixed(2).toString());
                    setPromotionalPrice(Number(currentProduct.promotionalPrice).toFixed(2).toString());
                    setProductLinkEN(currentProduct.productLinkEN);
                    setProductLinkBM(currentProduct.productLinkBM);
                    setProductVariant(currentProduct.productVariant);
                    setStatus(currentProduct.status);
                    // local
                    setEditedProductName(currentProduct.productName);
                    setEnPdTitle(currentProduct.productTitleEN);
                    setEnPdDescription(currentProduct.productDescEN);
                    setBmPdTitle(currentProduct.productTitleBM);
                    setBmPdDescription(currentProduct.productDescBM);
                    setProductPriceState(Number(currentProduct.productPrice).toFixed(2).toString());
                    setPromotionalPriceState(Number(currentProduct.promotionalPrice).toFixed(2).toString());
                    setEnProductLinkState(currentProduct.productLinkEN);
                    setBmProductLinkState(currentProduct.productLinkBM);
                }
            }
        }
    }, [id, productDetails]);

    // ! functions
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, language: LanguageEnum, type: MessageCategoryEnum) => {
        const { value } = e.target;
        if (language === LanguageEnum.EN) {
            if (type === MessageCategoryEnum.PdTitle) {
                setEnPdTitle(value);
            } else if (type === MessageCategoryEnum.PdDescription) {
                setEnPdDescription(value);
            }
        } else if (language === LanguageEnum.BM) {
            if (type === MessageCategoryEnum.PdTitle) {
                setBmPdTitle(value);
            } else if (type === MessageCategoryEnum.PdDescription) {
                setBmPdDescription(value);
            }
        }
    };

    const handleResetButton = () => {
        setEnPdTitle(productTitleEN);
        setEnPdDescription(productDescEN);
        setBmPdTitle(productTitleBM);
        setBmPdDescription(productDescBM);
        setProductPriceState(productPrice);
        setPromotionalPriceState(promotionalPrice);
        setEnProductLinkState(productLinkEN);
        setBmProductLinkState(productLinkBM);
    };

    const handleSaveButton = () => {
        if (enProductLinkErrorMessage || bmProductLinkErrorMessage) {
            toast.error('Please enter a valid link.');
        } else {
            editProductDetails({
                id: _id,
                productName,
                productTitleEN: enPdTitle,
                productDescEN: enPdDescription,
                productTitleBM: bmPdTitle,
                productDescBM: bmPdDescription,
                productPrice: productPriceState,
                promotionalPrice: promotionalPriceState,
                productLinkEN: enProductLinkState,
                productLinkBM: bmProductLinkState,
                status,
            });
        }
    };

    const handleProductNameEdit = () => {
        editProductDetails({
            id: _id,
            productName: editedProductName,
            productTitleEN: enPdTitle,
            productDescEN: enPdDescription,
            productTitleBM: bmPdTitle,
            productDescBM: bmPdDescription,
            productPrice: productPriceState,
            promotionalPrice: promotionalPriceState,
            productLinkEN: enProductLinkState,
            productLinkBM: bmProductLinkState,
            status,
        });
        setProductNameIsEdit(false);
    };

    const handleProductPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const numericValue = value.replace(/[^0-9.]/g, '');

        if (numericValue !== '') {
            setProductPriceState(numericValue);
        } else {
            setProductPriceState('');
        }
    };
    const handleDisplayedProductPrice = (value: string) => {
        if (value !== '') {
            const floatValue = Number(value).toFixed(2).toString();
            setProductPriceState(floatValue);
        } else {
            setProductPriceState('0');
        }
    };

    const handlePromotionalPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const numericValue = value.replace(/[^0-9.]/g, '');

        if (numericValue !== '') {
            setPromotionalPriceState(numericValue);
        } else {
            setPromotionalPriceState('');
        }
    };

    const handleDisplayedPromotionalPrice = (value: string) => {
        if (value !== '') {
            const floatValue = Number(value).toFixed(2).toString();
            setPromotionalPriceState(floatValue);
        } else {
            setPromotionalPriceState('0');
        }
    };

    const handleProductLinkChange = (e: React.ChangeEvent<HTMLInputElement>, language: LanguageEnum) => {
        const { value } = e.target;

        if (language === LanguageEnum.EN) {
            setEnProductLinkState(value);
            const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
            const isValidUrl = urlRegex.test(value);

            if (isValidUrl) {
                setEnProductLinkErrorMessage('');
            } else {
                setEnProductLinkErrorMessage('Please enter a valid link!');
            }
        } else if (language === LanguageEnum.BM) {
            setBmProductLinkState(value);

            const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
            const isValidUrl = urlRegex.test(value);

            if (isValidUrl) {
                setBmProductLinkErrorMessage('');
            } else {
                setBmProductLinkErrorMessage('Please enter a valid link!');
            }
        }
    };

    // ! render zone
    const renderReminder = () => {
        const reminderMessage = (
            <ReminderContainer>You have made some changes! Click save to save your changes.</ReminderContainer>
        );
        if (
            (enPdTitle !== productTitleEN || enPdDescription !== productDescEN)
        || (bmPdTitle !== productTitleBM || bmPdDescription !== productDescBM)
        || (productPriceState !== productPrice || enProductLinkState !== productLinkEN || bmProductLinkState !== productLinkBM)
        || (promotionalPriceState !== promotionalPrice)
        ) {
            return reminderMessage;
        }
        return null;
    };

    const renderPageTitle = () => {
        if (productNameIsEdit) {
            return (
                <TitleContainer>

                    <Input
                        value={editedProductName}
                        onChange={(e) => setEditedProductName(e.target.value)}
                        css={styles.titleInput}
                    />
                    <StyledActionButton
                        onClick={() => handleProductNameEdit()}
                        css={styles.updatedPencilIcon}
                        style={{ border: 'none' }}
                    >
                        <SVG src={Icons.Save} id='edit' />
                    </StyledActionButton>
                    <StyledActionButton
                        onClick={() => setProductNameIsEdit(false)}
                        css={styles.updatedPencilIcon}
                        style={{ border: 'none' }}
                    >
                        <SVG src={Icons.Undo} id='edit' />
                    </StyledActionButton>
                </TitleContainer>
            );
        }
        return (
            <TitleContainer>
                <StyledMainTitle>{productName}</StyledMainTitle>
                <StyledActionButton
                    onClick={() => setProductNameIsEdit(true)}
                    css={styles.updatedPencilIcon}
                >
                    <SVG src={Icons.PencilIcon} id='edit' />
                </StyledActionButton>
            </TitleContainer>
        );
    };

    // * main render
    return (
        <MainWrapper>
            <MainContent>
                {renderPageTitle()}
                <ContentContainer>
                    <StyledTitle>Product Description</StyledTitle>
                    <Content>
                        <CollapsibleCard
                            index='1.0'
                            title='English'
                            titleContent={enPdTitle}
                            descriptionContent={enPdDescription}
                            handleTitleChange={(e) => handleInputChange(e, LanguageEnum.EN, MessageCategoryEnum.PdTitle)}
                            handleDescriptionChange={(e) => handleInputChange(e, LanguageEnum.EN, MessageCategoryEnum.PdDescription)}
                            isOpenByDefault
                        />
                        <CollapsibleCard
                            index='2.0'
                            title='Bahasa Melayu'
                            titleContent={bmPdTitle}
                            descriptionContent={bmPdDescription}
                            handleTitleChange={(e) => handleInputChange(e, LanguageEnum.BM, MessageCategoryEnum.PdTitle)}
                            handleDescriptionChange={(e) => handleInputChange(e, LanguageEnum.BM, MessageCategoryEnum.PdDescription)}
                        />
                    </Content>
                </ContentContainer>
                <ContentContainer>
                    <StyledTitle>Product Pricing</StyledTitle>
                    <ContentRow style={{ marginBottom: '40px' }}>
                        RM
                        <Input
                            value={productPriceState}
                            onChange={handleProductPriceChange}
                            onBlur={() => handleDisplayedProductPrice(productPriceState)}
                            css={styles.input}
                        />
                    </ContentRow>
                    <StyledTitle>Promotional Pricing</StyledTitle>
                    <ContentRow>
                        RM
                        <Input
                            value={promotionalPriceState}
                            onChange={handlePromotionalPriceChange}
                            onBlur={() => handleDisplayedPromotionalPrice(promotionalPriceState)}
                            css={styles.input}
                        />
                    </ContentRow>
                </ContentContainer>
                <ContentContainer>
                    <StyledTitle>Product Link - English</StyledTitle>
                    <Content style={{ marginBottom: '40px' }}>
                        <TextArea
                            placeholder=''
                            value={enProductLinkState}
                            onChange={(e) => handleProductLinkChange(e, LanguageEnum.EN)}
                            css={styles.inputMessageBox}
                        />
                        <ReminderContainer>{enProductLinkErrorMessage}</ReminderContainer>
                    </Content>
                    <StyledTitle>Product Link - Bahaha Melayu</StyledTitle>
                    <Content>
                        <TextArea
                            placeholder=''
                            value={bmProductLinkState}
                            onChange={(e) => handleProductLinkChange(e, LanguageEnum.BM)}
                            css={styles.inputMessageBox}
                        />
                        <ReminderContainer>{bmProductLinkErrorMessage}</ReminderContainer>
                    </Content>
                </ContentContainer>
                <ContentContainer>
                    <ButtonContainer>
                        <StyledTitle>Product Variants</StyledTitle>
                        <StyledActionButton
                            type='button'
                            css={styles.primary}
                            onClick={() => setCreateVariantModalIsOpen(true)}
                        >
                            Add New Variant
                        </StyledActionButton>
                    </ButtonContainer>
                    <CreateVariantModal productId={_id} />
                    <VariantContainer>
                        {
                            productVariant.length > 0
                                ? (
                                    <VariantsList
                                        productId={_id}
                                    />
                                ) : (
                                    <div>
                                        No variant yet. Please add new variant!
                                    </div>
                                )
                        }
                    </VariantContainer>
                    <div style={{ marginTop: '20px' }}>
                        {renderReminder()}
                        <ButtonWrapper>
                            <StyledActionButton
                                type='button'
                                css={styles.primary}
                                onClick={() => handleSaveButton()}
                            >
                                Save
                            </StyledActionButton>
                            <StyledActionButton
                                type='button'
                                css={styles.secondary}
                                onClick={() => handleResetButton()}
                            >
                                Reset
                            </StyledActionButton>
                        </ButtonWrapper>
                    </div>
                </ContentContainer>
            </MainContent>
        </MainWrapper>
    );
};

// ! styled-components
interface StyledButtonProps {
    css?: FlattenSimpleInterpolation;
}
const MainWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: space-evenly;

    background-color: #F1F6F8;
    border-radius: 20px;

    padding-top: 1%;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 1%;

    width: 71%;
    height: 100%;

    margin-bottom: 5%;
    margin-right: 7%;
`;
const MainContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    gap: 20px;
    margin-top: 2%;
`;
const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    min-width: 30%;
`;
const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
`;
const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const StyledMainTitle = styled.text`
    font-size: 32px;
    color: #27242E;
    padding-right: 30px;
    font-family: ${Fonts.secondary};
    font-weight: bold;

    @media (max-width: 1580px) {
        font-size: 27px;
    }
`;
const StyledTitle = styled.text`
    font-size: 20px;
    color: #27242E;
    font-family: ${Fonts.primary};
    font-weight: bold;
`;
const StyledActionButton = styled.button<StyledButtonProps>`
    cursor:pointer;
    ${props => props.css}
`;
const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    margin-top: 10px;
`;
const ContentRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap:10px;
    margin-top: 10px;
    align-items: center;
`;
const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    gap: 20px;
`;
const ReminderContainer = styled.div`
    color: ${Colors.iqosError};
    font-style: italic;
`;
const VariantContainer = styled.div`
    display: flex;
    padding-top: 20px;
    overflow: auto;
    min-height: 250px;
`;
const styles = {
    mainTitle: css`
        font-size: 100px;
    `,
    primary: css`
        min-width:150px;
        color: ${Colors.white};
        background-color: ${Colors.iqosPrimary};
        font-size: 18px;
        height:46px;
        border: 1px solid ${Colors.iqosPrimary};
        font-family: ${Colors.secondary};
        border-radius: 20px;
        font-weight: 600;
        color: white;
        padding-inline: 30px;
    `,
    secondary: css`
    min-width:150px;
    color: ${Colors.primary};
    background-color: ${Colors.white};
    font-size: 18px;
    height:46px;
    border: 1px solid ${Colors.primary};
    font-family: ${Colors.secondary};
    border-radius: 20px;
    color: black;
    `,
    input: css`
        background-color: ${Colors.white};
        padding: 10px;
        width: 300px;
    `,
    titleInput: css`
        background-color: ${Colors.white};
        padding: 10px;
        width: max-content;
        font-size: 32px;
        color: #27242E;
        padding-right: 30px;
        font-family: ${Fonts.secondary};
        font-weight: bold;
    `,
    inputMessageBox: css`
        background-color: ${Colors.white};
        height: 80px;
        margin-top: 5px;
        resize: none;
    `,
    updatedPencilIcon: css`
        border: none;
        background-color: transparent;
        width: 40px;
        height: 40px;
    #edit {
        width: 35px;
        height: 35px;
        color: ${Colors.iqosPrimary};
        /* &:hover{
            color: #1976d2;
        } */
    }
    `,
};

// ! redux toolkit
// * Selectors
const mapStateToProps = (state: RootState) => ({
    productDetails: Selectors.getECommerceGetProductDetails(state),
});
// * Dispatch
const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getProductDetails: (productId: string) => dispatch(Actions.getProductDetailsAttempt({ productId })),
    editProductDetails: (data: IEditProductDetails) => dispatch(Actions.editProductDetailsAttempt(data)),
    setCreateVariantModalIsOpen: (state: boolean) => dispatch(Actions.setCreateVariantModalIsOpen(state)),

});
// * Connection
export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
