import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import ContestGateway from 'api/Contests';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { GetContestResponsesParams } from 'redux/slices/contests/types';
import { toast } from 'react-toastify';

export default function* watchGetContestResponses(api: ContestGateway): SagaWatcherReturnType {
    yield takeEvery('contests/contestGetContestResponsesAttempt', handleGetContestResponses, api);
}

function* handleGetContestResponses(api: ContestGateway, data: GetContestResponsesParams) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.getContestDetailsResponses], {
        authToken,
        contestId: data.payload.contestId,
        index: data.payload.index,
        type: data.payload.type,
    });
    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.contestGetContestResponsesFailure(response.message || ''));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.contestGetContestResponsesSuccess(response.data));
    }
}
