import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';
import {
    SummaryDashboardDetails,
    SubscribersOverview,
} from 'entities/summaryDashboard';

export interface GetSummaryDashboardDetailsParams {
    authToken: string;
    type: string;
    dateFrom: string;
    dateTo: string;
    isYtd: string;
}

export interface GetSubscribersOverviewParams {
    authToken: string;
    type: string;
    year: string;
}

export abstract class ISummaryDashboardGateway extends Gateway {
    abstract getSummaryDashboardDetails(params: GetSummaryDashboardDetailsParams): GatewayResponse<SummaryDashboardDetails>;

    abstract getSubscribersOverview(params: GetSubscribersOverviewParams): GatewayResponse<SubscribersOverview>;
}
