import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import ContestGateway from 'api/Contests';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { toast } from 'react-toastify';

import { ExportContestParams } from 'redux/slices/contests/types';
import { GatewayResponseStatus } from 'api/types/types';

import xlsx, { IContent } from 'json-as-xlsx';
import dayjs from 'dayjs';

export default function* watchContestExportResponses(api: ContestGateway): SagaWatcherReturnType {
    yield takeEvery('contests/contestExportResponsesAttempt', handleGetExportToDownload, api);
}

function* handleGetExportToDownload(api: ContestGateway, data: ExportContestParams) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const contestDetails = yield* select(Selectors.getContestDetails);
    const isLanu = yield* select(Selectors.getFaqGetIsLANU);

    const fileTypeLAU = 'LAU';
    const fileTypeLANU = 'LANU';

    const { name } = contestDetails;

    const response = yield* call([api, api.getExportResponses], {
        authToken,
        contestId: data.payload.contestId,
        type: data.payload.type,
    });

    if (response.status === GatewayResponseStatus.Error) {
        toast.error(response.message);
        yield put(Actions.contestExportResponsesFailure());
    }

    if (response.status === GatewayResponseStatus.Success) {
        const { data: responsesExport } = response;
        if (!response.data.length) {
            toast.error('Insufficient data to export!');
            yield put(Actions.contestExportResponsesFailure());
            return;
        }

        const massagedData = response.data.map((item) => {
            return {
                id: item.id,
                firstName: item.firstName,
                userName: item.username,
                phoneNumber: item.phoneNumber,
                clickTime: dayjs(item.clickTime).format('DD-MMM-YYYY'),
                totalCorrectAnswers: item.totalCorrectAnswers,
                eligible: (item.eligible === true) ? 'Eligible' : 'Not Eligible',
            };
        });

        interface StringDictionary {
            [key: string]: any;
        }

        const excelFixedContestColumns = [{
            label: 'ID',
            value: 'id',
        }, {
            label: 'First Name',
            value: 'firstName',
        }, {
            label: 'Username',
            value: 'username',
        }, {
            label: 'Phone Number',
            value: 'phoneNumber',
        }, {
            label: 'Click Time',
            value: 'clickTime',
        }, {
            label: 'Total Correct Answers',
            value: 'totalCorrectAnswers',
        }, {
            label: 'Eligibility',
            value: 'eligible',
        },
        ];

        const count = Object.keys(responsesExport[0]).length - 8;

        for (let i = 1; i < count + 1; i += 1) {
            excelFixedContestColumns.push({
                label: `Answer ${i}`,
                value: `answer${i}`,
            });
        }

        let contestDataToExport: IContent[] = [];
        contestDataToExport = massagedData as StringDictionary[];

        const constestDataToDownload = [{
            sheet: 'Contest Responses',
            columns: excelFixedContestColumns,
            content: contestDataToExport,
        }];
        xlsx(constestDataToDownload, { fileName: `${name} for ${(isLanu) ? fileTypeLANU : fileTypeLAU} Responses` });

        yield* put(Actions.contestExportResponsesSuccess());
    }
}
