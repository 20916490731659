import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SummaryDashboardDetails, SubscribersOverview } from 'entities/summaryDashboard';
import { IChannelTypeEnum } from 'entities/contests';
import { GetSummaryDashboardDetailsParams, GetSubscribersOverviewParams, SummaryDashboardReduxState } from './types';

const initialState: SummaryDashboardReduxState = {
    actions: {
        summaryDashboardDetails: false,
        subscribersOverview: false,
    },
    summaryDashboardDetailsData: {
        newFollowersCurrentDate: 0,
        newFollowersPreviousDate: 0,
        newFollowersChange: 0,
        netFollowersCurrentDate: 0,
        netFollowersPreviousDate: 0,
        netFollowersChange: 0,
        cumulativeFollowersCurrentDate: 0,
        cumulativeFollowersPreviousDate: 0,
        cumulativeFollowersChange: 0,
        viewRateCurrentDate: 0,
        viewRatePreviousDate: 0,
        viewRatePercentageChange: 0,
        engagementRateCurrentDate: 0,
        engagementRatePreviousDate: 0,
        engagementRateChange: 0,
        unsubscribeRateInputDate: 0,
        unsubscribeRatePreviousDate: 0,
        unsubscribeRate: 0,
        viewCountCurrentDate: 0,
        viewCountPreviousDate: 0,
        viewCountChange: 0,
        engagementCountCurrentDate: 0,
        engagementCountPreviousDate: 0,
        engagementCountChange: 0,
    },
    subscribersOverviewData: {
        channelType: IChannelTypeEnum.LAU,
        data: [],
    },
    error: {
        summaryDashboardDetails: '',
        subscribersOverview: '',
    },
};

const summaryDashboardSlice = createSlice({
    name: 'summaryDashboard',
    initialState,
    reducers: {
        getSummaryDashboardDetailsAttempt: (state, _action: GetSummaryDashboardDetailsParams) => {
            state.actions.summaryDashboardDetails = true;
            state.error.summaryDashboardDetails = '';
        },
        getSummaryDashboardDetailsSuccess: (state, action: PayloadAction<SummaryDashboardDetails>) => {
            state.actions.summaryDashboardDetails = false;

            if (action.payload) {
                state.summaryDashboardDetailsData = action.payload;
            }

            state.error.summaryDashboardDetails = '';
        },
        getSummaryDashboardDetailsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.summaryDashboardDetails = false;

            if (action.payload) {
                state.error.summaryDashboardDetails = action.payload;
            }
        },

        getSubscribersOverviewAttempt: (state, _action: GetSubscribersOverviewParams) => {
            state.actions.subscribersOverview = true;
            state.error.subscribersOverview = '';
        },
        getSubscribersOverviewSuccess: (state, action: PayloadAction<SubscribersOverview>) => {
            state.actions.subscribersOverview = false;

            if (action.payload) {
                state.subscribersOverviewData = action.payload;
            }

            state.error.subscribersOverview = '';
        },
        getSubscribersOverviewFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.subscribersOverview = false;

            if (action.payload) {
                state.error.subscribersOverview = action.payload;
            }
        },
    },
});

export type SummaryDashboardState = typeof initialState;

export default {
    actions: summaryDashboardSlice.actions,
    reducers: summaryDashboardSlice.reducer,
};
