import { IChannelTypeEnum } from './contests';

export interface ICreateGame {
    name: string;
}

export interface IDeleteGame {
    id: string;
}

export interface IGame {
    id: string;
    name: string;
    status: IGameStatusEnum;
    clicks: number;
    createdAt: string;
}

export enum IGameStatusEnum {
    Inactive = 0,
    Active = 1,
}

export interface IGameResponse {
    id: string;
    firstName: string;
    username: string;
    type: IChannelTypeEnum;
    phoneNumber: string;
    practicePlayedTimes: number;
    realGameScore: number;
    realGamePlayedTime: Date;
    initialClickTime: Date;
}

export interface IGetGameResponsesResponse {
    index: number;
    maxIndex: number;
    data: IGameResponse[];
}

export interface IGetExportResponses {
    gameId: string;
    type: IChannelTypeEnum;
}

export interface IEditGameParams {
    id: string;
    name?: string;
    status: IGameStatusEnum;
}

export interface IGameDetails {
    id: string;
    name: string;
    status: IGameStatusEnum;
    totalResponses: number;
    createdAt: string;
}

export interface IGetGameDetailsSuccess {
    id: string;
    status: number;
    name: string;
    totalResponses: number;
    createdAt: string;
}

export interface IGetGameDetailsResponseParams {
    gameId: string;
    index: number;
    type: IChannelTypeEnum;
}
