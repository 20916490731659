import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';

import Colours from 'assets/themes/Colors';
import Text from './Text';

interface HeaderButtonProps {
    text?: string;
    onClick?: () => void;
    isSelected: boolean;
}

const StyledButton = styled.button<HeaderButtonProps>`
    border: none;
    font-size: 18px;
    transition: 0.3s;
    width: 100%;
    height: 100%;
    color: black;
    background-color: lightgrey;

    &:hover {
        div {
            color: black;
            transition: 0.5s;
        }
    }

    ${props => (props.isSelected === true) && css`
        background-color: white;
        color: black;
        border: none;
        border-radius: 20px 20px 0px 0px;
    `}
`;

const HeaderButton: FunctionComponent<HeaderButtonProps> = ({ text, onClick, isSelected, ...props }: HeaderButtonProps) => {
    return (
        <StyledButton onClick={onClick} isSelected={isSelected} {...props}>
            <Text>{text}</Text>
        </StyledButton>
    );
};

export default HeaderButton;

HeaderButton.defaultProps = {
    onClick: () => null,
    text: '',
};
