import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import Dashboard from './Dashboard';
import Overview from './Overview';

const SummaryDashboard: FunctionComponent = () => {
    return (
        <MainWrapper>
            <Dashboard />
            <Overview />
        </MainWrapper>
    );
};

const MainWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;

    background-color: #F1F6F8;
    border-radius: 20px;

    padding-top: 1.6%;
    padding-left: 1.6%;
    padding-bottom: 1%;

    width: 71%;
    height: 100%;
    margin-bottom: 5%;
    margin-right: 7%;
`;

export default SummaryDashboard;
