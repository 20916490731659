import React, { useEffect, useState } from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import { useLocation } from 'react-router';
import { connect } from 'react-redux';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import FollowersHeader from 'components/analytics/FollowersHeader';
import ListTable from 'components/contests/ListTable';
import Flex from 'components/Flex';
import ContentWrapper from 'components/ContentWrapper';
import AnalyticsHeader from 'components/analytics/AnalyticsHeader';
import { ButtonStyles } from 'components/styles';

import { IContestList, IGetContestsReduxParam, ContestListTypesEnum, IContestStatusEnum, ICreateContest, ICreateQuizModalState } from 'entities/contests';

import Icons from 'assets/icons/Index';
import CreateContestModal from './CreateQuizModal';
import DeleteQuizModal from './DeleteContestModal';
import EditQuizModal from './EditQuizModal';

interface OverviewProps {
    isLanu: boolean;

    activeQuizList: IContestList;
    activeQuizListError: string;
    activeQuizListIsLoading: boolean;
    activeQuizListMaxPage: number;
    activeQuizListCurrentPage: number;
    activeQuizListCurrentIndex: number;

    inactiveQuizList:IContestList;
    inactiveQuizListError: string;
    inactiveQuizListIsLoading: boolean;
    inactiveQuizListMaxPage: number;
    inactiveQuizListCurrentPage: number;
    inactiveQuizListCurrentIndex: number;

    setActiveQuizListIndex: (param: number) => void;
    setInactiveQuizListIndex: (param: number) => void;

    getActiveQuiz: (param:IGetContestsReduxParam) => void;
    getInactiveQuiz: (param:IGetContestsReduxParam) => void;

    setCreateModalContest: (param: ICreateQuizModalState) => void;
}

const Overview:React.FunctionComponent<OverviewProps> = ({
    isLanu,

    activeQuizList,
    activeQuizListError,
    activeQuizListIsLoading,
    activeQuizListMaxPage,
    activeQuizListCurrentPage,
    activeQuizListCurrentIndex,

    inactiveQuizList,
    inactiveQuizListError,
    inactiveQuizListIsLoading,
    inactiveQuizListMaxPage,
    inactiveQuizListCurrentPage,
    inactiveQuizListCurrentIndex,

    setActiveQuizListIndex,
    setInactiveQuizListIndex,

    getActiveQuiz,
    getInactiveQuiz,

    setCreateModalContest,
}) => {
    const tableHeaders = ['Name of Contest', 'Total Players', 'Eligible Winners', 'Total Clicks', 'Max Winners', 'Correct Answers Needed', 'Actions'];

    const contestType = ContestListTypesEnum.Quiz;

    const { pathname } = useLocation();

    const [render, setRender] = useState<boolean>(false);

    const capitalizeFirstLetter = (title:string) => {
        return title[0].toUpperCase() + title.slice(1);
    };

    useEffect(() => {
        getActiveQuiz({ index: activeQuizListCurrentIndex });
        getInactiveQuiz({ index: inactiveQuizListCurrentIndex });
    }, [isLanu, activeQuizListCurrentIndex, inactiveQuizListCurrentIndex]);

    useEffect(() => {
        setRender(true);
        setActiveQuizListIndex(1);
        setInactiveQuizListIndex(1);
        getActiveQuiz({ index: activeQuizListCurrentIndex });
        getInactiveQuiz({ index: inactiveQuizListCurrentIndex });
    }, [isLanu]);

    useEffect(() => {
        setRender(false);
    }, [render === true]);

    return (
        <ContentWrapper>
            <Flex alignItems='center' gap='1rem' justifyContent='space-between' flexWrap='wrap'>
                <AnalyticsHeader
                    header={`Contest - ${capitalizeFirstLetter(contestType)}`}
                    size='lg'
                />
                <StyledButton
                    type='button'
                    css={ButtonStyles.primary}
                    onClick={() => setCreateModalContest({
                        isOpen: true,
                        type: ContestListTypesEnum.Quiz,
                    })}
                >
                    Create New
                    {` ${capitalizeFirstLetter(contestType)}`}
                </StyledButton>
            </Flex>
            <OverviewContainer>
                <FollowersHeader
                    headerContent={`Current Active ${contestType.charAt(0).toUpperCase() + contestType.slice(1)}`}
                    headerSize='sm'
                    tooltipIconSize='1rem'
                    tooltipId={`contest-${contestType}-tooltip-active`}
                    tooltipIconSrc={Icons.QuestionMark}
                    tooltipContent='There can only be 1 active game or quiz across multiple channels.'
                />

                <ListTable
                    tableId={`active-${contestType}`}
                    contestStatus={IContestStatusEnum.Active}
                    contestType={ContestListTypesEnum.Quiz}
                    headers={tableHeaders}
                    isLoading={activeQuizListIsLoading}
                    contestList={activeQuizList}
                    contestError={activeQuizListError}
                    contestMaxPage={activeQuizList.maxIndex}
                    contestCurrentPage={activeQuizListCurrentPage}
                    contestCurrentIndex={activeQuizListCurrentIndex}
                    setContestIndex={setActiveQuizListIndex}
                    tablePathname={pathname}
                    renderState={render}
                />

                <FollowersHeader
                    headerContent={`${contestType.charAt(0).toUpperCase() + contestType.slice(1)} History (Inactive)`}
                    headerSize='sm'
                    tooltipIconSize='1rem'
                    tooltipId={`contest-${contestType}-tooltip-inactive`}
                    tooltipIconSrc={Icons.QuestionMark}
                    tooltipContent='Details of inactive quizzes that has been created in the past.'
                />
                <ListTable
                    tableId={`inactive-${contestType}`}
                    contestStatus={IContestStatusEnum.Inactive}
                    contestType={ContestListTypesEnum.Quiz}
                    headers={tableHeaders}
                    isLoading={inactiveQuizListIsLoading}
                    contestList={inactiveQuizList}
                    contestError={inactiveQuizListError}
                    contestMaxPage={inactiveQuizList.maxIndex}
                    contestCurrentPage={inactiveQuizListCurrentPage}
                    contestCurrentIndex={inactiveQuizListCurrentIndex}
                    setContestIndex={setInactiveQuizListIndex}
                    tablePathname={pathname}
                    renderState={render}
                />

            </OverviewContainer>
            <CreateContestModal />
            <DeleteQuizModal />
            <EditQuizModal />
        </ContentWrapper>
    );
};

interface CustomStylingProps {
    css?: FlattenSimpleInterpolation;
}

const StyledButton = styled.button<CustomStylingProps>`
    ${props => props.css}
`;

const OverviewContainer = styled.div`
    margin:1rem 0;
`;

const mapStateToProps = (state: RootState) => ({
    isLanu: Selectors.getFaqGetIsLANU(state),

    activeQuizList: Selectors.contestGetActiveQuizList(state),
    activeQuizListError: Selectors.contestGetActiveQuizListError(state),
    activeQuizListIsLoading: Selectors.contestGetActiveQuizListAttempting(state),
    activeQuizListMaxPage: Selectors.contestGetActiveQuizListMaxIndex(state),
    activeQuizListCurrentPage: Selectors.contestGetActiveQuizListPage(state),
    activeQuizListCurrentIndex: Selectors.contestGetActiveQuizListIndex(state),

    inactiveQuizList: Selectors.contestGetInactiveQuizList(state),
    inactiveQuizListError: Selectors.contestGetInactiveQuizListError(state),
    inactiveQuizListIsLoading: Selectors.contestGetInactiveQuizListAttempting(state),
    inactiveQuizListMaxPage: Selectors.contestGetInactiveQuizListMaxIndex(state),
    inactiveQuizListCurrentPage: Selectors.contestGetInactiveQuizListPage(state),
    inactiveQuizListCurrentIndex: Selectors.contestGetInactiveQuizListIndex(state),

});

const mapDispatchToProps = (dispatch: AppDispatch) => ({

    setActiveQuizListIndex: (param: number) => dispatch(Actions.setActiveQuizListIndex(param)),
    setActiveGamesListIndex: (param: number) => dispatch(Actions.setActiveGamesListIndex(param)),

    setInactiveQuizListIndex: (param: number) => dispatch(Actions.setInactiveQuizListIndex(param)),
    setInactiveGamesListIndex: (param: number) => dispatch(Actions.setInactiveGamesListIndex(param)),

    getActiveGames: (param: IGetContestsReduxParam) => dispatch(Actions.getActiveGamesListAttempt(param)),
    getActiveQuiz: (param: IGetContestsReduxParam) => dispatch(Actions.getActiveQuizListAttempt(param)),

    getInactiveGames: (param: IGetContestsReduxParam) => dispatch(Actions.getInactiveGamesListAttempt(param)),
    getInactiveQuiz: (param: IGetContestsReduxParam) => dispatch(Actions.getInactiveQuizListAttempt(param)),

    createQuiz: (data: ICreateContest) => dispatch(Actions.createContestAttempt(data)),

    setCreateModalContest: (params: ICreateQuizModalState) => dispatch(Actions.contestSetCreateQuizModalState(params)),

});

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
