import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';

import { useLocation } from 'react-router';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';

import { AnalyticsTypeEnum } from 'entities/analytics';

import SVG from 'react-inlinesvg';
import Icons from 'assets/icons/Index';
import NavActions from 'lib/NavActions';
import Fonts from 'assets/themes/Fonts';

interface SideMenuButtonProps {
    selected?: boolean;
}

interface SideMenuProps {
    selectedAnalyticsType: AnalyticsTypeEnum;

    setSelectedAnalyticsType: (type: AnalyticsTypeEnum) => void;
    setMasterExportModalOpen: (state: boolean) => void;
}

enum DropdownTypeEnum {
    Contest = 1,
    ChannelAnalytics = 2,
    EcommAnalytics = 3,
}

const SideMenu: FunctionComponent<SideMenuProps> = (props: SideMenuProps) => {
    const { selectedAnalyticsType, setSelectedAnalyticsType, setMasterExportModalOpen } = props;

    const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false);
    const [contestDropdownIsOpen, setContestDropdownIsOpen] = useState<boolean>(false);
    const [ecommAnalyticsDropdownIsOpen, setEcommAnalyticsDropdownIsOpen] = useState<boolean>(false);

    const location = useLocation();
    const currentPath = location.pathname.split('/');

    useEffect(() => {
        // check to see which sidemenu should be opened (to handle refreshes)
        if (checkPathname('contests', 1) && !contestDropdownIsOpen) setContestDropdownIsOpen(true);
        if (checkPathname('ecommerceAnalytics', 1) && !ecommAnalyticsDropdownIsOpen) {
            setEcommAnalyticsDropdownIsOpen(true);
        }
        if (checkPathname('analytics', 1) && !dropdownIsOpen) setDropdownIsOpen(true);
    }, []);

    const checkPathname = (path: string, index = 1) => {
        if (currentPath[index] === path) return true;
        return false;
    };

    const toggleDropdown = (type: DropdownTypeEnum) => {
        switch (type) {
            case DropdownTypeEnum.Contest: {
                setContestDropdownIsOpen(!contestDropdownIsOpen);
                setDropdownIsOpen(false);
                setEcommAnalyticsDropdownIsOpen(false);
                break;
            }
            case DropdownTypeEnum.ChannelAnalytics: {
                setDropdownIsOpen(!dropdownIsOpen);
                setContestDropdownIsOpen(false);
                setEcommAnalyticsDropdownIsOpen(false);
                break;
            }
            case DropdownTypeEnum.EcommAnalytics:
            default: {
                setEcommAnalyticsDropdownIsOpen(!ecommAnalyticsDropdownIsOpen);
                setDropdownIsOpen(false);
                setContestDropdownIsOpen(false);
            }
        }
    };

    const renderMenuItem = (params: { label: string, selected: boolean, onClick: () => any, dropdownType?: DropdownTypeEnum }) => {
        const { selected, onClick, label, dropdownType } = params;

        let buttonClicked = onClick;

        if (!dropdownType) {
            buttonClicked = () => [
                onClick(),
                setDropdownIsOpen(false),
                setContestDropdownIsOpen(false),
                setEcommAnalyticsDropdownIsOpen(false),
            ];
        } else {
            buttonClicked = () => [
                onClick(),
                toggleDropdown(dropdownType),
            ];
        }

        return (
            <SideMenuButton
                selected={selected}
                onClick={buttonClicked}
            >
                <div style={{ fontFamily: `${Fonts.secondary}` }}>{label}</div>
            </SideMenuButton>
        );
    };

    const renderSubmenuItem = (params: { dropdownType: DropdownTypeEnum, items: { selected: boolean, label: string, onClick: () => void }[]}) => {
        const { items, dropdownType } = params;

        let isVisible = false;

        switch (dropdownType) {
            case DropdownTypeEnum.Contest: {
                if (contestDropdownIsOpen) isVisible = true;
                break;
            }
            case DropdownTypeEnum.ChannelAnalytics: {
                if (dropdownIsOpen) isVisible = true;
                break;
            }
            case DropdownTypeEnum.EcommAnalytics:
            default: {
                if (ecommAnalyticsDropdownIsOpen) isVisible = true;
                break;
            }
        }

        if (!isVisible) return null;

        return (
            <StyledSideDropMenu>
                {items.map((submenuItem) => {
                    const { selected, label, onClick } = submenuItem;

                    return (
                        <SideDropdownMenuWrapper key={`${dropdownType}-${label}`}>
                            <div style={{ width: '5%' }}>
                                {selected && (
                                    <SVG
                                        style={{
                                            width: '11px',
                                            height: '8px',
                                            marginRight: '5px',
                                            fill: '#00D1D2',
                                            strokeWidth: '0.1px',
                                            transform: 'scale(1.8)',
                                        }}
                                        src={Icons.RightArrow}
                                        id='editIcon'
                                    />
                                )}
                            </div>
                            <SideDropMenuButton
                                selected={selected}
                                onClick={onClick}
                            >
                                {label}
                            </SideDropMenuButton>
                        </SideDropdownMenuWrapper>
                    );
                })}
            </StyledSideDropMenu>
        );
    };

    return (
        <StyledSideMenu>
            {renderMenuItem({
                selected: checkPathname('faq'),
                label: 'FAQ',
                onClick: NavActions.navToHome,
            })}

            {renderMenuItem({
                selected: checkPathname('agebot'),
                label: 'Agebot',
                onClick: NavActions.navToAgeBot,
            })}

            {renderMenuItem({
                selected: checkPathname('quiz', 2) || checkPathname('game', 2) || checkPathname('contests', 1),
                label: 'Contests',
                onClick: NavActions.navToContestsQuiz,
                dropdownType: DropdownTypeEnum.Contest,
            })}

            {renderSubmenuItem({
                dropdownType: DropdownTypeEnum.Contest,
                items: [{
                    selected: checkPathname('quiz', 2),
                    label: 'Contest - Quiz',
                    onClick: NavActions.navToContestsQuiz,
                }, {
                    selected: checkPathname('game', 2),
                    label: 'Contest - Game',
                    onClick: NavActions.navToContestsGame,
                }],
            })}

            {renderMenuItem({
                selected: checkPathname('analytics'),
                onClick: NavActions.navToSummaryDashboard,
                dropdownType: DropdownTypeEnum.ChannelAnalytics,
                label: 'Channel Analytics',
            })}

            {renderSubmenuItem({
                dropdownType: DropdownTypeEnum.ChannelAnalytics,
                items: [{
                    selected: location.pathname === '/analytics/summaryDashboard',
                    onClick: NavActions.navToSummaryDashboard,
                    label: 'Summary Dashboard',
                }, {
                    selected: selectedAnalyticsType === AnalyticsTypeEnum.growth && (location.pathname === '/analytics'),
                    onClick: () => [
                        setSelectedAnalyticsType(AnalyticsTypeEnum.growth),
                        NavActions.navToAnalytics(),
                    ],
                    label: 'Growth',
                }, {
                    selected: selectedAnalyticsType === AnalyticsTypeEnum.followers && (location.pathname === '/analytics/followers' || location.pathname === '/analytics/followers/fullFollowersList'),
                    onClick: () => [
                        setSelectedAnalyticsType(AnalyticsTypeEnum.followers),
                        NavActions.navToFollowersScreen(),
                    ],
                    label: 'Followers',
                }, {
                    selected: selectedAnalyticsType === AnalyticsTypeEnum.notifications && (location.pathname === '/analytics'),
                    onClick: () => [
                        setSelectedAnalyticsType(AnalyticsTypeEnum.notifications),
                        NavActions.navToAnalytics(),
                    ],
                    label: 'Notifications',
                }, {
                    selected: selectedAnalyticsType === AnalyticsTypeEnum.viewBySources && (location.pathname === '/analytics'),
                    onClick: () => [
                        setSelectedAnalyticsType(AnalyticsTypeEnum.viewBySources),
                        NavActions.navToAnalytics(),
                    ],
                    label: 'Views By Source',
                }, {
                    selected: selectedAnalyticsType === AnalyticsTypeEnum.languages && (location.pathname === '/analytics'),
                    onClick: () => [
                        setSelectedAnalyticsType(AnalyticsTypeEnum.languages),
                        NavActions.navToAnalytics(),
                    ],
                    label: 'Languages',
                }, {
                    selected: selectedAnalyticsType === AnalyticsTypeEnum.interaction && (location.pathname === '/analytics'),
                    onClick: () => [
                        setSelectedAnalyticsType(AnalyticsTypeEnum.interaction),
                        NavActions.navToAnalytics(),
                    ],
                    label: 'Interactions',
                }, {
                    selected: selectedAnalyticsType === AnalyticsTypeEnum.posts && (location.pathname === '/analytics/posts'),
                    onClick: () => [
                        setSelectedAnalyticsType(AnalyticsTypeEnum.posts),
                        NavActions.navToAnalyticsPosts(),
                    ],
                    label: 'Posts',
                }, {
                    selected: selectedAnalyticsType === AnalyticsTypeEnum.hour && (location.pathname === '/analytics'),
                    onClick: () => [
                        setSelectedAnalyticsType(AnalyticsTypeEnum.hour),
                        NavActions.navToAnalytics(),
                    ],
                    label: 'Views By Hour',
                }, {
                    selected: false,
                    onClick: () => setMasterExportModalOpen(true),
                    label: 'Master Export',
                }],
            })}

            {renderMenuItem({
                label: 'Broadcast',
                onClick: NavActions.navToBroadcast,
                selected: checkPathname('broadcast'),
            })}

            {renderMenuItem({
                label: 'E-Commerce',
                onClick: NavActions.navToECommerce,
                selected: checkPathname('ecommerce'),
            })}

            {renderMenuItem({
                label: 'E-Commerce Analytics',
                onClick: NavActions.navToEcommerceAnalyticsClicksReport,
                dropdownType: DropdownTypeEnum.EcommAnalytics,
                selected: checkPathname('ecommerceAnalytics', 1),
            })}

            {renderSubmenuItem({
                dropdownType: DropdownTypeEnum.EcommAnalytics,
                items: [{
                    selected: checkPathname('clicks', 2) || checkPathname('productClicks', 2),
                    label: 'Clicks Report',
                    onClick: NavActions.navToEcommerceAnalyticsClicksReport,
                }, {
                    selected: checkPathname('buyNowClicks', 2) || checkPathname('buyNowProductClicks', 2),
                    label: 'Buy Now Clicks Report',
                    onClick: NavActions.navToEcommerceAnalyticsBuyNowReport,
                }, {
                    selected: checkPathname('activities', 2),
                    label: 'Activities Report',
                    onClick: NavActions.navToEcommerceAnalyticsActivitiesReport,
                }, {
                    selected: checkPathname('funnel', 2),
                    label: 'Funnel Report',
                    onClick: NavActions.navToEcommerceAnalyticsFunnelReport,
                }],
            })}

            {renderMenuItem({
                label: 'PNC',
                onClick: NavActions.navToPncMessage,
                selected: checkPathname('pncmessage'),
            })}
        </StyledSideMenu>
    );
};

const StyledSideMenu = styled.div`
    background-color: white;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    position: fixed;
    top: 14%;
    left: 0;
    
    width: 22%;
    padding-left: 8%;

    height: 100%;

    @media (max-width: 1580px) {
        margin: 0 0 0 60px;
        padding: 0;
        width: 15%;
        transform: scale(0.9);
    }
`;

const StyledSideDropMenu = styled.div`
    background-color: white;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    align-self: flex-start;

    top: 0px;
    left: 0px;

    width: 100%;
    // height: 335px;
`;

const SideMenuButton = styled.button<SideMenuButtonProps>`
    background-color: white;

    border: ${props => (props.selected ? '1px solid black' : 'none')};

    color: ${props => (props.selected ? 'black' : 'lightgrey')};

    width: 70%;
    height: 50px;

    font-family: ${Fonts.secondary} !important;

    text-align: left;

    font-size: 13px;

    border-radius: 5px;

    transition: all ease 0.3s;
`;

const SideDropMenuButton = styled.button<SideMenuButtonProps>`
    background-color: white;

    display: flex;

    align-items: center;
    
    border: none;

    color: ${props => (props.selected ? 'black' : 'grey')};

    font-weight: ${props => (props.selected ? 'bold' : 'none')};

    width: 100%;
    height: 31px;

    font-family: ${Fonts.primary};

    margin-top: 2px;

    text-align: left;

    font-size: 13px;

    border-radius: 5px;

    transition: all ease 0.3s;
`;

const SideDropdownMenuWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const mapStateToProps = (state: RootState) => ({
    isLanu: Selectors.getFaqGetIsLANU(state),

    selectedAnalyticsType: Selectors.getAnalyticsGetSelectedAnalyticsType(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setSelectedAnalyticsType: (type: AnalyticsTypeEnum) => dispatch(Actions.setAnalyticsType(type)),
    setMasterExportModalOpen: (state: boolean) => dispatch(Actions.setMasterExportModalOpen(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
