import React from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import { Table, Row, Col } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';
import xlsx, { IContent } from 'json-as-xlsx';
import dayjs from 'dayjs';

import { RootState } from 'redux/store';
import Selectors from 'redux/Selectors';

import SpreadSheetColumns from 'lib/SpreadSheetColumns';

import Flex from 'components/Flex';
import FollowersHeader from 'components/analytics/FollowersHeader';

import Icons from 'assets/icons/Index';

import { ButtonStyles, TableStyles } from 'components/styles';
import Colors from 'assets/themes/Colors';
import { IFollowersTableInfo } from 'entities/followers';
import { toast } from 'react-toastify';

interface TablesProps {
    isLoading: boolean;
    followersDateError: string;
    followersDateData: IFollowersTableInfo;
}

const Tables:React.FunctionComponent<TablesProps> = ({
    isLoading,
    followersDateError,
    followersDateData,
}) => {
    const renderDailyData = () => {
        if (isLoading) {
            return (
                <tr>
                    <StyledTdTemp colSpan={4}>
                        <Skeleton count={5} height={30} />
                    </StyledTdTemp>
                </tr>
            );
        }
        if (followersDateError) return <tr><StyledTdTemp colSpan={4}>{followersDateError}</StyledTdTemp></tr>;

        if (!followersDateData.followersInfoDailyData.length) {
            return <tr><StyledTdTemp colSpan={4}>There appears to be no data yet.</StyledTdTemp></tr>;
        }

        return followersDateData && followersDateData.followersInfoDailyData.map((item) => {
            return (
                <tr key={`${item.date} + `}>
                    <td>{item.date}</td>
                    <td>{item.newFollowers}</td>
                    <td>{item.unsubscribers}</td>
                    <td>{item.netFollowers}</td>
                </tr>
            );
        });
    };

    const renderMonthlyData = () => {
        if (isLoading) {
            return (
                <tr>
                    <StyledTdTemp colSpan={4}>
                        <Skeleton count={5} height={30} />
                    </StyledTdTemp>
                </tr>
            );
        }
        if (followersDateError) return <tr><StyledTdTemp colSpan={4}>{followersDateError}</StyledTdTemp></tr>;

        if (!followersDateData.followersInfoMonthlyData.length) {
            return <tr><StyledTdTemp colSpan={4}>There appears to be no data yet.</StyledTdTemp></tr>;
        }

        return followersDateData && followersDateData.followersInfoMonthlyData.map((item) => {
            return (
                <tr key={JSON.stringify(item)}>
                    <td>{item.month}</td>
                    <td>{item.newFollowers}</td>
                    <td>{item.unsubscribers}</td>
                    <td>{item.netFollowers}</td>
                </tr>
            );
        });
    };

    const onClickExportFollowersDailyInfoToXls = () => {
        const {
            FollowersDailyData,
        } = SpreadSheetColumns;

        const { followersInfoDailyData } = followersDateData;

        const parsedFollowersDailyData = followersInfoDailyData.map(item => {
            const { date, newFollowers, unsubscribers, netFollowers } = item;

            const splitDate = date.split('-');
            const newDate = dayjs(`${splitDate[1]}-${splitDate[0]}-${splitDate[2]}`).toISOString();
            const newDateFormat = dayjs(newDate).format('DD-MMM-YYYY');

            return {
                date: newDateFormat,
                newFollowers,
                unsubscribers,
                netFollowers,
            };
        });

        const dataToExport = [
            {
                sheet: 'Daily Followers',
                columns: FollowersDailyData,
                content: parsedFollowersDailyData as IContent[],
            },
            // append/push parsedFollowersMonthlyData into dataToExport add monthly data sheet in the excel file
        ];

        if (followersDateError || !followersDateData.followersInfoDailyData.length) toast.error('No data available.');
        else xlsx(dataToExport, { fileName: 'IQOS Followers Data' });
    };

    return (
        <div>
            <Flex justifyContent='space-between' alignItems='center' flexWrap='wrap'>
                <FollowersHeader
                    headerContent='Followers Tables'
                    headerSize='md'
                    tooltipId='followers-tables'
                    tooltipIconSrc={Icons.QuestionMark}
                    tooltipMaxWidth='unset'
                    tooltipContent={(
                        <StyledUl>
                            <li>
                                <div>New Followers:</div>
                                <div>Total number of new followers in the selected date range.</div>
                            </li>
                            <li>
                                <div>Unsubscribers:</div>
                                <div>Total number of unsubscribers in the selected date range.</div>
                            </li>
                            <li>
                                <div>Net Followers:</div>
                                <div>Total number of new followers minus by total number of unsubscribers of the selected date range.</div>
                            </li>
                        </StyledUl>
                    )}
                />
                <StyledButton
                    type='button'
                    css={ButtonStyles.primary}
                    onClick={() => onClickExportFollowersDailyInfoToXls()}
                >
                    Export Data
                </StyledButton>
            </Flex>
            <Row>
                <Col xs='12' md='6'>
                    <StyledTableWrapper>
                        <StyledTable css={TableStyles.primary}>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>New Followers</th>
                                    <th>Unsubscribers</th>
                                    <th>Net Followers</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(followersDateData && followersDateData.followersInfoDailyData.length && !followersDateError) ? (
                                    <tr style={
                                        {
                                            backgroundColor: Colors.iqosLightPrimary,
                                            fontWeight: 'bold',
                                        }
                                    }
                                    >
                                        <td>Total</td>
                                        <td>{followersDateData.totalNewFollowers}</td>
                                        <td>{followersDateData.totalUnsubscribers}</td>
                                        <td style={{ borderTopRightRadius: '10px !important' }}>{followersDateData.totalNetFollowers}</td>
                                    </tr>
                                ) : null}
                                {renderDailyData()}
                            </tbody>
                        </StyledTable>
                    </StyledTableWrapper>
                </Col>
                <Col xs='12' md='6'>
                    <StyledTableWrapper>
                        <StyledTable css={TableStyles.primary}>
                            <thead>
                                <tr>
                                    <th>Month</th>
                                    <th>New Followers</th>
                                    <th>Unsubscribers</th>
                                    <th>Net Followers</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(followersDateData && followersDateData.followersInfoMonthlyData.length && !followersDateError) ? (
                                    <tr style={
                                        {
                                            backgroundColor: Colors.iqosLightPrimary,
                                            fontWeight: 'bold',
                                        }
                                    }
                                    >
                                        <td>Total</td>
                                        <td>{followersDateData.totalNewFollowers}</td>
                                        <td>{followersDateData.totalUnsubscribers}</td>
                                        <td>{followersDateData.totalNetFollowers}</td>
                                    </tr>
                                ) : null}
                                {renderMonthlyData()}
                            </tbody>
                        </StyledTable>
                    </StyledTableWrapper>
                </Col>
            </Row>
        </div>
    );
};

const StyledUl = styled.ul`
    text-align:left;
    padding-right:.5rem;
    > li {
        > div:first-child {
            font-weight:bold;
            margin-top:.5rem;

        }
    }
`;

const StyledTdTemp = styled.td`
    min-height:200px;
    text-align:center;
`;

interface StyledButtonProps {
    css?: FlattenSimpleInterpolation;
}

type StyledTableProps = StyledButtonProps;

const StyledButton = styled.button<StyledButtonProps>`
    cursor:pointer;
    ${props => props.css}
`;

const StyledTable = styled(Table)<StyledTableProps>`
    margin-bottom: unset !important;

    thead tr th {
        position:sticky;
        top:0;
        background-color:${Colors.white};
    }

    tr {
        white-space: nowrap; 
    }
    ${props => props.css}
`;

interface StyledTableWrapperProps {
    scroll?: boolean;
}

const StyledTableWrapper = styled.div<StyledTableWrapperProps>`
    margin: 1rem 0;
    border-radius:8px;
    overflow-x:auto;
    overflow-y:auto;
    max-height: 768px;
    box-sizing: content-box;
    
    ::-webkit-scrollbar {
        width: 6px;
        height:6px;
    }
      
    ::-webkit-scrollbar-track {
    background: #f1f1f1; 
    }
       
    ::-webkit-scrollbar-thumb {
    background: #888; 
    }
      
    ::-webkit-scrollbar-thumb:hover {
    background: #555; 
    }

    scrollbar-gutter: stable !important;
`;

const mapStateToProps = (state: RootState) => ({
    isLoading: Selectors.followersGetFollowersTablesInfoAttempting(state),
    followersDateError: Selectors.followersGetFollowersTablesInfoError(state),
    followersDateData: Selectors.followersGetFollowersTablesInfo(state),
});

export default connect(mapStateToProps)(Tables);
