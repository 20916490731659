import { ApiResponse } from 'apisauce';

import { SummaryDashboardDetails, SubscribersOverview } from 'entities/summaryDashboard';
import { GatewayResponse } from './types/types';

import {
    ISummaryDashboardGateway,
    GetSummaryDashboardDetailsParams,
    GetSubscribersOverviewParams,
} from './SummaryDashboardBase';

const getHeaders = (authToken: string) => ({
    headers: {
        Authorization: `Bearer ${authToken}`,
    },
});

export default class SummaryDashboardGateway extends ISummaryDashboardGateway {
    async getSummaryDashboardDetails(params: GetSummaryDashboardDetailsParams): GatewayResponse<SummaryDashboardDetails> {
        const {
            type,
            dateFrom,
            dateTo,
            isYtd,
        } = params;
        const response: ApiResponse<SummaryDashboardDetails> = await this.api.get(`/v1/analytics/summary?channelType=${type}&dateFrom=${dateFrom}&dateTo=${dateTo}&isYtd=${isYtd}`, {}, getHeaders(params.authToken));

        return this.process(response);
    }

    async getSubscribersOverview(params: GetSubscribersOverviewParams): GatewayResponse<SubscribersOverview> {
        const { type, year } = params;
        const response: ApiResponse<SubscribersOverview> = await this.api.get(`/v1/analytics/subscribersOverview?channelType=${type}&year=${year}`, {}, getHeaders(params.authToken));

        return this.process(response);
    }
}
