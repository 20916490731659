import { fork } from 'redux-saga/effects';

import GamesGateway from 'api/Games';

import { RootSagaReturnType } from 'sagas/types';

import watchGetGames from './getGames';
import watchCreateGame from './createGame';
import watchDeleteGame from './deleteGame';
import watchEditGameDetails from './editGameDetails';
import watchGetGameDetails from './getGameDetails';
import watchGetGameDetailsResponses from './getGameResponses';
import watchGameExportResponses from './exportResponses';

export default (api: GamesGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetGames, api);
        yield fork(watchCreateGame, api);
        yield fork(watchDeleteGame, api);
        yield fork(watchEditGameDetails, api);
        yield fork(watchGetGameDetails, api);
        yield fork(watchGetGameDetailsResponses, api);
        yield fork(watchGameExportResponses, api);
    }

    return {
        rootSaga,
    };
};
