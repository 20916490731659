export enum IContestStatusEnum {
    Inactive = 0,
    Active = 1
}

export enum IChannelTypeEnum {
    LAU = 0,
    LANU = 1,
}

export interface IContest {
    id: string;
    name: string;
    status: IContestStatusEnum;
    type: IChannelTypeEnum;
    clicks: number;
    totalCorrectAnswers: number;
    [key: string]: any;
}

export interface IContestDetails {
    id: string;
    name: string;
    status: IContestStatusEnum;
    totalResponses: number;
    qualtricsId?: string;

    maxWinnersLau: number;
    maxWinnersLanu: number;
    correctAnswersNeeded: number;

}

export interface ICreateContest {
    name: string;
    maxWinnersLanu: number;
    maxWinnersLau: number;
    correctAnswersNeeded: number;
}

export interface IDeleteContest {
    _id: string;
}

export interface IContestResponse {
    id: string;
    firstName: string;
    username: string;
    type: IChannelTypeEnum;
    phoneNumber: number;
    clickTime: string;
    totalCorrectAnswers: number;
    eligible: boolean;
    [key: string]: any;
}

export interface IContestResponseExport {
    Id: string;
    'First Name': string;
    Username: string;
    'Phone Number': number;
    'Click Time': string;
}

export interface IGetContestResponsesResponse {
    index: number;
    maxIndex: number;
    data: IContestResponse[],
}

export interface IGetExportResponses {
    contestId: string;
}

export interface IEditContestParams {
    id: string;
    qualtricsId?: string;
    status: IContestStatusEnum;
    name?: string;
    maxWinnersLau: number;
    maxWinnersLanu: number;
    correctAnswersNeeded: number;
}

export interface IGetContestResponsesParams {
    contestId: string;
    index: number;
    type: IChannelTypeEnum;
}

export interface ISpreadsheetColumn {
    label: string;
    value: string;
}

export interface IGetContestDetailsSuccess {
    id: string;
    qualtricsId?: string;
    status: number;
    name: string;
    totalResponses: number;
    maxWinnersLau: number;
    maxWinnersLanu: number;
    correctAnswersNeeded: number;
}

const ContestDetailsColumns: ISpreadsheetColumn[] = [
    {
        label: 'First Name',
        value: 'firstName',
    },
    {
        label: 'Username',
        value: 'username',
    },
    {
        label: 'Phone Number',
        value: 'phoneNumber',
    },
    {
        label: 'Click Time',
        value: 'clickTime',
    },
];

export default {
    ContestDetailsColumns,
};

export interface IGetContestsReduxParam {
    index: number;
}

export interface IGetContestsListResponse {
    index: number;
    maxIndex: number;
    data: IContestsListData[];
}

export interface IGetContestListApiParam extends IGetContestsReduxParam {
    status: IContestStatusEnum;
    authToken: string;
    type: IChannelTypeEnum;
}

export interface IContestsListData {
    id: string;
    name: string;
    status: IContestStatusEnum;
    clicks: number;
    maxWinners: number;
    correctAnswersNeeded: number;
    eligibleWinners: number;
}

export enum ContestListTypesEnum {
    Quiz = 'quiz',
    Game= 'game',
}

export interface IContestList {
    index: number;
    maxIndex: number;
    data: IContestsListData[];
}

export interface ICreateQuizModalState {
    isOpen: boolean;
    type: ContestListTypesEnum;
}

export interface IEditQuizModalState extends ICreateQuizModalState {
    id: string;
}

export interface IDeleteQuizModalState extends ICreateQuizModalState {
    id: string
}
