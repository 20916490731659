import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { toast } from 'react-toastify';

import ECommerceGateway from 'api/ECommerce';
import { CreateProductCategoryParams } from 'redux/slices/eCommerce/types';

export default function* watchCreateProductCategory(api: ECommerceGateway): SagaWatcherReturnType {
    yield takeEvery('eCommerce/createProductCategoryAttempt', handleCreateProductCategory, api);
}

function* handleCreateProductCategory(api: ECommerceGateway, data: CreateProductCategoryParams) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const { categoryName, status } = data.payload;

    const response = yield* call([api, api.createProductCategory], {
        authToken,
        categoryName,
        status,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.createProductCategoryFailure(response.message));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.createProductCategorySuccess());
        yield put(Actions.getProductCategoryAttempt());
        yield put(Actions.setCreateCategoryModalIsOpen(false));
        toast.success('New Product Category created!');
    }
}
