import { SummaryDashboardDetails, SubscribersOverview } from 'entities/summaryDashboard';
import { SummaryDashboardState } from '.';

const getSummaryDashboardDetailsAttempt = (state: SummaryDashboardState): boolean => state.actions.summaryDashboardDetails || false;
const getSummaryDashboardDetailsFalure = (state: SummaryDashboardState): string => state.error.summaryDashboardDetails || '';
const getSummaryDashboardDetails = (state: SummaryDashboardState): SummaryDashboardDetails => state.summaryDashboardDetailsData;

const getSubscribersOverviewAttempt = (state: SummaryDashboardState): boolean => state.actions.subscribersOverview || false;
const getSubscribersOverviewFailure = (state: SummaryDashboardState): string => state.error.subscribersOverview || '';
const getSubscribersOverview = (state: SummaryDashboardState): SubscribersOverview => state.subscribersOverviewData;

export default {
    getSummaryDashboardDetailsAttempt,
    getSummaryDashboardDetailsFalure,
    getSummaryDashboardDetails,

    getSubscribersOverviewAttempt,
    getSubscribersOverviewFailure,
    getSubscribersOverview,
};
