import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';
import { CategoryIdEnum, EditPostsReport, GraphExportInfo, PerformingPosts, PostsOverview, PostsReport, PostsReportExport, RateByCategories, InformationPostModalInfo, RateByPost, RateByPostingTime, PostsReportWithAmountOfDays, UserClaims, EditToggleColumns } from 'entities/posts';
import { SummaryDashboardDetails } from 'entities/summaryDashboard';

export interface ParamsExtender {
    dateFrom: string;
    dateTo: string;
    channelType: string;
}

export interface GetPostsOverviewParams {
    authToken: string;
    channelType: string;
    month: string;
}

export interface GetRateByCategoriesParams extends ParamsExtender {
    authToken: string;
}
export interface GetRateByCategoriesModalInfoParams extends ParamsExtender {
    authToken: string;
    category: CategoryIdEnum;
    index: number;
}
export interface GetRateByCategoriesExportParams extends ParamsExtender {
    authToken: string;
    category: CategoryIdEnum;
}

export interface GetRateByPostingTimeParams extends ParamsExtender {
    authToken: string;
}
export interface GetRateByPostingTimeModalInfoParams extends ParamsExtender {
    authToken: string;
    hour: number;
    index: number;
}
export interface GetRateByPostingTimeExportParams extends ParamsExtender {
    authToken: string;
    hour: number;
}

export interface GetRateByPostParams extends ParamsExtender {
    authToken: string;
}
export interface GetRateByPostModalInfoParams extends ParamsExtender {
    authToken: string;
    week: number;
    index: number;
}
export interface GetRateByPostExportParams extends ParamsExtender {
    authToken: string;
    week: number
}

export interface GetPostsReportParams extends ParamsExtender {
    authToken: string;
    index: number;
    category: CategoryIdEnum;
}
export interface GetPostsReportExportParams extends ParamsExtender {
    authToken: string;
    category: CategoryIdEnum;
}
export interface EditPostsReportParams {
    authToken: string;
    data: EditPostsReport;
}

export interface GetPostsReportWithAmountOfDaysParams extends ParamsExtender {
    authToken: string;
    index: number;
    customDays: number;
}

export interface GetPerformingPostsParams extends ParamsExtender {
    authToken: string;
    sort: number;
}

export interface GetUserClaimsParams {
    authToken: string;
}

export interface EditToggleClaimsParams {
    authToken: string;
    data: EditToggleColumns;
}

export interface GetSummaryDashboardDetailsParams {
    authToken: string;
    type: string;
    dateFrom: string;
    dateTo: string;
    isYtd: string;
}
export abstract class IPostsGateway extends Gateway {
    abstract getPostsOverview(params: GetPostsOverviewParams): GatewayResponse<PostsOverview>;

    abstract getRateByCategories(params: GetRateByCategoriesParams): GatewayResponse<RateByCategories[]>;

    abstract getRateByCategoriesModalInfo(params: GetRateByCategoriesModalInfoParams): GatewayResponse<InformationPostModalInfo>;

    abstract getRateByCategoriesExport(params: GetRateByCategoriesExportParams): GatewayResponse<GraphExportInfo[]>;

    abstract getRateByPostingTime(params: GetRateByPostingTimeParams): GatewayResponse<RateByPostingTime[]>;

    abstract getRateByPostingTimeModalInfo(params: GetRateByPostingTimeModalInfoParams): GatewayResponse<InformationPostModalInfo>;

    abstract getRateByPostingTimeExport(params: GetRateByPostingTimeExportParams): GatewayResponse<GraphExportInfo[]>;

    abstract getRateByPost(params: GetRateByPostParams): GatewayResponse<RateByPost[]>;

    abstract getRateByPostModalInfo(params: GetRateByPostModalInfoParams): GatewayResponse<InformationPostModalInfo>;

    abstract getRateByPostExport(params: GetRateByPostExportParams): GatewayResponse<GraphExportInfo[]>;

    abstract getPostsReport(params: GetPostsReportParams): GatewayResponse<PostsReport>;

    abstract getPostsReportExport(params: GetPostsReportExportParams): GatewayResponse<PostsReportExport[]>;

    abstract editPostsReport(params: EditPostsReportParams): GatewayResponse<EditPostsReport>;

    abstract getPostsReportWithAmountOfDays(params: GetPostsReportWithAmountOfDaysParams): GatewayResponse<PostsReportWithAmountOfDays>;

    abstract getPerformingPosts(params: GetPerformingPostsParams): GatewayResponse<PerformingPosts>;

    abstract getUserClaims(params: GetUserClaimsParams): GatewayResponse<UserClaims>;

    abstract editToggleColumn(params: EditToggleClaimsParams): GatewayResponse<EditToggleColumns>;

    abstract getSummaryDashboardDetails(params: GetSummaryDashboardDetailsParams): GatewayResponse<SummaryDashboardDetails>;
}
