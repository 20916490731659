import React, { FunctionComponent, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import dayjs from 'dayjs';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import FsLightbox from 'fslightbox-react';

import { connect } from 'react-redux';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { AppDispatch, RootState } from 'redux/store';

import 'components/StyledComponent.css';
import Text from 'components/Text';
import ButtonText from 'components/ButtonText';
import IQOSDateRangePicker from 'components/IQOSDateRangePicker';
import { EditToggleColumns, PerformingPosts, PerformingPostsFilter, PostTopLeastPerformingClaimsEnum, ReportEnum, UserClaims } from 'entities/posts';
import { IChannelTypeEnum } from 'entities/contests';
import Fonts from 'assets/themes/Fonts';
import Utils from 'lib/Utils';

interface PerformingPostsProps {
    isLanu: boolean;
    performingPostsData: PerformingPosts;
    isLoading: boolean;
    isFailure: string;
    getUserClaimsRedux: UserClaims;

    getDateFrom: Date;
    getDateTo: Date;
    setDateFrom(date: Date | null): void;
    setDateTo(date: Date | null): void;

    getPerformingPosts(channelType: string, dateFrom: string, dateTo: string, sort: number): void;
    editToggleColumn(data: EditToggleColumns): void;
}

const PerformingPostsTab: FunctionComponent<PerformingPostsProps> = (props: PerformingPostsProps) => {
    const {
        isLanu,
        performingPostsData,
        isLoading,
        isFailure,
        getUserClaimsRedux,

        getDateFrom,
        getDateTo,
        setDateFrom,
        setDateTo,
        getPerformingPosts,
        editToggleColumn,
    } = props;

    const filterData = getUserClaimsRedux.userRemovedColumnClaims.postTopLeastPerformingClaims;
    const topPosts = performingPostsData.top;
    const leastPosts = performingPostsData.least;

    const [sort, setSort] = useState<PerformingPostsFilter>(PerformingPostsFilter.Views);

    const [localDateFrom, setLocalDateFrom] = useState<Date | null>(getDateFrom);
    const [localDateTo, setLocalDateTo] = useState<Date | null>(getDateTo);

    const [columnFilterIsOpen, setColumnFilterIsOpen] = useState<boolean>(false);
    const [toggler, setToggler] = useState<boolean>(false);

    const [currentLightboxImage, setCurrentLightboxImage] = useState<string>('');

    const [postedOnCheck, setPostedOnCheck] = useState<boolean>(!(filterData.includes(PostTopLeastPerformingClaimsEnum.PostedOn)));
    const [categoryCheck, setCategoryCheck] = useState<boolean>(!(filterData.includes(PostTopLeastPerformingClaimsEnum.Category)));
    const [viewsCheck, setViewsCheck] = useState<boolean>(!(filterData.includes(PostTopLeastPerformingClaimsEnum.Views)));
    const [viewRateCheck, setViewRateCheck] = useState<boolean>(!(filterData.includes(PostTopLeastPerformingClaimsEnum.ViewRate)));
    const [engagementCheck, setEngagementCheck] = useState<boolean>(!(filterData.includes(PostTopLeastPerformingClaimsEnum.Engagement)));
    const [engagementRateCheck, setEngagementRateCheck] = useState<boolean>(!(filterData.includes(PostTopLeastPerformingClaimsEnum.EngagementRate)));

    useEffect(() => {
        getPerformingPosts(`${(isLanu) ? IChannelTypeEnum.LANU : IChannelTypeEnum.LAU}`, dayjs(getDateFrom).format('YYYY-MM-DD'), dayjs(getDateTo).format('YYYY-MM-DD'), sort);
    }, [isLanu, localDateFrom, localDateTo, sort]);

    useEffect(() => {
        setDateFrom(localDateFrom);
        setDateTo(localDateTo);
    }, [localDateFrom, localDateTo]);

    const toggleColumn = (columnEnum: PostTopLeastPerformingClaimsEnum, removeValue: boolean) => {
        editToggleColumn({
            reportEnum: ReportEnum.PostTopLeastPerforming,
            column: columnEnum,
            remove: removeValue,
        });
    };

    const renderTopPerformanceBody = () => {
        if (isLoading) {
            return (
                <tr>
                    <td colSpan={5}>
                        <div>
                            <Skeleton count={5} height={50} />
                        </div>
                    </td>
                </tr>
            );
        }
        if (isFailure) {
            return (
                <tr>
                    <td colSpan={5} style={{ padding: '20px', fontSize: '18px' }}>
                        <div>
                            <Text style={{ color: 'red' }}>Something went wrong. Please try again.</Text>
                        </div>
                    </td>
                </tr>
            );
        }
        if (!topPosts.length) {
            return (
                <tr>
                    <td colSpan={5} style={{ padding: '20px', fontSize: '18px' }}>
                        <div>
                            <Text>There appears to be no data yet. </Text>
                        </div>
                    </td>
                </tr>
            );
        }

        return (
            performingPostsData && (
                topPosts.map((item) => {
                    const { postId, title, mediaUrl, date, postCategory, views, viewRate, engagement, engagementRate } = item;

                    return (
                        <StyledTr key={postId}>
                            <StyledTd style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', textAlign: 'left', gap: '50px', minWidth: '550px', maxWidth: 'auto' }}>
                                <div title={title} style={{ display: 'block', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                    {title}
                                </div>
                                {(mediaUrl && mediaUrl !== '') ? (
                                    <StyledButton onClick={() => [setToggler(!toggler), setCurrentLightboxImage(mediaUrl)]}>
                                        <img src={mediaUrl} alt='iqos' style={{ width: '30px', height: '30px' }} />
                                    </StyledButton>
                                ) : (
                                    <div style={{ width: '30px', height: '30px' }} />
                                )}
                            </StyledTd>

                            {(postedOnCheck) && (
                                <StyledTd>
                                    {dayjs(date).format('DD-MM-YYYY')}
                                </StyledTd>
                            )}

                            {(categoryCheck) && (
                                <StyledTd style={{ fontSize: '13px', minWidth: '250px', maxWidth: '250px' }}>
                                    {(Utils.Posts.categoryEnumToStringConverter(postCategory)) || '-'}
                                </StyledTd>
                            )}

                            {(sort === PerformingPostsFilter.Views && viewsCheck) && (
                                <StyledTd>
                                    {views.toLocaleString()}
                                </StyledTd>
                            )}

                            {(sort === PerformingPostsFilter.Views && viewRateCheck) && (
                                <StyledTd>
                                    {(viewRate !== undefined) ? `${Utils.Posts.rateConverter(viewRate)}%` : '-'}
                                </StyledTd>
                            )}

                            {(sort === PerformingPostsFilter.Engagement && engagementCheck) && (
                                <StyledTd>
                                    {(engagement !== undefined) ? engagement.toLocaleString() : '-'}
                                </StyledTd>
                            )}

                            {(sort === PerformingPostsFilter.Engagement && engagementRateCheck) && (
                                <StyledTd>
                                    {(engagementRate !== undefined) ? `${Utils.Posts.rateConverter(engagementRate)}%` : '-'}
                                </StyledTd>
                            )}
                        </StyledTr>
                    );
                })
            ));
    };

    const renderLeastPerformanceBody = () => {
        if (isLoading) {
            return (
                <tr>
                    <td colSpan={5}>
                        <div>
                            <Skeleton count={5} height={50} />
                        </div>
                    </td>
                </tr>
            );
        }

        if (isFailure) {
            return (
                <tr>
                    <td colSpan={5} style={{ padding: '20px', fontSize: '18px' }}>
                        <div>
                            <Text style={{ color: 'red' }}>Something went wrong. Please try again.</Text>
                        </div>
                    </td>
                </tr>
            );
        }

        if (!leastPosts.length) {
            return (
                <tr>
                    <td colSpan={5} style={{ padding: '20px', fontSize: '18px' }}>
                        <div>
                            <Text>There appears to be no data yet. </Text>
                        </div>
                    </td>
                </tr>
            );
        }

        return (
            performingPostsData && (
                leastPosts.map((item) => {
                    const { postId, title, mediaUrl, date, postCategory, views, viewRate, engagement, engagementRate } = item;

                    return (
                        <StyledTr key={postId}>
                            <StyledTd style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', textAlign: 'left', gap: '50px', minWidth: '550px', maxWidth: 'auto' }}>
                                <div title={title} style={{ display: 'block', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                    {title}
                                </div>
                                {(mediaUrl && mediaUrl !== '') ? (
                                    <StyledButton onClick={() => [setToggler(!toggler), setCurrentLightboxImage(mediaUrl)]}>
                                        <img src={mediaUrl} alt='iqos' style={{ width: '30px', height: '30px' }} />
                                    </StyledButton>
                                ) : (
                                    <div style={{ width: '30px', height: '30px' }} />
                                )}
                            </StyledTd>

                            {(postedOnCheck) && (
                                <StyledTd>
                                    {dayjs(date).format('DD-MM-YYYY')}
                                </StyledTd>
                            )}

                            {(categoryCheck) && (
                                <StyledTd style={{ fontSize: '13px', minWidth: '250px', maxWidth: '250px' }}>
                                    {(Utils.Posts.categoryEnumToStringConverter(postCategory)) || '-'}
                                </StyledTd>
                            )}

                            {(sort === PerformingPostsFilter.Views && viewsCheck) && (
                                <StyledTd>
                                    {views.toLocaleString()}
                                </StyledTd>
                            )}

                            {(sort === PerformingPostsFilter.Views && viewRateCheck) && (
                                <StyledTd>
                                    {(viewRate !== undefined) ? `${Utils.Posts.rateConverter(viewRate)}%` : '-'}
                                </StyledTd>
                            )}

                            {(sort === PerformingPostsFilter.Engagement && engagementCheck) && (
                                <StyledTd>
                                    {(engagement !== undefined) ? engagement.toLocaleString() : '-'}
                                </StyledTd>
                            )}

                            {(sort === PerformingPostsFilter.Engagement && engagementRateCheck) && (
                                <StyledTd>
                                    {(engagementRate !== undefined) ? `${Utils.Posts.rateConverter(engagementRate)}%` : '-'}
                                </StyledTd>
                            )}
                        </StyledTr>
                    );
                })
            ));
    };

    return (
        <TabBodyWrapper>
            <div style={{ width: '100%', padding: '50px 0px 0px 40px' }}>
                <StyledText style={{ fontFamily: `${Fonts.secondary}`, fontSize: '22px' }}>
                    Top & Least Performing Posts
                </StyledText>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', padding: '20px 20px 5px 40px', gap: '20px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', gap: '5px' }}>
                    <ButtonText label='By Views' onClick={() => setSort(0)} isSelected={(sort === 0)} css={styles.viewButton} />
                    <ButtonText label='By Engagement' onClick={() => setSort(1)} isSelected={(sort === 1)} css={styles.viewButton} />
                </div>
                <MonthWrapper>
                    <Dropdown isOpen={columnFilterIsOpen} toggle={() => setColumnFilterIsOpen(!columnFilterIsOpen)}>
                        <StyledDropdown caret style={{ background: 'transparent', color: 'black' }}>
                            <DropdownLabel>
                                Show All
                            </DropdownLabel>
                        </StyledDropdown>
                        <DropdownMenu container='body' style={{ width: '180px', textAlign: 'center', background: 'white' }}>
                            <FormGroup style={{ paddingLeft: '25px' }}>
                                <FormControlLabel control={<Checkbox size='small' checked={postedOnCheck} onChange={() => [setPostedOnCheck(!postedOnCheck), toggleColumn(PostTopLeastPerformingClaimsEnum.PostedOn, postedOnCheck)]} />} label='Posted On' />
                                <FormControlLabel control={<Checkbox size='small' checked={categoryCheck} onChange={() => [setCategoryCheck(!categoryCheck), toggleColumn(PostTopLeastPerformingClaimsEnum.Category, categoryCheck)]} />} label='Category' />
                                {(sort === PerformingPostsFilter.Views) && (
                                    <>
                                        <FormControlLabel control={<Checkbox size='small' checked={viewsCheck} onChange={() => [setViewsCheck(!viewsCheck), toggleColumn(PostTopLeastPerformingClaimsEnum.Views, viewsCheck)]} />} label='Views' />
                                        <FormControlLabel control={<Checkbox size='small' checked={viewRateCheck} onChange={() => [setViewRateCheck(!viewRateCheck), toggleColumn(PostTopLeastPerformingClaimsEnum.ViewRate, viewRateCheck)]} />} label='View Rate' />
                                    </>
                                )}
                                {(sort === PerformingPostsFilter.Engagement) && (
                                    <>
                                        <FormControlLabel control={<Checkbox size='small' checked={engagementCheck} onChange={() => [setEngagementCheck(!engagementCheck), toggleColumn(PostTopLeastPerformingClaimsEnum.Engagement, engagementCheck)]} />} label='Engagements' />
                                        <FormControlLabel control={<Checkbox size='small' checked={engagementRateCheck} onChange={() => [setEngagementRateCheck(!engagementRateCheck), toggleColumn(PostTopLeastPerformingClaimsEnum.EngagementRate, engagementRateCheck)]} />} label='Eng. Rate' />
                                    </>
                                )}
                            </FormGroup>
                        </DropdownMenu>
                    </Dropdown>

                    <DateDisplayText>
                        <IQOSDateRangePicker
                            dateFrom={localDateFrom}
                            dateTo={localDateTo}
                            setDateFrom={setLocalDateFrom}
                            setDateTo={setLocalDateTo}
                        />
                    </DateDisplayText>
                </MonthWrapper>
            </div>
            <StyledTable>
                <StyledTHead>
                    <tr>
                        <StyledHeaderTd style={{ textAlign: 'left', color: '#287BAF', width: '550px' }}>
                            Top 3 Performing Posts
                        </StyledHeaderTd>
                        {(postedOnCheck) && (
                            <StyledHeaderTd>Posted On</StyledHeaderTd>
                        )}
                        {(categoryCheck) && (
                            <StyledHeaderTd style={{ minWidth: '250px', maxWidth: '250px' }}>Category</StyledHeaderTd>
                        )}
                        {(sort === PerformingPostsFilter.Views && viewsCheck) && (
                            <StyledHeaderTd>Views</StyledHeaderTd>
                        )}
                        {(sort === PerformingPostsFilter.Views && viewRateCheck) && (
                            <StyledHeaderTd>View Rate</StyledHeaderTd>
                        )}
                        {(sort === PerformingPostsFilter.Engagement && engagementCheck) && (
                            <StyledHeaderTd>Engagement</StyledHeaderTd>
                        )}
                        {(sort === PerformingPostsFilter.Engagement && engagementRateCheck) && (
                            <StyledHeaderTd>Eng. Rate</StyledHeaderTd>
                        )}
                    </tr>
                </StyledTHead>
                <StyledTableBody>
                    {renderTopPerformanceBody()}
                </StyledTableBody>
            </StyledTable>
            <StyledTable>
                <StyledTHead>
                    <StyledHeaderTd style={{ textAlign: 'left', color: '#DB3624', width: '550px' }}>
                        Least 3 Performing Posts
                    </StyledHeaderTd>
                    {(postedOnCheck) && (
                        <StyledHeaderTd>Posted On</StyledHeaderTd>
                    )}
                    {(categoryCheck) && (
                        <StyledHeaderTd style={{ minWidth: '250px', maxWidth: '250px' }}>Category</StyledHeaderTd>
                    )}
                    {(sort === PerformingPostsFilter.Views && viewsCheck) && (
                        <StyledHeaderTd>Views</StyledHeaderTd>
                    )}
                    {(sort === PerformingPostsFilter.Views && viewRateCheck) && (
                        <StyledHeaderTd>View Rate</StyledHeaderTd>
                    )}
                    {(sort === PerformingPostsFilter.Engagement && engagementCheck) && (
                        <StyledHeaderTd>Engagement</StyledHeaderTd>
                    )}
                    {(sort === PerformingPostsFilter.Engagement && engagementRateCheck) && (
                        <StyledHeaderTd>Eng. Rate</StyledHeaderTd>
                    )}
                </StyledTHead>
                <StyledTableBody>
                    {renderLeastPerformanceBody()}
                </StyledTableBody>
            </StyledTable>
            <FsLightbox
                toggler={(toggler)}
                sources={[currentLightboxImage]}
            />
        </TabBodyWrapper>
    );
};

const TabBodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%; 
`;

const MonthWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    width: auto;
    margin-right: 13px;
`;

const StyledText = styled.text`
    text-align: left;
    font-size: 18px;
`;

const StyledHeaderTd = styled.td`
    padding: 20px 20px 20px 20px;
    font-size: 16px;
    whiteSpace: nowrap;
    overflow: hidden;
    text-Overflow: ellipsis;
    text-align: center;
    font-size: 15px;
    color: #959595;
`;

const DateDisplayText = styled.text`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    width: 400px;
    gap: 5px;
    padding-right: 15px;

    color: lightgrey;
    font-size: 18px;
    text-align: left;
`;

const StyledTable = styled.table`
    width: 95%;
    background-color: white;
    border-collapes: collapse;
    table-layout: fixed;
    
    margin-top: 20px;
    border-radius: 10px !important;
`;

const StyledTableBody = styled.tbody`
    text-align: center;
`;

const StyledTHead = styled.thead`
    text-align: center;
    border-bottom: 1px solid #C5C5C5;
`;

const StyledTr = styled.tr`
    &:nth-last-of-type(1){
        border-radius: 0px 0px 10px 10px !important;
    }

    &:nth-of-type(odd){
        background-color: #F6FCFC;
    }
`;

const StyledTd = styled.td`
    padding: 5px 25px 5px 25px;
    font-size: 16px;
    whiteSpace: nowrap;
    overflow: hidden;
    text-Overflow: ellipsis;
`;

const StyledButton = styled.button`
    display: flex;
    align-items: center;
    border: none;
    background: transparent;
    height: 75%;
    width: 10%;
    font-size: 11px;
`;

const StyledDropdown = styled(DropdownToggle)`
        background-color: #F1F6F8;
        font-size: 18px;
        font-family: ${Fonts.primary};
        color: black;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        width: 163px;
        height: 40px;

        border: 1px solid black;
        border-radius: 20px;
        cursor: pointer;

        @media (max-width: 1580px) {
            width: fit-content;
            background-color: transparent;
            color: black;
        }
`;

const DropdownLabel = styled.div`
    padding-left: 10px;
    margin-right: 10px ;
    overflow-x: hidden;
    @media (max-width: 1580px) {
        font-size: 12px;
    }
`;
const styles = {
    viewButton: css`
        width: 163px !important;
        height: 40px !important;
    `,

    filterButton: {
        backgroundColor: '#F1F6F8',
        fontSize: '18',
        fontFamily: 'Fonts.primary',
        color: 'black',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',

        width: '163px',
        height: '40px',

        border: '1px solid black',
        borderRadius: '20px',
        cursor: 'pointer',
    },
};

const mapStateToProps = (state: RootState) => ({
    isLanu: Selectors.getFaqGetIsLANU(state),
    performingPostsData: Selectors.getPostsGetPerformingPosts(state),
    isLoading: Selectors.getPostsGetPerformingPostsAttempting(state),
    isFailure: Selectors.getPostsGetPerformingPostsFailure(state),
    getDateFrom: Selectors.getPostsGetDateFrom(state),
    getDateTo: Selectors.getPostsGetDateTo(state),
    getUserClaimsRedux: Selectors.getPostsGetUserClaimsRedux(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getPerformingPosts: (channelType: string, dateFrom: string, dateTo: string, sort: number) => dispatch(Actions.getPerformingPostsAttempt({ channelType, dateFrom, dateTo, sort })),
    setDateFrom: (date: Date | null) => dispatch(Actions.setDateFrom(date)),
    setDateTo: (date: Date | null) => dispatch(Actions.setDateTo(date)),
    editToggleColumn: (data: EditToggleColumns) => dispatch(Actions.editToggleColumnsAttempt(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PerformingPostsTab);
