import React from 'react';
import { Row, Col, FormGroup, Spinner } from 'reactstrap';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

import { RootState, AppDispatch } from 'redux/store';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

import Flex from 'components/Flex';
import IQOSModal from 'components/IQOSModal';
import IconTooltip from 'components/IconTooltip';

import Icons from 'assets/icons/Index';
import Colors from 'assets/themes/Colors';

import Utils from 'lib/Utils';

import { ContestListTypesEnum, ICreateContest, ICreateQuizModalState } from 'entities/contests';
import { ICreateGame } from 'entities/games';

interface CreateContestModalProps {
    isLanu: boolean;
    modalState: ICreateQuizModalState;
    createQuizIsLoading: boolean;
    createQuizError: string;
    createGame: (params: ICreateGame) => void;
    createQuiz: (params: ICreateContest) => void;
    changeModalState: (params: ICreateQuizModalState) => void;
    clearCreateQuizError: () => void;

}

const CreateQuizSchema = z.object({
    name: z
        .string()
        .trim()
        .min(5, 'Minimum \'Contest Name\' length is 5'),
    maxWinnersLau: z.coerce
        .number({
            required_error: '\'Maximum Winners (LAU)\' is required',
            invalid_type_error: '\'Maximum Winners (LAU)\' value must be a number',
        })
        .min(1, 'Minimum value for \'Maximum Winners (LAU)\' is 1'),
    maxWinnersLanu: z.coerce
        .number({
            required_error: '\'Maximum Winners (LANU)\' is required',
            invalid_type_error: '\'Maximum Winners (LANU)\' value must be a number',
        })
        .min(1, 'Minimum value for \'Maximum Winners (LANU)\' is 1'),
    correctAnswersNeeded: z.coerce
        .number({
            required_error: '\'Number of Correct Answers\' is required',
            invalid_type_error: '\'Number of Correct Answers\' must be a number',
        })
        .min(1, 'Minimum value for \'Number of Correct Answers\' is 1'),

});

export type CreateQuizType = z.infer<typeof CreateQuizSchema>;

const CreateQuizModal:React.FunctionComponent<CreateContestModalProps> = ({
    isLanu,
    modalState,
    createGame,
    createQuiz,
    changeModalState,
    createQuizIsLoading,
    createQuizError,
    clearCreateQuizError,
}:CreateContestModalProps) => {
    const initialFormDataState = {
        name: '',
        maxWinnersLanu: 0,
        maxWinnersLau: 0,
        correctAnswersNeeded: 0,
    };

    const [isConfirmed, setIsConfirmed] = React.useState<boolean>(false);
    const [formData, setFormData] = React.useState<CreateQuizType>(initialFormDataState);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm<CreateQuizType>({
        resolver: zodResolver(CreateQuizSchema),
        defaultValues: initialFormDataState,
    });

    React.useEffect(() => {
        if (modalState.isOpen === true) {
            reset();
            setFormData(initialFormDataState);
        }

        if (isConfirmed) {
            setIsConfirmed(false);
        }
    }, [modalState.isOpen]);

    const toggle = () => {
        changeModalState({
            isOpen: !modalState.isOpen,
            type: modalState.type,
        });
    };

    const onSubmit = handleSubmit((data) => {
        if (isValid) {
            setIsConfirmed(true);
            setFormData(data);
        }
    });

    const cancelSubmit = () => {
        setIsConfirmed(false);
        clearCreateQuizError();
    };

    const header = isConfirmed ? 'Confirm to create a new quiz contest?' : `Create New ${Utils.ModifyString.capitalizeFirstLetter(ContestListTypesEnum.Quiz)}`;

    return (
        <IQOSModal
            header={header}
            isOpen={modalState.isOpen}
            toggle={toggle}
            size={isConfirmed ? 'sm' : 'lg'}
        >
            {!isConfirmed ? (
                <form onSubmit={onSubmit} autoComplete='off'>
                    <Row>
                        <StyledCol>
                            <FormGroup>
                                <StyledLabel>
                                    Contest Name
                                </StyledLabel>
                                <StyledInput error={errors.name && true} {...register('name', { valueAsNumber: false })} />
                            </FormGroup>
                        </StyledCol>
                    </Row>
                    <Row>
                        <StyledCol>
                            <FormGroup>
                                <Flex alignItems='center' justifyContent='flex-start' gap='0.3rem' margin='0 0 0.3rem 0'>
                                    <div>Maximum Winners (LAU)</div>
                                    <IconTooltip
                                        tooltipId='createMaxWinnersLau'
                                        iconSrc={Icons.QuestionMark}
                                        bgColor={Colors.iqosPrimary}
                                        fontColor={Colors.black}
                                    >
                                        Contest maximum winners for LAU channel.
                                    </IconTooltip>
                                </Flex>
                                <StyledInput error={errors.maxWinnersLau && true} {...register('maxWinnersLau', { valueAsNumber: true })} />
                            </FormGroup>
                        </StyledCol>
                        <StyledCol>
                            <FormGroup>
                                <Flex alignItems='center' justifyContent='flex-start' gap='0.3rem' margin='0 0 0.3rem 0'>
                                    <div>Maximum Winners (LANU)</div>
                                    <IconTooltip
                                        tooltipId='createMaxWinnersLanu'
                                        iconSrc={Icons.QuestionMark}
                                        bgColor={Colors.iqosPrimary}
                                        fontColor={Colors.black}
                                    >
                                        Contest maximum winners for LANU channel.
                                    </IconTooltip>
                                </Flex>
                                <StyledInput error={errors.maxWinnersLanu && true} {...register('maxWinnersLanu', { valueAsNumber: true })} />
                            </FormGroup>
                        </StyledCol>
                    </Row>
                    <Row>
                        <StyledCol>
                            <FormGroup>
                                <Flex alignItems='center' justifyContent='flex-start' gap='0.3rem' margin='0 0 0.3rem 0'>
                                    <div>Number of Correct Answers</div>
                                    <IconTooltip
                                        tooltipId='correctAnswersCreateModal'
                                        iconSrc={Icons.QuestionMark}
                                        bgColor={Colors.iqosPrimary}
                                        fontColor={Colors.black}
                                    >
                                        Minimum amount of correct answers to be eligible.
                                    </IconTooltip>
                                </Flex>
                                <StyledInput error={errors.correctAnswersNeeded && true} type='text' {...register('correctAnswersNeeded', { valueAsNumber: true })} />
                            </FormGroup>
                        </StyledCol>
                    </Row>
                    <Flex gap='1rem' margin='1rem 0 0 0'>
                        <SubmitButton type='submit' value='Submit' />
                        <StyledButton type='button' onClick={() => toggle()}>Cancel</StyledButton>
                    </Flex>
                    {(errors.name || errors.maxWinnersLanu || errors.maxWinnersLanu || errors.correctAnswersNeeded) && (
                        <Flex flexDirection='column' padding='1rem 0 0 0' gap='1rem'>
                            {errors.name && <StyledError>{errors.name.message}</StyledError>}
                            {errors.maxWinnersLanu && <StyledError>{errors.maxWinnersLanu.message}</StyledError>}
                            {errors.maxWinnersLau && <StyledError>{errors.maxWinnersLau.message}</StyledError>}
                            {errors.correctAnswersNeeded && <StyledError>{errors.correctAnswersNeeded.message}</StyledError>}
                        </Flex>
                    )}
                </form>
            ) : (
                <Flex justifyContent='center' flexWrap='wrap' flexDirection='column' gap='1rem'>
                    {createQuizError.length > 1 && (<StyledError>{createQuizError}</StyledError>)}
                    <Flex gap='1rem'>
                        <StyledPrimaryButton type='button' onClick={() => createQuiz(formData)}>
                            {createQuizIsLoading ? <Spinner size='sm' /> : 'Save' }
                        </StyledPrimaryButton>
                        <StyledButton type='button' onClick={() => cancelSubmit()}>Cancel</StyledButton>
                    </Flex>
                </Flex>
            )}
        </IQOSModal>

    );
};

interface StyledCSSProps {
    css?: FlattenSimpleInterpolation;
}

const SubmitButton = styled.input<StyledCSSProps>`
    background-color: ${Colors.iqosPrimary};
    border: 1px solid ${Colors.iqosPrimary};
    color: ${Colors.white};
    padding: 0.5rem 1.6rem;
    border-radius:24px;
    min-width:90px;  
    ${props => props.css}
`;

const StyledPrimaryButton = styled.button<StyledCSSProps>`
background-color: ${Colors.iqosPrimary};
border: 1px solid ${Colors.iqosPrimary};
color: ${Colors.white};
padding: 0.5rem 1.6rem;
border-radius:24px;
min-width:90px; 
`;

const StyledButton = styled.button<StyledCSSProps>`
    background-color: ${Colors.white};
    border: 1px solid ${Colors.iqosPrimary};
    padding: 0.5rem 1.6rem;
    border-radius:24px;
    min-width:90px;
    color: ${Colors.iqosPrimary};
    ${props => props.css}
`;

const StyledLabel = styled.div`
    margin-bottom: 0.3rem;
`;

const StyledCol = styled(Col)`
    color: ${Colors.lightGrey}FF;
`;

interface StyledInputProps {
    error?: boolean
}

const StyledInput = styled.input<StyledInputProps>`
    background-color: #F7F7F7;
    border-color: transparent;

    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-clip: padding-box;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    ${props => props.error && (`border: 1px solid ${Colors.red};`)}

    &:focus {
        background-color: #F7F7F7;
        ${props => props.error && (`border: 1px solid ${Colors.red};`
    )}
        outline:none;
    }
`;

const StyledError = styled.div`
    color: ${Colors.red};
`;

const mapStateToProps = (state: RootState) => ({
    isLanu: Selectors.getFaqGetIsLANU(state),

    modalState: Selectors.contestGetCreateContestModalState(state),

    createQuizIsLoading: Selectors.createContestAttempt(state),
    createQuizError: Selectors.createContestFailure(state),

});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    createGame: (params: ICreateGame) => dispatch(Actions.createGameAttempting(params)),
    createQuiz: (params: ICreateContest) => dispatch(Actions.createContestAttempt(params)),

    changeModalState: (param: ICreateQuizModalState) => dispatch(Actions.contestSetCreateQuizModalState(param)),

    clearCreateQuizError: () => dispatch(Actions.contestClearCreateQuizError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateQuizModal);
