import { put, call, select, takeEvery } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import PostsGateway from 'api/Posts';

export default function* watchGetUserClaims(api: PostsGateway): SagaWatcherReturnType {
    yield takeEvery('posts/getUserClaimsAttempt', handleGetUserClaims, api);
}

function* handleGetUserClaims(api: PostsGateway) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.getUserClaims], {
        authToken,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield* put(Actions.getUserClaimsFailure(response.message || ''));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield* put(Actions.getUserClaimsSuccess(response.data));
        yield* put(Actions.setUserClaimsRedux(response.data));
    }
}
