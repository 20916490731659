import { fork } from 'redux-saga/effects';

import BroadcastGateway from 'api/Broadcast';

import { RootSagaReturnType } from 'sagas/types';

import watchGetRecurringPostStatus from './getRecurringPost';
import watchGetSchedulePostStatus from './getSchedulePost';
import watchGetHistoryPostStatus from './getHistoryPost';
import watchGetHistoryDetailsStatus from './getHistoryDetails';
import watchCreateBroadcastPost from './createBroadcastPost';
import watchEditBroadcastPost from './editBroadcastPost';
import watchGetBroadcastOverview from './getBroadcastOverview';
import watchRecallBroadcastMessage from './recallBroadcastMessage';
import watchGetHistoryPostExportStatus from './getHistoryPostExport';
import watchGetHistoryDetailsExportStatus from './getHistoryDetailsExport';

export default (api: BroadcastGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetRecurringPostStatus, api);
        yield fork(watchGetSchedulePostStatus, api);
        yield fork(watchGetHistoryPostStatus, api);
        yield fork(watchGetHistoryDetailsStatus, api);
        yield fork(watchCreateBroadcastPost, api);
        yield fork(watchEditBroadcastPost, api);
        yield fork(watchGetBroadcastOverview, api);
        yield fork(watchRecallBroadcastMessage, api);
        yield fork(watchGetHistoryPostExportStatus, api);
        yield fork(watchGetHistoryDetailsExportStatus, api);
    }

    return {
        rootSaga,
    };
};
