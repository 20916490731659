import React, { useState, useEffect, useRef } from 'react';
import { Col, Row, Input as UploadInput, Spinner } from 'reactstrap';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { connect } from 'react-redux';
import SVG from 'react-inlinesvg';
import reactImageSize from 'react-image-size';
import ReactPlayer from 'react-player';
import axios from 'axios';

import { RootState, AppDispatch } from 'redux/store';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

import IQOSModal from 'components/IQOSModal';
import FollowersHeader from 'components/analytics/FollowersHeader';
import AnalyticsHeader from 'components/analytics/AnalyticsHeader';
import Flex from 'components/Flex';
import TextArea from 'components/TextArea';
import FaqTypeChip from 'components/FaqTypeChip';
import PdfViewer from 'components/faq/PdfViewer';

import { FaqMessageTypeEnum, IEditFaqCardModalState, IEditFaqDTO, IFaq } from 'entities/faq';

import Icons from 'assets/icons/Index';
import Colors from 'assets/themes/Colors';

import Config from 'config';

import Validators from 'lib/Validators';

interface EditFaqCardModalProps {

    faqData: IFaq[];

    modalState: IEditFaqCardModalState;

    authToken: string;

    editFaqError: string;

    editFaqIsLoading: boolean;

    editFaq: (param: IEditFaqDTO) => void;
    setModalState: (param: IEditFaqCardModalState) => void;
}

const EditFaqCardModal:React.FunctionComponent<EditFaqCardModalProps> = ({

    authToken,

    editFaqError,

    editFaqIsLoading,

    faqData,
    modalState,
    editFaq,
    setModalState,
}:EditFaqCardModalProps) => {
    const { isOpen, data } = modalState;

    const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
    const [hasChanged, setHasChanged] = useState<boolean>(true);

    const [formError, setFormError] = useState<string>('');

    const [wordLimit, setWordLimit] = useState<number>(1021);

    const [cardId, setCardId] = useState(data.id);
    const [cardTitle, setCardTitle] = useState<string>(data.title);
    const [cardType, setCardType] = useState<FaqMessageTypeEnum>(data.type);
    const [cardBody, setCardBody] = useState<string>(data.text);
    const [cardCaption, setCardCaption] = useState<string>(data.caption || '');

    const [allowedExtensions, setAllowedExtensions] = useState<string>('');
    const [uploadError, setUploadError] = useState('');
    const [uploading, setUploading] = useState<boolean>(false);

    const fileInputRef = useRef<HTMLInputElement>(null);

    const modalHeader = modalState.data.title.length < 30 ? modalState.data.title : `${modalState.data.title.substring(0, 30)}....`;
    const modalHeaderNotConfirmed = `Edit FAQ - ${modalHeader || 'Empty Title'}`;
    const modalHeaderIsConfirmed = `Edit FAQ Confirmation - ${modalHeader} ?`;

    useEffect(() => {
        if (data.id !== '') {
            setCardId(data.id);
            setCardTitle(data.title);
            setCardType(data.type);
            setCardBody(data.text);
            setCardCaption(data.caption || '');

            if (data.type === FaqMessageTypeEnum.Text) {
                setWordLimit(4094);
            } else {
                setWordLimit(1021);
                if (data.type === FaqMessageTypeEnum.Image) setAllowedExtensions('image/jpg, image/jpeg, image/png');
                if (data.type === FaqMessageTypeEnum.Video) setAllowedExtensions('video/mp4, image/gif');
                if (data.type === FaqMessageTypeEnum.Document) setAllowedExtensions('application/pdf');
            }
        }

        if (isOpen === false) {
            setModalState({ ...modalState, data: modalState.data });
            setIsConfirmed(false);
            setHasChanged(false);
            setFormError('');

            setUploadError('');
            setAllowedExtensions('');
        }
    }, [isOpen]);

    const toggle = () => {
        setModalState({ ...modalState, isOpen: !isOpen });
    };

    const videoFileHandler = (event: React.SyntheticEvent) => {
        const target = event.target as HTMLInputElement;

        if (target.files) {
            const [file] = target.files;
            if (checkFileExtension(file.name, cardType)) {
                const { size } = file;

                const videoSizeError = Validators.ValidateVideoSize(size);

                setUploadError(videoSizeError);

                if (!videoSizeError.length) {
                    uploadFile(file);
                }
            } else {
                setUploadError('Only single .mp4 .gif file is allowed');
            }
        }
    };

    const imageFileHandler = async (event: React.SyntheticEvent) => {
        const target = event.target as HTMLInputElement;

        if (target.files) {
            const [file] = target.files;
            if (checkFileExtension(file.name, cardType)) {
                const img = new Image();

                img.src = window.URL.createObjectURL(file);

                if (target.files) {
                    const { size } = file;
                    try {
                        const { width, height } = await reactImageSize(img.src);

                        const heightWidthRatio = width / height;

                        const imageDimensionsError = Validators.ValidateImageDimensions(size, width, height, heightWidthRatio);

                        setUploadError(imageDimensionsError);

                        if (!imageDimensionsError.length) {
                            uploadFile(file);
                        }
                    } catch (e) {
                        if (e instanceof Error) {
                            setUploadError(e.toString());
                        }
                    }
                }
            } else {
                setUploadError('Only single .jpeg .jpg .png file is allowed');
            }
        }
    };

    const documentFileHandler = (event: React.SyntheticEvent) => {
        const target = event.target as HTMLInputElement;

        if (target.files) {
            const [file] = target.files;
            if (checkFileExtension(file.name, cardType)) {
                const { size } = file;

                const documentSizeError = Validators.ValidateDocumentSize(size);

                setUploadError(documentSizeError);

                if (!documentSizeError.length) {
                    uploadFile(file);
                }
            } else {
                setUploadError('Only single .pdf file is allowed');
            }
        }
    };

    const checkFileExtension = (filename:string, type: FaqMessageTypeEnum) => {
        const fileExt = filename.slice(-3);
        if (type === FaqMessageTypeEnum.Document) {
            return fileExt === 'pdf';
        }

        if (type === FaqMessageTypeEnum.Image) {
            return !!((fileExt === 'png' || fileExt === 'jpg' || fileExt === 'peg'));
        }

        if (type === FaqMessageTypeEnum.Video) {
            return !!((fileExt === 'gif' || fileExt === 'mp4'));
        }

        return false;
    };

    const uploadFile = (file: File) => {
        setUploading(true);
        setUploadError('');

        const endpoint = `${Config.baseUrl}/v1/faq/fileUpload`;

        const formData = new FormData();
        formData.append('media', file);
        formData.append('fileType', cardType?.toString() || '');

        const config = {
            headers: {
                Authorization: `Bearer ${authToken}`,
                'content-type': 'multipart/form-data',
            },
        };

        axios.post(endpoint, formData, config).then(response => {
            const url = response.data || '';
            if (cardType !== FaqMessageTypeEnum.Text) {
                setCardBody(url);
                setHasChanged(true);
                setUploading(false);
                setUploadError('');
            }
        }).catch((err: any) => {
            setUploading(false);
            setUploadError(err.message);
        });
    };

    const toConfirmEdit = () => {
        let validateError = '';

        const trimmedTitle = cardTitle.trim();
        const trimmedText = cardBody.trim();
        const trimmedCaption = cardCaption.trim();

        if (cardType === FaqMessageTypeEnum.Text) {
            validateError = Validators.ValidateTextFaq(trimmedTitle, trimmedText);
            setFormError(validateError);
        }

        if (cardType === FaqMessageTypeEnum.Image) {
            if (cardCaption)validateError = Validators.ValidateDocumentFaq(trimmedTitle, trimmedCaption, trimmedText);
            setFormError(validateError);
        }

        if (cardType === FaqMessageTypeEnum.Video) {
            if (cardCaption)validateError = Validators.ValidateDocumentFaq(trimmedTitle, trimmedCaption, trimmedText);
            setFormError(validateError);
        }

        if (cardType === FaqMessageTypeEnum.Document) {
            if (cardCaption)validateError = Validators.ValidateDocumentFaq(trimmedTitle, trimmedCaption, trimmedText);
            setFormError(validateError);
        }

        if (!validateError.length && !uploadError.length) {
            setIsConfirmed(true);
            setFormError('');
        } else {
            setIsConfirmed(false);
        }
    };

    const onInputFileChange = (event: React.SyntheticEvent) => {
        setCardBody('');
        setUploadError('');

        if (cardType !== FaqMessageTypeEnum.Text) {
            switch (cardType) {
                case FaqMessageTypeEnum.Image:
                    imageFileHandler(event);
                    break;
                case FaqMessageTypeEnum.Video:
                    videoFileHandler(event);
                    break;
                case FaqMessageTypeEnum.Document:
                    documentFileHandler(event);
                    break;
                default:
                    break;
            }
        }
    };

    const removeFile = () => {
        setCardBody('');
        setHasChanged(true);
    };

    const editClickHandler = () => {
        let editData:IEditFaqDTO;

        if (cardType === FaqMessageTypeEnum.Text) {
            editData = {
                id: cardId,
                title: cardTitle.trim(),
                messages: [
                    {
                        text: cardBody.trim(),
                        type: cardType,
                    },
                ],
            };
        } else {
            editData = {
                id: cardId,
                title: cardTitle,
                messages: [
                    {
                        text: cardBody.trim(),
                        type: cardType,
                        caption: cardCaption.trim(),
                    },
                ],
            };
        }
        editFaq(editData);
    };

    const onClickFileInputHandler = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        const element = event.target as HTMLInputElement;
        element.value = '';
    };

    const renderPreviewFile = () => {
        const renderFile = () => {
            if (!cardBody.length) {
                return (
                    <Flex justifyContent='center' alignItems='center' height='250px' width='100%'>
                        <StyledButtonV2 type='button' onClick={() => fileInputRef.current?.click()}>
                            No File Uploaded
                        </StyledButtonV2>
                    </Flex>
                );
            }

            if (cardBody && cardType === FaqMessageTypeEnum.Image) {
                return (<StyledImg src={cardBody} alt={cardTitle} css={customizedFilePreview.img} />);
            }

            if (cardBody && cardType === FaqMessageTypeEnum.Video) {
                const videoFormat = cardBody.slice(-3);
                if (videoFormat === 'gif') {
                    return (<StyledImg src={cardBody} alt={cardTitle} css={customizedFilePreview.img} />);
                }

                return (
                    <StyledReactPlayer
                        url={cardBody}
                        playing
                        light
                        style={{ width: '100%', maxWidth: '470px', height: 'auto', maxHeight: '200px' }}
                        controls
                    />
                );
            }

            if (cardBody && cardType === FaqMessageTypeEnum.Document) {
                return (
                    <PdfViewer
                        file={cardBody}
                        pdfWrapper={customizedComponentStyle.pdfWrapper}
                    />
                );
            }

            return null;
        };

        return uploading

            ? (
                <Flex justifyContent='center' alignItems='center' height='250px' width='100%'>
                    <Spinner size='lg' />
                </Flex>
            )
            : (
                <Flex width='100%' justifyContent='center' alignItems='center' padding='0.6rem 0 0 0' flexDirection='column'>
                    {renderFile()}
                    {cardBody ? (
                        <StyledButton type='button' onClick={removeFile} css={customizedButtonStyle.removeFile}>
                            <Flex justifyContent='center' alignItems='center' gap='0.5rem' width='100%'>
                                <div>Remove</div>
                                <SVG src={Icons.TrashIcon} />
                            </Flex>
                        </StyledButton>
                    ) : null}
                </Flex>
            );
    };

    return (
        <IQOSModal
            header={isConfirmed ? modalHeaderIsConfirmed : modalHeaderNotConfirmed}
            isOpen={isOpen}
            toggle={toggle}
            size='lg'
            wrapperCSS={customizedComponentStyle.modal}
            bodyCSS={customizedComponentStyle.modalBody}
        >
            {!isConfirmed ? (
                <>
                    <FaqChipWrapper>
                        <FaqTypeChip type={cardType} svgStyle={customizedComponentStyle.svg} />
                    </FaqChipWrapper>
                    <Row>
                        <Col>
                            <InputWrapper>
                                <Flex flexDirection='column' padding='0 0 1rem 0'>
                                    <FollowersHeader
                                        headerContent='Question'
                                        headerSize='sm'
                                        headerColor={Colors.lightGrey}
                                        headerWeight='thin'
                                        tooltipIconSize='1rem'
                                        tooltipId={`edit-question-${cardId}`}
                                        tooltipIconSrc={Icons.QuestionMark}
                                        tooltipMaxWidth='400px'
                                        tooltipContent=' This text will be displayed as the selectable options of the FAQ. It will also be repeated at the top section of the message.'
                                    />
                                    <StyledInput
                                        type='text'
                                        value={cardTitle}
                                        onChange={(e) => {
                                            setHasChanged(true);
                                            setCardTitle(e.target.value);
                                        }}
                                    />
                                </Flex>
                                <Flex justifyContent='space-between' gap='wrap' flexWrap='wrap'>
                                    <FollowersHeader
                                        headerContent={cardType === FaqMessageTypeEnum.Text ? 'Message' : 'Caption'}
                                        headerSize='sm'
                                        headerColor={Colors.lightGrey}
                                        headerWeight='thin'
                                        tooltipIconSize='1rem'
                                        tooltipId={`edit-${cardType === FaqMessageTypeEnum.Text ? 'Message' : 'Caption'}-${cardId}`}
                                        tooltipIconSrc={Icons.QuestionMark}
                                        tooltipMaxWidth='unset'
                                        tooltipContent={cardType === FaqMessageTypeEnum.Text ? 'This will be the content of the selected option.' : 'The caption will be display at the bottom of the asset, right after the title.'}
                                    />
                                    <Flex gap='0.5rem' flexWrap='nowrap'>
                                        <div>Number of characters</div>
                                        <div>
                                            {cardType === FaqMessageTypeEnum.Text
                                                ? cardTitle.length + cardBody.length
                                                : cardTitle.length + cardCaption.length}
                                            {' / '}
                                            {wordLimit}
                                        </div>
                                    </Flex>
                                </Flex>
                                <TextArea
                                    css={customizedComponentStyle.textarea}
                                    value={cardType === FaqMessageTypeEnum.Text ? cardBody : cardCaption}
                                    onChange={(e) => {
                                        setHasChanged(true);
                                        if (cardType === FaqMessageTypeEnum.Text) {
                                            setCardBody(e.target.value);
                                        } else {
                                            setCardCaption(e.target.value);
                                        }
                                    }}
                                />
                            </InputWrapper>
                        </Col>
                        { cardType !== FaqMessageTypeEnum.Text && (
                            <Col>
                                <AnalyticsHeader
                                    header='Upload Media (optional)'
                                    size='sm'
                                    fontColor={Colors.lightGrey}
                                    fontWeight='thin'
                                />
                                <Flex
                                    style={{ bottom: '-12px', position: 'relative' }}
                                    flexWrap='nowrap'
                                    gap='1rem'
                                    width='100%'
                                    justifyContent='space-between'
                                    margin='0 0 1rem 0'
                                >
                                    <StyledButton
                                        disabled={cardType !== FaqMessageTypeEnum.Image}
                                        css={customizedButtonStyle.fileInput}
                                        type='button'
                                        onClick={() => fileInputRef.current?.click()}
                                    >
                                        <Flex justifyContent='center' alignItems='center' gap='0.5rem'>
                                            <div>Image</div>
                                            <SVG src={Icons.Image} />
                                        </Flex>
                                    </StyledButton>
                                    <StyledButton
                                        disabled={cardType !== FaqMessageTypeEnum.Video}
                                        css={customizedButtonStyle.fileInput}
                                        type='button'
                                        onClick={() => fileInputRef.current?.click()}
                                    >
                                        <Flex justifyContent='center' alignItems='center' gap='0.5rem'>
                                            <div>Video</div>
                                            <SVG src={Icons.Video} />
                                        </Flex>
                                    </StyledButton>
                                    <StyledButton
                                        disabled={cardType !== FaqMessageTypeEnum.Document}
                                        css={customizedButtonStyle.fileInput}
                                        type='button'
                                        onClick={() => fileInputRef.current?.click()}
                                    >
                                        <Flex justifyContent='center' alignItems='center' gap='0.5rem'>
                                            <div>PDF</div>
                                            <SVG src={Icons.Pdf} />
                                        </Flex>
                                    </StyledButton>

                                </Flex>
                                <div style={{ marginTop: '1.6rem' }}>
                                    <AnalyticsHeader
                                        header='Media Preview'
                                        size='sm'
                                        fontColor={Colors.lightGrey}
                                        fontWeight='thin'
                                    />
                                    <UploadInput
                                        type='file'
                                        innerRef={fileInputRef}
                                        onChange={(e) => onInputFileChange(e)}
                                        onClick={onClickFileInputHandler}
                                        style={{ display: 'none' }}
                                        accept={allowedExtensions}
                                    />
                                    {renderPreviewFile()}
                                </div>
                            </Col>
                        )}
                    </Row>
                    {formError.length > 1 && (
                        <Flex padding='1rem 0 0 .3rem'>
                            <FormError>{formError}</FormError>
                        </Flex>
                    )}
                    {uploadError.length > 1 && (
                        <Flex padding='1rem 0 0 .3rem'>
                            <FormError>
                                {uploadError}
                            </FormError>
                        </Flex>
                    )}
                    <Flex justifyContent='flex-start' gap='1rem' padding='1rem 0px 1rem 0rem'>
                        <StyledButton
                            css={customizedButtonStyle.primary}
                            type='button'
                            onClick={toConfirmEdit}
                            disabled={!hasChanged}
                        >
                            Save

                        </StyledButton>
                        <StyledButton
                            css={customizedButtonStyle.cancel}
                            type='button'
                            onClick={toggle}
                        >
                            Cancel

                        </StyledButton>
                    </Flex>
                </>
            ) : (
                <Flex justifyContent='flex-start' gap='1rem' padding='1rem 0px 1rem 0rem'>
                    <StyledButton
                        css={customizedButtonStyle.primary}
                        type='button'
                        onClick={() => editClickHandler()}
                    >
                        {editFaqIsLoading ? (<Spinner size='sm' />) : 'Confirm'}
                    </StyledButton>
                    <StyledButton
                        css={customizedButtonStyle.cancel}
                        type='button'
                        onClick={() => setIsConfirmed(false)}
                    >
                        Cancel
                    </StyledButton>
                </Flex>
            )}
        </IQOSModal>
    );
};

const customizedFilePreview = {
    img: css`
        object-fit: fill;
        width:100%;
    `,
};

const FaqChipWrapper = styled.div`
    position:absolute;
    right: 2rem;
    top:2rem;
`;

const InputWrapper = styled.div`
    padding-bottom:1rem;
    height:100%;
    max-height:100%;
    position:relative;
`;

interface StyledInputProps {
    error?: boolean
}

interface StyledCSSProps {
    css?: FlattenSimpleInterpolation;
}

const StyledReactPlayer = styled(ReactPlayer)<StyledCSSProps>`
    background-color:  rgba(0,0,0,0.1);
    ${props => props.css};
`;

const StyledImg = styled.img<StyledCSSProps>`
    ${props => props.css};
`;

const StyledButton = styled.button<StyledCSSProps>`
    padding: 0.4rem 2.5rem;
    position:relative;
    border-radius: 20px;
    font-weight:bold;

    ${props => props.css}
`;

const StyledButtonV2 = styled.button<StyledCSSProps>`
    border: 5px dashed rgba(0,0,0,0.1); 
    height: 100%; 
    width: 100%;
    ${props => props.css};
`;

const customizedButtonStyle = {
    primary: css`
        background-color: ${Colors.iqosPrimary};
        border: 1px solid ${Colors.iqosPrimary};
        color: ${Colors.white};

        &:hover {
            background-color: ${Colors.white};
            color: ${Colors.iqosPrimary};
        }

        &:disabled{
            background-color: ${Colors.lightGrey};
            border-color: ${Colors.lightGrey};
            cursor: not-allowed;
            &:hover{
                color: ${Colors.white} !important;
            }
        }
    `,
    cancel: css`
        background-color: ${Colors.white};
        border: 1px solid rgba(0,0,0,0.5);

        &:hover {
            opacity: 0.7;
        }
    `,

    fileInput: css`
        background-color: ${Colors.white};
        border: 1px solid rgba(0,0,0,0.2);
        border-radius:4px;
        width:100%; 
    `,
    removeFile: css`
        background-color: ${Colors.white};
        color: ${Colors.red};
        border: 1px solid ${Colors.red};
        width:100%;
        margin-top:7px;
        border-radius:4px;

        &:hover{
            background-color: ${Colors.red};
            border:1px solid ${Colors.white};
            color: ${Colors.white};
        }

        &:disabled {
            opacity: 0.3;
            cursor: not-allowed;
        }


    `,
    noFileButton: css`
        background-color: #F7F7F7;
    `,
};

const FormError = styled.div`
    color: ${Colors.red};
`;

const customizedComponentStyle = {
    textarea: css`
        min-height:200px;
        margin-top: 0.5rem;
        background-color: #F7F7F7;
        border-color: transparent;
        max-height:calc(100% - 108px);
    `,
    modal: css`
        max-width:960px;
    `,
    modalBody: css`
        padding: 2rem 2rem 0rem 2rem;
        
    `,
    svg: css`
        height:auto;
        width:35px;
    `,
    pdfWrapper: css`
        height: 100%;
        max-height:310px;
        width:100% !important;
        max-width:470px !important;
        overflow:auto;
        margin: 0 !important;
    `,
};

const StyledInput = styled.input<StyledInputProps>`
    background-color: #F7F7F7;
    border-color: transparent;

    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    margin-top:0.6rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-clip: padding-box;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    ${props => props.error && (`border: 1px solid ${Colors.red};`)}

    &:focus {
        background-color: #F7F7F7;
        ${props => props.error && (`border: 1px solid ${Colors.red};`
    )}
        outline:none;
    }
`;

const mapStateToProps = (state: RootState) => ({
    faqData: Selectors.getFaqFaqs(state),

    modalState: Selectors.faqGetEditFaqCardModalState(state),

    authToken: Selectors.getAuthAuthToken(state),

    editFaqError: Selectors.getFaqFaqsError(state),
    editFaqIsLoading: Selectors.getFaqFaqsAttempting(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    editFaq: (param: IEditFaqDTO) => dispatch(Actions.updateAttempt(param)),

    setModalState: (param: IEditFaqCardModalState) => dispatch(Actions.faqSetEditFaqCardModalState(param)),

});

export default connect(mapStateToProps, mapDispatchToProps)(EditFaqCardModal);
