import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import { Spinner, Table } from 'reactstrap';
import { useParams, useLocation } from 'react-router';
import SVG from 'react-inlinesvg';
import dayjs from 'dayjs';
import Skeleton from 'react-loading-skeleton';

import { AppDispatch, RootState } from 'redux/store';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

import ContentWrapper from 'components/ContentWrapper';
import Flex from 'components/Flex';
import { ButtonStyles, TableStyles } from 'components/styles';
import IQOSPaginator from 'components/IQOSPaginator';
import AnalyticsHeader from 'components/analytics/AnalyticsHeader';

import Color from 'assets/themes/Colors';
import Icons from 'assets/icons/Index';

import { IChannelTypeEnum } from 'entities/contests';
import { IEditGameParams, IGameDetails, IGameResponse } from 'entities/games';

import NavActions from 'lib/NavActions';

interface GameDetailsProps {
    isLanu: boolean;
    gameDetails: IGameDetails;
    gameDetailsIsLoading: boolean;
    gameDetailsError: string;
    editGameDetailsIsLoading: boolean;
    exportGameFileisLoading: boolean;
    gameResponsesIndex: number;
    gameResponsesPage: number;
    gameResponsesMaxIndex: number;
    gameResponsesIsLoading: boolean;
    gameResponsesError: string;
    gameResponses: IGameResponse[];
    getGameDetails: (id: string, type: IChannelTypeEnum) => void;
    getGameResponses: (contestId: string, index: number, type: IChannelTypeEnum) => void;
    editGameDetails: (data: IEditGameParams) => void;
    exportGameFile: (contestId: string, type: IChannelTypeEnum) => void;
    setGameIndex: (param: number) => void;
}

const GameDetails:React.FunctionComponent<GameDetailsProps> = ({
    isLanu,

    gameDetails,
    gameDetailsIsLoading,
    gameDetailsError,
    editGameDetailsIsLoading,
    exportGameFileisLoading,
    gameResponsesIndex,
    gameResponsesPage,
    gameResponsesMaxIndex,
    gameResponsesIsLoading,
    gameResponsesError,
    gameResponses,

    getGameDetails,
    getGameResponses,
    editGameDetails,
    exportGameFile,
    setGameIndex,
}:GameDetailsProps) => {
    const { id } = useParams();
    const tableHeaders = ['First Name', 'Username', 'Phone Numbers', 'Practice Time Spent', 'Game Score', 'Initial Click Time', 'Real Playtime'];

    const [checkLanu, setCheckLanu] = useState<IChannelTypeEnum>(isLanu ? IChannelTypeEnum.LANU : IChannelTypeEnum.LAU);
    const { pathname } = useLocation();

    const [reRenderPaginator, setReRenderPaginator] = useState<boolean>(false);

    useEffect(() => {
        setCheckLanu(isLanu ? IChannelTypeEnum.LANU : IChannelTypeEnum.LAU);
    }, [isLanu]);

    useEffect(() => {
        setReRenderPaginator(true);
        if (id) {
            getGameDetails(id, checkLanu);
            getGameResponses(id, 1, checkLanu);
            setTimeout(() => { setReRenderPaginator(false); }, 500);
        }
    }, [id, checkLanu]);

    useEffect(() => {
        if (id) { getGameResponses(id, gameResponsesIndex, checkLanu); }
    }, [gameResponsesIndex]);

    const renderName = () => {
        if (gameDetailsIsLoading) {
            return <Spinner size='sm' />;
        }

        if (gameDetailsError) {
            return gameDetailsError;
        }

        return gameDetails.name || 'Contest name is empty';
    };

    const renderExportButtonText = () => {
        if (exportGameFileisLoading) {
            return (<Spinner size='sm' />);
        }
        return 'Export Data';
    };

    const renderTopPanel = () => {
        return (
            <>
                <Flex justifyContent='space-between'>
                    <AnalyticsHeader header={renderName()} size='lg' />

                    <StyledButton onClick={() => exportGameFile(gameDetails.id, checkLanu)} css={ButtonStyles.primary} type='button'>
                        {renderExportButtonText()}
                    </StyledButton>
                </Flex>
                <Flex justifyContent='space-between' alignItems='center' margin='2rem 0 0 0'>
                    <StyledButton type='button' css={ButtonStyles.blank} onClick={() => NavActions.navToContestsGame()}>
                        <Flex justifyContent='space-between' flexWrap='nowrap' alignItems='center' gap='.3rem'>
                            <SVG fill={Color.iqosPrimary} src={Icons.ChevronLeft} style={{ transform: '180deg' }} height={30} />
                            <AnalyticsHeader size='sm' header='Back to Report' fontColor={Color.iqosPrimary} />
                        </Flex>
                    </StyledButton>
                    {gameDetails.totalResponses > 0 && !gameDetailsError && (
                        <TotalResponsesContainer>
                            Total:
                            {' '}
                            {gameDetails.totalResponses}
                            {' '}
                            Response
                            {gameDetails.totalResponses > 1 && 's'}
                        </TotalResponsesContainer>
                    )}
                </Flex>
            </>

        );
    };

    const renderGameTable = () => {
        const renderTableBody = () => {
            if (gameResponsesIsLoading) {
                return (
                    <tr>
                        <AlignedTextTd colSpan={tableHeaders.length}>
                            <Skeleton count={5} height={50} />
                        </AlignedTextTd>
                    </tr>
                );
            }

            if (!gameResponses.length) {
                return (
                    <tr>
                        <AlignedTextTd colSpan={tableHeaders.length}>
                            There appears to be no data yet.
                        </AlignedTextTd>
                    </tr>
                );
            }

            if (gameResponsesError) {
                return (
                    <tr>
                        <AlignedTextTd colSpan={tableHeaders.length}>
                            {gameResponsesError}
                        </AlignedTextTd>
                    </tr>
                );
            }

            return gameResponses.map((game) => {
                const adjustClickTime = (clickTime:Date):string => {
                    if (clickTime) {
                        return dayjs(clickTime).format('YYYY-MM-DD HH:mmA');
                    }
                    return '-';
                };

                return (
                    <tr key={game.id}>
                        <td>{game.firstName || '-'}</td>
                        <td>{game.username || '-'}</td>
                        <td>{game.phoneNumber || '-'}</td>
                        <td>{game.practicePlayedTimes || '-'}</td>
                        <td>{game.realGameScore || '-'}</td>
                        <td>{adjustClickTime(dayjs(game.initialClickTime).toDate())}</td>
                        <td>{adjustClickTime(dayjs(game.realGamePlayedTime).toDate())}</td>
                    </tr>
                );
            });
        };
        return (
            <>
                <StyledTable id={`game--${gameDetails.id}`} css={TableStyles.primary}>
                    <thead>
                        <tr>
                            {tableHeaders.map((item) => {
                                return (<th key={`game-${gameDetails.id}-table-headers-${item}`}>{item}</th>);
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {renderTableBody()}
                    </tbody>
                </StyledTable>
                {(gameResponses.length && (reRenderPaginator === false)) ? (
                    <IQOSPaginator
                        maxIndex={gameResponsesMaxIndex}
                        setIndex={setGameIndex}
                        currentIndex={gameResponsesIndex}
                        url={pathname}
                        containerId={`game--${gameDetails.id}`}
                    />
                ) : null}
            </>
        );
    };

    return (
        <ContentWrapper>
            {renderTopPanel()}
            <TableContainer>
                {renderGameTable()}
            </TableContainer>
        </ContentWrapper>
    );
};

type StyledCSSProps = {
    css?: FlattenSimpleInterpolation
}

const StyledButton = styled.button<StyledCSSProps>`
    ${props => props.css}
`;

const TableContainer = styled.div`
    margin-bottom: 1rem;
`;

const AlignedTextTd = styled.td`
    text-align:center;
`;

const StyledTable = styled(Table)<StyledCSSProps>`
    margin-top:1rem;
    ${props => props.css}
`;

const TotalResponsesContainer = styled.div`
    color: ${Color.lightGrey};
`;

const mapStateToProps = (state: RootState) => ({
    isLanu: Selectors.getFaqGetIsLANU(state),

    gameDetails: Selectors.getGameDetails(state),
    gameDetailsIsLoading: Selectors.getGameDetailsAttempting(state),
    gameDetailsError: Selectors.getGameDetailsFailure(state),

    editGameDetailsIsLoading: Selectors.getGameDetailsAttempting(state),

    exportGameFileisLoading: Selectors.getGameExportResponsesAttempting(state),

    gameResponsesIndex: Selectors.getGameDetailsResponsesIndex(state),
    gameResponsesPage: Selectors.getGameDetailsResponsesPage(state),
    gameResponsesMaxIndex: Selectors.getGameDetailsResponsesMaxIndex(state),
    gameResponsesIsLoading: Selectors.getGameDetailsResponsesAttempting(state),
    gameResponsesError: Selectors.getGameDetailsResponsesFailure(state),
    gameResponses: Selectors.getGameDetailsResponses(state),

});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getGameDetails: (id: string, type:IChannelTypeEnum) => dispatch(Actions.getGameDetailsAttempting({ id, type })),
    getGameResponses: (gameId: string, index: number, type: IChannelTypeEnum) => dispatch(Actions.getGameDetailsResponsesAttempting({ gameId, index, type })),
    editGameDetails: (data: IEditGameParams) => dispatch(Actions.editGameAttempting(data)),
    exportGameFile: (gameId: string, type: IChannelTypeEnum) => dispatch(Actions.gameExportResponsesAttempting({ gameId, type })),
    setGameIndex: (param: number) => dispatch(Actions.setGamePaginatorIndex(param)),

});
export default connect(mapStateToProps, mapDispatchToProps)(GameDetails);
