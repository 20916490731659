import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EditPostsReport, EditToggleColumns, GraphExportInfo, InformationPostModalInfo, PerformingPosts, PostsOverview, PostsReport, PostsReportExport, PostsReportWithAmountOfDays, RateByCategories, RateByPost, RateByPostingTime, UserClaims } from 'entities/posts';
import Utils from 'lib/Utils';

import { SummaryDashboardDetails } from 'entities/summaryDashboard';
import {
    GetSummaryDashboardDetailsParams,
    PerformingPostsPayloadParams,
    PostsOverviewPayloadParams,
    PostsReduxState,
    PostsReportExportPayloadParams,
    PostsReportPayloadParams,
    PostsReportWithAmountOfDaysPayloadParams,
    RateByCategoriesExportPayloadParams,
    RateByCategoriesModalInfoPayloadParams,
    RateByCategoriesPayloadParams,
    RateByPostExportPayloadParams,
    RateByPostingTimeExportPayloadParams,
    RateByPostingTimeModalInfoPayloadParams,
    RateByPostingTimePayloadParams,
    RateByPostModalInfoPayloadParams,
    RateByPostPayloadParams,
} from './types';

const initialState: PostsReduxState = {
    actions: {
        postsOverview: false,
        rateByCategories: false,
        rateByCategoriesModalInfo: false,
        rateByCategoriesExport: false,
        rateByPostingTime: false,
        rateByPostingTimeModalInfo: false,
        rateByPostingTimeExport: false,
        rateByPost: false,
        rateByPostModalInfo: false,
        rateByPostExport: false,
        postsReport: false,
        postsReportExport: false,
        editPostsReport: false,
        postsReportWithAmountOfDays: false,
        performingPost: false,
        userClaims: false,
        editToggleColumn: false,
        summaryDashboardDetails: false,
    },
    postsOverviewData: {
        totalPostsMonth: 0,
        totalPostsYtd: 0,
        months: [],
    },
    rateByCategoriesData: [{
        category: '',
        avgViewRate: 0,
        avgEngRate: 0,
    }],
    rateByCategoriesModalInfoData: {
        index: 0,
        maxIndex: 0,
        data: [],
    },
    rateByCategoriesExportData: [{
        postId: '',
        title: '',
        mediaUrl: '',
        date: '',
        views: 0,
        viewRate: 0,
        engagement: 0,
        engagementRate: 0,
    }],
    rateByPostingTimeData: [{
        hour: '',
        avgViewRate: 0,
        avgEngRate: 0,
    }],
    rateByPostingTimeModalInfoData: {
        index: 0,
        maxIndex: 0,
        data: [],
    },
    rateByPostingTimeExportData: [{
        postId: '',
        title: '',
        mediaUrl: '',
        date: '',
        views: 0,
        viewRate: 0,
        engagement: 0,
        engagementRate: 0,
    }],
    rateByPostData: [{
        week: '',
        numOfPosts: 0,
        avgViewRate: 0,
        avgEngRate: 0,
        dateFrom: '',
        dateTo: '',
    }],
    rateByPostModalInfoData: {
        index: 0,
        maxIndex: 0,
        data: [],
    },
    rateByPostModalExportData: [{
        postId: '',
        title: '',
        mediaUrl: '',
        date: '',
        views: 0,
        viewRate: 0,
        engagement: 0,
        engagementRate: 0,
    }],
    postsReportData: {
        index: 0,
        maxIndex: 0,
        data: [],
    },
    postsReportExportData: [],
    editPostsReportData: {
        postId: '',
        postCategory: 0,
        clicks: 0,
        language: 1,
    },
    postsReportWithAmountOfDaysData: {
        index: 0,
        maxIndex: 0,
        data: [],
    },
    performingPostsData: {
        top: [],
        least: [],
    },
    userClaimsData: {
        userRemovedColumnClaims: {
            postReportClaims: [],
            postDaysClaims: [],
            postTopLeastPerformingClaims: [],
            postDetailsModal: [],
        },
    },
    editToggleColumnData: {
        reportEnum: 0,
        column: 0,
        remove: false,
    },
    dateFrom: new Date(new Date().setDate(new Date().getDate() - 30)),
    dateTo: new Date(),
    informationPostModalIsOpen: false,
    informationPostIndex: 1,
    postsReportIndex: 1,
    postsReportWithAmountOfDaysIndex: 1,
    currentGraphCategory: '',
    currentGraphWeek: '',
    currentGraphHour: '',
    selectedCategory: 0,
    userClaimsRedux: {
        userRemovedColumnClaims: {
            postReportClaims: [],
            postDaysClaims: [],
            postTopLeastPerformingClaims: [],
            postDetailsModal: [],
        },
    },
    summaryDashboardDetailsData: {
        newFollowersCurrentDate: 0,
        newFollowersPreviousDate: 0,
        newFollowersChange: 0,
        netFollowersCurrentDate: 0,
        netFollowersPreviousDate: 0,
        netFollowersChange: 0,
        cumulativeFollowersCurrentDate: 0,
        cumulativeFollowersPreviousDate: 0,
        cumulativeFollowersChange: 0,
        viewRateCurrentDate: 0,
        viewRatePreviousDate: 0,
        viewRatePercentageChange: 0,
        engagementRateCurrentDate: 0,
        engagementRatePreviousDate: 0,
        engagementRateChange: 0,
        unsubscribeRateInputDate: 0,
        unsubscribeRatePreviousDate: 0,
        unsubscribeRate: 0,
        viewCountCurrentDate: 0,
        viewCountPreviousDate: 0,
        viewCountChange: 0,
        engagementCountCurrentDate: 0,
        engagementCountPreviousDate: 0,
        engagementCountChange: 0,
    },
    error: {
        postsOverview: '',
        rateByCategories: '',
        rateByCategoriesModalInfo: '',
        rateByCategoriesExport: '',
        rateByPostingTime: '',
        rateByPostingTimeModalInfo: '',
        rateByPostingTimeExport: '',
        rateByPost: '',
        rateByPostModalInfo: '',
        rateByPostExport: '',
        postsReport: '',
        postsReportExport: '',
        editPostsReport: '',
        postsReportWithAmountOfDays: '',
        performingPost: '',
        userClaims: '',
        editToggleColumn: '',
        summaryDashboardDetails: '',
    },
};

const posts = createSlice({ name: 'posts',
    initialState,
    reducers: {
        getPostsOverviewAttempt: (state, _action: PostsOverviewPayloadParams) => {
            state.actions.postsOverview = true;
            state.error.postsOverview = '';
        },
        getPostsOverviewSuccess: (state, action: PayloadAction<PostsOverview>) => {
            state.actions.postsOverview = false;
            state.error.postsOverview = '';
            if (action.payload) { state.postsOverviewData = action.payload; }
            if (action.payload) {
                const valueUpdater = action.payload.months.map((item) => {
                    return ({
                        ...item,
                        month: Utils.Posts.monthEnumToStringConverter(Number(item.month)),
                    });
                });

                const massagedData = {
                    ...action.payload,
                    months: valueUpdater,
                };

                state.postsOverviewData = massagedData;
            }
        },
        getPostsOverviewFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.postsOverview = false;
            if (action.payload) { state.error.postsOverview = action.payload; }
        },

        getRateByCategoriesAttempt: (state, _action: RateByCategoriesPayloadParams) => {
            state.actions.rateByCategories = true;
            state.error.rateByCategories = '';
        },
        getRateByCategoriesSuccess: (state, action: PayloadAction<RateByCategories[]>) => {
            state.actions.rateByCategories = false;
            state.error.rateByCategories = '';
            if (action.payload) {
                const valueUpdater = action.payload.map((item) => {
                    return ({
                        category: Utils.Posts.categoryEnumToStringConverter(item.category),
                        avgViewRate: Number(Utils.Posts.rateConverter(item.avgViewRate)),
                        avgEngRate: Number(Utils.Posts.rateConverter(item.avgEngRate)),
                    });
                });

                state.rateByCategoriesData = valueUpdater;
            }
        },
        getRateByCategoriesFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.rateByCategories = false;
            if (action.payload) { state.error.rateByCategories = action.payload; }
        },

        getRateByCategoriesModalInfoAttempt: (state, _action: RateByCategoriesModalInfoPayloadParams) => {
            state.actions.rateByCategoriesModalInfo = true;
            state.error.rateByCategoriesModalInfo = '';
        },
        getRateByCategoriesModalInfoSuccess: (state, action: PayloadAction<InformationPostModalInfo>) => {
            state.actions.rateByCategoriesModalInfo = false;
            state.error.rateByCategoriesModalInfo = '';

            if (action.payload) {
                state.rateByCategoriesModalInfoData = action.payload;
            }
        },
        getRateByCategoriesModalInfoFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.rateByCategoriesModalInfo = false;
            if (action.payload) { state.error.rateByCategoriesModalInfo = action.payload; }
        },

        getRateByCategoriesExportAttempt: (state, _action: RateByCategoriesExportPayloadParams) => {
            state.actions.rateByCategoriesExport = true;
            state.error.rateByCategoriesExport = '';
        },
        getRateByCategoriesExportSuccess: (state, action: PayloadAction<GraphExportInfo[]>) => {
            state.actions.rateByCategoriesExport = false;
            state.error.rateByCategoriesExport = '';

            if (action.payload) {
                state.rateByCategoriesExportData = action.payload;
            }
        },
        getRateByCategoriesExportFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.rateByCategoriesExport = false;
            if (action.payload) { state.error.rateByCategoriesExport = action.payload; }
        },

        getRateByPostingTimeAttempt: (state, _action: RateByPostingTimePayloadParams) => {
            state.actions.rateByPostingTime = true;
            state.error.rateByPostingTime = '';
        },
        getRateByPostingTimeSuccess: (state, action: PayloadAction<RateByPostingTime[]>) => {
            state.actions.rateByPostingTime = false;
            state.error.rateByPostingTime = '';
            if (action.payload) {
                const valueUpdater = action.payload.map((item) => {
                    return ({
                        hour: Utils.Posts.hourNumberToStringConverter(Number(item.hour)),
                        avgViewRate: Number(Utils.Posts.rateConverter(item.avgViewRate)),
                        avgEngRate: Number(Utils.Posts.rateConverter(item.avgEngRate)),
                    });
                });

                state.rateByPostingTimeData = valueUpdater;
            }
        },
        getRateByPostingTimeFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.rateByPostingTime = false;
            if (action.payload) { state.error.rateByPostingTime = action.payload; }
        },

        getRateByPostingTimeModalInfoAttempt: (state, _action: RateByPostingTimeModalInfoPayloadParams) => {
            state.actions.rateByPostingTimeModalInfo = true;
            state.error.rateByPostingTimeModalInfo = '';
        },
        getRateByPostingTimeModalInfoSuccess: (state, action: PayloadAction<InformationPostModalInfo>) => {
            state.actions.rateByPostingTimeModalInfo = false;
            state.error.rateByPostingTimeModalInfo = '';

            if (action.payload) {
                state.rateByPostingTimeModalInfoData = action.payload;
            }
        },
        getRateByPostingTimeModalInfoFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.rateByPostingTimeModalInfo = false;
            if (action.payload) { state.error.rateByPostingTimeModalInfo = action.payload; }
        },

        getRateByPostingTimeExportAttempt: (state, _action: RateByPostingTimeExportPayloadParams) => {
            state.actions.rateByPostingTimeExport = true;
            state.error.rateByPostingTimeExport = '';
        },
        getRateByPostingTimeExportSuccess: (state, action: PayloadAction<GraphExportInfo[]>) => {
            state.actions.rateByPostingTimeModalInfo = false;
            state.error.rateByPostingTimeExport = '';

            if (action.payload) {
                state.rateByPostingTimeExportData = action.payload;
            }
        },
        getRateByPostingTimeExportFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.rateByPostingTimeExport = false;
            if (action.payload) { state.error.rateByPostingTimeExport = action.payload; }
        },

        getRateByPostAttempt: (state, _action: RateByPostPayloadParams) => {
            state.actions.rateByPost = true;
            state.error.rateByPost = '';
        },
        getRateByPostSuccess: (state, action: PayloadAction<RateByPost[]>) => {
            state.actions.rateByPost = false;
            state.error.rateByPost = '';
            if (action.payload) {
                const valueUpdater = action.payload.map((item) => {
                    return ({
                        ...item,
                        avgViewRate: Number(Utils.Posts.rateConverter(item.avgViewRate)),
                        avgEngRate: Number(Utils.Posts.rateConverter(item.avgEngRate)),
                    });
                });

                state.rateByPostData = valueUpdater;
            }
        },
        getRateByPostFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.rateByPost = false;
            if (action.payload) { state.error.rateByPost = action.payload; }
        },

        getRateByPostModalInfoAttempt: (state, _action: RateByPostModalInfoPayloadParams) => {
            state.actions.rateByPostModalInfo = true;
            state.error.rateByPostModalInfo = '';
        },
        getRateByPostModalInfoSuccess: (state, action: PayloadAction<InformationPostModalInfo>) => {
            state.actions.rateByPostModalInfo = false;
            state.error.rateByPostModalInfo = '';

            if (action.payload) {
                state.rateByPostModalInfoData = action.payload;
            }
        },
        getRateByPostModalInfoFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.rateByPostModalInfo = false;
            if (action.payload) { state.error.rateByPostModalInfo = action.payload; }
        },

        getRateByPostExportAttempt: (state, _action: RateByPostExportPayloadParams) => {
            state.actions.rateByPostExport = true;
            state.error.rateByPostExport = '';
        },
        getRateByPostExportSuccess: (state, action: PayloadAction<GraphExportInfo[]>) => {
            state.actions.rateByPostExport = false;
            state.error.rateByPostExport = '';

            if (action.payload) {
                state.rateByPostModalExportData = action.payload;
            }
        },
        getRateByPostExportFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.rateByPostExport = false;
            if (action.payload) { state.error.rateByPostExport = action.payload; }
        },

        getPostsReportAttempt: (state, _action: PostsReportPayloadParams) => {
            state.actions.postsReport = true;
            state.error.postsReport = '';
        },
        getPostsReportSuccess: (state, action: PayloadAction<PostsReport>) => {
            state.actions.postsReport = false;
            state.error.postsReport = '';
            if (action.payload) { state.postsReportData = action.payload; }
        },
        getPostsReportFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.postsReport = false;
            if (action.payload) { state.error.postsReport = action.payload; }
        },

        getPostsReportExportAttempt: (state, _action: PostsReportExportPayloadParams) => {
            state.actions.postsReportExport = true;
            state.error.postsReportExport = '';
        },
        getPostsReportExportSuccess: (state, action: PayloadAction<PostsReportExport[]>) => {
            state.actions.postsReportExport = false;
            state.error.postsReportExport = '';

            if (action.payload) {
                const valueUpdater = action.payload.map((item) => {
                    return ({
                        ...item,
                        postCategory: Utils.Posts.categoryEnumToStringConverter(item.postCategory),
                        viewRate: Number(Utils.Posts.rateConverter(item.viewRate)),
                        engagementRate: Number(Utils.Posts.rateConverter(item.engagementRate)),
                    });
                });

                state.postsReportExportData = valueUpdater;
            }
        },
        getPostsReportExportFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.postsReportExport = false;
            if (action.payload) { state.error.postsReportExport = action.payload; }
        },

        editPostsReportAttempt: (state, _action: PayloadAction<EditPostsReport>) => {
            state.actions.editPostsReport = true;
            state.error.editPostsReport = '';
        },
        editPostsReportSuccess: (state, action: PayloadAction<EditPostsReport>) => {
            state.actions.editPostsReport = false;
            state.error.editPostsReport = '';

            if (action.payload) {
                state.editPostsReportData = {
                    ...state.editPostsReportData,
                    ...action.payload,
                };
            }
        },
        editPostsReportFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.editPostsReport = false;
            if (action.payload) { state.error.editPostsReport = action.payload; }
        },

        getPostsReportWithAmountOfDaysAttempt: (state, _action: PostsReportWithAmountOfDaysPayloadParams) => {
            state.actions.postsReportWithAmountOfDays = true;
            state.error.postsReportWithAmountOfDays = '';
        },
        getPostsReportWithAmountOfDaysSuccess: (state, action: PayloadAction<PostsReportWithAmountOfDays>) => {
            state.actions.postsReportWithAmountOfDays = false;
            state.error.postsReportWithAmountOfDays = '';
            if (action.payload) { state.postsReportWithAmountOfDaysData = action.payload; }
        },
        getPostsReportWithAmountOfDaysFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.postsReportWithAmountOfDays = false;
            if (action.payload) { state.error.postsReportWithAmountOfDays = action.payload; }
        },

        getPerformingPostsAttempt: (state, _action: PerformingPostsPayloadParams) => {
            state.actions.performingPost = true;
            state.error.performingPost = '';
        },
        getPerformingPostsSuccess: (state, action: PayloadAction<PerformingPosts>) => {
            state.actions.performingPost = false;
            state.error.performingPost = '';
            if (action.payload) { state.performingPostsData = action.payload; }
        },
        getPerformingPostsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.performingPost = false;
            if (action.payload) { state.error.performingPost = action.payload; }
        },

        getUserClaimsAttempt: (state) => {
            state.actions.userClaims = true;
            state.error.userClaims = '';
        },
        getUserClaimsSuccess: (state, action: PayloadAction<UserClaims>) => {
            state.actions.userClaims = false;
            state.error.userClaims = '';
            if (action.payload) { state.userClaimsData = action.payload; }
        },
        getUserClaimsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.userClaims = false;
            if (action.payload) { state.error.userClaims = action.payload; }
        },

        editToggleColumnsAttempt: (state, _action: PayloadAction<EditToggleColumns>) => {
            state.actions.editToggleColumn = true;
            state.error.editToggleColumn = '';
        },
        editToggleColumnsSuccess: (state, action: PayloadAction<EditToggleColumns>) => {
            state.actions.editToggleColumn = false;
            state.error.editToggleColumn = '';
            if (action.payload) { state.editToggleColumnData = action.payload; }
        },
        editToggleColumnsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.editToggleColumn = false;
            if (action.payload) { state.error.editToggleColumn = action.payload; }
        },

        setDateFrom: (state, action: PayloadAction<Date | null>) => {
            state.dateFrom = action.payload;
        },

        setDateTo: (state, action: PayloadAction<Date | null>) => {
            state.dateTo = action.payload;
        },

        setInformationPostModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.informationPostModalIsOpen = action.payload;
        },

        setInformationPostIndex: (state, action: PayloadAction<number>) => {
            state.informationPostIndex = action.payload;
        },

        setPostsReportIndex: (state, action: PayloadAction<number>) => {
            state.postsReportIndex = action.payload;
        },

        setPostsReportWithAmountOfDaysIndex: (state, action: PayloadAction<number>) => {
            state.postsReportWithAmountOfDaysIndex = action.payload;
        },

        setCurrentGraphCategory: (state, action: PayloadAction<string | undefined>) => {
            state.currentGraphCategory = action.payload;
        },

        setCurrentGraphWeek: (state, action: PayloadAction<string | undefined>) => {
            state.currentGraphWeek = action.payload;
        },

        setCurrentGraphHour: (state, action: PayloadAction<string | undefined>) => {
            state.currentGraphHour = action.payload;
        },

        setUserClaimsRedux: (state, action: PayloadAction<UserClaims>) => {
            state.userClaimsRedux = action.payload;
        },
        setPostsReportSelectedCategory: (state, action: PayloadAction<number>) => {
            state.selectedCategory = action.payload;
        },
        getSummaryDashboardDetailsForPostsAttempt: (state, _action: GetSummaryDashboardDetailsParams) => {
            state.actions.summaryDashboardDetails = true;
            state.error.summaryDashboardDetails = '';
        },
        getSummaryDashboardDetailsForPostsSuccess: (state, action: PayloadAction<SummaryDashboardDetails>) => {
            state.actions.summaryDashboardDetails = false;

            if (action.payload) {
                state.summaryDashboardDetailsData = action.payload;
            }

            state.error.summaryDashboardDetails = '';
        },
        getSummaryDashboardDetailsForPostsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.summaryDashboardDetails = false;

            if (action.payload) {
                state.error.summaryDashboardDetails = action.payload;
            }
        },
    } });

export type PostsState = typeof initialState;

export default {
    actions: posts.actions,
    reducers: posts.reducer,
};
