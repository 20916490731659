import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { GatewayResponseStatus } from 'api/types/types';
import GamesGateway from 'api/Games';
import { GetGamesTypePayload } from 'redux/slices/games/types';

export default function* watchGetGames(api: GamesGateway): SagaWatcherReturnType {
    yield takeEvery('games/getGamesAttempting', handleGetGames, api);
}

function* handleGetGames(api: GamesGateway, data: GetGamesTypePayload) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.getGames], { authToken, type: data.payload.type });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.getGamesFailure(response.message || ''));
    } if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.getGamesSuccess(response.data));
    }
}
