import React, { FunctionComponent, useState, useEffect } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import SVG from 'react-inlinesvg';
import Colors from 'assets/themes/Colors';
import Icons from '../assets/icons/Index';
import Button from './Button';

export interface CollapsibleProps {
    title?: any;
    content?: any;
    openFaqs: string[],
    id: string,
    showBorder?: boolean;
}

const StyledDiv = styled.div<{ css?: FlattenSimpleInterpolation, border?:boolean }>`
    border-radius: 8px;
    display: flex;
    position:relative;
    padding-right:1rem;

    
    // Message Content Border
    & > div {
        padding-left: 1rem;
        border-radius:5px;
    ${props => props.border && `
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    `}
    };

    ${props => props.css}
`;

const StyledCollapsibleDiv = styled.div<{ display: string }>`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    
    ${props => props.display && css`
        display: ${props.display};
    `} 
`;

const Collapsible: FunctionComponent<CollapsibleProps> = ({
    title,
    content,
    openFaqs,
    id,
    showBorder,
}: CollapsibleProps) => {
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        openFaqs.forEach(item => {
            if (item === id) setIsOpen(true);
            return false;
        });

        if (openFaqs.length === 0) setIsOpen(false);
    }, [openFaqs]);

    const renderChevron = () => {
        const { children = [] } = content.props || {};
        if (children.length) {
            return (
                <MainChevronWrapper css={styles.mainChevronWrapper}>
                    <StyledTransparentButton
                        onClick={() => setIsOpen(!isOpen)}
                        css={isOpen ? styles.chevronDown : styles.chevronRight}
                    >
                        <SVG src={Icons.ChevronRight} id='chevron' />
                    </StyledTransparentButton>
                </MainChevronWrapper>
            );
        }
        return null;
    };

    return (
        <StyledDiv css={isOpen ? styles.expandedDiv : styles.initialDiv} border={!showBorder}>
            {/* Title Wrapper */}
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', backgroundColor: Colors.lightCream, position: 'relative' }}>
                {renderChevron()}
                <div style={{ width: '100%', height: '100%' }}>
                    <div style={{ width: '100%', paddingLeft: '1rem' }}>
                        {title}
                    </div>

                    {isOpen && (
                        <StyledCollapsibleDiv
                            display='flex'
                        >
                            {content}
                        </StyledCollapsibleDiv>
                    )}

                </div>
            </div>
        </StyledDiv>
    );
};

interface CustomStyle {
    css?: FlattenSimpleInterpolation;
}

const StyledTransparentButton = styled(Button)`
    background-color: transparent;
`;

const MainChevronWrapper = styled.div<CustomStyle>`
    ${props => props.css}
`;

const styles = {
    mainChevronWrapper: css`
        width: 6%; 
        display: flex; 
        align-items: flex-start;
        justify-content: center;
        position: absolute;
        right:1rem;
        top:5px;
    `,
    chevronRight: css`
        background-color: transparent;
        margin-top: 5px;
        #chevron {
            width: 30px;
            height: 30px;
            transition: 0.3s;
            transform: rotate(-90deg);

            color: ${Colors.primary};

            &:hover{
                color: ${Colors.iqosPrimary};
            }
        }
    `,
    chevronDown: css`
        background-color: transparent;
        margin-top: 5px;
        #chevron {
            width: 30px;
            height: 30px;
            transform: rotate(90deg);
            transition: 0.3s;

            color: ${Colors.primary};

            &:hover{
                color: ${Colors.iqosPrimary};
            }
        }
    `,
    initialDiv: css`
        height: 100%;
        width: 100%;
        transition: height 0.5s;
        margin-bottom: 1rem;
    `,
    expandedDiv: css`
        width: 100%;
        transition: height 0.5s;
        margin-bottom: 6px;
        margin-bottom: 1rem;
    `,
};

export default Collapsible;
