import React from 'react';
import styled from 'styled-components';

interface AnalyticsContainerProps {
    children: JSX.Element[] | JSX.Element;
}

const AnalyticsContainer = (props: AnalyticsContainerProps): JSX.Element => {
    const { children } = props;

    return (
        <Container>
            {children}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: space-evenly;

    background-color: #F1F6F8;
    border-radius: 20px;

    padding-top: 1%;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 2%;

    width: 71%;
    height: 100%;

    margin-bottom: 5%;
    margin-right: 7%;
`;

export default AnalyticsContainer;
