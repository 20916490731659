import React, { FunctionComponent, useEffect, useState } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Spinner } from 'reactstrap';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { connect } from 'react-redux';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { AppDispatch, RootState } from 'redux/store';

import Icons from 'assets/icons/Index';
import Fonts from 'assets/themes/Fonts';

import { BroadcastHistoryFilter } from 'entities/broadcast';

import HistoryPostTable from 'components/broadcast/HistoryPostTable';
import DuplicateBroadcastModal from 'components/broadcast/DuplicateBroadcastModal';
import RecallBroadcastMessageModal from 'components/broadcast/RecallBroadcastMessageModal';
import BroadcastDetailsModal from 'components/broadcast/BroadcastDetailsModal';
import IconTooltip from 'components/IconTooltip';

interface HistoryPostProps {
    setDuplicateBroadcastModalIsOpen(data: boolean): void;
    setRecallBroadcastMessageModalIsOpen(data: boolean): void;
    setBroadcastDetailsModalIsOpen(data: boolean): void;
    getHistoryPostExport(type: BroadcastHistoryFilter): void;

    getBroadcastId: string;
    getExportBroadcastDetailsId: string;
    getDuplicateBroadcastModalIsOpen: boolean;
    getRecallBroadcastMessageModalIsOpen: boolean;
    getBroadcastDetailsModalIsOpen: boolean;
    isHistoryExportLoading: boolean;
}

const HistoryPost: FunctionComponent<HistoryPostProps> = (props: HistoryPostProps) => {
    const {
        setDuplicateBroadcastModalIsOpen,
        setRecallBroadcastMessageModalIsOpen,
        setBroadcastDetailsModalIsOpen,
        getHistoryPostExport,

        getBroadcastId,
        getExportBroadcastDetailsId,
        getDuplicateBroadcastModalIsOpen,
        getRecallBroadcastMessageModalIsOpen,
        getBroadcastDetailsModalIsOpen,
        isHistoryExportLoading,
    } = props;

    dayjs.extend(utc);

    const [historyDropdownIsOpen, setHistoryDropdownIsOpen] = useState<boolean>(false);
    const [historyStatus, setHistoryStatus] = useState<BroadcastHistoryFilter>(BroadcastHistoryFilter.All);
    const [historyFilter, setHistoryFilter] = useState<string>('Show all');

    const toggleDuplicatePostModal = (isBroadcast: boolean) => {
        if (isBroadcast) {
            setDuplicateBroadcastModalIsOpen(true);
        }
    };
    const toggleRecallBroadcastMessageModal = (isBroadcast: boolean) => {
        if (isBroadcast) {
            setRecallBroadcastMessageModalIsOpen(true);
        }
    };
    const toggleBroadcastDetailsModal = (isBroadcast: boolean) => {
        if (isBroadcast) {
            setBroadcastDetailsModalIsOpen(true);
        }
    };

    useEffect(() => {
        renderHistoryPostTable();
    }, [historyStatus]);

    const renderExportButtonText = () => {
        if (isHistoryExportLoading) {
            return (<Spinner size='sm' />);
        }
        return 'Export Data';
    };

    const historyExport = () => {
        getHistoryPostExport(historyStatus);
    };

    const renderHistoryPostTable = () => {
        return (
            <>
                <EmptyContainerForHashLink />
                <div style={{ width: '100%', padding: '0px 20px 30px 20px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <TableName>
                            <StyledText>Broadcast History Post</StyledText>
                            <IconTooltip
                                tooltipId='historyPost'
                                iconSrc={Icons.QuestionMark}
                                bgColor='#00D1D2'
                                fontColor='black'
                                size='xl'
                            >
                                You can scroll the table horizontally to see the rest of the columns.
                            </IconTooltip>
                        </TableName>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                            <Dropdown isOpen={historyDropdownIsOpen} toggle={() => setHistoryDropdownIsOpen(!historyDropdownIsOpen)}>
                                <DropdownToggle caret style={styles.filterButton}>
                                    <div style={{ paddingLeft: '10px', marginRight: '10px', overflowX: 'hidden' }}>
                                        {historyFilter}
                                    </div>
                                </DropdownToggle>
                                <DropdownMenu container='body' className='filter-menu-item' style={{ width: '180px', textAlign: 'center' }}>
                                    <DropdownItem onClick={() => [setHistoryStatus(BroadcastHistoryFilter.All), setHistoryFilter('Show all')]}>
                                        <StyledText style={{ fontSize: '18px', fontFamily: `${Fonts.secondary}` }}>Show all</StyledText>
                                    </DropdownItem>
                                    <DropdownItem onClick={() => [setHistoryStatus(BroadcastHistoryFilter.Immediate), setHistoryFilter('Immediate')]}>
                                        <StyledText style={{ fontSize: '18px', fontFamily: `${Fonts.secondary}` }}>Immediate</StyledText>
                                    </DropdownItem>
                                    <DropdownItem onClick={() => [setHistoryStatus(BroadcastHistoryFilter.Recurring), setHistoryFilter('Recurring')]}>
                                        <StyledText style={{ fontSize: '18px', fontFamily: `${Fonts.secondary}` }}>Recurring</StyledText>
                                    </DropdownItem>
                                    <DropdownItem onClick={() => [setHistoryStatus(BroadcastHistoryFilter.Scheduled), setHistoryFilter('Scheduled')]}>
                                        <StyledText style={{ fontSize: '18px', fontFamily: `${Fonts.secondary}` }}>Scheduled</StyledText>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>

                            <StyledButton onClick={() => historyExport()} css={styles.exportButton} type='button'>
                                {renderExportButtonText()}
                            </StyledButton>
                        </div>
                    </div>

                    <HistoryPostTable
                        duplicatePost={() => toggleDuplicatePostModal(true)}
                        recallBroadcastMessage={() => toggleRecallBroadcastMessageModal(true)}
                        broadcastDetails={() => toggleBroadcastDetailsModal(true)}
                        getStatus={historyStatus}
                    />

                    {getDuplicateBroadcastModalIsOpen && (
                        <DuplicateBroadcastModal
                            broadcastDuplicateId={getBroadcastId}
                        />
                    )}

                    {getRecallBroadcastMessageModalIsOpen && (
                        <RecallBroadcastMessageModal
                            recallBroadcastMessageId={getBroadcastId}
                        />
                    )}

                    {getBroadcastDetailsModalIsOpen && (
                        <BroadcastDetailsModal
                            broadcastDetailsId={getBroadcastId}
                            exportBroadcastDetailsId={getExportBroadcastDetailsId}
                        />
                    )}
                </div>
            </>
        );
    };

    return (
        renderHistoryPostTable()
    );
};

const EmptyContainerForHashLink = styled.div`
    height: 55px;
`;

const TableName = styled.h1`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    gap: 3%;

    height: 1.8rem;
    line-height: 1.8rem;
    padding-top: 14px;

    font-size: 1.8rem;
    font-weight: 500;
    font-family: ${Fonts.primary};
`;

const StyledText = styled.text`
    font-size: 20px;
    color: #27242E;
    font-family: ${Fonts.primary};
    font-weight: bold;
`;

type StyledCSSProps = {
    css?: FlattenSimpleInterpolation
}

const StyledButton = styled.button<StyledCSSProps>`
    ${props => props.css}
`;

const styles = {
    exportButton: css`
        background-color: #00D1D2;
        color: white;

        width: 183px;
        height: 46px;
        
        border: none;
        border-radius: 20px;
    `,
    filterButton: {
        backgroundColor: '#F1F6F8',
        fontSize: '18',
        fontFamily: 'Fonts.primary',
        color: 'black',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',

        width: '183px',
        height: '46px',

        border: '1px solid black',
        borderRadius: '20px',
        cursor: 'pointer',
    },
};

const mapStateToProps = (state: RootState) => ({
    getBroadcastId: Selectors.getBroadcastBroadcastId(state),
    getDuplicateBroadcastModalIsOpen: Selectors.getBroadcastDuplicateBroadcastModalIsOpen(state),
    getRecallBroadcastMessageModalIsOpen: Selectors.getBroadcastRecallBroadcastMessageModalIsOpen(state),
    getBroadcastDetailsModalIsOpen: Selectors.getBroadcastBroadcastDetailsModalIsOpen(state),
    isHistoryExportLoading: Selectors.getBroadcastHistoryPostExportAttempting(state),
    getExportBroadcastDetailsId: Selectors.getBroadcastExportBroadcastDetailsId(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setDuplicateBroadcastModalIsOpen: (data: boolean) => dispatch(Actions.setDuplicateBroadcastModalIsOpen(data)),
    setRecallBroadcastMessageModalIsOpen: (data: boolean) => dispatch(Actions.setRecallBroadcastMessageModalIsOpen(data)),
    setBroadcastDetailsModalIsOpen: (data: boolean) => dispatch(Actions.setBroadcastDetailsModalIsOpen(data)),
    getHistoryPostExport: (type: BroadcastHistoryFilter) => dispatch(Actions.getHistoryPostExportAttempt({ type })),
});

export default connect(mapStateToProps, mapDispatchToProps)(HistoryPost);
