import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';

import {
    ECommActivitiesReportData,
    ECommBuyNowClicksProductReportData,
    ECommBuyNowClicksReportData,
    ECommClicksProductReportData,
    ECommClicksReportData,
    ECommClicksVariantReportData,
    ECommFunnelReportData,
} from 'entities/ecommerceAnalytics';

export interface GetClicksReportApiParams {
    authToken: string;
    dateFrom: string;
    dateTo: string;
}

export interface GetClicksProductReportApiParams {
    authToken: string;
    productCategoryId: string;
    dateFrom: string;
    dateTo: string;
}

export interface GetClicksVariantReportApiParams {
    authToken: string;
    productId: string;
    dateFrom: string;
    dateTo: string;
}

export type GetBuyNowClicksReportApiParams = GetClicksReportApiParams;
export type GetBuyNowClicksProductReportApiParams = GetClicksProductReportApiParams;
export type GetBuyNowClicksVariantReportApiParams = GetClicksVariantReportApiParams;
export type GetActivitiesReportApiParams = GetClicksReportApiParams;

export interface GetFunnelReportApiParams {
    authToken: string;
    dateFrom: string;
    dateTo: string;
    productCategoryId: string;
}

export abstract class IECommerceAnalyticsGateway extends Gateway {
    abstract getClicksReport(params: GetClicksReportApiParams): GatewayResponse<ECommClicksReportData>;

    abstract getClicksProductReport(params: GetClicksProductReportApiParams): GatewayResponse<ECommClicksProductReportData>;

    abstract getClicksVariantReport(params: GetClicksVariantReportApiParams): GatewayResponse<ECommClicksVariantReportData[]>;

    abstract getBuyNowClicksReport(params: GetBuyNowClicksReportApiParams): GatewayResponse<ECommBuyNowClicksReportData>;

    abstract getBuyNowClicksProductReport(params: GetBuyNowClicksProductReportApiParams): GatewayResponse<ECommBuyNowClicksProductReportData>;

    abstract getBuyNowClicksVariantReport(params: GetBuyNowClicksVariantReportApiParams): GatewayResponse<ECommClicksVariantReportData[]>;

    abstract getActivitiesReport(params: GetActivitiesReportApiParams): GatewayResponse<ECommActivitiesReportData>;

    abstract getFunnelReport(params: GetFunnelReportApiParams): GatewayResponse<ECommFunnelReportData>;
}
