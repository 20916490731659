import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import SVG from 'react-inlinesvg';

import Text from 'components/Text';

import { FaqMessageTypeEnum } from 'entities/faq';

import Colors from 'assets/themes/Colors';
import Icons from 'assets/icons/Index';
import Flex from 'components/Flex';

interface FaqTypeDDProps {
    onChange: (type: FaqMessageTypeEnum) => void;
    displayIcons?: boolean;
}

const StyledDropdown = styled(Dropdown)`

    .dropdown-toggle::after {
        color:${Colors.iqosPrimary};
    }

    .btn-secondary {
        background-color: transparent;
        border: 1px solid ${Colors.iqosLightPrimary};
        box-shadow: none;
        outline:none;

        &:hover {
            color: ${Colors.iqosLightPrimary};
        }

        &:focus {
            background-color: ${Colors.iqosLightPrimary};
            color: white;
            border: none;
            box-shadow: none;
            outline:none;
        } 
    }
  
    ${props => props.css}
`;

const StyledDropdownToggle = styled(DropdownToggle)`
    background-color: transparent;
    font-size: 18px;
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledDropdownMenu = styled(DropdownMenu)`
    min-width: 130px;
`;

const StyledDropdownItem = styled(DropdownItem)`
    font-size: 18px;
    padding-top:6px;
    padding-bottom:6px;

    &:hover {
        background-color: ${Colors.iqosLightPrimary};
        color: white;
    }
`;

const FaqTypeDD: FunctionComponent<FaqTypeDDProps> = ({
    onChange,
    displayIcons,
}: FaqTypeDDProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [faqType, setFaqType] = useState('Text');

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const messageTypes = [{
        type: FaqMessageTypeEnum.Text,
        label: 'Text',
    },
    {
        type: FaqMessageTypeEnum.Image,
        label: 'Image',
    },
    {
        type: FaqMessageTypeEnum.Video,
        label: 'Video',
    },
    {
        type: FaqMessageTypeEnum.Document,
        label: 'Document',
    }];

    const dropdownItemClickHandler = (label: string, type: FaqMessageTypeEnum) => {
        setFaqType(label);
        onChange(type);
    };

    const convertLabelToIcons = (label: string) => {
        if (label === 'Text') {
            return <StyledSVG fill={Colors.iqosPrimary} src={Icons.Text} />;
        }

        if (label === 'Image') {
            return <StyledSVG fill={Colors.iqosPrimary} src={Icons.Image} />;
        }

        if (label === 'Video') {
            return <StyledSVG fill={Colors.iqosPrimary} src={Icons.Video} />;
        }

        if (label === 'Document') {
            return <StyledSVG fill={Colors.iqosPrimary} src={Icons.Pdf} />;
        }

        return null;
    };

    const renderDropdownItem = () => {
        return (
            messageTypes.map((item) => {
                const { type, label } = item;
                return (
                    <StyledDropdownItem onClick={() => dropdownItemClickHandler(label, type)} key={type}>
                        {displayIcons
                            ? (
                                <Flex gap='0.7rem' style={{ color: Colors.black }}>
                                    {convertLabelToIcons(label)}
                                    <div>{label}</div>
                                </Flex>
                            ) : label}
                    </StyledDropdownItem>
                );
            })
        );
    };

    return (
        <StyledDropdown isOpen={isOpen} toggle={toggleDropdown}>
            <StyledDropdownToggle caret>
                {displayIcons ? convertLabelToIcons(faqType) : (
                    <Text style={{ marginRight: '5px' }}>{faqType}</Text>
                )}
            </StyledDropdownToggle>
            <StyledDropdownMenu>
                {renderDropdownItem()}
            </StyledDropdownMenu>
        </StyledDropdown>
    );
};

const StyledSVG = styled(SVG)`
    height:30px;
    width:30px;
`;

export default FaqTypeDD;
