import { call, put, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import ECommerceAnalyticsGateway from 'api/ECommerceAnalytics';
import { GetClicksProductReportParams } from 'redux/slices/eCommerceAnalytics/types';

export default function* watchGetClicksProductReport(api: ECommerceAnalyticsGateway): SagaWatcherReturnType {
    yield takeEvery('eCommerceAnalytics/ecommAnalGetClicksProductReportAttempt', handleGetClicksProductReport, api);
}

function* handleGetClicksProductReport(api: ECommerceAnalyticsGateway, data: GetClicksProductReportParams) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const selectedDates = yield* select(Selectors.getECommerceAnalDatePicker);

    const { productCategoryId } = data.payload;

    const response = yield* call([api, api.getClicksProductReport], {
        authToken,
        productCategoryId,
        dateFrom: selectedDates.dateFrom.toISOString(),
        dateTo: selectedDates.dateTo.toISOString(),
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.ecommAnalGetClicksProductReportFailure(response.message));
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.ecommAnalGetClicksProductReportSuccess(response.data));
    }
}
