import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Spinner } from 'reactstrap';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label, Text, ResponsiveContainer, ReferenceLine } from 'recharts';

import { connect } from 'react-redux';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { AppDispatch, RootState } from 'redux/store';

import 'components/StyledComponent.css';
import IQOSDateRangePicker from 'components/IQOSDateRangePicker';
import TextComponent from 'components/Text';
import Fonts from 'assets/themes/Fonts';
import { IChannelTypeEnum } from 'entities/contests';
import { RateByPostingTime, InformationPostModalInfo } from 'entities/posts';
import Utils from 'lib/Utils';
import { SummaryDashboardDetails } from 'entities/summaryDashboard';
import dayjs from 'dayjs';
import InformationPost from './InformationPost';

interface RateByPostingTimeProps {
    isLanu: boolean;
    rateByPostingTimeData: RateByPostingTime[];
    rateByPostingTimeModalInfoData: InformationPostModalInfo;
    isLoading: boolean;
    isFailure: string;
    getDateFrom: Date;
    getDateTo: Date;
    informationPostsIndex: number;
    getCurrentGraphHour: string | undefined;
    summaryDashboardDetailsData: SummaryDashboardDetails;

    setCurrentGraphHour(value: string | undefined): void;
    setInformationPostModalIsOpen(data: boolean): void;
    setDateFrom(date: Date | null): void;
    setDateTo(date: Date | null): void;
    getRateByPostingTime(channelType: string, dateFrom: string, dateTo: string): void;
    getRateByPostingTimeModalInfo(hour: number, channelType: string, dateFrom: string, dateTo: string, index: number): void;
    getSummaryDashboardDetails(type: string, dateFrom: string, dateTo: string, isYtd: string): void;
}

const RateByPostingTimeTab: FunctionComponent<RateByPostingTimeProps> = (props: RateByPostingTimeProps) => {
    const {
        isLanu,
        rateByPostingTimeData,
        rateByPostingTimeModalInfoData,
        isLoading,
        isFailure,
        getDateFrom,
        getDateTo,
        informationPostsIndex,
        getCurrentGraphHour,
        summaryDashboardDetailsData,

        setCurrentGraphHour,
        setInformationPostModalIsOpen,
        setDateFrom,
        setDateTo,
        getRateByPostingTime,
        getRateByPostingTimeModalInfo,
        getSummaryDashboardDetails,
    } = props;

    const [localDateFrom, setLocalDateFrom] = useState<Date | null>(getDateFrom);
    const [localDateTo, setLocalDateTo] = useState<Date | null>(getDateTo);

    const [callApiData, setCallApiData] = useState<boolean>(false);

    useEffect(() => {
        if (localDateFrom && localDateTo) {
            getRateByPostingTime(`${(isLanu) ? IChannelTypeEnum.LANU : IChannelTypeEnum.LAU}`, localDateFrom.toISOString(), localDateTo.toISOString());
        }
    }, [isLanu, localDateTo]);

    useEffect(() => {
        if (callApiData === true && getCurrentGraphHour !== '' && localDateFrom && localDateTo) {
            getRateByPostingTimeModalInfo(Utils.Posts.hourStringToNumberConverter(getCurrentGraphHour), `${(isLanu) ? IChannelTypeEnum.LANU : IChannelTypeEnum.LAU}`, localDateFrom.toISOString(), localDateTo.toISOString(), informationPostsIndex);
        }
    }, [getCurrentGraphHour, isLanu, localDateTo, informationPostsIndex, callApiData]);

    useEffect(() => {
        const newDateFrom = dayjs(localDateFrom).format('YYYY-MM-DDT00:00:00');
        const newDateTo = dayjs(localDateTo).format('YYYY-MM-DDT00:00:00');
        if (localDateTo) {
            getSummaryDashboardDetails(`${(isLanu) ? IChannelTypeEnum.LANU : IChannelTypeEnum.LAU}`, newDateFrom, newDateTo, 'false');
        }
    }, [isLanu, localDateTo]);

    useEffect(() => {
        setDateFrom(localDateFrom);
        setDateTo(localDateTo);
    }, [localDateFrom, localDateTo]);

    const toggleInformationModal = (data: string | undefined) => {
        setCurrentGraphHour(data);
        setInformationPostModalIsOpen(true);
    };

    const rateByPostingTimeGraph = () => {
        const { viewRateCurrentDate, engagementRateCurrentDate } = summaryDashboardDetailsData;
        const overallView = `Overall View Rate - ${(viewRateCurrentDate * 100).toFixed(2)}%`;
        const overallEng = `Overall Engagement Rate - ${(engagementRateCurrentDate * 100).toFixed(2)}%`;

        if (isLoading) {
            return (
                <div style={{ textAlign: 'center' }}>
                    <Spinner />
                </div>
            );
        }

        if (isFailure.length) {
            return (
                <div style={{ textAlign: 'center' }}>
                    <TextComponent style={{ color: 'red' }}>Something went wrong. Please try again.</TextComponent>
                </div>
            );
        }

        return (
            <ResponsiveContainer width='99%' height={(rateByPostingTimeData.length > 0) ? 550 : 'auto'}>
                {(rateByPostingTimeData.length > 0) ? (
                    <BarChart
                        width={1400}
                        height={550}
                        data={rateByPostingTimeData}
                        barSize={20}
                        margin={{
                            top: 120,
                            right: 25,
                            left: 25,
                            bottom: 50,
                        }}
                        onClick={(data) => [setCallApiData(true), toggleInformationModal(data.activeLabel)]}
                    >

                        <CartesianGrid strokeDasharray='none' vertical={false} />

                        <XAxis
                            height={50}
                            axisLine={false}
                            tickLine={false}
                            fontSize='13px'
                            dataKey='hour'
                        />
                        <YAxis axisLine={false} tickLine={false} fontSize='13px'>
                            <Label value={overallView} offset={30} position={{ x: 250, y: -90 }} />
                            <Label value={overallEng} offset={30} position={{ x: 295, y: -60 }} />
                        </YAxis>
                        <Tooltip />
                        <Legend
                            align='right'
                            verticalAlign='top'
                            width={500}
                            wrapperStyle={{ top: 40, right: 30, textAlign: 'end' }}
                            payload={
                                [
                                    { id: 'avgViewRate', value: 'Average View Rate', type: 'circle', color: '#00D1D2' },
                                    { id: 'avgEngRate', value: 'Average Engagement Rate', type: 'circle', color: '#DB3624' },
                                    { id: 'overallView', value: 'Overall View Rate', type: 'plainline', payload: { strokeDasharray: '6 5' }, color: '#00D1D2' },
                                    { id: 'overallEng', value: 'Overall Engagement Rate', type: 'plainline', payload: { strokeDasharray: '6 5' }, color: '#DB3624' },
                                ]
                            }
                        />
                        <Bar
                            name='Average View Rate'
                            dataKey='avgViewRate'
                            fill='#00D1D2'
                        />
                        <Bar
                            name='Average Engagement Rate'
                            dataKey='avgEngRate'
                            fill='#DB3624'
                        />
                        <ReferenceLine y={(viewRateCurrentDate * 100).toFixed(2)} stroke='#0480807d' strokeDasharray='14 14' strokeWidth={3} />
                        <ReferenceLine y={(engagementRateCurrentDate * 100).toFixed(2)} stroke='#882217a6' strokeDasharray='14 14' strokeWidth={3} />
                    </BarChart>
                ) : (
                    <div style={{ textAlign: 'center' }}>
                        <TextComponent>There appears to be no data yet.</TextComponent>
                    </div>
                )}
            </ResponsiveContainer>
        );
    };

    return (
        <TabBodyWrapper>
            <TabHeaderWrapper>
                <StyledText style={{ fontFamily: `${Fonts.secondary}`, fontSize: '22px' }}>
                    Average Rate Across Different Posting Time
                </StyledText>
                <MonthWrapper>
                    <DateDisplayText>
                        <IQOSDateRangePicker
                            dateFrom={localDateFrom}
                            dateTo={localDateTo}
                            setDateFrom={setLocalDateFrom}
                            setDateTo={setLocalDateTo}
                        />
                    </DateDisplayText>
                </MonthWrapper>
            </TabHeaderWrapper>
            <StyledGraphWrapper>
                {rateByPostingTimeGraph()}
            </StyledGraphWrapper>
            <InformationPost dataToDisplay={rateByPostingTimeModalInfoData} exportType={2} />
        </TabBodyWrapper>
    );
};

const TabBodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%; 
`;

const MonthWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    min-width: 600px;
`;

const StyledText = styled.text`
    text-align: left;
    font-size: 18px;
`;

const TabHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 50px 20px 20px 40px;
`;

const DateDisplayText = styled.text`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    width: 100%;
    gap: 5px;
    padding-right: 15px;

    color: lightgrey;
    font-size: 18px;
    text-align: left;
`;

const StyledGraphWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 94%;
    min-height: 425px;
    margin-top: 1%;

    background-color: white;
`;

const mapStateToProps = (state: RootState) => ({
    isLanu: Selectors.getFaqGetIsLANU(state),
    rateByPostingTimeData: Selectors.getPostsGetRateByPostingTime(state),
    rateByPostingTimeModalInfoData: Selectors.getPostsGetRateByPostingTimeModalInfo(state),
    isLoading: Selectors.getPostsGetRateByPostingTimeAttempting(state),
    isFailure: Selectors.getPostsGetRateByPostingTimeFailure(state),
    getDateFrom: Selectors.getPostsGetDateFrom(state),
    getDateTo: Selectors.getPostsGetDateTo(state),
    informationPostsIndex: Selectors.getPostsGetInformationPostIndex(state),
    getCurrentGraphHour: Selectors.getPostsGetCurrentGraphHour(state),
    summaryDashboardDetailsData: Selectors.getPostsGetSummaryDashboardDetails(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setCurrentGraphHour: (value: string | undefined) => dispatch(Actions.setCurrentGraphHour(value)),
    setInformationPostModalIsOpen: (data: boolean) => dispatch(Actions.setInformationPostModalIsOpen(data)),
    setDateFrom: (date: Date | null) => dispatch(Actions.setDateFrom(date)),
    setDateTo: (date: Date | null) => dispatch(Actions.setDateTo(date)),
    getRateByPostingTime: (channelType: string, dateFrom: string, dateTo: string) => dispatch(Actions.getRateByPostingTimeAttempt({ channelType, dateFrom, dateTo })),
    getRateByPostingTimeModalInfo: (hour: number, channelType: string, dateFrom: string, dateTo: string, index: number) => dispatch(Actions.getRateByPostingTimeModalInfoAttempt({ hour, channelType, dateFrom, dateTo, index })),
    getSummaryDashboardDetails: (type: string, dateFrom: string, dateTo: string, isYtd: string) => dispatch(Actions.getSummaryDashboardDetailsForPostsAttempt({ type, dateFrom, dateTo, isYtd })),
});

export default connect(mapStateToProps, mapDispatchToProps)(RateByPostingTimeTab);
