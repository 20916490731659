import React from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';

import Icons from 'assets/icons/Index';
import Colors from 'assets/themes/Colors';
import Text from 'components/Text';

interface Props {
    isYtd: true;

    label: string;
    subtitle?: string;
    current: number;
    prev: number;
    diff: number;

    selectedYear: number;

    reverse?: boolean;
    isPercentage?: boolean;

    id?: string;
    onClick?: (id: string) => void;
}

interface PropsNotYtd {
    isYtd: false | undefined;

    label: string;
    subtitle?: string;
    current: number;
    isPercentage?: boolean;

    id?: string;
    onClick?: (id: string) => void;
}

const StatCard = (props: Props | PropsNotYtd): React.ReactElement => {
    const { label, current, isPercentage, isYtd, onClick } = props;

    const displayedCurrent = isPercentage ? `${(current * 100).toFixed(2)}%` : current.toLocaleString();

    const renderComparison = (): JSX.Element | null => {
        if (!isYtd || isYtd !== true) return null;

        const { prev, diff, reverse, selectedYear } = props;

        let arrowColor = '';
        let arrowRotation = '';

        if (diff < 0) {
            arrowRotation = 'rotate(90deg)';

            if (reverse) arrowColor = Colors.teal;
            else arrowColor = Colors.red;
        } else {
            arrowRotation = 'rotate(270deg)';

            if (reverse) arrowColor = Colors.red;
            else arrowColor = Colors.teal;
        }

        const displayedPrev = isPercentage ? `${(prev * 100).toFixed(2)}%` : prev.toLocaleString();
        const displayedDiff = isPercentage ? `${(diff * 100).toFixed(2)}%` : diff.toLocaleString();

        return (
            <>
                <StyledText style={{ fontSize: '18px', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '3px', color: `${diff === 0 ? 'black' : arrowColor}` }}>
                    {diff !== 0 && (
                        <SVG
                            style={{
                                fill: arrowColor,
                                width: '20px',
                                strokeWidth: '0.1px',
                                transform: arrowRotation,
                            }}
                            src={Icons.RightArrow}
                            id='editIcon'
                        />
                    )}
                    {displayedDiff}
                </StyledText>
                <StyledText style={{ fontSize: '18px', color: '#A2A2A2' }}>{`(${displayedPrev} in ${selectedYear - 1})`}</StyledText>
            </>
        );
    };

    const renderSubtitle = (): JSX.Element | null => {
        const { subtitle } = props;

        return (
            <Subtitle>
                {subtitle}
            </Subtitle>
        );
    };

    const renderClickableIcon = (): JSX.Element | null => {
        if (!onClick) return null;

        return (
            <GraphIconWrapper>
                <SVG
                    style={{
                        width: '28px',
                        strokeWidth: '0.1px',
                    }}
                    src={Icons.AnalyticsGraph}
                    id='analyticsGraph'
                />
            </GraphIconWrapper>
        );
    };

    const onClickHandler = () => {
        const { id } = props;

        if (onClick && id) {
            return () => onClick(id);
        }
        return undefined;
    };

    return (
        <DataDisplayWrapper onClick={onClickHandler()}>
            <StyledBodyText>
                <StyledText>{label}</StyledText>
                <StyledValue>{displayedCurrent}</StyledValue>

                <StyledBodyText style={{ flexDirection: 'row', padding: '0px', justifyContent: 'flex-start', alignItems: 'center', gap: '5px' }}>
                    {renderComparison()}
                    {renderSubtitle()}
                    {renderClickableIcon()}
                </StyledBodyText>
            </StyledBodyText>
        </DataDisplayWrapper>
    );
};

const DataDisplayWrapper = styled.div`
    min-width: 100%;
    background-color: white;
    color: black;
    
    padding: 30px;

    border-radius: 10px;
    cursor: ${props => (props.onClick ? 'pointer' : 'auto')};
`;

const StyledBodyText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
`;

const StyledText = styled(Text)`
    font-size: 25px;
    @media (max-width: 1580px) {
        font-size: 16px;
    }
`;

const StyledValue = styled(Text)`
    font-size: 70px;

    @media (max-width: 1580px) {
        font-size: 58px;
    }
`;

const Subtitle = styled(Text)`
    font-size: 16px;
    color: #A2A2A2;
`;

const GraphIconWrapper = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
`;

StatCard.defaultProps = {
    isPercentage: false,
    reverse: false,
};

export default StatCard;
