import React from 'react';
import { Row, Col, FormGroup, Spinner } from 'reactstrap';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

import { RootState, AppDispatch } from 'redux/store';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

import Flex from 'components/Flex';
import IQOSModal from 'components/IQOSModal';

import Colors from 'assets/themes/Colors';

import Utils from 'lib/Utils';

import { ContestListTypesEnum, ICreateQuizModalState } from 'entities/contests';
import { ICreateGame } from 'entities/games';

interface CreateGameModalProps {
    modalState: ICreateQuizModalState;
    createGameIsLoading: boolean;
    createGameErrors: string;
    createGame: (params: ICreateGame) => void;
    changeModalState: (params: ICreateQuizModalState) => void;
    clearError: () => void;
}

const CreateQuizSchema = z.object({
    name: z
        .string()
        .trim()
        .min(5, 'Minimum \'Contest Name\' length is 5'),
});

export type CreateQuizType = z.infer<typeof CreateQuizSchema>;

const CreateGameModal:React.FunctionComponent<CreateGameModalProps> = ({
    modalState,
    createGame,
    changeModalState,
    createGameIsLoading,
    createGameErrors,
    clearError,
}:CreateGameModalProps) => {
    const initialFormDataState = {
        name: '',
    };

    const [isConfirmed, setIsConfirmed] = React.useState<boolean>(false);
    const [formData, setFormData] = React.useState<CreateQuizType>(initialFormDataState);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm<CreateQuizType>({
        resolver: zodResolver(CreateQuizSchema),
        defaultValues: initialFormDataState,
    });

    React.useEffect(() => {
        if (modalState.isOpen === true) {
            reset();
            setFormData(initialFormDataState);
        }

        if (isConfirmed) {
            setIsConfirmed(false);
        }
    }, [modalState.isOpen]);

    const toggle = () => {
        changeModalState({
            isOpen: !modalState.isOpen,
            type: modalState.type,
        });
    };

    const onSubmit = handleSubmit((data) => {
        if (isValid) {
            setIsConfirmed(true);
            setFormData(data);
        }
    });

    const cancelSubmit = () => {
        setIsConfirmed(false);
        clearError();
    };

    const create = () => {
        createGame(formData);
    };

    const header = isConfirmed ? 'Confirm to create a new quiz game?' : `Create New ${Utils.ModifyString.capitalizeFirstLetter(ContestListTypesEnum.Game)}`;

    return (
        <IQOSModal
            header={header}
            isOpen={modalState.isOpen}
            toggle={toggle}
            size={isConfirmed ? 'sm' : 'lg'}
        >
            {!isConfirmed ? (
                <form onSubmit={onSubmit} autoComplete='off'>
                    <Row>
                        <StyledCol>
                            <FormGroup>
                                <StyledLabel>
                                    Contest Name
                                </StyledLabel>
                                <StyledInput error={errors.name && true} {...register('name', { valueAsNumber: false })} />
                            </FormGroup>
                        </StyledCol>
                    </Row>
                    <Flex gap='1rem' margin='1rem 0 0 0'>
                        <SubmitButton type='submit' value='Submit' />
                        <StyledButton type='button' onClick={() => toggle()}>Cancel</StyledButton>
                    </Flex>
                    {(errors.name) && (
                        <Flex flexDirection='column' padding='1rem 0 0 0' gap='1rem'>
                            {errors.name && <StyledError>{errors.name.message}</StyledError>}
                        </Flex>
                    )}
                </form>
            ) : (
                <Flex justifyContent='center' flexWrap='wrap' flexDirection='column' gap='1rem'>
                    {createGameErrors.length > 1 && (<StyledError>{createGameErrors}</StyledError>)}
                    <Flex gap='1rem'>
                        <StyledPrimaryButton type='button' onClick={() => create()}>
                            {createGameIsLoading ? <Spinner size='sm' /> : 'Save' }
                        </StyledPrimaryButton>
                        <StyledButton type='button' onClick={() => cancelSubmit()}>Cancel</StyledButton>
                    </Flex>
                </Flex>
            )}
        </IQOSModal>
    );
};

interface StyledCSSProps {
    css?: FlattenSimpleInterpolation;
}

const SubmitButton = styled.input<StyledCSSProps>`
    background-color: ${Colors.iqosPrimary};
    border: 1px solid ${Colors.iqosPrimary};
    color: ${Colors.white};
    padding: 0.5rem 1.6rem;
    border-radius:24px;
    min-width:90px;  
    ${props => props.css}
`;

const StyledPrimaryButton = styled.button<StyledCSSProps>`
background-color: ${Colors.iqosPrimary};
border: 1px solid ${Colors.iqosPrimary};
color: ${Colors.white};
padding: 0.5rem 1.6rem;
border-radius:24px;
min-width:90px; 
`;

const StyledButton = styled.button<StyledCSSProps>`
    background-color: ${Colors.white};
    border: 1px solid ${Colors.iqosPrimary};
    padding: 0.5rem 1.6rem;
    border-radius:24px;
    min-width:90px;
    color: ${Colors.iqosPrimary};
    ${props => props.css}
`;

const StyledLabel = styled.div`
    margin-bottom: 0.3rem;
`;

const StyledCol = styled(Col)`
    color: ${Colors.lightGrey}FF;
`;

interface StyledInputProps {
    error?: boolean
}

const StyledInput = styled.input<StyledInputProps>`
    background-color: #F7F7F7;
    border-color: transparent;

    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-clip: padding-box;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    ${props => props.error && (`border: 1px solid ${Colors.red};`)}

    &:focus {
        background-color: #F7F7F7;
        ${props => props.error && (`border: 1px solid ${Colors.red};`
    )}
        outline:none;
    }
`;

const StyledError = styled.div`
    color: ${Colors.red};
`;

const mapStateToProps = (state: RootState) => ({
    modalState: Selectors.contestGetCreateContestModalState(state),

    createGameIsLoading: Selectors.getGamesCreateGameAttempting(state),
    createGameErrors: Selectors.getGamesCreateGameFailure(state),

});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    createGame: (params: ICreateGame) => dispatch(Actions.createGameAttempting(params)),

    changeModalState: (param: ICreateQuizModalState) => dispatch(Actions.contestSetCreateQuizModalState(param)),

    clearError: () => dispatch(Actions.gamesClearCreateGamesError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateGameModal);
