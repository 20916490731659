import { ApiResponse } from 'apisauce';

import { IGame, IGameResponse, IGetGameDetailsSuccess, IGetGameResponsesResponse } from 'entities/games';
import IGamesGateway, { CreateGameParams, DeleteGameParams, EditGameDetailsParams, GetGameDetailsParams, GetGameDetailsResponseParams, GetGamesParams, GetExportResponsesParams } from './GamesBase';
import { GatewayResponse } from './types/types';

const getHeaders = (authToken: string) => ({
    headers: {
        Authorization: `Bearer ${authToken}`,
    },
});

export default class GamesGateway extends IGamesGateway {
    async getGames(params: GetGamesParams): GatewayResponse<IGame[]> {
        const { type } = params;
        const response: ApiResponse<IGame[]> = await this.api.get('/v1/games', { type }, getHeaders(params.authToken));
        return this.process(response);
    }

    async createGame(params: CreateGameParams): GatewayResponse<IGame[]> {
        const response: ApiResponse<IGame[]> = await this.api.post('/v1/games', params.data, getHeaders(params.authToken));
        return this.process(response);
    }

    async deleteGame(params: DeleteGameParams): GatewayResponse<string> {
        const response: ApiResponse<string> = await this.api.delete(`/v1/games/${params.data.id}`, {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async getGameDetails(params: GetGameDetailsParams): GatewayResponse<IGetGameDetailsSuccess> {
        const { type } = params;
        const response: ApiResponse<IGetGameDetailsSuccess> = await this.api.get(`/v1/games/${params.id}`, { type }, getHeaders(params.authToken));
        return this.process(response);
    }

    async editGameDetails(params: EditGameDetailsParams): GatewayResponse<null> {
        const response: ApiResponse<null> = await this.api.put('/v1/games/', params.data, getHeaders(params.authToken));
        return this.process(response);
    }

    async getGameDetailsResponse(params: GetGameDetailsResponseParams): GatewayResponse<IGetGameResponsesResponse> {
        const { gameId, index, type } = params;
        const response: ApiResponse<IGetGameResponsesResponse> = await this.api.get(`/v1/games/responses?gameId=${gameId}&index=${index}&type=${type}`, {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async getExportResponses(params: GetExportResponsesParams): GatewayResponse<IGameResponse[]> {
        const response: ApiResponse<IGameResponse[]> = await this.api.get(`/v1/games/exportResponses?gameId=${params.gameId}&type=${params.type}`, {}, getHeaders(params.authToken));
        return this.process(response);
    }
}
