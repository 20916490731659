import { put, call, select, takeEvery } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import PostsGateway from 'api/Posts';
import { RateByPostingTimeModalInfoPayloadParams } from 'redux/slices/posts/types';

export default function* watchGetRateByPostingTimeModalInfo(api: PostsGateway): SagaWatcherReturnType {
    yield takeEvery('posts/getRateByPostingTimeModalInfoAttempt', handleRateByPostingTimeModalInfo, api);
}

function* handleRateByPostingTimeModalInfo(api: PostsGateway, data: RateByPostingTimeModalInfoPayloadParams) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.getRateByPostingTimeModalInfo], {
        authToken,
        hour: data.payload.hour,
        channelType: data.payload.channelType,
        dateFrom: data.payload.dateFrom,
        dateTo: data.payload.dateTo,
        index: data.payload.index,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.getRateByPostingTimeModalInfoFailure(response.message || ''));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.getRateByPostingTimeModalInfoSuccess(response.data));
    }
}
