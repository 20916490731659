export interface ISpreadsheetColumn {
    label: string;
    value: string;
}

const GrowthColumns: ISpreadsheetColumn[] = [
    {
        label: 'Date',
        value: 'date',
    },
    {
        label: 'Total Followers',
        value: 'totalFollowers',
    },
];

const FollowersColumns: ISpreadsheetColumn[] = [
    {
        label: 'Date',
        value: 'date',
    },
    {
        label: 'Joined',
        value: 'joined',
    },
    {
        label: 'Left',
        value: 'left',
    },
];

const NotificationsColumns: ISpreadsheetColumn[] = [
    {
        label: 'Date',
        value: 'date',
    },
    {
        label: 'Muted',
        value: 'muted',
    },
    {
        label: 'Unmuted',
        value: 'unmuted',
    },
];

const ViewsBySourceColumns: ISpreadsheetColumn[] = [
    {
        label: 'Date',
        value: 'date',
    },
    {
        label: 'Followers',
        value: 'followers',
    },
    {
        label: 'Other',
        value: 'other',
    },
    {
        label: 'Channels',
        value: 'channels',
    },
    {
        label: 'Pm',
        value: 'pm',
    },
    {
        label: 'Total',
        value: 'total',
    },
];

const InteractionsColumns: ISpreadsheetColumn[] = [
    {
        label: 'Date',
        value: 'date',
    },
    {
        label: 'Views',
        value: 'views',
    },
    {
        label: 'Shares',
        value: 'shares',
    },
];

const PostsColumns: ISpreadsheetColumn[] = [
    {
        label: 'Title',
        value: 'title',
    },
    {
        label: 'Created At',
        value: 'createdAt',
    },
    {
        label: 'Views',
        value: 'views',
    },
    {
        label: 'Shares',
        value: 'shares',
    },
    {
        label: 'Updated At',
        value: 'updatedAt',
    },
];

const FollowersDailyData: ISpreadsheetColumn[] = [
    {
        label: 'Date',
        value: 'date',
    },
    {
        label: 'New Followers',
        value: 'newFollowers',
    },
    {
        label: 'Unsubscribers',
        value: 'unsubscribers',
    },
    {
        label: 'Net Followers',
        value: 'netFollowers',
    },
];

const FollowersMonthlyData: ISpreadsheetColumn[] = [
    {
        label: 'Month',
        value: 'month',
    },
    {
        label: 'New Followers',
        value: 'newFollowers',
    },
    {
        label: 'Unsubscribers',
        value: 'unsubscribers',
    },
    {
        label: 'Net Followers',
        value: 'netFollowers',
    },
];

const FullFollowersData: ISpreadsheetColumn[] = [
    {
        label: 'Telegram ID',
        value: 'telegramId',
    },
    {
        label: 'Username',
        value: 'username',
    },
    {
        label: 'Phone Number',
        value: 'phoneNumber',
    },
    {
        label: 'First Name',
        value: 'firstName',
    },
    {
        label: 'Last Name',
        value: 'lastName',
    },
    {
        label: 'Date Joined',
        value: 'dateJoined',
    },
    {
        label: 'User Blocked Bot',
        value: 'hasBlockedBot',
    },
];

export default {
    GrowthColumns,
    FollowersColumns,
    NotificationsColumns,
    ViewsBySourceColumns,
    InteractionsColumns,
    PostsColumns,
    FollowersDailyData,
    FollowersMonthlyData,
    FullFollowersData,
};
