import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { RootState, AppDispatch } from 'redux/store';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

import Flex from 'components/Flex';
import IQOSModal from 'components/IQOSModal';

import { ContestListTypesEnum, IChannelTypeEnum, IContestDetails, IDeleteContest, IDeleteQuizModalState } from 'entities/contests';
import { IDeleteGame, IGameDetails } from 'entities/games';
import Colors from 'assets/themes/Colors';
import { Spinner } from 'reactstrap';

interface DeleteContestModalProps {
    isLanu: boolean;
    quizIsLoading: boolean;
    gameIsLoading: boolean;
    quizError: string;
    gameError: string;
    modalState: IDeleteQuizModalState;
    gameDetails: IGameDetails;
    quizDetails: IContestDetails;
    deleteQuiz: (param: IDeleteContest) => void;
    deleteGame: (param: IDeleteGame) => void;
    changeModalState: (param: IDeleteQuizModalState) => void;

    getQuizDetails: (id: string, type:IChannelTypeEnum) => void;
    getGameDetails: (id: string, type:IChannelTypeEnum) => void;
}

const DeleteContestModal:React.FunctionComponent<DeleteContestModalProps> = (
    {
        isLanu,
        quizIsLoading,
        gameIsLoading,

        quizError,
        gameError,

        deleteQuiz,
        deleteGame,
        modalState,
        changeModalState,

        gameDetails,
        quizDetails,

        getQuizDetails,
        getGameDetails,
    }:DeleteContestModalProps,
) => {
    React.useEffect(() => {
        if (modalState.isOpen) {
            if (modalState.type === ContestListTypesEnum.Quiz) {
                getQuizDetails(modalState.id, isLanu ? IChannelTypeEnum.LANU : IChannelTypeEnum.LAU);
            }

            if (modalState.type === ContestListTypesEnum.Game) {
                getGameDetails(modalState.id, isLanu ? IChannelTypeEnum.LANU : IChannelTypeEnum.LAU);
            }
        }
    }, [modalState.isOpen]);

    const toggle = () => {
        changeModalState({ ...modalState, isOpen: !modalState.isOpen });
    };

    const deleteContest = () => {
        if (modalState.type === ContestListTypesEnum.Quiz) {
            deleteQuiz({ _id: modalState.id });
        } else {
            deleteGame({ id: modalState.id });
        }
    };
    const contestName = modalState.type === ContestListTypesEnum.Quiz ? quizDetails.name : gameDetails.name;
    return (
        <IQOSModal
            header={`Confirm to delete ${modalState.type} '${contestName}'?`}
            isOpen={modalState.isOpen}
            toggle={toggle}
            size='sm'
        >
            <Flex gap='1rem'>
                <StyledSubmitButton type='button' onClick={() => deleteContest()} disabled={gameIsLoading || quizIsLoading}>
                    { (gameIsLoading || quizIsLoading) ? <Spinner size='sm' /> : 'Confirm Delete' }
                </StyledSubmitButton>
                <StyledCancelButton type='button' onClick={() => changeModalState({ ...modalState, isOpen: false })}>
                    Cancel
                </StyledCancelButton>
            </Flex>
        </IQOSModal>
    );
};

const StyledSubmitButton = styled.button`
    background-color: ${Colors.iqosPrimary};
    border: 1px solid ${Colors.iqosPrimary};
    color: ${Colors.white};
    padding: 0.5rem 1.6rem;
    border-radius:24px;
    min-width:90px;  

    &:disabled {
        background-color: ${Colors.lightGrey};
        border-color: ${Colors.lightGrey};
    }
`;

const StyledCancelButton = styled.button`
    background-color: ${Colors.white};
    border: 1px solid ${Colors.iqosPrimary};
    color: ${Colors.iqosPrimary};
    padding: 0.5rem 1.6rem;
    border-radius:24px;
    min-width:90px;  

    &:disabled {
        background-color: ${Colors.lightGrey};
        border-color: ${Colors.lightGrey};
    }
`;

const mapStateToProps = (state: RootState) => ({
    isLanu: Selectors.getFaqGetIsLANU(state),

    quizIsLoading: Selectors.deleteContestAttempt(state),
    gameIsLoading: Selectors.getGamesDeleteGameAttempting(state),

    quizError: Selectors.deleteContestFailure(state),
    gameError: Selectors.getGamesDeleteGameFailure(state),

    modalState: Selectors.contestGetDeleteContestModalState(state),

    gameDetails: Selectors.getGameDetails(state),
    quizDetails: Selectors.getContestDetails(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    deleteQuiz: (param:IDeleteContest) => dispatch(Actions.deleteContestAttempt(param)),
    deleteGame: (param: IDeleteGame) => dispatch(Actions.deleteGameAttempting(param)),

    changeModalState: (param: IDeleteQuizModalState) => dispatch(Actions.contestSetDeleteQuizModalState(param)),

    getQuizDetails: (id: string, type: IChannelTypeEnum) => dispatch(Actions.contestGetContestDetailsAttempt({ id, type })),
    getGameDetails: (id: string, type: IChannelTypeEnum) => dispatch(Actions.getGameDetailsAttempting({ id, type })),

});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteContestModal);
