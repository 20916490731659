import { fork } from 'redux-saga/effects';
import FollowersGateway from 'api/Followers';

import { RootSagaReturnType } from 'sagas/types';

import watchGetFollowersUsersList from './getFollowersUsersList';
import watchGetFullFollowersUsersList from './getFullFollowersUserList';
import watchGetFollowersTablesInfo from './getFollowersTablesInfo';
import watchGetFollowersCharts from './getFollowersChartsData';
import watchExportFullFollowersListToExcel from './exportFullFollowersList';

export default (api: FollowersGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetFollowersUsersList, api);
        yield fork(watchGetFullFollowersUsersList, api);
        yield fork(watchGetFollowersTablesInfo, api);
        yield fork(watchGetFollowersCharts, api);
        yield fork(watchExportFullFollowersListToExcel, api);
    }

    return {
        rootSaga,
    };
};
