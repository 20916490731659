import { IContest, IContestDetails, IContestList, IContestResponse, ICreateQuizModalState, IDeleteQuizModalState, IEditQuizModalState } from 'entities/contests';
import { ContestsState } from '.';

const getContestsAttempting = (state: ContestsState): boolean => state.actions.getContests || false;
const getContestsFailure = (state: ContestsState): string => state.error.getContests || '';
const getContests = (state: ContestsState): IContest[] => state.contests || null;

const createContestAttempt = (state: ContestsState): boolean => state.actions.createContest || false;
const createContestSuccess = (state: ContestsState): IContest[] => state.contests || null;
const createContestFailure = (state: ContestsState): string => state.error.createContest || '';

const deleteContestAttempt = (state: ContestsState): boolean => state.actions.deleteContest || false;
const deleteContestSuccess = (state: ContestsState): IContest[] => state.contests || null;
const deleteContestFailure = (state: ContestsState): string => state.error.deleteContest || '';

const getEditContestAttempting = (state: ContestsState): boolean => state.actions.editContest || false;
const getEditContestError = (state: ContestsState): string => state.error.editContest || '';

const getContestDetailsAttempting = (state: ContestsState): boolean => state.actions.getContestDetails || false;
const getContestDetailsError = (state: ContestsState): string => state.error.getContestDetails || '';
const getContestDetails = (state: ContestsState): IContestDetails => state.contestDetails || '';

const getContestDetailsResponsesIndex = (state: ContestsState): number => state.contestResponses.index || 1;
const getContestDetailsResponsesMaxIndex = (state: ContestsState): number => state.contestResponses.maxIndex || 0;
const getContestDetailsResponsesAttempting = (state: ContestsState): boolean => state.actions.getContestResponses || false;
const getContestDetailsResponsesError = (state: ContestsState): string => state.error.getContestResponses || '';
const getContestDetailsResponses = (state: ContestsState): IContestResponse[] => state.contestResponses.data || [];

const getContestDetailsResponsesPage = (state: ContestsState): number => state.page || 0;

const getContestDetailsPaginatorIndex = (state: ContestsState): number => state.paginatorIndex || 1;

const getEditContestDetailsAttempting = (state: ContestsState): boolean => state.actions.editContest || false;
const getContestExportResponsesAttempting = (state: ContestsState): boolean => state.actions.exportResponses || false;

const getActiveGamesList = (state: ContestsState): IContestList => state.activeGamesList || {};
const getActiveGamesListPage = (state: ContestsState): number => state.activeGamesListPage || 1;
const getActiveGamesListIndex = (state: ContestsState): number => state.activeGamesListIndex || 1;
const getActiveGamesListMaxIndex = (state: ContestsState): number => state.activeGamesListMaxPage || 0;
const getActiveGamesListError = (state: ContestsState): string => state.error.getActiveGamesList || '';
const getActiveGamesListAttempting = (state:ContestsState): boolean => state.actions.getActiveGamesList || false;

const getActiveQuizList = (state: ContestsState): IContestList => state.activeQuizList || {};
const getActiveQuizListPage = (state: ContestsState): number => state.activeQuizListPage || 1;
const getActiveQuizListIndex = (state: ContestsState): number => state.activeQuizListIndex || 1;
const getActiveQuizListMaxIndex = (state: ContestsState): number => state.activeQuizListMaxPage || 0;
const getActiveQuizListError = (state: ContestsState): string => state.error.getActiveQuizList || '';
const getActiveQuizListAttempting = (state: ContestsState): boolean => state.actions.getActiveQuizList || false;

const getInactiveGamesList = (state: ContestsState): IContestList => state.inactiveGamesList || {};
const getInactiveGamesListPage = (state: ContestsState): number => state.inactiveGamesListPage || 1;
const getInactiveGamesListIndex = (state: ContestsState): number => state.inactiveGamesListIndex || 1;
const getInactiveGamesListMaxIndex = (state: ContestsState): number => state.inactiveGamesListMaxPage || 0;
const getInactiveGamesListError = (state: ContestsState): string => state.error.getInactiveGamesList || '';
const getInactiveGamesListAttempting = (state: ContestsState): boolean => state.actions.getInactiveGamesList || false;

const getInactiveQuizList = (state: ContestsState): IContestList => state.inactiveQuizList || {};
const getInactiveQuizListPage = (state: ContestsState): number => state.inactiveQuizListPage || 1;
const getInactiveQuizListIndex = (state: ContestsState): number => state.inactiveQuizListIndex || 1;
const getInactiveQuizListMaxIndex = (state: ContestsState): number => state.inactiveQuizListMaxPage || 0;
const getInactiveQuizListError = (state: ContestsState): string => state.error.getInactiveQuizList || '';
const getInactiveQuizListAttempting = (state: ContestsState): boolean => state.actions.getInactiveQuizList || false;

const getCreateContestModalState = (state: ContestsState): ICreateQuizModalState => state.createQuizModalState || {};
const getEditContestModalState = (state: ContestsState): IEditQuizModalState => state.editQuizModalState || {};
const getDeleteContestModalState = (state: ContestsState): IDeleteQuizModalState => state.deleteQuizModalState || {};

export default {
    getContestsAttempting,
    getContestsFailure,
    getContests,

    createContestAttempt,
    createContestSuccess,
    createContestFailure,

    deleteContestAttempt,
    deleteContestSuccess,
    deleteContestFailure,

    getEditContestAttempting,
    getEditContestError,

    getContestDetailsAttempting,
    getContestDetailsError,
    getContestDetails,

    getContestDetailsResponsesIndex,
    getContestDetailsResponsesMaxIndex,
    getContestDetailsResponsesAttempting,
    getContestDetailsResponsesError,
    getContestDetailsResponses,

    getContestDetailsResponsesPage,

    getEditContestDetailsAttempting,

    getContestDetailsPaginatorIndex,

    getContestExportResponsesAttempting,

    getActiveGamesList,
    getActiveGamesListPage,
    getActiveGamesListIndex,
    getActiveGamesListMaxIndex,
    getActiveGamesListError,
    getActiveGamesListAttempting,

    getActiveQuizList,
    getActiveQuizListPage,
    getActiveQuizListIndex,
    getActiveQuizListMaxIndex,
    getActiveQuizListError,
    getActiveQuizListAttempting,

    getInactiveGamesList,
    getInactiveGamesListPage,
    getInactiveGamesListIndex,
    getInactiveGamesListMaxIndex,
    getInactiveGamesListError,
    getInactiveGamesListAttempting,

    getInactiveQuizList,
    getInactiveQuizListPage,
    getInactiveQuizListIndex,
    getInactiveQuizListMaxIndex,
    getInactiveQuizListError,
    getInactiveQuizListAttempting,

    getCreateContestModalState,
    getEditContestModalState,
    getDeleteContestModalState,
};
