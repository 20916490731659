import React, { FunctionComponent, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Modal, UncontrolledTooltip, ModalBody, ModalFooter, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from 'reactstrap';
import SVG from 'react-inlinesvg';
import * as XLSX from 'xlsx';
import DatePicker from 'react-datepicker';
import FsLightbox from 'fslightbox-react';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';
import { connect } from 'react-redux';
import Actions from 'redux/Actions';

import HeaderButton from 'components/HeaderButton';
import Button from 'components/Button';
import ButtonText from 'components/ButtonText';
import Input from 'components/Input';
import TextArea from 'components/TextArea';

import Icons from 'assets/icons/Index';
import Colors from 'assets/themes/Colors';
import Fonts from 'assets/themes/Fonts';
import Documents from 'assets/documents/index';

import 'components/StyledComponent.css';

import Validators from 'lib/Validators';

import {
    BroadcastMessageTypesEnum,
    BroadcastTypesEnum,
    ICreateBroadcastPost,
    RecipientListEnum,
    BroadcastSendingFrequencyEnum,
    HistoryDetails,
} from 'entities/broadcast';

interface DuplicateBroadcastModalProps {
    createBroadcastPost(data: ICreateBroadcastPost): void;
    setBroadcastType(data: BroadcastTypesEnum): void;
    setRecipientList(data: RecipientListEnum): void;
    setDuplicateBroadcastModalIsOpen(data: boolean): void;
    getHistoryDetails(id: string): void;

    historyDetailsData: HistoryDetails;
    broadcastDuplicateId: string;
    isCreateBroadcastPostLoading: boolean;
    getDuplicateBroadcastModalIsOpen: boolean;
    getBroadcastType: BroadcastTypesEnum;
    getRecipientList: RecipientListEnum;
}

const DuplicateBroadcastModal: FunctionComponent<DuplicateBroadcastModalProps> = (props: DuplicateBroadcastModalProps) => {
    const {
        createBroadcastPost,
        setBroadcastType,
        setRecipientList,
        setDuplicateBroadcastModalIsOpen,
        getHistoryDetails,

        historyDetailsData,
        broadcastDuplicateId,
        isCreateBroadcastPostLoading,
        getDuplicateBroadcastModalIsOpen,
        getBroadcastType,
        getRecipientList,
    } = props;

    const [checkingPostTab, setCheckingPostTab] = useState<string>('');
    const [recipientListArray, setRecipientListArray] = useState<string>('');
    const [titleContent, setTitleContent] = useState<string>('');
    const [messageContent, setMessageContent] = useState<string>('');
    const [mediaUrl, setMediaUrl] = useState<string>('');
    const [viewMedia, setViewMedia] = useState<string>('');
    const [repeatOption, setRepeatOption] = useState<string>('Repeat Options');
    const [uploadedFileName, setUploadedFileName] = useState<string>('');
    const [uploadedImageFileName, setUploadedImageFileName] = useState<string>('');
    const [uploadedVideoFileName, setUploadedVideoFileName] = useState<string>('');
    const [fileId, setFileId] = useState<string>('');

    const [startDate, setStartDate] = useState<Date>(new Date());
    const [minExpireDate, setMinExpireDate] = useState<Date>();
    const [expireDate, setExpireDate] = useState<Date>();

    const [repetition, setRepetition] = useState<BroadcastSendingFrequencyEnum>(0);
    const [selectState, setSelectState] = useState<RecipientListEnum>(RecipientListEnum.Custom);

    const [receiverCount, setReceiverCount] = useState<number>(0);

    const [toggler, setToggler] = useState<boolean>(false);

    const [uploadedImageFile, setUploadedImageFile] = useState<File>();
    const [uploadedVideoFile, setUploadedVideoFile] = useState<File>();

    useEffect(() => {
        getHistoryDetails(broadcastDuplicateId);
    }, [broadcastDuplicateId]);

    useEffect(() => {
        if (historyDetailsData.broadcastId === broadcastDuplicateId) {
            setTitleContent(historyDetailsData.title);
            setMessageContent(historyDetailsData.message);
            setStartDate(new Date());
            setRecipientListArray(historyDetailsData.recipientList.toString());
            setReceiverCount(historyDetailsData.recipientList.length);
            setMinExpireDate(new Date((historyDetailsData.interval.startDate)));

            if (historyDetailsData.interval.expiryDate) { setExpireDate(new Date(historyDetailsData.interval.expiryDate)); }
            if (historyDetailsData.interval.frequency) { setRepetition(historyDetailsData.interval.frequency); }
            if (historyDetailsData.mediaUrl !== undefined) { setMediaUrl(historyDetailsData.mediaUrl); }
            if (historyDetailsData.fileId) { setFileId(historyDetailsData.fileId); }
            if (historyDetailsData.broadcastType === 1) { setCheckingPostTab('Immediate Post'); }
            if (historyDetailsData.broadcastType === 2) { setCheckingPostTab('Schedule Post'); }
            if (historyDetailsData.broadcastType === 3) { setCheckingPostTab('Recurring Post'); }

            if (historyDetailsData.interval.frequency === 1) {
                setRepeatOption('Daily');
            } else if (historyDetailsData.interval.frequency === 2) {
                setRepeatOption('Weekly');
            } else if (historyDetailsData.interval.frequency === 3) {
                setRepeatOption('Monthly');
            } else {
                setRepeatOption('Repeat Options');
            }

            if (historyDetailsData.recipientListEnum.toString() === RecipientListEnum.Custom) {
                setUploadedFileName('Attached File');
            } else if (historyDetailsData.recipientListEnum.toString() === RecipientListEnum.LAU) {
                setSelectState(RecipientListEnum.LAU);
            } else if (historyDetailsData.recipientListEnum.toString() === RecipientListEnum.LANU) {
                setSelectState(RecipientListEnum.LANU);
            } else if (historyDetailsData.recipientListEnum.toString() === RecipientListEnum.Users) {
                setSelectState(RecipientListEnum.Users);
            }
        }
    }, [historyDetailsData]);

    const duplicateToggle = () => {
        setDuplicateBroadcastModalIsOpen(!getDuplicateBroadcastModalIsOpen);
    };

    const handleChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileUploaded = event.target.files?.[0];

        if (fileUploaded !== undefined) {
            if (uploadedImageFile === undefined) {
                setUploadedImageFile(uploadedImageFile);
            }
            const { type } = fileUploaded;
            let documentExtension = '';

            switch (type) {
                case 'image/jpg': documentExtension = '.jpg'; break;
                case 'image/jpeg': documentExtension = '.jpeg'; break;
                case 'image/png': documentExtension = '.png'; break;
                case 'image/gif': documentExtension = '.gif'; break;
                default:
            }

            if (fileUploaded.size <= 5242880) {
                if ((documentExtension === ('.png'))
                || (documentExtension === ('.jpeg'))
                || (documentExtension === ('.jpg'))
                || (documentExtension === ('.gif'))) {
                    setUploadedImageFile(fileUploaded);
                    setUploadedImageFileName(fileUploaded.name);
                    setViewMedia(URL.createObjectURL(fileUploaded));

                    toast.success('File uploaded!');
                } else {
                    event.target.value = ''; // Reset the file input field
                    setUploadedImageFile(undefined);
                    setUploadedImageFileName('');
                    setViewMedia('');
                    toast.error('Unsupported file type!');
                }
            } else {
                event.target.value = '';
                setUploadedImageFile(undefined);
                setUploadedImageFileName('');
                setMediaUrl('');
                setViewMedia('');
                toast.error('Limit size for image file is 5mb');
            }
        }
    };

    const handleChangeVideo = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileUploaded = event.target.files?.[0];
        if (fileUploaded !== undefined) {
            if (uploadedVideoFile === undefined) {
                setUploadedVideoFile(uploadedVideoFile);
            }
            const { type } = fileUploaded;
            let documentExtension = '';

            switch (type) {
                case 'video/mp4': documentExtension = '.mp4'; break;
                default:
            }

            if (fileUploaded.size <= 20971520) {
                if (documentExtension === ('.mp4')) {
                    setUploadedVideoFile(fileUploaded);
                    setUploadedVideoFileName(fileUploaded.name);
                    setViewMedia(URL.createObjectURL(fileUploaded));

                    toast.success('File uploaded!');
                } else {
                    event.target.value = ''; // Reset the file input field
                    setUploadedVideoFileName('');
                    setUploadedVideoFile(undefined);
                    setViewMedia('');
                    setMediaUrl('');
                    toast.error('Unsupported file type!');
                }
            } else {
                event.target.value = '';
                setUploadedVideoFileName('');
                setUploadedVideoFile(undefined);
                setViewMedia('');
                setMediaUrl('');
                toast.error('Limit size for video file is 20mb');
            }
        }
    };
    const onConfirmHandler = () => {
        const dataToSubmit: ICreateBroadcastPost = {
            title: titleContent,
            message: messageContent,
            messageType: 1,
            recipientList: recipientListArray,
            broadcastType: getBroadcastType,
            startDate,
            frequency: repetition,
            recipientListEnum: getRecipientList,
            mediaUrl: (uploadedImageFile || uploadedVideoFile) ? '' : mediaUrl,
        };

        const titleError = Validators.ValidateBroadcast(dataToSubmit);

        if (titleError) {
            toast.error(titleError);
        } else {
            createBroadcastPost({
                title: titleContent,
                message: messageContent,
                messageType: BroadcastMessageTypesEnum.Message,
                recipientList: recipientListArray,
                broadcastType: getBroadcastType,
                file: (uploadedImageFile !== undefined) ? uploadedImageFile : uploadedVideoFile,
                startDate,
                expiryDate: expireDate,
                frequency: repetition,
                recipientListEnum: getRecipientList,
                mediaUrl,
                fileId,
            });
        }
    };
    console.log(fileId);
    const onClickExcelFileHanlder = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        setRecipientList(RecipientListEnum.Custom);
        const element = event.target as HTMLInputElement;
        element.value = '';
    };

    const handleExcelFileAttached = (event: React.FormEvent<HTMLInputElement>) => {
        const excelTarget = event.target as HTMLInputElement;
        const excelFileList = excelTarget.files;

        if (excelFileList) {
            setUploadedFileName(excelFileList[0].name);

            const reader = new FileReader();

            reader.readAsBinaryString(excelFileList[0]);

            reader.onload = (e: any) => {
                const str = e.target.result;
                const readedData = XLSX.read(str, { type: 'binary' });
                const sheetName = readedData.SheetNames[0];
                const sheets = readedData.Sheets[sheetName];
                const data = XLSX.utils.sheet_to_json(sheets);
                const dataArray = JSON.stringify(data);
                const dataArrayToSendToBackEnd = dataArray.replace(/]|"telegramId"|'|:|{|}/g, '').replace(/\[/g, '');

                const excelFileError = Validators.ValidateExcelFile(dataArrayToSendToBackEnd);

                if (excelFileError) {
                    toast.error(excelFileError);
                } else {
                    setUploadedFileName(excelFileList[0].name);
                    setReceiverCount(data.length);
                    setRecipientListArray(dataArrayToSendToBackEnd);
                }
                e.target.value = '';
            };
        }
    };

    const optionalMedia = () => {
        return (
            <StyledButtonWrapper>
                <StyledLabelWrapper htmlFor='imageFileInput' style={{ justifyContent: 'center', gap: '5%' }}>
                    {(uploadedImageFile) ? (
                        <StyledText style={{ fontWeight: '500', color: '#00D1D2', fontSize: '16px', paddingTop: '1.5px', paddingBottom: '1.5px' }}>
                            {uploadedImageFileName.substring(0, 21)}
                        </StyledText>
                    ) : (
                        <>
                            <StyledText style={{ fontWeight: '500' }}>Image</StyledText>
                            <img src={Icons.UploadImage} alt='uploadImage' style={{ width: '20px' }} />
                        </>
                    )}
                    <input
                        id='imageFileInput'
                        type='file'
                        name='file'
                        style={{ display: 'none' }}
                        accept='image/*, .jpeg, .jpg, .png'
                        onChange={handleChangeImage}
                    />
                </StyledLabelWrapper>

                <StyledLabelWrapper htmlFor='videoFileInput' style={{ justifyContent: 'center', gap: '5%' }}>
                    {(uploadedVideoFile) ? (
                        <StyledText style={{ fontWeight: '500', color: '#00D1D2', fontSize: '16px', paddingTop: '1.5px', paddingBottom: '1.5px' }}>
                            {uploadedVideoFileName}
                        </StyledText>
                    ) : (
                        <>
                            <StyledText style={{ fontWeight: '500' }}>Video</StyledText>
                            <img src={Icons.VideoCamera} alt='uploadVideo' style={{ width: '20px' }} />
                        </>
                    )}
                    <input
                        id='videoFileInput'
                        type='file'
                        name='file'
                        style={{ display: 'none' }}
                        accept='video/*, .mp4'
                        onChange={handleChangeVideo}
                    />
                </StyledLabelWrapper>
                {(mediaUrl !== '' && !uploadedImageFile && !uploadedVideoFile) && (
                    <>
                        <StyledButton onClick={() => setToggler(!toggler)}>
                            <SVG
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    fill: '#00D1D2',
                                    strokeWidth: '0.1px',
                                }}
                                src={Icons.ViewImage}
                                id='viewImageIcon'
                            />
                        </StyledButton>
                        <FsLightbox
                            toggler={toggler}
                            sources={[mediaUrl]}
                        />
                    </>
                )}
                {(uploadedImageFile || uploadedVideoFile) && (
                    <>
                        <StyledButton onClick={() => setToggler(!toggler)}>
                            <SVG
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    fill: 'red',
                                    strokeWidth: '0.1px',
                                }}
                                src={Icons.ViewImage}
                                id='viewImageIcon'
                            />
                        </StyledButton>
                        <FsLightbox
                            toggler={toggler}
                            sources={[viewMedia]}
                        />
                    </>
                )}
            </StyledButtonWrapper>
        );
    };

    const filterPassedTime = (time: Date) => {
        const currentDate = new Date();
        currentDate.setMinutes(currentDate.getMinutes() + 6);
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };

    const broadcastRepetition = () => {
        if (repetition === BroadcastSendingFrequencyEnum.Daily) {
            return (
                <DateWrapper>
                    <DateTimePicker
                        className='customized-datepicker'
                        onChange={(expiryDate: Date) => setExpireDate(expiryDate)}
                        value={(!expireDate) ? 'Expire Date' : dayjs(expireDate).format('ddd, DD-MM-YY')}
                        selected={new Date(dayjs(minExpireDate).add(1, 'day').format())}
                        minDate={new Date(dayjs(minExpireDate).add(1, 'day').format())}
                        dateFormat='MMMM d, yyyy'
                        shouldCloseOnSelect
                    />
                </DateWrapper>
            );
        }

        if (repetition === BroadcastSendingFrequencyEnum.Weekly) {
            return (
                <DateWrapper>
                    <DateTimePicker
                        className='customized-datepicker'
                        onChange={(expiryDate: Date) => setExpireDate(expiryDate)}
                        value={(!expireDate) ? 'Expire Date' : dayjs(expireDate).format('ddd, DD-MM-YY')}
                        selected={new Date(dayjs(minExpireDate).add(1, 'week').format())}
                        minDate={new Date(dayjs(minExpireDate).add(1, 'week').format())}
                        dateFormat='MMMM d, yyyy'
                        shouldCloseOnSelect
                    />
                </DateWrapper>
            );
        }

        return (
            <DateWrapper>
                <DateTimePicker
                    className='customized-datepicker'
                    onChange={(expiryDate: Date) => setExpireDate(expiryDate)}
                    value={(!expireDate) ? 'Expire Date' : dayjs(expireDate).format('ddd, DD-MM-YY')}
                    selected={new Date(dayjs(minExpireDate).add(1, 'month').format())}
                    minDate={new Date(dayjs(minExpireDate).add(1, 'month').format())}
                    dateFormat='MMMM d, yyyy'
                    shouldCloseOnSelect
                />
            </DateWrapper>
        );
    };

    const resetImage = () => {
        setUploadedImageFile(undefined);
        setUploadedImageFileName('');
        if (historyDetailsData !== undefined && historyDetailsData.mediaUrl !== undefined) {
            setMediaUrl(historyDetailsData.mediaUrl);
        } else { setMediaUrl(''); }

        setUploadedVideoFile(undefined);
        setUploadedVideoFileName('');
    };

    const buttonToToggleAllLAU = () => {
        if (selectState === RecipientListEnum.LAU) {
            setSelectState(RecipientListEnum.Custom);
        } else {
            setSelectState(RecipientListEnum.LAU);
        }
        setReceiverCount(0);
        setUploadedFileName('');

        setRecipientList(RecipientListEnum.LAU);
    };
    const buttonToToggleAllLANU = () => {
        if (selectState === RecipientListEnum.LANU) {
            setSelectState(RecipientListEnum.Custom);
        } else {
            setSelectState(RecipientListEnum.LANU);
        }
        setReceiverCount(0);
        setUploadedFileName('');

        setRecipientList(RecipientListEnum.LANU);
    };
    const buttonToToggleAllUsers = () => {
        if (selectState === RecipientListEnum.Users) {
            setSelectState(RecipientListEnum.Custom);
        } else {
            setSelectState(RecipientListEnum.Users);
        }
        setReceiverCount(0);
        setUploadedFileName('');

        setRecipientList(RecipientListEnum.Users);
    };

    const onDatepickerChangeHanlder = (date: Date) => {
        if (dayjs(date).unix() > dayjs().unix()) {
            setStartDate(date);
            setMinExpireDate(date);
        } else {
            toast.error('Selected date cannot be earlier than the current time.');
        }
    };

    const renderBody = () => {
        return (
            <MainWrapper>
                <StyledHeader>
                    {`Create ${checkingPostTab}`}
                </StyledHeader>

                <StyledGridWrapper>
                    <StyledInsideFlexWrapper>
                        <StyledContainerWrapper style={{ minHeight: '180px', marginBottom: '0.7%' }}>
                            <StyledText style={{ fontSize: '18px', color: '#A2A2A2' }}>
                                Receivers
                            </StyledText>
                            {selectState === RecipientListEnum.Custom && (
                                <StyledText style={{ fontSize: '16px', color: '#A2A2A2', paddingLeft: '1%', display: 'inline-flex', alignItems: 'center', width: '77%' }}>
                                    {'(Total receivers selected: '}
                                    <StyledText style={{ fontSize: '16px', color: 'black', textAlign: 'center', paddingLeft: '5px' }}>{`${receiverCount}`}</StyledText>
                                    <StyledText style={{ paddingRight: '2%' }}>{' )'}</StyledText>
                                </StyledText>
                            )}

                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '80%', marginTop: '2%', marginBottom: '2%', gap: '5px' }}>
                                <ButtonText label='All LAU' onClick={() => buttonToToggleAllLAU()} isSelected={selectState === RecipientListEnum.LAU} />
                            </div>
                            {(selectState !== RecipientListEnum.LANU && selectState !== RecipientListEnum.LAU && selectState !== RecipientListEnum.Users) && (
                                <div style={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
                                    <div style={{ width: '61%', marginRight: '2%' }}>
                                        <Input
                                            placeholder=''
                                            value={uploadedFileName || ''}
                                            css={styles.inputReceiver}
                                            readonly
                                        />
                                    </div>
                                    <StyledButtonWrapper style={{ width: '32%' }}>
                                        <StyledLabelWrapper htmlFor='excelFileInput' style={{ width: '100%' }}>
                                            <StyledText>Upload excel</StyledText>
                                            <img src={Icons.Upload} alt='Upload' style={{ width: '15px', marginLeft: '1%' }} />
                                            <input id='excelFileInput' onChange={handleExcelFileAttached} type='file' style={{ display: 'none' }} accept='.xls, .xlsx, .csv' onClick={(event) => onClickExcelFileHanlder(event)} />
                                        </StyledLabelWrapper>
                                    </StyledButtonWrapper>
                                </div>
                            )}
                            <div style={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
                                <div style={{ width: '61%', marginRight: '2%', display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                                        <StyledText style={{ fontSize: '18px', color: '#A2A2A2' }}>Excel Segmentation ID</StyledText>
                                        <img src={Icons.QuestionMark} alt='Tooltip' id='excelIdTooltip' style={{ width: '14px', border: '1px solid #00D1D2', borderRadius: '60px', padding: '2px', backgroundColor: '#00D1D2' }} />
                                        <UncontrolledTooltip placement='right' target='excelIdTooltip' style={{ backgroundColor: '#00D1D2', color: 'black', fontSize: '10px', textAlign: 'justify', padding: '10px' }}>
                                            Name for internal reference when it&#39;s showcased in the Broadcast post list
                                        </UncontrolledTooltip>
                                    </div>
                                    <Input
                                        placeholder=''
                                        value=''
                                        css={styles.inputReceiver}
                                        readonly
                                    />
                                </div>
                                <StyledButtonWrapper style={{ width: '32%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <StyledText style={{ fontSize: '18px', color: '#A2A2A2' }}>Excel Template</StyledText>
                                    <StyledDownloadWrapper style={{ width: '100%' }}>
                                        <StyledA href={Documents.ExcelDocument} download='Excel Template.xlsx'>Download</StyledA>
                                        <img src={Icons.Download} alt='Upload' style={{ width: '15px', marginLeft: '1%' }} />
                                    </StyledDownloadWrapper>
                                </StyledButtonWrapper>
                            </div>

                        </StyledContainerWrapper>

                        <StyledContainerWrapper>
                            <StyledText style={{ fontSize: '18px', color: '#A2A2A2' }}>
                                Title
                            </StyledText>
                            <TextArea
                                placeholder=''
                                value={titleContent}
                                onChange={(e) => setTitleContent(e.target.value)}
                                css={styles.inputTitle}
                            />
                            <StyledText style={{ fontSize: '18px', color: '#A2A2A2', display: 'inline-flex', alignItems: 'center', width: '100%' }}>
                                <StyledText style={{ paddingRight: '2%' }}>
                                    Message
                                </StyledText>
                                <img src={Icons.QuestionMark} alt='Tooltip' id='messageTooltip' style={{ width: '14px', border: '1px solid #00D1D2', borderRadius: '60px', padding: '2px', backgroundColor: '#00D1D2' }} />
                                <UncontrolledTooltip placement='right' target='messageTooltip' style={{ backgroundColor: '#00D1D2', color: 'black', fontSize: '10px', textAlign: 'justify', padding: '10px' }}>
                                    {'Type \'{{firstName}}\' to populate user\'s first names in the message box automatically'}
                                </UncontrolledTooltip>
                            </StyledText>
                            <div>
                                <TextArea
                                    placeholder=''
                                    value={messageContent}
                                    onChange={(e) => setMessageContent(e.target.value)}
                                    css={styles.inputMessageBox}
                                />
                            </div>
                        </StyledContainerWrapper>
                    </StyledInsideFlexWrapper>

                    <StyledInsideFlexWrapper>
                        <StyledContainerWrapper style={{ minHeight: '180px' }}>
                            <StyledText style={{ color: '#A2A2A2', display: 'inline-flex', justifyContent: 'flex-start', width: '100%', gap: '2%', alignItems: 'center' }}>
                                Upload Media (Optional)
                                <img src={Icons.QuestionMark} alt='Tooltip' id='uploadMediaTooltip' style={{ width: '14px', border: '1px solid #00D1D2', borderRadius: '60px', padding: '2px', backgroundColor: '#00D1D2' }} />
                                <UncontrolledTooltip placement='right' target='uploadMediaTooltip' style={{ backgroundColor: '#00D1D2', color: 'black', fontSize: '10px', textAlign: 'justify', padding: '10px' }}>
                                    You can only upload one file (either image or video). Simply upload any of the two options to replace the current uploaded file.
                                </UncontrolledTooltip>

                                {(uploadedImageFile || uploadedVideoFile || mediaUrl !== '') && (
                                    <StyledButton onClick={() => resetImage()} style={{ fontSize: '16px' }}>Reset</StyledButton>
                                )}
                            </StyledText>

                            {optionalMedia()}

                            {(getBroadcastType === BroadcastTypesEnum.Immediate) ? (<> </>) : (
                                <>
                                    {(getBroadcastType === BroadcastTypesEnum.Recurring) ? (
                                        <StyledText style={{ color: '#A2A2A2' }}>
                                            Set Frequency of Post
                                        </StyledText>
                                    ) : (
                                        <StyledText style={{ color: '#A2A2A2' }}>
                                            Schedule Posting Time
                                        </StyledText>
                                    )}
                                    <StyledButtonWrapper>
                                        <div style={{ marginRight: '-1%', borderRadius: '5px', height: '75%', width: '85%' }}>
                                            <DateTimePicker
                                                className='customized-datepicker'
                                                onChange={(newDate: Date) => onDatepickerChangeHanlder(newDate)}
                                                value={(!startDate) ? 'Select Date' : dayjs(startDate).format('dddd,   DD-MM-YY   h:mm A')}
                                                selected={startDate}
                                                minDate={new Date()}
                                                filterTime={filterPassedTime}
                                                showTimeSelect
                                                dateFormat='MMMM d, yyyy HH:mm'
                                                shouldCloseOnSelect
                                            />
                                        </div>
                                    </StyledButtonWrapper>
                                </>
                            )}
                        </StyledContainerWrapper>

                        {(getBroadcastType === BroadcastTypesEnum.Recurring) ? (
                            <StyledContainerWrapper style={{ marginTop: '1%', marginBottom: '1%', minHeight: '70px' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                                    <StyledText style={{ color: '#A2A2A2' }}>
                                        Repeat Options & Expire Date
                                    </StyledText>
                                    <img src={Icons.QuestionMark} alt='Tooltip' id='expireDateTooltip' style={{ width: '14px', border: '1px solid #00D1D2', borderRadius: '60px', padding: '2px', backgroundColor: '#00D1D2' }} />
                                    <UncontrolledTooltip placement='right' target='expireDateTooltip' style={{ backgroundColor: '#00D1D2', color: 'black', fontSize: '10px', textAlign: 'justify', padding: '10px' }}>
                                        This date will be the date of the last broadcast
                                    </UncontrolledTooltip>
                                </div>
                                <StyledButtonWrapper style={{ gap: '10px', alignItems: 'flex-start' }}>
                                    <UncontrolledDropdown>
                                        <DropdownToggle style={{
                                            border: '1px solid #A2A2A2',

                                            paddingTop: '5px',
                                            paddingBottom: '5px',

                                            minWidth: '194px',
                                            height: '75%',
                                            marginRight: '1%',
                                            marginTop: '1%',
                                            marginBottom: '6%',
                                            backgroundColor: 'white',
                                            color: 'black',
                                        }}
                                        >
                                            <StyledText style={{ fontWeight: '500' }}>{repeatOption}</StyledText>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={() => [setRepetition(BroadcastSendingFrequencyEnum.Daily), setRepeatOption('Daily'), setExpireDate(undefined)]}>
                                                Daily
                                            </DropdownItem>
                                            <DropdownItem onClick={() => [setRepetition(BroadcastSendingFrequencyEnum.Weekly), setRepeatOption('Weekly'), setExpireDate(undefined)]}>
                                                Weekly
                                            </DropdownItem>
                                            <DropdownItem onClick={() => [setRepetition(BroadcastSendingFrequencyEnum.Monthly), setRepeatOption('Monthly'), setExpireDate(undefined)]}>
                                                Monthly
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    {repeatOption !== 'Repeat Option' && (
                                        broadcastRepetition()
                                    )}
                                </StyledButtonWrapper>
                            </StyledContainerWrapper>
                        ) : (
                            <div>
                                <StyledContainerWrapper style={{ marginTop: '6%', minHeight: '70px' }} />
                            </div>
                        )}

                        <StyledContainerWrapper>
                            <StyledText style={{ color: '#A2A2A2' }}>
                                Posting Details
                            </StyledText>
                            <div
                                style={{
                                    border: '1px solid white',
                                    borderRadius: '5px',
                                    backgroundColor: '#E3F9F9',
                                    outlineColor: '#96bfff',
                                    width: '100%',
                                    height: '249px',
                                    padding: '30px 30px 20px 30px',
                                    marginTop: 5,
                                }}
                            >
                                <StyledTextBody>
                                    {(getBroadcastType === 1) ? (
                                        <>
                                            <StyledText style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                                {dayjs(new Date()).format('dddd,')}
                                            </StyledText>

                                            <StyledText style={{ fontSize: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                {dayjs(new Date()).format('DD-MM-YY')}

                                                <StyledText style={{ fontSize: '20px', paddingLeft: '15%', display: 'inline-flex', alignItems: 'center' }}>
                                                    <SVG src={Icons.Clock} id='clockIcon' style={{ width: '30px', paddingRight: '10%', fill: '#009899' }} />
                                                    {dayjs(new Date()).format('h:mm A')}
                                                </StyledText>
                                            </StyledText>
                                        </>
                                    ) : (
                                        <>
                                            <StyledText style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                                {dayjs(startDate).format('dddd,')}
                                            </StyledText>

                                            <StyledText style={{ fontSize: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                {dayjs(startDate).format('DD-MM-YY')}

                                                <StyledText style={{ fontSize: '20px', paddingLeft: '15%', display: 'inline-flex', alignItems: 'center' }}>
                                                    <SVG src={Icons.Clock} id='clockIcon' style={{ width: '30px', paddingRight: '10%', fill: '#009899' }} />
                                                    {dayjs(startDate).format('h:mm A')}
                                                </StyledText>
                                            </StyledText>
                                        </>
                                    )}

                                </StyledTextBody>
                            </div>
                        </StyledContainerWrapper>
                    </StyledInsideFlexWrapper>
                </StyledGridWrapper>
            </MainWrapper>
        );
    };

    return (
        <Modal isOpen={getDuplicateBroadcastModalIsOpen} toggle={duplicateToggle} centered size='xl' fullscreen='sm'>
            <StyledHeaderTab>
                <HeaderButton
                    text='Immediate Post'
                    onClick={() => [setCheckingPostTab('Immediate Post'), setBroadcastType(1)]}
                    isSelected={getBroadcastType === BroadcastTypesEnum.Immediate}
                />
                <HeaderButton
                    text='Recurring Post'
                    onClick={() => [setCheckingPostTab('Recurring Post'), setBroadcastType(3)]}
                    isSelected={getBroadcastType === BroadcastTypesEnum.Recurring}
                />
                <HeaderButton
                    text='Schedule Post'
                    onClick={() => [setCheckingPostTab('Schedule Post'), setBroadcastType(2)]}
                    isSelected={getBroadcastType === BroadcastTypesEnum.Scheduled}
                />
            </StyledHeaderTab>

            <ModalBody>
                {renderBody()}
            </ModalBody>

            <ModalFooter style={{ justifyContent: 'flex-start', border: 'none', paddingLeft: '6%' }}>
                {(getBroadcastType === BroadcastTypesEnum.Immediate) ? (
                    <Button
                        label='Post'
                        onClick={() => onConfirmHandler()}
                        css={styles.confirmButton}
                        loading={isCreateBroadcastPostLoading}
                        disabled={isCreateBroadcastPostLoading}
                    />
                ) : (
                    <Button
                        label='Schedule'
                        onClick={() => onConfirmHandler()}
                        css={styles.confirmButton}
                        loading={isCreateBroadcastPostLoading}
                        disabled={isCreateBroadcastPostLoading}
                    />
                )}
                <Button
                    label='Cancel'
                    onClick={() => setDuplicateBroadcastModalIsOpen(false)}
                    css={styles.cancelButton}
                />
            </ModalFooter>
        </Modal>
    );
};

const DateWrapper = styled.div`
    margin-top: 2px;
    border-radius: 5px;
    height: 75%;
    width: 42%;
`;

const DateTimePicker = styled(DatePicker)`
    text-align: center;
    z-index: 9999999999;
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid ${Colors.primary};
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:focus, &:active{
        border-color: rgba(61, 39, 78, 1);
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(61, 39, 78, 0.6);
        outline: 0 none;
    }

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${Colors.primary};
        opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: ${Colors.primary};
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
        color: ${Colors.primary};
    }
`;

const MainWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StyledHeader = styled.div`
    width: 90%;
    height: 55px;
    font-size: 25px;
    border-bottom: 2px solid #A2A2A2;
`;

const StyledGridWrapper = styled.div`
    width: 90%;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    column-gap: 3%;

    margin-top: 2%;
`;

const StyledHeaderTab = styled.div`
    border: none;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-around;
    width: 100%;
    height: 60px;
    background-color: lightgrey;
    align-items: center;
    border-radius: 20px 20px 0px 0px;
`;

const StyledInsideFlexWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-item: flex-start;
`;

const StyledContainerWrapper = styled.div`
    min-width: 100%;
    max-width: 100%;
    min-height: 131.6px;
    max-height: 100%;
`;

const StyledTextBody = styled.div`
    width: 100%;
    height: 100%;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const StyledButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2%;
`;

const StyledDownloadWrapper = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    background: transparent;

    border: 1px solid #A2A2A2;
    border-radius: 5px;

    padding-top: 5px;
    padding-bottom: 5px;

    width: 40%;
    height: 75%;
    margin-right: 1%;
    margin-top: 1%;
    margin-bottom: 1%;
`;

const StyledA = styled.a`
    text-decoration: none;
    color: black;
`;

const StyledLabelWrapper = styled.label`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    border: 1px solid #A2A2A2;
    border-radius: 5px;

    padding-top: 5px;
    padding-bottom: 5px;

    width: 40%;
    height: 75%;
    margin-right: 1%;
    margin-top: 1%;
    margin-bottom: 1%;
`;

const StyledButton = styled.button`
    display: flex;
    align-items: center;
    border: none;
    background: transparent;
    height: 75%;
    width: 10%;
    transition: all 0.1s ease;
    font-size: 11px;

    &:hover {
        color: red;
    }

    &:active {
        color: green;
    }
`;

const StyledText = styled.text`
    font-size: 18px;
`;

const styles = {
    stateButton: css`
        background-color: white;
        font-size: 20px;
        font-family: ${Fonts.primary};
        border-radius: 20px;
        color: black;
        width: 125px;

        &:active {
            background-color: blue;
            color: white;
        }
    `,

    cancelButton: css`
        background-color: ${Colors.primary};
        color: white;
        font-size: 18px;
        border-radius: 50px;
        width: 100px;
        height: 40px;

        &:hover {
            background-color:  #36CAB5;
            color: white;
            border: 1px solid #36CAB5;
        }
    `,

    confirmButton: css`
        color: white;
        border: 1px solid #36CAB5;
        font-size: 18px;
        border-radius: 50px;
        width: 100px;
        height: 40px;

        &:hover {
            background-color: #36CAB5;
            color: white;
            border: 1px solid #36CAB5;
        }

        &:disabled {
            background-color: #BBBDBD;
            color: white;
        }

        &:active {
            background-color: #36CAB5;
            color: white;
        }
    `,

    datePicker: css`
        border: 1px solid #A2A2A2;

        padding: 5px 20px;

        width: 40%;
        height: 75%;
        margin-right: 1%;
        margin-top: 1%;
        margin-bottom: 1%;
    `,

    inputReceiver: css`
        background-color: #F4F9F9;
        height: 47.5px;
        margin-bottom: 4.22%;
        resize: none;
        overflow: auto;
        cursor: not-allowed;
        text-align: center;
        color: #00D1D2;
    `,

    inputTitle: css`
        background-color: #F4F9F9;
        height: 47.5px;
        margin-bottom: 4.22%;
        resize: none;
        overflow: auto;
    `,

    inputMessageBox: css`
        background-color: #F4F9F9;
        height: 249px;
        resize: none;
    `,

    inputPostingDetails: css`
        height: 141px;
        background-color: #E3F9F9;
        color: black;
    `,
};

const mapStateToProps = (state: RootState) => ({
    recurringPostData: Selectors.getBroadcastRecurringPost(state),
    schedulePostData: Selectors.getBroadcastSchedulePost(state),
    historyPostData: Selectors.getBroadcastHistoryPost(state),
    historyDetailsData: Selectors.getBroadcastHistoryDetails(state),

    isCreateBroadcastPostLoading: Selectors.createBroadcastBroadcastPostAttempting(state),

    getDuplicateBroadcastModalIsOpen: Selectors.getBroadcastDuplicateBroadcastModalIsOpen(state),

    getBroadcastType: Selectors.getBroadcastGetBroadcastType(state),
    getRecipientList: Selectors.getBroadcastGetRecipientList(state),

    recurringIndex: Selectors.getBroadcastRecurringPaginatorIndex(state),
    scheduleIndex: Selectors.getBroadcastSchedulePaginatorIndex(state),
    historyIndex: Selectors.getBroadcastHistoryPaginatorIndex(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    createBroadcastPost: (data: ICreateBroadcastPost) => dispatch(Actions.createBroadcastPostAttempt(data)),

    setDuplicateBroadcastModalIsOpen: (data: boolean) => dispatch(Actions.setDuplicateBroadcastModalIsOpen(data)),
    setBroadcastType: (data: BroadcastTypesEnum) => dispatch(Actions.setBroadcastType(data)),
    setRecipientList: (data: RecipientListEnum) => dispatch(Actions.setRecipientList(data)),

    getHistoryDetails: (id: string) => dispatch(Actions.getHistoryDetailsAttempt({ id })),
});

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateBroadcastModal);
