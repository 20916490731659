import React from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import dayjs from 'dayjs';
import { Spinner, Table } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import NavActions from 'lib/NavActions';

import Flex from 'components/Flex';
import FollowersHeader from 'components/analytics/FollowersHeader';
import IQOSPaginator from 'components/IQOSPaginator';

import Icons from 'assets/icons/Index';

import { ButtonStyles, TableStyles } from 'components/styles';
import { IExportFullFollowersListToExcelReduxParam, IFollowersUsersList } from 'entities/followers';

interface ListProps {
    childDateFrom: Date | null;
    childDateTo: Date | null;
    exportFollowersUsersListIsLoading: boolean;

    getFollowerUsersListIsLoading: boolean;
    getFollowerUsersListError: string;
    followersUsersList: IFollowersUsersList[];

    followersUsersListCurrentIndex: number;
    followersUsersListMaxPage: number;
    setFollowersUsersListIndex: (param:number) => void;
    exportFollowersUsersList: (param: IExportFullFollowersListToExcelReduxParam) => void;

}

const List:React.FunctionComponent<ListProps> = ({
    childDateFrom,
    childDateTo,
    exportFollowersUsersListIsLoading,
    getFollowerUsersListIsLoading,
    getFollowerUsersListError,
    followersUsersList,
    followersUsersListCurrentIndex,
    followersUsersListMaxPage,
    setFollowersUsersListIndex,
    exportFollowersUsersList,
}) => {
    const list = followersUsersList.find((item) => item.index === followersUsersListCurrentIndex);
    const currentLocation = useLocation();
    const headers = ['Username', 'Phone Number', 'Date Joined'];

    const handleExportData = () => {
        if (childDateFrom && childDateTo) exportFollowersUsersList({ dateFrom: childDateFrom, dateTo: childDateTo });
    };

    const renderTableHeaders = () => {
        return (
            <thead>
                <tr>
                    {headers.map((item) => {
                        return (
                            <th key={item}>{item}</th>
                        );
                    })}
                </tr>
            </thead>
        );
    };

    const renderTableData = () => {
        if (getFollowerUsersListIsLoading) {
            return (
                <tr>
                    <StyledTdTemp colSpan={4}>
                        <Skeleton count={5} height={30} />
                    </StyledTdTemp>
                </tr>
            );
        }

        if (getFollowerUsersListError) return <tr><StyledTdTemp colSpan={headers.length}>{getFollowerUsersListError || 'There appears to be no data yet.'}</StyledTdTemp></tr>;

        if (list !== undefined) {
            if (list.data.length === 0) {
                return <tr><StyledTdTemp colSpan={headers.length}>There appears to be no data yet.</StyledTdTemp></tr>;
            }
        }

        return followersUsersList && list && list.data.map((newItem) => {
            const { firstName, lastName, username, phoneNumber, dateJoined } = newItem;

            return (
                <tr key={`${phoneNumber}${dateJoined}`}>
                    <td>{firstName ? `${firstName} ${lastName || ''}` : (username || '')}</td>
                    <td>{phoneNumber || '-'}</td>
                    <td>{dateJoined ? dayjs(dateJoined).format('DD-MM-YY') : '-'}</td>
                </tr>
            );
        });
    };

    return (
        <div>
            <Flex justifyContent='space-between' alignItems='center'>
                <FollowersHeader
                    headerContent='Followers List'
                    headerSize='md'
                    tooltipId='followers-list'
                    tooltipIconSrc={Icons.QuestionMark}
                    tooltipMaxWidth='400px'
                    tooltipContent='The followers data will be from the selected date range.'
                />
                <Flex gap='1rem'>
                    {list && list.data.length !== 0 && (
                        <StyledButton type='button' css={ButtonStyles.secondary} onClick={handleExportData}>
                            {!exportFollowersUsersListIsLoading ? 'Export Data' : <Spinner size='sm' />}
                        </StyledButton>
                    )}
                    <StyledButton type='button' css={ButtonStyles.primary} onClick={() => NavActions.navToFullFollowersScreen()}>
                        View Full List
                    </StyledButton>
                </Flex>
            </Flex>
            <StyledTableWrapper>
                <StyledTable css={TableStyles.primary} id='IQOSFollowersUsersList'>
                    {renderTableHeaders()}
                    <tbody>
                        {renderTableData()}
                    </tbody>
                </StyledTable>
                <Flex width='100%' justifyContent='center'>
                    {followersUsersList && (followersUsersList?.length > 0) && (
                        <IQOSPaginator
                            maxIndex={followersUsersListMaxPage}
                            setIndex={setFollowersUsersListIndex}
                            currentIndex={followersUsersListCurrentIndex}
                            url={currentLocation.pathname}
                            containerId='IQOSFollowersUsersList'
                        />
                    )}
                </Flex>
            </StyledTableWrapper>
        </div>
    );
};

interface StyledButtonProps {
    css?: FlattenSimpleInterpolation;
}

type StyledTableProps = StyledButtonProps;

const StyledButton = styled.button<StyledButtonProps>`
    cursor:pointer;
    ${props => props.css}
`;

const StyledTable = styled(Table)<StyledTableProps>`
    ${props => props.css}
`;

const StyledTableWrapper = styled.div`
    margin: 1rem 0;
    border-radius:8px;
`;

const StyledTdTemp = styled.td`
    min-height:200px;
    text-align:center;
`;

const mapStateToProps = (state: RootState) => ({
    exportFollowersUsersListIsLoading: Selectors.followersExportFullFollowersListAttempting(state),
    getFollowerUsersListIsLoading: Selectors.followersGetFollowersUsersListAttempting(state),
    getFollowerUsersListError: Selectors.followersGetFollowersUsersListError(state),
    followersUsersList: Selectors.followersGetFollowersUsersList(state),
    followersUsersListCurrentIndex: Selectors.followersGetFollowersUsersListCurrentIndex(state),
    followersUsersListMaxPage: Selectors.followersGetFollowersUsersListMaxPage(state),

});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setFollowersUsersListIndex: (param:number) => dispatch(Actions.followersSetFollowersUsersListIndex(param)),
    exportFollowersUsersList: (param: IExportFullFollowersListToExcelReduxParam) => dispatch(Actions.followersExportFullFollowersListToExcelAttempt(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(List);
