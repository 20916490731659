import React, { useEffect, FunctionComponent, useState } from 'react';
// ? Redux Toolkit
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import dayjs from 'dayjs';
import SVG from 'react-inlinesvg';
import { useParams } from 'react-router';
import styled, { css } from 'styled-components';

import Icons from 'assets/icons/Index';
import Colors from 'assets/themes/Colors';

import Text from 'components/Text';
import Button from 'components/Button';
import ToggleSwitch from 'components/ToggleSwitch';

import {
    IEditProductCategory,
    IEditProductDetails,
    IReorderSequence,
    ProductCategoryDetails,
    ProductListDetails,
    StatusTypeEnum,
    TableTypeEnum,
    VariantsListDetails,
} from 'entities/ecommerce';

import NavActions from 'lib/NavActions';

// ! interface zone
// * props interface
export interface ProductCategoriesTableProps {
    tableType: TableTypeEnum;
    tableData: ProductCategoryDetails[] | ProductListDetails[];

    productCategory: ProductCategoryDetails[];
    editProductCategory: (data: IEditProductCategory) => void;
    deleteProductCategory: (id: string) => void;

    productList: ProductListDetails[];
    getProductList: (categoryId: string) => void;
    editProductDetails: (data: IEditProductDetails) => void;
    deleteProductDetails: (id: string) => void

    editProductDetailsAttempt: boolean;
}

// ! JSX function component
const ListTable:FunctionComponent<ProductCategoriesTableProps> = (props: ProductCategoriesTableProps) => {
    // ! destructuring zone
    // * props destructured
    const {
        tableType,
        tableData,
        editProductCategory,
        deleteProductCategory,
        productList,
        getProductList,
        editProductDetails,
        deleteProductDetails,
        editProductDetailsAttempt,
    } = props;

    const { id } = useParams();

    // ! useState Zone
    const [idFromParams, setIdFromParams] = useState<string>('');

    const productCategoriesHeader = ['Product Category Name', 'Date created', 'Status', 'Action'];
    const productListHeader = ['Product Listing', '', 'Date Created', 'Number of variants', 'Status', 'Action'];

    // ! useEffect zone
    useEffect(() => {
        if (id) {
            setIdFromParams(id);
            getProductList(id);
        }
    }, []);

    useEffect(() => {
        if (editProductDetailsAttempt) {
            getProductList(idFromParams);
        }
    }, [editProductDetailsAttempt]);

    // ! functions
    // ? function to handle status toggle
    const handleCategoryStatusChange = (categoryId: string, categoryName: string, changedStatus: StatusTypeEnum) => {
        editProductCategory({
            id: categoryId,
            categoryName,
            status: changedStatus,
        });
    };

    const handleCategoryReorder = (categoryId: string, categoryName: string, status: StatusTypeEnum, reorderBy: IReorderSequence) => {
        editProductCategory({
            id: categoryId,
            categoryName,
            status,
            reorderBy,
        });
    };
    const handleProductStatusChange = (productId: string, changedStatus: StatusTypeEnum) => {
        const selectedProduct = productList.find((product) => product._id === productId);

        if (selectedProduct) {
            const {
                productName,
                productTitleEN,
                productDescEN,
                productTitleBM,
                productDescBM,
                productPrice,
                promotionalPrice,
                productLinkEN,
                productLinkBM,
            } = selectedProduct;

            editProductDetails({
                id: productId,
                productName,
                productTitleEN,
                productDescEN,
                productTitleBM,
                productDescBM,
                productPrice,
                promotionalPrice,
                productLinkEN,
                productLinkBM,
                status: changedStatus,
            });
        }
    };

    const handleProductReorder = (productId: string, status: StatusTypeEnum, reorderBy: IReorderSequence) => {
        const selectedProduct = productList.find((product) => product._id === productId);

        if (selectedProduct) {
            const {
                productName,
                productTitleEN,
                productDescEN,
                productTitleBM,
                productDescBM,
                productPrice,
                promotionalPrice,
                productLinkEN,
                productLinkBM,
            } = selectedProduct;

            editProductDetails({
                id: productId,
                productName,
                productTitleEN,
                productDescEN,
                productTitleBM,
                productDescBM,
                productPrice,
                promotionalPrice,
                productLinkEN,
                productLinkBM,
                status,
                reorderBy,
            });
        }
    };
    // ! render zone
    // ? Table  Header
    const renderTableHeader = () => {
        if (tableType === TableTypeEnum.Categories) {
            return (
                <tr>
                    {productCategoriesHeader.map((item) => (
                        <StyledTh key={item}>
                            {item}
                        </StyledTh>
                    ))}
                </tr>
            );
        }
        return (
            <tr>
                {productListHeader.map((item) => (
                    <StyledTh key={item}>
                        {item}
                    </StyledTh>
                ))}
            </tr>
        );
    };
    // ? Table Body
    const renderTableBody = () => {
        if (!tableData.length && tableType === TableTypeEnum.Categories) {
            return (
                <tr>
                    <td colSpan={4} style={{ padding: '20px', fontSize: '18px' }}>
                        <div>
                            <Text>There appears to be no data yet. </Text>
                        </div>
                    </td>
                </tr>
            );
        }
        if (!tableData.length && tableType === TableTypeEnum.Products) {
            return (
                <tr>
                    <td colSpan={6} style={{ padding: '20px', fontSize: '18px' }}>
                        <div>
                            <Text>There appears to be no data yet. </Text>
                        </div>
                    </td>
                </tr>
            );
        }

        if (tableType === TableTypeEnum.Categories) {
            return (
                tableData.map((item, index) => {
                    const { _id: categoryId, categoryName, createdAt, status } = item as ProductCategoryDetails;

                    let changedStatus:StatusTypeEnum;
                    if (status === StatusTypeEnum.Active) {
                        changedStatus = StatusTypeEnum.Inactive;
                    } else if (status === StatusTypeEnum.Inactive) {
                        changedStatus = StatusTypeEnum.Active;
                    }
                    return (
                        <StyledTr key={categoryId}>
                            <StyledTd categoryTable>
                                <StyledLink onClick={() => NavActions.navToProductList(categoryId)}>
                                    {categoryName}
                                </StyledLink>
                            </StyledTd>

                            <StyledTd>
                                {dayjs(createdAt).format('DD-MM-YYYY')}
                            </StyledTd>

                            <StyledTd>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <ToggleSwitch
                                        isEnabled={status === StatusTypeEnum.Active}
                                        onClick={() => handleCategoryStatusChange(categoryId, categoryName, changedStatus)}
                                    />
                                </div>
                            </StyledTd>

                            <StyledTd>
                                <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
                                    <StyledActionButton
                                        onClick={() => NavActions.navToProductList(categoryId)}
                                        css={styles.updatedPencilIcon}
                                    >
                                        <SVG src={Icons.Edit} id='edit' />
                                    </StyledActionButton>
                                    <StyledActionButton
                                        onClick={() => deleteProductCategory(categoryId)}
                                        css={styles.trashIcon}
                                    >
                                        <SVG src={Icons.TrashV2} id='trash' />
                                    </StyledActionButton>
                                    {
                                        index === 0 ? (
                                            <StyledActionButton
                                                onClick={() => null}
                                                css={styles.upIcon}
                                            >
                                                <SVG src={Icons.ArrowUpV2} id='up' />
                                            </StyledActionButton>
                                        ) : (
                                            <StyledActionButton
                                                onClick={() => handleCategoryReorder(categoryId, categoryName, status, IReorderSequence.MoveUp)}
                                                css={styles.upIcon}
                                            >
                                                <SVG src={Icons.ArrowUpV2} id='up' />
                                            </StyledActionButton>
                                        )
                                    }
                                    {
                                        index === (tableData.length - 1) ? (
                                            <StyledActionButton
                                                onClick={() => null}
                                                css={styles.downIcon}
                                            >
                                                <SVG src={Icons.ArrowDownV2} id='down' />
                                            </StyledActionButton>
                                        ) : (
                                            <StyledActionButton
                                                onClick={() => handleCategoryReorder(categoryId, categoryName, status, IReorderSequence.MoveDown)}
                                                css={styles.downIcon}
                                            >
                                                <SVG src={Icons.ArrowDownV2} id='down' />
                                            </StyledActionButton>
                                        )
                                    }
                                </div>
                            </StyledTd>
                        </StyledTr>
                    );
                })
            );
        }
        return (
            tableData.map((item, index) => {
                const { _id: productId, productName, productVariant, createdAt, status } = item as ProductListDetails;

                const defaultVariant = productVariant.find((variant) => variant.defaultVariant);

                let variantImage = '';
                if (defaultVariant) {
                    variantImage = (defaultVariant as VariantsListDetails).variantImage;
                }

                let changedStatus:StatusTypeEnum;
                if (status === StatusTypeEnum.Active) {
                    changedStatus = StatusTypeEnum.Inactive;
                } else if (status === StatusTypeEnum.Inactive) {
                    changedStatus = StatusTypeEnum.Active;
                }

                return (
                    <StyledTr key={productId}>
                        <StyledTd productTable>
                            <StyledLink onClick={() => NavActions.navToProductDetails(idFromParams, productId)}>
                                {productName}
                            </StyledLink>
                        </StyledTd>
                        <StyledTd>
                            <ImageContainer>
                                {
                                    variantImage === ''
                                        ? (
                                            <ImagePlaceholder />
                                        ) : (
                                            <StyledImg src={variantImage} alt='uploadedImage' />
                                        )
                                }
                            </ImageContainer>
                        </StyledTd>
                        <StyledTd>
                            {dayjs(createdAt).format('DD-MM-YYYY')}
                        </StyledTd>
                        <StyledTd>
                            {productVariant.length}
                        </StyledTd>
                        <StyledTd>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <ToggleSwitch
                                    isEnabled={status === StatusTypeEnum.Active}
                                    onClick={() => handleProductStatusChange(productId, changedStatus)}
                                />
                            </div>
                        </StyledTd>
                        <StyledTd>
                            <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
                                <StyledActionButton
                                    onClick={() => NavActions.navToProductDetails(idFromParams, productId)}
                                    css={styles.updatedPencilIcon}
                                >
                                    <SVG src={Icons.Edit} id='edit' />
                                </StyledActionButton>
                                <StyledActionButton
                                    onClick={() => deleteProductDetails(productId)}
                                    css={styles.trashIcon}
                                >
                                    <SVG src={Icons.TrashV2} id='trash' />
                                </StyledActionButton>
                                {
                                    index === 0 ? (
                                        <StyledActionButton
                                            onClick={() => null}
                                            css={styles.upIcon}
                                        >
                                            <SVG src={Icons.ArrowUpV2} id='up' />
                                        </StyledActionButton>
                                    ) : (
                                        <StyledActionButton
                                            onClick={() => handleProductReorder(productId, status, IReorderSequence.MoveUp)}
                                            css={styles.upIcon}
                                        >
                                            <SVG src={Icons.ArrowUpV2} id='up' />
                                        </StyledActionButton>
                                    )
                                }
                                {
                                    index === (tableData.length - 1) ? (
                                        <StyledActionButton
                                            onClick={() => null}
                                            css={styles.downIcon}
                                        >
                                            <SVG src={Icons.ArrowDownV2} id='down' />
                                        </StyledActionButton>
                                    ) : (
                                        <StyledActionButton
                                            onClick={() => handleProductReorder(productId, status, IReorderSequence.MoveDown)}
                                            css={styles.downIcon}
                                        >
                                            <SVG src={Icons.ArrowDownV2} id='down' />
                                        </StyledActionButton>
                                    )
                                }
                            </div>
                        </StyledTd>
                    </StyledTr>
                );
            })
        );
    };

    return (
        <StyledTable>
            <StyledTHead>
                {renderTableHeader()}
            </StyledTHead>
            <StyledTableBody>
                {renderTableBody()}
            </StyledTableBody>
        </StyledTable>
    );
};

// ! styled-component zone
const StyledTable = styled.table`
    min-width: 100%;
    background-color: white;
    border-collapse: collapse;
    table-layout: fixed;

    margin-top: 20px;
    border-radius: 10px !important;
`;
const StyledTHead = styled.thead`
    text-align: center;
    border-bottom: 1px solid #C5C5C5;
`;
const StyledTableBody = styled.tbody`
    min-width: 100%;
    text-align: center;
`;
const StyledTh = styled.th`
    padding: 20px;
    border-bottom: 1px solid rgb(0,0,0,0,3);
    font-size: 15px;
    color: #959595;
    font-weight: lighter;

    &:first-of-type {
        text-align: left;
    }
`;
const StyledTr = styled.tr`
    &:not(:last-of-type){
        border-bottom: 5px solid #ffffff;
    }

    &:nth-last-of-type(1){
        border-radius: 0px 0px 10px 10px !important;
    }

    &:nth-of-type(odd){
        background-color: #F6FCFC;
    }
`;
const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const StyledTd = styled.td<{categoryTable?: boolean, productTable?: boolean}>`
    padding: 20px;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    ${props => props.categoryTable && `
        min-width: 300px;
        max-width: 500px;
    `}

    ${props => props.productTable && `
        min-width: 300px;
        max-width: 300px;
    `}
`;
const StyledLink = styled.a`
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-decoration: none;
    color: black;
    font-size: 16px;
    text-align: left;
    border: none;

    &:hover {
        color: #00D1D2;
        cursor: pointer;
    }
`;
const StyledActionButton = styled(Button)`
    background-color: transparent !important;
`;
const ImagePlaceholder = styled.div`
    width: 50px;
    height: 50px;
    max-width: 100%;
    max-height: 100%;
    border: 1px solid ${Colors.black};
`;
const StyledImg = styled.img`
    width: 50px;
    height: 50px;
    max-width: 100%;
    max-height: 100%;
`;
const styles = {
    trashIcon: css`
        background-color: transparent;
        width: 40px;
        height: 40px;
        #trash {
            width: 35px;
            height: 35px;
            color: #00D1D2;
        }
        &:hover{
            color: #d32f2f;
        }
    `,
    updatedPencilIcon: css`
        background-color: transparent;
        width: 40px;
        height: 40px;
    #pencil {
        width: 35px;
        height: 35px;
        &:hover{
            color: #1976d2;
        }
    }
    `,
    upIcon: css`
        background-color: transparent;
        width: 40px;
        height: 40px;
        #up {
            width: 35px;
            height: 35px;
            &:hover{
                color: ${Colors.secondary};
            }
        }
    `,
    downIcon: css`
        background-color: transparent;
        width: 40px;
        height: 40px;
        #down {
            width: 35px;
            height: 35px;
            &:hover{
                color: ${Colors.secondary};
            }
        }
    `,
};

// ! redux toolkit
// * Selectors
const mapStateToProps = (state: RootState) => ({
    productCategory: Selectors.getECommerceGetProductCategory(state),

    productList: Selectors.getECommerceGetProductList(state),
    productDetails: Selectors.getECommerceGetProductDetails(state),
    editProductDetailsAttempt: Selectors.getECommerceEditProductDetailsAttempting(state),
});
// * Dispatch
const mapDispatchToProps = (dispatch: AppDispatch) => ({
    editProductCategory: (data: IEditProductCategory) => dispatch(Actions.editProductCategoryAttempt(data)),
    deleteProductCategory: (id: string) => dispatch(Actions.deleteProductCategoryAttempt({ id })),

    getProductList: (categoryId: string) => dispatch(Actions.getProductListAttempt({ categoryId })),
    getProductDetails: (productId: string) => dispatch(Actions.getProductDetailsAttempt({ productId })),
    editProductDetails: (data: IEditProductDetails) => dispatch(Actions.editProductDetailsAttempt(data)),
    deleteProductDetails: (id: string) => dispatch(Actions.deleteProductDetailsAttempt({ id })),

});
// * Connection
export default connect(mapStateToProps, mapDispatchToProps)(ListTable);
