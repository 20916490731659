import React, { useEffect } from 'react';

import styled from 'styled-components';
import { connect } from 'react-redux';

import { ECommActivitiesReportData } from 'entities/ecommerceAnalytics';

import Title from 'components/Title';
import Error from 'components/analytics/common/Error';
import AnalyticsContainer from 'components/analytics/common/AnalyticsContainer';
import DatePicker from 'components/analytics/common/DatePicker';
import Loading from 'components/analytics/common/Loading';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { RootState, AppDispatch } from 'redux/store';

import ActivitiesReportGraphs from './components/ActivitiesReportGraphs';

interface ActivitiesReportProps {
    loading: boolean;
    error: string | null;

    data: ECommActivitiesReportData | null;

    selectedDates: { dateFrom: Date, dateTo: Date };

    setDatePicker: (dateFrom: Date, dateTo: Date) => void;
    getActivitiesReport: () => void;
}

const ActivitiesReport = (props: ActivitiesReportProps) => {
    const { loading, error, data, selectedDates, setDatePicker, getActivitiesReport } = props;

    const { dateFrom, dateTo } = selectedDates;

    useEffect(() => {
        getActivitiesReport();
    }, [dateFrom, dateTo]);

    const renderData = () => {
        if (loading) {
            return <Loading />;
        }

        if (error || !data) {
            return (
                <Error errorMessage={error} />
            );
        }

        return (
            <ActivitiesReportGraphs data={data} />
        );
    };

    return (
        <AnalyticsContainer>
            <HeaderWrapper>
                <Title label='Click Activities Report' />

                <DatePicker
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    onChange={setDatePicker}
                />
            </HeaderWrapper>

            <DisplayWrapper>
                {renderData()}
            </DisplayWrapper>
        </AnalyticsContainer>
    );
};

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-items: flex-end;

    width: 100%;

    gap: 20px;
`;

const DisplayWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    padding-top: 20px;

    gap: 20px;
`;

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getECommerceAnalActivitiesReportAttempting(state),
    error: Selectors.getECommerceAnalActivitiesReportFailure(state),
    data: Selectors.getECommerceAnalActivitiesReportData(state),

    selectedDates: Selectors.getECommerceAnalDatePicker(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setDatePicker: (dateFrom: Date, dateTo: Date) => dispatch(Actions.ecommAnalSetDatePicker({ dateFrom, dateTo })),
    getActivitiesReport: () => dispatch(Actions.ecommAnalGetActivitiesReportAttempt()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesReport);
