import { put, call, select, takeEvery } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import GameGateway from 'api/Games';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { ExportGameResponsesPayload } from 'redux/slices/games/types';
import xlsx, { IContent } from 'json-as-xlsx';
import dayjs from 'dayjs';

export default function* watchGameExportResponses(api: GameGateway): SagaWatcherReturnType {
    yield takeEvery('games/gameExportResponsesAttempting', handleGetGameExportToDownload, api);
}

function* handleGetGameExportToDownload(api: GameGateway, data: ExportGameResponsesPayload) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const gameDetails = yield* select(Selectors.getGameDetails);
    const isLanu = yield* select(Selectors.getFaqGetIsLANU);

    const fileTypeLAU = 'LAU';
    const fileTypeLANU = 'LANU';

    const { name } = gameDetails;

    const response = yield* call([api, api.getExportResponses], {
        authToken,
        gameId: data.payload.gameId,
        type: data.payload.type,
    });

    if (response.status === GatewayResponseStatus.Error) {
        toast.error(response.message);
        yield put(Actions.gameExportResponsesFailure());
    }

    if (response.status === GatewayResponseStatus.Success) {
        const massagedData = response.data.map((item) => {
            return {
                id: item.id,
                firstName: item.firstName,
                username: item.username,
                type: item.type,
                phoneNumber: item.phoneNumber,
                practicePlayedTimes: item.practicePlayedTimes,
                realGameScore: item.realGameScore,
                realGamePlayedTime: dayjs(item.realGamePlayedTime).format('DD-MMM-YYYY'),
                initialClickTime: dayjs(item.initialClickTime).format('DD-MMM-YYYY'),
            };
        });

        if (!massagedData.length) {
            toast.error('Insufficient data to export');
            yield put(Actions.gameExportResponsesFailure());
            return;
        }

        interface StringDictionary {
            [key: string]: any;
        }

        const excelGameColumns = [{
            label: 'ID',
            value: 'id',
        }, {
            label: 'First Name',
            value: 'firstName',
        }, {
            label: 'Username',
            value: 'username',
        }, {
            label: 'Phone Number',
            value: 'phoneNumber',
        }, {
            label: 'Click Time',
            value: 'initialClickTime',
        }, {
            label: 'Practice Plays',
            value: 'practicePlayedTimes',
        }, {
            label: 'Final Score',
            value: 'realGameScore',
        }, {
            label: 'Completed Game Time',
            value: 'realGamePlayedTime',
        }];

        let gameDataToExport: IContent[] = [];
        gameDataToExport = massagedData as StringDictionary[];

        const gameDataToDownload = [{
            sheet: 'Game Responses',
            columns: excelGameColumns,
            content: gameDataToExport,
        }];

        xlsx(gameDataToDownload, { fileName: `${name} for ${(isLanu) ? fileTypeLANU : fileTypeLAU} Responses` });

        yield* put(Actions.gameExportResponsesSuccess());
    }
}
