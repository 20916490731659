import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    ICreateContest,
    IDeleteContest,
    IGetContestsReduxParam,
    IGetContestsListResponse,
    ContestListTypesEnum,

    IDeleteQuizModalState,
    IEditQuizModalState,
    ICreateQuizModalState,
} from 'entities/contests';

import {
    ContestsReduxState,
    GetContestTypePayload,
    GetContestDetailsPayload,
    GetContestDetailsSuccessPayload,
    GetContestsPayload,
    GetContestResponsesParams,
    GetContestResponsesSuccessPayload,
    EditContestDetailsParamsPayload,
    ExportContestParams,
} from './types';

const initialState: ContestsReduxState = {
    actions: {
        getContests: false,
        createContest: false,
        deleteContest: false,
        editContest: false,
        getContestDetails: false,
        getContestResponses: false,
        exportResponses: false,

        getActiveQuizList: false,
        getInactiveQuizList: false,

        getActiveGamesList: false,
        getInactiveGamesList: false,
    },
    contests: [],
    contestDetails: {
        id: '',
        name: '',
        status: 0,
        totalResponses: 0,
        qualtricsId: '',
        maxWinnersLau: 0,
        maxWinnersLanu: 0,
        correctAnswersNeeded: 0,
    },
    contestResponses: {
        index: 1,
        maxIndex: 0,
        data: [],
    },
    page: 1,
    paginatorIndex: 1,

    activeQuizList: {
        index: 0,
        maxIndex: 0,
        data: [],
    },
    activeQuizListMaxPage: 0,
    activeQuizListIndex: 1,
    activeQuizListPage: 1,

    inactiveQuizList: {
        index: 0,
        maxIndex: 0,
        data: [],
    },
    inactiveQuizListMaxPage: 0,
    inactiveQuizListIndex: 1,
    inactiveQuizListPage: 1,

    activeGamesList: {
        index: 0,
        maxIndex: 0,
        data: [],
    },
    activeGamesListMaxPage: 0,
    activeGamesListIndex: 1,
    activeGamesListPage: 1,

    inactiveGamesList: {
        index: 0,
        maxIndex: 0,
        data: [],
    },
    inactiveGamesListMaxPage: 0,
    inactiveGamesListIndex: 1,
    inactiveGamesListPage: 1,

    createQuizModalState: {
        isOpen: false,
        type: ContestListTypesEnum.Quiz,
    },
    deleteQuizModalState: {
        isOpen: false,
        type: ContestListTypesEnum.Quiz,
        id: '',
    },
    editQuizModalState: {
        isOpen: false,
        type: ContestListTypesEnum.Quiz,
        id: '',
    },

    error: {
        getContests: '',
        createContest: '',
        deleteContest: '',
        editContest: '',
        getContestDetails: '',
        getContestResponses: '',

        getActiveQuizList: '',
        getInactiveQuizList: '',

        getActiveGamesList: '',
        getInactiveGamesList: '',
    },

};

const contestSlice = createSlice({
    name: 'contests',
    initialState,
    reducers: {
        contestClearCreateQuizError: (state) => {
            state.error.createContest = '';
        },
        contestSetCreateQuizModalState: (state, action: PayloadAction<ICreateQuizModalState>) => {
            if (action.payload)state.createQuizModalState = action.payload;
        },
        contestSetDeleteQuizModalState: (state, action: PayloadAction<IDeleteQuizModalState>) => {
            if (action.payload) state.deleteQuizModalState = action.payload;
        },
        contestSetEditContestModalState: (state, action: PayloadAction<IEditQuizModalState>) => {
            if (action.payload) state.editQuizModalState = action.payload;
        },
        getActiveQuizListAttempt: (state, _action: PayloadAction<IGetContestsReduxParam>) => {
            state.actions.getActiveQuizList = true;
            state.error.getActiveQuizList = '';
        },
        getActiveQuizListFailure: (state, action: PayloadAction<string>) => {
            state.actions.getActiveQuizList = false;
            if (action.payload) state.error.getActiveQuizList = action.payload;
        },
        getActiveQuizListSuccess: (state, action: PayloadAction<IGetContestsListResponse>) => {
            state.actions.getActiveQuizList = false;
            if (action.payload) state.activeQuizList = action.payload;
        },
        setActiveQuizListIndex: (state, action: PayloadAction<number>) => {
            if (action.payload) state.activeQuizListIndex = action.payload;
        },
        setActiveQuizListPage: (state, action: PayloadAction<number>) => {
            if (action.payload) state.activeQuizListPage = action.payload;
        },
        getInactiveQuizListAttempt: (state, _action: PayloadAction<IGetContestsReduxParam>) => {
            state.actions.getInactiveQuizList = true;
            state.error.getInactiveQuizList = '';
        },
        getInactiveQuizListFailure: (state, action: PayloadAction<string>) => {
            state.actions.getInactiveQuizList = false;
            if (action.payload) state.error.getInactiveQuizList = action.payload;
        },
        getInactiveQuizListSuccess: (state, action: PayloadAction<IGetContestsListResponse>) => {
            state.actions.getInactiveQuizList = false;
            if (action.payload) state.inactiveQuizList = action.payload;
        },
        setInactiveQuizListIndex: (state, action: PayloadAction<number>) => {
            if (action.payload) state.inactiveQuizListIndex = action.payload;
        },
        setInactiveQuizListPage: (state, action: PayloadAction<number>) => {
            if (action.payload) state.inactiveQuizListPage = action.payload;
        },
        getActiveGamesListAttempt: (state, _action: PayloadAction<IGetContestsReduxParam>) => {
            state.actions.getActiveGamesList = true;
            state.error.getActiveGamesList = '';
        },
        getActiveGamesListFailure: (state, action:PayloadAction<string>) => {
            state.actions.getActiveGamesList = false;
            if (action.payload)state.error.getActiveGamesList = action.payload;
        },
        getActiveGamesListSuccess: (state, action: PayloadAction<IGetContestsListResponse>) => {
            state.actions.getActiveGamesList = false;
            if (action.payload) state.activeGamesList = action.payload;
        },
        setActiveGamesListIndex: (state, action: PayloadAction<number>) => {
            if (action.payload)state.activeGamesListIndex = action.payload;
        },
        setActiveGamesListPage: (state, action: PayloadAction<number>) => {
            if (action.payload) state.activeGamesListPage = action.payload;
        },
        getInactiveGamesListAttempt: (state, _action: PayloadAction<IGetContestsReduxParam>) => {
            state.actions.getInactiveGamesList = true;
            state.error.getInactiveGamesList = '';
        },
        getInactiveGamesListFailure: (state, action:PayloadAction<string>) => {
            state.actions.getInactiveGamesList = false;
            if (action.payload) state.error.getInactiveGamesList = action.payload;
        },
        getInactiveGamesListSuccess: (state, action: PayloadAction<IGetContestsListResponse>) => {
            state.actions.getInactiveGamesList = false;
            if (action.payload) state.inactiveGamesList = action.payload;
        },
        setInactiveGamesListIndex: (state, action: PayloadAction<number>) => {
            if (action.payload)state.inactiveGamesListIndex = action.payload;
        },
        setInactiveGamesListPage: (state, action: PayloadAction<number>) => {
            if (action.payload) state.inactiveGamesListPage = action.payload;
        },
        getContestsAttempt: (state, _action: GetContestTypePayload) => {
            state.actions.getContests = true;
            state.error.getContests = '';
        },
        getContestsSuccess: (state, action: GetContestsPayload) => {
            state.actions.getContests = false;
            if (action.payload) {
                state.contests = action.payload;
            }
            state.error.getContests = '';
        },
        getContestsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getContests = false;
            if (action.payload) {
                state.error.getContests = action.payload;
            }
        },

        createContestAttempt: (state, _action: PayloadAction<ICreateContest>) => {
            state.actions.createContest = true;
            state.error.createContest = '';
        },
        createContestSuccess: (state) => {
            state.actions.createContest = false;
            state.error.createContest = '';
        },
        createContestFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.createContest = false;
            if (action.payload) {
                state.error.createContest = action.payload;
            }
        },

        deleteContestAttempt: (state, _action: PayloadAction<IDeleteContest>) => {
            state.actions.deleteContest = true;
            state.error.deleteContest = '';
        },
        deleteContestSuccess: (state) => {
            state.actions.deleteContest = false;
            state.error.createContest = '';
        },
        deleteContestFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.deleteContest = false;
            if (action.payload) {
                state.error.deleteContest = action.payload;
            }
        },

        contestEditContestAttempt: (state, _action: EditContestDetailsParamsPayload) => {
            state.actions.editContest = true;
            state.error.editContest = '';
        },
        contestEditContestSuccess: (state, action: EditContestDetailsParamsPayload) => {
            state.actions.editContest = false;

            state.contestDetails = {
                ...state.contestDetails,
                ...action.payload,
            };
        },
        contestEditContestFailure: (state, action: PayloadAction<string>) => {
            state.actions.editContest = false;
            if (action.payload) {
                state.error.editContest = action.payload;
            }
        },

        contestGetContestDetailsAttempt: (state, _action: GetContestDetailsPayload) => {
            state.actions.getContestDetails = true;
            state.error.getContestDetails = '';
        },
        contestGetContestDetailsSuccess: (state, action: GetContestDetailsSuccessPayload) => {
            state.actions.getContestDetails = false;

            if (action.payload) {
                state.contestDetails = action.payload;
            }
        },
        contestGetContestStatusFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getContestDetails = false;
            if (action.payload) {
                state.error.getContestDetails = action.payload;
            }
        },

        setContestPaginatorIndex: (state, action: PayloadAction<number>) => {
            state.contestResponses.index = action.payload;
        },
        setContestPage: (state, action: PayloadAction<number>) => {
            state.page = action.payload;
        },

        contestGetContestResponsesAttempt: (state, _action: GetContestResponsesParams) => {
            state.actions.getContestResponses = true;
            state.error.getContestResponses = '';
        },
        contestGetContestResponsesSuccess: (state, action: GetContestResponsesSuccessPayload) => {
            state.actions.getContestResponses = false;
            state.contestResponses = action.payload;
        },
        contestGetContestResponsesFailure: (state, action: PayloadAction<string>) => {
            state.actions.getContestResponses = false;
            if (action.payload) {
                state.error.getContestResponses = action.payload;
            }
        },

        contestExportResponsesAttempt: (state, _action: ExportContestParams) => {
            state.actions.exportResponses = true;
        },
        contestExportResponsesSuccess: (state) => {
            state.actions.exportResponses = false;
        },
        contestExportResponsesFailure: (state) => {
            state.actions.exportResponses = false;
        },
    },
});

export type ContestsState = typeof initialState;

export default {
    actions: contestSlice.actions,
    reducers: contestSlice.reducer,
};
