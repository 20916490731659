import React, { FunctionComponent, useEffect, useState } from 'react';
import { Row, Col, FormGroup, Spinner } from 'reactstrap';
import styled, { FlattenSimpleInterpolation, css } from 'styled-components';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

import { RootState, AppDispatch } from 'redux/store';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

import Flex from 'components/Flex';
import IQOSModal from 'components/IQOSModal';

import Colors from 'assets/themes/Colors';
import { ICreateProductCategory } from 'entities/ecommerce';

interface CreateCategoryModalProps {
    createProductCategoryAttempt: boolean;
    createProductCategoryError: string;
    createProductCategory: (data: ICreateProductCategory) => void;
    isOpen: boolean;
    setIsOpen: (state: boolean) => void;
}

const CreateCategorySchema = z.object({
    categoryName: z
        .string()
        .trim()
        .min(5, 'Minimum \'Category name\' length is 5'),
    status: z.number().min(1).max(2),
});

export type CreateCategoryType = z.infer<typeof CreateCategorySchema>;

const CreateCategoryModal:FunctionComponent<CreateCategoryModalProps> = (props:CreateCategoryModalProps) => {
    const {
        createProductCategoryAttempt,
        createProductCategoryError,
        createProductCategory,
        isOpen,
        setIsOpen,
    } = props;

    const initialFormDataState = {
        categoryName: '',
        status: 1,
    };

    const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
    const [formData, setFormData] = useState<CreateCategoryType>(initialFormDataState);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm<CreateCategoryType>({
        resolver: zodResolver(CreateCategorySchema),
        defaultValues: initialFormDataState,
    });

    useEffect(() => {
        if (isOpen === true) {
            reset();
            setFormData(initialFormDataState);
        }

        if (isConfirmed) {
            setIsConfirmed(false);
        }
    }, [isOpen]);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    const onSubmit = handleSubmit((data) => {
        if (isValid) {
            setIsConfirmed(true);
            setFormData(data);
        }
    });

    const cancelSubmit = () => {
        setIsConfirmed(false);
    };

    const create = () => {
        createProductCategory(formData);
    };

    const header = isConfirmed ? 'Confirm to create a new category?' : 'Create New Product Category';

    return (
        <IQOSModal
            header={header}
            isOpen={isOpen}
            toggle={toggle}
            size={isConfirmed ? 'sm' : 'lg'}
        >
            {!isConfirmed ? (
                <form onSubmit={onSubmit} autoComplete='off'>
                    <Row>
                        <StyledCol>
                            <FormGroup>
                                <StyledLabel>
                                    Product Category Name
                                </StyledLabel>
                                <StyledInput error={errors.categoryName && true} {...register('categoryName', { valueAsNumber: false })} />
                            </FormGroup>
                        </StyledCol>
                    </Row>
                    <Flex gap='1rem' margin='1rem 0 0 0'>
                        <SubmitButton type='submit' value='Create' />
                        <StyledButton type='button' css={styles.cancelButton} onClick={() => toggle()}>Cancel</StyledButton>
                    </Flex>
                    {(errors.categoryName) && (
                        <Flex flexDirection='column' padding='1rem 0 0 0' gap='1rem'>
                            {errors.categoryName && <StyledError>{errors.categoryName.message}</StyledError>}
                        </Flex>
                    )}
                </form>
            ) : (
                <Flex justifyContent='center' flexWrap='wrap' flexDirection='column' gap='1rem'>
                    {createProductCategoryError.length > 1 && (<StyledError>{createProductCategoryError}</StyledError>)}
                    <Flex gap='1rem'>
                        <StyledPrimaryButton type='button' onClick={() => create()}>
                            {createProductCategoryAttempt ? <Spinner size='sm' /> : 'Save' }
                        </StyledPrimaryButton>
                        <StyledButton type='button' css={styles.cancelButton} onClick={() => cancelSubmit()}>Cancel</StyledButton>
                    </Flex>
                </Flex>
            )}
        </IQOSModal>
    );
};

interface StyledCSSProps {
    css?: FlattenSimpleInterpolation;
}

const SubmitButton = styled.input<StyledCSSProps>`
    background-color: ${Colors.iqosPrimary};
    border: 1px solid ${Colors.iqosPrimary};
    color: ${Colors.white};
    padding: 0.5rem 1.6rem;
    border-radius:24px;
    min-width:90px;
    ${props => props.css}
`;

const StyledPrimaryButton = styled.button<StyledCSSProps>`
background-color: ${Colors.iqosPrimary};
border: 1px solid ${Colors.iqosPrimary};
color: ${Colors.white};
padding: 0.5rem 1.6rem;
border-radius:24px;
min-width:90px;
`;

const StyledButton = styled.button<StyledCSSProps>`
    background-color: ${Colors.white};
    border: 1px solid ${Colors.iqosPrimary};
    padding: 0.5rem 1.6rem;
    border-radius:24px;
    min-width:90px;
    color: ${Colors.iqosPrimary};
    ${props => props.css}
`;

const StyledLabel = styled.div`
    margin-bottom: 0.3rem;
`;

const StyledCol = styled(Col)`
    color: ${Colors.lightGrey}FF;
`;

interface StyledInputProps {
    error?: boolean
}

const StyledInput = styled.input<StyledInputProps>`
    background-color: #F7F7F7;
    border-color: transparent;

    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-clip: padding-box;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    ${props => props.error && (`border: 1px solid ${Colors.red};`)}

    &:focus {
        background-color: #F7F7F7;
        ${props => props.error && (`border: 1px solid ${Colors.red};`
    )}
        outline:none;
    }
`;

const StyledError = styled.div`
    color: ${Colors.red};
`;

const styles = {
    cancelButton: css`
        color: ${Colors.primary};
        background-color: ${Colors.white};
        border: 1px solid ${Colors.primary};
    `,
};

const mapStateToProps = (state: RootState) => ({
    createProductCategoryAttempt: Selectors.getECommerceCreateProductCategoryAttempting(state),
    createProductCategoryError: Selectors.getECommerceCreateProductCategoryFailure(state),
    isOpen: Selectors.getECommerceGetCreateCategoryModalIsOpen(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    createProductCategory: (data: ICreateProductCategory) => dispatch(Actions.createProductCategoryAttempt(data)),
    setIsOpen: (state: boolean) => dispatch(Actions.setCreateCategoryModalIsOpen(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCategoryModal);
