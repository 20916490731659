import React from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import Datepicker from 'react-datepicker';
import SVG from 'react-inlinesvg';
import dayjs from 'dayjs';
import { Spinner } from 'reactstrap';

import Icons from 'assets/icons/Index';
import FontStyles from 'assets/themes/Fonts';
import { ButtonStyles } from './styles';
import Flex from './Flex';

interface IQOSDateRangePickerProps {
    dateFrom: Date | null;
    dateTo : Date | null;
    setDateFrom: (param: Date) => void;
    setDateTo: (param: Date) => void;
    disabled?: boolean;
}

const IQOSDateRangePicker:React.FunctionComponent<IQOSDateRangePickerProps> = ({
    dateFrom,
    dateTo,
    setDateFrom,
    setDateTo,
    disabled,
}) => {
    const [calendarIsOpen, setCalendarIsOpen] = React.useState<boolean>(false);

    const onChange = (dates: Date[]) => {
        const [start, end] = dates;

        setDateFrom(start);
        setDateTo(end);

        if (start !== null && end !== null) setCalendarIsOpen(false);
    };

    const formatFollowersDate = (date:Date) => {
        if (!date) return false;
        return dayjs(date).format('DD-MM-YYYY');
    };

    const triggerCalendarPopup = () => {
        setCalendarIsOpen(!calendarIsOpen);
    };

    return (
        <Flex width='auto' alignItems='center' gap='1px' flexWrap='nowrap'>
            <Flex gap='0.6rem' justifyContent='flex-end'>
                <StyledHeader style={{ color: 'gray' }} size='sm'>
                    Showing For:
                </StyledHeader>
                <StyledCalendarButton type='button' css={ButtonStyles.blank} onClick={() => triggerCalendarPopup()} disabled={disabled}>
                    <Flex alignItems='center' flexWrap='nowrap' gap='0.5rem'>
                        <StyledSVG src={Icons.Calendar} />
                        <StyledHeader size='sm'>
                            {dateFrom && formatFollowersDate(dateFrom)}
                        </StyledHeader>
                        <StyledHeader style={{ color: 'gray' }} size='sm'>
                            to
                        </StyledHeader>
                        <StyledHeader size='sm' style={{ paddingRight: '1rem' }}>
                            {dateTo ? formatFollowersDate(dateTo) : <Spinner size='sm' />}
                        </StyledHeader>
                    </Flex>
                </StyledCalendarButton>
            </Flex>
            <DatePickerWrapper
                popperContainer={Popper}
                popperPlacement='bottom-end'
                calendarContainer={Calendar}
                selected={dateFrom}
                onChange={onChange}
                startDate={dateFrom}
                endDate={dateTo}
                maxDate={new Date()}
                selectsRange
                open={calendarIsOpen}
                onClickOutside={() => setCalendarIsOpen(false)}
                showYearDropdown
                showMonthDropdown
            />
        </Flex>
    );
};

const StyledCalendarButton = styled.button<{css?: FlattenSimpleInterpolation}>`
    ${props => props.css}
`;

const DatePickerWrapper = styled(({ className, ...props }) => (
    <Datepicker {...props} wrapperClassName={className} />
))`
    width: auto;
    input {
        border-radius: 1rem;
        text-align:center;
        border: 1px solid #A2A2A2;
        min-width: 200px;
        font-weight:bold;
        font-size: 18px;
        height:46px;

        display:none;
        color:transparent;
        background-color:transparent;
        position:absoulute;

        &:hover {
            cursor:pointer;
        }


    }
`;

const Calendar = styled.div`
    .react-datepicker__month-dropdown {
        width:auto !important;
        left: 60px !important;
        position: fixed !important;
        top: 47px !important;
        text-align: -webkit-left !important;
        .react-datepicker__month-option {
            padding-left: 1rem;
            padding-right:0.3rem;
        }
        .react-datepicker__month-option--selected {
            left:3px;
        }
    }

    .react-datepicker__month-read-view {
        .react-datepicker__month-read-view--down-arrow {
            content: '' !important;
            transform: none;
            right: 21px;
            top: -21px;
            min-width: 100px;
            height: 30px;
            border-width: 0;

            &:hover {
                cursor:pointer;
            }
        }

        .react-datepicker__month-read-view--selected-month { 
            display:none;
        }
    }

    .react-datepicker__year-read-view--selected-year {
        color: transparent;
        visibility:none;
    }

    .react-datepicker__year-read-view--down-arrow {
        transform: scale(1.3) rotate(135deg);
        right: -4px;
        top: -2px;
    }

    .react-datepicker__year-read-view--down-arrow {
        content: '' !important;
        transform: none;
        right: -6px;
        top: -10px;
        min-width: 62px;
        height: 32px;
        border-width: 0;

        &:hover {
            cursor:pointer;
            opacity: 0.7;
        }
    }

    .react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll {
        position: absolute !important;
        right: 48px !important;
        bottom: 29px !important;
    }

    .react-datepicker__year-dropdown {
        min-width: 100px;
        top: 47px;
        right: 33px;
        position: fixed;

        & > .react-datepicker__year-option:nth-last-child(1) {
            align-items: center;
        }

        & > .react-datepicker__year-option:nth-last-child(1)::after {
            content: '\u003C';
            margin: 0;
            position: absolute;
            left:44%;
            transform: rotate(-90deg) scale(1.5);
            bottom: 5px;
        }

        & > .react-datepicker__year-option:first-child:has(.react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming){
            align-items: center;
        }

        & > .react-datepicker__year-option:first-child:has(.react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming)::after {
            content: '\u003C';
            margin: 0;
            position: absolute;
            left:44%;
            top:5px;
            transform: rotate(90deg) scale(1.5);
        }
    }

    border-radius: 10px;
    box-shadow: 0 6px 12px rgba(27, 37, 86, 0.16);
    overflow: hidden;
    `;

const Popper = styled.div`
    position: absolute;
    top: 0;
    left: 100px;
    z-index: 2;
`;

const StyledSVG = styled(SVG)`
    fill: black;
    width: 24px;
    height: 24px;

    @media (max-width: 1580px) {
        width: 15px;
        height: 15px;
    }
`;

interface StyledHeaderProps {
    size: string;
}

const changeFontSize = (size:string) => {
    let fontSize:string;
    switch (size) {
        case 'sm':
            fontSize = FontStyles.sm;
            break;
        case 'lg':
            fontSize = FontStyles.lg;
            break;
        default:
            fontSize = FontStyles.md;
            break;
    }
    return fontSize;
};

const StyledHeader = styled.div<StyledHeaderProps>`
    font-size: ${props => changeFontSize(props.size)};
    color: #27242E;
    font-family: IQOS-Bold;
    font-weight: bold;

    @media (max-width: 1580px) {
        font-size: 12px;
        align-items: flex-end;
    }
`;

export default IQOSDateRangePicker;
