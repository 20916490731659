import React, { useEffect } from 'react';

import styled from 'styled-components';
import { connect } from 'react-redux';
import Selectors from 'redux/Selectors';
import { RootState, AppDispatch } from 'redux/store';

import { ECommClicksReportData } from 'entities/ecommerceAnalytics';

import Title from 'components/Title';
import Subtitle from 'components/Subtitle';
import StatCard from 'components/summaryDashboard/StatCardPercentage';
import AnalyticsContainer from 'components/analytics/common/AnalyticsContainer';
import Loading from 'components/analytics/common/Loading';

import Actions from 'redux/Actions';

import NavActions from 'lib/NavActions';
import DatePicker from 'components/analytics/common/DatePicker';
import Error from 'components/analytics/common/Error';
import ClicksReportGraphs from './components/ClicksReportGraphs';

interface ClicksReportProps {
    selectedDates: { dateFrom: Date, dateTo: Date };
    setDatePicker: (dateFrom: Date, dateTo: Date) => void;
    getClicksReport: () => void;

    loading: boolean;
    error: string | null;
    data: ECommClicksReportData | null;
}

const ECommerceAnalytics = (props: ClicksReportProps): JSX.Element => {
    const {
        selectedDates,
        setDatePicker,
        getClicksReport,

        loading,
        error,
        data,
    } = props;

    const { dateFrom, dateTo } = selectedDates;

    useEffect(() => {
        getClicksReport();
    }, [dateFrom, dateTo]);

    const renderData = () => {
        if (loading) {
            return <Loading />;
        }

        if (error || !data) {
            return (
                <Error errorMessage={error} />
            );
        }

        return (
            <>
                <ClicksReportGraphs data={data} />

                <Subtitle>
                    Number of Start Clicks Across Various Entry Points
                </Subtitle>
                <GridWrapper>
                    <StatCard
                        label='Start'
                        current={data.starts.withoutLinks}
                        isYtd={false}
                    />
                    <StatCard
                        label='Direct EN Starts'
                        current={data.starts.en}
                        isYtd={false}
                    />
                    <StatCard
                        label='Direct BM Starts'
                        current={data.starts.bm}
                        isYtd={false}
                    />
                </GridWrapper>

                <Subtitle>
                    Trade-In Clicks
                </Subtitle>
                <GridWrapper>
                    <StatCard
                        label='Trade-In'
                        current={data.tradeIn.clicks}
                        subtitle={`${data.tradeIn.uniqueClicks} unique clicks`}
                        isYtd={false}
                    />
                </GridWrapper>

                <Subtitle>
                    Number of Clicks Based on Product Categories
                </Subtitle>

                <GridWrapper>
                    {data.clicks.map((item) => (
                        <StatCard
                            key={item.id}
                            id={item.id}
                            label={item.name}
                            current={item.clicks}
                            subtitle={`${item.uniqueClicks} unique clicks`}
                            isYtd={false}
                            onClick={(id) => NavActions.navToEcommerceAnalyticsClicksProductReport(id)}
                        />
                    ))}
                </GridWrapper>
            </>
        );
    };

    return (
        <AnalyticsContainer>
            <HeaderWrapper>
                <Title label='Clicks Report' />
                <DatePicker
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    onChange={setDatePicker}
                />
            </HeaderWrapper>

            <DisplayWrapper>
                {renderData()}
            </DisplayWrapper>
        </AnalyticsContainer>
    );
};

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-items: flex-end;

    width: 100%;

    gap: 20px;
`;

const DisplayWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    padding-top: 20px;

    gap: 20px;
`;

const GridWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    
    gap: 20px;
`;

const mapStateToProps = (state: RootState) => ({
    selectedDates: Selectors.getECommerceAnalDatePicker(state),

    loading: Selectors.getECommerceAnalClicksReportAttempting(state),
    error: Selectors.getECommerceAnalClicksReportFailure(state),
    data: Selectors.getECommerceAnalClicksReportData(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setDatePicker: (dateFrom: Date, dateTo: Date) => dispatch(Actions.ecommAnalSetDatePicker({ dateFrom, dateTo })),
    getClicksReport: () => dispatch(Actions.ecommAnalGetClicksReportAttempt()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ECommerceAnalytics);
