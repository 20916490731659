export enum StatusTypeEnum {
    All = '',
    Active = 1,
    Inactive = 2,
}

export enum RecipientListEnum {
    Custom = '0',
    Users = '1',
    LAU = '2',
    LANU = '3',
}

export enum BroadcastMessageTypesEnum {
    Message = 1,
    Polls = 2,
}

export enum BroadcastSendingFrequencyEnum {
    Daily = 1,
    Weekly = 2,
    Monthly = 3,
}

export enum BroadcastTypesEnum {
    Immediate = 1,
    Scheduled = 2,
    Recurring = 3,
}

export enum BroadcastHistoryFilter {
    All = 0,
    Immediate = 1,
    Scheduled = 2,
    Recurring = 3,
}

export enum IBroadcastMediaUploadStatus {
    NoMedia = 0,
    Success = 1,
    Failed = 2,
    InProgress = 3,
}

export interface BroadcastOverview {
    month: number;
    postsSent: number;
    ytdPostsSent: number;
}

export interface RecurringPost {
    index: number;
    maxIndex: number;
    data: {
        id: string;
        status: StatusTypeEnum;
        title: string;
        message: string;
        messageType: BroadcastMessageTypesEnum;
        broadcastType: BroadcastTypesEnum;
        mediaUrl: string;
        recipientList: string[];
        recipientListEnum: RecipientListEnum;
        createdAt: string;
        updatedAt: string;
        interval: {
            startDate: string,
            frequency: BroadcastSendingFrequencyEnum,
            expiryDate?: string,
        },
        mediaUploadStatus: IBroadcastMediaUploadStatus;
    }[]
}

export interface SchedulePost {
    index: number;
    maxIndex: number;
    data: {
        id: string;
        status: StatusTypeEnum;
        title: string;
        message: string;
        messageType: BroadcastMessageTypesEnum;
        broadcastType: BroadcastTypesEnum;
        mediaUrl: string;
        recipientList: string[];
        recipientListEnum: RecipientListEnum;
        createdAt: string;
        updatedAt: string;
        interval: {
            startDate: string,
            frequency?: BroadcastSendingFrequencyEnum,
            expiryDate?: string,
        },
        mediaUploadStatus: IBroadcastMediaUploadStatus;
    }[]
}

export interface BroadcastHistoryPost {
    index: number,
    maxIndex: number,
    data: {
        id: string;
        broadcastId: string;
        title: string;
        message: string;
        messageType: BroadcastMessageTypesEnum;
        numOfRecipients: number;
        broadcastType: BroadcastTypesEnum;
        revoked: boolean;
        failedRecipientList: {
            telegramId: number;
            errorMessage: string;
        }[];
        revokedDate?: string;
        sentDate: string;
        mediaUploadStatus: IBroadcastMediaUploadStatus;
    }[]
}

export interface BroadcastHistoryPostExport {
    id: string;
    broadcastId: string;
    title: string;
    message: string;
    messageType: BroadcastMessageTypesEnum;
    numOfRecipients: number;
    broadcastType: string;
    revoked: boolean;
    failedRecipients: number;
    revokedDate: string;
    sentDate: string;
    successRate: number;
}

export interface HistoryDetails {
        broadcastId: string;
        title: string;
        message: string;
        messageType: BroadcastMessageTypesEnum;
        recipientList: string[];
        recipientListEnum: RecipientListEnum;
        broadcastType: BroadcastTypesEnum;
        interval: {
            startDate: string,
            frequency?: BroadcastSendingFrequencyEnum,
            expiryDate?: string,
        },
        isDeleted: boolean;
        status: StatusTypeEnum;
        createdAt: string;
        updatedAt: string;
        mediaUrl: string;
        mediaUploadStatus: IBroadcastMediaUploadStatus;
        fileId?: string;
}

export interface HistoryDetailsExport {
    messageId: number;
    firstName: string;
    lastName: string;
    username: string;
    phoneNumber: string;
    recipientTelegramId: number;
    sent: boolean;
    sentAt: string;
    errorMessage: string;
}

export interface ICreateBroadcastPost {
    title: string;
    message: string;
    messageType: BroadcastMessageTypesEnum;
    recipientList?: string;
    broadcastType: BroadcastTypesEnum;
    file?: File;
    startDate: Date;
    expiryDate?: Date;
    frequency?: BroadcastSendingFrequencyEnum;
    recipientListEnum: RecipientListEnum;
    mediaUrl?: string;
    fileId?: string;
}

export interface IEditBroadcastPost {
    broadcastId: string;
    title: string;
    message: string;
    messageType: BroadcastMessageTypesEnum;
    recipientList?: string;
    broadcastType: BroadcastTypesEnum;
    mediaUrl?: string;
    startDate: Date;
    expiryDate?: Date;
    frequency?: BroadcastSendingFrequencyEnum;
    status: StatusTypeEnum;
    recipientListEnum: string;
    file?: File | null;
    fileId?: string;
}
