import React from 'react';

import Colors from 'assets/themes/Colors';

import Flex from 'components/Flex';
import IconTooltip from 'components/IconTooltip';
import AnalyticsHeader from './AnalyticsHeader';

interface FollowersHeaderProps {
    headerContent: string;
    headerSize: 'sm' | 'lg' | 'md';
    headerColor?: string;
    headerWeight?: string;
    tooltipId: string;
    tooltipIconSrc: string;
    tooltipContent: string | React.ReactNode;
    tooltipIconSize?: string;
    tooltipBgColor?: string;
    tooltipFontColor?: string;
    tooltipMaxWidth?: string;
}

const FollowersHeader:React.FunctionComponent<FollowersHeaderProps> = ({
    tooltipContent,
    tooltipId,
    tooltipIconSrc,
    tooltipIconSize,
    tooltipBgColor,
    tooltipFontColor,
    tooltipMaxWidth,
    headerContent,
    headerSize,
    headerColor,
    headerWeight,
}) => {
    return (
        <Flex alignItems='center' gap='.8rem'>
            <AnalyticsHeader fontWeight={headerWeight} size={headerSize} header={headerContent} fontColor={headerColor} />
            <IconTooltip
                iconSrc={tooltipIconSrc}
                tooltipId={tooltipId}
                bgColor={tooltipBgColor}
                fontColor={tooltipFontColor}
                size={tooltipIconSize}
                maxWidth={tooltipMaxWidth}
            >
                {tooltipContent}
            </IconTooltip>
        </Flex>
    );
};

FollowersHeader.defaultProps = {
    tooltipIconSize: '20px',
    tooltipBgColor: Colors.iqosPrimary,
    tooltipFontColor: Colors.primary,
    headerColor: Colors.black,
    headerWeight: 'bold',
};

export default FollowersHeader;
