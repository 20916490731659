import React from 'react';
import styled from 'styled-components';

interface AnalyticsContainerHeaderProps {
    children: JSX.Element | JSX.Element[];
}

const AnalyticsContainerHeader = (props: AnalyticsContainerHeaderProps): JSX.Element => {
    const { children } = props;

    return (
        <HeaderWrapper>
            {children}
        </HeaderWrapper>
    );
};

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-items: flex-end;

    width: 100%;

    gap: 20px;
`;

export default AnalyticsContainerHeader;
