import React, { FunctionComponent, useEffect, useState } from 'react';
import { Row, Col, FormGroup, Spinner } from 'reactstrap';
import styled, { FlattenSimpleInterpolation, css } from 'styled-components';
import { connect } from 'react-redux';

import { RootState, AppDispatch } from 'redux/store';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

import SVG from 'react-inlinesvg';
import { toast } from 'react-toastify';

import Flex from 'components/Flex';
import IQOSModal from 'components/IQOSModal';

import Colors from 'assets/themes/Colors';
import Icons from 'assets/icons/Index';

import { ICreateVariant } from 'entities/ecommerce';
import FsLightbox from 'fslightbox-react';

interface CreateVariantModalProps {
    productId: string;
    createProductVariant: (data: ICreateVariant) => void;
    createProductVariantAttempt: boolean;

    createVariantModalIsOpen: boolean;
    setCreateVariantModalIsOpen: (state: boolean) => void;
}

const CreateVariantModal:FunctionComponent<CreateVariantModalProps> = (props:CreateVariantModalProps) => {
    const {
        productId,
        createProductVariant,
        createProductVariantAttempt,
        createVariantModalIsOpen,
        setCreateVariantModalIsOpen,
    } = props;

    const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
    const [toggler, setToggler] = useState<boolean>(false);

    const [enVariantName, setEnVariantName] = useState<string>('');
    const [bmVariantName, setBmVariantName] = useState<string>('');

    const [viewMedia, setViewMedia] = useState<string>('');
    const [viewMediaUrl, setViewMediaUrl] = useState<string>('');
    const [uploadedImageFile, setUploadedImageFile] = useState<File>();
    const [uploadedImageFileName, setUploadedImageFileName] = useState<string>('');

    const [errorMessage, setErrorMessage] = useState<string>('');

    const header = isConfirmed ? 'Confirm to create a new variant?' : 'Add New Variant';

    useEffect(() => {
        if (isConfirmed) {
            setIsConfirmed(false);
            setErrorMessage('');
            setEnVariantName('');
            setBmVariantName('');
            setUploadedImageFile(undefined);
            setUploadedImageFileName('');
            setViewMedia('');
            setViewMediaUrl('');
        }
    }, [createVariantModalIsOpen]);

    const formatFileName = (url: string) => {
        if (url) {
            const { pathname } = new URL(url as string);
            const fileName = pathname.substring(pathname.lastIndexOf('/') + 1);
            return fileName;
        }
        return '';
    };

    const handleChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileUploaded = event.target.files?.[0];

        if (fileUploaded !== undefined) {
            if (uploadedImageFile === undefined) {
                setUploadedImageFile(uploadedImageFile);
            }
            const { type } = fileUploaded;
            let documentExtension = '';

            switch (type) {
                case 'image/jpg': documentExtension = '.jpg'; break;
                case 'image/jpeg': documentExtension = '.jpeg'; break;
                case 'image/png': documentExtension = '.png'; break;
                default:
            }

            if (fileUploaded.size <= 5242880) {
                if ((documentExtension === ('.png'))
                || (documentExtension === ('.jpeg'))
                || (documentExtension === ('.jpg'))) {
                    setUploadedImageFile(fileUploaded);
                    setUploadedImageFileName(fileUploaded.name);
                    setViewMedia(URL.createObjectURL(fileUploaded));

                    toast.success('File uploaded!');
                } else {
                    event.target.value = ''; // Reset the file input field
                    setUploadedImageFile(undefined);
                    setUploadedImageFileName('');
                    setViewMedia('');
                    toast.error('Unsupported file type!');
                }
            } else {
                event.target.value = '';
                setUploadedImageFile(undefined);
                setUploadedImageFileName('');
                setViewMedia('');
                toast.error('Limit size for image file is 5mb');
            }
        }
    };

    const resetImage = () => {
        setUploadedImageFile(undefined);
        setUploadedImageFileName('');
        setViewMedia('');
        setViewMediaUrl('');
    };

    const toggle = () => {
        setCreateVariantModalIsOpen(!createVariantModalIsOpen);
        setEnVariantName('');
        setBmVariantName('');
        resetImage();
        setErrorMessage('');
    };
    const cancelSubmit = () => {
        setIsConfirmed(false);
        setErrorMessage('');
    };

    const createVariant = () => {
        if (isConfirmed) {
            createProductVariant({
                productId,
                variantNameEN: enVariantName,
                variantNameBM: bmVariantName,
                variantImage: uploadedImageFile,
            });
            setIsConfirmed(false);
        }
        if (enVariantName === '' || bmVariantName === '') {
            setErrorMessage('Please enter variant name');
            return;
        }
        if (uploadedImageFile === undefined) {
            setErrorMessage('Please upload image');
            return;
        }
        setErrorMessage('');
        setIsConfirmed(true);
    };

    const renderUploadMedia = () => {
        return (
            <MediaContainer>
                <StyledText style={{ color: '#A2A2A2', display: 'inline-flex', justifyContent: 'flex-start', width: '100%', gap: '2%', alignItems: 'center' }}>
                    Upload Image
                </StyledText>
                <StyledUploadImageWrapper>
                    <StyledLabelWrapper htmlFor='imageFileInput' style={{ justifyContent: 'center', gap: '5%' }}>
                        {(uploadedImageFile || viewMediaUrl) ? (
                            <StyledText style={{ fontWeight: '500', color: '#00D1D2', fontSize: '16px', paddingTop: '1.5px', paddingBottom: '1.5px', fontStyle: 'italic' }}>
                                {uploadedImageFileName || formatFileName(viewMediaUrl)}
                            </StyledText>
                        ) : (
                            <>
                                <StyledText style={{ fontWeight: '500' }}>Image</StyledText>
                                <img src={Icons.UploadImage} alt='uploadImage' style={{ width: '20px' }} />
                            </>
                        )}
                        <input
                            id='imageFileInput'
                            type='file'
                            name='file'
                            style={{ display: 'none' }}
                            accept='.jpeg, .jpg, .png'
                            onChange={handleChangeImage}
                        />
                    </StyledLabelWrapper>
                    {viewMedia ? (
                        <>
                            <StyledButton
                                onClick={() => setToggler(!toggler)}
                                style={{ padding: '0', justifyContent: 'center', border: 'none', minWidth: '25px' }}
                            >
                                <SVG
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        fill: '#00D1D2',
                                        strokeWidth: '0.1px',
                                    }}
                                    src={Icons.ViewImage}
                                    id='viewImageIcon'
                                />
                            </StyledButton>
                            <FsLightbox
                                toggler={toggler}
                                sources={[viewMedia]}
                            />
                        </>
                    ) : null}
                    {(uploadedImageFile || viewMediaUrl) && (
                        <StyledButton onClick={() => resetImage()} style={{ fontSize: '16px', border: 'none', color: 'black', minWidth: 'unset' }}>Remove</StyledButton>
                    )}
                </StyledUploadImageWrapper>
            </MediaContainer>
        );
    };

    return (
        <IQOSModal
            header={header}
            isOpen={createVariantModalIsOpen}
            toggle={toggle}
            size={isConfirmed ? 'sm' : 'lg'}
        >
            {!isConfirmed ? (
                <>
                    <Row>
                        <StyledCol>
                            <FormGroup>
                                <StyledLabel>
                                    Variant Name
                                </StyledLabel>
                                <StyledInput
                                    value={enVariantName}
                                    error={errorMessage.length > 0}
                                    onChange={(e) => setEnVariantName(e.target.value)}
                                />
                                <StyledLabel>
                                    Nama Variant
                                </StyledLabel>
                                <StyledInput
                                    value={bmVariantName}
                                    error={errorMessage.length > 0}
                                    onChange={(e) => setBmVariantName(e.target.value)}
                                />
                                {renderUploadMedia()}
                            </FormGroup>
                        </StyledCol>
                    </Row>
                    <Flex gap='1rem' margin='1rem 0 0 0'>
                        <SubmitButton type='submit' onClick={() => createVariant()} />
                        <StyledButton type='button' css={styles.cancelButton} onClick={() => toggle()}>Cancel</StyledButton>
                    </Flex>
                    {(errorMessage.length > 0) && (
                        <Flex flexDirection='column' padding='1rem 0 0 0' gap='1rem'>
                            <StyledError>{errorMessage}</StyledError>
                        </Flex>
                    )}
                </>
            ) : (
                <Flex justifyContent='center' flexWrap='wrap' flexDirection='column' gap='1rem'>
                    <Flex gap='1rem'>
                        <StyledPrimaryButton type='button' onClick={() => createVariant()}>
                            {createProductVariantAttempt ? <Spinner size='sm' /> : 'Save' }
                        </StyledPrimaryButton>
                        <StyledButton type='button' css={styles.cancelButton} onClick={() => cancelSubmit()}>Cancel</StyledButton>
                    </Flex>
                </Flex>
            )}
        </IQOSModal>
    );
};

interface StyledCSSProps {
    css?: FlattenSimpleInterpolation;
}

const SubmitButton = styled.input<StyledCSSProps>`
    background-color: ${Colors.iqosPrimary};
    border: 1px solid ${Colors.iqosPrimary};
    color: ${Colors.white};
    padding: 0.5rem 1.6rem;
    border-radius:24px;
    min-width:90px;
    ${props => props.css}
`;

const StyledPrimaryButton = styled.button<StyledCSSProps>`
background-color: ${Colors.iqosPrimary};
border: 1px solid ${Colors.iqosPrimary};
color: ${Colors.white};
padding: 0.5rem 1.6rem;
border-radius:24px;
min-width:90px; 
`;

const StyledButton = styled.button<StyledCSSProps>`
    background-color: ${Colors.white};
    border: 1px solid ${Colors.iqosPrimary};
    padding: 0.5rem 1.6rem;
    border-radius:24px;
    min-width:90px;
    color: ${Colors.iqosPrimary};
    ${props => props.css}
`;

const StyledLabel = styled.div`
    margin-bottom: 0.3rem;
    margin-top: 10px;
`;

const StyledCol = styled(Col)`
    color: ${Colors.lightGrey}FF;
`;

interface StyledInputProps {
    error?: boolean
}

const StyledInput = styled.input<StyledInputProps>`
    background-color: #F7F7F7;
    border-color: transparent;

    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-clip: padding-box;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    ${props => props.error && (`border: 1px solid ${Colors.red};`)}

    &:focus {
        background-color: #F7F7F7;
        ${props => props.error && (`border: 1px solid ${Colors.red};`
    )}
        outline:none;
    }
`;

const StyledError = styled.div`
    color: ${Colors.red};
`;

const MediaContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 20px 0 20px 0;
    width: 50%;
`;

const StyledText = styled.text`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const StyledLabelWrapper = styled.label`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 70%;
    border: 1px solid #A2A2A2;
    border-radius: 5px;

    padding: 5px 10px;
`;

const StyledUploadImageWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    width: 100%;
`;

const styles = {
    cancelButton: css`
        color: ${Colors.primary};
        background-color: ${Colors.white};
        border: 1px solid ${Colors.primary};
    `,
};

const mapStateToProps = (state: RootState) => ({
    createProductVariantAttempt: Selectors.getECommerceCreateProductVariantAttempting(state),
    createVariantModalIsOpen: Selectors.getECommerceGetCreateVariantModalIsOpen(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    createProductVariant: (data: ICreateVariant) => dispatch(Actions.createProductVariantAttempt(data)),

    setCreateVariantModalIsOpen: (state: boolean) => dispatch(Actions.setCreateVariantModalIsOpen(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateVariantModal);
