import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { GatewayResponseStatus } from 'api/types/types';
import GamesGateway from 'api/Games';

import { CreateGamePayload } from 'redux/slices/games/types';

import { toast } from 'react-toastify';
import { ContestListTypesEnum, IChannelTypeEnum } from 'entities/contests';

export default function* watchCreateGame(api: GamesGateway): SagaWatcherReturnType {
    yield takeEvery('games/createGameAttempting', handleCreateGame, api);
}

function* handleCreateGame(api: GamesGateway, data: CreateGamePayload) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const isLanu = yield* select(Selectors.getFaqGetIsLANU);

    const response = yield* call([api, api.createGame], {
        data: data.payload,
        authToken,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.createGameFailure(response.message));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        toast.success('New game created');
        yield put(Actions.createGameSuccess());

        const activeGameIndex = yield* select(Selectors.contestGetActiveGamesListIndex);
        const inactiveGameIndex = yield* select(Selectors.contestGetInactiveGamesListIndex);
        yield put(Actions.getActiveGamesListAttempt({ index: activeGameIndex }));
        yield put(Actions.getInactiveGamesListAttempt({ index: inactiveGameIndex }));
        yield put(Actions.contestSetCreateQuizModalState({ isOpen: false, type: ContestListTypesEnum.Game }));
    }
}
