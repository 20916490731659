import React from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import ColorStyles from 'assets/themes/Colors';

interface IQOSCheckboxProps {
    label: string;
    checkboxContainerId: string;
    isChecked: boolean;
    setIsChecked: (param: boolean) => void;
    inputStyles?: FlattenSimpleInterpolation;
    containerStyles?: FlattenSimpleInterpolation;
}

const IQOSCheckbox:React.FunctionComponent<IQOSCheckboxProps> = ({
    label,
    checkboxContainerId,
    isChecked,
    setIsChecked,
    containerStyles,
    inputStyles,
}) => {
    return (
        <CheckboxWrapper css={containerStyles}>
            <label htmlFor={checkboxContainerId}>
                <Checkbox
                    css={inputStyles}
                    id={checkboxContainerId}
                    checked={isChecked}
                    type='checkbox'
                    onChange={() => {
                        setIsChecked(!isChecked);
                    }}
                />
                <span>{label}</span>
            </label>
        </CheckboxWrapper>
    );
};

interface customizedCSSProps {
    css?: FlattenSimpleInterpolation;
}

const CheckboxWrapper = styled.div<customizedCSSProps>`
    margin-bottom:1rem;
    span {
        color: ${ColorStyles.white};
    }
    label {
        input[type="checkbox"]:checked {
            accent-color:${ColorStyles.secondary};
        }
    }

    ${props => props.css}
`;

const Checkbox = styled.input<customizedCSSProps>`
    margin-right:0.6rem;
    ${props => props.css}
`;

export default IQOSCheckbox;
