import {
    IFollowersTableInfo,
    IFollowersUsersList,
    IFollowersChartsData,
    IFullFollowersUsersList,
} from 'entities/followers';
import { FollowersState } from '.';

const getFollowersTablesInfoAttempting = (state: FollowersState): boolean => state.actions.getFollowersTablesInfo || false;
const getFollowersTablesInfoError = (state: FollowersState): string => state.error.getFollowersTablesInfo || '';
const getFollowersTablesInfo = (state: FollowersState): IFollowersTableInfo => state.followersTablesInfo || {};

const getFollowersUsersListAttempting = (state: FollowersState): boolean => state.actions.getFollowersUsersList || false;
const getFollowersUsersListError = (state: FollowersState): string => state.error.getFollowersUsersList || '';
const getFollowersUsersList = (state: FollowersState): IFollowersUsersList[] => state.followersUsersList || [];
const getFollowersUsersListCurrentPage = (state: FollowersState): number => state.followersUsersListPage || 1;
const getFollowersUsersListCurrentIndex = (state: FollowersState): number => state.followersUsersListIndex || 1;
const getFollowersUsersListMaxPage = (state: FollowersState): number => state.followersUsersListMaxPage || 0;

const getFullFollowersUsersListAttempting = (state: FollowersState): boolean => state.actions.getFullFollowersUsersList || false;
const getFullFollowersUsersListError = (state: FollowersState): string => state.error.getFullFollowersUsersList || '';
const getFullFollowersUsersList = (state: FollowersState): IFullFollowersUsersList[] => state.fullFollowersUsersList || [];
const getFullFollowersUsersListCurrentPage = (state: FollowersState): number => state.fullFollowersUsersListPage || 1;
const getFullFollowersUsersListCurrentIndex = (state: FollowersState): number => state.fullFollowersUsersListIndex || 1;
const getFullFollowersUsersListMaxPage = (state: FollowersState): number => state.fullFollowersUsersListMaxPage || 0;

const getFollowersChartsData = (state: FollowersState): IFollowersChartsData => state.followersChartsData || {};
const getFollowersChartsDataAttempting = (state: FollowersState): boolean => state.actions.getFollowersChartsData || false;
const getFollowersChartsDataError = (state: FollowersState): string => state.error.getFollowersChartsData || '';

const exportFullFollowersListAttempting = (state: FollowersState): boolean => state.actions.exportFullFollowersListToExcel || false;
const exportFullFollowersListError = (state:FollowersState): string => state.error.exportFullFollowersListToExcel || '';

export default {
    getFollowersTablesInfoAttempting,
    getFollowersTablesInfoError,
    getFollowersTablesInfo,

    getFollowersUsersListAttempting,
    getFollowersUsersListError,
    getFollowersUsersList,
    getFollowersUsersListCurrentPage,
    getFollowersUsersListCurrentIndex,
    getFollowersUsersListMaxPage,

    getFullFollowersUsersListAttempting,
    getFullFollowersUsersListError,
    getFullFollowersUsersList,
    getFullFollowersUsersListCurrentPage,
    getFullFollowersUsersListCurrentIndex,
    getFullFollowersUsersListMaxPage,

    getFollowersChartsData,
    getFollowersChartsDataAttempting,
    getFollowersChartsDataError,

    exportFullFollowersListAttempting,
    exportFullFollowersListError,
};
