import { ApiResponse } from 'apisauce';

import { ECommActivitiesReportData, ECommBuyNowClicksProductReportData, ECommBuyNowClicksReportData, ECommBuyNowClicksVariantReportData, ECommClicksProductReportData, ECommClicksReportData, ECommClicksVariantReportData, ECommFunnelReportData } from 'entities/ecommerceAnalytics';

import {
    GetBuyNowClicksVariantReportApiParams,
    GetClicksProductReportApiParams,
    GetClicksReportApiParams, GetClicksVariantReportApiParams, GetFunnelReportApiParams, IECommerceAnalyticsGateway,
} from 'api/ECommerceAnalyticsBase';
import { GatewayResponse } from './types/types';

const getHeaders = (authToken: string) => ({
    headers: {
        Authorization: `Bearer ${authToken}`,
    },
});

export default class ECommerceAnalyticsGateway extends IECommerceAnalyticsGateway {
    async getClicksReport(params: GetClicksReportApiParams): GatewayResponse<ECommClicksReportData> {
        const response: ApiResponse<ECommClicksReportData> = await this.api.get('/clicks', {
            dateFrom: params.dateFrom,
            dateTo: params.dateTo,
        }, getHeaders(params.authToken));

        return this.process(response);
    }

    async getClicksProductReport(params: GetClicksProductReportApiParams): GatewayResponse<ECommClicksProductReportData> {
        const { authToken, dateFrom, dateTo, productCategoryId } = params;
        const response: ApiResponse<ECommClicksProductReportData> = await this.api.get(`/clicks/productCategory/${productCategoryId}`, {
            dateFrom,
            dateTo,
        }, getHeaders(authToken));

        return this.process(response);
    }

    async getClicksVariantReport(params: GetClicksVariantReportApiParams): GatewayResponse<ECommClicksVariantReportData[]> {
        const { authToken, dateFrom, dateTo, productId } = params;
        const response: ApiResponse<ECommClicksVariantReportData[]> = await this.api.get(`/clicks/product/${productId}`, {
            dateFrom,
            dateTo,
        }, getHeaders(authToken));

        return this.process(response);
    }

    async getBuyNowClicksReport(params: GetClicksReportApiParams): GatewayResponse<ECommBuyNowClicksReportData> {
        const response: ApiResponse<ECommBuyNowClicksReportData> = await this.api.get('/buyNow', {
            dateFrom: params.dateFrom,
            dateTo: params.dateTo,
        }, getHeaders(params.authToken));

        return this.process(response);
    }

    async getBuyNowClicksProductReport(params: GetClicksProductReportApiParams): GatewayResponse<ECommBuyNowClicksProductReportData> {
        const { authToken, dateFrom, dateTo, productCategoryId } = params;
        const response: ApiResponse<ECommBuyNowClicksProductReportData> = await this.api.get(`/buyNow/productCategory/${productCategoryId}`, {
            dateFrom,
            dateTo,
        }, getHeaders(authToken));

        return this.process(response);
    }

    async getBuyNowClicksVariantReport(params: GetBuyNowClicksVariantReportApiParams): GatewayResponse<ECommBuyNowClicksVariantReportData[]> {
        const { authToken, dateFrom, dateTo, productId } = params;
        const response: ApiResponse<ECommBuyNowClicksVariantReportData[]> = await this.api.get(`/buyNow/product/${productId}`, {
            dateFrom,
            dateTo,
        }, getHeaders(authToken));

        return this.process(response);
    }

    async getActivitiesReport(params: GetClicksReportApiParams): GatewayResponse<ECommActivitiesReportData> {
        const { dateFrom, dateTo, authToken } = params;

        const response: ApiResponse<ECommActivitiesReportData> = await this.api.get(
            '/activities',
            { dateFrom,
                dateTo },
            getHeaders(authToken),
        );

        return this.process(response);
    }

    async getFunnelReport(params: GetFunnelReportApiParams): GatewayResponse<ECommFunnelReportData> {
        const { authToken, dateFrom, dateTo, productCategoryId } = params;

        const response: ApiResponse<ECommFunnelReportData> = await this.api.get('/funnel', {
            dateFrom,
            dateTo,
            productCategoryId,
        }, getHeaders(authToken));

        return this.process(response);
    }
}
