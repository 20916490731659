import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import IQOSDateRangePicker from 'components/IQOSDateRangePicker';

interface DatePickerProps {
    dateTo: Date;
    dateFrom: Date;
    onChange: (dateFrom: Date, dateTo: Date) => void;
}

const DatePicker = (props: DatePickerProps): JSX.Element => {
    const { dateFrom, dateTo, onChange } = props;

    const [localDateFrom, setLocalDateFrom] = useState<Date>(dateFrom);
    const [localDateTo, setLocalDateTo] = useState<Date>(dateTo);

    useEffect(() => {
        // we need to do this ridiculous local dates here in useState because the datePicker doesn't
        // support dispatching redux actions.
        if (localDateFrom && localDateTo) onChange(localDateFrom, localDateTo);
    }, [localDateFrom, localDateTo]);

    return (
        <DatePickerWrapper>
            <IQOSDateRangePicker
                dateFrom={localDateFrom}
                dateTo={localDateTo}
                setDateFrom={setLocalDateFrom}
                setDateTo={setLocalDateTo}
            />
        </DatePickerWrapper>
    );
};

const DatePickerWrapper = styled.div`
    display: flex;

    justify-content: flex-end;
    gap: 30px;
    margin-right: 4%;

    @media (max-width: 1580px) {
        height: 50px;
    }
`;

export default DatePicker;
