import { put, takeEvery, call, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import AuthGateway from 'api/Auth';
import Actions from 'redux/Actions';
import Utils from 'lib/Utils';
import { GatewayResponseStatus } from 'api/types/types';
import NavActions from 'lib/NavActions';
import dayjs from 'dayjs';

export default function* watchStartup(api: AuthGateway): SagaWatcherReturnType {
    yield takeEvery('auth/authStartupAttempt', handleStartup, api);
}

function* handleStartup(api: AuthGateway) {
    const authToken = Utils.Auth.getAuthToken();

    if (window.navigator.appVersion.indexOf('Win') !== -1) {
        yield put(Actions.setDetectOS('Windows'));
    } else if (window.navigator.appVersion.indexOf('Mac') !== -1) {
        yield put(Actions.setDetectOS('MacOS'));
    } else if (window.navigator.appVersion.indexOf('X11') !== -1) {
        yield put(Actions.setDetectOS('UNIX'));
    } else if (window.navigator.appVersion.indexOf('Linux') !== -1) {
        yield put(Actions.setDetectOS('Linux'));
    }

    const resetAuthLocalStorage = () => {
        Utils.Auth.clearAuthToken();
        Utils.Auth.clearUserName();
        Utils.Auth.clearExpiresAt();

        NavActions.navResetToLogin();
    };

    if (authToken) {
        if ((Number(Utils.Auth.getExpiresAt()) > dayjs().unix())) {
            if ((Utils.Auth.getIsAuthTokenTemp() === 'false')) {
                const response = yield* call([api, api.refresh], { authToken });

                if (response.status === GatewayResponseStatus.Success) {
                    if (response.data) {
                        Utils.Auth.storeAuthToken(response.data.tokens.access.token);
                        Utils.Auth.storeExpiresAt(response.data.tokens.access.expiresAt);
                        Utils.Auth.storeIsAuthTokenTemp((response.data.tokens.access.temp).toString());
                        yield* put(Actions.authLoginSuccess(response.data.tokens.access.token));
                    } else {
                        yield put(Actions.authLoginFailure('Something went wrong. Please try again.'));
                    }
                }

                if (response.status === GatewayResponseStatus.Error) {
                    resetAuthLocalStorage();

                    yield put(Actions.authStartupFailure(response.message));
                }
            } else {
                yield put(Actions.authLoginSuccess(authToken));
                yield put(Actions.getUserClaimsAttempt());
            }
        } else {
            resetAuthLocalStorage();
        }
    } else {
        resetAuthLocalStorage();
    }
    yield put(Actions.authStartupSuccess());
}
