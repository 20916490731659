import UsernameIcon from './Username.svg';
import EyeIcon from './Eye.svg';
import LockIcon from './Lock.svg';
import LogOutIcon from './Logout.svg';
import SearchIcon from './Search.svg';
import PlusIcon from './Plus.svg';
import TrashIcon from './Trash.svg';
import PencilIcon from './Pencil.svg';
import UpArrow from './UpArrow.svg';
import DownArrow from './DownArrow.svg';
import Calendar from './CalendarIcon.svg';
import ChevronUp from './ChevronUp.svg';
import ChevronRight from './ChevronRight.svg';
import ChevronLeft from './ChevronLeft.svg';
import CloseCircle from './CloseCircle.svg';
import Pdf from './Pdf.svg';
import Image from './Image.svg';
import Text from './Text.svg';
import Video from './Video.svg';
import ChevronLeftDouble from './chevronLeftDouble.svg';
import ChevronRightDouble from './chevronRightDouble.svg';
import Clipboard from './clipboard.svg';
import Edit from './edit.svg';
import Graph from './graph.svg';
import Undo from './undo.svg';
import IqosIcon from './IQOSicon.svg';
import Upload from './upload.svg';
import VideoCamera from './videocamera.svg';
import Clock from './clock.svg';
import QuestionMark from './questionmark.svg';
import UploadImage from './uploadImage.svg';
import RightArrow from './rightarrow.svg';
import ChoosingArrow from './choosingarrow.svg';
import ViewImage from './view.svg';
import Download from './download.svg';
import Details from './details.svg';
import Tick from './tick.svg';
import BarChart from './barChartIcon.svg';
import FeatherEdit from './featherEditIcon.svg';
import Save from './save.svg';
import Cancel from './cancel.svg';
import Send from './send.svg';
import AnalyticsGraph from './analyticsGraph.svg';
import AnalyticsGraphWhite from './analyticsGraphWhite.svg';

import ArrowUpV2 from './newArrowUpIcon.svg';
import ArrowDownV2 from './newArrowDownIcon.svg';
import TrashV2 from './newTrashIcon.svg';
import AddV2 from './newAddIcon.svg';

import IqosIconWithoutName from './iqosIconWithoutName.svg';
import Star from './star.svg';
import StarOutline from './starOutline.svg';

export default {
    BarChart,
    FeatherEdit,
    Calendar,
    UsernameIcon,
    EyeIcon,
    LockIcon,
    LogOutIcon,
    SearchIcon,
    PlusIcon,
    TrashIcon,
    PencilIcon,
    UpArrow,
    DownArrow,
    ChevronUp,
    ChevronRight,
    ChevronLeft,
    CloseCircle,
    Pdf,
    Image,
    Text,
    Video,
    ChevronLeftDouble,
    ChevronRightDouble,
    Clipboard,
    Edit,
    Graph,
    Undo,
    IqosIcon,
    Upload,
    VideoCamera,
    Clock,
    QuestionMark,
    UploadImage,
    RightArrow,
    ChoosingArrow,
    ViewImage,
    Download,
    Details,
    Tick,
    Send,
    AnalyticsGraph,
    AnalyticsGraphWhite,

    ArrowUpV2,
    ArrowDownV2,
    TrashV2,
    AddV2,
    Save,
    Cancel,

    IqosIconWithoutName,
    Star,
    StarOutline,
};
