import { fork } from 'redux-saga/effects';

import PostsGateway from 'api/Posts';

import { RootSagaReturnType } from 'sagas/types';

import watchGetPostsOverview from './getPostsOverview';
import watchGetRateByCategories from './getRateByCategories';
import watchGetRateByPostingTime from './getRateByPostingTime';
import watchGetRateByPost from './getRateByPost';
import watchGetPostsReport from './getPostsReport';
import watchEditPostsReport from './editPostsReport';
import watchGetPerformingPosts from './getPerformingPosts';
import watchGetRateByPostingTimeModalInfo from './getRateByPostingTimeModalInfo';
import watchGetRateByCategoriesModalInfo from './getRateByCategoriesModalInfo';
import watchGetRateByPostModalInfo from './getRateByPostModalInfo';
import watchGetRateByCategoriesExport from './getRateByCategoriesExport';
import watchGetRateByPostingTimeExport from './getRateByPostingTimeExport';
import watchGetRateByPostExport from './getRateByPostExport';
import watchGetPostsReportExport from './getPostsReportExport';
import watchGetPostsReportWithAmountOfDays from './getPostsReportWithAmountOfDays';
import watchGetUserClaims from './getUserClaims';
import watchEditToggleColumns from './editToggleColumn';
import watchGetSummaryDashboardDetailsStatus from './getSummaryDashboardDetails';

export default (api: PostsGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetPostsOverview, api);
        yield fork(watchGetRateByCategories, api);
        yield fork(watchGetRateByCategoriesModalInfo, api);
        yield fork(watchGetRateByCategoriesExport, api);
        yield fork(watchGetRateByPostingTime, api);
        yield fork(watchGetRateByPostingTimeModalInfo, api);
        yield fork(watchGetRateByPostingTimeExport, api);
        yield fork(watchGetRateByPost, api);
        yield fork(watchGetRateByPostModalInfo, api);
        yield fork(watchGetRateByPostExport, api);
        yield fork(watchGetPostsReport, api);
        yield fork(watchGetPostsReportExport, api);
        yield fork(watchEditPostsReport, api);
        yield fork(watchGetPostsReportWithAmountOfDays, api);
        yield fork(watchGetPerformingPosts, api);
        yield fork(watchGetUserClaims, api);
        yield fork(watchEditToggleColumns, api);
        yield fork(watchGetSummaryDashboardDetailsStatus, api);
    }

    return {
        rootSaga,
    };
};
