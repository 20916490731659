import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { toast } from 'react-toastify';

import ECommerceGateway from 'api/ECommerce';
import { SetCTAButtonDetailsParams } from 'redux/slices/eCommerce/types';

export default function* watchSetCTAButtonDetails(api: ECommerceGateway): SagaWatcherReturnType {
    yield takeEvery('eCommerce/setCTAButtonDetailsAttempt', handleSetCTAButtonDetails, api);
}

function* handleSetCTAButtonDetails(api: ECommerceGateway, data: SetCTAButtonDetailsParams) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const { title, message, file, clearImage, buttonText, link, language } = data.payload;

    const response = yield* call([api, api.setCTAButtonDetails], {
        authToken,
        title,
        message,
        file,
        clearImage,
        buttonText,
        link,
        language,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.setCTAButtonDetailsFailure(response.message));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.setCTAButtonDetailsSuccess());
        yield put(Actions.getCTAButtonDetailsAttempt());
        toast.success('Message Saved Successfully');
    }
}
