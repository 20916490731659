import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Dropdown, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import FsLightbox from 'fslightbox-react';

import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';
import { connect } from 'react-redux';
import Actions from 'redux/Actions';

import Fonts from 'assets/themes/Fonts';
import Utils from 'lib/Utils';
import IQOSPaginator from 'components/IQOSPaginator';
import { IChannelTypeEnum } from 'entities/contests';
import { CategoryIdEnum, EditToggleColumns, InformationPostModalInfo, PostDetailsModalClaimsEnum, ReportEnum, UserClaims } from 'entities/posts';

interface InformationPostProps {
    isLanu: boolean;
    informationPostIndex: number;
    dataToDisplay: InformationPostModalInfo;
    exportType: number;
    getDateFrom: Date;
    getDateTo: Date;
    getWeekDateFrom?: string;
    getWeekDateTo?: string;
    getInformationPostModalIsOpen: boolean;
    getCurrentGraphCategory: string | undefined;
    getCurrentGraphWeek: string | undefined;
    getCurrentGraphHour: string | undefined;
    getUserClaimsRedux: UserClaims;
    isRateByCategoriesLoading: boolean;
    isRateByPostingTimeLoading: boolean;
    isRateByPostLoading: boolean;

    setInformationPostModalIsOpen(data: boolean): void;
    setInformationPostIndex(index: number): void;
    getRateByCategoriesExport(category: CategoryIdEnum, channelType: string, dateFrom: string, dateTo: string): void;
    getRateByPostingTimeExport(hour: number, channelType: string, dateFrom: string, dateTo: string): void;
    getRateByPostExport(week: number, channelType: string, dateFrom: string, dateTo: string): void;
    editToggleColumn(data: EditToggleColumns): void;
}

const InformationPost: FunctionComponent<InformationPostProps> = (props: InformationPostProps) => {
    const {
        isLanu,
        informationPostIndex,
        dataToDisplay,
        exportType,
        getDateFrom,
        getDateTo,
        getWeekDateFrom,
        getWeekDateTo,
        getInformationPostModalIsOpen,
        getCurrentGraphCategory,
        getCurrentGraphWeek,
        getCurrentGraphHour,
        getUserClaimsRedux,
        isRateByCategoriesLoading,
        isRateByPostingTimeLoading,
        isRateByPostLoading,

        setInformationPostModalIsOpen,
        setInformationPostIndex,
        getRateByCategoriesExport,
        getRateByPostingTimeExport,
        getRateByPostExport,
        editToggleColumn,
    } = props;

    const filterData = getUserClaimsRedux.userRemovedColumnClaims.postDetailsModal;

    const [columnFilterIsOpen, setColumnFilterIsOpen] = useState<boolean>(false);
    const [toggler, setToggler] = useState<boolean>(false);

    const [currentLightboxImage, setCurrentLightboxImage] = useState<string>('');

    const [postedOnCheck, setPostedOnCheck] = useState<boolean>(!(filterData.includes(PostDetailsModalClaimsEnum.PostedOn)));
    const [viewsCheck, setViewsCheck] = useState<boolean>(!(filterData.includes(PostDetailsModalClaimsEnum.Views)));
    const [viewRateCheck, setViewRateCheck] = useState<boolean>(!(filterData.includes(PostDetailsModalClaimsEnum.ViewRate)));
    const [engagementCheck, setEngagementCheck] = useState<boolean>(!(filterData.includes(PostDetailsModalClaimsEnum.Engagement)));
    const [engagementRateCheck, setEngagementRateCheck] = useState<boolean>(!filterData.includes(PostDetailsModalClaimsEnum.EngagementRate));

    useEffect(() => {
        if (getInformationPostModalIsOpen === false) {
            setInformationPostIndex(1);
        }
    }, [getInformationPostModalIsOpen]);

    const toggleColumn = (columnEnum: PostDetailsModalClaimsEnum, removeValue: boolean) => {
        editToggleColumn({
            reportEnum: ReportEnum.PostDetailsModal,
            column: columnEnum,
            remove: removeValue,
        });
    };

    const exportData = () => {
        if (exportType === 1) {
            if (getCurrentGraphCategory) {
                getRateByCategoriesExport(Utils.Posts.categoryStringToEnumConverter(getCurrentGraphCategory), `${(isLanu) ? IChannelTypeEnum.LANU : IChannelTypeEnum.LAU}`, getDateFrom.toISOString(), getDateTo.toISOString());
            }
        }

        if (exportType === 2) {
            getRateByPostingTimeExport(Utils.Posts.hourStringToNumberConverter(getCurrentGraphHour), `${(isLanu) ? IChannelTypeEnum.LANU : IChannelTypeEnum.LAU}`, getDateFrom.toISOString(), getDateTo.toISOString());
        }

        if (exportType === 3 && getWeekDateFrom && getWeekDateTo) {
            getRateByPostExport((Number(getCurrentGraphWeek?.substring(0, 8).replace(/\D+/g, ''))), `${(isLanu) ? IChannelTypeEnum.LANU : IChannelTypeEnum.LAU}`, getWeekDateFrom, getWeekDateTo);
        }
    };

    const renderInformationPostTable = () => {
        if (isRateByCategoriesLoading || isRateByPostingTimeLoading || isRateByPostLoading) {
            return (
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <Spinner />
                </div>
            );
        }

        return (
            dataToDisplay && (
                dataToDisplay.data.map((item) => {
                    const { postId, title, date, mediaUrl, views, viewRate, engagement, engagementRate } = item;

                    return (
                        <StyledTr key={postId}>
                            <StyledTd title={title} style={{ textAlign: 'left', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '50px', minWidth: '420px', maxWidth: 'auto' }}>
                                <TitleWrapper>{title}</TitleWrapper>
                                {(mediaUrl && mediaUrl !== '') ? (
                                    <div>
                                        <StyledLightBox onClick={() => [setToggler(!toggler), setCurrentLightboxImage(mediaUrl)]}>
                                            <img src={mediaUrl} alt='iqos' style={{ width: '41px', height: '41px' }} />
                                        </StyledLightBox>
                                    </div>
                                ) : (
                                    <div style={{ width: '41px', height: '41px' }} />
                                )}
                            </StyledTd>

                            {(postedOnCheck) && (
                                <StyledTd style={{ minWidth: '100px', maxWidth: '100px' }}>
                                    {dayjs(date).format('DD-MM-YYYY')}
                                </StyledTd>
                            )}

                            {(viewsCheck) && (
                                <StyledTd>
                                    {views}
                                </StyledTd>

                            )}

                            {(viewRateCheck) && (
                                <StyledTd>
                                    {(viewRate !== undefined) ? `${Utils.Posts.rateConverter(viewRate)}%` : '-'}
                                </StyledTd>
                            )}

                            {(engagementCheck) && (
                                <StyledTd>
                                    {engagement}
                                </StyledTd>
                            )}

                            {(engagementRateCheck) && (
                                <StyledTd>
                                    {(engagementRate !== undefined) ? `${Utils.Posts.rateConverter(engagementRate)}%` : '-'}
                                </StyledTd>
                            )}
                        </StyledTr>
                    );
                })
            )
        );
    };

    const renderBody = () => {
        return (
            <MainWrapper>
                <div style={{ width: '95%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}>
                    <div style={{ display: 'flex', gap: '35px', justifyContent: 'space-between', alignItems: 'center' }}>
                        {exportType === 1 && (
                            <StyledText id='modalHeader'>{getCurrentGraphCategory}</StyledText>
                        )}
                        {exportType === 2 && (
                            <StyledText id='modalHeader'>{getCurrentGraphHour}</StyledText>
                        )}
                        {exportType === 3 && (
                            <StyledText style={{ fontSize: '27px' }} id='modalHeader'>{`${getCurrentGraphWeek}`}</StyledText>
                        )}
                    </div>
                    <ButtonWrapper>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginRight: '20px', color: 'gray' }}>
                            <div>Date Range</div>
                            <div>
                                <StyledText style={{ fontSize: '15px', color: 'black', fontFamily: `${Fonts.secondary}` }}>
                                    {`${dayjs(getDateFrom).format('DD/MM/YYYY')}`}
                                </StyledText>
                                {' to '}
                                <StyledText style={{ fontSize: '15px', color: 'black', fontFamily: `${Fonts.secondary}` }}>
                                    {`${dayjs(getDateTo).format('DD/MM/YYYY')}`}
                                </StyledText>
                            </div>
                        </div>
                        <Dropdown isOpen={columnFilterIsOpen} toggle={() => setColumnFilterIsOpen(!columnFilterIsOpen)}>
                            <DropdownToggle caret style={styles.filterButton}>
                                <div style={{ paddingLeft: '10px', marginRight: '10px', overflowX: 'hidden' }}>
                                    Show All
                                </div>
                            </DropdownToggle>
                            <DropdownMenu container='body' style={{ width: '150px', textAlign: 'center', background: 'white ' }}>
                                <FormGroup style={{ paddingLeft: '15px' }}>
                                    <FormControlLabel control={<Checkbox size='small' checked={postedOnCheck} onChange={() => [setPostedOnCheck(!postedOnCheck), toggleColumn(PostDetailsModalClaimsEnum.PostedOn, postedOnCheck)]} />} label='Posted On' />
                                    <FormControlLabel control={<Checkbox size='small' checked={viewsCheck} onChange={() => [setViewsCheck(!viewsCheck), toggleColumn(PostDetailsModalClaimsEnum.Views, viewsCheck)]} />} label='Views' />
                                    <FormControlLabel control={<Checkbox size='small' checked={viewRateCheck} onChange={() => [setViewRateCheck(!viewRateCheck), toggleColumn(PostDetailsModalClaimsEnum.ViewRate, viewRateCheck)]} />} label='View Rate' />
                                    <FormControlLabel control={<Checkbox size='small' checked={engagementCheck} onChange={() => [setEngagementCheck(!engagementCheck), toggleColumn(PostDetailsModalClaimsEnum.Engagement, engagementCheck)]} />} label='Engagement' />
                                    <FormControlLabel control={<Checkbox size='small' checked={engagementRateCheck} onChange={() => [setEngagementRateCheck(!engagementRateCheck), toggleColumn(PostDetailsModalClaimsEnum.EngagementRate, engagementRateCheck)]} />} label='Eng. Rate' />
                                </FormGroup>
                            </DropdownMenu>
                        </Dropdown>
                        <StyledButton onClick={() => exportData()} isPrimary>
                            Export Data
                        </StyledButton>
                    </ButtonWrapper>
                </div>
                <StyledTable>
                    <StyledTHead>
                        <tr>
                            <StyledTh style={{ width: '430px' }}>Post Title</StyledTh>
                            {(postedOnCheck) && (
                                <StyledTh>Posted On</StyledTh>
                            )}
                            {(viewsCheck) && (
                                <StyledTh>Views</StyledTh>
                            )}
                            {(viewRateCheck) && (
                                <StyledTh>View Rate</StyledTh>
                            )}
                            {(engagementCheck) && (
                                <StyledTh>Engagement</StyledTh>
                            )}
                            {(engagementRateCheck) && (
                                <StyledTh>Eng. Rate</StyledTh>
                            )}
                        </tr>
                    </StyledTHead>
                    <StyledTableBody>
                        {renderInformationPostTable()}
                    </StyledTableBody>
                </StyledTable>

                <FsLightbox
                    toggler={(toggler)}
                    sources={[currentLightboxImage]}
                />
            </MainWrapper>
        );
    };

    return (
        <Modal isOpen={getInformationPostModalIsOpen} toggle={() => setInformationPostModalIsOpen(!getInformationPostModalIsOpen)} centered size='xl' fullscreen='sm' zIndex={100}>
            <ModalBody>
                {renderBody()}
            </ModalBody>

            {(dataToDisplay.data.length > 0) && (
                <ModalFooter style={{ justifyContent: 'flex-end', border: 'none', marginRight: '22px' }}>
                    <IQOSPaginator
                        maxIndex={dataToDisplay.maxIndex}
                        setIndex={setInformationPostIndex}
                        currentIndex={informationPostIndex}
                        url='/analytics/posts'
                        containerId='modalHeader'
                    />
                </ModalFooter>
            )}
        </Modal>
    );
};

const MainWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StyledTable = styled.table`
    width: 95%;
    background-color: white;
    border-collapes: collapse;
    table-layout: fixed;
    
    margin-top: 20px;
    border-radius: 10px !important;
`;

const StyledTh = styled.th`
    padding: 20px;
    border-bottom: 1px solid rgb(0,0,0,0,3);
    font-size: 15px;
    color: #959595;
    font-weight: lighter;
    text-align: center;
    background-color: #FFFDFB;

    &:first-of-type {
        text-align: left;
        width: 400px;
    }
`;

const StyledTr = styled.tr`
    background-color: #F7F7F7;

    &:not(:last-of-type){
        border-bottom: 5px solid #FFFDFB;
    }

    &:nth-last-of-type(1){
        border-radius: 0px 0px 10px 10px !important;
    }

    &:nth-of-type(odd){
        background-color: #FFFDFB;
    }
`;

const StyledTd = styled.td`
    padding: 20px;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
`;

const StyledButton = styled.button<{isPrimary?: boolean}>`
    background-color: ${props => (props.isPrimary ? '#00D1D2' : 'white')};
    border: ${props => (props.isPrimary ? '1px solid #00D1D2' : '1px solid black')};
    border-radius: 20px;

    color: ${props => (props.isPrimary ? 'white' : 'black')};

    width: 160px;
    height: 40px;

    transition: all 0.1s ease;
    
    font-family: ${Fonts.secondary}
    font-size: 16px;
    text-align: center;

    &:active {
        color: ${props => (props.isPrimary ? 'black' : '#00D1D2')};
    }
`;

const StyledText = styled.text`
    font-size: 33px;
    font-family: ${Fonts.primary}
`;

const StyledTHead = styled.thead`
    text-align: center;
    border-bottom: 2px solid #959595;
`;

const StyledTableBody = styled.tbody`
    text-align: center;
`;

const TitleWrapper = styled.div`
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
`;

const StyledLightBox = styled.button`
    display: flex;
    align-items: center;
    border: none;
    background: transparent;
    height: 75%;
    width: 10%;
    font-size: 11px;
`;

const styles = {
    filterButton: {
        backgroundColor: 'transparent',
        fontSize: '18',
        fontFamily: 'Fonts.primary',
        color: 'black',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',

        width: '163px',
        height: '40px',

        border: '1px solid black',
        borderRadius: '20px',
        cursor: 'pointer',
    },
};

const mapStateToProps = (state: RootState) => ({
    isLanu: Selectors.getFaqGetIsLANU(state),
    informationPostIndex: Selectors.getPostsGetInformationPostIndex(state),
    getDateFrom: Selectors.getPostsGetDateFrom(state),
    getDateTo: Selectors.getPostsGetDateTo(state),
    getInformationPostModalIsOpen: Selectors.getPostsGetInformationPostModalIsOpen(state),
    getCurrentGraphCategory: Selectors.getPostsGetCurrentGraphCategory(state),
    getCurrentGraphWeek: Selectors.getPostsGetCurrentGraphWeek(state),
    getCurrentGraphHour: Selectors.getPostsGetCurrentGraphHour(state),
    getUserClaimsRedux: Selectors.getPostsGetUserClaimsRedux(state),
    isRateByCategoriesLoading: Selectors.getPostsGetRateByCategoriesAttempting(state),
    isRateByPostingTimeLoading: Selectors.getPostsGetRateByPostingTimeAttempting(state),
    isRateByPostLoading: Selectors.getPostsGetRateByPostAttempting(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setInformationPostModalIsOpen: (data: boolean) => dispatch(Actions.setInformationPostModalIsOpen(data)),
    setInformationPostIndex: (index: number) => dispatch(Actions.setInformationPostIndex(index)),
    getRateByCategoriesExport: (category: CategoryIdEnum, channelType: string, dateFrom: string, dateTo: string) => dispatch(Actions.getRateByCategoriesExportAttempt({ category, channelType, dateFrom, dateTo })),
    getRateByPostingTimeExport: (hour: number, channelType: string, dateFrom: string, dateTo: string) => dispatch(Actions.getRateByPostingTimeExportAttempt({ hour, channelType, dateFrom, dateTo })),
    getRateByPostExport: (week: number, channelType: string, dateFrom: string, dateTo: string) => dispatch(Actions.getRateByPostExportAttempt({ week, channelType, dateFrom, dateTo })),
    editToggleColumn: (data: EditToggleColumns) => dispatch(Actions.editToggleColumnsAttempt(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InformationPost);
