import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { GetSubscribersOverviewParams } from 'redux/slices/summaryDashboard/types';
import SummaryDashboardGateway from 'api/SummaryDashboard';

export default function* watchGetSubscribersOverviewStatus(api: SummaryDashboardGateway): SagaWatcherReturnType {
    yield takeEvery('summaryDashboard/getSubscribersOverviewAttempt', handleGetSubscribersOverview, api);
}

function* handleGetSubscribersOverview(api: SummaryDashboardGateway, data: GetSubscribersOverviewParams) {
    const { type, year } = data.payload;
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.getSubscribersOverview], {
        authToken,
        type,
        year,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.getSubscribersOverviewFailure(response.message || ''));
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.getSubscribersOverviewSuccess(response.data));
    }
}
