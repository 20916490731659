import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { connect } from 'react-redux';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { AppDispatch, RootState } from 'redux/store';

import Title from 'components/Title';
import ButtonText from 'components/ButtonText';
import StatCard from 'components/summaryDashboard/StatCardPercentage';

import { IChannelTypeEnum } from 'entities/contests';
import { SummaryDashboardDetails } from 'entities/summaryDashboard';
import IQOSDateRangePicker from 'components/IQOSDateRangePicker';

interface DashboardProps {
    isLanu: boolean;
    summaryDashboardDetailsData: SummaryDashboardDetails;

    getSummaryDashboardDetails(type: string, dateFrom: string, dateTo: string, isYtd: string): void;
}

const Dashboard: FunctionComponent<DashboardProps> = (props: DashboardProps) => {
    const {
        isLanu,
        summaryDashboardDetailsData,

        getSummaryDashboardDetails,
    } = props;

    const [isYtd, setIsYtd] = useState<boolean>(false);

    const [dateFrom, setDateFrom] = useState<Date | null>(dayjs().startOf('month').toDate());
    const [dateTo, setDateTo] = useState<Date | null>(dayjs().toDate());

    const getYear = new Date().getFullYear();
    const dateToday = dayjs().format('YYYY-MM-DDT00:00:00');

    useEffect(() => {
        const newDateFrom = dayjs(dateFrom).format('YYYY-MM-DDT00:00:00');
        const newDateTo = dayjs(dateTo).format('YYYY-MM-DDT00:00:00');
        if (dateTo) {
            if (isYtd) {
                getSummaryDashboardDetails(`${(isLanu) ? IChannelTypeEnum.LANU : IChannelTypeEnum.LAU}`, newDateFrom, dateToday, `${isYtd}`);
            } else {
                getSummaryDashboardDetails(`${(isLanu) ? IChannelTypeEnum.LANU : IChannelTypeEnum.LAU}`, newDateFrom, newDateTo, `${isYtd}`);
            }
        }
    }, [isLanu, dateTo, isYtd]);

    const cummulativeFollowers = () => {
        const { cumulativeFollowersCurrentDate, cumulativeFollowersPreviousDate, cumulativeFollowersChange } = summaryDashboardDetailsData;

        return (
            <StatCard
                label='Accumulative Followers'
                current={cumulativeFollowersCurrentDate}
                prev={cumulativeFollowersPreviousDate}
                diff={cumulativeFollowersChange}
                selectedYear={getYear}
                isYtd={isYtd}
            />
        );
    };

    const newFollowers = () => {
        const { newFollowersChange, newFollowersCurrentDate, newFollowersPreviousDate } = summaryDashboardDetailsData;

        return (
            <StatCard
                label='New Followers'
                current={newFollowersCurrentDate}
                prev={newFollowersPreviousDate}
                diff={newFollowersChange}
                selectedYear={getYear}
                isYtd={isYtd}
            />
        );
    };

    const nettFollowers = () => {
        const { netFollowersChange, netFollowersCurrentDate, netFollowersPreviousDate } = summaryDashboardDetailsData;

        return (
            <StatCard
                label='Net Followers'
                current={netFollowersCurrentDate}
                prev={netFollowersPreviousDate}
                diff={netFollowersChange}
                selectedYear={getYear}
                isYtd={isYtd}
            />
        );
    };

    const viewRate = () => {
        const {
            viewRateCurrentDate,
            viewRatePercentageChange,
            viewRatePreviousDate,
        } = summaryDashboardDetailsData;

        return (
            <StatCard
                label='View Rate'
                current={viewRateCurrentDate}
                prev={viewRatePreviousDate}
                diff={viewRatePercentageChange}
                selectedYear={getYear}
                isPercentage
                isYtd={isYtd}
            />
        );
    };

    const engagementRate = () => {
        const {
            engagementRateChange,
            engagementRateCurrentDate,
            engagementRatePreviousDate,
        } = summaryDashboardDetailsData;

        return (
            <StatCard
                label='Engagement Rate'
                current={engagementRateCurrentDate}
                prev={engagementRatePreviousDate}
                diff={engagementRateChange}
                selectedYear={getYear}
                isPercentage
                isYtd={isYtd}
            />
        );
    };

    const unsubscribeRate = () => {
        const {
            unsubscribeRate: currentUnsubscribeRate,
            unsubscribeRateInputDate,
            unsubscribeRatePreviousDate,
        } = summaryDashboardDetailsData;

        return (
            <StatCard
                label='Unsubscribe Rate'
                current={unsubscribeRateInputDate}
                prev={unsubscribeRatePreviousDate}
                diff={currentUnsubscribeRate}
                selectedYear={getYear}
                reverse
                isPercentage
                isYtd={isYtd}
            />
        );
    };

    const viewCount = () => {
        const {
            viewCountCurrentDate,
            viewCountPreviousDate,
            viewCountChange,
        } = summaryDashboardDetailsData;

        return (
            <StatCard
                label='View Count'
                current={viewCountCurrentDate}
                prev={viewCountPreviousDate}
                diff={viewCountChange}
                selectedYear={getYear}
                isYtd={isYtd}
            />
        );
    };

    const engagementCount = () => {
        const {
            engagementCountCurrentDate,
            engagementCountPreviousDate,
            engagementCountChange,
        } = summaryDashboardDetailsData;

        return (
            <StatCard
                label='Engagement Count'
                current={engagementCountCurrentDate}
                prev={engagementCountPreviousDate}
                diff={engagementCountChange}
                selectedYear={getYear}
                isYtd={isYtd}
            />
        );
    };

    return (
        <>
            <HeaderWrapper>
                <Title label='Summary Dashboard' />
                <DatePickerWrapper>
                    <ButtonText isYtd={isYtd} label='View Year To Date' onClick={() => setIsYtd(!isYtd)} isSelected={isYtd === true} />
                    <IQOSDateRangePicker
                        dateFrom={dateFrom}
                        dateTo={dateTo}
                        setDateFrom={setDateFrom}
                        setDateTo={setDateTo}
                        disabled={isYtd}
                    />
                </DatePickerWrapper>
            </HeaderWrapper>

            <DisplayWrapper>
                <RowGridWrapper>
                    {cummulativeFollowers()}
                    {newFollowers()}
                    {nettFollowers()}
                </RowGridWrapper>
                <RowGridWrapper>
                    {viewRate()}
                    {engagementRate()}
                    {unsubscribeRate()}
                </RowGridWrapper>
                <RowGridWrapper>
                    {viewCount()}
                    {engagementCount()}
                </RowGridWrapper>
            </DisplayWrapper>
        </>
    );
};

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-left: 1.6%;
    padding-bottom: 1%;
    align-items: center;

    @media (max-width: 1580px) {
        padding-bottom: 2%;
    }
`;

const DatePickerWrapper = styled.div`
    display: flex;
    width: 50%;
    flex-direction: row;
    justify-content: flex-end;
    gap: 30px;
    margin-right: 4%;

    @media (max-width: 1580px) {
        padding-top: 20px;
        width: 60%;
        height: 50px;
    }
`;

const DisplayWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    padding-left: 1.6%;

`;

const RowGridWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 31.5%);
    gap: 10px;
    width: 100%;
`;

const mapStateToProps = (state: RootState) => ({
    isLanu: Selectors.getFaqGetIsLANU(state),
    summaryDashboardDetailsData: Selectors.getSummaryDashboardGetSummaryDashboardDetails(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getSummaryDashboardDetails: (type: string, dateFrom: string, dateTo: string, isYtd: string) => dispatch(Actions.getSummaryDashboardDetailsAttempt({ type, dateFrom, dateTo, isYtd })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
