import { put, call, select, takeEvery } from 'typed-redux-saga/macro';
import { PayloadAction } from '@reduxjs/toolkit';

import { SagaWatcherReturnType } from 'sagas/types';

import Actions from 'redux/Actions';
import { GatewayResponseStatus } from 'api/types/types';

import Selectors from 'redux/Selectors';
import PostsGateway from 'api/Posts';
import { EditToggleColumns } from 'entities/posts';

export default function* watchEditToggleColumns(api: PostsGateway): SagaWatcherReturnType {
    yield takeEvery('posts/editToggleColumnsAttempt', hanldeEditToggleColumns, api);
}

function* hanldeEditToggleColumns(api: PostsGateway, data: PayloadAction<EditToggleColumns>) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const userClaimsData = yield* select(Selectors.getPostsGetUserClaims);

    const response = yield* call([api, api.editToggleColumn], {
        authToken,
        data: data.payload,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.editToggleColumnsFailure(response.message));
        return;
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.editToggleColumnsSuccess(response.data));
        yield put(Actions.getUserClaimsAttempt());
        yield put(Actions.setUserClaimsRedux(userClaimsData));
    }
}
