import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { toast } from 'react-toastify';

import ECommerceGateway from 'api/ECommerce';
import { EditProductCategoryParams } from 'redux/slices/eCommerce/types';

export default function* watchEditProductCategory(api: ECommerceGateway): SagaWatcherReturnType {
    yield takeEvery('eCommerce/editProductCategoryAttempt', handleEditProductCategory, api);
}

function* handleEditProductCategory(api: ECommerceGateway, data: EditProductCategoryParams) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const {
        id,
        categoryName,
        status,
        reorderBy,
        productListingMessageTitleEN,
        productListingMessageTitleBM,
        productListingMessageDescEN,
        productListingMessageDescBM,
    } = data.payload;

    const response = yield* call([api, api.editProductCategory], {
        authToken,
        id,
        categoryName,
        status,
        reorderBy,
        productListingMessageTitleEN,
        productListingMessageTitleBM,
        productListingMessageDescEN,
        productListingMessageDescBM,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.editProductCategoryFailure(response.message));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.editProductCategorySuccess());
        yield put(Actions.getProductCategoryAttempt());
        toast.success('Changes saved!');
    }
}
