import { put, call, select, takeEvery } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import GameGateway from 'api/Games';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { EditGameDetailsPayload } from 'redux/slices/games/types';
import { ContestListTypesEnum } from 'entities/contests';

export default function* watchEditGameDetails(api: GameGateway): SagaWatcherReturnType {
    yield takeEvery('games/editGameAttempting', handleEditGameDetails, api);
}

function* handleEditGameDetails(api: GameGateway, data: EditGameDetailsPayload) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.editGameDetails], {
        authToken,
        data: data.payload,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.editGameFailure(response.message || ''));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.editGameSuccess(data.payload));
        toast.success('Game status update success!');

        yield put(Actions.setActiveGamesListIndex(1));
        yield put(Actions.setInactiveGamesListIndex(1));
        const activeQuizIndex = yield* select(Selectors.contestGetActiveGamesListIndex);
        const inactiveQuizIndex = yield* select(Selectors.contestGetInactiveGamesListIndex);
        yield put(Actions.getActiveGamesListAttempt({ index: activeQuizIndex }));
        yield put(Actions.getInactiveGamesListAttempt({ index: inactiveQuizIndex }));
        yield put(Actions.contestSetEditContestModalState({ id: '', isOpen: false, type: ContestListTypesEnum.Game }));
    }
}
