import { put, call, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import AuthGateway from 'api/Auth';

import Actions from 'redux/Actions';
import Utils from 'lib/Utils';
import NavActions from 'lib/NavActions';
import { LoginActionPayload } from 'redux/slices/auth/types';
import { GatewayResponseStatus } from 'api/types/types';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

export default function* watchLogin(api: AuthGateway): SagaWatcherReturnType {
    yield takeEvery('auth/authLoginAttempt', handleLogin, api);
}

function* handleLogin(api: AuthGateway, data: LoginActionPayload) {
    const { userName, password, rememberMe } = data.payload;
    if (!userName.length || !password) {
        yield put(Actions.authLoginFailure('Please enter your username and password'));
        return;
    }

    const response = yield* call([api, api.login], { email: userName, password, rememberMe });

    if (response.status === GatewayResponseStatus.Error) {
        if (response.code === '401') {
            yield put(Actions.authLoginFailure('Invalid username or password!'));

            return;
        }

        yield put(Actions.authLoginFailure(response.message));
        return;
    }

    if (response.status === GatewayResponseStatus.Success) {
        if (!response.data) {
            yield put(Actions.authLoginFailure('Something went wrong. Please try again.'));
            return;
        }

        const { tokens, user } = response.data;
        if (tokens.access.token) {
            yield put(Actions.authLoginSuccess(tokens.access.token));
            Utils.Auth.storeAuthToken(tokens.access.token);
            Utils.Auth.storeUserName(user.name);
            Utils.Auth.storeExpiresAt(tokens.access.expiresAt);
            Utils.Auth.storeIsAuthTokenTemp((tokens.access.temp).toString());

            NavActions.navToSummaryDashboard();
        } else {
            yield put(Actions.authLoginFailure('Something went wrong. Please try again.'));
        }
    }
}
