import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';

export interface LoginParams {
    email: string;
    password: string;
    rememberMe: boolean;
}

export interface LoginResponse {
    user: {
        role: string;
        isEmailVerified: boolean;
        email: string;
        name: string;
        id: string;
    };
    tokens: {
        access: {
            token: string;
            expiresAt: string;
            temp: boolean;
        };
    }
}

export interface RefreshParam {
    authToken: string;
}

export abstract class IAuthGateway extends Gateway {
    abstract login(params: LoginParams): GatewayResponse<LoginResponse | null>;

    abstract refresh(params:RefreshParam): GatewayResponse<LoginResponse | null>;
}
