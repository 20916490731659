import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import BroadcastGateway from 'api/Broadcast';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { toast } from 'react-toastify';
import { GetHistoryDetailsExportParams } from 'redux/slices/broadcast/types';
import xlsx, { IContent } from 'json-as-xlsx';
import dayjs from 'dayjs';

export default function* watchGetHistoryDetailsExportStatus(api: BroadcastGateway): SagaWatcherReturnType {
    yield takeEvery('broadcast/getHistoryDetailsExportAttempt', handleGetHistoryDetailsExport, api);
}

function* handleGetHistoryDetailsExport(api: BroadcastGateway, data: GetHistoryDetailsExportParams) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.getHistoryDetailsExport], {
        authToken,
        broadcastHistoryId: data.payload.id,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.getHistoryDetailsExportFailure(response.message || ''));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        const massagedData = response.data.map((item) => {
            return {
                firstName: item.firstName,
                lastName: item.lastName,
                userName: item.username,
                phoneNumber: item.phoneNumber,
                recipientTelegramId: item.recipientTelegramId,
                sent: (item.sent === true) ? 'Success' : 'Failed',
                sentAt: (item.sent === true) ? dayjs(item.sentAt).format('DD-MMM-YYYY') : '-',
                errorMessage: item.errorMessage,
            };
        });

        interface StringDictionary {
            [key: string]: any;
        }

        const excelColumns = [{
            label: 'Telegram ID',
            value: 'recipientTelegramId',
        }, {
            label: 'Message ID',
            value: 'messageId',
        }, {
            label: 'First Name',
            value: 'firstName',
        }, {
            label: 'Last Name',
            value: 'lastName',
        }, {
            label: 'Username',
            value: 'userName',
        }, {
            label: 'Phone Number',
            value: 'phoneNumber',
        }, {
            label: 'Posted On',
            value: 'sentAt',
        }, {
            label: 'Received Status',
            value: 'sent',
        }, {
            label: 'Error Message',
            value: 'errorMessage',
        }];

        let historyDetailsDataToExport: IContent[] = [];
        historyDetailsDataToExport = massagedData as unknown as StringDictionary[];

        const historyDetailsToDownload = [{
            sheet: 'Post Data',
            columns: excelColumns,
            content: historyDetailsDataToExport,
        }];

        xlsx(historyDetailsToDownload, { fileName: `History Details Data (ID: ${data.payload.id} )` });

        yield put(Actions.getHistoryDetailsExportSuccess(response.data));
    }
}
