import React from 'react';
import styled from 'styled-components';

interface FlexProps {
    children?: React.ReactNode,
    flexDirection?: 'row' | 'column',
    justifyContent?:
      | 'flex-start'
      | 'flex-end'
      | 'center'
      | 'space-between'
      | 'space-around'
      | 'initial'
      | 'inherit',
    flexWrap?: 'wrap' | 'nowrap' | 'wrap-reverse',
    alignItems?:
      | 'stretch'
      | 'center'
      | 'flex-start'
      | 'flex-end'
      | 'baseline'
      | 'initial'
      | 'inherit',
    flexGrow?: number,
    flexShrink?: number,
    flexBasis?: number,
    gap?: string,
    flex?: string,
    padding?: string,
    margin?: string,
    width?: string,
    height?: string,
    maxWidth?: string,
    maxHeight?: string,
    bgColor?: string,
    test?: boolean,
    style?: React.CSSProperties,
}

const Flex = (props:FlexProps):React.ReactElement => {
    const {
        justifyContent,
        flexDirection,
        flexGrow,
        flexBasis,
        flexShrink,
        flexWrap,
        flex,
        gap,
        alignItems,
        margin,
        padding,
        height,
        width,
        maxWidth,
        maxHeight,
        children,
        bgColor,
        test,
        style,
    } = props;

    return (
        <StyledFlex
            test={test}
            style={{
                display: 'flex',
                gap,
                justifyContent,
                flexDirection,
                flexGrow,
                flexBasis,
                flexShrink,
                flexWrap,
                flex,
                alignItems,
                margin,
                padding,
                width,
                height,
                maxWidth,
                maxHeight,
                backgroundColor: `${bgColor}`,
                ...style,
            }}
        >
            {children}
        </StyledFlex>
    );
};

interface StyledFlexProps {
    test?: boolean;
}

const StyledFlex = styled.div<StyledFlexProps>`

    * {
        ${(props => props.test && 'background-color: rgba(255, 0, 0, 0.3) !important;')}
    }

`;

Flex.defaultProps = {
    children: undefined,
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flexGrow: 0,
    flexBasis: 'auto',
    flexShrink: 1,
    gap: '0',
    flexWrap: 'nowrap',
    flex: '0 1 auto',
    alignItems: 'stretch',
    margin: '0',
    padding: '0',
    width: null,
    height: '',
    maxWidth: 'none',
    maxHeight: 'none',
    bgColor: 'transparent',
    test: false,
};

export default Flex;
