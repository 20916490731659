import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { connect } from 'react-redux';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { AppDispatch, RootState } from 'redux/store';

import Fonts from 'assets/themes/Fonts';

import { BroadcastOverview, BroadcastTypesEnum } from 'entities/broadcast';

import { format } from 'date-fns';
import MonthPicker from 'components/MonthPicker';

interface OverviewProps {
    getBroadcastOverview(date: string): void;

    broadcastOverviewData: BroadcastOverview;
}

const Overview: FunctionComponent<OverviewProps> = (props: OverviewProps) => {
    const {
        getBroadcastOverview,

        broadcastOverviewData,
    } = props;

    dayjs.extend(utc);

    const [getMonth, setGetMonth] = useState<number>((dayjs().month()) + 1);
    const [getYear, setGetYear] = useState<number>(dayjs().year());

    const dayjsMonthValue = dayjs().month(getMonth - 1).toDate();
    const dayjsToFNSdate = format(dayjsMonthValue, 'MM');

    useEffect(() => {
        getBroadcastOverview(`${getYear}-${dayjsToFNSdate}`);
    }, [getMonth, getYear]);

    const broadcastOverviewPost = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', paddingRight: '20px' }}>

                    <StyledText style={{ paddingLeft: '20px' }}>Broadcast Overview</StyledText>
                    <MonthPicker
                        month={getMonth}
                        year={getYear}
                        setMonth={setGetMonth}
                        setYear={setGetYear}
                        isYtd={false}
                    />

                </div>
                <StyledOverview>
                    <OverviewWrapper>
                        <InfoWrapper>
                            <StyledText style={{ color: 'white', fontFamily: `${Fonts.secondary}` }}>
                                {`Total Posts Sent (${dayjs(getMonth.toString()).format('MMMM')} ${dayjs(getYear.toString()).format('YYYY')})`}
                            </StyledText>
                            <StyledText id='recurringPostAnchor' style={{ color: 'white', fontFamily: `${Fonts.secondary}`, fontSize: '70px' }}>
                                {broadcastOverviewData.postsSent}
                            </StyledText>
                        </InfoWrapper>
                    </OverviewWrapper>

                    <OverviewWrapper>
                        <InfoWrapper>
                            <StyledText style={{ color: 'white', fontFamily: `${Fonts.secondary}` }}>
                                {`Total Posts Sent (${dayjs(getYear.toString()).format('YYYY')})`}
                            </StyledText>
                            <StyledText style={{ color: 'white', fontFamily: `${Fonts.secondary}`, fontSize: '70px' }}>
                                {broadcastOverviewData.ytdPostsSent}
                            </StyledText>
                        </InfoWrapper>
                    </OverviewWrapper>
                </StyledOverview>
            </div>
        );
    };

    return (
        broadcastOverviewPost()
    );
};

const StyledOverview = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3%;

    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 3%;
    align-items: center;
`;

const OverviewWrapper = styled.div`
    background-color: #003333;
    min-height: 203px;
    border-radius: 15px;
    padding: 35px 0px 20px 45px;
`;

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    gap: 10px;
`;

const StyledText = styled.text`
    font-size: 20px;
    color: #27242E;
    font-family: ${Fonts.primary};
    font-weight: bold;
`;

const mapStateToProps = (state: RootState) => ({
    getBroadcastId: Selectors.getBroadcastBroadcastId(state),
    broadcastOverviewData: Selectors.getBroadcastGetBroadcastOverview(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setBroadcastType: (data: BroadcastTypesEnum) => dispatch(Actions.setBroadcastType(data)),
    getBroadcastOverview: (date: string) => dispatch(Actions.getBroadcastOverviewAttempt({ date })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
