import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { toast } from 'react-toastify';
import ECommerceGateway from 'api/ECommerce';
import { GetProductDetailsParams } from 'redux/slices/eCommerce/types';
import NavActions from 'lib/NavActions';

export default function* watchGetProductDetails(api: ECommerceGateway): SagaWatcherReturnType {
    yield takeEvery('eCommerce/getProductDetailsAttempt', handleGetProductDetails, api);
}

function* handleGetProductDetails(api: ECommerceGateway, data: GetProductDetailsParams) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const { productId } = data.payload;

    const response = yield* call([api, api.getProductDetails], {
        authToken,
        productId,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.getProductDetailsFailure(response.message));
        NavActions.navToECommerce();
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.getProductDetailsSuccess(response.data));
    }
}
