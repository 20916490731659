import React, { FunctionComponent, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import dayjs from 'dayjs';
import SVG from 'react-inlinesvg';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledDropdown } from 'reactstrap';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import FsLightbox from 'fslightbox-react';
import isEmpty from 'lodash.isempty';
import Skeleton from 'react-loading-skeleton';

import { connect } from 'react-redux';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { AppDispatch, RootState } from 'redux/store';

import 'components/StyledComponent.css';
import Button from 'components/Button';
import IQOSDateRangePicker from 'components/IQOSDateRangePicker';
import IQOSPaginator from 'components/IQOSPaginator';
import Text from 'components/Text';
import Fonts from 'assets/themes/Fonts';
import Icons from 'assets/icons/Index';
import { CategoryIdEnum, EditPostsReport, EditToggleColumns, LanguageEnum, PostDaysClaimsEnum, PostReportClaimsEnum, PostsReport, PostsReportWithAmountOfDays, ReportEnum, UserClaims } from 'entities/posts';
import { IChannelTypeEnum } from 'entities/contests';
import Utils from 'lib/Utils';
import IconTooltip from 'components/IconTooltip';

interface PostsReportProps {
    isLanu: boolean;
    postsReportData: PostsReport;
    postsReportWithAmountOfDaysData: PostsReportWithAmountOfDays;

    isEditLoading: boolean;
    isExportLoading: boolean;
    isPostReportLoading: boolean;
    isAmountOfDaysLoading: boolean;

    isPostReportFailure: string;
    isAmountOfDaysFailure: string;

    getDateFrom: Date;
    getDateTo: Date;

    getPostsReportIndex: number;
    getPostsReportWithAmountOfDaysIndex: number;

    getUserClaimsRedux: UserClaims;

    setDateFrom(date: Date | null): void;
    setDateTo(date: Date | null): void;
    getPostsReport(channelType: string, dateFrom: string, dateTo: string, category: CategoryIdEnum, index: number): void;
    getPostsReportWithAmountOfDays(channelType: string, dateFrom: string, dateTo: string, index: number, customDays: number): void;
    getPostsReportExport(category: CategoryIdEnum, channelType: string, dateFrom: string, dateTo: string): void;
    editPostsReport(data: EditPostsReport): void;
    setPostsReportIndex(data: number): void;
    setPostsReportWithAmountOfDaysIndex(data: number): void;
    setPostsReportSelectedCategory(data: number): void;
    editToggleColumn(data: EditToggleColumns): void;
}

const PostsReportTab: FunctionComponent<PostsReportProps> = (props: PostsReportProps) => {
    const {
        isLanu,
        postsReportData,
        postsReportWithAmountOfDaysData,
        isEditLoading,
        isExportLoading,
        isPostReportLoading,
        isAmountOfDaysLoading,
        isPostReportFailure,
        isAmountOfDaysFailure,
        getDateFrom,
        getDateTo,
        getPostsReportIndex,
        getPostsReportWithAmountOfDaysIndex,
        getUserClaimsRedux,

        setDateFrom,
        setDateTo,
        getPostsReport,
        getPostsReportWithAmountOfDays,
        getPostsReportExport,
        editPostsReport,
        setPostsReportIndex,
        setPostsReportWithAmountOfDaysIndex,
        setPostsReportSelectedCategory,
        editToggleColumn,
    } = props;

    const languageEnumToStringConverter = (language: LanguageEnum) => {
        switch (language) {
            case 1: return 'English (UK)';
            case 2: return 'B. Melayu';
            default: return '';
        }
    };

    const languageStringToEnumConverter = (language: string) => {
        switch (language) {
            case 'B. Melayu': return LanguageEnum.Melayu;
            default: return LanguageEnum.English;
        }
    };

    const filterData = getUserClaimsRedux.userRemovedColumnClaims.postReportClaims;

    const [categoryFilterIsOpen, setCategoryFilterIsOpen] = useState<boolean>(false);
    const [postReportTableFilterIsOpen, setPostReportTableFilterIsOpen] = useState<boolean>(false);
    const [amountOfDaysTableFilterIsOpen, setAmountOfDaysTableFilterIsOpen] = useState<boolean>(false);
    const [editToggle, setEditToggle] = useState<boolean>(false);

    const [postedOnCheck, setPostedOnCheck] = useState<boolean>(!(filterData.includes(PostReportClaimsEnum.PostedOn)));
    const [categoryCheck, setCategoryCheck] = useState<boolean>(!(filterData.includes(PostReportClaimsEnum.Category)));
    const [viewsCheck, setViewsCheck] = useState<boolean>(!(filterData.includes(PostReportClaimsEnum.Views)));
    const [viewRateCheck, setViewRateCheck] = useState<boolean>(!(filterData.includes(PostReportClaimsEnum.ViewRate)));
    const [engagementsCheck, setEngagementsCheck] = useState<boolean>(!(filterData.includes(PostReportClaimsEnum.Engagement)));
    const [engagementRateCheck, setEngagementRateCheck] = useState<boolean>(!(filterData.includes(PostReportClaimsEnum.EngagementRate)));
    const [reactionsCheck, setReactionsCheck] = useState<boolean>(!(filterData.includes(PostReportClaimsEnum.Reactions)));
    const [repliesCheck, setRepliesCheck] = useState<boolean>(!(filterData.includes(PostReportClaimsEnum.Replies)));
    const [responsesCheck, setResponsesCheck] = useState<boolean>(!(filterData.includes(PostReportClaimsEnum.Responses)));
    const [clicksCheck, setClicksCheck] = useState<boolean>(!(filterData.includes(PostReportClaimsEnum.Clicks)));
    const [languageCheck, setLanguageCheck] = useState<boolean>(!(filterData.includes(PostReportClaimsEnum.Language)));

    const [allUncheck, setAllUncheck] = useState<boolean>(false);
    const [toggler, setToggler] = useState<boolean>(false);
    const [reRenderPaginator, setReRenderPaginator] = useState<boolean>(false);

    const [localDateFrom, setLocalDateFrom] = useState<Date | null>(getDateFrom);
    const [localDateTo, setLocalDateTo] = useState<Date | null>(getDateTo);

    const [categoryStatus, setCategoryStatus] = useState<CategoryIdEnum>(CategoryIdEnum.All);

    const [updateCategory, setUpdateCategory] = useState<CategoryIdEnum>(0);
    const [updateLanguage, setUpdateLanguage] = useState<LanguageEnum>(0);
    const [updateClicks, setUpdateClicks] = useState<number>(0);

    const [editibleIdArray, setEditableIdArray] = useState<string>('');
    const [currentId, setCurrentId] = useState<string>('');
    const [currentLightboxImage, setCurrentLightboxImage] = useState<string>('');

    const [customDays, setCustomDays] = useState<number>(31);

    const [day3Check, setDay3Check] = useState<boolean>(!(filterData.includes(PostDaysClaimsEnum.Day3)));
    const [day7Check, setDay7Check] = useState<boolean>(!(filterData.includes(PostDaysClaimsEnum.Day7)));
    const [day14Check, setDay14Check] = useState<boolean>(!(filterData.includes(PostDaysClaimsEnum.Day14)));
    const [customDayCheck, setCustomDayCheck] = useState<boolean>(!(filterData.includes(PostDaysClaimsEnum.CustomDays)));

    useEffect(() => {
        setPostsReportIndex(1);
        setPostsReportWithAmountOfDaysIndex(1);
    }, []);

    useEffect(() => {
        setPostsReportIndex(1);
        setPostsReportWithAmountOfDaysIndex(1);
    }, [isLanu]);

    useEffect(() => {
        setReRenderPaginator(!reRenderPaginator);
        setPostsReportIndex(1);
        setPostsReportWithAmountOfDaysIndex(1);
    }, [getDateTo]);

    useEffect(() => {
        if (localDateFrom && localDateTo) {
            getPostsReport(`${(isLanu) ? IChannelTypeEnum.LANU : IChannelTypeEnum.LAU}`, localDateFrom.toISOString(), localDateTo.toISOString(), categoryStatus, getPostsReportIndex);
            setTimeout(() => { setReRenderPaginator(false); }, 500);
        }
    }, [getPostsReportIndex, localDateTo, categoryStatus, isLanu]);

    useEffect(() => {
        if (localDateFrom && localDateTo && amountOfDaysTableFilterIsOpen === false) {
            getPostsReportWithAmountOfDays(`${(isLanu) ? IChannelTypeEnum.LANU : IChannelTypeEnum.LAU}`, localDateFrom.toISOString(), localDateTo.toISOString(), getPostsReportWithAmountOfDaysIndex, customDays);
            setTimeout(() => { setReRenderPaginator(false); }, 500);
        }
    }, [getPostsReportWithAmountOfDaysIndex, localDateTo, customDays, amountOfDaysTableFilterIsOpen === false, isLanu]);

    useEffect(() => {
        setDateFrom(localDateFrom);
        setDateTo(localDateTo);
    }, [localDateFrom, localDateTo]);

    useEffect(() => {
        if (!postedOnCheck && !categoryCheck && !viewsCheck && !viewRateCheck && !engagementsCheck && !engagementRateCheck && !reactionsCheck && !responsesCheck && !clicksCheck && !languageCheck) {
            setAllUncheck(true);
        } else {
            setAllUncheck(false);
        }
    }, [postedOnCheck, categoryCheck, viewsCheck, viewRateCheck, engagementsCheck, engagementRateCheck, reactionsCheck, responsesCheck, clicksCheck, languageCheck]);

    const toggleColumn = (reportEnum: ReportEnum, columnEnum: PostReportClaimsEnum | PostDaysClaimsEnum, removeValue: boolean) => {
        editToggleColumn({
            reportEnum,
            column: columnEnum,
            remove: removeValue,
        });
    };

    const input = document.getElementById('customDay');
    const button = document.getElementById('filterButton');

    if (button !== null && input !== null) {
        button.addEventListener('click', () => {
            setAmountOfDaysTableFilterIsOpen(false);
        });

        input.addEventListener('keydown', (e) => {
            if (e.keyCode === 13) {
                button.click();
            }
        });
    }

    const customDayValidator = (value: number) => {
        if (value < 0) {
            setCustomDays(Number(value.toString().replace(/[-]/g, '')));
        } else {
            setCustomDays(value);
        }
    };

    const saveClickHandler = (id: string) => {
        setEditToggle(false);
        setEditableIdArray(id);

        editPostsReport({
            postId: currentId,
            postCategory: updateCategory,
            clicks: updateClicks,
            language: updateLanguage,
        });

        setUpdateCategory(0);
        setUpdateLanguage(0);
        setUpdateClicks(0);
    };

    const cancelClickHandler = (id: string, category: CategoryIdEnum, clicks: number, language: LanguageEnum) => {
        setUpdateClicks(clicks);
        setUpdateCategory(() => Utils.Posts.categoryStringToEnumConverter(Utils.Posts.categoryEnumToStringConverter(category)));
        setUpdateLanguage(() => languageStringToEnumConverter(languageEnumToStringConverter(language)));

        setEditableIdArray(id);
        setEditToggle(false);
    };

    const editClickHandler = (id: string, category: CategoryIdEnum, clicks: number, language: LanguageEnum) => {
        setEditToggle(true);
        setCurrentId(id);
        setUpdateCategory(category);
        setUpdateClicks(clicks);
        setUpdateLanguage(language);

        if (editibleIdArray.includes(id) === false) {
            setEditableIdArray(id);
        }
    };

    const exportData = () => {
        getPostsReportExport(categoryStatus, `${(isLanu) ? IChannelTypeEnum.LANU : IChannelTypeEnum.LAU}`, getDateFrom.toISOString(), getDateTo.toISOString());
    };

    const renderPostTable = () => {
        return (
            postsReportData && (
                postsReportData.data.map((item, index) => {
                    const { postId, title, postCategory, mediaUrl, date, views, viewRate, engagement, engagementRate, reactions, replies, responses, clicks, language } = item;

                    return (
                        <StyledTr key={postId}>
                            <StyledTd className={index % 2 === 0 ? 'even-post' : 'odd'} style={{ height: '79.5px', textAlign: 'left', padding: '0', position: 'absolute', left: '0', minWidth: '180px', maxWidth: '180px', display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'center' }}>
                                {editToggle === true && editibleIdArray.includes(postId) ? (
                                    <>
                                        {isEditLoading && (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Spinner size='sm' />
                                            </div>
                                        )}
                                        {!isEditLoading && (
                                            <>
                                                <StyledIcon
                                                    type='button'
                                                    onClick={() => saveClickHandler(postId)}
                                                >
                                                    <SVG
                                                        style={{
                                                            width: '14px',
                                                            height: '14px',
                                                            strokeWidth: '0.1px',
                                                            transform: 'scale(1.8)',
                                                        }}
                                                        src={Icons.Save}
                                                        id='saveIcon'
                                                    />
                                                </StyledIcon>
                                                <StyledIcon
                                                    type='button'
                                                    onClick={() => cancelClickHandler(postId, postCategory, clicks, language)}
                                                >
                                                    <SVG
                                                        style={{
                                                            width: '14px',
                                                            height: '14px',
                                                            strokeWidth: '0.1px',
                                                            transform: 'scale(1.8)',
                                                        }}
                                                        src={Icons.Cancel}
                                                        id='canceIcon'
                                                    />
                                                </StyledIcon>
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <StyledIcon
                                        type='button'
                                        onClick={() => editClickHandler(postId, postCategory, clicks, language)}
                                    >
                                        <SVG
                                            style={{
                                                width: '13px',
                                                height: '13px',
                                                fill: '#00D1D2',
                                                strokeWidth: '0.1px',
                                                transform: 'scale(1.8)',
                                            }}
                                            src={Icons.Edit}
                                            id='editIcon'
                                        />
                                    </StyledIcon>
                                )}

                            </StyledTd>
                            <StyledTd className={index % 2 === 0 ? 'even-post' : 'odd'} style={{ gap: '40px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', height: '79.5px', textAlign: 'left', padding: '0px', position: 'absolute', left: '180px', minWidth: `${(allUncheck) ? '1000px' : '360px'}`, maxWidth: '360px' }}>
                                <div title={title} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: `${(allUncheck) ? '950px' : '290px'}`, paddingLeft: '20px' }}>
                                    {title}
                                </div>
                                <div style={{ width: '41px', marginRight: '30px', textAlign: 'center' }}>
                                    {(mediaUrl && mediaUrl !== '') && (
                                        <StyledButton onClick={() => [setToggler(!toggler), setCurrentLightboxImage(mediaUrl)]}>
                                            <img src={mediaUrl} alt='thumbnail' style={{ width: '41px', height: '41px' }} />
                                        </StyledButton>
                                    )}
                                </div>
                            </StyledTd>

                            <StyledTd style={{ minWidth: '1px', maxWidth: '1px' }} />

                            {(postedOnCheck) && (
                                <StyledTd>
                                    {dayjs(date).format('DD-MM-YYYY')}
                                </StyledTd>
                            )}

                            {(categoryCheck) && (
                                <StyledTd key={postId} style={{ minWidth: '200px', maxWidth: '200px', padding: editToggle ? '23px 0px 23px 0px' : '25px', zIndex: 1080, overflow: 'visible' }}>
                                    {(editToggle === true && categoryCheck && editibleIdArray.includes(postId)) ? (
                                        <UncontrolledDropdown style={{ display: 'flex', alignItems: 'center' }}>
                                            <DropdownToggle
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    backgroundColor: 'transparent',
                                                    color: 'black',
                                                    height: '30px',
                                                    width: '100%',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                }}
                                                caret
                                            >
                                                <StyledText style={{ fontSize: '13px' }}>{(updateCategory !== 0) ? Utils.Posts.categoryEnumToStringConverter(updateCategory) : Utils.Posts.categoryEnumToStringConverter(postCategory)}</StyledText>
                                            </DropdownToggle>
                                            <DropdownMenu style={{ background: 'white', width: '100%' }}>
                                                <div style={{ position: 'relative', overflowY: 'scroll', height: '90px' }}>
                                                    <DropdownItem onClick={() => setUpdateCategory(1)}>
                                                        <StyledText style={{ fontSize: '13px' }}>Category Motivation</StyledText>
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => setUpdateCategory(2)}>
                                                        <StyledText style={{ fontSize: '13px' }}>Acquisition</StyledText>
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => setUpdateCategory(3)}>
                                                        <StyledText style={{ fontSize: '13px' }}>Retention</StyledText>
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => setUpdateCategory(4)}>
                                                        <StyledText style={{ fontSize: '13px' }}>Promotion</StyledText>
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => setUpdateCategory(5)}>
                                                        <StyledText style={{ fontSize: '13px' }}>Product Info </StyledText>
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => setUpdateCategory(6)}>
                                                        <StyledText style={{ fontSize: '13px' }}>Branded Engagement</StyledText>
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => setUpdateCategory(7)}>
                                                        <StyledText style={{ fontSize: '13px' }}>Non Branded Engagement</StyledText>
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => setUpdateCategory(8)}>
                                                        <StyledText style={{ fontSize: '13px' }}>Recruitment</StyledText>
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => setUpdateCategory(9)}>
                                                        <StyledText style={{ fontSize: '13px' }}>Product Superiority</StyledText>
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => setUpdateCategory(10)}>
                                                        <StyledText style={{ fontSize: '13px' }}>Announcement</StyledText>
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => setUpdateCategory(11)}>
                                                        <StyledText style={{ fontSize: '13px' }}>Device Care</StyledText>
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => setUpdateCategory(12)}>
                                                        <StyledText style={{ fontSize: '13px' }}>Contest</StyledText>
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => setUpdateCategory(13)}>
                                                        <StyledText style={{ fontSize: '13px' }}>Greeting</StyledText>
                                                    </DropdownItem>
                                                </div>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    ) : (
                                        <StyledText style={{ fontSize: '13px' }}>{(Utils.Posts.categoryEnumToStringConverter(postCategory)) || '-'}</StyledText>
                                    )}
                                </StyledTd>
                            )}

                            {(viewsCheck) && (
                                <StyledTd>
                                    {views.toLocaleString()}
                                </StyledTd>
                            )}

                            {(viewRateCheck) && (
                                <StyledTd>
                                    {(viewRate !== undefined) ? `${Utils.Posts.rateConverter(viewRate)}%` : '-'}
                                </StyledTd>
                            )}

                            {(engagementsCheck) && (
                                <StyledTd>
                                    {(engagement !== undefined) ? engagement.toLocaleString() : '-'}
                                </StyledTd>
                            )}

                            {(engagementRateCheck) && (
                                <StyledTd>
                                    {(engagementRate !== undefined) ? `${Utils.Posts.rateConverter(engagementRate)}%` : '-'}
                                </StyledTd>
                            )}

                            {reactionsCheck && (
                                <StyledTd>
                                    {(reactions !== undefined) ? reactions : '-'}
                                </StyledTd>
                            )}

                            {repliesCheck && (
                                <StyledTd>
                                    {(replies !== undefined) ? replies : '-'}
                                </StyledTd>
                            )}

                            {(responsesCheck) && (
                                <StyledTd>
                                    {(responses !== undefined) ? responses : '-'}
                                </StyledTd>
                            )}

                            {(clicksCheck) && (
                                <StyledTd>
                                    {((editToggle === true && clicksCheck && editibleIdArray.includes(postId))) ? (
                                        <label htmlFor='clicks'>
                                            <input type='number' id='clicks' name='clicks' min={-10} value={(updateClicks < 0) ? 0 : updateClicks?.toString().replace(/(?!^-)[^0-9.]/g, '')} onChange={(e) => setUpdateClicks(Number(e.target.value))} style={{ width: '100px' }} />
                                        </label>
                                    ) : (
                                        <StyledText>{(clicks !== undefined) ? `${clicks}` : '-'}</StyledText>
                                    )}

                                </StyledTd>
                            )}

                            {(languageCheck) && (
                                <StyledTd style={{ minWidth: '200px', maxWidth: '200px', zIndex: 1080, overflow: 'visible' }}>
                                    {(editToggle === true && languageCheck && editibleIdArray.includes(postId)) ? (
                                        <UncontrolledDropdown style={{ display: 'flex', alignItems: 'center' }}>
                                            <DropdownToggle
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    backgroundColor: 'transparent',
                                                    color: 'black',
                                                    height: '30px',
                                                    width: '100%',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                }}
                                                caret
                                            >
                                                <StyledText style={{ fontSize: '13px' }}>{(Number(updateLanguage) !== 0) ? languageEnumToStringConverter(updateLanguage) : languageEnumToStringConverter(language)}</StyledText>
                                            </DropdownToggle>
                                            <DropdownMenu style={{ background: 'white' }}>
                                                <DropdownItem onClick={() => setUpdateLanguage(1)}>
                                                    English (UK)
                                                </DropdownItem>
                                                <DropdownItem onClick={() => setUpdateLanguage(2)}>
                                                    B. Melayu
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    ) : (
                                        <StyledText style={{ fontSize: '13px', textAlign: 'center' }}>{(languageEnumToStringConverter(language)) || '-'}</StyledText>
                                    )}
                                </StyledTd>
                            )}
                        </StyledTr>
                    );
                })
            ));
    };

    const renderPostsWithAmountOfDays = () => {
        if (isAmountOfDaysLoading) {
            return (
                <tr>
                    <td colSpan={7}>
                        <div>
                            <Skeleton count={5} height={50} />
                        </div>
                    </td>
                </tr>
            );
        }

        if (isAmountOfDaysFailure) {
            return (
                <tr>
                    <td colSpan={7} style={{ padding: '20px', fontSize: '18px' }}>
                        <div>
                            <Text style={{ color: 'red' }}>Something went wrong. Please try again.</Text>
                        </div>
                    </td>
                </tr>
            );
        }

        if (postsReportWithAmountOfDaysData.data.length <= 0) {
            return (
                <tr>
                    <td colSpan={7} style={{ padding: '20px', fontSize: '18px' }}>
                        <div>
                            <Text>There appears to be no data yet. </Text>
                        </div>
                    </td>
                </tr>
            );
        }

        return (
            postsReportWithAmountOfDaysData && (
                postsReportWithAmountOfDaysData.data.map((item, index) => {
                    const { postId, title, date, mediaUrl, day3, day7, day14, customDay } = item;

                    return (
                        <>
                            <StyledAmountOfDaysTr key={postId} style={{ textAlign: 'center', borderBottom: '1px solid #C5C5C5', background: `${(index % 2 === 0) ? '#F7F7F7' : 'white'}` }}>
                                <StyledAmountOfDaysTd rowSpan={2}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', textAlign: 'left', minWidth: '400px', maxWidth: 'auto', gap: '40px' }}>
                                        <div title={title} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', minWidth: '359px' }}>
                                            {title}
                                        </div>
                                        <div style={{ width: '41px', marginRight: '30px', textAlign: 'center' }}>
                                            {(mediaUrl && mediaUrl !== '') && (
                                                <StyledButton onClick={() => [setToggler(!toggler), setCurrentLightboxImage(mediaUrl)]}>
                                                    <img src={mediaUrl} alt='iqos' style={{ width: '41px', height: '41px' }} />
                                                </StyledButton>
                                            )}
                                        </div>
                                    </div>
                                </StyledAmountOfDaysTd>

                                <StyledAmountOfDaysTd rowSpan={2} style={{ minWidth: '150px', maxWidth: '150px', borderRight: `${(day3Check || day7Check || day14Check || customDayCheck) ? '1px solid #C5C5C5' : 'none'}` }}>
                                    {dayjs(date).format('DD-MM-YYYY')}
                                </StyledAmountOfDaysTd>

                                {(day3Check || day7Check || day14Check || customDayCheck) && (
                                    <StyledAmountOfDaysTd title='Eng. Rate' style={{ textAlign: 'left' }}>
                                        Eng. Rate
                                    </StyledAmountOfDaysTd>
                                )}

                                {(day3Check) && (
                                    <StyledAmountOfDaysTd title={(!isEmpty(day3)) ? `${Utils.Posts.rateConverter(day3.engagementRate)}%` : ''} style={{ minWidth: '123px', maxWidth: '123px' }}>
                                        {((!isEmpty(day3))) ? `${Utils.Posts.rateConverter(day3.engagementRate)}%` : '-'}
                                    </StyledAmountOfDaysTd>
                                )}

                                {(day7Check) && (
                                    <StyledAmountOfDaysTd title={(!isEmpty(day7)) ? `${Utils.Posts.rateConverter(day7.engagementRate)}%` : ''} style={{ minWidth: '123px', maxWidth: '123px' }}>
                                        {(!isEmpty(day7)) ? `${Utils.Posts.rateConverter(day7.engagementRate)}%` : '-'}
                                    </StyledAmountOfDaysTd>
                                )}

                                {(day14Check) && (
                                    <StyledAmountOfDaysTd title={(!isEmpty(day14)) ? `${Utils.Posts.rateConverter(day14.engagementRate)}%` : ''} style={{ minWidth: '130px', maxWidth: '130px' }}>
                                        {(!isEmpty(day14)) ? `${Utils.Posts.rateConverter(day14.engagementRate)}%` : '-'}
                                    </StyledAmountOfDaysTd>
                                )}

                                {(customDayCheck) && (
                                    <StyledAmountOfDaysTd title={(!isEmpty(customDay)) ? `${Utils.Posts.rateConverter(customDay.engagementRate)}%` : ''} style={{ minWidth: '130px', maxWidth: '130px' }}>
                                        {(!isEmpty(customDay)) ? `${Utils.Posts.rateConverter(customDay.engagementRate)}%` : '-'}
                                    </StyledAmountOfDaysTd>
                                )}
                            </StyledAmountOfDaysTr>

                            <StyledAmountOfDaysTr key={`${postId} + ${date}`} style={{ borderBottom: '1px solid #C5C5C5', background: `${(index % 2 === 0) ? '#F7F7F7' : 'white'}` }}>
                                {(day3Check || day7Check || day14Check || customDayCheck) && (
                                    <StyledAmountOfDaysTd title='View Rate' style={{ textAlign: 'left' }}>
                                        View Rate
                                    </StyledAmountOfDaysTd>
                                )}

                                {(day3Check) && (
                                    <StyledAmountOfDaysTd title={(!isEmpty(day3)) ? `${Utils.Posts.rateConverter(day3.viewRate)}%` : ''} style={{ minWidth: '123px', maxWidth: '123px' }}>
                                        {(!isEmpty(day3)) ? `${Utils.Posts.rateConverter(day3.viewRate)}%` : '-'}
                                    </StyledAmountOfDaysTd>
                                )}

                                {(day7Check) && (
                                    <StyledAmountOfDaysTd title={(!isEmpty(day7)) ? `${Utils.Posts.rateConverter(day7.viewRate)}%` : ''} style={{ minWidth: '123px', maxWidth: '123px' }}>
                                        {(!isEmpty(day7)) ? `${Utils.Posts.rateConverter(day7.viewRate)}%` : '-'}
                                    </StyledAmountOfDaysTd>
                                )}

                                {(day14Check) && (
                                    <StyledAmountOfDaysTd title={(!isEmpty(day14)) ? `${Utils.Posts.rateConverter(day14.viewRate)}%` : ''} style={{ minWidth: '130px', maxWidth: '130px' }}>
                                        {(!isEmpty(day14)) ? `${Utils.Posts.rateConverter(day14.viewRate)}%` : '-'}
                                    </StyledAmountOfDaysTd>
                                )}

                                {(customDayCheck) && (
                                    <StyledAmountOfDaysTd title={(!isEmpty(customDay)) ? `${Utils.Posts.rateConverter(customDay.viewRate)}%` : ''} style={{ minWidth: '130px', maxWidth: '130px' }}>
                                        {(!isEmpty(customDay)) ? `${Utils.Posts.rateConverter(customDay.viewRate)}%` : '-'}
                                    </StyledAmountOfDaysTd>
                                )}
                            </StyledAmountOfDaysTr>
                        </>
                    );
                })
            ));
    };

    return (
        <TabBodyWrapper>
            <TabHeaderWrapper>
                <StyledText style={{ fontFamily: `${Fonts.secondary}`, fontSize: '22px', display: 'flex', gap: '10px', alignItems: 'center' }} id='postsReport'>
                    Post Reports
                    <IconTooltip
                        tooltipId='postsReport'
                        iconSrc={Icons.QuestionMark}
                        bgColor='#00D1D2'
                        fontColor='black'
                        size='xl'
                    >
                        The data will be updated 3 days after the posting date.
                    </IconTooltip>
                </StyledText>
                <MonthWrapper>
                    <DateDisplayText>
                        <IQOSDateRangePicker
                            dateFrom={localDateFrom}
                            dateTo={localDateTo}
                            setDateFrom={setLocalDateFrom}
                            setDateTo={setLocalDateTo}
                        />
                    </DateDisplayText>

                    <ButtonWrapper>
                        <Dropdown isOpen={categoryFilterIsOpen} toggle={() => setCategoryFilterIsOpen(!categoryFilterIsOpen)}>
                            <StyledDropdown caret style={{ background: 'transparent', color: 'black' }}>
                                <DropdownLabel>
                                    View Only
                                </DropdownLabel>
                            </StyledDropdown>
                            <DropdownMenu container='body' className='filter-menu-item' style={{ width: '180px', textAlign: 'center' }}>
                                <DropdownItem onClick={() => [setCategoryStatus(0), setPostsReportSelectedCategory(0)]}>
                                    <StyledText style={{ fontSize: '12px', fontFamily: `${Fonts.secondary}` }}>All</StyledText>
                                </DropdownItem>
                                <DropdownItem onClick={() => [setCategoryStatus(1), setPostsReportSelectedCategory(1)]}>
                                    <StyledText style={{ fontSize: '12px', fontFamily: `${Fonts.secondary}` }}>Category Motivation</StyledText>
                                </DropdownItem>
                                <DropdownItem onClick={() => [setCategoryStatus(2), setPostsReportSelectedCategory(2)]}>
                                    <StyledText style={{ fontSize: '12px', fontFamily: `${Fonts.secondary}` }}>Acquisition</StyledText>
                                </DropdownItem>
                                <DropdownItem onClick={() => [setCategoryStatus(3), setPostsReportSelectedCategory(3)]}>
                                    <StyledText style={{ fontSize: '12px', fontFamily: `${Fonts.secondary}` }}>Retention</StyledText>
                                </DropdownItem>
                                <DropdownItem onClick={() => [setCategoryStatus(4), setPostsReportSelectedCategory(4)]}>
                                    <StyledText style={{ fontSize: '12px', fontFamily: `${Fonts.secondary}` }}>Promotion</StyledText>
                                </DropdownItem>
                                <DropdownItem onClick={() => [setCategoryStatus(5), setPostsReportSelectedCategory(5)]}>
                                    <StyledText style={{ fontSize: '12px', fontFamily: `${Fonts.secondary}` }}>Product Info</StyledText>
                                </DropdownItem>
                                <DropdownItem onClick={() => [setCategoryStatus(6), setPostsReportSelectedCategory(6)]}>
                                    <StyledText style={{ fontSize: '12px', fontFamily: `${Fonts.secondary}` }}>Branded Engagement</StyledText>
                                </DropdownItem>
                                <DropdownItem onClick={() => [setCategoryStatus(7), setPostsReportSelectedCategory(7)]}>
                                    <StyledText style={{ fontSize: '12px', fontFamily: `${Fonts.secondary}` }}>Non Branded Engagement</StyledText>
                                </DropdownItem>
                                <DropdownItem onClick={() => [setCategoryStatus(8), setPostsReportSelectedCategory(8)]}>
                                    <StyledText style={{ fontSize: '12px', fontFamily: `${Fonts.secondary}` }}>Recruitment</StyledText>
                                </DropdownItem>
                                <DropdownItem onClick={() => [setCategoryStatus(9), setPostsReportSelectedCategory(9)]}>
                                    <StyledText style={{ fontSize: '12px', fontFamily: `${Fonts.secondary}` }}>Product Superiority</StyledText>
                                </DropdownItem>
                                <DropdownItem onClick={() => [setCategoryStatus(10), setPostsReportSelectedCategory(10)]}>
                                    <StyledText style={{ fontSize: '12px', fontFamily: `${Fonts.secondary}` }}>Announcement</StyledText>
                                </DropdownItem>
                                <DropdownItem onClick={() => [setCategoryStatus(11), setPostsReportSelectedCategory(11)]}>
                                    <StyledText style={{ fontSize: '12px', fontFamily: `${Fonts.secondary}` }}>Device Care</StyledText>
                                </DropdownItem>
                                <DropdownItem onClick={() => [setCategoryStatus(12), setPostsReportSelectedCategory(12)]}>
                                    <StyledText style={{ fontSize: '12px', fontFamily: `${Fonts.secondary}` }}>Contest</StyledText>
                                </DropdownItem>
                                <DropdownItem onClick={() => [setCategoryStatus(13), setPostsReportSelectedCategory(13)]}>
                                    <StyledText style={{ fontSize: '12px', fontFamily: `${Fonts.secondary}` }}>Greeting</StyledText>
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>

                        <Dropdown isOpen={postReportTableFilterIsOpen} toggle={() => setPostReportTableFilterIsOpen(!postReportTableFilterIsOpen)}>
                            <StyledDropdown caret style={{ background: 'transparent', color: 'black' }}>
                                <DropdownLabel>
                                    Show All
                                </DropdownLabel>
                            </StyledDropdown>
                            <DropdownMenu container='body' style={{ width: '180px', textAlign: 'center', background: 'white' }}>
                                <FormGroup style={{ paddingLeft: '25px' }}>
                                    <FormControlLabel control={<Checkbox size='small' checked={postedOnCheck} onChange={() => [setPostedOnCheck(!postedOnCheck), toggleColumn(ReportEnum.PostReport, PostReportClaimsEnum.PostedOn, postedOnCheck)]} />} label='Posted On' />
                                    <FormControlLabel control={<Checkbox size='small' checked={categoryCheck} onChange={() => [setCategoryCheck(!categoryCheck), toggleColumn(ReportEnum.PostReport, PostReportClaimsEnum.Category, categoryCheck)]} />} label='Category' />
                                    <FormControlLabel control={<Checkbox size='small' checked={viewsCheck} onChange={() => [setViewsCheck(!viewsCheck), toggleColumn(ReportEnum.PostReport, PostReportClaimsEnum.Views, viewsCheck)]} />} label='Views' />
                                    <FormControlLabel control={<Checkbox size='small' checked={viewRateCheck} onChange={() => [setViewRateCheck(!viewRateCheck), toggleColumn(ReportEnum.PostReport, PostReportClaimsEnum.ViewRate, viewRateCheck)]} />} label='View Rate' />
                                    <FormControlLabel control={<Checkbox size='small' checked={engagementsCheck} onChange={() => [setEngagementsCheck(!engagementsCheck), toggleColumn(ReportEnum.PostReport, PostReportClaimsEnum.Engagement, engagementsCheck)]} />} label='Engagements' />
                                    <FormControlLabel control={<Checkbox size='small' checked={engagementRateCheck} onChange={() => [setEngagementRateCheck(!engagementRateCheck), toggleColumn(ReportEnum.PostReport, PostReportClaimsEnum.EngagementRate, engagementRateCheck)]} />} label='Eng. Rate' />
                                    <FormControlLabel control={<Checkbox size='small' checked={reactionsCheck} onChange={() => [setReactionsCheck(!reactionsCheck), toggleColumn(ReportEnum.PostReport, PostReportClaimsEnum.Reactions, reactionsCheck)]} />} label='Reactions' />
                                    <FormControlLabel control={<Checkbox size='small' checked={repliesCheck} onChange={() => [setRepliesCheck(!repliesCheck), toggleColumn(ReportEnum.PostReport, PostReportClaimsEnum.Replies, repliesCheck)]} />} label='Replies' />
                                    <FormControlLabel control={<Checkbox size='small' checked={responsesCheck} onChange={() => [setResponsesCheck(!responsesCheck), toggleColumn(ReportEnum.PostReport, PostReportClaimsEnum.Responses, responsesCheck)]} />} label='Responses' />
                                    <FormControlLabel control={<Checkbox size='small' checked={clicksCheck} onChange={() => [setClicksCheck(!clicksCheck), toggleColumn(ReportEnum.PostReport, PostReportClaimsEnum.Clicks, clicksCheck)]} />} label='Clicks' />
                                    <FormControlLabel control={<Checkbox size='small' checked={languageCheck} onChange={() => [setLanguageCheck(!languageCheck), toggleColumn(ReportEnum.PostReport, PostReportClaimsEnum.Language, languageCheck)]} />} label='Language' />
                                </FormGroup>
                            </DropdownMenu>
                        </Dropdown>
                    </ButtonWrapper>

                    <Button onClick={() => exportData()} primary style={{ width: '350px', borderRadius: '20px', marginRight: '15px' }}>
                        {(isExportLoading) ? (<Spinner />) : 'Export Data'}
                    </Button>
                </MonthWrapper>
            </TabHeaderWrapper>

            <div style={{ position: 'relative', width: '95%' }}>
                {(isPostReportLoading) && (
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
                        <tr style={{ background: 'transparent' }}>
                            <Spinner size='xl' />
                        </tr>
                    </div>
                )}
                {(isPostReportFailure) && (
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
                        <tr style={{ background: 'transparent' }}>
                            <StyledText style={{ color: 'red' }}>Something went wrong. Please try again.</StyledText>
                        </tr>
                    </div>
                )}
                {(!isPostReportLoading) && (!isPostReportFailure) && (postsReportData.data.length <= 0) && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '50px', background: 'white', height: '60px' }}>
                        <tr style={{ background: 'transparent' }}>
                            <StyledText>There appears to be no data yet.</StyledText>
                        </tr>
                    </div>
                )}
                {(!isPostReportLoading) && (!isPostReportFailure) && (postsReportData.data.length > 0) && (
                    <div className='post-div'>
                        <StyledTable>
                            <tr>
                                <StyledTh style={{ textAlign: 'center', position: 'absolute', left: '0', top: 'auto', minWidth: '180px', maxWidth: '180px', background: 'white' }}>Action</StyledTh>
                                <StyledTh style={{ position: 'absolute', left: '180px', top: 'auto', minWidth: '360px', maxWidth: '360px', background: 'white' }}>Post Title</StyledTh>
                                <StyledHeaderTd style={{ minWidth: '1px', maxWidth: '1px', padding: '31px' }} />
                                {(postedOnCheck) && (
                                    <StyledHeaderTd>Posted On</StyledHeaderTd>
                                )}
                                {(categoryCheck) && (
                                    <StyledHeaderTd>Category</StyledHeaderTd>
                                )}
                                {(viewsCheck) && (
                                    <StyledHeaderTd>Views</StyledHeaderTd>
                                )}
                                {(viewRateCheck) && (
                                    <StyledHeaderTd>View Rate</StyledHeaderTd>
                                )}
                                {(engagementsCheck) && (
                                    <StyledHeaderTd>Engagement</StyledHeaderTd>
                                )}
                                {(engagementRateCheck) && (
                                    <StyledHeaderTd>Eng. Rate</StyledHeaderTd>
                                )}
                                {(reactionsCheck) && (
                                    <StyledHeaderTd>Reactions</StyledHeaderTd>
                                )}
                                {(repliesCheck) && (
                                    <StyledHeaderTd>Replies</StyledHeaderTd>
                                )}
                                {(responsesCheck) && (
                                    <StyledHeaderTd>Responses</StyledHeaderTd>
                                )}
                                {(clicksCheck) && (
                                    <StyledHeaderTd>Clicks</StyledHeaderTd>
                                )}
                                {(languageCheck) && (
                                    <StyledHeaderTd>Language</StyledHeaderTd>
                                )}
                            </tr>
                            {renderPostTable()}
                        </StyledTable>
                    </div>
                )}
                { (reRenderPaginator === false) && (
                    <IQOSPaginator
                        maxIndex={postsReportData.maxIndex}
                        setIndex={setPostsReportIndex}
                        currentIndex={getPostsReportIndex}
                        url='/analytics/posts'
                        containerId='postsReport'
                    />
                )}
            </div>
            <TabBodyWrapper>
                <AmountOfDaysTabHeaderWrapper>
                    <StyledText style={{ fontFamily: `${Fonts.secondary}`, fontSize: '22px' }} id='postsReportWithAmountOfDays'>
                        Post Reports With Amount Of Days
                    </StyledText>
                    <MonthWrapper>
                        <DateDisplayText>
                            <IQOSDateRangePicker
                                dateFrom={localDateFrom}
                                dateTo={localDateTo}
                                setDateFrom={setLocalDateFrom}
                                setDateTo={setLocalDateTo}
                            />
                        </DateDisplayText>
                        <ButtonWrapper>
                            <HiddenButton type='submit' id='filterButton' style={{ display: 'none' }} />
                            <Dropdown isOpen={amountOfDaysTableFilterIsOpen} toggle={() => setAmountOfDaysTableFilterIsOpen(!amountOfDaysTableFilterIsOpen)}>
                                <DropdownToggle caret style={styles.filterButton}>
                                    <div style={{ paddingLeft: '10px', marginRight: '10px', overflowX: 'hidden' }}>
                                        Show All
                                    </div>
                                </DropdownToggle>
                                <DropdownMenu container='body' style={{ width: '180px', textAlign: 'center', background: 'white' }}>
                                    <FormGroup style={{ paddingLeft: '15px' }}>
                                        <FormControlLabel control={<Checkbox size='small' checked={day3Check} onChange={() => [setDay3Check(!day3Check), toggleColumn(ReportEnum.PostDays, PostDaysClaimsEnum.Day3, day3Check)]} />} label='Last 3 Days' />
                                        <FormControlLabel control={<Checkbox size='small' checked={day7Check} onChange={() => [setDay7Check(!day7Check), toggleColumn(ReportEnum.PostDays, PostDaysClaimsEnum.Day7, day7Check)]} />} label='Last 7 Days' />
                                        <FormControlLabel control={<Checkbox size='small' checked={day14Check} onChange={() => [setDay14Check(!day14Check), toggleColumn(ReportEnum.PostDays, PostDaysClaimsEnum.Day14, day14Check)]} />} label='Last 14 Days' />
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <FormControlLabel style={{ marginRight: '6px' }} control={<Checkbox size='small' checked={customDayCheck} onChange={() => [setCustomDayCheck(!customDayCheck), toggleColumn(ReportEnum.PostDays, PostDaysClaimsEnum.CustomDays, customDayCheck)]} />} label='Custom:' />
                                            <label htmlFor='customDay'>
                                                <input max={31} type='number' id='customDay' name='customDay' min={0} value={customDays.toString().replace(/^[0]+/g, '')} onChange={(e) => customDayValidator(Number(e.target.value))} style={{ width: '50px' }} />
                                            </label>
                                        </div>
                                    </FormGroup>
                                </DropdownMenu>
                            </Dropdown>
                        </ButtonWrapper>
                    </MonthWrapper>
                </AmountOfDaysTabHeaderWrapper>
                <StyledAmountOfDaysTable>
                    {(postsReportWithAmountOfDaysData.data.length > 0) && (
                        <tr style={{ borderBottom: '1px solid #C5C5C5' }}>
                            <StyledHeaderTd style={{ textAlign: 'left', minWidth: '480px', width: '480px' }}>Post Title</StyledHeaderTd>
                            <StyledHeaderTd style={{ width: '150px' }}>Posted On</StyledHeaderTd>
                            {(day3Check || day7Check || day14Check || customDayCheck) && (
                                <StyledHeaderTd />
                            )}
                            {(day3Check) && (
                                <StyledHeaderTd style={{ minWidth: '123px', maxWidth: '123px' }}>Last 3 Days</StyledHeaderTd>
                            )}
                            {(day7Check) && (
                                <StyledHeaderTd style={{ minWidth: '123px', maxWidth: '123px' }}>Last 7 Days</StyledHeaderTd>
                            )}
                            {(day14Check) && (
                                <StyledHeaderTd style={{ minWidth: '130px', maxWidth: '130px' }}>Last 14 Days</StyledHeaderTd>
                            )}
                            {(customDayCheck) && (
                                <StyledHeaderTd style={{ minWidth: '130px', maxWidth: '130px' }}>{`Last ${customDays} Days`}</StyledHeaderTd>
                            )}
                        </tr>
                    )}
                    <StyledTableBody style={{ whiteSpace: 'nowrap' }}>
                        {renderPostsWithAmountOfDays()}
                    </StyledTableBody>
                </StyledAmountOfDaysTable>
                <div style={{ position: 'relative', width: '96%', marginTop: '2%' }}>
                    { (reRenderPaginator === false) && (
                        <IQOSPaginator
                            maxIndex={postsReportWithAmountOfDaysData.maxIndex}
                            setIndex={setPostsReportWithAmountOfDaysIndex}
                            currentIndex={getPostsReportWithAmountOfDaysIndex}
                            url='/analytics/posts'
                            containerId='postsReportWithAmountOfDays'
                        />
                    )}
                </div>
                <FsLightbox
                    toggler={(toggler)}
                    sources={[currentLightboxImage]}
                />
            </TabBodyWrapper>
        </TabBodyWrapper>
    );
};

const TabBodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%; 
`;

const MonthWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    min-width: 600px;

    @media (max-width: 1580px) {
        gap: 5px;
    }
`;

const StyledText = styled.text`
    text-align: left;
    font-size: 18px;
`;

const TabHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 50px 20px 20px 40px;
`;

const AmountOfDaysTabHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 50px 35px 20px 40px;
`;

const DateDisplayText = styled.text`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    width: 100%;
    gap: 5px;
    padding-right: 15px;

    color: lightgrey;
    font-size: 18px;
    text-align: left;
`;

const StyledTableBody = styled.tbody`
    width: 100%;
    max-width: 700px;
    text-align: center;
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
    font-size: 5px;

    @media (max-width: 1580px) {
        justify-content: space-between;
        gap: 5px;
    }
`;

const StyledTable = styled.table`
    min-width: 100%;
    max-width: 100%;
    background-color: white;
    border-collapse: collapse;
    table-layout: fixed;
    
    margin-top: 20px;
    border-radius: 0px 0px 10px 0px !important;
`;

const StyledAmountOfDaysTable = styled.table`
    width: 95%;
    background-color: white;
    border-collapse: collapse;
    table-layout: fixed;
    
    margin-top: 20px;
    border-radius: 0px 0px 10px 0px !important;
`;

const StyledTh = styled.th`
    padding: 20px;
    border-bottom: 1px solid rgb(0,0,0,0,3);
    font-size: 15px;
    color: #959595;
    font-weight: lighter;

    &:first-of-type {
        text-align: left;
    }
`;

const StyledTr = styled.tr`
    z-index: 0;
    
    &:last-of-type {
        border-bottom: -1px solid white;
    }

    &:nth-last-of-type(1){
        border-radius: 0px 0px 10px 0px !important;
    }

    &:nth-of-type(even){
        background-color: #F7F7F7;
    }

    &:nth-of-type(odd){
        background-color: white;
    }
`;

const StyledAmountOfDaysTr = styled.tr`
    z-index: 0;
    height: 30px;

    &:last-of-type {
        border-bottom: none !important;
    }

    &:nth-last-of-type(1){
        border-radius: 0px 0px 10px 0px !important;
    }
`;

const StyledTd = styled.td`
    padding: 23px;
    font-size: 16px;
    width: 150px;
    min-width: 150px;
    max-width: 150px;
    height: 79.5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
`;

const StyledAmountOfDaysTd = styled.td`
    padding: 23px;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
`;

const StyledHeaderTd = styled.td`
    padding: 20px 20px 20px 20px;
    font-size: 16px;
    min-width: 150px;
    max-width: 150px;
    whiteSpace: nowrap;
    overflow: hidden;
    text-Overflow: ellipsis;
    text-align: center;
    font-size: 15px;
    color: #959595;
`;

const StyledIcon = styled.button`
    background-color: transparent;
    color: inherit;
    border: none;
    transition: transform 0.2s;
    position: relative;
    z-index: 0;

    &:hover{
        transform: scale(1.1);
    }
`;

const HiddenButton = styled.button`
    display: none;
`;

const StyledButton = styled.button`
    display: flex;
    align-items: center;
    border: none;
    background: transparent;
    height: 75%;
    width: 10%;
    font-size: 11px;
`;

const StyledDropdown = styled(DropdownToggle)`
        background-color: #F1F6F8;
        font-size: 18px;
        font-family: ${Fonts.primary};
        color: black;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        width: 163px;
        height: 40px;

        border: 1px solid black;
        border-radius: 20px;
        cursor: pointer;

        @media (max-width: 1580px) {
            width: fit-content;
            background-color: transparent;
            color: black;
        }
`;

const DropdownLabel = styled.div`
    padding-left: 10px;
    margin-right: 10px ;
    overflow-x: hidden;
    @media (max-width: 1580px) {
        font-size: 12px;
    }
`;

const styles = {
    viewButton: css`
        max-width: 20px;
        max-height: 20px;
    `,

    filterButton: {
        backgroundColor: '#F1F6F8',
        fontSize: '18',
        fontFamily: 'Fonts.primary',
        color: 'black',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',

        width: '163px',
        height: '40px',

        border: '1px solid black',
        borderRadius: '20px',
        cursor: 'pointer',
    },
};

const mapStateToProps = (state: RootState) => ({
    isLanu: Selectors.getFaqGetIsLANU(state),

    postsReportData: Selectors.getPostsGetPostsReport(state),
    postsReportWithAmountOfDaysData: Selectors.getPostsGetPostsReportWithAmountOfDays(state),
    userClaimsData: Selectors.getPostsGetUserClaims(state),

    isEditLoading: Selectors.editPostsEditPostsReportAttempting(state),
    isExportLoading: Selectors.getPostsGetPostsReportExportAttempting(state),
    isPostReportLoading: Selectors.getPostsGetPostsReportAttempting(state),
    isAmountOfDaysLoading: Selectors.getPostsGetPostsReportWithAmountOfDaysAttempting(state),

    isPostReportFailure: Selectors.getPostsGetPostsReportFailure(state),
    isAmountOfDaysFailure: Selectors.getPostsGetPostsReportWithAmountOfDaysFailure(state),

    getDateFrom: Selectors.getPostsGetDateFrom(state),
    getDateTo: Selectors.getPostsGetDateTo(state),

    getPostsReportIndex: Selectors.getPostsGetPostsReportIndex(state),
    getPostsReportWithAmountOfDaysIndex: Selectors.getPostsGetPostsReportWithAmountOfDaysIndex(state),

    getUserClaimsRedux: Selectors.getPostsGetUserClaimsRedux(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getPostsReport: (channelType: string, dateFrom: string, dateTo: string, category: CategoryIdEnum, index: number) => dispatch(Actions.getPostsReportAttempt({ channelType, dateFrom, dateTo, category, index })),
    getPostsReportExport: (category: CategoryIdEnum, channelType: string, dateFrom: string, dateTo: string) => dispatch(Actions.getPostsReportExportAttempt({ category, channelType, dateFrom, dateTo })),
    getPostsReportWithAmountOfDays: (channelType: string, dateFrom: string, dateTo: string, index: number, customDays: number) => dispatch(Actions.getPostsReportWithAmountOfDaysAttempt({ channelType, dateFrom, dateTo, index, customDays })),
    getUserClaims: () => dispatch(Actions.getUserClaimsAttempt()),

    setDateFrom: (date: Date | null) => dispatch(Actions.setDateFrom(date)),
    setDateTo: (date: Date | null) => dispatch(Actions.setDateTo(date)),

    setPostsReportIndex: (data: number) => dispatch(Actions.setPostsReportIndex(data)),
    setPostsReportSelectedCategory: (data: number) => dispatch(Actions.setPostsReportSelectedCategory(data)),
    setPostsReportWithAmountOfDaysIndex: (index: number) => dispatch(Actions.setPostsReportWithAmountOfDaysIndex(index)),

    editPostsReport: (data: EditPostsReport) => dispatch(Actions.editPostsReportAttempt(data)),
    editToggleColumn: (data: EditToggleColumns) => dispatch(Actions.editToggleColumnsAttempt(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostsReportTab);
