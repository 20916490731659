import React, { FunctionComponent } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { Spinner } from 'reactstrap';
import Colors from '../assets/themes/Colors';

export interface ButtonProps {
    label?: string;
    onClick?: () => void;
    loading?: boolean;
    disabled?: boolean;
    primary?: boolean;
    [x: string]: any;
    css?: FlattenSimpleInterpolation;
}

const Button: FunctionComponent<ButtonProps> = ({
    onClick,
    label,
    loading = false,
    disabled = false,
    primary = false,
    ...props
}: ButtonProps) => {
    const renderBody = () => {
        if (loading) return <Spinner color='warning' size='sm' />;

        return label;
    };

    return (
        <StyledButton
            type='button'
            onClick={onClick}
            disabled={disabled}
            $loading={loading} // https://stackoverflow.com/questions/49784294/warning-received-false-for-a-non-boolean-attribute-how-do-i-pass-a-boolean-f
            primary={primary}
            {...props}
        >
            {renderBody()}
            {props.children}
        </StyledButton>
    );
};

const StyledButton = styled.button<ButtonProps>`
    border: 0;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;

    ${props => (props.disabled && !props.$loading) && css`
        background-color: grey;
    `}

    SVG {
        width: 30px;
        height: 30px;
        color: ${Colors.primary};
    }

    ${props => props.css}

    ${props => (props.disabled === true) && css`
        background-color: lightgrey;
        border: 1px solid lightgrey !important;
        cursor: not-allowed;

        &:hover {
            background-color: lightgrey;
            color: grey;

        }
    `}

    ${props => (props.primary === true) && css`
        background-color: #36CAB5;
        color: white;
        font-weight: lighter;
        height: 46px;
        width: 205px;
    `}

    ${props => (props.primary === false) && css`
        background-color: white;
        border: 1px solid transparent;
        font0-weight: bold;
        color: black;
    `}
`;

export default Button;
