import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import styled, { FlattenSimpleInterpolation } from 'styled-components';

import AnalyticsHeader from 'components/analytics/AnalyticsHeader';

import Colors from 'assets/themes/Colors';

interface IQOSModalProps {
    header: string;
    headerSize?: 'sm' | 'lg' | 'md';
    isOpen: boolean;

    children: React.ReactNode | React.ReactNode[]

    size?: 'sm' | 'lg' | 'xl';
    wrapperCSS?: FlattenSimpleInterpolation;
    bodyCSS? : FlattenSimpleInterpolation;

    toggle: () => void;
}

const IQOSModal:React.FunctionComponent<IQOSModalProps> = ({
    header,
    headerSize,
    isOpen,
    size,
    wrapperCSS,
    bodyCSS,
    toggle,
    children,
}:IQOSModalProps) => {
    return (
        <StyledModal css={wrapperCSS} isOpen={isOpen} toggle={toggle} centered size={size}>
            <StyledModalBody css={bodyCSS}>
                <AnalyticsHeader header={header} size={headerSize || 'md'} />
                <GreyedHr />
                {children}
            </StyledModalBody>
        </StyledModal>

    );
};

interface StyledCSSProps {
    css?: FlattenSimpleInterpolation;
}

const StyledModal = styled(Modal)<StyledCSSProps>`
    max-width: 648px;
    .modal-content {
        border-radius: 20px !important;
    }
    ${props => props.css};
`;

const StyledModalBody = styled(ModalBody)<StyledCSSProps>`
    margin: 0 0 .5rem 0;
    padding:2rem;
    ${props => props.css}
`;

const GreyedHr = styled.div`
    height:2px;
    background-color: ${Colors.lightGrey}54;
    margin: 1rem 0 1rem 0;
`;

export default IQOSModal;
