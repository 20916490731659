import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';
import { IChannelTypeEnum } from 'entities/contests';

import { ICreateGame, IDeleteGame, IEditGameParams, IGame, IGameResponse, IGetGameResponsesResponse, IGetGameDetailsSuccess } from 'entities/games';

export interface GetGamesParams {
    authToken: string;
    type?: IChannelTypeEnum;
}

export interface CreateGameParams {
    authToken: string;
    data: ICreateGame;
}

export interface DeleteGameParams {
    authToken: string;
    data: IDeleteGame;
}

export interface GetGameDetailsParams {
    authToken: string;
    id: string;
    type: IChannelTypeEnum;
}

export interface EditGameDetailsParams {
    authToken: string;
    data : IEditGameParams;
}

export interface GetGameDetailsResponseParams {
    authToken: string;
    gameId: string;
    index: number;
    type: IChannelTypeEnum;
}

export interface GetExportResponsesParams {
    authToken: string;
    gameId: string;
    type: IChannelTypeEnum;
}

export default abstract class IGamesGateway extends Gateway {
    abstract getGames(params: GetGamesParams): GatewayResponse<IGame[]>;

    abstract createGame(params: CreateGameParams): GatewayResponse<IGame[]>;

    abstract deleteGame(params: DeleteGameParams): GatewayResponse<string>;

    abstract getGameDetails(params: GetGameDetailsParams): GatewayResponse<IGetGameDetailsSuccess>;

    abstract editGameDetails(params: EditGameDetailsParams): GatewayResponse<null>;

    abstract getGameDetailsResponse(params: GetGameDetailsResponseParams): GatewayResponse<IGetGameResponsesResponse>;

    abstract getExportResponses(params: GetExportResponsesParams): GatewayResponse<IGameResponse[]>;
}
