import React, { CSSProperties } from 'react';
import { css } from 'styled-components';

import FontStyles from 'assets/themes/Fonts';
import ColorStyles from 'assets/themes/Colors';

export interface StylesDictionary {
    [Key: string]: CSSProperties;
}

export const ContainerStyles: StylesDictionary = {
    mainContainer: {
        display: 'flex',
        marginTop: '50px',
        borderTop: '0.5px solid rgb(0,0,0,0.1)',
        width: '80%',
    },
    spinnerContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
};

export const TableStyles = {
    primary: css`
        font-size:12px;
        border-bottom: none;

        tbody, td, tfoot, th, tr {
            border-style:none !important;
        }

        
        thead {
            font-family: ${FontStyles.primary};
            color: ${ColorStyles.tableHeaderSecondary};
            background-color: ${ColorStyles.white};
            font-weight:400 !important;
            border-bottom: 1px solid;
            border-color: rgba(0,0,0,0.1);
            
            tr {
                th {
                    &:first-child {
                        border-top-left-radius: 10px;

                    }
    
                    &:last-child {
                        border-top-right-radius: 10px;
                    }
                }
            }

        }

        td,th {
            padding: 1rem;
            vertical-align: middle;
        }

        tbody {

            tr {
                border-color: rgba(0,0,0,0.1);

                &:first-child {
                    border-top:1px solid grey;
                }

                &:nth-child(odd) {
                    background-color: #F7F7F7;
                }

                &:nth-child(even) {
                    background-color: ${ColorStyles.white};
                }

                &:hover {

                }

                &:last-child td:first-child { 
                    border-bottom-left-radius: 10px; 
                }

                &:last-child td:last-child { 
                    border-bottom-right-radius: 10px; 
                }
            }
        }

    `,
};

export const ButtonStyles = {
    primary: css`
        min-width:200px !important;
        color: ${ColorStyles.white} !important;
        background-color: ${ColorStyles.iqosPrimary} !important;
        font-size: 18px;
        height:46px;
        border: 1px solid ${ColorStyles.iqosPrimary} !important;;
        font-family: ${FontStyles.secondary};
        border-radius: 20px;
        color: black;
    `,
    secondary: css`
        min-width:200px !important;
        color: ${ColorStyles.iqosPrimary} !important;
        background-color: ${ColorStyles.white} !important;
        font-size: 18px;
        height:46px;
        border: 1px solid ${ColorStyles.white} !important;
        font-family: ${FontStyles.secondary};
        border-radius: 20px;
        color: black;

        &:hover {
            border-color ${ColorStyles.iqosPrimary} !important;
        }
    `,
    error: css`
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    `,
    blank: css`
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    `,
};
