import React, { FunctionComponent } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

export interface ToggleSwitchProps {
    isEnabled: boolean;
    disabled?: boolean;
    [x: string]: any;
    css?: FlattenSimpleInterpolation;

    onClick?: () => void;
}

const ToggleSwitch: FunctionComponent<ToggleSwitchProps> = ({
    onClick,
    ...props
}: ToggleSwitchProps) => {
    return (
        <StyledSwitch {...props}>
            <StyledCheckbox id='checkbox' type='checkbox' checked={props.isEnabled} onClick={onClick} readOnly />
            <StyledSlider htmlFor='checkbox' />
        </StyledSwitch>
    );
};

const StyledSwitch = styled.div<ToggleSwitchProps>`
    position: relative;
    width: 70px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    ${props => props.css}
`;

const StyledSlider = styled.label`
    position: absolute;
    top: 0;
    left: 0;
    width: 68px;
    height: 36px;
    border-radius: 18px;
    background: #bebebe;
    cursor: pointer;

    &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 28px;
        height: 28px;
        margin: 3px;
        background: #ffffff;
        box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
        transition: 0.2s;
    }

`;

const StyledCheckbox = styled.input`
    cursor: pointer;
    height: 100%;
    width: 100%;
    opacity: 0;
    z-index: 1;
    &:checked + ${StyledSlider} {
        background: #00D1D2;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      margin-left: 52%;
      transition: 0.2s;
    }
  }
`;

export default ToggleSwitch;
