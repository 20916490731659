import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';
import {
    IChannelTypeEnum,
    IContest,
    IContestResponse,
    IContestStatusEnum,
    ICreateContest,
    IGetContestDetailsSuccess,
    IGetContestResponsesResponse,

    IGetContestsListResponse,
    IGetContestListApiParam,
} from 'entities/contests';

export interface GetContestsReduxParams {
    status: IContestStatusEnum;
}

export interface GetContestsParams {
    authToken: string;
    type: IChannelTypeEnum;
    status: IContestStatusEnum;
}

export interface CreateContestParams {
    authToken: string;
    data: ICreateContest;
}

export interface DeleteContestParams {
    authToken: string;
    _id: string;
}

export interface GetContestDetailsParams {
    authToken: string;
    id: string;
    type: IChannelTypeEnum;
}

export interface EditContestDetailsParams {
    authToken: string;
    data: {
        id: string;
        qualtricsId?: string;
        status: IContestStatusEnum;
        name?: string;
    }
}

export interface GetContestResponsesParams {
    authToken: string;
    contestId: string;
    index: number;
    type: IChannelTypeEnum;
}

export interface GetExportResponsesParams {
    authToken: string;
    contestId: string;
    type: IChannelTypeEnum;
}

export abstract class IContestsGateway extends Gateway {
    abstract getContests(params: GetContestsParams): GatewayResponse<IContest[]>;

    abstract createContest(params: CreateContestParams): GatewayResponse<IContest[]>;

    abstract deleteContest(params: DeleteContestParams): GatewayResponse<string>;

    abstract getContestDetails(params: GetContestDetailsParams): GatewayResponse<IGetContestDetailsSuccess>;

    abstract editContestDetails(params: EditContestDetailsParams): GatewayResponse<null>;

    abstract getContestDetailsResponses(params: GetContestResponsesParams): GatewayResponse<IGetContestResponsesResponse>;

    abstract getExportResponses(params: GetExportResponsesParams): GatewayResponse<IContestResponse[]>;

    abstract getQuizList(param: IGetContestListApiParam): GatewayResponse<IGetContestsListResponse>;

    abstract getGamesList(param: IGetContestListApiParam): GatewayResponse<IGetContestsListResponse>;
}
