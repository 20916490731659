import React, { useState, useEffect } from 'react';
// ? Redux Toolkit
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import SVG from 'react-inlinesvg';
import styled, { css } from 'styled-components';

import Button from 'components/Button';
import Icons from 'assets/icons/Index';

import { IEditVariant, ProductListDetails, VariantsListDetails } from 'entities/ecommerce';
import EditVariantModal from 'containers/home/eCommerce/EditVariantModal';

// ! interface zone
// * props interface
export interface VariantsListProps {
    productId: string;
    productDetails: ProductListDetails[];

    getProductDetails: (productId: string) => void;

    editProductVariant: (data: IEditVariant) => void;
    deleteProductVariant: (productId: string, variantId: string) => void;
    deleteProductVariantAttempt: boolean;

    setEditVariantModalIsOpen: (state: boolean) => void;
}

// ! JSX function component
const VariantsList = (props: VariantsListProps): JSX.Element => {
    // ! destructuring zone
    // * props destructured
    const {
        productId,
        productDetails,
        getProductDetails,
        editProductVariant,
        deleteProductVariant,
        deleteProductVariantAttempt,
        setEditVariantModalIsOpen,
    } = props;

    const curentProductDetails = productDetails.find((item) => item._id === productId);
    const variantData = curentProductDetails?.productVariant;

    // ! useState Zone
    const [selectedVariantData, setSelectedVariantData] = useState<VariantsListDetails>({
        _id: '',
        variantNameEN: '',
        variantNameBM: '',
        variantImage: '',
        defaultVariant: false,
    });

    // ! useEffect zone
    useEffect(() => {
        if (!deleteProductVariantAttempt) {
            getProductDetails(productId);
        }
    }, [deleteProductVariantAttempt]);

    // ! functions
    const handleDeleteProductVariant = (thisProductId: string, variantId: string) => {
        deleteProductVariant(thisProductId, variantId);
    };

    const handleDefaultVariantChange = (data: VariantsListDetails) => {
        const {
            _id,
            variantNameEN,
            variantNameBM,
            variantImage,
        } = data;

        editProductVariant({
            productId,
            variantId: _id,
            variantNameEN,
            variantNameBM,
            variantImage,
            defaultVariant: true,
        });
    };

    const handleVariantEdit = (data: VariantsListDetails) => {
        setEditVariantModalIsOpen(true);
        setSelectedVariantData(data);
    };

    // ! render zone
    const renderStar = (defaultState: boolean, data: VariantsListDetails) => {
        if (!defaultState) {
            return (
                <StyledActionButton
                    onClick={() => handleDefaultVariantChange(data)}
                    css={styles.starIcon}
                >
                    <SVG src={Icons.StarOutline} id='star' />
                </StyledActionButton>
            );
        }
        return (
            <StyledActionButton
                onClick={() => null}
                css={styles.starIcon}
            >
                <SVG src={Icons.Star} id='star' />
            </StyledActionButton>
        );
    };
    // * main render
    return (
        <VariantContainer>
            {
                variantData && variantData.map((item) => {
                    const { _id, variantNameEN, variantNameBM, defaultVariant, variantImage } = item;
                    const variantName = `${variantNameEN}/${variantNameBM}`;
                    return (
                        <Variant key={_id}>
                            <ImageContainer>
                                <StyledImg src={variantImage} alt={variantNameEN} />
                                {renderStar(defaultVariant, item)}
                            </ImageContainer>
                            <Captioncontainer>
                                <Caption>{variantName}</Caption>
                                <ButtonContainer>
                                    <StyledActionButton
                                        onClick={() => handleVariantEdit(item)}
                                        css={styles.updatedPencilIcon}
                                    >
                                        <SVG src={Icons.Edit} id='edit' />
                                    </StyledActionButton>
                                    <StyledActionButton
                                        onClick={() => handleDeleteProductVariant(productId, _id)}
                                        css={styles.trashIcon}
                                    >
                                        <SVG src={Icons.TrashV2} id='trash' />
                                    </StyledActionButton>
                                </ButtonContainer>
                            </Captioncontainer>
                        </Variant>
                    );
                })
            }
            <EditVariantModal productId={productId} variantData={selectedVariantData} />
        </VariantContainer>
    );
};

// ! styled-components
const VariantContainer = styled.div`
    display: flex;
    gap: 20px;
`;
const Variant = styled.div`
    display: flex;
    flex-direction: column;
`;
const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    border-radius: 5px;
    position: relative;
`;

const StyledImg = styled.img`
    aspect-ratio: 1/1;
    min-width: 250px;
    min-height: 250px;
    max-width: 250px;
    max-height: 250px;
`;
const Captioncontainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 20px;
    max-width: 250px;
`;
const Caption = styled.div`
    display: flex;
    text-overflow: ellipsis;
    overflow: hidden;
`;
const ButtonContainer = styled.div`
    display: flex;
`;

const StyledActionButton = styled(Button)`
    background-color: transparent !important;
`;

const styles = {
    starIcon: css`
    background-color: transparent;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 40px;
    height: 40px;
    #star {
        width: 35px;
        height: 35px;
    }
`,
    trashIcon: css`
        background-color: transparent;
        width: 35px;
        height: 35px;
        #trash {
            width: 35px;
            height: 35px;
            color: #d32f2f;
        }
    `,
    updatedPencilIcon: css`
        background-color: transparent;
        width: 35px;
        height: 35px;
    #pencil {
        width: 35px;
        height: 35px;
        &:hover{
            color: #1976d2;
        }
    }
    `,
};
// ! redux toolkit
// * Selectors
const mapStateToProps = (state: RootState) => ({
    productDetails: Selectors.getECommerceGetProductDetails(state),
    deleteProductVariantAttempt: Selectors.getECommerceDeleteProductVariantAttempting(state),
});
// * Dispatch
const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getProductDetails: (productId: string) => dispatch(Actions.getProductDetailsAttempt({ productId })),
    editProductVariant: (data: IEditVariant) => dispatch(Actions.editProductVariantAttempt(data)),
    deleteProductVariant: (productId: string, variantId: string) => dispatch(Actions.deleteProductVariantAttempt({ productId, variantId })),
    setEditVariantModalIsOpen: (state: boolean) => dispatch(Actions.setEditVariantModalIsOpen(state)),
});
// * Connection
export default connect(mapStateToProps, mapDispatchToProps)(VariantsList);
