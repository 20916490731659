const Colors = {
    primary: '#202030',
    secondary: '#C29A69',

    tableHeaderPrimary: 'rgba(39, 36, 46, 1)',
    tableHeaderSecondary: 'rgba(39, 36, 46, 0.5)',

    faqBlue: 'rgb(241, 246, 248)',

    white: '#fff',
    lightCream: '#FFFDFB',
    black: '#000',
    grey: '#F7F7F7',
    lightGrey: '#A2A2A2',

    iqosPrimary: '#00D1D2',
    iqosLightPrimary: '#B7EFEF',
    iqosError: '#DB3624',

    bgPrimary: '#F1F6F8',

    red: '#DB3624',
    teal: '#00D1D2',
};

export default Colors;
