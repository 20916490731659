import React, { FunctionComponent, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { UncontrolledTooltip } from 'reactstrap';
import dayjs from 'dayjs';
import SVG from 'react-inlinesvg';

import { connect } from 'react-redux';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { AppDispatch, RootState } from 'redux/store';

import Icons from 'assets/icons/Index';
import Fonts from 'assets/themes/Fonts';

import { IChannelTypeEnum } from 'entities/contests';
import Text from 'components/Text';
import Skeleton from 'react-loading-skeleton';
import { SubscribersOverview } from 'entities/summaryDashboard';

const MIN_YEAR_IN_YEAR_PICKER = 2021;

interface OverviewProps {
    isLanu: boolean;
    subscribersOverviewData: SubscribersOverview;
    isLoading: boolean;
    isFailure: string;

    getSubscribersOverview(type: string, year: string): void;
}

interface ArrayType {
    id: string;
    value: JSX.Element[];
}

const Overview: FunctionComponent<OverviewProps> = (props: OverviewProps) => {
    const {
        isLanu,
        subscribersOverviewData,
        isLoading,
        isFailure,

        getSubscribersOverview,
    } = props;

    const [yearPicker, setYearPicker] = useState<number>(2023);

    const arrayNewFollowers: JSX.Element[] = [];
    const arrayUnsubscribers: JSX.Element[] = [];
    const arrayNetFollowers: JSX.Element[] = [];
    const arrayCumulativeFollowers: JSX.Element[] = [];

    const arraySubscribersOverview: ArrayType[] = [];

    const dataHeader = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    useEffect(() => {
        getSubscribersOverview(`${(isLanu) ? IChannelTypeEnum.LANU : IChannelTypeEnum.LAU}`, yearPicker.toString());
    }, [isLanu, yearPicker]);

    const prevYear = () => {
        if (yearPicker > MIN_YEAR_IN_YEAR_PICKER) {
            setYearPicker(yearPicker - 1);
        }
    };

    const nextYear = () => {
        const currentYear = new Date().getFullYear();
        if (yearPicker < currentYear) {
            setYearPicker(yearPicker + 1);
        }
    };

    const prepareData = () => {
        arrayNewFollowers.push(<div>New Followers</div>);
        arrayUnsubscribers.push(<div>Unsubscribers</div>);
        arrayNetFollowers.push(<div>Net Followers</div>);
        arrayCumulativeFollowers.push(<div>Accumulative Followers</div>);

        if (subscribersOverviewData) {
            subscribersOverviewData.data.forEach((item) => {
                const { newFollowers, netFollowers, unsubscribers, cumulativeFollowers } = item;

                // arrayNewFollowers.push(newFollowers.toLocaleString());
                arrayNewFollowers.push(<div title={`${newFollowers.toLocaleString()}`}>{newFollowers.toLocaleString()}</div>);
                arrayUnsubscribers.push(<div title={`${unsubscribers.toLocaleString()}`}>{unsubscribers.toLocaleString()}</div>);
                arrayNetFollowers.push(<div title={`${netFollowers.toLocaleString()}`}>{netFollowers.toLocaleString()}</div>);
                arrayCumulativeFollowers.push(<div title={`${cumulativeFollowers.toLocaleString()}`}>{cumulativeFollowers.toLocaleString()}</div>);
            });
        }

        arraySubscribersOverview.push({ id: `New Followers (${yearPicker} + ${isLanu})`, value: arrayNewFollowers });
        arraySubscribersOverview.push({ id: `Unsubscribers (${yearPicker} + ${isLanu})`, value: arrayUnsubscribers });
        arraySubscribersOverview.push({ id: `Net Followers (${yearPicker} + ${isLanu})`, value: arrayNetFollowers });
        arraySubscribersOverview.push({ id: `Cumulative Followers (${yearPicker} + ${isLanu})`, value: arrayCumulativeFollowers });
    };

    const renderSubscribersOverview = () => {
        if (isLoading) {
            return (
                <tr>
                    <td colSpan={dataHeader.length + 1}>
                        <div>
                            <Skeleton count={5} height={50} />
                        </div>
                    </td>
                </tr>
            );
        }

        if (isFailure.length) {
            return (
                <tr>
                    <td colSpan={dataHeader.length + 1}>
                        <div>
                            <Text style={{ padding: '20px', color: 'red' }}>Something went wrong. Please try again.</Text>
                        </div>
                    </td>
                </tr>
            );
        }

        return (
            arraySubscribersOverview && (
                arraySubscribersOverview.map((item) => {
                    return (
                        <StyledTr key={item.id}>
                            {item.value.map((newItem, index) => {
                                // 5 because of the comma delimiter
                                let tableFontSize = item.value.length > 5 ? 11 : 12;
                                // table column header
                                if (index === 0) tableFontSize = 12;

                                return (
                                    <StyledTd key={`${item.id} + Sub`} style={{ fontSize: tableFontSize }} colSpan={index === 0 ? 2 : 1}>
                                        {newItem}
                                    </StyledTd>
                                );
                            })}
                        </StyledTr>
                    );
                })
            )
        );
    };

    prepareData();

    return (
        <div style={{ marginTop: '5%', width: '100%', marginRight: '4%', paddingLeft: '1.6%' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <StyledText style={{ display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center', fontFamily: `${Fonts.secondary}` }}>
                    {`Subscribers Overview (${dayjs(new Date()).format('YYYY')})`}
                    <img src={Icons.QuestionMark} alt='Tooltip' id='recurringPostTooltip' style={{ width: '20px', border: '1px solid #00D1D2', borderRadius: '60px', padding: '2px', backgroundColor: '#00D1D2' }} />
                    <UncontrolledTooltip placement='right' target='recurringPostTooltip' style={{ backgroundColor: '#00D1D2', color: 'black', fontSize: '10px', textAlign: 'justify', padding: '10px' }}>
                        Subscribers overview displays the data from the 1st January of the current year until the current date of the particular year.
                    </UncontrolledTooltip>
                </StyledText>
                <MonthWrapper>
                    <StyledButton onClick={() => prevYear()}>
                        <SVG
                            style={{
                                width: '13px',
                                strokeWidth: '0.1px',
                                transform: 'rotate(270deg)',
                            }}
                            src={Icons.ChoosingArrow}
                            id='editIcon'
                        />
                    </StyledButton>
                    <StyledText style={{ fontSize: '18px', fontFamily: `${Fonts.secondary}`, minWidth: '100px', textAlign: 'center', color: 'black' }}>
                        {`${dayjs(yearPicker.toString()).format('YYYY')}`}
                    </StyledText>
                    <StyledButton onClick={() => nextYear()}>
                        <SVG
                            style={{
                                width: '13px',
                                strokeWidth: '0.1px',
                                transform: 'rotate(90deg)',
                            }}
                            src={Icons.ChoosingArrow}
                            id='editIcon'
                        />
                    </StyledButton>
                </MonthWrapper>
            </div>
            <StyledTable>
                <StyledTHead>
                    <tr>
                        {dataHeader.map((item, index) => (
                            <StyledTh key={item} colSpan={index === 0 ? 2 : 1}>
                                {item}
                            </StyledTh>
                        ))}
                    </tr>
                </StyledTHead>
                <StyledTableBody>
                    {renderSubscribersOverview()}
                </StyledTableBody>
            </StyledTable>
        </div>
    );
};

const MonthWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;
`;

const StyledButton = styled.button<{isYtd?: boolean}>`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F1F6F8;

    border-radius: 5px;
    border: 1px solid black;

    width: 40px;
    height: 40px;

    ${props => (props.isYtd === true) && css`
        border: 1px solid lightgrey;
        fill: lightgrey;
    `}
`;

const StyledText = styled(Text)`
    font-size: 25px;
`;

const StyledTable = styled.table`
    width: 100%;
    background-color: white;
    border-collapes: collapse;
    table-layout: fixed;
    
    margin-top: 20px;
    border-radius: 10px !important;
`;

const StyledTHead = styled.thead`
    text-align: center;
    border-bottom: 1px solid #C5C5C5;
`;

const StyledTableBody = styled.tbody`
    text-align: center;
`;

const StyledTh = styled.th`
    padding: 20px;
    border-bottom: 1px solid rgb(0,0,0,0,3);
    font-size: 15px;
    color: #959595;
    font-weight: lighter;

    &:first-of-type {
        text-align: center;
    }
`;

const StyledTr = styled.tr`
    &:hover {
        background-color: #00D1D2;
    }
    
    &:not(:last-of-type){
        border-bottom: 5px solid #ffffff;
    }

    &:nth-of-type(odd){
        background-color: #F6FCFC;
        &:hover {
            background-color: #00D1D2;
        }
    }
`;

const StyledTd = styled.td`
    padding: 16px;
    font-size: 16px;
    whiteSpace: nowrap;
    overflow: hidden;
    text-Overflow: ellipsis;
`;

const mapStateToProps = (state: RootState) => ({
    isLanu: Selectors.getFaqGetIsLANU(state),
    subscribersOverviewData: Selectors.getSummaryDashboardGetSubscribersOverview(state),
    isLoading: Selectors.getSummaryDashboardGetSubscribersOverviewAttempting(state),
    isFailure: Selectors.getSummaryDashboardGetSubscribersOverviewFailure(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getSubscribersOverview: (type: string, year: string) => dispatch(Actions.getSubscribersOverviewAttempt({ type, year })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
