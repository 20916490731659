import { put, call, select, takeEvery } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import PostsGateway from 'api/Posts';
import { PostsReportExportPayloadParams } from 'redux/slices/posts/types';
import xlsx, { IContent } from 'json-as-xlsx';
import Utils from 'lib/Utils';
import dayjs from 'dayjs';

export default function* watchGetPostsReportExport(api: PostsGateway): SagaWatcherReturnType {
    yield takeEvery('posts/getPostsReportExportAttempt', handlePostsReportExport, api);
}

function* handlePostsReportExport(api: PostsGateway, data: PostsReportExportPayloadParams) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.getPostsReportExport], {
        authToken,
        category: data.payload.category,
        channelType: data.payload.channelType,
        dateFrom: data.payload.dateFrom,
        dateTo: data.payload.dateTo,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.getPostsReportExportFailure(response.message || ''));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        const { data: postsResponse } = response;

        const massageData = postsResponse.map((item) => {
            return {
                ...item,
                date: dayjs(item.date).format('DD-MMM-YYYY'),
                viewRate: (item.viewRate !== undefined) ? Utils.Posts.rateConverter(item.viewRate) : '-',
                engagementRate: (item.engagementRate !== undefined) ? Utils.Posts.rateConverter(item.engagementRate) : '-',
            };
        });

        interface StringDictionary {
            [key: string]: any;
        }

        const excelColumns = [{
            label: 'Post ID',
            value: 'postId',
        }, {
            label: 'Title',
            value: 'title',
        }, {
            label: 'Media Url',
            value: 'mediaUrl',
        }, {
            label: 'Date',
            value: 'date',
        }, {
            label: 'Category',
            value: 'postCategory',
        }, {
            label: 'Views',
            value: 'views',
        }, {
            label: 'View Rate ( % )',
            value: 'viewRate',
        }, {
            label: 'Engagement',
            value: 'engagement',
        }, {
            label: 'Engagement Rate ( % )',
            value: 'engagementRate',
        }, {
            label: 'Reactions',
            value: 'reactions',
        }, {
            label: 'Responses',
            value: 'responses',
        }, {
            label: 'Clicks',
            value: 'clicks',
        }, {
            label: 'Language',
            value: 'language',
        }];

        let rateByPostDataToExport: IContent[] = [];
        rateByPostDataToExport = massageData as unknown as StringDictionary[];

        const rateByPostToDownload = [{
            sheet: 'Post Data',
            columns: excelColumns,
            content: rateByPostDataToExport,
        }];

        xlsx(rateByPostToDownload, { fileName: 'Post Data (Posts Report)' });

        yield put(Actions.getPostsReportExportSuccess(response.data));
    }
}
