import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { Row, Col, Spinner, Input as UploadInput } from 'reactstrap';
import { connect } from 'react-redux';
import reactImageSize from 'react-image-size';
import ReactPlayer from 'react-player';
import axios from 'axios';
import SVG from 'react-inlinesvg';

import { ICreateFaqDTO } from 'redux/slices/faq/types';
import { RootState, AppDispatch } from 'redux/store';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

import IQOSModal from 'components/IQOSModal';
import FaqTypeDD from 'components/faq/FaqTypeDD';
import Flex from 'components/Flex';
import FollowersHeader from 'components/analytics/FollowersHeader';
import TextArea from 'components/TextArea';

import Colors from 'assets/themes/Colors';
import Icons from 'assets/icons/Index';

import Validators from 'lib/Validators';

import Config from 'config';

import { FaqMessageTypeEnum } from 'entities/faq';
import AnalyticsHeader from 'components/analytics/AnalyticsHeader';
import PdfViewer from 'components/faq/PdfViewer';

interface CreateFaqModalProps {
    authToken: string;

    id: string;
    header?: string;
    isOpen: boolean;
    toggle: () => void;
    onConfirmed(data: ICreateFaqDTO): void;
}

const CreateFaqModal:FunctionComponent<CreateFaqModalProps> = ({
    authToken,

    id,
    header,
    isOpen,
    toggle,
    onConfirmed,
}:CreateFaqModalProps) => {
    const [hasChanged, setHasChanged] = useState<boolean>(false);

    const [wordLimit, setWordLimit] = useState<number>(4094);

    const [allowedExtensions, setAllowedExtensions] = useState<string>('');
    const [uploadError, setUploadError] = useState('');
    const [uploading, setUploading] = useState<boolean>(false);

    const [cardType, setCardType] = useState<FaqMessageTypeEnum>(0);
    const [cardBody, setCardBody] = useState<string>('');
    const [cardCaption, setCardCaption] = useState<string>('');
    const [cardTitle, setCardTitle] = useState<string>('');

    const [formError, setFormError] = useState<string>('');

    const fileInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setCardBody('');
        setCardCaption('');

        if (cardType === FaqMessageTypeEnum.Text) {
            setWordLimit(4094);
            setAllowedExtensions('');
        } else {
            setWordLimit(1021);
            if (cardType === FaqMessageTypeEnum.Image) setAllowedExtensions('image/jpg, image/jpeg, image/png');
            if (cardType === FaqMessageTypeEnum.Video) setAllowedExtensions('video/mp4, image/gif');
            if (cardType === FaqMessageTypeEnum.Document) setAllowedExtensions('application/pdf');
        }
    }, [cardType]);

    useEffect(() => {
        if (isOpen === false) {
            setCardTitle('');
            setCardType(0);
            setCardBody('');
            setCardCaption('');

            setWordLimit(4094);

            setHasChanged(false);
            setFormError('');

            setUploadError('');
            setAllowedExtensions('');
        }
    }, [isOpen]);

    const videoFileHandler = (event: React.SyntheticEvent) => {
        const target = event.target as HTMLInputElement;

        if (target.files) {
            const [file] = target.files;
            if (checkFileExtension(file.name, cardType)) {
                const { size } = file;

                const videoSizeError = Validators.ValidateVideoSize(size);

                setUploadError(videoSizeError);

                if (!videoSizeError.length) {
                    uploadFile(file);
                }
            } else {
                setUploadError('Only single .mp4 .gif file is allowed');
            }
        }
    };

    const imageFileHandler = async (event: React.SyntheticEvent) => {
        const target = event.target as HTMLInputElement;

        if (target.files) {
            const [file] = target.files;
            if (checkFileExtension(file.name, cardType)) {
                const img = new Image();

                img.src = window.URL.createObjectURL(file);

                if (target.files) {
                    const { size } = file;
                    try {
                        const { width, height } = await reactImageSize(img.src);

                        const heightWidthRatio = width / height;

                        const imageDimensionsError = Validators.ValidateImageDimensions(size, width, height, heightWidthRatio);

                        setUploadError(imageDimensionsError);

                        if (!imageDimensionsError.length) {
                            uploadFile(file);
                        }
                    } catch (e) {
                        if (e instanceof Error) {
                            setUploadError(e.toString());
                        }
                    }
                }
            } else {
                setUploadError('Only single .jpeg .jpg .png file is allowed');
            }
        }
    };

    const documentFileHandler = (event: React.SyntheticEvent) => {
        const target = event.target as HTMLInputElement;

        if (target.files) {
            const [file] = target.files;
            if (checkFileExtension(file.name, cardType)) {
                const { size } = file;

                const documentSizeError = Validators.ValidateDocumentSize(size);

                setUploadError(documentSizeError);

                if (!documentSizeError.length) {
                    uploadFile(file);
                }
            } else {
                setUploadError('Only single .pdf file is allowed');
            }
        }
    };

    const checkFileExtension = (filename:string, type: FaqMessageTypeEnum) => {
        const fileExt = filename.slice(-3);
        if (type === FaqMessageTypeEnum.Document) {
            return fileExt === 'pdf';
        }

        if (type === FaqMessageTypeEnum.Image) {
            return !!((fileExt === 'png' || fileExt === 'jpg' || fileExt === 'peg'));
        }

        if (type === FaqMessageTypeEnum.Video) {
            return !!((fileExt === 'gif' || fileExt === 'mp4'));
        }

        return false;
    };

    const onInputFileChange = (event: React.SyntheticEvent) => {
        setCardBody('');
        setUploadError('');

        if (cardType !== FaqMessageTypeEnum.Text) {
            switch (cardType) {
                case FaqMessageTypeEnum.Image:
                    imageFileHandler(event);
                    break;
                case FaqMessageTypeEnum.Video:
                    videoFileHandler(event);
                    break;
                case FaqMessageTypeEnum.Document:
                    documentFileHandler(event);
                    break;
                default:
                    break;
            }
        }
    };

    const uploadFile = (file: File) => {
        setUploading(true);
        setUploadError('');

        const endpoint = `${Config.baseUrl}/v1/faq/fileUpload`;

        const formData = new FormData();
        formData.append('media', file);
        formData.append('fileType', cardType?.toString() || '');

        const config = {
            headers: {
                Authorization: `Bearer ${authToken}`,
                'content-type': 'multipart/form-data',
            },
        };

        axios.post(endpoint, formData, config).then(response => {
            const url = response.data || '';
            if (cardType !== FaqMessageTypeEnum.Text) {
                setCardBody(url);
                setHasChanged(true);
                setUploading(false);
                setUploadError('');
            }
        }).catch((err: any) => {
            setUploading(false);
            setUploadError(err.message);
        });
    };

    const removeFile = () => {
        setCardBody('');
        setHasChanged(true);
    };

    const onClickFileInputHandler = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        const element = event.target as HTMLInputElement;
        element.value = '';
    };

    const createFaqHandler = () => {
        let validateError = '';

        const trimmedTitle = cardTitle.trim();
        const trimmedText = cardBody.trim();
        const trimmedCaption = cardCaption.trim();

        if (cardType === FaqMessageTypeEnum.Text) {
            validateError = Validators.ValidateTextFaq(trimmedTitle, trimmedText);
            setFormError(validateError);
        }

        if (cardType === FaqMessageTypeEnum.Image) {
            if (cardCaption)validateError = Validators.ValidateDocumentFaq(trimmedTitle, trimmedCaption, trimmedText);
            setFormError(validateError);
        }

        if (cardType === FaqMessageTypeEnum.Video) {
            if (cardCaption)validateError = Validators.ValidateDocumentFaq(trimmedTitle, trimmedCaption, trimmedText);
            setFormError(validateError);
        }

        if (cardType === FaqMessageTypeEnum.Document) {
            if (cardCaption)validateError = Validators.ValidateDocumentFaq(trimmedTitle, trimmedCaption, trimmedText);
            setFormError(validateError);
        }

        if (!validateError.length && !uploadError.length) {
            setFormError('');
            setUploadError('');

            let massagedData: ICreateFaqDTO;

            if (cardType === FaqMessageTypeEnum.Text) {
                massagedData = {
                    parentId: id,
                    title: cardTitle.trim(),
                    messages: [{
                        text: cardBody.trim(),
                        type: cardType,
                    }],
                };
            } else {
                massagedData = {
                    parentId: id,
                    title: cardTitle.trim(),
                    messages: [{
                        text: cardBody.trim(),
                        type: cardType,
                        caption: cardCaption.trim(),
                    }],
                };
            }

            onConfirmed(massagedData);
            toggle();
        }
    };

    const renderPreviewFile = () => {
        const renderFile = () => {
            if (!cardBody.length) {
                return (
                    <Flex justifyContent='center' alignItems='center' height='200px' width='100%'>
                        <StyledButton
                            type='button'
                            onClick={() => fileInputRef.current?.click()}
                            css={styles.noImageButton}
                        >
                            No File Uploaded
                        </StyledButton>
                    </Flex>
                );
            }

            if (cardBody && cardType === FaqMessageTypeEnum.Image) {
                return (<StyledImg src={cardBody} alt={cardTitle} css={styles.imageWrapper} />);
            }

            if (cardBody && cardType === FaqMessageTypeEnum.Video) {
                const videoFormat = cardBody.slice(-3);
                if (videoFormat === 'gif') {
                    return (<StyledImg src={cardBody} alt={cardTitle} css={styles.imageWrapper} />);
                }

                return (
                    <ReactPlayer
                        url={cardBody}
                        playing
                        light
                        style={{ width: '100%', maxWidth: '470px', height: 'auto', maxHeight: '200px', backgroundColor: 'rgba(0,0,0,0.1)' }}
                        controls
                    />
                );
            }

            if (cardBody && cardType === FaqMessageTypeEnum.Document) {
                return (
                    <PdfViewer
                        file={cardBody}
                        pdfWrapper={styles.pdfWrapper}
                    />
                );
            }

            return null;
        };

        return uploading

            ? (
                <Flex justifyContent='center' alignItems='center' height='250px' width='100%'>
                    <Spinner size='lg' />
                </Flex>
            )
            : (
                <Flex width='100%' justifyContent='center' alignItems='center' padding='0.6rem 0 0 0' flexDirection='column'>
                    {renderFile()}
                    {cardBody ? (
                        <StyledButton type='button' onClick={removeFile} css={styles.removeFileButton}>
                            <Flex justifyContent='center' alignItems='center' gap='0.5rem' width='100%'>
                                <div>Remove</div>
                                <SVG src={Icons.TrashIcon} />
                            </Flex>
                        </StyledButton>
                    ) : null}
                </Flex>
            );
    };

    return (
        <IQOSModal
            isOpen={isOpen}
            toggle={toggle}
            size='lg'
            header={header || 'Add New FAQ Card'}
            wrapperCSS={styles.modal}
            bodyCSS={styles.modalBody}
        >
            <FaqTypeWrapper>
                <FaqTypeDD onChange={(type: FaqMessageTypeEnum) => setCardType(type)} displayIcons />
            </FaqTypeWrapper>
            <Row>
                <Col>
                    <Flex flexDirection='column' gap='0.5rem'>
                        <FollowersHeader
                            headerContent='Question'
                            headerSize='sm'
                            headerColor={Colors.lightGrey}
                            headerWeight='thin'
                            tooltipIconSize='1rem'
                            tooltipId={`add-question-${id}`}
                            tooltipIconSrc={Icons.QuestionMark}
                            tooltipMaxWidth='400px'
                            tooltipContent='This text will be displayed as the selectable options of the FAQ. It will also be repeated at the top section of the message.'
                        />
                        <StyledInput
                            type='text'
                            value={cardTitle}
                            onChange={(e) => {
                                setHasChanged(true);
                                setCardTitle(e.target.value);
                            }}
                        />
                    </Flex>
                    <Flex margin='.8rem 0 0 0' flexDirection='column' gap='0.5rem' height='calc(100% - 16px)'>
                        <Flex justifyContent='space-between' gap='wrap' flexWrap='wrap'>
                            <FollowersHeader
                                headerContent={cardType === FaqMessageTypeEnum.Text ? 'Message' : 'Caption'}
                                headerSize='sm'
                                headerColor={Colors.lightGrey}
                                headerWeight='thin'
                                tooltipIconSize='1rem'
                                tooltipId={`add-${cardType === FaqMessageTypeEnum.Text ? 'Message' : 'Caption'}-${id}`}
                                tooltipIconSrc={Icons.QuestionMark}
                                tooltipMaxWidth='400px'
                                tooltipContent={cardType === FaqMessageTypeEnum.Text ? 'This will be the content of the selected option.' : ' The caption will be display at the bottom of the asset, right after the title.'}
                            />
                            <Flex gap='0.5rem' flexWrap='nowrap'>
                                <div>Number of characters</div>
                                <div>
                                    {cardType === FaqMessageTypeEnum.Text
                                        ? cardTitle.length + cardBody.length
                                        : cardTitle.length + cardCaption.length}
                                    {' / '}
                                    {wordLimit}
                                </div>
                            </Flex>
                        </Flex>
                        <TextArea
                            css={styles.textarea}
                            value={cardType === FaqMessageTypeEnum.Text ? cardBody : cardCaption}
                            onChange={(e) => {
                                setHasChanged(true);
                                if (cardType === FaqMessageTypeEnum.Text) {
                                    setCardBody(e.target.value);
                                } else {
                                    setCardCaption(e.target.value);
                                }
                            }}
                        />
                    </Flex>
                </Col>
                {cardType !== FaqMessageTypeEnum.Text ? (
                    <Col>
                        <Flex flexDirection='column' gap='0.5rem'>
                            <AnalyticsHeader
                                header='Upload Media (optional)'
                                size='sm'
                                fontColor={Colors.lightGrey}
                                fontWeight='thin'
                            />
                            <Flex width='100%' justifyContent='space-between' gap='.5rem'>
                                <StyledButton
                                    disabled={cardType !== FaqMessageTypeEnum.Image}
                                    css={styles.faqTypeButton}
                                    type='button'
                                    onClick={() => fileInputRef.current?.click()}
                                >
                                    <Flex justifyContent='center' alignItems='center' gap='0.5rem'>
                                        <div>Image</div>
                                        <SVG src={Icons.Image} />
                                    </Flex>
                                </StyledButton>
                                <StyledButton
                                    disabled={cardType !== FaqMessageTypeEnum.Video}
                                    css={styles.faqTypeButton}
                                    type='button'
                                    onClick={() => fileInputRef.current?.click()}
                                >
                                    <Flex justifyContent='center' alignItems='center' gap='0.5rem'>
                                        <div>Video</div>
                                        <SVG src={Icons.Video} />
                                    </Flex>
                                </StyledButton>
                                <StyledButton
                                    disabled={cardType !== FaqMessageTypeEnum.Document}
                                    css={styles.faqTypeButton}
                                    type='button'
                                    onClick={() => fileInputRef.current?.click()}
                                >
                                    <Flex justifyContent='center' alignItems='center' gap='0.5rem'>
                                        <div>PDF</div>
                                        <SVG src={Icons.Pdf} />
                                    </Flex>
                                </StyledButton>
                            </Flex>
                        </Flex>
                        <Flex flexDirection='column' gap='0.5rem' padding='0.8rem 0 0 0'>
                            <AnalyticsHeader
                                header='Media Preview'
                                size='sm'
                                fontColor={Colors.lightGrey}
                                fontWeight='thin'
                            />
                            <UploadInput
                                type='file'
                                innerRef={fileInputRef}
                                onChange={(e) => onInputFileChange(e)}
                                onClick={onClickFileInputHandler}
                                style={{ display: 'none' }}
                                accept={allowedExtensions}
                            />
                            {renderPreviewFile()}

                        </Flex>
                    </Col>
                ) : null}

            </Row>
            {formError.length > 1 && (
                <Flex padding='1rem 0 0 .3rem'>
                    <FormError>{formError}</FormError>
                </Flex>
            )}
            {uploadError.length > 1 && (
                <Flex padding='1rem 0 0 .3rem'>
                    <FormError>{uploadError}</FormError>
                </Flex>
            )}
            <Flex justifyContent='flex-start' gap='1rem' padding='1rem 0px 0rem 0rem'>
                <StyledButton
                    css={styles.primaryButton}
                    type='button'
                    onClick={() => createFaqHandler()}
                    disabled={!hasChanged}
                >
                    Create FAQ
                </StyledButton>
                <StyledButton
                    css={styles.cancelButton}
                    type='button'
                    onClick={toggle}
                >
                    Cancel
                </StyledButton>
            </Flex>

        </IQOSModal>
    );
};

interface StyledCSSProps {
    css?: FlattenSimpleInterpolation;
}

interface StyledInputProps {
    error?: boolean;
}

const StyledImg = styled.img<StyledCSSProps>`
    ${props => props.css};
`;

const StyledButton = styled.button<StyledCSSProps>`
    padding: 0.3rem 1.5rem;
    border-radius:20px;
    border-style:solid;

    ${props => props.css}:
`;

const StyledInput = styled.input<StyledInputProps & StyledCSSProps>`
    background-color: #F7F7F7;
    border-color: transparent;

    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-clip: padding-box;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    ${props => props.error && (`border: 1px solid ${Colors.red};`)}

    &:focus {
        background-color: #F7F7F7;
        ${props => props.error && (`border: 1px solid ${Colors.red};`
    )}
        outline:none;
    }

    ${props => props.css}
`;

const styles = {
    modal: css`
        max-width: 960px;
    `,
    modalBody: css`
        padding-bottom: 1rem !important;
    `,

    primaryButton: css`
        background-color: ${Colors.iqosPrimary};
        border: 1px solid ${Colors.iqosPrimary};
        color: ${Colors.white};

        &:hover {
            background-color: ${Colors.white};
            color: ${Colors.iqosPrimary};
        }

        &:disabled{
            background-color: ${Colors.lightGrey};
            border-color: ${Colors.lightGrey};
            cursor: not-allowed;

            &:hover {
                color: ${Colors.white};
            }
        }
    
    `,
    cancelButton: css`
        background-color: ${Colors.white};
        border: 1px solid rgba(0,0,0,0.5);

        &:hover {
            opacity: 0.7;
        }
    `,
    textarea: css`
        min-height:200px;
        margin-top: 0.5rem;
        background-color: #F7F7F7;
        border-color: transparent;
        max-height:calc(100% - 108px);
    `,
    faqTypeButton: css`
        padding: 0.4rem 2.5rem;
        position:relative;
        border-radius: 20px;
        font-weight:bold;

        background-color: ${Colors.white};
        border: 1px solid rgba(0,0,0,0.2);
        border-radius:4px;
        width:100%; 
    `,
    pdfWrapper: css`
        height: 100%;
        max-height:310px;
        width:100% !important;
        max-width:470px !important;
        overflow:auto;
        margin: 0 !important;
    `,
    noImageButton: css`
        border: 5px dashed rgba(0,0,0,0.1); 
        height: 100%; 
        width: 100%;
    `,
    removeFileButton: css`
        background-color: ${Colors.white};
        color: ${Colors.red};
        border: 1px solid ${Colors.red};
        width:100%;
        margin-top:7px;
        border-radius:4px;

        &:hover{
            background-color: ${Colors.red};
            border:1px solid ${Colors.white};
            color: ${Colors.white};
        }

        &:disabled {
            opacity: 0.3;
            cursor: not-allowed;
        }
    `,
    imageWrapper: css`
        object-fit: fill;
        width:100%;
    `,

};

const FormError = styled.div`
    color:${Colors.red};
`;

const FaqTypeWrapper = styled.div`
    position:absolute;
    right:2rem;
    top:27px;
`;

const mapStateToProps = (state: RootState) => ({
    authToken: Selectors.getAuthAuthToken(state),
});

export default connect(mapStateToProps)(CreateFaqModal);
