import { ApiResponse } from 'apisauce';
import { EditPostsReport, EditToggleColumns, GraphExportInfo, InformationPostModalInfo, PerformingPosts, PostsOverview, PostsReport, PostsReportExport, PostsReportWithAmountOfDays, RateByCategories, RateByPost, RateByPostingTime, UserClaims } from 'entities/posts';
import { SummaryDashboardDetails } from 'entities/summaryDashboard';
import { GatewayResponse } from './types/types';
import {
    EditPostsReportParams,
    EditToggleClaimsParams,
    GetPerformingPostsParams,
    GetPostsOverviewParams,
    GetPostsReportExportParams,
    GetPostsReportParams,
    GetPostsReportWithAmountOfDaysParams,
    GetRateByCategoriesExportParams,
    GetRateByCategoriesModalInfoParams,
    GetRateByCategoriesParams,
    GetRateByPostExportParams,
    GetRateByPostingTimeExportParams,
    GetRateByPostingTimeModalInfoParams,
    GetRateByPostingTimeParams,
    GetRateByPostModalInfoParams,
    GetRateByPostParams,
    GetSummaryDashboardDetailsParams,
    GetUserClaimsParams,
    IPostsGateway,
} from './PostsBase';

const getHeaders = (authToken: string) => ({
    headers: {
        Authorization: `Bearer ${authToken}`,
    },
});

export default class PostsGateway extends IPostsGateway {
    async getPostsOverview(params: GetPostsOverviewParams): GatewayResponse<PostsOverview> {
        const { channelType, month } = params;
        const response: ApiResponse<PostsOverview> = await this.api.get('/v1/analytics/posts/overview', { channelType, month }, getHeaders(params.authToken));

        return this.process(response);
    }

    async getRateByCategories(params: GetRateByCategoriesParams): GatewayResponse<RateByCategories[]> {
        const { channelType, dateFrom, dateTo } = params;
        const response: ApiResponse<RateByCategories[]> = await this.api.get('/v1/analytics/posts/averageInCategories', { channelType, dateFrom, dateTo }, getHeaders(params.authToken));

        return this.process(response);
    }

    async getRateByCategoriesModalInfo(params: GetRateByCategoriesModalInfoParams): GatewayResponse<InformationPostModalInfo> {
        const { channelType, category, dateFrom, dateTo, index } = params;
        const response: ApiResponse<InformationPostModalInfo> = await this.api.get('/v1/analytics/posts/categories', { category, channelType, dateFrom, dateTo, index }, getHeaders(params.authToken));

        return this.process(response);
    }

    async getRateByCategoriesExport(params: GetRateByCategoriesExportParams): GatewayResponse<GraphExportInfo[]> {
        const { channelType, dateFrom, dateTo, category } = params;
        const response: ApiResponse<GraphExportInfo[]> = await this.api.get('/v1/analytics/posts/categories/export', { category, channelType, dateFrom, dateTo }, getHeaders(params.authToken));

        return this.process(response);
    }

    async getRateByPostingTime(params: GetRateByPostingTimeParams): GatewayResponse<RateByPostingTime[]> {
        const { channelType, dateFrom, dateTo } = params;
        const response: ApiResponse<RateByPostingTime[]> = await this.api.get('/v1/analytics/posts/averageInPostingTime', { channelType, dateFrom, dateTo }, getHeaders(params.authToken));

        return this.process(response);
    }

    async getRateByPostingTimeModalInfo(params: GetRateByPostingTimeModalInfoParams): GatewayResponse<InformationPostModalInfo> {
        const { channelType, hour, dateFrom, dateTo, index } = params;
        const response: ApiResponse<InformationPostModalInfo> = await this.api.get('/v1/analytics/posts/postingHour', { hour, dateFrom, dateTo, channelType, index }, getHeaders(params.authToken));

        return this.process(response);
    }

    async getRateByPostingTimeExport(params: GetRateByPostingTimeExportParams): GatewayResponse<GraphExportInfo[]> {
        const { channelType, dateFrom, dateTo, hour } = params;
        const response: ApiResponse<GraphExportInfo[]> = await this.api.get('/v1/analytics/posts/postingHour/export', { hour, dateFrom, dateTo, channelType }, getHeaders(params.authToken));

        return this.process(response);
    }

    async getRateByPost(params: GetRateByPostParams): GatewayResponse<RateByPost[]> {
        const { channelType, dateFrom, dateTo } = params;
        const response: ApiResponse<RateByPost[]> = await this.api.get('/v1/analytics/posts/averageInPostingQuantity', { channelType, dateFrom, dateTo }, getHeaders(params.authToken));

        return this.process(response);
    }

    async getRateByPostModalInfo(params: GetRateByPostModalInfoParams): GatewayResponse<InformationPostModalInfo> {
        const { channelType, dateFrom, dateTo, index, week } = params;
        const response: ApiResponse<InformationPostModalInfo> = await this.api.get('/v1/analytics/posts/postingWeek', { channelType, dateFrom, dateTo, index, week }, getHeaders(params.authToken));

        return this.process(response);
    }

    async getRateByPostExport(params: GetRateByPostExportParams): GatewayResponse<GraphExportInfo[]> {
        const { channelType, dateFrom, dateTo, week } = params;
        const response: ApiResponse<GraphExportInfo[]> = await this.api.get('/v1/analytics/posts/postingWeek/export', { channelType, dateFrom, dateTo, week }, getHeaders(params.authToken));

        return this.process(response);
    }

    async getPostsReport(params: GetPostsReportParams): GatewayResponse<PostsReport> {
        const { channelType, dateFrom, dateTo, category, index } = params;
        const response: ApiResponse<PostsReport> = await this.api.get('/v1/analytics/posts/postsReport', { dateFrom, dateTo, channelType, index, category }, getHeaders(params.authToken));

        return this.process(response);
    }

    async getPostsReportExport(params: GetPostsReportExportParams): GatewayResponse<PostsReportExport[]> {
        const { channelType, dateFrom, dateTo, category } = params;
        const response: ApiResponse<PostsReportExport[]> = await this.api.get('/v1/analytics/posts/postsReport/export', { dateFrom, dateTo, channelType, category }, getHeaders(params.authToken));

        return this.process(response);
    }

    async editPostsReport(params: EditPostsReportParams): GatewayResponse<EditPostsReport> {
        const { postId, postCategory, clicks, language } = params.data;
        const response: ApiResponse<EditPostsReport> = await this.api.put('/v1/analytics/posts/', { postId, postCategory, clicks, language }, getHeaders(params.authToken));

        return this.process(response);
    }

    async getPostsReportWithAmountOfDays(params: GetPostsReportWithAmountOfDaysParams): GatewayResponse<PostsReportWithAmountOfDays> {
        const { channelType, dateFrom, dateTo, customDays, index } = params;
        const response: ApiResponse<PostsReportWithAmountOfDays> = await this.api.get('/v1/analytics/posts/postPerformanceByDays', { dateFrom, dateTo, channelType, index, customDays }, getHeaders(params.authToken));

        return this.process(response);
    }

    async getPerformingPosts(params: GetPerformingPostsParams): GatewayResponse<PerformingPosts> {
        const { channelType, dateFrom, dateTo, sort } = params;
        const response: ApiResponse<PerformingPosts> = await this.api.get('/v1/analytics/posts/topLeastPerformingPosts', { dateFrom, dateTo, channelType, sort }, getHeaders(params.authToken));

        return this.process(response);
    }

    async getUserClaims(params: GetUserClaimsParams): GatewayResponse<UserClaims> {
        const response: ApiResponse<UserClaims> = await this.api.get('/v1/user/claims', {}, getHeaders(params.authToken));

        return this.process(response);
    }

    async editToggleColumn(params: EditToggleClaimsParams): GatewayResponse<EditToggleColumns> {
        const { reportEnum, column, remove } = params.data;
        const response: ApiResponse<EditToggleColumns> = await this.api.post('/v1/user/toggleColumn', { reportEnum, column, remove }, getHeaders(params.authToken));

        return this.process(response);
    }

    async getSummaryDashboardDetails(params: GetSummaryDashboardDetailsParams): GatewayResponse<SummaryDashboardDetails> {
        const {
            type,
            dateFrom,
            dateTo,
            isYtd,
        } = params;
        const response: ApiResponse<SummaryDashboardDetails> = await this.api.get(`/v1/analytics/summary?channelType=${type}&dateFrom=${dateFrom}&dateTo=${dateTo}&isYtd=${isYtd}`, {}, getHeaders(params.authToken));

        return this.process(response);
    }
}
