import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { connect } from 'react-redux';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { AppDispatch, RootState } from 'redux/store';

import Fonts from 'assets/themes/Fonts';

import { StatusTypeEnum } from 'entities/broadcast';

import SchedulePostTable from 'components/broadcast/SchedulePostTable';
import EditScheduleBroadcastModal from 'components/broadcast/EditScheduleBroadcastModal';

interface ScheduledPostProps {
    setEditScheduleBroadcastModalIsOpen(data: boolean): void;

    getBroadcastId: string;
    getEditScheduleBroadcastModalIsOpen: boolean;
}

const ScheduledPost: FunctionComponent<ScheduledPostProps> = (props: ScheduledPostProps) => {
    const {
        setEditScheduleBroadcastModalIsOpen,

        getBroadcastId,
        getEditScheduleBroadcastModalIsOpen,
    } = props;

    dayjs.extend(utc);

    const [scheduleDropdownIsOpen, setScheduleDropdownIsOpen] = useState<boolean>(false);
    const [scheduleStatus, setScheduleStatus] = useState<StatusTypeEnum>(StatusTypeEnum.All);
    const [scheduleFilter, setScheduleFilter] = useState<string>('Show all');

    const toggleEditScheduleModal = (isBroadcast: boolean) => {
        if (isBroadcast) {
            setEditScheduleBroadcastModalIsOpen(true);
        }
    };

    useEffect(() => {
        renderSchedulePostTable();
    }, [scheduleStatus]);

    const renderSchedulePostTable = () => {
        return (
            <>
                <EmptyContainerForHashLink />

                <div style={{ width: '100%', padding: '0px 20px 30px 20px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <TableName>
                            <StyledText>Scheduled Post</StyledText>
                        </TableName>
                        <Dropdown isOpen={scheduleDropdownIsOpen} toggle={() => setScheduleDropdownIsOpen(!scheduleDropdownIsOpen)}>
                            <DropdownToggle caret style={styles.filterButton}>
                                <div style={{ paddingLeft: '10px', marginRight: '10px', overflowX: 'hidden' }}>
                                    {scheduleFilter}
                                </div>
                            </DropdownToggle>
                            <DropdownMenu container='body' className='filter-menu-item' style={{ width: '180px', textAlign: 'center' }}>
                                <DropdownItem onClick={() => [setScheduleStatus(StatusTypeEnum.All), setScheduleFilter('Show all')]}>
                                    <StyledText style={{ fontSize: '18px', fontFamily: `${Fonts.secondary}` }}>Show all</StyledText>
                                </DropdownItem>
                                <DropdownItem onClick={() => [setScheduleStatus(StatusTypeEnum.Active), setScheduleFilter('Active only')]}>
                                    <StyledText style={{ fontSize: '18px', fontFamily: `${Fonts.secondary}` }}>Active Post</StyledText>
                                </DropdownItem>
                                <DropdownItem onClick={() => [setScheduleStatus(StatusTypeEnum.Inactive), setScheduleFilter('Inactive only')]}>
                                    <StyledText style={{ fontSize: '18px', fontFamily: `${Fonts.secondary}` }}>Inactive Post</StyledText>
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>

                    <SchedulePostTable getStatus={scheduleStatus} editSchedulePost={() => toggleEditScheduleModal(true)} />

                    {getEditScheduleBroadcastModalIsOpen && (
                        <EditScheduleBroadcastModal
                            getStatus={scheduleStatus}
                            broadcastEditId={getBroadcastId}
                        />
                    )}
                </div>
            </>
        );
    };

    return (
        renderSchedulePostTable()
    );
};

const EmptyContainerForHashLink = styled.div`
    height: 55px;
`;

const TableName = styled.h1`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    gap: 3%;

    height: 1.8rem;
    line-height: 1.8rem;
    padding-top: 14px;

    font-size: 1.8rem;
    font-weight: 500;
    font-family: ${Fonts.primary};
`;

const StyledText = styled.text`
    font-size: 20px;
    color: #27242E;
    font-family: ${Fonts.primary};
    font-weight: bold;
`;

const styles = {
    filterButton: {
        backgroundColor: '#F1F6F8',
        fontSize: '18',
        fontFamily: 'Fonts.primary',
        color: 'black',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',

        width: '183px',
        height: '46px',

        border: '1px solid black',
        borderRadius: '20px',
        cursor: 'pointer',
    },
};

const mapStateToProps = (state: RootState) => ({
    getBroadcastId: Selectors.getBroadcastBroadcastId(state),
    getEditScheduleBroadcastModalIsOpen: Selectors.getBroadcastEditScheduleBroadcastModalIsOpen(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setEditScheduleBroadcastModalIsOpen: (data: boolean) => dispatch(Actions.setEditScheduleBroadcastModalIsOpen(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledPost);
