import { fork } from 'redux-saga/effects';

import { RootSagaReturnType } from 'sagas/types';

import ECommerceAnalyticsGateway from 'api/ECommerceAnalytics';

import watchGetClicksReport from './getClicksReport';
import watchGetClicksProductReport from './getClicksProductReport';
import watchGetClicksVariantReport from './getClicksVariantReport';
import watchGetBuyNowClicksReport from './getBuyNowClicksReport';
import watchGetBuyNowClicksProductReport from './getBuyNowProductReport';
import watchGetBuyNowClicksVariantReport from './getBuyNowVariantReport';
import watchGetActivitiesReport from './getActivitiesReport';
import watchGetFunnelReport from './getFunnelReport';

export default (api: ECommerceAnalyticsGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetClicksReport, api);
        yield fork(watchGetClicksProductReport, api);
        yield fork(watchGetClicksVariantReport, api);

        yield fork(watchGetBuyNowClicksReport, api);
        yield fork(watchGetBuyNowClicksProductReport, api);
        yield fork(watchGetBuyNowClicksVariantReport, api);

        yield fork(watchGetActivitiesReport, api);

        yield fork(watchGetFunnelReport, api);
    }

    return {
        rootSaga,
    };
};
