import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import ContestGateway from 'api/Contests';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { GetContestDetailsPayload } from 'redux/slices/contests/types';
import { toast } from 'react-toastify';
import NavActions from 'lib/NavActions';

export default function* watchGetContestStatus(api: ContestGateway): SagaWatcherReturnType {
    yield takeEvery('contests/contestGetContestDetailsAttempt', handleGetContestDetails, api);
}

function* handleGetContestDetails(api: ContestGateway, data: GetContestDetailsPayload) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.getContestDetails], {
        authToken,
        id: data.payload.id,
        type: data.payload.type,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.contestGetContestStatusFailure(response.message || ''));
        NavActions.navToContests();
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.contestGetContestDetailsSuccess(response.data));
    }
}
