import React, { useState, FunctionComponent } from 'react';

import SVG from 'react-inlinesvg';
import styled, { css } from 'styled-components';

import Colors from 'assets/themes/Colors';
import Icons from 'assets/icons/Index';
import Button from 'components/Button';
import TextArea from 'components/TextArea';

// ! interface zone
// * props interface
export interface CollapsibleCardProps {
    index: string;
    title: string;
    titleContent: string;
    descriptionContent?: string;
    linkContent?: string;
    textContent?: string;
    handleTitleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleDescriptionChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleLinkChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleTextChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isOpenByDefault?: boolean;
    mediaPreview?: boolean;
    children?: React.ReactNode | React.ReactNode[];
    renderButtonText?: boolean;
    renderLink?: boolean;
}

// ! JSX function component
const CollapsibleCard:FunctionComponent<CollapsibleCardProps> = (props: CollapsibleCardProps) => {
    // ! destructuring zone
    // * props destructured
    const {
        index,
        title,
        titleContent,
        descriptionContent,
        textContent,
        linkContent,
        handleLinkChange,
        handleTitleChange,
        handleDescriptionChange,
        handleTextChange,
        isOpenByDefault,
        mediaPreview,
        children,
        renderButtonText,
        renderLink,
    } = props;

    // ! useState Zone
    const [cardIsOpen, setCardIsOpen] = useState(isOpenByDefault || false);

    // ! render zone
    const renderMediaPreview = () => {
        if (mediaPreview) {
            return (
                <CardRight isOpen={cardIsOpen}>
                    {children}
                </CardRight>
            );
        }
        return null;
    };
    // * main render
    return (
        <CollapsibleCardContainer>
            <StyledTransparentButton
                onClick={() => setCardIsOpen(!cardIsOpen)}
                css={cardIsOpen ? styles.chevronRight : styles.chevronDown}
            >
                <SVG src={Icons.ChevronRight} id='chevron' />
            </StyledTransparentButton>
            <CardLeft mediaPreview={!!mediaPreview}>
                <CardTitle>
                    <Index>{index}</Index>
                    <Title>{title}</Title>
                </CardTitle>
                <CardContent isOpen={cardIsOpen}>
                    <ContentTitle>
                        Title:
                    </ContentTitle>
                    <TextArea
                        placeholder=''
                        value={titleContent}
                        onChange={handleTitleChange}
                        css={styles.inputTitle}
                    />

                    <ContentTitle>
                        Description:
                    </ContentTitle>
                    <TextArea
                        placeholder=''
                        value={descriptionContent || ''}
                        onChange={handleDescriptionChange}
                        css={styles.inputMessageBox}
                    />
                    {renderButtonText && (
                        <>
                            <ContentTitle>
                                Button Text:
                            </ContentTitle>
                            <TextArea
                                placeholder=''
                                value={textContent || ''}
                                onChange={handleTextChange}
                                css={styles.inputTitle}
                            />
                        </>
                    )}
                    {renderLink && (
                        <>
                            <ContentTitle>
                                Link:
                            </ContentTitle>
                            <TextArea
                                placeholder=''
                                value={linkContent || ''}
                                onChange={handleLinkChange}
                                css={styles.inputMessageBox}
                            />
                        </>
                    )}
                </CardContent>

            </CardLeft>
            {renderMediaPreview()}
        </CollapsibleCardContainer>
    );
};

// ! styled-components
const CollapsibleCardContainer = styled.div`
    display: flex;
    width: 100%;
    background-color: ${Colors.white};
    padding: 15px;
    border-radius: 5px;
    position: relative;
`;
const CardLeft = styled.div<{mediaPreview?: boolean}>`
    width: ${({ mediaPreview }) => (mediaPreview ? '70%' : '100%')};
    display: flex;
    flex-direction: column;
`;
const CardRight = styled.div<{isOpen:boolean}>`
    width: 30%;
    display: ${(props) => (props.isOpen ? 'flex' : 'none')};
    flex-direction: column;
`;

const CardTitle = styled.div`
    display: flex;
    width: 100%;
`;

const Index = styled.div`
    color: #A2A2A2;
    font-size: 18px;
    width: 50px;
`;

const Title = styled.div`
    font-size: 18px;
`;

const CardContent = styled.div<{isOpen:boolean}>`
    margin-left: 50px;
    display: ${(props) => (props.isOpen ? 'flex' : 'none')};
    flex-direction: column;
`;

const StyledTransparentButton = styled(Button)`
    background-color: transparent;
    position: absolute;
    right: 30px;
    top: 10px;
`;

const ContentTitle = styled.div`
    font-size: 18px;
    color: #A2A2A2;
    margin-top: 20px;
`;

const styles = {
    chevronRight: css`
        background-color: transparent;
        margin-top: 5px;
        #chevron {
            width: 30px;
            height: 30px;
            transition: 0.3s;
            transform: rotate(-90deg);

            color: ${Colors.primary};

            &:hover{
                color: ${Colors.iqosPrimary};
            }
        }
    `,
    chevronDown: css`
        background-color: transparent;
        margin-top: 5px;
        #chevron {
            width: 30px;
            height: 30px;
            transform: rotate(90deg);
            transition: 0.3s;

            color: ${Colors.primary};

            &:hover{
                color: ${Colors.iqosPrimary};
            }
        }
    `,
    inputTitle: css`
        background-color:#F1F6F8;
        height: 47.5px;
        margin-top: 5px;
        resize: none;
        overflow: hidden;
`,
    inputMessageBox: css`
        background-color: #F1F6F8;
        height: 140px;
        margin-top: 5px;
        resize: none;
`,
};

export default CollapsibleCard;
