import React from 'react';
import { Table } from 'reactstrap';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import SVG from 'react-inlinesvg';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';

import { AppDispatch, RootState } from 'redux/store';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

import Flex from 'components/Flex';
import IQOSPaginator from 'components/IQOSPaginator';
import { TableStyles, ButtonStyles } from 'components/styles';

import { ContestListTypesEnum, IChannelTypeEnum, IContestList, IContestStatusEnum, ICreateContest, IDeleteContest, IDeleteQuizModalState, IEditQuizModalState } from 'entities/contests';

import Icons from 'assets/icons/Index';
import Colors from 'assets/themes/Colors';

import NavActions from 'lib/NavActions';
import { ICreateGame, IDeleteGame } from 'entities/games';

interface ListTableProps {
    tableId: string;
    tablePathname: string;
    contestType: ContestListTypesEnum;
    contestStatus: IContestStatusEnum;
    headers: string[];
    renderState: boolean;

    isLoading: boolean;
    contestList: IContestList;
    contestError: string;

    contestMaxPage: number;
    contestCurrentPage: number;
    contestCurrentIndex: number;

    deleteModalState: IDeleteQuizModalState;

    setContestIndex: (param:number) => void;

    setDeleteModalState: (param: IDeleteQuizModalState) => void;
    setEditModalState: (param: IEditQuizModalState) => void;
}

const ListTable:React.FunctionComponent<ListTableProps> = ({
    tableId,
    tablePathname,
    contestType,
    contestStatus,
    headers,
    renderState,

    isLoading,
    contestList,
    contestError,

    contestMaxPage,
    contestCurrentPage,
    contestCurrentIndex,

    setContestIndex,

    deleteModalState,
    setDeleteModalState,
    setEditModalState,

}:ListTableProps) => {
    const renderActionsContent = (id:string) => {
        return (
            <Flex gap='1.5rem' justifyContent='center'>
                <StyledButtons
                    css={ButtonStyles.blank}
                    type='button'
                    onClick={
                        contestType === ContestListTypesEnum.Quiz
                            ? () => setEditModalState({ id, isOpen: true, type: ContestListTypesEnum.Quiz })
                            : () => setEditModalState({ id, isOpen: true, type: ContestListTypesEnum.Game })
                    }
                >
                    <SVG src={Icons.FeatherEdit} fill={Colors.iqosPrimary} />
                </StyledButtons>
                <StyledButtons
                    css={ButtonStyles.blank}
                    onClick={
                        contestType === ContestListTypesEnum.Quiz
                            ? () => NavActions.navToContestQuizDetail(id)
                            : () => NavActions.navToContestGameDetail(id)
                    }
                >
                    <SVG
                        src={Icons.BarChart}
                        fill={Colors.iqosPrimary}
                    />
                </StyledButtons>
                <StyledButtons
                    css={ButtonStyles.blank}
                    onClick={
                        contestType === ContestListTypesEnum.Quiz
                            ? () => setDeleteModalState({ id, isOpen: true, type: ContestListTypesEnum.Quiz })
                            : () => setDeleteModalState({ id, isOpen: true, type: ContestListTypesEnum.Game })
                    }
                >
                    <SVG
                        src={Icons.TrashIcon}
                        style={{ height: '25px', width: '25px' }}
                        fill={Colors.iqosPrimary}
                    />
                </StyledButtons>
            </Flex>
        );
    };

    const renderTableBody = () => {
        if (isLoading) {
            return (
                <tr>
                    <AlignedTextTd colSpan={headers.length}>
                        <Skeleton count={5} height={50} />
                    </AlignedTextTd>
                </tr>
            );
        }

        if (contestList.data.length === 0) {
            return (
                <tr>
                    <AlignedTextTd colSpan={headers.length}>
                        There appears to be no data yet.
                    </AlignedTextTd>
                </tr>
            );
        }

        if (contestError) {
            return (
                <tr>
                    <AlignedTextTd colSpan={headers.length}>
                        {contestError}
                    </AlignedTextTd>
                </tr>
            );
        }

        return contestList && contestList.data.map((contestItem) => {
            return (
                <tr key={`${contestType}--${contestStatus}--table--data--${contestItem.id}`}>
                    <td>
                        <StyledButtons
                            css={ButtonStyles.blank}
                            onClick={
                                contestType === ContestListTypesEnum.Quiz
                                    ? () => NavActions.navToContestQuizDetail(contestItem.id)
                                    : () => NavActions.navToContestGameDetail(contestItem.id)
                            }
                        >
                            {contestItem.name}
                        </StyledButtons>
                    </td>
                    <td>{contestItem.clicks}</td>
                    {contestType === ContestListTypesEnum.Quiz && (
                        <td>{contestItem.eligibleWinners || '-'}</td>
                    )}
                    <td>{contestItem.clicks || '-'}</td>
                    {contestType === ContestListTypesEnum.Quiz && (
                        <>
                            <td>{contestItem.maxWinners || '-'}</td>
                            <td>{contestItem.correctAnswersNeeded || '-'}</td>
                        </>

                    )}

                    <td>{renderActionsContent(contestItem.id)}</td>
                </tr>
            );
        });
    };

    return (
        <>
            <StyledTable css={TableStyles.primary}>
                <thead>
                    <tr>
                        {headers.map((item) => {
                            return (<th key={`${contestType}--${contestStatus}--table--data--${item}`}>{item}</th>);
                        })}
                    </tr>
                </thead>
                <tbody>
                    {renderTableBody()}
                </tbody>
            </StyledTable>
            <div>
                {((contestList.data.length > 0 && contestStatus === IContestStatusEnum.Inactive) && (renderState === false)) ? (
                    <IQOSPaginator
                        maxIndex={contestMaxPage}
                        setIndex={setContestIndex}
                        currentIndex={contestCurrentIndex}
                        url={tablePathname}
                        containerId={tableId}
                    />
                ) : null}
            </div>
        </>
    );
};

const StyledButtons = styled.button<StyledTableProps>`
    ${props => props.css};
`;

type StyledTableProps = {
    css?: FlattenSimpleInterpolation
}

const AlignedTextTd = styled.td`
    text-align:center;
`;

const StyledTable = styled(Table)<StyledTableProps>`
    margin-top:1rem;
    thead {
        tr {
            th:not(:first-child) {
                text-align:center;
            }
        }
    }

    tbody {
        tr {
            td {
                &:not(:first-child) {
                    text-align:center;
                }
                
                button {
                    text-align:start;
                    &:hover{
                        cursor: pointer !important; 
                        transform: scale(1.1);
                    }
                }
            }
        }
    }

    ${props => props.css}
`;

const mapStateToProps = (state: RootState) => ({
    isLanu: Selectors.getFaqGetIsLANU(state),

    deleteModalState: Selectors.contestGetDeleteContestModalState(state),

});

const mapDispatchToProps = (dispatch: AppDispatch) => ({

    getGameDetails: (id: string, type:IChannelTypeEnum) => dispatch(Actions.getGameDetailsAttempting({ id, type })),
    getQuizDetails: (id: string, type: IChannelTypeEnum) => dispatch(Actions.contestGetContestDetailsAttempt({ id, type })),

    setDeleteModalState: (param: IDeleteQuizModalState) => dispatch(Actions.contestSetDeleteQuizModalState(param)),
    setEditModalState: (param: IEditQuizModalState) => dispatch(Actions.contestSetEditContestModalState(param)),

    deleteQuiz: (data: IDeleteContest) => dispatch(Actions.deleteContestAttempt(data)),
    deleteGame: (data: IDeleteGame) => dispatch(Actions.deleteGameAttempting(data)),

});

export default connect(mapStateToProps, mapDispatchToProps)(ListTable);
