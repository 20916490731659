import React, { useState, useEffect, FunctionComponent } from 'react';
// ? Redux Toolkit
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { toast } from 'react-toastify';
import { Spinner, UncontrolledTooltip } from 'reactstrap';
import styled, { FlattenSimpleInterpolation, css } from 'styled-components';
import SVG from 'react-inlinesvg';
import FsLightbox from 'fslightbox-react';

import Title from 'components/Title';
import TextArea from 'components/TextArea';

import Icons from 'assets/icons/Index';
import { IPNCMessageDetails, ISetPNCMessage } from 'entities/PNCMessage';
import Colors from 'assets/themes/Colors';
import PhoneMockup from 'components/PhoneMockup';

// ! interface zone
// * props interface
export interface PNCMessageProps {
    pncMessageDetails: IPNCMessageDetails;
    getPNCMessageAttempt: boolean;
    getPNCMessageError: string;
    editPNCMessageAttempt: boolean;
    editPNCMessageError: string;

    getPNCMessage: () => void;
    setPNCMessage: (data: ISetPNCMessage) => void;
}

// ! JSX function component
const PNCMessage: FunctionComponent<PNCMessageProps> = (props: PNCMessageProps) => {
    // ! destructuring zone
    // * props destructured
    const {
        pncMessageDetails,
        getPNCMessageAttempt,
        getPNCMessageError,
        editPNCMessageAttempt,
        editPNCMessageError,
        getPNCMessage,
        setPNCMessage,
    } = props;

    const {
        title,
        message,
        mediaUrl,
    } = pncMessageDetails;

    // ! useState Zone
    const [titleContent, setTitleContent] = useState<string>('');
    const [titleDisplayed, setTitleDisplayed] = useState<string>('');
    const [messageContent, setMessageContent] = useState<string>('');
    const [messageDisplayed, setMessageDisplayed] = useState<string>('');

    const [viewMedia, setViewMedia] = useState<string>('');
    const [viewMediaUrl, setViewMediaUrl] = useState<string>('');
    const [uploadedImageFile, setUploadedImageFile] = useState<File>();
    const [uploadedImageFileName, setUploadedImageFileName] = useState<string>('');

    const [toggler, setToggler] = useState<boolean>(false);

    // ! useEffect Zone
    useEffect(() => {
        if (!editPNCMessageAttempt) {
            getPNCMessage();
        }
    }, [editPNCMessageAttempt]);

    useEffect(() => {
        if (pncMessageDetails) {
            setTitleContent(title);
            setTitleDisplayed(formatValue(title));
            setMessageContent(message);
            setMessageDisplayed(formatValue(message));
            setViewMediaUrl(mediaUrl);
            setUploadedImageFileName(formatFileName(mediaUrl));
            setViewMedia(mediaUrl);
        }
    }, [pncMessageDetails]);

    // ! functions
    const formatFileName = (url: string) => {
        if (url) {
            const { pathname } = new URL(url as string);
            const fileName = pathname.substring(pathname.lastIndexOf('/') + 1);
            return fileName;
        }
        return '';
    };

    const formatValue = (value: string) => {
        const urlRegex = /(\bhttps?:\/\/\S+\b)/gi;
        if (value) {
            let formattedValue = value.replace(/\*(.*?)\*/g, '<strong>$1</strong>'); // bold text
            formattedValue = formattedValue.replace(/__(.*?)__/g, '<u>$1</u>'); // underlined text
            formattedValue = formattedValue.replace(/~(.*?)~/g, '<s>$1</s>'); // strikethrough text
            formattedValue = formattedValue.replace(/\|\|([^|]+)\|\|/g, '<span style="border: 1px solid;">$1</span>'); // spoiler text
            formattedValue = formattedValue.replace(urlRegex, (match) => {
                return `<a href="${match}" target="_blank">${match}</a>`;
            }); // link
            formattedValue = formattedValue.replace(/\n/g, '<br/>'); // new line

            return formattedValue;
        }
        return '';
    };

    const resetImage = () => {
        setUploadedImageFile(undefined);
        setUploadedImageFileName('');
        setViewMedia('');
        setViewMediaUrl('');
    };

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const formattedValue = formatValue(value);

        setTitleContent(value);
        setTitleDisplayed(formattedValue);
    };

    const handleMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const formattedValue = formatValue(value);

        setMessageContent(value);
        setMessageDisplayed(formattedValue);
    };

    const handleReset = () => {
        setTitleContent(title);
        setTitleDisplayed(formatValue(title));
        setMessageContent(message);
        setMessageDisplayed(formatValue(message));
        setUploadedImageFile(undefined);
        setViewMediaUrl(mediaUrl);
        setUploadedImageFileName(formatFileName(mediaUrl));
        setViewMedia(mediaUrl);
    };

    const handleSaveAction = () => {
        if (uploadedImageFile === undefined && viewMediaUrl === '') {
            setPNCMessage({
                title: titleContent,
                message: messageContent,
                file: uploadedImageFile,
                clearImage: 'true',
            });
            return;
        }
        setPNCMessage({
            title: titleContent,
            message: messageContent,
            file: uploadedImageFile,
            clearImage: 'false',
        });
    };

    const handleChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileUploaded = event.target.files?.[0];

        if (fileUploaded !== undefined) {
            if (uploadedImageFile === undefined) {
                setUploadedImageFile(uploadedImageFile);
            }
            const { type } = fileUploaded;
            let documentExtension = '';

            switch (type) {
                case 'image/jpg': documentExtension = '.jpg'; break;
                case 'image/jpeg': documentExtension = '.jpeg'; break;
                case 'image/png': documentExtension = '.png'; break;
                default:
            }

            if (fileUploaded.size <= 5242880) {
                if ((documentExtension === ('.png'))
                || (documentExtension === ('.jpeg'))
                || (documentExtension === ('.jpg'))
                ) {
                    setUploadedImageFile(fileUploaded);
                    setUploadedImageFileName(fileUploaded.name);
                    setViewMedia(URL.createObjectURL(fileUploaded));

                    toast.success('File uploaded!');
                } else {
                    event.target.value = ''; // Reset the file input field
                    setUploadedImageFile(undefined);
                    setUploadedImageFileName('');
                    setViewMedia('');
                    toast.error('Unsupported file type!');
                }
            } else {
                event.target.value = '';
                setUploadedImageFile(undefined);
                setUploadedImageFileName('');
                setViewMedia('');
                toast.error('Limit size for image file is 5mb');
            }
        }
    };

    // ! render zone

    const renderReminder = () => {
        if (titleContent !== title
            || messageContent !== message
            || uploadedImageFileName !== formatFileName(mediaUrl)) {
            return (
                <ReminderContainer>You have made some changes! Click save to save your changes.</ReminderContainer>
            );
        }

        return null;
    };

    if (getPNCMessageAttempt || editPNCMessageAttempt) {
        return (
            <MainWrapper>
                <div style={{ width: '100%' }}>
                    <HeaderWrapper>
                        <Title label='Phone Number Checking' css={styles.mainTitle} />
                    </HeaderWrapper>
                    <ContentWrapper>
                        <LoadingContainer>
                            <Spinner size='lg' />
                        </LoadingContainer>
                    </ContentWrapper>
                </div>
            </MainWrapper>
        );
    }
    // * main render
    return (
        <MainWrapper>
            <div style={{ width: '100%' }}>
                <HeaderWrapper>
                    <Title label='Phone Number Checking' css={styles.mainTitle} />
                </HeaderWrapper>
                <ContentWrapper>
                    <Content width='60%'>
                        <div>

                            <StyledText style={{ fontSize: '18px', color: '#A2A2A2' }}>
                                Title
                            </StyledText>
                            <TextArea
                                placeholder=''
                                value={titleContent}
                                onChange={handleTitleChange}
                                css={styles.inputTitle}
                            />
                            <StyledText style={{ fontSize: '18px', color: '#A2A2A2', display: 'inline-flex', alignItems: 'center', width: '100%' }}>
                                Message
                            </StyledText>
                            <TextArea
                                placeholder=''
                                value={messageContent}
                                onChange={handleMessageChange}
                                css={styles.inputMessageBox}
                            />
                            <StyledText style={{ color: '#A2A2A2', display: 'inline-flex', justifyContent: 'flex-start', width: '100%', gap: '2%', alignItems: 'center' }}>
                                Upload Media (Optional)
                                <img src={Icons.QuestionMark} alt='Tooltip' id='uploadMediaTooltip' style={{ width: '14px', border: '1px solid #00D1D2', borderRadius: '60px', padding: '2px', backgroundColor: '#00D1D2' }} />
                                <UncontrolledTooltip placement='right' target='uploadMediaTooltip' style={{ backgroundColor: '#00D1D2', color: 'black', fontSize: '10px', textAlign: 'justify', padding: '10px' }}>
                                    You can only upload one image file. Simply upload any file to replace current uploaded file. Image files should not be more than 5MB.
                                </UncontrolledTooltip>
                            </StyledText>
                            <StyledUploadImageWrapper>
                                <StyledLabelWrapper htmlFor='imageFileInput' style={{ justifyContent: 'center', gap: '5%' }}>
                                    {(uploadedImageFile || viewMediaUrl) ? (
                                        <StyledText style={{ fontWeight: '500', color: '#00D1D2', fontSize: '16px', paddingTop: '1.5px', paddingBottom: '1.5px', fontStyle: 'italic' }}>
                                            {uploadedImageFileName || formatFileName(viewMediaUrl)}
                                        </StyledText>
                                    ) : (
                                        <>
                                            <StyledText style={{ fontWeight: '500' }}>Image</StyledText>
                                            <img src={Icons.UploadImage} alt='uploadImage' style={{ width: '20px' }} />
                                        </>
                                    )}
                                    <input
                                        id='imageFileInput'
                                        type='file'
                                        name='file'
                                        style={{ display: 'none' }}
                                        accept='.jpeg, .jpg, .png'
                                        onChange={handleChangeImage}
                                    />
                                </StyledLabelWrapper>
                                {viewMedia ? (
                                    <>
                                        <StyledButton
                                            onClick={() => setToggler(!toggler)}
                                            style={{ width: '25px', padding: '0', justifyContent: 'center' }}
                                        >
                                            <SVG
                                                style={{
                                                    width: '20px',
                                                    height: '20px',
                                                    fill: '#00D1D2',
                                                    strokeWidth: '0.1px',
                                                }}
                                                src={Icons.ViewImage}
                                                id='viewImageIcon'
                                            />
                                        </StyledButton>
                                        <FsLightbox
                                            toggler={toggler}
                                            sources={[viewMedia]}
                                        />
                                    </>
                                ) : null}
                                {(uploadedImageFile || viewMediaUrl) && (
                                    <StyledButton onClick={() => resetImage()} style={{ fontSize: '16px' }}>Remove</StyledButton>
                                )}
                            </StyledUploadImageWrapper>
                        </div>
                        <div>
                            {renderReminder()}
                            <ButtonWrapper>
                                <StyledActionButton
                                    type='button'
                                    css={styles.primary}
                                    onClick={() => handleSaveAction()}
                                >
                                    Save
                                </StyledActionButton>
                                <StyledActionButton
                                    type='button'
                                    css={styles.secondary}
                                    onClick={() => handleReset()}
                                >
                                    Reset
                                </StyledActionButton>
                            </ButtonWrapper>
                        </div>
                    </Content>
                    <Content width='40%'>
                        <StyledText style={{ fontSize: '18px', color: '#A2A2A2', marginBottom: '10px' }}>
                            Media Preview
                        </StyledText>
                        <PhoneMockup
                            telegramUsername='IQOS Telegram'
                            titleDisplayed={titleDisplayed}
                            messageDisplayed={messageDisplayed}
                            mediaUrl={viewMediaUrl}
                            uploadedImageFile={uploadedImageFile}
                        />
                    </Content>
                </ContentWrapper>
            </div>
        </MainWrapper>
    );
};

// ! styled-components
const MainWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: space-evenly;

    background-color: #F1F6F8;
    border-radius: 20px;

    padding-top: 1%;
    padding-left: 0.5%;
    padding-bottom: 1%;

    width: 71%;
    height: 100%;

    margin-bottom: 5%;
    margin-right: 7%;
`;

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
`;

const ContentWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 40px;
    height: 100%;
    width: 100%;
    padding: 20px;
`;

const Content = styled.div<{width: string}>`
    display: flex;
    flex-direction: column;
    width: ${(props) => props.width};
    min-height: 600px;
    max-height: 700px;
    margin-bottom: 40px;
    justify-content: space-between;
`;

const LoadingContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    min-height: 600px;
`;

const StyledText = styled.text`
    font-size: 18px;
`;

const StyledLabelWrapper = styled.label`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 40%;
    border: 1px solid #A2A2A2;
    border-radius: 5px;

    padding-top: 5px;
    padding-bottom: 5px;
`;

const StyledUploadImageWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
`;

const StyledButton = styled.button`
    display: flex;
    align-items: center;
    border: none;
    background: transparent;
    height: 75%;
    width: 10%;
    transition: all 0.1s ease;
    font-size: 11px;

    &:hover {
        color: red;
    }

    &:active {
        color: green;
    }
`;

const ReminderContainer = styled.div`
    color: ${Colors.iqosError};
    font-style: italic;
`;
const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    gap: 20px;
`;

interface StyledButtonProps {
    css?: FlattenSimpleInterpolation;
}

const StyledActionButton = styled.button<StyledButtonProps>`
    cursor:pointer;
    ${props => props.css}
`;

const styles = {
    mainTitle: css`
        font-size: 100px;
    `,
    inputTitle: css`
        background-color: #fff;
        height: 70px;
        margin-bottom: 4.22%;
        resize: none;
        overflow: auto;
    `,
    inputMessageBox: css`
        background-color: #fff;
        height: 249px;
        margin-bottom: 4.22%;
        resize: none;
    `,
    primary: css`
        min-width:150px;
        color: ${Colors.white};
        background-color: ${Colors.iqosPrimary};
        font-size: 18px;
        height:46px;
        border: 1px solid ${Colors.iqosPrimary};
        font-family: ${Colors.secondary};
        border-radius: 20px;
        color: white;
    `,
    secondary: css`
        min-width:150px;
        color: ${Colors.primary};
        background-color: ${Colors.white};
        font-size: 18px;
        height:46px;
        border: 1px solid ${Colors.primary};
        font-family: ${Colors.secondary};
        border-radius: 20px;
        color: black;

    `,
};

// ! redux toolkit
// * Selectors
const mapStateToProps = (state: RootState) => ({
    pncMessageDetails: Selectors.getPNCGetPNCMessage(state),
    getPNCMessageAttempt: Selectors.getPNCGetPNCMessageAttempting(state),
    getPNCMessageError: Selectors.getPNCGetPNCMessageFailure(state),
    editPNCMessageAttempt: Selectors.getPNCEditPNCMessageAttempting(state),
    editPNCMessageError: Selectors.getPNCEditPNCMessageFailure(state),
});
// * Dispatch
const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getPNCMessage: () => dispatch(Actions.getPNCMessageAttempt()),
    setPNCMessage: (data: ISetPNCMessage) => dispatch(Actions.setPNCMessageAttempt(data)),
});
// * Connection
export default connect(mapStateToProps, mapDispatchToProps)(PNCMessage);
