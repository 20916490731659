import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { toast } from 'react-toastify';

import ECommerceGateway from 'api/ECommerce';
import { DeleteUsingIdParams } from 'redux/slices/eCommerce/types';

export default function* watchDeleteProductCategory(api: ECommerceGateway): SagaWatcherReturnType {
    yield takeEvery('eCommerce/deleteProductCategoryAttempt', handleDeleteProductCategory, api);
}

function* handleDeleteProductCategory(api: ECommerceGateway, data: DeleteUsingIdParams) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const { id } = data.payload;

    const response = yield* call([api, api.deleteProductCategory], {
        authToken,
        id,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.deleteProductCategoryFailure(response.message));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.deleteProductCategorySuccess());
        yield put(Actions.getProductCategoryAttempt());
        toast.success('Product Category deleted!');
    }
}
