import React, { useEffect } from 'react';

import styled from 'styled-components';
import { connect } from 'react-redux';
import Selectors from 'redux/Selectors';
import { RootState, AppDispatch } from 'redux/store';

import { ECommBuyNowClicksReportData } from 'entities/ecommerceAnalytics';

import Title from 'components/Title';
import Subtitle from 'components/Subtitle';
import StatCard from 'components/summaryDashboard/StatCardPercentage';
import AnalyticsContainer from 'components/analytics/common/AnalyticsContainer';
import Loading from 'components/analytics/common/Loading';

import Actions from 'redux/Actions';

import NavActions from 'lib/NavActions';
import DatePicker from 'components/analytics/common/DatePicker';
import Error from 'components/analytics/common/Error';
import BuyNowClicksReportGraphs from './components/BuyNowClicksReportGraphs';

interface BuyNowClicksReportProps {
    selectedDates: { dateFrom: Date, dateTo: Date };
    setDatePicker: (dateFrom: Date, dateTo: Date) => void;
    getBuyNowClicksReport: () => void;

    loading: boolean;
    error: string | null;
    data: ECommBuyNowClicksReportData | null;
}

const BuyNowClicksReport = (props: BuyNowClicksReportProps): JSX.Element => {
    const {
        selectedDates,
        setDatePicker,
        getBuyNowClicksReport,

        loading,
        error,
        data,
    } = props;

    const { dateFrom, dateTo } = selectedDates;

    useEffect(() => {
        getBuyNowClicksReport();
    }, [dateFrom, dateTo]);

    const renderData = () => {
        if (loading) {
            return <Loading />;
        }

        if (error || !data) {
            return (
                <Error errorMessage={error} />
            );
        }

        return (
            <>
                <BuyNowClicksReportGraphs data={data} />

                <Subtitle>
                    Number of Buy Now Clicks Based on Product Categories
                </Subtitle>

                <GridWrapper>
                    {data.clicks.map((item) => (
                        <StatCard
                            key={item.id}
                            id={item.id}
                            label={item.name}
                            current={item.clicks}
                            subtitle={`${item.uniqueClicks} unique buy now clicks`}
                            isYtd={false}
                            onClick={NavActions.navToEcommerceAnalyticsBuyNowProductReport}
                        />
                    ))}
                </GridWrapper>
            </>
        );
    };

    return (
        <AnalyticsContainer>
            <HeaderWrapper>
                <Title label='Buy Now Clicks Report' />
                <DatePicker
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    onChange={setDatePicker}
                />
            </HeaderWrapper>

            <DisplayWrapper>
                {renderData()}
            </DisplayWrapper>
        </AnalyticsContainer>
    );
};

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-items: flex-end;

    width: 100%;

    gap: 20px;
`;

const DisplayWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    padding-top: 20px;

    gap: 20px;
`;

const GridWrapper = styled.div`
    display: grid;
    grid-template-columns: auto auto auto;
    
    gap: 20px;
`;

const mapStateToProps = (state: RootState) => ({
    selectedDates: Selectors.getECommerceAnalDatePicker(state),

    loading: Selectors.getECommerceAnalBuyNowClicksReportAttempting(state),
    error: Selectors.getECommerceAnalBuyNowClicksReportFailure(state),
    data: Selectors.getECommerceAnalBuyNowClicksReportData(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setDatePicker: (dateFrom: Date, dateTo: Date) => dispatch(Actions.ecommAnalSetDatePicker({ dateFrom, dateTo })),
    getBuyNowClicksReport: () => dispatch(Actions.ecommAnalGetBuyNowClicksReportAttempt()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BuyNowClicksReport);
