import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { toast } from 'react-toastify';

import ECommerceGateway from 'api/ECommerce';
import { EditProductVariantParams } from 'redux/slices/eCommerce/types';

export default function* watchEditProductVariant(api: ECommerceGateway): SagaWatcherReturnType {
    yield takeEvery('eCommerce/editProductVariantAttempt', handleEditProductVariant, api);
}

function* handleEditProductVariant(api: ECommerceGateway, data: EditProductVariantParams) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const {
        productId,
        variantId,
        variantNameEN,
        variantNameBM,
        variantImage,
        variantImageUpload,
        defaultVariant,
    } = data.payload;

    let stringDefaultVariant = '';
    if (defaultVariant) {
        stringDefaultVariant = 'true';
    } else {
        stringDefaultVariant = 'false';
    }
    const response = yield* call([api, api.editProductVariant], {
        authToken,
        productId,
        variantId,
        variantNameEN,
        variantNameBM,
        variantImage,
        variantImageUpload,
        defaultVariant: stringDefaultVariant,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.editProductVariantFailure(response.message));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.editProductVariantSuccess());
        yield put(Actions.getProductDetailsAttempt({ productId }));
        yield put(Actions.setEditVariantModalIsOpen(false));
        toast.success('Changes saved!');
    }
}
