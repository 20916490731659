import { call, put, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import ECommerceAnalyticsGateway from 'api/ECommerceAnalytics';
import { GetFunnelReportParams } from 'redux/slices/eCommerceAnalytics/types';

export default function* watchGetFunnelReport(api: ECommerceAnalyticsGateway): SagaWatcherReturnType {
    yield takeEvery('eCommerceAnalytics/ecommAnalGetFunnelReportAttempt', handleGetFunnelReport, api);
}

function* handleGetFunnelReport(api: ECommerceAnalyticsGateway, data: GetFunnelReportParams) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const selectedDates = yield* select(Selectors.getECommerceAnalDatePicker);

    const response = yield* call([api, api.getFunnelReport], {
        authToken,
        dateFrom: selectedDates.dateFrom.toISOString(),
        dateTo: selectedDates.dateTo.toISOString(),
        productCategoryId: data.payload.productCategoryId,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.ecommAnalGetFunnelReportFailure(response.message));
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.ecommAnalGetFunnelReportSuccess(response.data));
    }
}
