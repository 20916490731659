import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { toast } from 'react-toastify';

import ECommerceGateway from 'api/ECommerce';
import { EditProductDetailsParams } from 'redux/slices/eCommerce/types';

export default function* watchEditProductDetails(api: ECommerceGateway): SagaWatcherReturnType {
    yield takeEvery('eCommerce/editProductDetailsAttempt', handleEditProductDetails, api);
}

function* handleEditProductDetails(api: ECommerceGateway, data: EditProductDetailsParams) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const { id,
        productName,
        productTitleEN,
        productDescEN,
        productTitleBM,
        productDescBM,
        productPrice,
        promotionalPrice,
        productLinkEN,
        productLinkBM,
        status,
        reorderBy } = data.payload;

    const response = yield* call([api, api.editProductDetails], {
        authToken,
        id,
        productName,
        productTitleEN,
        productDescEN,
        productTitleBM,
        productDescBM,
        productPrice,
        promotionalPrice,
        productLinkEN,
        productLinkBM,
        status,
        reorderBy,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.editProductDetailsFailure(response.message));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        const categoryId = yield* select(Selectors.getECommerceGetProductCategoryId);
        yield put(Actions.editProductDetailsSuccess());
        yield put(Actions.getProductListAttempt({ categoryId }));
        yield put(Actions.getProductDetailsAttempt({ productId: id }));
        toast.success('Changes saved!');
    }
}
