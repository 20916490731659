import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { toast } from 'react-toastify';
import ECommerceGateway from 'api/ECommerce';

export default function* watchGetProductCategory(api: ECommerceGateway): SagaWatcherReturnType {
    yield takeEvery('eCommerce/getProductCategoryAttempt', handleGetProductCategory, api);
}

function* handleGetProductCategory(api: ECommerceGateway) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.getProductCategory], {
        authToken,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.getProductCategoryFailure(response.message));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.getProductCategorySuccess(response.data));
    }
}
