import React from 'react';
import SVG from 'react-inlinesvg';
import dayjs from 'dayjs';
import { Table, Spinner } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import FollowersHeader from 'components/analytics/FollowersHeader';
import AnalyticsHeader from 'components/analytics/AnalyticsHeader';
import ContentWrapper from 'components/ContentWrapper';
import Flex from 'components/Flex';
import { ButtonStyles, TableStyles } from 'components/styles';

import NavActions from 'lib/NavActions';

import { IExportFullFollowersListToExcelReduxParam, IFullFollowersUsersList, IGetFullFollowersUsersListReduxParam } from 'entities/followers';

import Icons from 'assets/icons/Index';
import Colors from 'assets/themes/Colors';
import IQOSPaginator from 'components/IQOSPaginator';

interface FullListProps {
    isLanu: boolean;
    fullFollowersUsersList: IFullFollowersUsersList[];
    currentIndex: number;
    currentPage: number;
    maxPage: number;
    isLoading: boolean;
    error: string;
    exportIsLoading: boolean;
    exportError: string;

    resetFullFollowersUsersList: () => void;
    setCurrentIndex: (param:number) => void;
    getFullFollowersUsersList: (param:IGetFullFollowersUsersListReduxParam) => void;
    exportFullFollowersUsersList: (param:IExportFullFollowersListToExcelReduxParam) => void;
}

const FullList:React.FunctionComponent<FullListProps> = ({
    isLanu,
    fullFollowersUsersList,
    isLoading,
    error,
    setCurrentIndex,
    currentIndex,
    currentPage,
    maxPage,
    getFullFollowersUsersList,
    resetFullFollowersUsersList,
    exportIsLoading,
    exportError,
    exportFullFollowersUsersList,

}) => {
    const list = fullFollowersUsersList.find(item => item.index === currentIndex);
    const headers = ['First Name', 'Username', 'Phone numbers', 'Date Joined', 'Blocked'];
    const currentLocation = useLocation();

    const [render, setRender] = React.useState<boolean>(false);

    React.useEffect(() => {
        getFullFollowersUsersList({ index: currentIndex });
    }, [currentIndex]);

    React.useEffect(() => {
        setRender(true);
        resetFullFollowersUsersList();
        getFullFollowersUsersList({ index: 1 });
    }, [isLanu]);

    React.useEffect(() => {
        setRender(false);
    }, [render]);

    const renderTable = () => {
        const renderHeaders = () => {
            return (
                <tr>
                    {headers.map(item => {
                        return (<th key={item}>{item}</th>);
                    })}
                </tr>
            );
        };

        const renderData = () => {
            if (isLoading) {
                return (
                    <tr>
                        <StyledTdTemp colSpan={headers.length}>
                            <Skeleton count={5} height={50} />
                        </StyledTdTemp>
                    </tr>
                );
            }

            if (error) {
                return (
                    <tr>
                        <StyledTdTemp colSpan={headers.length}>
                            {error || 'Error'}
                        </StyledTdTemp>
                    </tr>
                );
            }

            if (list !== undefined) {
                if (!list.data.length) {
                    return (
                        <tr>
                            <StyledTdTemp colSpan={headers.length}>
                                There appears to be no data yet.
                            </StyledTdTemp>
                        </tr>
                    );
                }
            }

            return fullFollowersUsersList && list && list.data.map(({
                username,
                firstName,
                lastName,
                phoneNumber,
                dateJoined,
                hasBlockedBot,
            }) => {
                return (
                    <tr key={`followersfulllist${username}${dateJoined}`}>
                        <td>{firstName ? `${firstName} ${lastName || ''}` : '-'}</td>
                        <td>{username || '-'}</td>
                        <td>{phoneNumber || '-'}</td>
                        <td>{dateJoined ? dayjs(dateJoined).format('DD-MM-YY') : '-'}</td>
                        <td>{hasBlockedBot ? 'Blocked' : '-'}</td>
                    </tr>
                );
            });
        };

        return (
            <>
                <Flex justifyContent='space-between' alignItems='center' flexWrap='wrap' gap='1rem' margin='2rem 0 0 0'>
                    <StyledButton type='button' css={ButtonStyles.blank} onClick={() => NavActions.navToFollowersScreen()}>
                        <SVG fill={Colors.iqosPrimary} src={Icons.ChevronLeft} style={{ transform: '180deg' }} height={30} />
                        <AnalyticsHeader size='sm' header='Back to Report' fontColor={Colors.iqosPrimary} />
                    </StyledButton>
                </Flex>
                <StyledTable css={TableStyles.primary} id='IQOSFullFollowersList'>
                    <thead>
                        {renderHeaders()}
                    </thead>
                    <tbody>
                        {renderData()}
                    </tbody>
                </StyledTable>
                {(!render) && (
                    <IQOSPaginator
                        maxIndex={maxPage}
                        setIndex={setCurrentIndex}
                        currentIndex={currentIndex}
                        url={currentLocation.pathname}
                        containerId='IQOSFullFollowersList'
                    />
                )}
            </>
        );
    };

    const renderExportToExcelContent = () => {
        if (exportIsLoading) return <Spinner size='sm' />;
        return 'Export Data';
    };

    const onClickToExport = () => {
        exportFullFollowersUsersList({});
    };

    return (
        <ContentWrapper>
            <Flex justifyContent='space-between' alignItems='center' flexWrap='wrap' gap='1rem'>
                <FollowersHeader
                    headerContent='Full Followers List'
                    headerSize='md'
                    tooltipId='followers-list'
                    tooltipMaxWidth='400px'
                    tooltipIconSrc={Icons.QuestionMark}
                    tooltipContent='This is a list of all the followers in the channel.'
                />
                <StyledButton type='button' css={ButtonStyles.primary} onClick={() => onClickToExport()} disabled={exportIsLoading}>
                    {renderExportToExcelContent()}
                </StyledButton>
            </Flex>
            <StyledTableWrapper>
                {renderTable()}
            </StyledTableWrapper>
        </ContentWrapper>
    );
};

interface StyledButtonProps {
    css?: FlattenSimpleInterpolation;
}
type StyledTableProps = StyledButtonProps;

const StyledButton = styled.button<StyledButtonProps>`
    justify-content:center;
    cursor:pointer;
    color: ${Colors.iqosPrimary};
    background-color: transparent;
    display:flex;
    gap:0.3rem;
    align-items:center;
    ${props => props.css}
`;

const StyledTable = styled(Table)<StyledTableProps>`
    margin-top:1rem;
    ${props => props.css}
`;

const StyledTdTemp = styled.td`
    min-height:200px;
    text-align:center;
`;

const StyledTableWrapper = styled.div`
    margin: 1rem 0;
    border-radius:8px;
    overflow-x:auto;
    overflow-y:auto;
    max-height: 768px;
    box-sizing: content-box;
    
    ::-webkit-scrollbar {
        width: 6px;
        height:6px;
    }
      
    ::-webkit-scrollbar-track {
    background: #f1f1f1; 
    }
       
    ::-webkit-scrollbar-thumb {
    background: #888; 
    }
      
    ::-webkit-scrollbar-thumb:hover {
    background: #555; 
    }

    scrollbar-gutter: stable !important;
`;

const mapStateToProps = (state: RootState) => ({
    isLanu: Selectors.getFaqGetIsLANU(state),

    isLoading: Selectors.followersGetFullFollowersUsersListAttempting(state),
    error: Selectors.followersGetFullFollowersUsersListError(state),
    fullFollowersUsersList: Selectors.followersGetFullFollowersUsersList(state),
    currentIndex: Selectors.followersGetFullFollowersUsersListCurrentIndex(state),
    currentPage: Selectors.followersGetFullFollowersUsersListCurrentPage(state),
    maxPage: Selectors.followersGetFullFollowersUsersListMaxPage(state),

    exportIsLoading: Selectors.followersExportFullFollowersListAttempting(state),
    exportError: Selectors.followersExportFullFollowersListError(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    resetFullFollowersUsersList: () => dispatch(Actions.followersResetFullFollowersUsersList()),

    setCurrentIndex: (param:number) => dispatch(Actions.followersSetFullFollowersUsersListIndex(param)),
    getFullFollowersUsersList: (param:IGetFullFollowersUsersListReduxParam) => dispatch(Actions.followersGetFullFollowersUsersListAttempt(param)),

    exportFullFollowersUsersList: (param:IExportFullFollowersListToExcelReduxParam) => dispatch(Actions.followersExportFullFollowersListToExcelAttempt(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FullList);
