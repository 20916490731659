import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import createSagaMiddleware from '@redux-saga/core';
import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';

import authReducer from 'redux/slices/auth';
import faqReducer from 'redux/slices/faq';
import analyticsReducer from 'redux/slices/analytics';
import contestsReducer from 'redux/slices/contests';
import gamesReducer from 'redux/slices/games';
import broadcastReducer from 'redux/slices/broadcast';
import followersReducer from 'redux/slices/followers';
import postsReducer from 'redux/slices/posts';
import summaryDashbordReducer from 'redux/slices/summaryDashboard';
import pncMessageReducer from 'redux/slices/pncMessage';
import eCommerceReducer from 'redux/slices/eCommerce';
import eCommerceAnalyticsReducer from 'redux/slices/eCommerceAnalytics';

import rootSaga from 'sagas';

const sagaMiddleware = createSagaMiddleware();
const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history: createBrowserHistory(),
});

const rootReducer = combineReducers({
    auth: authReducer.reducers,
    analytics: analyticsReducer.reducers,
    broadcast: broadcastReducer.reducers,
    contests: contestsReducer.reducers,
    faq: faqReducer.reducers,
    followers: followersReducer.reducers,
    games: gamesReducer.reducers,
    router: routerReducer,
    posts: postsReducer.reducers,
    summaryDashboard: summaryDashbordReducer.reducers,
    pncMessage: pncMessageReducer.reducers,
    eCommerce: eCommerceReducer.reducers,
    eCommerceAnalytics: eCommerceAnalyticsReducer.reducers,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        thunk: false,
        serializableCheck: false,
    }).concat(sagaMiddleware, routerMiddleware),
    devTools: process.env.REACT_APP_STAGE !== 'prod',
});

sagaMiddleware.run(rootSaga);

export const history = createReduxHistory(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
