import React from 'react';

import parse from 'html-react-parser';
import styled from 'styled-components';
import Icons from 'assets/icons/Index';
import SVG from 'react-inlinesvg';
import Colors from 'assets/themes/Colors';

// ! interface zone
// * props interface
export interface PhoneMockupProps {
    telegramUsername : string;
    titleDisplayed: string;
    messageDisplayed: string;
    mediaUrl: string;
    uploadedImageFile?: File;
}

// ! JSX function component
const PhoneMockup = (props: PhoneMockupProps): JSX.Element => {
    // ! destructuring zone
    // * props destructured
    const {
        telegramUsername,
        titleDisplayed,
        messageDisplayed,
        mediaUrl,
        uploadedImageFile,
    } = props;

    // ! render zone
    const renderChatImage = () => {
        if (uploadedImageFile) {
            return (
                <ImageContainer>
                    <img src={URL.createObjectURL(uploadedImageFile)} alt='' />
                </ImageContainer>
            );
        }
        if (mediaUrl) {
            return (
                <ImageContainer>
                    <img src={mediaUrl} alt='' />
                </ImageContainer>
            );
        }
        return null;
    };
    const renderChatBubble = () => {
        if (messageDisplayed.trim().length > 0
        || titleDisplayed.trim().length > 0
        || uploadedImageFile
        || mediaUrl) {
            return (
                <ChatAreaContainer>
                    <ChatArea>
                        <ChatBubble>
                            {renderChatImage()}
                            {titleDisplayed && parse(`${titleDisplayed} <br>`)}
                            { titleDisplayed && messageDisplayed ? <br /> : null}
                            {parse(`${messageDisplayed}`)}
                        </ChatBubble>
                        <Tail />
                    </ChatArea>
                </ChatAreaContainer>
            );
        }
        return (
            <p style={{ height: '100%', width: '100%', textAlign: 'center', paddingTop: '50%' }}>
                No messages here yet...
            </p>
        );
    };

    // * main render
    return (
        <PhoneMockupContainer>
            <PhoneHeader>
                <div>
                    <SVG src={Icons.IqosIconWithoutName} width={25} />
                </div>
                {telegramUsername}
            </PhoneHeader>
            <PhoneContent>
                {renderChatBubble()}
            </PhoneContent>
            <PhoneFooter>
                <div> Message ... </div>
            </PhoneFooter>
        </PhoneMockupContainer>
    );
};

const PhoneMockupContainer = styled.div`
    display: flex;
    flex-direction: column;
    border: 4px solid #000000;
    border-radius: 20px;
    background-color: ${Colors.white};
    aspect-ratio: 11/19;
    width: 350px;
    /* position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%); */
    overflow: hidden;

    font-size: 12px;
`;

const PhoneHeader = styled.div`
    background-color: ${Colors.iqosPrimary};
    height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #fff;
    margin-bottom: 10px;
    div {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin: 10px;
    }
`;
const PhoneContent = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: end;
    padding-inline: 10px;
    gap: 20px;
    overflow-y: auto;
`;
const ChatAreaContainer = styled.div`
    height: 450px;
`;
const ChatArea = styled.div`
    position: relative;
    display: flex;
    align-items: flex-end;
    max-height: 800px;
    min-height: 450px;
`;
const ChatBubble = styled.div`
    max-width: 90%;
    background-color: ${Colors.bgPrimary};
    border-radius: 10px;
    height: fit-content;
    padding: 10px;
    overflow: hidden;
`;

const Tail = styled.div`
    content: "";
    position: absolute;
    /* top: 60%; */
    left: -12px;
    bottom: 0;
    border-style: solid;
    border-width: 20px 20px 0 0;
    border-color: transparent ${Colors.bgPrimary} transparent transparent;
    z-index: 4;
`;

const ImageContainer = styled.div`
    width: 100%;
    transform: translateY(-10px) scale(1.1);
    margin-bottom: 5px;
    img {
    max-width: 100%;
    height: auto;
    }
`;
const PhoneFooter = styled.div`
    background-color: ${Colors.bgPrimary} ;
    height: 60px;
    display: flex;
    flex-direction: row;
    padding-inline: 20px;
    align-items: center;
    justify-content: space-between;
    z-index: 5;
    margin-top: 10px;
    div {
        height: 35px;
        padding-top: 5px;
        padding-left: 20px;
        border-radius: 4px;
        background-color: ${Colors.white} ;
        /* border: 1px solid #229ED9; */
        width: 100%;
    }
`;

export default PhoneMockup;
