export enum StatusTypeEnum {
    All = '',
    Active = 1,
    Inactive = 2,
}

export enum LanguageEnum {
    All = '',
    EN = 1,
    BM = 2,
}

export enum MessageCategoryEnum {
    All = '',
    PromotionalMessage = 10,
    PromoTitle = 11,
    PromoDescription = 12,
    WelcomeMessage = 20,
    WmTitle = 21,
    WmDescription = 22,
    ProductDescription = 30,
    PdTitle = 31,
    PdDescription = 32,
    ProductListingMessage = 40,
    PlTitle = 41,
    PlDescription = 42,
    CTAButtonDetails = 50,
    CTATitle = 51,
    CTAMessage = 52,
    CTAText = 53,
    CTALink = 54,
}

export enum TableTypeEnum {
    All = '',
    Categories = 1,
    Products = 2,
}
export interface PromotionalMessageDetails {
    title: string;
    message: string;
    file?: File;
    mediaUrl: string;
    language: LanguageEnum;
}

export interface ISetPromotionalMessage {
    title: string;
    message: string;
    file?: File;
    clearImage: string;
    language: LanguageEnum;
}

export interface WelcomeMessageDetails {
    title: string;
    message: string;
    language: LanguageEnum;
}

export interface CTAButtonDetails {
    title: string;
    message: string;
    file?: File;
    mediaUrl: string;
    buttonText: string;
    link: string;
    language: LanguageEnum;
}

export interface ProductListingMessageDetails {
    title: string;
    message: string;
    language: LanguageEnum;
}

export interface ISetWelcomeMessage {
    title: string;
    message: string;
    language: LanguageEnum;
}

export interface ISetCTAButtonDetails {
    title: string;
    message: string;
    file?: File;
    clearImage: string;
    buttonText: string;
    link: string;
    language: LanguageEnum;
}

export interface ISetProductListingMessage {
    title: string;
    message: string;
    language: LanguageEnum;
}

export interface ProductCategoryDetails {
    _id: string;
    categoryName: string;
    createdAt: string;
    status: StatusTypeEnum;
    productListingMessageTitleEN?: string;
    productListingMessageTitleBM?: string;
    productListingMessageDescEN?: string;
    productListingMessageDescBM?: string;
}

export interface ICreateProductCategory {
    categoryName: string;
    status: StatusTypeEnum;
}

export interface IEditProductCategory {
    id: string;
    categoryName: string;
    status: StatusTypeEnum;
    reorderBy?: IReorderSequence;
    productListingMessageTitleEN?: string;
    productListingMessageTitleBM?: string;
    productListingMessageDescEN?: string;
    productListingMessageDescBM?: string;
}

export interface ProductListDetails {
    _id: string;
    productCategoryId: string;
    productName: string;
    productTitleEN: string;
    productDescEN: string;
    productTitleBM: string;
    productDescBM: string;
    productPrice: string;
    promotionalPrice: string;
    productLinkEN: string;
    productLinkBM: string;
    productVariant: VariantsListDetails[];
    status: StatusTypeEnum;
    createdAt: string;
}

export interface IEditProductDetails {
    id: string;
    productName: string;
    productTitleEN: string;
    productDescEN: string;
    productTitleBM: string;
    productDescBM: string;
    productPrice: string;
    promotionalPrice: string;
    productLinkEN: string;
    productLinkBM: string;
    status: StatusTypeEnum;
    reorderBy?: IReorderSequence;
}

export interface ICreateProductList {
    productName: string;
    productCategoryId: string;
}

export interface VariantsListDetails {
    _id: string;
    variantNameEN: string;
    variantNameBM: string;
    variantImage: string;
    defaultVariant: boolean;
}

export interface ICreateVariant {
    productId: string;
    variantNameEN: string;
    variantNameBM: string;
    variantImage?: File;
}

export interface IEditVariant {
    productId: string;
    variantId: string;
    variantNameEN: string;
    variantNameBM: string;
    variantImage: string;
    variantImageUpload?: File;
    defaultVariant: boolean;
}

export enum IReorderSequence {
    MoveUp = 1,
    MoveDown = 2,
}
