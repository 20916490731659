import { put, call, select, takeEvery } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import PostsGateway from 'api/Posts';
import { PerformingPostsPayloadParams } from 'redux/slices/posts/types';

export default function* watchGetPerformingPosts(api: PostsGateway): SagaWatcherReturnType {
    yield takeEvery('posts/getPerformingPostsAttempt', hanldeGetPerformingPosts, api);
}

function* hanldeGetPerformingPosts(api: PostsGateway, data: PerformingPostsPayloadParams) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.getPerformingPosts], {
        authToken,
        channelType: data.payload.channelType,
        dateFrom: data.payload.dateFrom,
        dateTo: data.payload.dateTo,
        sort: data.payload.sort,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.getPerformingPostsFailure(response.message || ''));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.getPerformingPostsSuccess(response.data));
    }
}
