import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { toast } from 'react-toastify';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import ContestsGateway from 'api/Contests';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';
import { DeleteContestAttemptPayload } from 'redux/slices/contests/types';
import { ContestListTypesEnum, IChannelTypeEnum, IContestStatusEnum } from 'entities/contests';

export default function* watchDeleteContest(api: ContestsGateway): SagaWatcherReturnType {
    yield takeEvery('contests/deleteContestAttempt', handleDeleteContest, api);
}

function* handleDeleteContest(api: ContestsGateway, data: DeleteContestAttemptPayload) {
    const isLanu = yield* select(Selectors.getFaqGetIsLANU);
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.deleteContest], {
        _id: data.payload._id,
        authToken,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.deleteContestFailure(response.message));
        toast.error(response.message);
        return;
    }

    if (response.status === GatewayResponseStatus.Success) {
        toast.success('Contest deleted successfully');
        yield put(Actions.deleteContestSuccess());

        yield put(Actions.setActiveQuizListIndex(1));
        yield put(Actions.setInactiveQuizListIndex(1));
        const activeQuizIndex = yield* select(Selectors.contestGetActiveQuizListIndex);
        const inactiveQuizIndex = yield* select(Selectors.contestGetInactiveQuizListIndex);
        yield put(Actions.getActiveQuizListAttempt({ index: activeQuizIndex }));
        yield put(Actions.getInactiveQuizListAttempt({ index: inactiveQuizIndex }));
        yield put(Actions.contestSetDeleteQuizModalState({ id: '', isOpen: false, type: ContestListTypesEnum.Quiz }));
    }
}
