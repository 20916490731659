import { ApiResponse } from 'apisauce';

import { RecurringPost, SchedulePost, BroadcastHistoryPost, ICreateBroadcastPost, BroadcastOverview, HistoryDetails, BroadcastHistoryPostExport, HistoryDetailsExport } from 'entities/broadcast';
import { GatewayResponse } from './types/types';

import {
    IBroadcastGateway,
    GetRecurringPostParams,
    GetSchedulePostParams,
    GetHistoryPostParams,
    CreateBroadcastPostParams,
    GetBroadcastOverviewParams,
    EditBroadcastPostParams,
    RecallBroadcastMessageParams,
    GetHistoryDetailsParams,
    GetHistoryPostExportParams,
    GetHistoryDetailsExportParams,
} from './BroadcastBase';

const getHeaders = (authToken: string) => ({
    headers: {
        Authorization: `Bearer ${authToken}`,
    },
});

export default class BroadcastGateway extends IBroadcastGateway {
    async getBroadcastOverview(params: GetBroadcastOverviewParams): GatewayResponse<BroadcastOverview> {
        const { date } = params;
        const response: ApiResponse<BroadcastOverview> = await this.api.get(`/v1/broadcasts/overview?date=${date}`, {}, getHeaders(params.authToken));

        return this.process(response);
    }

    async getRecurringPost(params: GetRecurringPostParams): GatewayResponse<RecurringPost> {
        const { index, status } = params;
        const response: ApiResponse<RecurringPost> = await this.api.get(`/v1/broadcasts/recurringBroadcasts?index=${index}&status=${status}`, {}, getHeaders(params.authToken));

        return this.process(response);
    }

    async getSchedulePost(params: GetSchedulePostParams): GatewayResponse<SchedulePost> {
        const { index, status } = params;
        const response: ApiResponse<SchedulePost> = await this.api.get(`/v1/broadcasts/scheduledBroadcasts?index=${index}&status=${status}`, {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async getHistoryPost(params: GetHistoryPostParams): GatewayResponse<BroadcastHistoryPost> {
        const { index, status } = params;
        const response: ApiResponse<BroadcastHistoryPost> = await this.api.get(`/v1/broadcasts/history?index=${index}&broadcastType=${status}`, {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async getHistoryPostExport(params: GetHistoryPostExportParams): GatewayResponse<BroadcastHistoryPostExport[]> {
        const { broadcastType } = params;
        const response: ApiResponse<BroadcastHistoryPostExport[]> = await this.api.get('/v1/broadcasts/history/export', { broadcastType }, getHeaders(params.authToken));

        return this.process(response);
    }

    async getHistoryDetails(params: GetHistoryDetailsParams): GatewayResponse<HistoryDetails> {
        const response: ApiResponse<HistoryDetails> = await this.api.get(`/v1/broadcasts/${params.id}`, {}, getHeaders(params.authToken));
        return this.process(response);
    }

    async getHistoryDetailsExport(params: GetHistoryDetailsExportParams): GatewayResponse<HistoryDetailsExport[]> {
        const { broadcastHistoryId } = params;
        const response: ApiResponse<HistoryDetailsExport[]> = await this.api.get('/v1/broadcasts/historyDetails/export', { broadcastHistoryId }, getHeaders(params.authToken));

        return this.process(response);
    }

    async createBroadcastPost(params: CreateBroadcastPostParams): GatewayResponse<ICreateBroadcastPost> {
        const form = new FormData();

        form.append('title', params.data.title);
        form.append('message', params.data.message);
        form.append('messageType', params.data.messageType.toString());
        if (params.data.recipientList) {
            form.append('recipientList', params.data.recipientList);
        }
        form.append('broadcastType', params.data.broadcastType.toString());
        if (params.data.file) {
            form.append('file', params.data.file as Blob);
        }
        form.append('startDate', params.data.startDate.toISOString());
        if (params.data.expiryDate) {
            form.append('expiryDate', params.data.expiryDate.toISOString());
        }
        if (params.data.frequency) {
            form.append('frequency', params.data.frequency.toString());
        }
        form.append('recipientListEnum', params.data.recipientListEnum);
        if (params.data.mediaUrl) {
            form.append('mediaUrl', params.data.mediaUrl);
        }
        if (params.data.fileId) {
            form.append('fileId', params.data.fileId);
        }

        const response: ApiResponse<ICreateBroadcastPost> = await this.api.post('/v1/broadcasts/broadcast', form, getHeaders(params.authToken));
        return this.process(response);
    }

    async editBroadcastPost(params: EditBroadcastPostParams): GatewayResponse<null> {
        const form = new FormData();

        if (params.data.broadcastId) {
            form.append('broadcastId', params.data.broadcastId);
        }
        form.append('title', params.data.title);
        form.append('message', params.data.message);
        form.append('messageType', params.data.messageType.toString());
        if (params.data.recipientList) {
            form.append('recipientList', params.data.recipientList);
        }
        form.append('broadcastType', params.data.broadcastType.toString());
        if (params.data.mediaUrl) {
            form.append('mediaUrl', params.data.mediaUrl);
        }
        form.append('startDate', params.data.startDate.toISOString());
        if (params.data.expiryDate) {
            form.append('expiryDate', params.data.expiryDate.toString());
        }
        if (params.data.frequency) {
            form.append('frequency', params.data.frequency.toString());
        }
        form.append('status', params.data.status.toString());
        form.append('recipientListEnum', params.data.recipientListEnum);
        if (params.data.file) {
            form.append('file', params.data.file as Blob);
        }

        const response: ApiResponse<null> = await this.api.put('/v1/broadcasts/editBroadcast', form, getHeaders(params.authToken));
        return this.process(response);
    }

    async recallBroadcastMessage(params: RecallBroadcastMessageParams): GatewayResponse<null> {
        const response: ApiResponse<null> = await this.api.put(`/v1/broadcasts/recall/${params.id}`, {}, getHeaders(params.authToken));
        return this.process(response);
    }
}
