import React from 'react';
import styled from 'styled-components';
import Colors from 'assets/themes/Colors';

type ContentWrapperProps = {
    children: React.ReactNode | React.ReactNode[]
    backgroundColor?: string;
    gap? : string;
}

const ContentWrapper = ({ children, backgroundColor, gap }:ContentWrapperProps):React.ReactElement => {
    return (
        <MainWrapper style={{ backgroundColor }} containerGap={gap} containerBgColor={backgroundColor}>
            {children}
        </MainWrapper>
    );
};

ContentWrapper.defaultProps = {
    backgroundColor: Colors.bgPrimary,
    gap: '0',
};

interface MainWrapperProps {
    containerGap?: string;
    containerBgColor?: string;
}

const MainWrapper = styled.div<MainWrapperProps>`
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-directions: row;
    gap: ${props => props.containerGap};

    background-color: ${props => props.containerBgColor};
    border-radius: 20px;

    padding: 1.6% 1.6% 1% 1.6%;

    width: 71%;
    height: 100%;
    margin-bottom: 5%;
    margin-right: 7%;
    
    & > * {
        width:100%;
        padding:1rem !important;
    }
`;

export default ContentWrapper;
