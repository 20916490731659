import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { connect } from 'react-redux';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import FontStyle from 'assets/themes/Fonts';

import ContentWrapper from 'components/ContentWrapper';
import Flex from 'components/Flex';

import {
    IGetFollowersUsersListReduxParam,
    IGetFollowersTablesReduxParam,
    IGetFollowersChartsDataReduxParam,
} from 'entities/followers';

import IQOSDateRangePicker from 'components/IQOSDateRangePicker';
import Charts from './Charts';
import List from './List';
import Tables from './Tables';

interface FollowersProps {
    followersUsersListCurrentIndex: number;
    channelType: boolean;
    getFollowersUserList: (param:IGetFollowersUsersListReduxParam) => void;
    getFollowersTablesInfo: (param:IGetFollowersTablesReduxParam) => void;
    getFollowersChartsData: (param: IGetFollowersChartsDataReduxParam) => void;
    resetFollowersUsersList: () => void;
}

const Followers:React.FunctionComponent<FollowersProps> = ({
    followersUsersListCurrentIndex,
    getFollowersUserList,
    resetFollowersUsersList,
    getFollowersTablesInfo,
    getFollowersChartsData,
    channelType,

}) => {
    const [startDate, setStartDate] = React.useState<Date | null>(dayjs().subtract(1, 'month').toDate());
    const [endDate, setEndDate] = React.useState<Date | null>(dayjs().toDate());

    React.useEffect(() => {
        if (startDate && endDate) {
            const dateFrom = dayjs(startDate).format('YYYY-MM-DD');
            const dateTo = dayjs(endDate).format('YYYY-MM-DD');
            resetFollowersUsersList();
            getFollowersUserList({ dateTo, dateFrom, index: followersUsersListCurrentIndex });
            getFollowersTablesInfo({ dateTo, dateFrom });
            getFollowersChartsData({ dateFrom: startDate, dateTo: endDate });
        }
    }, [endDate, channelType]);

    React.useEffect(() => {
        if (startDate && endDate) {
            const dateFrom = dayjs(startDate).format('YYYY-MM-DD');
            const dateTo = dayjs(endDate).format('YYYY-MM-DD');
            getFollowersUserList({ dateTo, dateFrom, index: followersUsersListCurrentIndex });
        }
    }, [followersUsersListCurrentIndex]);

    const renderTopPanel = () => {
        return (
            <Flex alignItems='center' gap='1rem' justifyContent='space-between' flexWrap='wrap'>
                <StyledHeader size='lg'>
                    Followers
                </StyledHeader>
                <IQOSDateRangePicker
                    dateFrom={startDate}
                    dateTo={endDate}
                    setDateFrom={setStartDate}
                    setDateTo={setEndDate}
                />
            </Flex>
        );
    };

    return (
        <ContentWrapper gap='2rem'>
            {renderTopPanel()}
            <Charts />
            <Tables />
            <List
                childDateFrom={startDate}
                childDateTo={endDate}
            />
        </ContentWrapper>
    );
};

interface StyledHeaderProps {
    size: string;
}

const changeFontSize = (size:string) => {
    let fontSize:string;
    switch (size) {
        case 'sm':
            fontSize = FontStyle.sm;
            break;
        case 'lg':
            fontSize = FontStyle.lg;
            break;
        default:
            fontSize = FontStyle.md;
            break;
    }
    return fontSize;
};

const StyledHeader = styled.div<StyledHeaderProps>`
    font-size: ${props => changeFontSize(props.size)};
    color: #27242E;
    font-family: IQOS-Bold;
    font-weight: bold;
`;

const mapStateToProps = (state: RootState) => ({
    followersUsersListCurrentIndex: Selectors.followersGetFollowersUsersListCurrentIndex(state),
    channelType: Selectors.getFaqGetIsLANU(state),

});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getFollowersUserList: (param:IGetFollowersUsersListReduxParam) => dispatch(Actions.followersGetFollowersUsersListAttempt(param)),
    getFollowersTablesInfo: (param:IGetFollowersTablesReduxParam) => dispatch(Actions.followersGetFollowersTablesInfoAttempt(param)),
    getFollowersChartsData: (param:IGetFollowersChartsDataReduxParam) => dispatch(Actions.followersGetFollowersChartsDataAttempt(param)),

    resetFollowersUsersList: () => dispatch(Actions.followersResetFollowersUsersList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Followers);
