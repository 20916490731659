import { ProductCategoryDetails, ProductListDetails, ProductListingMessageDetails, PromotionalMessageDetails, CTAButtonDetails, WelcomeMessageDetails } from 'entities/ecommerce';
import { ECommerceState } from '.';

const getPromotionalMessageAttempt = (state: ECommerceState): boolean => state.actions.getPromotionalMessage || false;
const getPromotionalMessageFailure = (state: ECommerceState): string => state.error.getPromotionalMessage || '';
const getPromotionalMessage = (state: ECommerceState): PromotionalMessageDetails[] => state.promotionalMessage || [];

const editPromotionalMessageAttempt = (state: ECommerceState): boolean => state.actions.setPromotionalMessage || false;
const editPromotionalMessageFailure = (state: ECommerceState): string => state.error.setPromotionalMessage || '';

const getWelcomeMessageAttempt = (state: ECommerceState): boolean => state.actions.getWelcomeMessage || false;
const getWelcomeMessageFailure = (state: ECommerceState): string => state.error.getWelcomeMessage || '';
const getWelcomeMessage = (state: ECommerceState): WelcomeMessageDetails[] => state.welcomeMessage || [];

const editWelcomeMessageAttempt = (state: ECommerceState): boolean => state.actions.setWelcomeMessage || false;
const editWelcomeMessageFailure = (state: ECommerceState): string => state.error.setWelcomeMessage || '';

const getProductListingMessageAttempt = (state: ECommerceState): boolean => state.actions.getProductListingMessage || false;
const getProductListingMessageFailure = (state: ECommerceState): string => state.error.getProductListingMessage || '';
const getProductListingMessage = (state: ECommerceState): ProductListingMessageDetails[] => state.productListingMessage || [];

const editProductListingMessageAttempt = (state: ECommerceState): boolean => state.actions.setProductListingMessage || false;
const editProductListingMessageFailure = (state: ECommerceState): string => state.error.setProductListingMessage || '';

const getProductCategoryAttempt = (state: ECommerceState): boolean => state.actions.getCategory || false;
const getProductCategoryFailure = (state: ECommerceState): string => state.error.getCategory || '';
const getProductCategory = (state: ECommerceState): ProductCategoryDetails[] => state.productCategoryData || [];

const createProductCategoryAttempt = (state: ECommerceState): boolean => state.actions.createCategory || false;
const createProductCategoryFailure = (state: ECommerceState): string => state.error.createCategory || '';

const editProductCategoryAttempt = (state: ECommerceState): boolean => state.actions.editCategory || false;
const editProductCategoryFailure = (state: ECommerceState): string => state.error.editCategory || '';

const deleteProductCategoryAttempt = (state: ECommerceState): boolean => state.actions.deleteCategory || false;
const deleteProductCategoryFailure = (state: ECommerceState): string => state.error.deleteCategory || '';

const getProductListAttempt = (state: ECommerceState): boolean => state.actions.createProduct || false;
const getProductListFailure = (state: ECommerceState): string => state.error.createProduct || '';
const getProductList = (state: ECommerceState): ProductListDetails[] => state.productListData || [];

const createProductListAttempt = (state: ECommerceState): boolean => state.actions.createProduct || false;
const createProductListFailure = (state: ECommerceState): string => state.error.createProduct || '';

const getProductDetailsAttempt = (state: ECommerceState): boolean => state.actions.getProductDetails || false;
const getProductDetailsFailure = (state: ECommerceState): string => state.error.getProductDetails || '';
const getProductDetails = (state: ECommerceState): ProductListDetails[] => state.productDetailsData || [];

const editProductDetailsAttempt = (state: ECommerceState): boolean => state.actions.editProductDetails || false;
const editProductDetailsFailure = (state: ECommerceState): string => state.error.editProductDetails || '';

const deleteProductDetailsAttempt = (state: ECommerceState): boolean => state.actions.deleteProduct || false;
const deleteProductDetailsFailure = (state: ECommerceState): string => state.error.deleteProduct || '';

const createProductVariantAttempt = (state: ECommerceState): boolean => state.actions.createProductVariant || false;
const createProductVariantFailure = (state: ECommerceState): string => state.error.createProductVariant || '';

const deleteProductVariantAttempt = (state: ECommerceState): boolean => state.actions.deleteProductVariant || false;
const deleteProductVariantFailure = (state: ECommerceState): string => state.error.deleteProductVariant || '';

const editProductVariantAttempt = (state: ECommerceState): boolean => state.actions.editProductVariant || false;
const editProductVariantFailure = (state: ECommerceState): string => state.error.editProductVariant || '';

const getProductCategoryId = (state: ECommerceState): string => state.productCategoryId || '';
const getCreateCategoryModalIsOpen = (state: ECommerceState): boolean => state.createCategoryModalIsOpen || false;
const getCreateProductModalIsOpen = (state: ECommerceState): boolean => state.createProductModalIsOpen || false;
const getCreateVariantModalIsOpen = (state: ECommerceState): boolean => state.createVariantModalIsOpen || false;
const getEditVariantModalIsOpen = (state: ECommerceState): boolean => state.editVariantModalIsOpen || false;

const getCTAButtonDetailsAttempt = (state: ECommerceState): boolean => state.actions.getCTAButtonDetails || false;
const getCTAButtonDetailsFailure = (state: ECommerceState): string => state.error.getCTAButtonDetails || '';
const getCTAButtonDetails = (state: ECommerceState): CTAButtonDetails[] => state.CTAButtonDetails || [];

const editCTAButtonDetailsAttempt = (state: ECommerceState): boolean => state.actions.setCTAButtonDetails || false;
const editCTAButtonDetailsFailure = (state: ECommerceState): string => state.error.setCTAButtonDetails || '';

export default {
    getPromotionalMessageAttempt,
    getPromotionalMessageFailure,
    getPromotionalMessage,

    editPromotionalMessageAttempt,
    editPromotionalMessageFailure,

    getWelcomeMessageAttempt,
    getWelcomeMessageFailure,
    getWelcomeMessage,

    editWelcomeMessageAttempt,
    editWelcomeMessageFailure,

    getProductListingMessageAttempt,
    getProductListingMessageFailure,
    getProductListingMessage,

    editProductListingMessageAttempt,
    editProductListingMessageFailure,

    getProductCategoryAttempt,
    getProductCategoryFailure,
    getProductCategory,

    createProductCategoryAttempt,
    createProductCategoryFailure,

    editProductCategoryAttempt,
    editProductCategoryFailure,

    deleteProductCategoryAttempt,
    deleteProductCategoryFailure,

    getProductListAttempt,
    getProductListFailure,
    getProductList,

    createProductListAttempt,
    createProductListFailure,

    getProductDetailsAttempt,
    getProductDetailsFailure,
    getProductDetails,

    editProductDetailsAttempt,
    editProductDetailsFailure,

    deleteProductDetailsAttempt,
    deleteProductDetailsFailure,

    createProductVariantAttempt,
    createProductVariantFailure,

    editProductVariantAttempt,
    editProductVariantFailure,

    deleteProductVariantAttempt,
    deleteProductVariantFailure,

    getProductCategoryId,
    getCreateCategoryModalIsOpen,
    getCreateProductModalIsOpen,
    getCreateVariantModalIsOpen,
    getEditVariantModalIsOpen,

    getCTAButtonDetailsAttempt,
    getCTAButtonDetailsFailure,
    getCTAButtonDetails,

    editCTAButtonDetailsAttempt,
    editCTAButtonDetailsFailure,
};
