import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import { Spinner, Table } from 'reactstrap';
import { useParams, useLocation } from 'react-router';
import SVG from 'react-inlinesvg';
import dayjs from 'dayjs';
import Skeleton from 'react-loading-skeleton';

import { AppDispatch, RootState } from 'redux/store';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

import ContentWrapper from 'components/ContentWrapper';
import Flex from 'components/Flex';
import { ButtonStyles, TableStyles } from 'components/styles';
import IQOSPaginator from 'components/IQOSPaginator';

import Color from 'assets/themes/Colors';
import Icons from 'assets/icons/Index';

import { IChannelTypeEnum, IContestDetails, IContestResponse, IEditContestParams } from 'entities/contests';
import AnalyticsHeader from 'components/analytics/AnalyticsHeader';
import NavActions from 'lib/NavActions';

interface QuizDetailsProps {
    isLanu: boolean;
    quizDetails: IContestDetails;
    quizDetailsIsLoading: boolean;
    quizDetailsError: string;
    editQuizDetailsIsLoading: boolean;
    exportQuizFileisLoading: boolean;
    quizResponsesIndex: number;
    quizResponsesPage: number;
    quizResponsesMaxIndex: number;
    quizResponsesIsLoading: boolean;
    quizResponsesError: string;
    quizResponses: IContestResponse[];
    getQuizDetails: (id: string, type: IChannelTypeEnum) => void;
    getQuizResponses: (contestId: string, index: number, type: IChannelTypeEnum) => void;
    editQuizDetails: (data: IEditContestParams) => void;
    exportQuizFile: (contestId: string, type: IChannelTypeEnum) => void;
    setQuizIndex: (param: number) => void;
}

const QuizDetails:React.FunctionComponent<QuizDetailsProps> = ({
    isLanu,

    quizDetails,
    quizDetailsIsLoading,
    quizDetailsError,
    editQuizDetailsIsLoading,
    exportQuizFileisLoading,
    quizResponsesIndex,
    quizResponsesPage,
    quizResponsesMaxIndex,
    quizResponsesIsLoading,
    quizResponsesError,
    quizResponses,
    getQuizDetails,
    getQuizResponses,
    editQuizDetails,
    exportQuizFile,
    setQuizIndex,
}:QuizDetailsProps) => {
    const { id } = useParams();
    const tableHeaders = ['First Name', 'Username', 'Phone Numbers', 'Clicks Time', 'Total Correct Answers', 'Eligibility'];
    const { pathname } = useLocation();

    const [reRenderPaginator, setReRenderPaginator] = useState<boolean>(false);

    useEffect(() => {
        setReRenderPaginator(true);
        if (id) {
            getQuizDetails(id, isLanu ? IChannelTypeEnum.LANU : IChannelTypeEnum.LAU);
            getQuizResponses(id, 1, isLanu ? IChannelTypeEnum.LANU : IChannelTypeEnum.LAU);
            setTimeout(() => { setReRenderPaginator(false); }, 500);
        }
    }, [id, isLanu]);

    useEffect(() => {
        if (id) { getQuizResponses(id, quizResponsesIndex, isLanu ? IChannelTypeEnum.LANU : IChannelTypeEnum.LAU); }
    }, [quizResponsesIndex]);

    const renderName = () => {
        if (quizDetailsIsLoading) {
            return <Spinner size='sm' />;
        }

        if (quizDetailsError) {
            return quizDetailsError;
        }

        return quizDetails.name || 'Contest name is empty';
    };

    const renderExportButtonText = () => {
        if (exportQuizFileisLoading) {
            return (<Spinner size='sm' />);
        }
        return 'Export Data';
    };
    const renderTopPanel = () => {
        return (
            <>
                <Flex justifyContent='space-between'>
                    <AnalyticsHeader header={renderName()} size='lg' />

                    <StyledButton onClick={() => exportQuizFile(quizDetails.id, isLanu ? IChannelTypeEnum.LANU : IChannelTypeEnum.LAU)} css={ButtonStyles.primary} type='button'>
                        {renderExportButtonText()}
                    </StyledButton>
                </Flex>
                <Flex justifyContent='space-between' alignItems='center' margin='2rem 0 0 0'>
                    <StyledButton type='button' css={ButtonStyles.blank} onClick={() => NavActions.navToContestsQuiz()}>
                        <Flex justifyContent='space-between' flexWrap='nowrap' alignItems='center' gap='.3rem'>
                            <SVG fill={Color.iqosPrimary} src={Icons.ChevronLeft} style={{ transform: '180deg' }} height={30} />
                            <AnalyticsHeader size='sm' header='Back to Report' fontColor={Color.iqosPrimary} />
                        </Flex>
                    </StyledButton>
                    {quizDetails.totalResponses > 0 && !quizDetailsError && (
                        <TotalResponsesContainer>
                            Total:
                            {' '}
                            {quizDetails.totalResponses}
                            {' '}
                            Response
                            {quizDetails.totalResponses > 1 && 's'}
                        </TotalResponsesContainer>
                    )}
                </Flex>
            </>

        );
    };

    const renderQuizTable = () => {
        const renderTableBody = () => {
            if (quizResponsesIsLoading) {
                return (
                    <tr>
                        <AlignedTextTd colSpan={tableHeaders.length}>
                            <Skeleton count={5} height={50} />
                        </AlignedTextTd>
                    </tr>
                );
            }

            if (!quizResponses.length) {
                return (
                    <tr>
                        <AlignedTextTd colSpan={tableHeaders.length}>
                            There appears to be no data yet.
                        </AlignedTextTd>
                    </tr>
                );
            }

            if (quizResponsesError) {
                return (
                    <tr>
                        <AlignedTextTd colSpan={tableHeaders.length}>
                            {quizResponsesError}
                        </AlignedTextTd>
                    </tr>
                );
            }

            return quizResponses.map((quiz) => {
                const adjustClickTime = (clickTime:Date):string => {
                    if (clickTime) {
                        return dayjs(clickTime).format('YYYY-MM-DD HH:mmA');
                    }
                    return '-';
                };

                return (
                    <tr key={quiz.id}>
                        <td>{quiz.firstName || '-'}</td>
                        <td>{quiz.username || '-'}</td>
                        <td>{quiz.phoneNumber || '-'}</td>
                        <td>{adjustClickTime(dayjs(quiz.clickTime).toDate())}</td>
                        <td>{quiz.totalCorrectAnswers || '-'}</td>
                        <td>{quiz.eligible ? 'Eligible' : 'Not Eligible'}</td>
                    </tr>
                );
            });
        };
        return (
            <>
                <StyledTable id={`quiz--${quizDetails.id}`} css={TableStyles.primary}>
                    <thead>
                        <tr>
                            {tableHeaders.map((item) => {
                                return (<th key={`quiz-${quizDetails.id}-table-headers-${item}`}>{item}</th>);
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {renderTableBody()}
                    </tbody>
                </StyledTable>
                {(quizResponses.length && (reRenderPaginator === false)) ? (
                    <IQOSPaginator
                        maxIndex={quizResponsesMaxIndex}
                        setIndex={setQuizIndex}
                        currentIndex={quizResponsesIndex}
                        url={pathname}
                        containerId={`quiz--${quizDetails.id}`}
                    />
                ) : null}
            </>
        );
    };

    return (
        <ContentWrapper>
            {renderTopPanel()}
            <TableContainer>
                {renderQuizTable()}
            </TableContainer>
        </ContentWrapper>
    );
};

type StyledCSSProps = {
    css?: FlattenSimpleInterpolation
}

const StyledButton = styled.button<StyledCSSProps>`
    ${props => props.css}
`;

const TableContainer = styled.div`
    margin-bottom: 1rem;
`;

const AlignedTextTd = styled.td`
    text-align:center;
`;

const StyledTable = styled(Table)<StyledCSSProps>`
    margin-top:1rem;
    ${props => props.css}
`;

const TotalResponsesContainer = styled.div`
    color: ${Color.lightGrey};
`;

const mapStateToProps = (state: RootState) => ({
    isLanu: Selectors.getFaqGetIsLANU(state),

    quizDetails: Selectors.getContestDetails(state),
    quizDetailsIsLoading: Selectors.getContestDetailsAttempting(state),
    quizDetailsError: Selectors.getContestDetailsError(state),

    editQuizDetailsIsLoading: Selectors.getContestDetailsAttempting(state),

    exportQuizFileisLoading: Selectors.getContestExportResponseAttempting(state),

    quizResponsesIndex: Selectors.getContestDetailsResponsesIndex(state),
    quizResponsesPage: Selectors.getContestDetailsResponsesPage(state),
    quizResponsesMaxIndex: Selectors.getContestDetailsResponsesMaxIndex(state),
    quizResponsesIsLoading: Selectors.getContestDetailsResponsesAttempting(state),
    quizResponsesError: Selectors.getContestDetailsResponsesError(state),
    quizResponses: Selectors.getContestDetailsResponses(state),

});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getQuizDetails: (id: string, type: IChannelTypeEnum) => dispatch(Actions.contestGetContestDetailsAttempt({ id, type })),
    getQuizResponses: (contestId: string, index: number, type: IChannelTypeEnum) => dispatch(Actions.contestGetContestResponsesAttempt({ contestId, index, type })),
    editQuizDetails: (data: IEditContestParams) => dispatch(Actions.contestEditContestAttempt(data)),
    exportQuizFile: (contestId: string, type: IChannelTypeEnum) => dispatch(Actions.contestExportResponsesAttempt({ contestId, type })),
    setQuizIndex: (param: number) => dispatch(Actions.setContestPaginatorIndex(param)),

});
export default connect(mapStateToProps, mapDispatchToProps)(QuizDetails);
