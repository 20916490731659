import { put, call, select, takeEvery } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import GameGateway from 'api/Games';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { GetGameDetailsParamsPayload } from 'redux/slices/games/types';

export default function* watchGetGameDetails(api: GameGateway): SagaWatcherReturnType {
    yield takeEvery('games/getGameDetailsAttempting', handleGetGameDetails, api);
}

function* handleGetGameDetails(api: GameGateway, data: GetGameDetailsParamsPayload) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.getGameDetails], {
        authToken,
        id: data.payload.id,
        type: data.payload.type,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.editGameFailure(response.message || ''));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.getGameDetailsSuccess(response.data));
    }
}
