import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { toast } from 'react-toastify';

import ECommerceGateway from 'api/ECommerce';
import { DeleteProductVariantParams } from 'redux/slices/eCommerce/types';

export default function* watchDeleteProductVariant(api: ECommerceGateway): SagaWatcherReturnType {
    yield takeEvery('eCommerce/deleteProductVariantAttempt', handleDeleteProductVariant, api);
}

function* handleDeleteProductVariant(api: ECommerceGateway, data: DeleteProductVariantParams) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const { productId, variantId } = data.payload;

    const response = yield* call([api, api.deleteProductVariant], {
        authToken,
        productId,
        variantId,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.deleteProductVariantFailure(response.message));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.deleteProductVariantSuccess());
        toast.success('Variant deleted!');
    }
}
