import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPNCMessageDetails } from 'entities/PNCMessage';
import { PNCMessageReduxState, SetPNCMessageParams } from './types';

const initialState: PNCMessageReduxState = {
    actions: {
        getPNCMessage: false,
        setPNCMessage: false,
    },
    PNCMessage: {
        title: '',
        message: '',
        mediaUrl: '',
    },
    error: {
        getPNCMessage: '',
        setPNCMessage: '',
    },
};

const PNCMessageSlice = createSlice({
    name: 'pncMessage',
    initialState,
    reducers: {
        getPNCMessageAttempt: (state) => {
            state.actions.getPNCMessage = true;
            state.error.getPNCMessage = '';
        },
        getPNCMessageSuccess: (state, action: PayloadAction<IPNCMessageDetails>) => {
            state.actions.getPNCMessage = false;
            state.error.getPNCMessage = '';

            if (action.payload) {
                state.PNCMessage = action.payload;
            }
        },
        getPNCMessageFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getPNCMessage = false;

            if (action.payload) {
                state.error.getPNCMessage = action.payload;
            }
        },
        setPNCMessageAttempt: (state, _action: SetPNCMessageParams) => {
            state.actions.setPNCMessage = true;
            state.error.setPNCMessage = '';
        },
        setPNCMessageSuccess: (state) => {
            state.actions.setPNCMessage = false;
            state.error.setPNCMessage = '';
        },
        setPNCMessageFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setPNCMessage = false;

            if (action.payload) {
                state.error.setPNCMessage = action.payload;
            }
        },
    },
});

export type PNCMessageState = typeof initialState;

export default {
    actions: PNCMessageSlice.actions,
    reducers: PNCMessageSlice.reducer,
};
