import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';
import {
    IGetFollowersTablesInfoAPIParam,
    IFollowersTableInfo,

    IGetFollowersUsersListAPIParam,
    IGetFollowersUsersListResponse,

    IGetFollowersChartsDataAPIParam,
    IFollowersChartsResponse,

    IGetFullFollowersUsersListAPIParam,
    IGetFullFollowersUsersListResponse,
    IExportFullFollowersListToExcelAPIParam,
    IExportFullFollowersListToExcelResponse,
} from 'entities/followers';

export default abstract class IFollowersGateway extends Gateway {
    abstract getFollowersUsersList(params:IGetFollowersUsersListAPIParam): GatewayResponse<IGetFollowersUsersListResponse>;

    abstract getFollowersTablesInfo(params: IGetFollowersTablesInfoAPIParam): GatewayResponse<IFollowersTableInfo>;

    abstract getFollowersChartsData(params: IGetFollowersChartsDataAPIParam): GatewayResponse<IFollowersChartsResponse>;

    abstract getFullFollowersUsersList(params:IGetFullFollowersUsersListAPIParam): GatewayResponse<IGetFullFollowersUsersListResponse>;

    abstract exportFullFollowersListToExcel(params:IExportFullFollowersListToExcelAPIParam): GatewayResponse<IExportFullFollowersListToExcelResponse>;
}
