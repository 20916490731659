import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { toast } from 'react-toastify';

import ECommerceGateway from 'api/ECommerce';
import { SetPromotionalMessageParams } from 'redux/slices/eCommerce/types';

export default function* watchSetPromotionalMessage(api: ECommerceGateway): SagaWatcherReturnType {
    yield takeEvery('eCommerce/setPromotionalMessageAttempt', handleSetPromotionalMessage, api);
}

function* handleSetPromotionalMessage(api: ECommerceGateway, data: SetPromotionalMessageParams) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const { title, message, file, clearImage, language } = data.payload;

    const response = yield* call([api, api.setPromotionalMessage], {
        authToken,
        title,
        message,
        file,
        clearImage,
        language,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.setPromotionalMessageFailure(response.message));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.setPromotionalMessageSuccess());
        yield put(Actions.getPromotionalMessageAttempt());
        toast.success('Message Saved Successfully');
    }
}
