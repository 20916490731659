import React, { useState, useEffect, FunctionComponent } from 'react';
// ? Redux Toolkit
import { RootState, AppDispatch } from 'redux/store';
import { connect } from 'react-redux';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import SVG from 'react-inlinesvg';
import { useParams } from 'react-router';
import styled, { FlattenSimpleInterpolation, css } from 'styled-components';

import Icons from 'assets/icons/Index';
import Fonts from 'assets/themes/Fonts';
import Colors from 'assets/themes/Colors';

import Input from 'components/Input';
import ProductListTable from 'components/ecommerce/ListTable';
import CollapsibleCard from 'components/ecommerce/CollapsibleCard';

import {
    IEditProductCategory,
    ProductCategoryDetails,
    ProductListDetails,
    StatusTypeEnum,
    TableTypeEnum,
} from 'entities/ecommerce';

import NavActions from 'lib/NavActions';
import CreateProductModal from './CreateProductModal';

// ! interface zone
// * props interface
export interface ProductsProps {
    // ? product list props
    getProductList: (categoryId: string) => void;
    productList: ProductListDetails[];
    // ? product category props
    productCategory: ProductCategoryDetails[];
    editProductCategory: (data: IEditProductCategory) => void;
    setProductCategoryId: (categoryId: string) => void;
    // ? create product modal props
    setCreateProductModalIsOpen: (state: boolean) => void;
}

// ! JSX function component
const Products: FunctionComponent<ProductsProps> = (props: ProductsProps) => {
    // ! destructuring zone
    // * props destructured
    const {
        getProductList,
        productList,
        productCategory,
        editProductCategory,
        setProductCategoryId,
        setCreateProductModalIsOpen,
    } = props;
    // ? get product category id from params
    const { id } = useParams();
    // ! useState Zone
    // ? selected product category state
    const [categoryId, setCategoryId] = useState<string>('');
    const [categoryName, setCategoryName] = useState<string>('');
    const [categoryStatus, setCategoryStatus] = useState<StatusTypeEnum>(StatusTypeEnum.All);
    // ? product category edit state
    const [productCategoryIsEdit, setProductCategoryIsEdit] = useState<boolean>(false);
    const [editedProductCategory, setEditedProductCategory] = useState<string>('');
    // ? product listing message data from api
    const [originalEnTitle, setOriginalEnTitle] = useState<string>('');
    const [originalEnDescription, setOriginalEnDescription] = useState<string>('');
    const [originalBmTitle, setOriginalBmTitle] = useState<string>('');
    const [originalBmDescription, setOriginalBmDescription] = useState<string>('');
    // ? product listing message state
    const [newEnTitle, setNewEnTitle] = useState<string>('');
    const [newEnDescription, setNewEnDescription] = useState<string>('');
    const [newBmTitle, setNewBmTitle] = useState<string>('');
    const [newBmDescription, setNewBmDescription] = useState<string>('');

    // ! useEffect zone
    // ? used to redirect user to ecommerce page if id not valid
    useEffect(() => {
        if (id) {
            const currentSelected = productCategory.find((category) => category._id === id) as ProductCategoryDetails;
            if (currentSelected) {
                setEditedProductCategory(currentSelected.categoryName);
                setCategoryId(currentSelected._id);
                setCategoryName(currentSelected.categoryName);
                setCategoryStatus(currentSelected.status);
                setProductCategoryId(currentSelected._id);
                getProductList(id);
                setOriginalEnTitle(currentSelected.productListingMessageTitleEN ?? '');
                setOriginalEnDescription(currentSelected.productListingMessageDescEN ?? '');
                setOriginalBmTitle(currentSelected.productListingMessageTitleBM ?? '');
                setOriginalBmDescription(currentSelected.productListingMessageDescBM ?? '');
                setNewEnTitle(currentSelected.productListingMessageTitleEN ?? '');
                setNewEnDescription(currentSelected.productListingMessageDescEN ?? '');
                setNewBmTitle(currentSelected.productListingMessageTitleBM ?? '');
                setNewBmDescription(currentSelected.productListingMessageDescBM ?? '');
            } else {
                NavActions.navToECommerce();
            }
        }
    }, [id, productCategory]);

    // ! functions
    // ? handle status toggle
    const handleProductCategoryEdit = () => {
        editProductCategory({
            id: categoryId,
            categoryName: editedProductCategory,
            status: categoryStatus,
        });
        setProductCategoryIsEdit(false);
    };
    const handleSaveButton = () => {
        editProductCategory({
            id: categoryId,
            categoryName,
            status: categoryStatus,
            productListingMessageTitleEN: newEnTitle,
            productListingMessageTitleBM: newBmTitle,
            productListingMessageDescEN: newEnDescription,
            productListingMessageDescBM: newBmDescription,
        });
    };
    const handleResetButton = () => {
        setNewEnTitle(originalEnTitle);
        setNewEnDescription(originalEnDescription);
        setNewBmTitle(originalBmTitle);
        setNewBmDescription(originalBmDescription);
    };
    // ! render zone
    const renderPageTitle = () => {
        if (productCategoryIsEdit) {
            return (
                <TitleContainer>
                    <Input
                        value={editedProductCategory}
                        onChange={(e) => setEditedProductCategory(e.target.value)}
                        css={styles.input}
                    />
                    <StyledActionButton
                        onClick={() => handleProductCategoryEdit()}
                        css={styles.updatedPencilIcon}
                        style={{ border: 'none' }}
                    >
                        <SVG src={Icons.Save} id='edit' />
                    </StyledActionButton>
                    <StyledActionButton
                        onClick={() => setProductCategoryIsEdit(false)}
                        css={styles.updatedPencilIcon}
                        style={{ border: 'none' }}
                    >
                        <SVG src={Icons.Undo} id='edit' />
                    </StyledActionButton>
                </TitleContainer>
            );
        }
        return (
            <TitleContainer>
                <StyledTitle>{categoryName}</StyledTitle>
                <StyledActionButton
                    onClick={() => setProductCategoryIsEdit(true)}
                    css={styles.updatedPencilIcon}
                >
                    <SVG src={Icons.PencilIcon} id='edit' />
                </StyledActionButton>
            </TitleContainer>
        );
    };
    const renderReminder = () => {
        const reminderMessage = (
            <ReminderContainer>You have made some changes! Click save to save your changes.</ReminderContainer>
        );
        // ? check for Product Listing Message input changes
        if (
            (newEnTitle !== originalEnTitle || newEnDescription !== originalEnDescription)
            || (newBmTitle !== originalBmTitle || newBmDescription !== originalBmDescription)
        ) {
            return reminderMessage;
        }
        return null;
    };

    // * main render
    return (
        <MainWrapper>
            <MainContent>
                <ContentContainer>
                    <ButtonContainer>
                        {renderPageTitle()}
                        <CreateProductModal />
                        <StyledActionButton
                            type='button'
                            css={styles.primary}
                            onClick={() => setCreateProductModalIsOpen(true)}
                        >
                            Add New Product
                        </StyledActionButton>
                    </ButtonContainer>
                    <ProductListTable
                        tableType={TableTypeEnum.Products}
                        tableData={productList}
                    />
                </ContentContainer>
                <ContentContainer>
                    <StyledTitle>Product Listing message</StyledTitle>
                    <Content>
                        <CollapsibleCard
                            index='1.0'
                            title='English'
                            titleContent={newEnTitle}
                            descriptionContent={newEnDescription}
                            handleTitleChange={(e) => setNewEnTitle(e.target.value)}
                            handleDescriptionChange={(e) => setNewEnDescription(e.target.value)}
                            isOpenByDefault
                        />
                        <CollapsibleCard
                            index='2.0'
                            title='Bahasa Melayu'
                            titleContent={newBmTitle}
                            descriptionContent={newBmDescription}
                            handleTitleChange={(e) => setNewBmTitle(e.target.value)}
                            handleDescriptionChange={(e) => setNewBmDescription(e.target.value)}
                        />
                    </Content>
                    <div style={{ marginTop: '20px' }}>
                        {renderReminder()}
                        <ButtonWrapper>
                            <StyledActionButton
                                type='button'
                                css={styles.primary}
                                onClick={() => handleSaveButton()}
                            >
                                Save
                            </StyledActionButton>
                            <StyledActionButton
                                type='button'
                                css={styles.secondary}
                                onClick={() => handleResetButton()}
                            >
                                Reset
                            </StyledActionButton>
                        </ButtonWrapper>
                    </div>
                </ContentContainer>
            </MainContent>
        </MainWrapper>
    );
};

// ! styled-components
interface StyledButtonProps {
    css?: FlattenSimpleInterpolation;
}
const MainWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: space-evenly;

    background-color: #F1F6F8;
    border-radius: 20px;

    padding-top: 1%;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 1%;

    width: 71%;
    height: 100%;

    margin-bottom: 5%;
    margin-right: 7%;
`;
const MainContent = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    gap: 20px;
`;
const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
`;
const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
    margin-top: 2%;
    /* border: 1px solid blueviolet; */
`;
const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const StyledTitle = styled.text`
    font-size: 32px;
    color: #27242E;
    padding-right: 30px;
    font-family: ${Fonts.secondary};
    font-weight: bold;
    max-width: 650px;

    @media (max-width: 1580px) {
        font-size: 27px;
    }
`;
const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap:10px;
    margin-top: 20px;
    `;
const StyledActionButton = styled.button<StyledButtonProps>`
    cursor:pointer;
    ${props => props.css}
`;
const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    gap: 20px;
`;
const ReminderContainer = styled.div`
    color: ${Colors.iqosError};
    font-style: italic;
`;
const styles = {
    mainTitle: css`
        font-size: 100px;
    `,
    primary: css`
        min-width:150px;
        color: ${Colors.white};
        background-color: ${Colors.iqosPrimary};
        font-size: 18px;
        height:46px;
        border: 1px solid ${Colors.iqosPrimary};
        font-family: ${Colors.secondary};
        border-radius: 20px;
        font-weight: 600;
        color: white;
        padding-inline: 30px;
    `,
    secondary: css`
        min-width:150px;
        color: ${Colors.primary};
        background-color: ${Colors.white};
        font-size: 18px;
        height:46px;
        border: 1px solid ${Colors.primary};
        font-family: ${Colors.secondary};
        border-radius: 20px;
        color: black;
    `,
    input: css`
        background-color: ${Colors.white};
        padding: 10px;
        width: max-content;
        font-size: 32px;
        color: #27242E;
        padding-right: 30px;
        font-family: ${Fonts.secondary};
        font-weight: bold;
    `,
    updatedPencilIcon: css`
        border: none;
        background-color: transparent;
        width: 40px;
        height: 40px;
    #edit {
        width: 35px;
        height: 35px;
        color: ${Colors.iqosPrimary};
        /* &:hover{
            color: #1976d2;
        } */
    }
    `,
};

// ! redux toolkit
// * Selectors
const mapStateToProps = (state: RootState) => ({
    // ? product category
    productCategory: Selectors.getECommerceGetProductCategory(state),
    // ? product list
    productList: Selectors.getECommerceGetProductList(state),
});
// * Dispatch
const mapDispatchToProps = (dispatch: AppDispatch) => ({
    // ? product category
    editProductCategory: (data: IEditProductCategory) => dispatch(Actions.editProductCategoryAttempt(data)),
    // ? product list
    getProductList: (categoryId: string) => dispatch(Actions.getProductListAttempt({ categoryId })),
    setProductCategoryId: (categoryId: string) => dispatch(Actions.setProductCategoryId(categoryId)),
    // ? create product modal setIsOpen
    setCreateProductModalIsOpen: (state: boolean) => dispatch(Actions.setCreateProductModalIsOpen(state)),
});
// * Connection
export default connect(mapStateToProps, mapDispatchToProps)(Products);
