import { put, call, select, takeEvery } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import ContestGateway from 'api/Contests';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { EditContestDetailsParamsPayload } from 'redux/slices/contests/types';
import { ContestListTypesEnum } from 'entities/contests';

export default function* watchEditContestDetails(api: ContestGateway): SagaWatcherReturnType {
    yield takeEvery('contests/contestEditContestAttempt', handleEditContestDetails, api);
}

function* handleEditContestDetails(api: ContestGateway, data: EditContestDetailsParamsPayload) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.editContestDetails], {
        authToken,
        data: data.payload,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.contestEditContestFailure(response.message || ''));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.contestEditContestSuccess(data.payload));
        toast.success('Contest status update success!');

        yield put(Actions.setActiveQuizListIndex(1));
        yield put(Actions.setInactiveQuizListIndex(1));
        const activeQuizIndex = yield* select(Selectors.contestGetActiveQuizListIndex);
        const inactiveQuizIndex = yield* select(Selectors.contestGetInactiveQuizListIndex);
        yield put(Actions.getActiveQuizListAttempt({ index: activeQuizIndex }));
        yield put(Actions.getInactiveQuizListAttempt({ index: inactiveQuizIndex }));
        yield put(Actions.contestSetEditContestModalState({ id: '', isOpen: false, type: ContestListTypesEnum.Quiz }));
    }
}
