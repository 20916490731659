import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import BroadcastGateway from 'api/Broadcast';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { toast } from 'react-toastify';
import { RecallBroadcastMessageParams } from 'redux/slices/broadcast/types';
import { BroadcastHistoryFilter, StatusTypeEnum } from 'entities/broadcast';

export default function* watchRecallBroadcastMessage(api: BroadcastGateway): SagaWatcherReturnType {
    yield takeEvery('broadcast/recallBroadcastMessageAttempt', handleRecallBroadcastMessage, api);
}

function* handleRecallBroadcastMessage(api: BroadcastGateway, data: RecallBroadcastMessageParams) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.recallBroadcastMessage], {
        authToken,
        id: data.payload.id,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.recallBroadcastMessageFailure(response.message || ''));

        yield put(Actions.getRecurringPostAttempt({ index: 1, status: StatusTypeEnum.All }));
        yield put(Actions.getSchedulePostAttempt({ index: 1, status: StatusTypeEnum.All }));
        yield put(Actions.getHistoryPostAttempt({ index: 1, status: BroadcastHistoryFilter.All }));

        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.recallBroadcastMessageSuccess());
        yield put(Actions.setRecallBroadcastMessageModalIsOpen(false));

        yield put(Actions.getRecurringPostAttempt({ index: 1, status: StatusTypeEnum.All }));
        yield put(Actions.getSchedulePostAttempt({ index: 1, status: StatusTypeEnum.All }));
        yield put(Actions.getHistoryPostAttempt({ index: 1, status: BroadcastHistoryFilter.All }));

        toast.success('Recall successful.');
    }
}
