import React from 'react';
import styled, { css } from 'styled-components';
import { HashLink as Link } from 'react-router-hash-link';

import Colors from 'assets/themes/Colors';
import Fonts from 'assets/themes/Fonts';

interface IQOSPaginatorProps {
    maxIndex: number;
    setIndex: (param:number) => void;
    currentIndex:number;
    url:string;
    containerId: string;
}

const IQOSPaginator:React.FunctionComponent<IQOSPaginatorProps> = ({
    maxIndex,
    setIndex,
    currentIndex,
    url,
    containerId,
}) => {
    const [minPage, setMinPage] = React.useState<number>(0);
    const [maxPage, setMaxPage] = React.useState<number>(9);

    const scrollToContainerId = `${url}#${containerId}`;

    const handleNextPageClick = () => {
        if (currentIndex < maxIndex) {
            setIndex(currentIndex + 1);
            if ((currentIndex >= ((maxPage + minPage) / 2)) && (currentIndex < (maxIndex - 4)) && maxPage !== maxIndex) {
                setMinPage(minPage + 1);
                setMaxPage(maxPage + 1);
            }
        }
    };

    const handlePreviousPageClick = () => {
        if (currentIndex > 1) {
            setIndex(currentIndex - 1);
            if ((currentIndex <= ((maxPage + minPage) / 2) + 1) && (currentIndex > 4) && minPage !== 0) {
                setMinPage(minPage - 1);
                setMaxPage(maxPage - 1);
            }
        }
    };

    const handleOnClickPageNumber = (value: number) => {
        if (maxIndex < 9) {
            setIndex(value);
            setMinPage(0);
            setMaxPage(maxIndex);
        } else if (((value + 4) >= maxIndex)) {
            setIndex(value);
            setMinPage(maxIndex - 9);
            setMaxPage(maxIndex);
        } else if ((value - 4) <= 1) {
            setIndex(value);
            setMinPage(0);
            setMaxPage(9);
        } else {
            setIndex(value);
            setMinPage(value - 5);
            setMaxPage(value + 4);
        }
    };

    const renderPages = () => {
        const pages = [];
        for (let i = 1; i <= maxIndex; i += 1) {
            pages.push(
                <StyledHashLink key={`${url}#${containerId}${i}`} smooth to={scrollToContainerId}>
                    <StyledPageLink isSelected={currentIndex === i} onClick={() => handleOnClickPageNumber(i)}>
                        {i}
                    </StyledPageLink>
                </StyledHashLink>,
            );
        }
        return pages.slice(minPage, maxPage);
    };

    return (
        <StyledPaginatorWrapper>
            <StyledPaginator style={{ minWidth: `${(maxIndex > 8) ? '400px' : '0px'}` }}>
                <Link smooth to={scrollToContainerId}>
                    <StyledButton onClick={() => handlePreviousPageClick()}>
                        Prev
                    </StyledButton>
                </Link>

                {renderPages()}

                <Link smooth to={scrollToContainerId}>
                    <StyledButton onClick={() => handleNextPageClick()}>
                        Next
                    </StyledButton>
                </Link>
            </StyledPaginator>
        </StyledPaginatorWrapper>

    );
};

const StyledPaginatorWrapper = styled.div`
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
`;

const StyledPaginator = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
`;

const StyledButton = styled.button`
    border: none;
    background-color: transparent;
    color: ${Colors.iqosPrimary};
    font-family: ${Fonts.secondary};
    font-size: 14px;
`;

const StyledHashLink = styled(Link)`
    text-decoration: none;
    margin-left: 5px;
    margin-right: 5px;
`;

const StyledPageLink = styled.button<{isSelected: boolean}>`
    font-size: 14px;
    font-family: ${Fonts.secondary};
    color: #A2A2A2;
    padding-left: 5px;
    padding-right: 5px;

    background-color:transparent;
    border:none;

    text-decoration: none;

    border: none;
    background: transparent;

    cursor: pointer;

    &:hover {
        color: black;
    }

    ${props => (props.isSelected === true) && css`
        color: black;
        border: 1px solid #A2A2A2;
        min-width: 20px;
        min-height: 10px;
        text-align: center;
        border-radius: 6px;
    `}
`;

export default IQOSPaginator;
