import React, { FunctionComponent, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import SVG from 'react-inlinesvg';
import dayjs from 'dayjs';
import Skeleton from 'react-loading-skeleton';
import { HashLink as Link } from 'react-router-hash-link';

import { AppDispatch, RootState } from 'redux/store';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { connect } from 'react-redux';

import Fonts from 'assets/themes/Fonts';
import Icons from 'assets/icons/Index';

import { IBroadcastMediaUploadStatus, RecurringPost, StatusTypeEnum } from 'entities/broadcast';
import Text from 'components/Text';
import IQOSPaginator from 'components/IQOSPaginator';

interface getRecurringPostProps {
    getStatus: StatusTypeEnum;
    isFailure: string;
    isLoading: boolean;

    recurringPostData: RecurringPost;
    recurringIndex: number;

    setRecurringIndex(index: number): void;
    setIsRecurring(data: boolean): void;
    setBroadcastId(data: string): void;

    getRecurringPost(index: number, status: StatusTypeEnum): void;

    editRecurringPost:() => void;
}

const Table: FunctionComponent<getRecurringPostProps> = (props: getRecurringPostProps) => {
    const {
        getStatus,
        isLoading,
        isFailure,

        recurringPostData,
        recurringIndex,

        setRecurringIndex,
        setIsRecurring,
        setBroadcastId,
        getRecurringPost,

        editRecurringPost,
    } = props;

    const recurringPostHeader = ['Broadcast Tracking Name', 'Media Upload Status', 'Date Created', 'Next Live date', 'Status', 'Action'];

    const [minPage, setMinPage] = useState<number>(0);
    const [maxPage, setMaxPage] = useState<number>(10);

    useEffect(() => {
        getRecurringPost(recurringIndex, getStatus);
    }, [recurringIndex]);

    useEffect(() => {
        setRecurringIndex(1);
        getRecurringPost(recurringIndex, getStatus);
    }, [getStatus]);

    useEffect(() => {
        let checkInProgress = true;
        const interval = setInterval(() => {
            if (recurringPostData && checkInProgress) {
                checkInProgress = recurringPostData.data.some((item) => item.mediaUploadStatus === IBroadcastMediaUploadStatus.InProgress);
                if (checkInProgress) {
                    getRecurringPost(recurringIndex, getStatus);
                }
            }
        }, 10000);

        return () => {
            clearInterval(interval);
        };
    }, [recurringPostData, getStatus, recurringIndex]);

    const renderMediaUploadStatus = (status: IBroadcastMediaUploadStatus) => {
        if (status === IBroadcastMediaUploadStatus.Success) {
            return 'Success';
        }
        if (status === IBroadcastMediaUploadStatus.Failed) {
            return 'Failed';
        }
        if (status === IBroadcastMediaUploadStatus.InProgress) {
            return 'In Progress';
        }
        return '';
    };

    const renderTableBody = () => {
        if (isLoading) {
            return (
                <tr>
                    <td colSpan={recurringPostHeader.length}>
                        <div>
                            <Skeleton count={5} height={50} />
                        </div>
                    </td>
                </tr>
            );
        }

        if (isFailure) {
            return (
                <tr>
                    <td colSpan={recurringPostHeader.length} style={{ padding: '20px', fontSize: '18px' }}>
                        <div>
                            <Text style={{ color: 'red' }}>Something went wrong. Please try again.</Text>
                        </div>
                    </td>
                </tr>
            );
        }

        if (!recurringPostData.data.length) {
            return (
                <tr>
                    <td colSpan={recurringPostHeader.length} style={{ padding: '20px', fontSize: '18px' }}>
                        <div>
                            <Text>There appears to be no data yet. </Text>
                        </div>
                    </td>
                </tr>
            );
        }

        return (
            recurringPostData && (
                recurringPostData.data.map((item) => {
                    const { id, title, createdAt, status, interval, mediaUploadStatus } = item;

                    return (
                        <StyledTr key={id}>
                            <StyledTd style={{ textAlign: 'left', minWidth: '400px', maxWidth: '400px' }}>
                                {title}
                            </StyledTd>

                            <StyledTd>
                                {renderMediaUploadStatus(mediaUploadStatus)}
                            </StyledTd>

                            <StyledTd>
                                {dayjs(createdAt).format('DD-MM-YY')}
                            </StyledTd>

                            <StyledTd>
                                {dayjs(interval.startDate).format('DD-MM-YY')}
                            </StyledTd>

                            <StyledTd>
                                {((status === StatusTypeEnum.Active) ? 'Active' : 'Inactive') || ''}
                            </StyledTd>

                            <StyledTd>
                                <StyledEdit
                                    type='button'
                                    onClick={() => [editRecurringPost(), setIsRecurring(true), setBroadcastId(item.id)]}
                                    disabled={mediaUploadStatus === IBroadcastMediaUploadStatus.InProgress}
                                >
                                    <SVG
                                        style={{
                                            width: '13px',
                                            fill: '#00FFDC',
                                            strokeWidth: '0.1px',
                                            transform: 'scale(1.8)',
                                        }}
                                        src={Icons.Edit}
                                        id='editIcon'
                                    />
                                </StyledEdit>
                            </StyledTd>
                        </StyledTr>
                    );
                })
            ));
    };

    return (
        <>
            <StyledTable>
                <StyledTHead>
                    <tr>
                        {recurringPostHeader.map((item) => (
                            <StyledTh key={item}>
                                {item}
                            </StyledTh>
                        ))}
                    </tr>
                </StyledTHead>
                <StyledTableBody>
                    {renderTableBody()}
                </StyledTableBody>
            </StyledTable>
            {(recurringPostData.data.length) ? (
                <IQOSPaginator
                    maxIndex={recurringPostData.maxIndex}
                    setIndex={setRecurringIndex}
                    currentIndex={recurringIndex}
                    url='/broadcast'
                    containerId='#recurringPostAnchor'
                />
            ) : (<> </>)}
        </>
    );
};

const StyledTable = styled.table`
    min-width: 100%;
    background-color: white;
    border-collapes: collapse;
    table-layout: fixed;
    
    margin-top: 20px;
    border-radius: 10px !important;
`;

const StyledTHead = styled.thead`
    text-align: center;
    border-bottom: 1px solid #C5C5C5;
`;

const StyledTableBody = styled.tbody`
    text-align: center;
`;

const StyledTh = styled.th`
    padding: 20px;
    border-bottom: 1px solid rgb(0,0,0,0,3);
    font-size: 15px;
    color: #959595;
    font-weight: lighter;

    &:first-of-type {
        text-align: left;
    }
`;

const StyledTr = styled.tr`
    &:not(:last-of-type){
        border-bottom: 5px solid #ffffff;
    }

    &:nth-last-of-type(1){
        border-radius: 0px 0px 10px 10px !important;
    }

    &:nth-of-type(odd){
        background-color: #F6FCFC;
    }
`;

const StyledTd = styled.td`
    padding: 20px;
    font-size: 16px;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const StyledEdit = styled.button`
    background-color: transparent;
    color: inherit;
    border: none;
    transition: transform 0.2s;
    position: relative;
    z-index: 0;

    &:hover{
        transform: scale(1.1);
    }
`;

const mapStateToProps = (state: RootState) => ({
    isLanu: Selectors.getFaqGetIsLANU(state),
    isFailure: Selectors.getBroadcastRecurringPostError(state),
    isLoading: Selectors.getBroadcastRecurringPostAttempting(state),

    recurringPostData: Selectors.getBroadcastRecurringPost(state),
    recurringIndex: Selectors.getBroadcastRecurringPaginatorIndex(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getRecurringPost: (index: number, status: StatusTypeEnum) => dispatch(Actions.getRecurringPostAttempt({ index, status })),
    setRecurringIndex: (index: number) => dispatch(Actions.setRecurringBroadcastIndex(index)),
    setIsRecurring: (data: boolean) => dispatch(Actions.setIsRecurring(data)),
    setBroadcastId: (data: string) => dispatch(Actions.setBroadcastId(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Table);
