import React from 'react';
import styled, { FlattenSimpleInterpolation, css } from 'styled-components';
import { connect } from 'react-redux';
import { Col, Row, Spinner } from 'reactstrap';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import AnalyticsHeader from 'components/analytics/AnalyticsHeader';
import Flex from 'components/Flex';

import { ICopyWriting } from 'entities/faq';
import Colors from 'assets/themes/Colors';
import Skeleton from 'react-loading-skeleton';

interface EditCopyWritingProps {
    test?: boolean;
    isLanu: boolean;
    loading: boolean;
    copyWritingData: ICopyWriting;
    getCopyWriting: () => void;
    setCopyWriting: (param:ICopyWriting) => void;
}

const EditCopyWriting:React.FunctionComponent<EditCopyWritingProps> = ({
    test,
    isLanu,
    loading,
    copyWritingData,
    getCopyWriting,
    setCopyWriting,
}:EditCopyWritingProps) => {
    const initialState = {
        mainMenu: copyWritingData.mainMenu,
        backToTelegramText: copyWritingData.backToTelegramText,
        backToTelegramButton: copyWritingData.backToTelegramButton,
        backToTelegramNotInChannel: copyWritingData.backToTelegramNotInChannel,
    };

    const [newData, setNewData] = React.useState<ICopyWriting>(initialState);
    const [dataHasChanged, setDataHasChanged] = React.useState<boolean>(false);
    const [formError, setFormError] = React.useState<string>('');

    React.useEffect(() => {
        getCopyWriting();
    }, [isLanu]);

    React.useEffect(() => {
        if (copyWritingData) { setNewData(initialState); }
    }, [copyWritingData]);

    const handleInputChange = (event:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setNewData({
            ...newData,
            [name]: value,
        });
        setDataHasChanged(true);
    };

    const onCancelEditCopyWriting = () => {
        setFormError('');
        setDataHasChanged(false);
        setNewData(initialState);
    };

    const onSaveEditCopyWriting = () => {
        const { mainMenu, backToTelegramButton, backToTelegramNotInChannel, backToTelegramText } = newData;
        if (mainMenu.trim().length
            && backToTelegramButton.trim().length
            && backToTelegramText.trim().length
            && backToTelegramNotInChannel.trim().length
        ) {
            setCopyWriting(newData);
            if (!loading) {
                setFormError('');
            }
        } else {
            setFormError('Please fill in the blanks.');
        }
        setDataHasChanged(false);
    };

    const renderEditCopyWritingForm = () => {
        if (loading) {
            return (
                <div style={{ marginTop: '1rem' }}>
                    <Skeleton height={50} count={3} />
                </div>

            );
        }

        return (
            <>
                <InputWrapper xs='2'>
                    <StyledCol>
                        <AnalyticsHeader size='sm' fontColor='#A2A2A2' header='Main Menu Text' />
                        <StyledTextArea
                            value={newData.mainMenu}
                            placeholder='Enter your main menu text'
                            name='mainMenu'
                            onChange={handleInputChange}
                        />
                    </StyledCol>
                    <StyledCol>
                        <AnalyticsHeader size='sm' fontColor='#A2A2A2' header='Back To Telegram Text' />
                        <StyledTextArea
                            value={newData.backToTelegramText}
                            placeholder='Enter your back to telegeram text'
                            name='backToTelegramText'
                            onChange={handleInputChange}
                        />
                    </StyledCol>
                    <StyledCol>
                        <AnalyticsHeader size='sm' fontColor='#A2A2A2' header='Back To Telegeram Button' />
                        <StyledTextArea
                            value={newData.backToTelegramButton}
                            placeholder='Enter your back to telegeram button text'
                            name='backToTelegramButton'
                            onChange={handleInputChange}
                        />
                    </StyledCol>
                    <StyledCol>
                        <AnalyticsHeader size='sm' fontColor='#A2A2A2' header='Back To Telegram Not In Channel' />
                        <StyledTextArea
                            value={newData.backToTelegramNotInChannel}
                            name='backToTelegramNotInChannel'
                            placeholder='Enter your back to telegeram not in channel text'
                            onChange={handleInputChange}
                        />
                    </StyledCol>
                </InputWrapper>

                {formError && (
                    <Flex margin='1rem 0'>
                        <span style={{ color: Colors.red }}>{formError}</span>
                    </Flex>
                )}
            </>

        );
    };

    return (
        <div style={{ width: '100%', backgroundColor: test ? 'black' : '' }}>
            <Flex justifyContent='space-between' width='100%'>
                <AnalyticsHeader size='md' header='Edit Copy Writing' />
                {dataHasChanged && (
                    <Flex gap='.5rem'>
                        <StyledButton type='button' onClick={onSaveEditCopyWriting} disabled={loading} css={buttonStyles.primary}>
                            {loading ? <Spinner size='sm' /> : 'Save Copy Writing'}
                        </StyledButton>
                        <StyledButton type='button' onClick={onCancelEditCopyWriting} css={buttonStyles.cancel}>Cancel</StyledButton>
                    </Flex>
                )}
            </Flex>
            {renderEditCopyWritingForm()}
        </div>
    );
};

interface CSSProps {
    css?: FlattenSimpleInterpolation;
}

const StyledButton = styled.button<CSSProps>`
    padding: 0.5rem 1.9rem;
    border-radius: 25px;
    ${props => props.css}; 
    `;

const buttonStyles = {
    primary: css`
        border-color: transparent;
        color: ${Colors.white};
        background-color: ${Colors.iqosPrimary};
    `,
    cancel: css`
    border-color:black;
        color: ${Colors.black};
        background-color: transparent;
    `,

};

const StyledTextArea = styled.textarea`
    width:100%;
    margin-top:0.5rem;
    padding:1rem;
    border-radius:5px;
    background-color: ${Colors.lightCream};
    border:none;
    resize: none;
`;

const StyledCol = styled(Col)`
    padding-bottom:1rem;
`;

const InputWrapper = styled(Row)`
    width:auto;
    margin-top:1rem;
`;

const mapStateToProps = (state: RootState) => ({
    isLanu: Selectors.getFaqGetIsLANU(state),
    loading: Selectors.getFaqSetCopyWritingAttempting(state),
    copyWritingData: Selectors.getFaqCopyWriting(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getCopyWriting: () => dispatch(Actions.getCopyWritingAttempt()),
    setCopyWriting: (copyWriting: ICopyWriting) => dispatch(Actions.setCopyWritingAttempt(copyWriting)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCopyWriting);
