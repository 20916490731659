import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { toast } from 'react-toastify';

import { GetSummaryDashboardDetailsParams } from 'redux/slices/summaryDashboard/types';
import SummaryDashboardGateway from 'api/SummaryDashboard';

export default function* watchGetSummaryDashboardDetailsStatus(api: SummaryDashboardGateway): SagaWatcherReturnType {
    yield takeEvery('summaryDashboard/getSummaryDashboardDetailsAttempt', handleGetSummaryDashboardDetails, api);
}

function* handleGetSummaryDashboardDetails(api: SummaryDashboardGateway, data: GetSummaryDashboardDetailsParams) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const { type, dateFrom, dateTo, isYtd } = data.payload;

    const response = yield* call([api, api.getSummaryDashboardDetails], {
        authToken,
        type,
        dateFrom,
        dateTo,
        isYtd,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.getSummaryDashboardDetailsFailure(response.message || ''));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.getSummaryDashboardDetailsSuccess(response.data));
    }
}
