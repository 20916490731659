import auth from 'redux/slices/auth/Selectors';
import faq from 'redux/slices/faq/Selectors';
import analytics from 'redux/slices/analytics/selectors';
import contests from 'redux/slices/contests/selectors';
import games from 'redux/slices/games/selectors';
import broadcast from 'redux/slices/broadcast/selectors';
import followers from 'redux/slices/followers/selectors';
import posts from 'redux/slices/posts/selectors';
import summaryDashboard from 'redux/slices/summaryDashboard/selectors';
import pncMessage from 'redux/slices/pncMessage/selectors';
import eCommerce from 'redux/slices/eCommerce/selectors';
import eCommerceAnalytics from 'redux/slices/eCommerceAnalytics/selectors';

import { AnalyticsTypeEnum, IAnalyticsSelector, IOverview } from 'entities/analytics';
import { BroadcastHistoryPost, BroadcastTypesEnum, RecurringPost, SchedulePost, RecipientListEnum, BroadcastOverview, HistoryDetails, HistoryDetailsExport, BroadcastHistoryPostExport } from 'entities/broadcast';
import { IContest, IContestDetails, IContestList, IContestResponse, ICreateQuizModalState, IDeleteQuizModalState, IEditQuizModalState } from 'entities/contests';
import { IAgeCopyWriting, ICopyWriting, IEditFaqCardModalState, IFaq, ISearchQuery } from 'entities/faq';
import { IFollowersChartsData, IFollowersTableInfo, IFollowersUsersList, IFullFollowersUsersList } from 'entities/followers';
import { IGame, IGameDetails, IGameResponse } from 'entities/games';
import { EditPostsReport, EditToggleColumns, GraphExportInfo, InformationPostModalInfo, PerformingPosts, PostsOverview, PostsReport, PostsReportExport, PostsReportWithAmountOfDays, RateByCategories, RateByPost, RateByPostingTime, UserClaims } from 'entities/posts';

import { SubscribersOverview, SummaryDashboardDetails } from 'entities/summaryDashboard';
import { IPNCMessageDetails } from 'entities/PNCMessage';
import { ProductCategoryDetails, ProductListDetails, PromotionalMessageDetails, CTAButtonDetails, WelcomeMessageDetails } from 'entities/ecommerce';
import { ECommActivitiesReportData, ECommBuyNowClicksProductReportData, ECommBuyNowClicksReportData, ECommBuyNowClicksVariantReportData, ECommClicksProductReportData, ECommClicksReportData, ECommClicksVariantReportData, ECommFunnelReportData } from 'entities/ecommerceAnalytics';
import { RootState } from './store';

const authGetLoginAttempting = (state: RootState): boolean => auth.getLoginAttempting(state.auth);
const authGetLoginError = (state: RootState): string => auth.getLoginError(state.auth);

const getAuthAuthToken = (state: RootState): string => auth.getAuthToken(state.auth);
const getAuthStartupAttempting = (state: RootState): boolean => auth.getStartupAttempting(state.auth);
const getAuthStartupError = (state: RootState): string => auth.getStartupError(state.auth);

const getAuthGetDetectOS = (state: RootState): string => auth.getDetectOS(state.auth);

const contestGetActiveGamesList = (state: RootState): IContestList => contests.getActiveGamesList(state.contests);
const contestGetActiveGamesListPage = (state: RootState): number => contests.getActiveGamesListPage(state.contests);
const contestGetActiveGamesListIndex = (state: RootState): number => contests.getActiveGamesListIndex(state.contests);
const contestGetActiveGamesListMaxIndex = (state: RootState): number => contests.getActiveGamesListMaxIndex(state.contests);
const contestGetActiveGamesListError = (state: RootState): string => contests.getActiveGamesListError(state.contests);
const contestGetActiveGamesListAttempting = (state: RootState): boolean => contests.getActiveGamesListAttempting(state.contests);

const contestGetInactiveGamesList = (state: RootState): IContestList => contests.getInactiveGamesList(state.contests);
const contestGetInactiveGamesListPage = (state: RootState): number => contests.getInactiveGamesListPage(state.contests);
const contestGetInactiveGamesListIndex = (state: RootState): number => contests.getInactiveGamesListIndex(state.contests);
const contestGetInactiveGamesListMaxIndex = (state: RootState): number => contests.getInactiveGamesListMaxIndex(state.contests);
const contestGetInactiveGamesListError = (state: RootState): string => contests.getInactiveGamesListError(state.contests);
const contestGetInactiveGamesListAttempting = (state: RootState): boolean => contests.getInactiveGamesListAttempting(state.contests);

const contestGetActiveQuizList = (state: RootState): IContestList => contests.getActiveQuizList(state.contests);
const contestGetActiveQuizListPage = (state: RootState): number => contests.getActiveQuizListPage(state.contests);
const contestGetActiveQuizListIndex = (state: RootState): number => contests.getActiveQuizListIndex(state.contests);
const contestGetActiveQuizListMaxIndex = (state: RootState): number => contests.getActiveQuizListMaxIndex(state.contests);
const contestGetActiveQuizListError = (state: RootState): string => contests.getActiveQuizListError(state.contests);
const contestGetActiveQuizListAttempting = (state: RootState): boolean => contests.getActiveQuizListAttempting(state.contests);

const contestGetInactiveQuizList = (state: RootState): IContestList => contests.getInactiveQuizList(state.contests);
const contestGetInactiveQuizListPage = (state: RootState): number => contests.getInactiveQuizListPage(state.contests);
const contestGetInactiveQuizListIndex = (state: RootState): number => contests.getInactiveQuizListIndex(state.contests);
const contestGetInactiveQuizListMaxIndex = (state: RootState): number => contests.getInactiveQuizListMaxIndex(state.contests);
const contestGetInactiveQuizListError = (state: RootState): string => contests.getInactiveQuizListError(state.contests);
const contestGetInactiveQuizListAttempting = (state: RootState): boolean => contests.getInactiveQuizListAttempting(state.contests);

const contestGetCreateContestModalState = (state: RootState): ICreateQuizModalState => contests.getCreateContestModalState(state.contests);
const contestGetEditContestModalState = (state: RootState): IEditQuizModalState => contests.getEditContestModalState(state.contests);
const contestGetDeleteContestModalState = (state: RootState): IDeleteQuizModalState => contests.getDeleteContestModalState(state.contests);

const faqGetEditFaqCardModalState = (state:RootState): IEditFaqCardModalState => faq.getEditFaqCardModalState(state.faq);

const followersGetFollowersTablesInfo = (state: RootState): IFollowersTableInfo => followers.getFollowersTablesInfo(state.followers);
const followersGetFollowersTablesInfoError = (state: RootState): string => followers.getFollowersTablesInfoError(state.followers);
const followersGetFollowersTablesInfoAttempting = (state: RootState): boolean => followers.getFollowersTablesInfoAttempting(state.followers);

const followersGetFollowersUsersList = (state: RootState): IFollowersUsersList[] => followers.getFollowersUsersList(state.followers);
const followersGetFollowersUsersListError = (state: RootState): string => followers.getFollowersUsersListError(state.followers);
const followersGetFollowersUsersListAttempting = (state: RootState): boolean => followers.getFollowersUsersListAttempting(state.followers);
const followersGetFollowersUsersListCurrentPage = (state: RootState): number => followers.getFollowersUsersListCurrentPage(state.followers);
const followersGetFollowersUsersListCurrentIndex = (state: RootState): number => followers.getFollowersUsersListCurrentIndex(state.followers);
const followersGetFollowersUsersListMaxPage = (state: RootState): number => followers.getFollowersUsersListMaxPage(state.followers);

const followersGetFullFollowersUsersList = (state: RootState): IFullFollowersUsersList[] => followers.getFullFollowersUsersList(state.followers);
const followersGetFullFollowersUsersListError = (state: RootState): string => followers.getFullFollowersUsersListError(state.followers);
const followersGetFullFollowersUsersListAttempting = (state: RootState): boolean => followers.getFullFollowersUsersListAttempting(state.followers);
const followersGetFullFollowersUsersListCurrentPage = (state: RootState): number => followers.getFullFollowersUsersListCurrentPage(state.followers);
const followersGetFullFollowersUsersListCurrentIndex = (state: RootState): number => followers.getFullFollowersUsersListCurrentIndex(state.followers);
const followersGetFullFollowersUsersListMaxPage = (state: RootState): number => followers.getFullFollowersUsersListMaxPage(state.followers);

const followersGetFollowersChartsData = (state: RootState): IFollowersChartsData => followers.getFollowersChartsData(state.followers);
const followersGetFollowersChartsDataAttempting = (state: RootState): boolean => followers.getFollowersChartsDataAttempting(state.followers);
const followersGetFollowersChartsDataError = (state: RootState): string => followers.getFollowersChartsDataError(state.followers);

const followersExportFullFollowersListAttempting = (state: RootState):boolean => followers.exportFullFollowersListAttempting(state.followers);
const followersExportFullFollowersListError = (state: RootState):string => followers.exportFullFollowersListError(state.followers);

const getFaqFaqsAttempting = (state: RootState): boolean => faq.getFaqAttempting(state.faq);
const getFaqFaqsError = (state: RootState): string => faq.getFaqError(state.faq);
const getFaqFaqs = (state: RootState): IFaq[] => faq.getFaq(state.faq);

const getFaqWelcomeMsg = (state: RootState): IFaq | null => faq.getWelcomeMsg(state.faq);
const getFaqSetWelcomeMsgAttempting = (state: RootState): boolean => faq.getSetWelcomeMsgAttempting(state.faq);

const getFaqFaqsEditing = (state: RootState): boolean => faq.getFaqEditing(state.faq);

const getFaqSearchQuery = (state: RootState): string => faq.getSearchQuery(state.faq);

const getFaqSearchChipText = (state: RootState): string => faq.getSearchChipText(state.faq);

const getFaqSearchAutocomplete = (state: RootState): ISearchQuery[] => faq.getSearchAutocomplete(state.faq);
const getFaqSearchAutocompleteAttempt = (state: RootState): boolean => faq.getSearchAutocompleteAttempt(state.faq);
const getFaqSearchAutocompleteError = (state: RootState): string => faq.getSearchAutocompleteError(state.faq);
const getFaqNoSearchResults = (state: RootState): boolean => faq.getNoSearchResults(state.faq);

const getFaqSearchedFaqId = (state: RootState): string => faq.getSearchedFaqId(state.faq);

const getFaqCopyWriting = (state: RootState): ICopyWriting => faq.getCopyWriting(state.faq);
const getFaqSetCopyWritingAttempting = (state: RootState): boolean => faq.getSetCopyWritingAttempting(state.faq);

const getFaqGetAgeCopyWritingAttempting = (state: RootState): boolean => faq.getAgeCopyWritingAttempting(state.faq);
const getFaqGetAgeCopyWritingError = (state: RootState): string => faq.getAgeCopyWritingError(state.faq);
const getFaqGetAgeCopyWriting = (state: RootState): IAgeCopyWriting | null => faq.getAgeCopyWriting(state.faq);

const getFaqGetIsLANU = (state: RootState): boolean => faq.getIsLANU(state.faq);

const getAnalyticsGetSelectedAnalyticsType = (state: RootState): AnalyticsTypeEnum => analytics.getSelectedAnalyticsType(state.analytics);
const getAnalyticsGetAnalyticsDataAttempting = (state: RootState): boolean => analytics.getAnalyticsAttempting(state.analytics);
const getAnalyticsGetAnalyticsDataError = (state: RootState): string => analytics.getAnalyticsError(state.analytics);

const getAnalyticsGetAnalyticsSelector = (state: RootState): IAnalyticsSelector => analytics.getAnalyticsSelector(state.analytics);

const getAnalyticsGetAnalyticsToDownloadAttempting = (state: RootState): boolean => analytics.getAnalyticsToDownloadAttempting(state.analytics);
const getAnalyticsGetAnalyticsToDownloadError = (state: RootState): string => analytics.getAnalyticsToDownloadError(state.analytics);

const getAnalyticsIsMasterExportModalOpen = (state: RootState): boolean => analytics.isMasterExportModalOpen(state.analytics);

const getAnalyticsMasterExportAttempting = (state: RootState): boolean => analytics.masterExportAttempting(state.analytics);
const getAnalyticsMasterExportError = (state: RootState): string => analytics.masterExportError(state.analytics);

const getAnalyticsGetOverviewAttempting = (state: RootState): boolean => analytics.getOverviewAttempting(state.analytics);
const getAnalyticsGetOverviewError = (state: RootState): string => analytics.getOverviewError(state.analytics);
const getAnalyticsGetOverview = (state: RootState): IOverview => analytics.getOverview(state.analytics);

const getContestsAttempting = (state: RootState): boolean => contests.getContestsAttempting(state.contests);
const getContestsFailure = (state: RootState): string => contests.getContestsFailure(state.contests);
const getContests = (state: RootState): IContest[] => contests.getContests(state.contests);

const createContestAttempt = (state: RootState): boolean => contests.createContestAttempt(state.contests);
const createContestSuccess = (state: RootState): IContest[] => contests.createContestSuccess(state.contests);
const createContestFailure = (state: RootState): string => contests.createContestFailure(state.contests);

const deleteContestAttempt = (state: RootState): boolean => contests.deleteContestAttempt(state.contests);
const deleteContestSuccess = (state: RootState): IContest[] => contests.deleteContestSuccess(state.contests);
const deleteContestFailure = (state: RootState): string => contests.deleteContestFailure(state.contests);

const getContestEditContestAttempting = (state: RootState): boolean => contests.getEditContestAttempting(state.contests);
const getContestEditContestError = (state: RootState): string => contests.getEditContestError(state.contests);

const getContestDetailsAttempting = (state: RootState): boolean => contests.getContestDetailsAttempting(state.contests);
const getContestDetailsError = (state: RootState): string => contests.getContestDetailsError(state.contests);
const getContestDetails = (state: RootState): IContestDetails => contests.getContestDetails(state.contests);

const getContestDetailsResponsesIndex = (state: RootState): number => contests.getContestDetailsResponsesIndex(state.contests);
const getContestDetailsResponsesMaxIndex = (state: RootState): number => contests.getContestDetailsResponsesMaxIndex(state.contests);
const getContestDetailsResponsesAttempting = (state: RootState): boolean => contests.getContestDetailsResponsesAttempting(state.contests);
const getContestDetailsResponsesError = (state: RootState): string => contests.getContestDetailsResponsesError(state.contests);
const getContestDetailsResponses = (state: RootState): IContestResponse[] => contests.getContestDetailsResponses(state.contests);

const getContestDetailsResponsesPage = (state: RootState): number => contests.getContestDetailsResponsesPage(state.contests);

const getEditContestDetailsAttempting = (state: RootState): boolean => contests.getContestDetailsAttempting(state.contests);

const getContestDetailsPaginatorIndex = (state:RootState): number => contests.getContestDetailsPaginatorIndex(state.contests);

const getContestExportResponseAttempting = (state: RootState): boolean => contests.getContestExportResponsesAttempting(state.contests);

const getGamesGetGamesAttempting = (state: RootState): boolean => games.getGamesAttempting(state.games);
const getGamesGetGamesError = (state: RootState): string => games.getGamesFailure(state.games);
const getGamesGetGames = (state: RootState): IGame[] => games.getGames(state.games);

const getGamesCreateGameAttempting = (state: RootState): boolean => games.getCreateGameAttempting(state.games);
const getGamesCreateGameFailure = (state: RootState): string => games.getCreateGameFailure(state.games);

const getGamesDeleteGameAttempting = (state: RootState): boolean => games.getDeleteGameAttempting(state.games);
const getGamesDeleteGameFailure = (state: RootState): string => games.getDeleteGameFailure(state.games);

const getEditGameAttempting = (state: RootState): boolean => games.getEditGameAttempting(state.games);
const getEditGameFailure = (state: RootState): string => games.getEditGameFailure(state.games);

const getGameDetailsAttempting = (state: RootState): boolean => games.getGameDetailsAttempting(state.games);
const getGameDetailsFailure = (state: RootState): string => games.getGameDetailsFailure(state.games);
const getGameDetails = (state: RootState): IGameDetails => games.getGameDetails(state.games);

const getGameDetailsResponsesIndex = (state: RootState): number => games.getGameDetailsResponsesIndex(state.games);
const getGameDetailsResponsesMaxIndex = (state: RootState): number => games.getGameDetailsResponsesMaxIndex(state.games);
const getGameDetailsResponsesAttempting = (state: RootState): boolean => games.getGameDetailsResponsesAttempting(state.games);
const getGameDetailsResponsesFailure = (state: RootState): string => games.getGameDetailsResponsesFailure(state.games);
const getGameDetailsResponses = (state: RootState): IGameResponse[] => games.getGameDetailsResponses(state.games);

const getGameDetailsResponsesPage = (state: RootState): number => games.getGameDetailsResponsePage(state.games);
const getGameDetailsPaginatorIndex = (state: RootState): number => games.getGameDetailsPaginatorIndex(state.games);

const getGameExportResponsesAttempting = (state: RootState): boolean => games.getGameExportResponsesAttempting(state.games);

const getSummaryDashboardGetSummaryDashboardDetailsAttempting = (state: RootState): boolean => summaryDashboard.getSummaryDashboardDetailsAttempt(state.summaryDashboard);
const getSummaryDashboardGetSummaryDashboardDetailsFailure = (state: RootState): string => summaryDashboard.getSummaryDashboardDetailsFalure(state.summaryDashboard);
const getSummaryDashboardGetSummaryDashboardDetails = (state: RootState): SummaryDashboardDetails => summaryDashboard.getSummaryDashboardDetails(state.summaryDashboard);

const getSummaryDashboardGetSubscribersOverviewAttempting = (state: RootState): boolean => summaryDashboard.getSubscribersOverviewAttempt(state.summaryDashboard);
const getSummaryDashboardGetSubscribersOverviewFailure = (state: RootState): string => summaryDashboard.getSubscribersOverviewFailure(state.summaryDashboard);
const getSummaryDashboardGetSubscribersOverview = (state: RootState): SubscribersOverview => summaryDashboard.getSubscribersOverview(state.summaryDashboard);

const getBroadcastGetBroadcastOverviewAttempting = (state: RootState): boolean => broadcast.getBroadcastOverviewAttempt(state.broadcast);
const getBroadcastGetBroadcastOverviewFailure = (state: RootState): string => broadcast.getBroadcastOverviewFailure(state.broadcast);
const getBroadcastGetBroadcastOverview = (state: RootState): BroadcastOverview => broadcast.getBroadcastOverview(state.broadcast);

const getBroadcastRecurringPostAttempting = (state: RootState): boolean => broadcast.getRecurringPostAttempt(state.broadcast);
const getBroadcastRecurringPostError = (state: RootState): string => broadcast.getRecurringPostFailure(state.broadcast);
const getBroadcastRecurringPost = (state: RootState): RecurringPost => broadcast.getRecurringPost(state.broadcast);

const getBroadcastSchedulePostAttempting = (state: RootState): boolean => broadcast.getSchedulePostAttempt(state.broadcast);
const getBroadcastSchedulePostError = (state: RootState): string => broadcast.getSchedulePostFailure(state.broadcast);
const getBroadcastSchedulePost = (state: RootState): SchedulePost => broadcast.getSchedulePost(state.broadcast);

const getBroadcastHistoryPostAttempting = (state: RootState): boolean => broadcast.getHistoryPostAttempt(state.broadcast);
const getBroadcastHistoryPostError = (state: RootState): string => broadcast.getHistoryPostFailure(state.broadcast);
const getBroadcastHistoryPost = (state: RootState): BroadcastHistoryPost => broadcast.getHistoryPost(state.broadcast);

const getBroadcastHistoryPostExportAttempting = (state: RootState): boolean => broadcast.getHistoryPostExportAttempt(state.broadcast);
const getBroadcastHistoryPostExportError = (state: RootState): string => broadcast.getHistoryPostExportFailure(state.broadcast);
const getBroadcastHistoryPostExport = (state: RootState): BroadcastHistoryPostExport[] => broadcast.getHistoryPostExport(state.broadcast);

const getBroadcastHistoryDetailsAttempting = (state: RootState): boolean => broadcast.getHistoryDetailsAttempt(state.broadcast);
const getBroadcastHistoryDetailsFailure = (state: RootState): string => broadcast.getHistoryDetailsFailure(state.broadcast);
const getBroadcastHistoryDetails = (state: RootState): HistoryDetails => broadcast.getHistoryDetails(state.broadcast);

const getBroadcastHistoryDetailsExportAttempting = (state: RootState): boolean => broadcast.getHistoryDetailsExportAttempt(state.broadcast);
const getBroadcastHistoryDetailsExportFailure = (state: RootState): string => broadcast.getHistoryDetailsExportFailure(state.broadcast);
const getBroadcastHistoryDetailsExport = (state: RootState): HistoryDetailsExport[] => broadcast.getHistoryDetailsExport(state.broadcast);

const createBroadcastBroadcastPostAttempting = (state: RootState): boolean => broadcast.createBroadcastPostAttempt(state.broadcast);
const createBroadcastBroadcastPostError = (state: RootState): string => broadcast.createBroadcastPostFailure(state.broadcast);

const editBroadcastBroadcastPostAttempting = (state: RootState): boolean => broadcast.editBroadcastPostAttempt(state.broadcast);
const editBroadcastBroadcastPostFailure = (state: RootState): string => broadcast.editBroadcastPostFailure(state.broadcast);

const recallBroadcastMessageAttempting = (state: RootState): boolean => broadcast.recallBroadcastMessageAttempt(state.broadcast);
const recallBroadcastMessageFailure = (state: RootState): string => broadcast.recallBroadcastMessageFailure(state.broadcast);

const getBroadcastGetBroadcastType = (state: RootState): BroadcastTypesEnum => broadcast.getBroadcastType(state.broadcast);
const getBroadcastGetRecipientList = (state: RootState): RecipientListEnum => broadcast.getRecipientList(state.broadcast);
const getBroadcastGetIsRecurring = (state: RootState): boolean => broadcast.getIsRecurring(state.broadcast);

const getBroadcastCreateBroadcastModalIsOpen = (state: RootState): boolean => broadcast.getCreateBroadcastModalIsOpen(state.broadcast);
const getBroadcastEditRecurringBroadcastModalIsOpen = (state: RootState): boolean => broadcast.getEditRecurringBroadcastModalIsOpen(state.broadcast);
const getBroadcastEditScheduleBroadcastModalIsOpen = (state: RootState): boolean => broadcast.getEditScheduleBroadcastModalIsOpen(state.broadcast);
const getBroadcastDuplicateBroadcastModalIsOpen = (state: RootState): boolean => broadcast.getDuplicateBroadcastModalIsOpen(state.broadcast);
const getBroadcastRecallBroadcastMessageModalIsOpen = (state: RootState): boolean => broadcast.getRecallBroadcastMessageModalIsOpen(state.broadcast);
const getBroadcastBroadcastDetailsModalIsOpen = (state: RootState): boolean => broadcast.getBroadcastDetailsModalIsOpen(state.broadcast);

const getBroadcastRecurringPaginatorIndex = (state: RootState): number => broadcast.getRecurringPaginatorIndex(state.broadcast);
const getBroadcastSchedulePaginatorIndex = (state: RootState): number => broadcast.getSchedulePaginatorIndex(state.broadcast);
const getBroadcastHistoryPaginatorIndex = (state: RootState): number => broadcast.getHistoryPaginatorIndex(state.broadcast);

const getBroadcastRecurringPage = (state: RootState): number => broadcast.getRecurringPage(state.broadcast);
const getBroadcastSchedulePage = (state: RootState): number => broadcast.getSchedulePage(state.broadcast);
const getBroadcastHistoryPage = (state: RootState): number => broadcast.getHistoryPage(state.broadcast);

const getBroadcastMaxIndex = (state: RootState): number => broadcast.getMaxIndex(state.broadcast);

const getBroadcastBroadcastId = (state: RootState): string => broadcast.getBroadcastId(state.broadcast);
const getBroadcastExportBroadcastDetailsId = (state: RootState): string => broadcast.getExportBroadcastDetailsId(state.broadcast);

const getPostsGetPostsOverviewAttempting = (state: RootState): boolean => posts.getPostsOverviewAttempting(state.posts);
const getPostsGetPostsOverviewFailure = (state: RootState): string => posts.getPostsOverviewFailure(state.posts);
const getPostsGetPostsOverview = (state: RootState): PostsOverview => posts.getPostsOverview(state.posts);

const getPostsGetRateByCategoriesAttempting = (state: RootState): boolean => posts.getRateByCategoriesAttempting(state.posts);
const getPostsGetRateByCategoriesFailure = (state: RootState): string => posts.getRateByCategoriesFailure(state.posts);
const getPostsGetRateByCategories = (state: RootState): RateByCategories[] => posts.getRateByCategories(state.posts);

const getPostsGetRateByCategoriesModalInfoAttempting = (state: RootState): boolean => posts.getRateByCategoriesModalInfoAttempting(state.posts);
const getPostsGetRateByCategoriesModalInfoFailure = (state: RootState): string => posts.getRateByCategoriesModalInfoFailure(state.posts);
const getPostsGetRateByCategoriesModalInfo = (state: RootState): InformationPostModalInfo => posts.getRateByCategoriesModalInfo(state.posts);

const getPostsGetRateByCategoriesExportAttempting = (state: RootState): boolean => posts.getRateByCategoriesExportAttempting(state.posts);
const getPostsGetRateByCategoriesExportFailure = (state: RootState): string => posts.getRateByCategoriesExportFailure(state.posts);
const getPostsGetRateByCategoriesExport = (state: RootState): GraphExportInfo[] => posts.getRateByCategoriesExport(state.posts);

const getPostsGetRateByPostingTimeAttempting = (state: RootState): boolean => posts.getRateByPostingTimeAttempting(state.posts);
const getPostsGetRateByPostingTimeFailure = (state: RootState): string => posts.getRateByPostingTimeFailure(state.posts);
const getPostsGetRateByPostingTime = (state: RootState): RateByPostingTime[] => posts.getRateByPostingTime(state.posts);

const getPostsGetRateByPostingTimeModalInfoAttempting = (state: RootState): boolean => posts.getRateByPostingTimeModalInfoAttempting(state.posts);
const getPostsGetRateByPostingTimeModalInfoFailure = (state: RootState): string => posts.getRateByPostingTimeModalInfoFailure(state.posts);
const getPostsGetRateByPostingTimeModalInfo = (state: RootState): InformationPostModalInfo => posts.getRateByPostingTimeModalInfo(state.posts);

const getPostsGetRateByPostingTimeExportAttempting = (state: RootState): boolean => posts.getRateByPostingTimeExportAttempting(state.posts);
const getPostsGetRateByPostingTImeExportFailure = (state: RootState): string => posts.getRateByPostingTimeExportFailure(state.posts);
const getPostsGetRateByPostingTimeExport = (state: RootState): GraphExportInfo[] => posts.getRateByPostingTimeExport(state.posts);

const getPostsGetRateByPostAttempting = (state: RootState): boolean => posts.getRateByPostAttempting(state.posts);
const getPostsGetRateByPostFailure = (state: RootState): string => posts.getRateByPostFailure(state.posts);
const getPostsGetRateByPost = (state: RootState): RateByPost[] => posts.getRateByPost(state.posts);

const getPostsGetRateByPostModalInfoAttempting = (state: RootState): boolean => posts.getRateByPostModalInfoAttempting(state.posts);
const getPostsGetRateByPostModalInfoFailure = (state: RootState): string => posts.getRateByPostModalInfoFailure(state.posts);
const getPostsGetRateByPostModalInfo = (state: RootState): InformationPostModalInfo => posts.getRateByPostModalInfo(state.posts);

const getPostsGetRateByPostExportAttempting = (state: RootState): boolean => posts.getRateByPostExportAttempting(state.posts);
const getPostsGetRateByPostExportFailure = (state: RootState): string => posts.getRateByPostExportFailure(state.posts);
const getPostsGetRateByPostExport = (state: RootState): GraphExportInfo[] => posts.getRateByPostExport(state.posts);

const getPostsGetPostsReportAttempting = (state: RootState): boolean => posts.getPostsReportAttempting(state.posts);
const getPostsGetPostsReportFailure = (state: RootState): string => posts.getPostsReportFailure(state.posts);
const getPostsGetPostsReport = (state: RootState): PostsReport => posts.getPostsReport(state.posts);

const getPostsGetPostsReportExportAttempting = (state: RootState): boolean => posts.getPostsReportExportAttempting(state.posts);
const getPostsGetPostsReportExportFailure = (state: RootState): string => posts.getPostsReportExportFailure(state.posts);
const getPostsGetPostsReportExport = (state: RootState): PostsReportExport[] => posts.getPostsReportExport(state.posts);

const editPostsEditPostsReportAttempting = (state: RootState): boolean => posts.editPostsReportAttempting(state.posts);
const editPostsEditPostsReportFailure = (state: RootState): string => posts.editPostsReportFailure(state.posts);
const editPostsEditPostsReport = (state: RootState): EditPostsReport => posts.editPostsReport(state.posts);

const getPostsGetPostsReportWithAmountOfDaysAttempting = (state: RootState): boolean => posts.getPostsReportWithAmountOfDaysAttempting(state.posts);
const getPostsGetPostsReportWithAmountOfDaysFailure = (state: RootState): string => posts.getPostsReportWithAmountOfDaysFailure(state.posts);
const getPostsGetPostsReportWithAmountOfDays = (state: RootState): PostsReportWithAmountOfDays => posts.getPostsReportWithAmountOfDays(state.posts);

const getPostsGetPerformingPostsAttempting = (state: RootState): boolean => posts.getPerformingPostsAttempting(state.posts);
const getPostsGetPerformingPostsFailure = (state: RootState): string => posts.getPerformingPostsFailure(state.posts);
const getPostsGetPerformingPosts = (state: RootState): PerformingPosts => posts.getPerformingPosts(state.posts);

const getPostsGetUserClaimsAttempting = (state: RootState): boolean => posts.getUserClaimsAttempting(state.posts);
const getPostsGetUserClaimsFailure = (state: RootState): string => posts.getUserClaimsFailure(state.posts);
const getPostsGetUserClaims = (state: RootState): UserClaims => posts.getUserClaims(state.posts);

const editPostsEditToggleColumnsAttempting = (state: RootState): boolean => posts.editToggleColumnAttempting(state.posts);
const editPostsEditToggleColumnsFailure = (state: RootState): string => posts.editToggleColumnFailure(state.posts);
const editPostsEditToggleColumns = (state: RootState): EditToggleColumns => posts.editToggleColumn(state.posts);

const getPostsGetDateFrom = (state: RootState): Date => posts.getDateFrom(state.posts);
const getPostsGetDateTo = (state: RootState): Date => posts.getDateTo(state.posts);

const getPostsGetInformationPostModalIsOpen = (state: RootState): boolean => posts.getInformationPostModalIsOpen(state.posts);
const getPostsGetInformationPostIndex = (state: RootState): number => posts.getInformationPostIndex(state.posts);

const getPostsGetPostsReportIndex = (state: RootState): number => posts.getPostsReportIndex(state.posts);
const getPostsGetPostsReportWithAmountOfDaysIndex = (state: RootState): number => posts.getPostsReportWithAmountOfDaysIndex(state.posts);

const getPostsGetCurrentGraphCategory = (state: RootState): string | undefined => posts.getCurrentGraphCategory(state.posts);
const getPostsGetCurrentGraphWeek = (state: RootState): string | undefined => posts.getCurrentGraphWeek(state.posts);
const getPostsGetCurrentGraphHour = (state: RootState): string | undefined => posts.getCurrentGraphHour(state.posts);

const getPostsGetSelectedCategory = (state: RootState): number => posts.getSelectedCategory(state.posts);

const getPostsGetUserClaimsRedux = (state: RootState): UserClaims => posts.getUserClaimsRedux(state.posts);

const getPostsGetSummaryDashboardDetailsAttempting = (state: RootState): boolean => posts.getSummaryDashboardDetailsAttempt(state.posts);
const getPostsGetSummaryDashboardDetailsFailure = (state: RootState): string => posts.getSummaryDashboardDetailsFalure(state.posts);
const getPostsGetSummaryDashboardDetails = (state: RootState): SummaryDashboardDetails => posts.getSummaryDashboardDetails(state.posts);

const getPNCGetPNCMessageAttempting = (state: RootState): boolean => pncMessage.getPNCMessageAttempt(state.pncMessage);
const getPNCGetPNCMessageFailure = (state: RootState): string => pncMessage.getPNCMessageFailure(state.pncMessage);
const getPNCGetPNCMessage = (state: RootState): IPNCMessageDetails => pncMessage.getPNCMessageSuccess(state.pncMessage);

const getPNCEditPNCMessageAttempting = (state: RootState): boolean => pncMessage.editPNCMessageAttempt(state.pncMessage);
const getPNCEditPNCMessageFailure = (state: RootState): string => pncMessage.editPNCMessageFailure(state.pncMessage);

const getECommerceGetPromotionalMessageAttempting = (state: RootState): boolean => eCommerce.getPromotionalMessageAttempt(state.eCommerce);
const getECommerceGetPromotionalMessageFailure = (state: RootState): string => eCommerce.getPromotionalMessageFailure(state.eCommerce);
const getECommerceGetPromotionalMessage = (state: RootState): PromotionalMessageDetails[] => eCommerce.getPromotionalMessage(state.eCommerce);

const getECommerceEditPromotionalMessageAttempting = (state: RootState): boolean => eCommerce.editPromotionalMessageAttempt(state.eCommerce);
const getECommerceEditPromotionalMessageFailure = (state: RootState): string => eCommerce.editPromotionalMessageFailure(state.eCommerce);

const getECommerceGetWelcomeMessageAttempting = (state: RootState): boolean => eCommerce.getWelcomeMessageAttempt(state.eCommerce);
const getECommerceGetWelcomeMessageFailure = (state: RootState): string => eCommerce.getWelcomeMessageFailure(state.eCommerce);
const getECommerceGetWelcomeMessage = (state: RootState): WelcomeMessageDetails[] => eCommerce.getWelcomeMessage(state.eCommerce);

const getECommerceEditWelcomeMessageAttempting = (state: RootState): boolean => eCommerce.editWelcomeMessageAttempt(state.eCommerce);
const getECommerceEditWelcomeMessageFailure = (state: RootState): string => eCommerce.editWelcomeMessageFailure(state.eCommerce);

const getECommerceGetProductListingMessageAttempting = (state: RootState): boolean => eCommerce.getProductListingMessageAttempt(state.eCommerce);
const getECommerceGetProductListingMessageFailure = (state: RootState): string => eCommerce.getProductListingMessageFailure(state.eCommerce);
const getECommerceGetProductListingMessage = (state: RootState): WelcomeMessageDetails[] => eCommerce.getProductListingMessage(state.eCommerce);

const getECommerceEditProductListingMessageAttempting = (state: RootState): boolean => eCommerce.editProductListingMessageAttempt(state.eCommerce);
const getECommerceEditProductListingMessageFailure = (state: RootState): string => eCommerce.editProductListingMessageFailure(state.eCommerce);

const getECommerceGetProductCategoryAttempting = (state: RootState): boolean => eCommerce.getProductCategoryAttempt(state.eCommerce);
const getECommerceGetProductCategoryFailure = (state: RootState): string => eCommerce.getProductCategoryFailure(state.eCommerce);
const getECommerceGetProductCategory = (state: RootState): ProductCategoryDetails[] => eCommerce.getProductCategory(state.eCommerce);

const getECommerceCreateProductCategoryAttempting = (state: RootState): boolean => eCommerce.createProductCategoryAttempt(state.eCommerce);
const getECommerceCreateProductCategoryFailure = (state: RootState): string => eCommerce.createProductCategoryFailure(state.eCommerce);

const getECommerceEditProductCategoryAttempting = (state: RootState): boolean => eCommerce.editProductCategoryAttempt(state.eCommerce);
const getECommerceEditProductCategoryFailure = (state: RootState): string => eCommerce.editProductCategoryFailure(state.eCommerce);

const getECommerceDeleteProductCategoryAttempting = (state: RootState): boolean => eCommerce.deleteProductCategoryAttempt(state.eCommerce);
const getECommerceDeleteProductCategoryFailure = (state: RootState): string => eCommerce.deleteProductCategoryFailure(state.eCommerce);

const getECommerceGetProductListAttempting = (state: RootState): boolean => eCommerce.getProductListAttempt(state.eCommerce);
const getECommerceGetProductListFailure = (state: RootState): string => eCommerce.getProductListFailure(state.eCommerce);
const getECommerceGetProductList = (state: RootState): ProductListDetails[] => eCommerce.getProductList(state.eCommerce);

const getECommerceCreateProductListAttempting = (state: RootState): boolean => eCommerce.createProductListAttempt(state.eCommerce);
const getECommerceCreateProductListFailure = (state: RootState): string => eCommerce.createProductListFailure(state.eCommerce);

const getECommerceGetProductDetailsAttempting = (state: RootState): boolean => eCommerce.getProductDetailsAttempt(state.eCommerce);
const getECommerceGetProductDetailsFailure = (state: RootState): string => eCommerce.getProductDetailsFailure(state.eCommerce);
const getECommerceGetProductDetails = (state: RootState): ProductListDetails[] => eCommerce.getProductDetails(state.eCommerce);

const getECommerceEditProductDetailsAttempting = (state: RootState): boolean => eCommerce.editProductDetailsAttempt(state.eCommerce);
const getECommerceEditProductDetailsFailure = (state: RootState): string => eCommerce.editProductDetailsFailure(state.eCommerce);

const getECommerceDeleteProductDetailsAttempting = (state: RootState): boolean => eCommerce.deleteProductDetailsAttempt(state.eCommerce);
const getECommerceDeleteProductDetailsFailure = (state: RootState): string => eCommerce.deleteProductDetailsFailure(state.eCommerce);

const getECommerceCreateProductVariantAttempting = (state: RootState): boolean => eCommerce.createProductVariantAttempt(state.eCommerce);
const getECommerceCreateProductVariantFailure = (state: RootState): string => eCommerce.createProductVariantFailure(state.eCommerce);

const getECommerceEditProductVariantAttempting = (state: RootState): boolean => eCommerce.editProductVariantAttempt(state.eCommerce);
const getECommerceEditProductVariantFailure = (state: RootState): string => eCommerce.editProductVariantFailure(state.eCommerce);

const getECommerceDeleteProductVariantAttempting = (state: RootState): boolean => eCommerce.deleteProductVariantAttempt(state.eCommerce);
const getECommerceDeleteProductVariantFailure = (state: RootState): string => eCommerce.deleteProductVariantFailure(state.eCommerce);

const getECommerceGetProductCategoryId = (state: RootState): string => eCommerce.getProductCategoryId(state.eCommerce);
const getECommerceGetCreateCategoryModalIsOpen = (state: RootState): boolean => eCommerce.getCreateCategoryModalIsOpen(state.eCommerce);
const getECommerceGetCreateProductModalIsOpen = (state: RootState): boolean => eCommerce.getCreateProductModalIsOpen(state.eCommerce);
const getECommerceGetCreateVariantModalIsOpen = (state: RootState): boolean => eCommerce.getCreateVariantModalIsOpen(state.eCommerce);
const getECommerceGetEditVariantModalIsOpen = (state: RootState): boolean => eCommerce.getEditVariantModalIsOpen(state.eCommerce);

const getECommerceGetCTAButtonDetailsAttempting = (state: RootState): boolean => eCommerce.getCTAButtonDetailsAttempt(state.eCommerce);
const getECommerceGetCTAButtonDetailsFailure = (state: RootState): string => eCommerce.getCTAButtonDetailsFailure(state.eCommerce);
const getECommerceGetCTAButtonDetails = (state: RootState): CTAButtonDetails[] => eCommerce.getCTAButtonDetails(state.eCommerce);

const getECommerceEditCTAButtonDetailsAttempting = (state: RootState): boolean => eCommerce.editCTAButtonDetailsAttempt(state.eCommerce);
const getECommerceEditCTAButtonDetailsFailure = (state: RootState): string => eCommerce.editCTAButtonDetailsFailure(state.eCommerce);

const getECommerceAnalDatePicker = (state: RootState): { dateFrom: Date, dateTo: Date } => eCommerceAnalytics.getUiDatePicker(state.eCommerceAnalytics);
const getECommerceAnalClicksReportAttempting = (state: RootState): boolean => eCommerceAnalytics.getClicksReportAttempting(state.eCommerceAnalytics);
const getECommerceAnalClicksReportFailure = (state: RootState): string | null => eCommerceAnalytics.getClicksReportError(state.eCommerceAnalytics);
const getECommerceAnalClicksReportData = (state: RootState): ECommClicksReportData | null => eCommerceAnalytics.getClicksReportData(state.eCommerceAnalytics);

const getECommerceAnalClicksProductReportAttempting = (state: RootState): boolean => eCommerceAnalytics.getClicksProductReportAttempting(state.eCommerceAnalytics);
const getECommerceAnalClicksProductReportFailure = (state: RootState): string | null => eCommerceAnalytics.getClicksProductReportError(state.eCommerceAnalytics);
const getECommerceAnalClicksProductReportData = (state: RootState): ECommClicksProductReportData | null => eCommerceAnalytics.getClicksProductReportData(state.eCommerceAnalytics);

const getECommerceAnalClicksVariantReportAttempting = (state: RootState): boolean => eCommerceAnalytics.getClicksVariantReportAttempting(state.eCommerceAnalytics);
const getECommerceAnalClicksVariantReportFailure = (state: RootState): string | null => eCommerceAnalytics.getClicksVariantReportError(state.eCommerceAnalytics);
const getECommerceAnalClicksVariantReportData = (state: RootState): ECommClicksVariantReportData[] | null => eCommerceAnalytics.getClicksVariantReportData(state.eCommerceAnalytics);

const getECommerceAnalBuyNowClicksReportAttempting = (state: RootState): boolean => eCommerceAnalytics.getBuyNowClicksReportAttempting(state.eCommerceAnalytics);
const getECommerceAnalBuyNowClicksReportFailure = (state: RootState): string | null => eCommerceAnalytics.getBuyNowClicksReportError(state.eCommerceAnalytics);
const getECommerceAnalBuyNowClicksReportData = (state: RootState): ECommBuyNowClicksReportData | null => eCommerceAnalytics.getBuyNowClicksReportData(state.eCommerceAnalytics);

const getECommerceAnalBuyNowClicksProductReportAttempting = (state: RootState): boolean => eCommerceAnalytics.getBuyNowClicksProductReportAttempting(state.eCommerceAnalytics);
const getECommerceAnalBuyNowClicksProductReportFailure = (state: RootState): string | null => eCommerceAnalytics.getBuyNowClicksProductReportError(state.eCommerceAnalytics);
const getECommerceAnalBuyNowClicksProductReportData = (state: RootState): ECommBuyNowClicksProductReportData | null => eCommerceAnalytics.getBuyNowClicksProductReportData(state.eCommerceAnalytics);

const getECommerceAnalBuyNowClicksVariantReportAttempting = (state: RootState): boolean => eCommerceAnalytics.getBuyNowClicksVariantReportAttempting(state.eCommerceAnalytics);
const getECommerceAnalBuyNowClicksVariantReportFailure = (state: RootState): string | null => eCommerceAnalytics.getBuyNowClicksVariantReportError(state.eCommerceAnalytics);
const getECommerceAnalBuyNowClicksVariantReportData = (state: RootState): ECommBuyNowClicksVariantReportData[] | null => eCommerceAnalytics.getBuyNowClicksVariantReportData(state.eCommerceAnalytics);

const getECommerceAnalActivitiesReportAttempting = (state:RootState): boolean => eCommerceAnalytics.getActivitiesReportAttempting(state.eCommerceAnalytics);
const getECommerceAnalActivitiesReportFailure = (state: RootState): string | null => eCommerceAnalytics.getActivitiesReportError(state.eCommerceAnalytics);
const getECommerceAnalActivitiesReportData = (state: RootState): ECommActivitiesReportData | null => eCommerceAnalytics.getActivitiesReportData(state.eCommerceAnalytics);

const getECommerceAnalFunnelReportAttempting = (state:RootState): boolean => eCommerceAnalytics.getFunnelReportAttempting(state.eCommerceAnalytics);
const getECommerceAnalFunnelReportFailure = (state:RootState): string | null => eCommerceAnalytics.getFunnelReportError(state.eCommerceAnalytics);
const getECommerceAnalFunnelReportData = (state:RootState): ECommFunnelReportData | null => eCommerceAnalytics.getFunnelReportData(state.eCommerceAnalytics);

export default {
    authGetLoginAttempting,
    authGetLoginError,

    getAuthAuthToken,
    getAuthStartupAttempting,
    getAuthStartupError,

    getAuthGetDetectOS,

    contestGetActiveGamesList,
    contestGetActiveGamesListPage,
    contestGetActiveGamesListIndex,
    contestGetActiveGamesListMaxIndex,
    contestGetActiveGamesListError,
    contestGetActiveGamesListAttempting,
    contestGetInactiveGamesList,
    contestGetInactiveGamesListPage,
    contestGetInactiveGamesListIndex,
    contestGetInactiveGamesListMaxIndex,
    contestGetInactiveGamesListError,
    contestGetInactiveGamesListAttempting,
    contestGetActiveQuizList,
    contestGetActiveQuizListPage,
    contestGetActiveQuizListIndex,
    contestGetActiveQuizListMaxIndex,
    contestGetActiveQuizListError,
    contestGetActiveQuizListAttempting,
    contestGetInactiveQuizList,
    contestGetInactiveQuizListPage,
    contestGetInactiveQuizListIndex,
    contestGetInactiveQuizListMaxIndex,
    contestGetInactiveQuizListError,
    contestGetInactiveQuizListAttempting,
    contestGetCreateContestModalState,
    contestGetEditContestModalState,
    contestGetDeleteContestModalState,

    faqGetEditFaqCardModalState,

    followersGetFollowersTablesInfo,
    followersGetFollowersTablesInfoError,
    followersGetFollowersTablesInfoAttempting,
    followersGetFollowersUsersList,
    followersGetFollowersUsersListError,
    followersGetFollowersUsersListAttempting,
    followersGetFollowersUsersListCurrentPage,
    followersGetFollowersUsersListCurrentIndex,
    followersGetFollowersUsersListMaxPage,
    followersGetFullFollowersUsersList,
    followersGetFullFollowersUsersListError,
    followersGetFullFollowersUsersListAttempting,
    followersGetFullFollowersUsersListCurrentPage,
    followersGetFullFollowersUsersListCurrentIndex,
    followersGetFullFollowersUsersListMaxPage,
    followersGetFollowersChartsData,
    followersGetFollowersChartsDataAttempting,
    followersGetFollowersChartsDataError,
    followersExportFullFollowersListAttempting,
    followersExportFullFollowersListError,

    getFaqFaqsAttempting,
    getFaqFaqsError,
    getFaqFaqs,

    getFaqWelcomeMsg,

    getFaqSetWelcomeMsgAttempting,

    getFaqFaqsEditing,

    getFaqSearchQuery,
    getFaqSearchChipText,

    getFaqSearchAutocomplete,
    getFaqSearchAutocompleteAttempt,
    getFaqSearchAutocompleteError,
    getFaqNoSearchResults,

    getFaqSearchedFaqId,

    getFaqCopyWriting,
    getFaqSetCopyWritingAttempting,

    getFaqGetAgeCopyWritingAttempting,
    getFaqGetAgeCopyWritingError,
    getFaqGetAgeCopyWriting,

    getFaqGetIsLANU,

    getAnalyticsGetSelectedAnalyticsType,
    getAnalyticsGetAnalyticsDataAttempting,
    getAnalyticsGetAnalyticsDataError,
    getAnalyticsGetAnalyticsSelector,

    getAnalyticsGetAnalyticsToDownloadAttempting,
    getAnalyticsGetAnalyticsToDownloadError,

    getAnalyticsIsMasterExportModalOpen,

    getAnalyticsMasterExportAttempting,
    getAnalyticsMasterExportError,

    getAnalyticsGetOverviewAttempting,
    getAnalyticsGetOverviewError,
    getAnalyticsGetOverview,

    getContests,
    getContestsAttempting,
    getContestsFailure,

    createContestAttempt,
    createContestSuccess,
    createContestFailure,

    deleteContestAttempt,
    deleteContestSuccess,
    deleteContestFailure,

    getContestEditContestAttempting,
    getContestEditContestError,

    getContestDetailsAttempting,
    getContestDetailsError,
    getContestDetails,

    getContestDetailsResponsesIndex,
    getContestDetailsResponsesMaxIndex,
    getContestDetailsResponsesAttempting,
    getContestDetailsResponsesError,
    getContestDetailsResponses,

    getContestDetailsResponsesPage,

    getEditContestDetailsAttempting,

    getContestDetailsPaginatorIndex,

    getContestExportResponseAttempting,

    getGamesCreateGameAttempting,
    getGamesCreateGameFailure,

    getGamesGetGamesAttempting,
    getGamesGetGamesError,
    getGamesGetGames,

    getGamesDeleteGameAttempting,
    getGamesDeleteGameFailure,

    getEditGameAttempting,
    getEditGameFailure,

    getGameDetailsAttempting,
    getGameDetailsFailure,
    getGameDetails,

    getGameDetailsResponsesIndex,
    getGameDetailsResponsesMaxIndex,
    getGameDetailsResponsesAttempting,
    getGameDetailsResponsesFailure,
    getGameDetailsResponses,

    getGameDetailsResponsesPage,
    getGameDetailsPaginatorIndex,

    getGameExportResponsesAttempting,

    getSummaryDashboardGetSummaryDashboardDetailsAttempting,
    getSummaryDashboardGetSummaryDashboardDetailsFailure,
    getSummaryDashboardGetSummaryDashboardDetails,

    getSummaryDashboardGetSubscribersOverviewAttempting,
    getSummaryDashboardGetSubscribersOverviewFailure,
    getSummaryDashboardGetSubscribersOverview,

    getBroadcastGetBroadcastOverviewAttempting,
    getBroadcastGetBroadcastOverviewFailure,
    getBroadcastGetBroadcastOverview,

    getBroadcastRecurringPostAttempting,
    getBroadcastRecurringPostError,
    getBroadcastRecurringPost,

    getBroadcastSchedulePostAttempting,
    getBroadcastSchedulePostError,
    getBroadcastSchedulePost,

    getBroadcastHistoryPostAttempting,
    getBroadcastHistoryPostError,
    getBroadcastHistoryPost,

    getBroadcastHistoryDetailsAttempting,
    getBroadcastHistoryDetailsFailure,
    getBroadcastHistoryDetails,

    getBroadcastHistoryPostExportAttempting,
    getBroadcastHistoryPostExportError,
    getBroadcastHistoryPostExport,

    getBroadcastHistoryDetailsExportAttempting,
    getBroadcastHistoryDetailsExportFailure,
    getBroadcastHistoryDetailsExport,

    createBroadcastBroadcastPostAttempting,
    createBroadcastBroadcastPostError,

    editBroadcastBroadcastPostAttempting,
    editBroadcastBroadcastPostFailure,

    recallBroadcastMessageAttempting,
    recallBroadcastMessageFailure,

    getBroadcastGetBroadcastType,
    getBroadcastGetRecipientList,
    getBroadcastGetIsRecurring,

    getBroadcastCreateBroadcastModalIsOpen,
    getBroadcastEditRecurringBroadcastModalIsOpen,
    getBroadcastEditScheduleBroadcastModalIsOpen,
    getBroadcastDuplicateBroadcastModalIsOpen,
    getBroadcastRecallBroadcastMessageModalIsOpen,
    getBroadcastBroadcastDetailsModalIsOpen,

    getBroadcastRecurringPaginatorIndex,
    getBroadcastSchedulePaginatorIndex,
    getBroadcastHistoryPaginatorIndex,

    getBroadcastRecurringPage,
    getBroadcastSchedulePage,
    getBroadcastHistoryPage,

    getBroadcastMaxIndex,

    getBroadcastBroadcastId,
    getBroadcastExportBroadcastDetailsId,

    getPostsGetPostsOverviewAttempting,
    getPostsGetPostsOverviewFailure,
    getPostsGetPostsOverview,

    getPostsGetRateByCategoriesAttempting,
    getPostsGetRateByCategoriesFailure,
    getPostsGetRateByCategories,

    getPostsGetRateByCategoriesModalInfoAttempting,
    getPostsGetRateByCategoriesModalInfoFailure,
    getPostsGetRateByCategoriesModalInfo,

    getPostsGetRateByCategoriesExportAttempting,
    getPostsGetRateByCategoriesExportFailure,
    getPostsGetRateByCategoriesExport,

    getPostsGetRateByPostingTimeAttempting,
    getPostsGetRateByPostingTimeFailure,
    getPostsGetRateByPostingTime,

    getPostsGetRateByPostingTimeModalInfoAttempting,
    getPostsGetRateByPostingTimeModalInfoFailure,
    getPostsGetRateByPostingTimeModalInfo,

    getPostsGetRateByPostingTimeExportAttempting,
    getPostsGetRateByPostingTImeExportFailure,
    getPostsGetRateByPostingTimeExport,

    getPostsGetRateByPostAttempting,
    getPostsGetRateByPostFailure,
    getPostsGetRateByPost,

    getPostsGetRateByPostModalInfoAttempting,
    getPostsGetRateByPostModalInfoFailure,
    getPostsGetRateByPostModalInfo,

    getPostsGetRateByPostExportAttempting,
    getPostsGetRateByPostExportFailure,
    getPostsGetRateByPostExport,

    getPostsGetPostsReportAttempting,
    getPostsGetPostsReportFailure,
    getPostsGetPostsReport,

    getPostsGetPostsReportExportAttempting,
    getPostsGetPostsReportExportFailure,
    getPostsGetPostsReportExport,

    editPostsEditPostsReportAttempting,
    editPostsEditPostsReportFailure,
    editPostsEditPostsReport,

    getPostsGetPostsReportWithAmountOfDaysAttempting,
    getPostsGetPostsReportWithAmountOfDaysFailure,
    getPostsGetPostsReportWithAmountOfDays,

    getPostsGetPerformingPostsAttempting,
    getPostsGetPerformingPostsFailure,
    getPostsGetPerformingPosts,

    getPostsGetUserClaimsAttempting,
    getPostsGetUserClaimsFailure,
    getPostsGetUserClaims,

    editPostsEditToggleColumnsAttempting,
    editPostsEditToggleColumnsFailure,
    editPostsEditToggleColumns,

    getPostsGetDateFrom,
    getPostsGetDateTo,

    getPostsGetInformationPostModalIsOpen,
    getPostsGetInformationPostIndex,

    getPostsGetPostsReportIndex,
    getPostsGetPostsReportWithAmountOfDaysIndex,

    getPostsGetCurrentGraphCategory,
    getPostsGetCurrentGraphWeek,
    getPostsGetCurrentGraphHour,

    getPostsGetUserClaimsRedux,
    getPostsGetSelectedCategory,

    getPostsGetSummaryDashboardDetailsAttempting,
    getPostsGetSummaryDashboardDetailsFailure,
    getPostsGetSummaryDashboardDetails,

    getPNCGetPNCMessageAttempting,
    getPNCGetPNCMessageFailure,
    getPNCGetPNCMessage,

    getPNCEditPNCMessageAttempting,
    getPNCEditPNCMessageFailure,

    getECommerceGetPromotionalMessageAttempting,
    getECommerceGetPromotionalMessageFailure,
    getECommerceGetPromotionalMessage,

    getECommerceEditPromotionalMessageAttempting,
    getECommerceEditPromotionalMessageFailure,

    getECommerceGetWelcomeMessageAttempting,
    getECommerceGetWelcomeMessageFailure,
    getECommerceGetWelcomeMessage,

    getECommerceEditWelcomeMessageAttempting,
    getECommerceEditWelcomeMessageFailure,

    getECommerceGetProductListingMessageAttempting,
    getECommerceGetProductListingMessageFailure,
    getECommerceGetProductListingMessage,

    getECommerceEditProductListingMessageAttempting,
    getECommerceEditProductListingMessageFailure,

    getECommerceGetProductCategoryAttempting,
    getECommerceGetProductCategoryFailure,
    getECommerceGetProductCategory,

    getECommerceCreateProductCategoryAttempting,
    getECommerceCreateProductCategoryFailure,

    getECommerceEditProductCategoryAttempting,
    getECommerceEditProductCategoryFailure,

    getECommerceDeleteProductCategoryAttempting,
    getECommerceDeleteProductCategoryFailure,

    getECommerceGetProductListAttempting,
    getECommerceGetProductListFailure,
    getECommerceGetProductList,

    getECommerceCreateProductListAttempting,
    getECommerceCreateProductListFailure,

    getECommerceGetProductDetailsAttempting,
    getECommerceGetProductDetailsFailure,
    getECommerceGetProductDetails,

    getECommerceEditProductDetailsAttempting,
    getECommerceEditProductDetailsFailure,

    getECommerceDeleteProductDetailsAttempting,
    getECommerceDeleteProductDetailsFailure,

    getECommerceCreateProductVariantAttempting,
    getECommerceCreateProductVariantFailure,

    getECommerceEditProductVariantAttempting,
    getECommerceEditProductVariantFailure,

    getECommerceDeleteProductVariantAttempting,
    getECommerceDeleteProductVariantFailure,

    getECommerceGetProductCategoryId,
    getECommerceGetCreateCategoryModalIsOpen,
    getECommerceGetCreateProductModalIsOpen,
    getECommerceGetCreateVariantModalIsOpen,
    getECommerceGetEditVariantModalIsOpen,

    getECommerceGetCTAButtonDetailsAttempting,
    getECommerceGetCTAButtonDetailsFailure,
    getECommerceGetCTAButtonDetails,

    getECommerceEditCTAButtonDetailsAttempting,
    getECommerceEditCTAButtonDetailsFailure,

    getECommerceAnalDatePicker,

    getECommerceAnalClicksReportAttempting,
    getECommerceAnalClicksReportFailure,
    getECommerceAnalClicksReportData,

    getECommerceAnalClicksProductReportAttempting,
    getECommerceAnalClicksProductReportFailure,
    getECommerceAnalClicksProductReportData,

    getECommerceAnalClicksVariantReportAttempting,
    getECommerceAnalClicksVariantReportFailure,
    getECommerceAnalClicksVariantReportData,

    getECommerceAnalBuyNowClicksReportAttempting,
    getECommerceAnalBuyNowClicksReportFailure,
    getECommerceAnalBuyNowClicksReportData,

    getECommerceAnalBuyNowClicksProductReportAttempting,
    getECommerceAnalBuyNowClicksProductReportFailure,
    getECommerceAnalBuyNowClicksProductReportData,

    getECommerceAnalBuyNowClicksVariantReportAttempting,
    getECommerceAnalBuyNowClicksVariantReportFailure,
    getECommerceAnalBuyNowClicksVariantReportData,

    getECommerceAnalActivitiesReportAttempting,
    getECommerceAnalActivitiesReportFailure,
    getECommerceAnalActivitiesReportData,

    getECommerceAnalFunnelReportAttempting,
    getECommerceAnalFunnelReportFailure,
    getECommerceAnalFunnelReportData,
};
