import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';
import {
    RecurringPost,
    SchedulePost,
    BroadcastHistoryPost,
    ICreateBroadcastPost,
    StatusTypeEnum,
    IEditBroadcastPost,
    BroadcastHistoryFilter,
    BroadcastOverview,
    HistoryDetails,
    BroadcastHistoryPostExport,
    HistoryDetailsExport,
} from 'entities/broadcast';

export interface GetBroadcastOverviewParams {
    authToken: string;
    date: string;
}

export interface GetRecurringPostParams {
    authToken: string;
    index: number;
    status: StatusTypeEnum;
}

export interface GetSchedulePostParams {
    authToken: string;
    index: number;
    status: StatusTypeEnum;
}

export interface GetHistoryPostParams {
    authToken: string;
    index: number;
    status: BroadcastHistoryFilter;
}

export interface GetHistoryPostExportParams {
    authToken: string;
    broadcastType: BroadcastHistoryFilter;
}

export interface GetHistoryDetailsParams {
    authToken: string;
    id: string;
}

export interface GetHistoryDetailsExportParams {
    authToken: string;
    broadcastHistoryId: string;
}

export interface CreateBroadcastPostParams {
    authToken: string;
    data: ICreateBroadcastPost;
}

export interface EditBroadcastPostParams {
    authToken: string;
    data: IEditBroadcastPost;
}

export interface RecallBroadcastMessageParams {
    authToken: string;
    id: string;
}

export abstract class IBroadcastGateway extends Gateway {
    abstract getBroadcastOverview(params: GetBroadcastOverviewParams): GatewayResponse<BroadcastOverview>;

    abstract getRecurringPost(params: GetRecurringPostParams): GatewayResponse<RecurringPost>;

    abstract getSchedulePost(params: GetSchedulePostParams): GatewayResponse<SchedulePost>;

    abstract getHistoryPost(params: GetHistoryPostParams): GatewayResponse<BroadcastHistoryPost>;

    abstract getHistoryPostExport(params: GetHistoryPostExportParams): GatewayResponse<BroadcastHistoryPostExport[]>;

    abstract getHistoryDetails(params: GetHistoryDetailsParams): GatewayResponse<HistoryDetails>;

    abstract getHistoryDetailsExport(params: GetHistoryDetailsExportParams): GatewayResponse<HistoryDetailsExport[]>;

    abstract createBroadcastPost(params: CreateBroadcastPostParams): GatewayResponse<ICreateBroadcastPost>;

    abstract editBroadcastPost(params: EditBroadcastPostParams): GatewayResponse<null>;

    abstract recallBroadcastMessage(params: RecallBroadcastMessageParams): GatewayResponse<null>;
}
