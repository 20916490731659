import React, { useEffect } from 'react';

import styled, { FlattenSimpleInterpolation } from 'styled-components';
import { connect } from 'react-redux';

import { Table, Row as TableRow, Col } from 'reactstrap';

import { ECommClicksVariantReportData } from 'entities/ecommerceAnalytics';

import Fonts from 'assets/themes/Fonts';
import Colors from 'assets/themes/Colors';

import Subtitle from 'components/Subtitle';
import Loading from 'components/analytics/common/Loading';
import Error from 'components/analytics/common/Error';

import { TableStyles } from 'components/styles';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

interface BuyNowProductVariantTableProps {
    productId?: string;
    productName?: string;

    data: ECommClicksVariantReportData[] | null;

    loading: boolean;
    error: string | null;

    getVariantData: (id: string) => void;
}

const BuyNowProductVariantReport = (props: BuyNowProductVariantTableProps): JSX.Element | null => {
    const { loading, error, data, productId, productName, getVariantData } = props;

    useEffect(() => {
        if (productId) getVariantData(productId);
    }, [productId]);

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <Error errorMessage={error} />;
    }

    if (!productId || !productName || !data) return null;

    const totalClicks = data.reduce((acc, item) => acc + item.clicks, 0);
    const totalUniqueClicks = data.reduce((acc, item) => acc + item.uniqueClicks, 0);

    return (
        <Container>
            <Row>
                <Subtitle>
                    Number of Buy Now Clicks Based on Variants
                </Subtitle>

                <Text>
                    {`Viewing: ${productName}`}
                </Text>
            </Row>

            <TableRow>
                <Col>
                    <StyledTableWrapper>
                        <StyledTable css={TableStyles.primary}>
                            <thead>
                                <tr>
                                    <th>Variants/Colours</th>
                                    <th>Buy Now Clicks</th>
                                    <th>Unique Buy Now Clicks</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{
                                    backgroundColor: Colors.iqosLightPrimary,
                                }}
                                >
                                    <td style={{ fontFamily: Fonts.secondary }}>Total</td>
                                    <td style={{ fontFamily: Fonts.secondary }}>{totalClicks}</td>
                                    <td style={{ fontFamily: Fonts.secondary }}>{totalUniqueClicks}</td>
                                </tr>
                                {data.map((item) => (
                                    <tr key={item.id}>
                                        <td>{item.name}</td>
                                        <td>{item.clicks}</td>
                                        <td>{item.uniqueClicks}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </StyledTable>
                    </StyledTableWrapper>
                </Col>
            </TableRow>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;

    flex: 1;

    width: 60%;

    @media (max-width: 1024px) {
        width: 100%;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;

    justify-content: space-between;

    flex: 1;

    align-items: center;
`;

const Text = styled.text`
    font-family: ${Fonts.secondary};
    font-size: 14px;
    color: #A2A2A2;
`;

interface StyledButtonProps {
    css?: FlattenSimpleInterpolation;
}

type StyledTableProps = StyledButtonProps;

const StyledTable = styled(Table)<StyledTableProps>`
    margin-bottom: unset !important;

    thead tr th {
        position:sticky;
        top: 0;
        background-color:${Colors.white};
    }

    tr {
        white-space: nowrap; 
    }
    ${props => props.css}
`;

interface StyledTableWrapperProps {
    scroll?: boolean;
}

const StyledTableWrapper = styled.div<StyledTableWrapperProps>`
    margin: 1rem 0;
    border-radius: 8px;
    overflow-x: auto;
    overflow-y: auto;
    max-height: 768px;
    box-sizing: content-box;
    
    ::-webkit-scrollbar {
        width: 6px;
        height:6px;
    }
      
    ::-webkit-scrollbar-track {
    background: #f1f1f1; 
    }
       
    ::-webkit-scrollbar-thumb {
    background: #888; 
    }
      
    ::-webkit-scrollbar-thumb:hover {
    background: #555; 
    }

    scrollbar-gutter: stable !important;
`;

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getECommerceAnalBuyNowClicksVariantReportAttempting(state),
    error: Selectors.getECommerceAnalBuyNowClicksVariantReportFailure(state),
    data: Selectors.getECommerceAnalBuyNowClicksVariantReportData(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getVariantData: (productId: string) => dispatch(Actions.ecommAnalGetBuyNowClicksVariantReportAttempt({ productId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(BuyNowProductVariantReport);
