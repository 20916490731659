import React from 'react';

import styled from 'styled-components';
import dayjs from 'dayjs';

import { ECommActivitiesReportData } from 'entities/ecommerceAnalytics';

import Subtitle from 'components/Subtitle';
import GraphWithAverage from 'components/analytics/common/GraphWithAverage';
import Colors from 'assets/themes/Colors';

import Tooltip from 'components/IconTooltip';
import Icons from 'assets/icons/Index';

interface ActivitiesGraphProps {
    data: ECommActivitiesReportData | null;
}

const ActivitiesGraph = (props: ActivitiesGraphProps): JSX.Element | null => {
    const { data } = props;

    if (!data) return null;

    const renderDayGraph = () => {
        const { averageClicks, averageUniqueClicks, data: dayData } = data.day;

        const activityData = dayData.map((item) => ({
            label: dayjs().day(item.day).format('ddd'),
            value: item.clicks,
            color: Colors.iqosPrimary,
        }));

        const uniqueActivityData = dayData.map((item) => ({
            label: dayjs().day(item.day).format('ddd'),
            value: item.uniqueClicks,
            color: Colors.iqosError,
        }));

        const averageData = [{
            label: 'Average clicks',
            color: Colors.iqosPrimary,
            value: averageClicks,
        }, {
            label: 'Average unique clicks',
            color: Colors.iqosError,
            value: averageUniqueClicks,
        }];

        return (
            <div>
                <Row>
                    <Subtitle>
                        Click Activities Across Days
                    </Subtitle>
                    <Tooltip
                        iconSrc={Icons.QuestionMark}
                        tooltipId='ecommAnalyticsActivitiesTooltip'
                        maxWidth='unset'
                        bgColor={Colors.iqosPrimary}
                        fontColor={Colors.primary}
                        size='20px'
                    >
                        Activities include all clicks in the bot (moving between products, start buttons, etc.).
                    </Tooltip>
                </Row>

                <GraphWithAverage
                    data={{
                        dataName: 'Clicks',
                        data: activityData,
                    }}
                    secondData={{
                        dataName: 'Unique clicks',
                        data: uniqueActivityData,
                    }}
                    averageData={averageData}
                />
            </div>
        );
    };

    const renderTimeGraph = () => {
        const { averageClicks, averageUniqueClicks, data: timeData } = data.time;

        const prepareTimeString = (dateFrom: string, dateTo: string): string => {
            return `${dateFrom.slice(0, dateFrom.length - 3)} to ${dateTo.slice(0, dateTo.length - 3)}`;
        };

        const clicksData = timeData.map((item) => ({
            label: prepareTimeString(item.timeFrom, item.timeTo),
            color: Colors.iqosPrimary,
            value: item.clicks,
        }));

        const uniqueClicksData = timeData.map((item) => ({
            label: prepareTimeString(item.timeFrom, item.timeTo),
            color: Colors.iqosError,
            value: item.uniqueClicks,
        }));

        const averageData = [{
            label: 'Average clicks',
            color: Colors.iqosPrimary,
            value: averageClicks,
        }, {
            label: 'Average unique clicks',
            color: Colors.iqosError,
            value: averageUniqueClicks,
        }];

        return (
            <div>
                <Subtitle>
                    Click Activities Across Times
                </Subtitle>
                <GraphWithAverage
                    data={{
                        dataName: 'Clicks',
                        data: clicksData,
                        disableGraphTooltip: true,
                    }}
                    secondData={{
                        dataName: 'Unique clicks',
                        data: uniqueClicksData,
                        disableGraphTooltip: true,
                    }}
                    averageData={averageData}
                />
            </div>
        );
    };

    return (
        <>
            {renderDayGraph()}
            {renderTimeGraph()}
        </>
    );
};

const Row = styled.div`
    display: flex;
    flex-direction: row;

    flex: 1;

    align-items: center;

    gap: 12px;
`;

export default ActivitiesGraph;
