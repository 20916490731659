import React, { FunctionComponent } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { HistoryRouter as Router } from 'redux-first-history/rr6';

import { history } from 'redux/store';

import LoginScreen from 'containers/auth/Login';
import HomeScreen from 'containers/home/index';
import FaqScreen from 'containers/home/faq';
import AgeBotScreen from 'containers/home/age';
import AnalyticsScreen from 'containers/home/analytics';

import ContestsQuizListScreen from 'containers/home/contest/QuizOverview';
import ContestsQuizDetailsScreen from 'containers/home/contest/QuizDetails';

import ContestsGameListScreen from 'containers/home/contest/GameOverview';
import ContestsGameDetailsScreen from 'containers/home/contest/GameDetails';

import PNCMessageScreen from 'containers/home/pncMessage';
import BroadcastScreen from 'containers/home/broadcast';
import SummaryDashboardScreen from 'containers/home/analytics/summaryDashboard';
import FollowersOverviewScreen from 'containers/home/analytics/followers';
import FollowersFullListScreen from 'containers/home/analytics/followers/FullList';
import PostsScreen from 'containers/home/analytics/posts';
import ECommerceScreen from 'containers/home/eCommerce';
import ProductListScreen from 'containers/home/eCommerce/products';
import ProductDetailsScreen from 'containers/home/eCommerce/productDetails';

import ECommAnalyticsClicksReportScreen from 'containers/home/eCommerceAnalytics/ClicksReport';
import ECommANalyticsClicksProductReportScreen from 'containers/home/eCommerceAnalytics/ClicksProductReport';

import ECommAnalyticsBuyNowReportScreen from 'containers/home/eCommerceAnalytics/BuyNowReport';
import ECommAnalyticsBuyNowProductReportScreen from 'containers/home/eCommerceAnalytics/BuyNowProductReport';

import ECommAnalyticsActivitiesReportScreen from 'containers/home/eCommerceAnalytics/ActivitiesReport';
import ECommAnalyticsFunnelReportScreen from 'containers/home/eCommerceAnalytics/FunnelReport';

import PrivateRoute from './PrivateRoutes';

const PrivateBucket: FunctionComponent = () => {
    return (
        <Route path='/' element={<PrivateRoute />}>
            <Route element={<HomeScreen />}>
                <Route path='/faq' element={<FaqScreen />} />
                <Route path='/agebot' element={<AgeBotScreen />} />
                <Route path='/analytics' element={<AnalyticsScreen />} />
                <Route path='/broadcast' element={<BroadcastScreen />} />
                <Route path='/pncmessage' element={<PNCMessageScreen />} />

                <Route path='/ecommerce' element={<ECommerceScreen />} />
                <Route path='/ecommerce/:id' element={<ProductListScreen />} />
                <Route path='/ecommerce/:id/:id' element={<ProductDetailsScreen />} />

                <Route path='/ecommerceAnalytics/clicks' element={<ECommAnalyticsClicksReportScreen />} />
                <Route path='/ecommerceAnalytics/productClicks/:id' element={<ECommANalyticsClicksProductReportScreen />} />

                <Route path='/ecommerceAnalytics/buyNowClicks' element={<ECommAnalyticsBuyNowReportScreen />} />
                <Route path='/ecommerceAnalytics/buyNowProductClicks/:id' element={<ECommAnalyticsBuyNowProductReportScreen />} />

                <Route path='/ecommerceAnalytics/activities' element={<ECommAnalyticsActivitiesReportScreen />} />
                <Route path='/ecommerceAnalytics/funnel' element={<ECommAnalyticsFunnelReportScreen />} />

                <Route path='/analytics/summaryDashboard/' element={<SummaryDashboardScreen />} />

                <Route path='/analytics/followers/' element={<FollowersOverviewScreen />} />
                <Route path='/analytics/followers/fullFollowersList' element={<FollowersFullListScreen />} />

                <Route path='/contests/quiz' element={<ContestsQuizListScreen />} />
                <Route path='/contests/game' element={<ContestsGameListScreen />} />

                <Route path='/contests/quiz/:id' element={<ContestsQuizDetailsScreen />} />
                <Route path='/contests/game/:id' element={<ContestsGameDetailsScreen />} />

                <Route path='/analytics/posts/' element={<PostsScreen />} />
            </Route>
        </Route>
    );
};

const NavRoutes: FunctionComponent = (props) => {
    return (
        <Router history={history}>
            <Routes>
                <Route path='/login' element={<LoginScreen />} />

                <Route path='/' element={<Navigate replace to='/analytics/summaryDashboard' />} />

                <Route path='*' element={<Navigate replace to='/analytics/summaryDashboard' />} />
                {PrivateBucket(props)}
            </Routes>
        </Router>
    );
};

export default NavRoutes;
