import Fonts from 'assets/themes/Fonts';
import React from 'react';
import styled from 'styled-components';

interface SubtitleProps {
    children: string;
}

const Subtitle = (props: SubtitleProps): JSX.Element => {
    const { children } = props;

    return (
        <SubtitleContainer>
            {children}
        </SubtitleContainer>
    );
};

const SubtitleContainer = styled.text`
    text-align: left;
    font-size: 20px;
    font-family: ${Fonts.secondary};
`;

export default Subtitle;
