import React from 'react';
import styled from 'styled-components';

import FontStyle from 'assets/themes/Fonts';

interface AnalyticsHeaderProps {
    header: string | React.ReactNode;
    size: 'sm' | 'lg' | 'md';
    fontColor?: string;
    fontWeight?: string,
}

const AnalyticsHeader:React.FunctionComponent<AnalyticsHeaderProps> = ({
    header,
    size,
    fontColor,
    fontWeight,
}) => {
    return (
        <StyledHeader size={size} fontColor={fontColor} fontWeight={fontWeight}>{header}</StyledHeader>
    );
};

interface StyledHeaderProps {
    size: string;
    fontColor?: string;
    fontWeight?: string;
}

const changeFontSize = (size: string) => {
    let fontSize: string;
    switch (size) {
        case 'sm':
            fontSize = FontStyle.sm;
            break;
        case 'lg':
            fontSize = FontStyle.lg;
            break;
        default:
            fontSize = FontStyle.md;
            break;
    }
    return fontSize;
};

const StyledHeader = styled.div<StyledHeaderProps>`
    font-size: ${props => changeFontSize(props.size)};
    color: ${props => props.fontColor};
    ${props => (props.fontWeight && props.fontWeight !== 'bold'
        ? `font-weight: ${props.fontWeight}`
        : 'font-family: IQOS-Bold;')};
    `;

AnalyticsHeader.defaultProps = {
    fontColor: '#27242E',
    fontWeight: 'bold',
};

export default AnalyticsHeader;
