import { ApiResponse } from 'apisauce';
import { IPNCMessageDetails } from 'entities/PNCMessage';
import { GatewayResponse } from './types/types';
import { GetPNCMessageParams, IPNCMessageGateway, SetPNCMessageParams } from './PNCMessageBase';

const getHeaders = (authToken: string) => ({
    headers: {
        Authorization: `Bearer ${authToken}`,
    },
});

export default class PNCMessageGateway extends IPNCMessageGateway {
    async getPNCMessage(params: GetPNCMessageParams): GatewayResponse<IPNCMessageDetails> {
        const response: ApiResponse<IPNCMessageDetails> = await this.api.get('/v1/tpc/tpcMessage', {}, getHeaders(params.authToken));

        return this.process(response);
    }

    async setPNCMessage(params: SetPNCMessageParams): GatewayResponse<null> {
        const { title, message, file, clearImage } = params;
        const form = new FormData();

        form.append('title', title);
        form.append('message', message);

        if (file) {
            form.append('file', file as Blob);
        }
        form.append('clearImage', clearImage);

        const response: ApiResponse<null> = await this.api.post('/v1/tpc/tpcMessage', form, getHeaders(params.authToken));
        return this.process(response);
    }
}
