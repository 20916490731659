import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { toast } from 'react-toastify';

import ECommerceGateway from 'api/ECommerce';
import { SetWelcomeMessageParams } from 'redux/slices/eCommerce/types';

export default function* watchSetWelcomeMessage(api: ECommerceGateway): SagaWatcherReturnType {
    yield takeEvery('eCommerce/setWelcomeMessageAttempt', handleSetWelcomeMessage, api);
}

function* handleSetWelcomeMessage(api: ECommerceGateway, data: SetWelcomeMessageParams) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const { title, message, language } = data.payload;

    const response = yield* call([api, api.setWelcomeMessage], {
        authToken,
        title,
        message,
        language,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.setWelcomeMessageFailure(response.message));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.setWelcomeMessageSuccess());
        yield put(Actions.getWelcomeMessageAttempt());
        toast.success('Message Saved Successfully');
    }
}
