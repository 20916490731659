import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';
import { connect } from 'react-redux';
import Actions from 'redux/Actions';

import Button from 'components/Button';
import Colors from 'assets/themes/Colors';
import Fonts from 'assets/themes/Fonts';

interface RecallBroadcastMessageModalProps {
    recallBroadcastMessageId: string;
    getRecallBroadcastMessageModalIsOpen: boolean;
    isRecallBroadcastMessageLoading: boolean;

    recallBroadcastMessage(id: string): void;
    setRecallBroadcastMessageModalIsOpen(data: boolean): void;
}

const RecallBroadcastMessageModal: FunctionComponent<RecallBroadcastMessageModalProps> = (props: RecallBroadcastMessageModalProps) => {
    const {
        recallBroadcastMessageId,
        getRecallBroadcastMessageModalIsOpen,

        isRecallBroadcastMessageLoading,

        recallBroadcastMessage,
        setRecallBroadcastMessageModalIsOpen,
    } = props;

    const onConfirmHandler = () => {
        recallBroadcastMessage(recallBroadcastMessageId);

        setRecallBroadcastMessageModalIsOpen(false);
    };

    const recallBroadcastMessageToggle = () => {
        setRecallBroadcastMessageModalIsOpen(!getRecallBroadcastMessageModalIsOpen);
    };

    return (
        <Modal isOpen={getRecallBroadcastMessageModalIsOpen} toggle={recallBroadcastMessageToggle} centered size='lg'>
            <ModalHeader>
                <StyledHeader>
                    Confirmation
                </StyledHeader>
            </ModalHeader>
            <ModalBody>
                <StyledText>
                    Are you sure you want to recall this broadcast message?
                </StyledText>
            </ModalBody>

            <ModalFooter style={{ justifyContent: 'flex-end', border: 'none' }}>
                <Button
                    label='Confirm'
                    onClick={() => onConfirmHandler()}
                    css={styles.confirmButton}
                    loading={isRecallBroadcastMessageLoading}
                />
                <Button
                    label='Cancel'
                    onClick={() => setRecallBroadcastMessageModalIsOpen(false)}
                    css={styles.cancelButton}
                />
            </ModalFooter>
        </Modal>
    );
};

const StyledHeader = styled.text`
    color: black;
    font-size: 40px;
    font-family: ${Fonts.secondary};
`;

const StyledText = styled.text`
    color: black;
    font-family: ${Fonts.primary};
    font-size: 18px;
`;

const styles = {
    cancelButton: css`
        background-color: ${Colors.primary};
        color: white;
        font-size: 18px;
        border-radius: 50px;
        width: 100px;
        height: 40px;

        &:hover {
            background-color:  #36CAB5;
            color: white;
            border: 1px solid #36CAB5;
        }
    `,

    confirmButton: css`
        color: white;
        border: 1px solid #36CAB5;
        font-size: 18px;
        border-radius: 50px;
        width: 100px;
        height: 40px;

        &:hover {
            background-color: #36CAB5;
            color: white;
            border: 1px solid #36CAB5;
        }

        &:disabled {
            background-color: #BBBDBD;
            color: white;
        }

        &:active {
            background-color: #36CAB5;
            color: white;
        }
    `,
};

const mapStateToProps = (state: RootState) => ({
    getRecallBroadcastMessageModalIsOpen: Selectors.getBroadcastRecallBroadcastMessageModalIsOpen(state),
    isRecallBroadcastMessageLoading: Selectors.recallBroadcastMessageAttempting(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    recallBroadcastMessage: (id: string) => dispatch(Actions.recallBroadcastMessageAttempt({ id })),
    setRecallBroadcastMessageModalIsOpen: (data: boolean) => dispatch(Actions.setRecallBroadcastMessageModalIsOpen(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecallBroadcastMessageModal);
