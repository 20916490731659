import React from 'react';
import styled from 'styled-components';

import Text from 'components/Text';

interface ErrorProps {
    errorMessage?: string | null;

    height?: number
}

const Error = (props: ErrorProps): JSX.Element => {
    const { errorMessage = 'Something went wrong. Please try again.', height } = props;

    return (
        <Container height={height}>
            <Text style={{ width: '100%', color: 'red' }}>{errorMessage}</Text>
        </Container>
    );
};

const Container = styled.div<{height?: number}>`
    width: 100%;
    height: ${props => (props.height ? `${props.height}px` : '500px')};
    display: flex;

    padding: 20px;
    text-align: center;
`;

export default Error;
