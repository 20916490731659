import { call, put, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import ECommerceAnalyticsGateway from 'api/ECommerceAnalytics';

export default function* watchGetActivitiesReport(api: ECommerceAnalyticsGateway): SagaWatcherReturnType {
    yield takeEvery('eCommerceAnalytics/ecommAnalGetActivitiesReportAttempt', handleGetActivitiesReport, api);
}

function* handleGetActivitiesReport(api: ECommerceAnalyticsGateway) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const selectedDates = yield* select(Selectors.getECommerceAnalDatePicker);

    const response = yield* call([api, api.getActivitiesReport], {
        authToken,
        dateFrom: selectedDates.dateFrom.toISOString(),
        dateTo: selectedDates.dateTo.toISOString(),
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.ecommAnalGetActivitiesReportFailure(response.message));
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.ecommAnalGetActivitiesReportSuccess(response.data));
    }
}
