import React from 'react';
import {
    LineChart,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    Legend,
    Tooltip,
    ResponsiveContainer,
    Text,
} from 'recharts';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';

import Selectors from 'redux/Selectors';
import { RootState } from 'redux/store';

import Icons from 'assets/icons/Index';
import Colors from 'assets/themes/Colors';

import FollowersHeader from 'components/analytics/FollowersHeader';

import 'components/iqosLineChart.css';

import { IFollowersChartsData } from 'entities/followers';

const CustomLineChartDot = ({ cy, cx, strokeColor }:any) => {
    if (!cy && cx) return null;
    return (
        <circle
            cy={cy}
            cx={cx}
            r={8} // circle size - to control marker size
            stroke={strokeColor}
            strokeWidth={3}
            fill='white'
        />

    );
};

const CustomXAxisTick = ({ x, y, payload, formatDate = false }: any) => {
    const renderPayloadValue = (text: string) => {
        if (formatDate) {
            return `${text.substring(0, 3)} ${text.substring(text.length - 2)}`;
        }
        return text;
    };

    if (payload && payload.value) {
        return (
            <Text
                fontSize='14px'
                width='12px'
                x={x}
                y={y + 26}
                textAnchor='middle'
                verticalAnchor='middle'
            >
                {renderPayloadValue(payload.value)}
            </Text>
        );
    }
    return null;
};

const CustomLineLabel = ({ x, y, value, backgroundColor, index, dataLength, isYtdData }: any) => {
    if (value >= 0) {
        const shouldRenderLabel = (isYtdData && dataLength > 100 && index % (Math.floor(dataLength / 15)) === 0)
            || (isYtdData && dataLength < 100 && dataLength > 60 && index % 5 === 0)
            || (isYtdData && dataLength < 60 && dataLength > 20 && index % 3 === 0)
            || (isYtdData && dataLength < 21)
            || (!isYtdData);

        if (shouldRenderLabel) {
            return (
                <g fontSize={12}>
                    <foreignObject x={x - 25} y={y - 40} width={50} height={40}>
                        <StyledLineChartLabel bgColor={backgroundColor}>{value}</StyledLineChartLabel>
                    </foreignObject>
                </g>
            );
        }
    }

    return null;
};
interface FollowersChartsProp {
    followersChartsData: IFollowersChartsData;
    isLoading: boolean;
    error: string;
}

const Charts:React.FunctionComponent<FollowersChartsProp> = ({
    followersChartsData,
    isLoading,
    error,
}) => {
    const { ytdSubscriberData, monthlySubscriberData } = followersChartsData;
    const renderLineCharts = () => {
        if (isLoading) {
            return (
                <Skeleton count={5} height={50} />
            );
        }

        const renderMonthlySubscriberChart = () => {
            return (
                <ResponsiveContainer width='99%' height={400}>
                    <LineChart
                        width={600}
                        height={400}
                        data={monthlySubscriberData}
                        margin={{ top: 80, right: 55, bottom: 15, left: 15 }}
                    >
                        <CartesianGrid stroke='#ccc' />
                        <XAxis
                            dataKey='name'
                            padding={{ right: 50, left: 50 }}
                            tick={<CustomXAxisTick formatDate />} // Xaxis Label
                            tickLine={false}
                            interval={monthlySubscriberData.length > 35 ? monthlySubscriberData.length / 12 : 0}
                        />
                        <YAxis
                            label={{ value: 'Accounts', position: 'top', dy: -35, dx: 20 }}
                            padding={{ top: 20 }}
                            axisLine={false}
                            tickLine={false}
                        />

                        <Tooltip />
                        <Legend
                            wrapperStyle={{ top: 23, right: 40, textAlign: 'end' }}
                            align='right'
                            payload={
                                [
                                    { id: 'followers', value: 'Followers', type: 'circle', color: Colors.iqosPrimary },
                                    { id: 'unsubscribers', value: 'Unsubscribers', type: 'circle', color: Colors.iqosError },
                                ]
                            }
                        />
                        <Line
                            name='Unsubscribers'
                            type='linear'
                            dataKey='unsubscribers'
                            stroke={Colors.iqosError}
                            strokeWidth={3}
                            dot={<CustomLineChartDot strokeColor={Colors.iqosError} />} // Ticks circle
                            label={monthlySubscriberData.length < 20 && <CustomLineLabel backgroundColor={Colors.iqosError} />} // Ticks label
                            isAnimationActive
                        />
                        <Line
                            name='Followers'
                            type='linear'
                            dataKey='followers'
                            stroke={Colors.iqosPrimary}
                            strokeWidth={3}
                            dot={<CustomLineChartDot strokeColor={Colors.iqosPrimary} />} // Ticks circle
                            label={monthlySubscriberData.length < 20 && <CustomLineLabel backgroundColor={Colors.iqosPrimary} />} // Ticks label
                            isAnimationActive
                        />
                    </LineChart>
                </ResponsiveContainer>
            );
        };
        const renderYTDGrowthSubscriberChart = () => {
            return (
                <ResponsiveContainer width='99%' height={400}>
                    <LineChart
                        width={600}
                        height={400}
                        data={ytdSubscriberData}
                        margin={{ top: 80, right: 15, bottom: 30, left: 15 }}
                    >
                        <CartesianGrid stroke='#ccc' />
                        <XAxis
                            dataKey='name'
                            padding={{ right: 50, left: 50 }}
                            tick={<CustomXAxisTick />} // Xaxis Label
                            tickLine={false}
                            interval='preserveStartEnd'
                        />
                        <YAxis
                            label={{ value: 'Accounts', position: 'top', dy: -35, dx: 20 }}
                            padding={{ top: 20 }}
                            axisLine={false}
                            tickLine={false}
                            yAxisId='left'
                            domain={['auto', 'auto']}
                        />
                        <Tooltip />
                        <Legend
                            wrapperStyle={{ top: 23, right: 40, textAlign: 'end' }}
                            align='right'
                            payload={
                                [
                                    { id: 'growthYtd', value: 'Year to date followers', type: 'circle', color: Colors.iqosPrimary },
                                ]
                            }
                        />
                        <Line
                            name='YTD Followers'
                            type='linear'
                            dataKey='growthYtd'
                            stroke={Colors.iqosPrimary}
                            strokeWidth={3}
                            // dot={false}
                            dot={<CustomLineChartDot strokeColor={Colors.iqosPrimary} />} // Ticks circle
                            label={<CustomLineLabel backgroundColor={Colors.iqosPrimary} dataLength={ytdSubscriberData.length} isYtdData />} // Ticks label
                            isAnimationActive
                            yAxisId='left'
                        />
                    </LineChart>
                </ResponsiveContainer>
            );
        };

        const renderMonthlyGrowthSubscriberChart = () => {
            return (
                <ResponsiveContainer width='99%' height={400}>
                    <LineChart
                        width={600}
                        height={400}
                        data={ytdSubscriberData}
                        margin={{ top: 80, right: 15, bottom: 30, left: 15 }}
                    >
                        <CartesianGrid stroke='#ccc' />
                        <XAxis
                            dataKey='name'
                            padding={{ right: 50, left: 50 }}
                            tick={<CustomXAxisTick />} // Xaxis Label
                            tickLine={false}
                            interval='preserveStartEnd'
                        />
                        <YAxis
                            label={{ value: 'Accounts', position: 'top', dy: -35, dx: 20 }}
                            padding={{ top: 20 }}
                            axisLine={false}
                            tickLine={false}
                            yAxisId='left'
                            orientation='left'
                            domain={['auto', 'auto']}
                        />
                        <Tooltip />
                        <Legend
                            wrapperStyle={{ top: 23, right: 40, textAlign: 'end' }}
                            align='right'
                            payload={
                                [
                                    { id: 'growthMonthly', value: 'Monthly new followers', type: 'circle', color: Colors.iqosError },
                                ]
                            }
                        />
                        <Line
                            name='Monthly Followers'
                            type='linear'
                            dataKey='growthMonthly'
                            stroke={Colors.iqosError}
                            strokeWidth={3}
                            // dot={false}
                            dot={<CustomLineChartDot strokeColor={Colors.iqosError} />} // Ticks circle
                            label={<CustomLineLabel backgroundColor={Colors.iqosError} dataLength={ytdSubscriberData.length} isYtdData />} // Ticks label
                            isAnimationActive
                            yAxisId='left'
                            connectNulls
                        />
                    </LineChart>
                </ResponsiveContainer>
            );
        };

        return (
            <>
                <div>
                    <FollowersHeader
                        headerContent='Monthly new follower & unsubscribers'
                        headerSize='md'
                        tooltipId='firstChartTooltip'
                        tooltipIconSrc={Icons.QuestionMark}
                        tooltipMaxWidth='unset'
                        tooltipContent='Total number of new followers and unsubscribers in
                        the selected date range, grouped by month.'
                    />
                    <StyledLineChartWrapper>
                        {renderMonthlySubscriberChart()}
                    </StyledLineChartWrapper>
                </div>
                <div>
                    <FollowersHeader
                        headerContent='YTD followers'
                        headerSize='md'
                        tooltipId='ytdChartTooltip'
                        tooltipIconSrc={Icons.QuestionMark}
                        tooltipMaxWidth='unset'
                        tooltipContent={(
                            <StyledUl>
                                <li>
                                    <div>Year to date followers:</div>
                                    <div>
                                        The total number of
                                        followers in the channel in the selected date range,
                                        grouped by individual days.
                                    </div>
                                </li>
                            </StyledUl>
                        )}
                    />
                    <StyledLineChartWrapper>
                        {renderYTDGrowthSubscriberChart()}
                    </StyledLineChartWrapper>
                </div>
                <div>
                    <FollowersHeader
                        headerContent='Monthly new followers'
                        headerSize='md'
                        tooltipId='monthlyChartTooltip'
                        tooltipIconSrc={Icons.QuestionMark}
                        tooltipMaxWidth='unset'
                        tooltipContent={(
                            <StyledUl>
                                <li>
                                    <div>Monthly new followers:</div>
                                    <div>
                                        Total number of new
                                        followers in the selected date range, grouped by
                                        individual days.
                                    </div>
                                </li>
                            </StyledUl>
                        )}
                    />
                    <StyledLineChartWrapper>
                        {renderMonthlyGrowthSubscriberChart()}
                    </StyledLineChartWrapper>
                </div>
            </>
        );
    };

    return renderLineCharts();
};

const StyledUl = styled.ul`
    text-align:left;
    padding-right:.5rem;
    > li {
        > div:first-child {
            font-weight:bold;
            margin-top:.5rem;

        }
    }
`;

const StyledLineChartWrapper = styled.div`
    background-color: white;
    border-radius:4px;
    margin: 2rem 0 1rem 0;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
`;

interface StyledLineChartLabelProps {
    bgColor: string;
}

const StyledLineChartLabel = styled.div<StyledLineChartLabelProps>`
    background-color: ${props => props.bgColor};
    padding:6px 8px;
    text-align:center;
    color: ${Colors.white};
    border-radius:8px;
`;

const mapStateToProps = (state: RootState) => ({
    followersUsersListCurrentIndex: Selectors.followersGetFollowersUsersListCurrentIndex(state),
    followersChartsData: Selectors.followersGetFollowersChartsData(state),
    isLoading: Selectors.followersGetFollowersChartsDataAttempting(state),
    error: Selectors.followersGetFollowersChartsDataError(state),
});

export default connect(mapStateToProps)(Charts);
