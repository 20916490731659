import React from 'react';
import styled from 'styled-components';

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, Label, ResponsiveContainer, Tooltip, ReferenceLine, LegendType } from 'recharts';
// eslint-disable-next-line import/no-unresolved
import { CategoricalChartFunc } from 'recharts/types/chart/generateCategoricalChart';
// eslint-disable-next-line import/no-unresolved
import { Payload } from 'recharts/types/component/DefaultLegendContent';

import TextComponent from 'components/Text';

export interface GraphData {
    label: string;
    value: number;
    color: string;
}

interface GraphDataSet {
    dataName: string;
    data: GraphData[];

    disableGraphTooltip?: boolean;
}

interface GraphProps {
    data: GraphDataSet;
    // if there are multiple bars in the graph
    secondData?: GraphDataSet;
    // pass in average value in decimals and not percentages.
    averageData: GraphData[];
    onDataBarClicked?: CategoricalChartFunc;
}

interface CustomLineLabelProps {
    x?: number;
    y?: number;
    value?: string;
    backgroundColor: string;
}

enum BarTypeEnum {
    Primary,
    Secondary,
}

const CustomLineLabel = ({ x = 0, y = 0, value, backgroundColor }: CustomLineLabelProps) => {
    if (value) {
        return (
            <g fontSize={12}>
                <foreignObject x={x + 5} y={y - 40} width={50} height={40}>
                    <StyledLineChartLabel bgColor={backgroundColor}>{value}</StyledLineChartLabel>
                </foreignObject>
            </g>
        );
    }
    return null;
};

const GraphWithAverage = (props: GraphProps): JSX.Element => {
    const { data, secondData, onDataBarClicked, averageData } = props;

    if (!data.data.length) {
        return (
            <NoData>
                This graph is disabled.
            </NoData>
        );
    }

    const averageInStringFormat = averageData.map((item, index) => ({
        value: `${item.label}: ${item.value.toFixed(0)}`,
        positionIndex: index,
    }));

    let legendData: Payload[] = [{
        id: 'value',
        value: data.dataName,
        type: 'circle',
        color: data.data[0].color,
    }];

    if (secondData) {
        legendData = legendData.concat({
            id: 'value2',
            value: secondData.dataName,
            type: 'circle',
            color: secondData.data[0].color,
        });
    }

    legendData = legendData.concat(averageData.map((item) => ({
        id: item.label,
        value: item.label,
        type: 'plainline' as LegendType,
        payload: { strokeDasharray: '6 5' },
        color: item.color,
    })));

    const renderBars = (barIndex: BarTypeEnum) => {
        let barToRender: GraphDataSet | undefined = data;
        let barDataKey = 'value';

        if (barIndex === BarTypeEnum.Secondary) {
            barToRender = secondData;
            barDataKey = 'value2';
        }

        if (!barToRender) {
            return null;
        }

        const { color } = barToRender.data[0];

        return (
            <Bar
                name={barToRender.dataName}
                dataKey={barDataKey}
                fill={color}
                label={barToRender.disableGraphTooltip ? undefined : <CustomLineLabel backgroundColor={color} />}
            />
        );
    };

    let massagedData = data.data;

    if (secondData) {
        massagedData = data.data.map((item, index) => ({
            ...item,
            value2: secondData.data[index].value,
        }));
    }

    return (
        <ResponsiveContainer width='99%' height={(massagedData.length > 0) ? 550 : 'auto'}>
            {(massagedData && massagedData.length > 0) ? (
                <BarChart
                    height={550}
                    data={massagedData}
                    barSize={60}
                    margin={{
                        top: 120,
                        bottom: 50,
                    }}
                    onClick={onDataBarClicked}
                >

                    <CartesianGrid strokeDasharray='none' vertical={false} />

                    <XAxis
                        axisLine={false}
                        tickLine={false}
                        fontSize='13px'
                        dataKey='label'
                    >
                        {averageInStringFormat.map((item) => {
                            const { value, positionIndex } = item;

                            const x = -56;
                            const y = -416;

                            return (
                                <Label value={value} position={{ x, y: y + (positionIndex * 30) }} style={{ textAnchor: 'start' }} />
                            );
                        })}
                    </XAxis>
                    <YAxis axisLine={false} tickLine={false} fontSize='13px' />
                    <Tooltip />
                    <Legend
                        align='right'
                        verticalAlign='top'
                        width={500}
                        wrapperStyle={{ top: 40, right: 30, textAlign: 'end' }}
                        payload={legendData}
                    />

                    {renderBars(BarTypeEnum.Primary)}
                    {renderBars(BarTypeEnum.Secondary)}

                    {averageData.map((item) => (
                        <ReferenceLine y={item.value} stroke={item.color} strokeDasharray='14 14' strokeWidth={3} />
                    ))}
                </BarChart>
            ) : (
                <div style={{ textAlign: 'center' }}>
                    <TextComponent>There appears to be no data yet.</TextComponent>
                </div>
            )}
        </ResponsiveContainer>
    );
};

interface StyledLineChartLabelProps {
    bgColor: string;
}

const StyledLineChartLabel = styled.div<StyledLineChartLabelProps>`
    background-color: ${props => props.bgColor};
    padding:6px 8px;
    text-align:center;
    color: white;
    border-radius:8px;
`;

const NoData = styled.div`
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default GraphWithAverage;
