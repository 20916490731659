import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import {
    IGetFollowersTablesReduxParam,
    IFollowersTableInfo,

    IGetFollowersUsersListReduxParam,
    IGetFollowersUsersListResponse,

    IGetFollowersChartsDataReduxParam,
    IFollowersChartsResponse,

    IGetFullFollowersUsersListReduxParam,
    IGetFullFollowersUsersListResponse,
    IExportFullFollowersListToExcelReduxParam,
} from 'entities/followers';

import {
    FollowersReduxState,
} from './types';

const initialState: FollowersReduxState = {
    actions: {
        exportFullFollowersListToExcel: false,
        getFollowersTablesInfo: false,
        getFollowersUsersList: false,
        getFollowersChartsData: false,
        getFullFollowersUsersList: false,
    },
    followersTablesInfo: {
        totalNewFollowers: 0,
        totalNetFollowers: 0,
        totalUnsubscribers: 0,
        followersInfoDailyData: [],
        followersInfoMonthlyData: [],
    },
    followersUsersList: [],
    followersUsersListPage: 0,
    followersUsersListIndex: 1,
    followersUsersListMaxPage: 0,
    followersChartsData: {
        monthlySubscriberData: [],
        ytdSubscriberData: [],
    },
    fullFollowersUsersList: [],
    fullFollowersUsersListPage: 0,
    fullFollowersUsersListIndex: 1,
    fullFollowersUsersListMaxPage: 0,
    error: {
        exportFullFollowersListToExcel: '',
        getFollowersTablesInfo: '',
        getFollowersUsersList: '',
        getFollowersChartsData: '',
        getFullFollowersUsersList: '',
    },
};

const followers = createSlice({
    name: 'followers',
    initialState,
    reducers: {
        followersGetFollowersTablesInfoAttempt: (state, _action: PayloadAction<IGetFollowersTablesReduxParam>) => {
            state.actions.getFollowersTablesInfo = true;
            state.error.getFollowersTablesInfo = '';
        },
        followersGetFollowersTablesInfoFailure: (state, action: PayloadAction<string>) => {
            state.actions.getFollowersTablesInfo = false;
            if (action.payload) state.error.getFollowersTablesInfo = action.payload;
        },
        followersGetFollowersTablesInfoSuccess: (state, action:PayloadAction<IFollowersTableInfo>) => {
            if (action.payload) state.followersTablesInfo = action.payload;
            state.actions.getFollowersTablesInfo = false;
        },
        followersGetFollowersUsersListAttempt: (state, _action:PayloadAction<IGetFollowersUsersListReduxParam>) => {
            state.actions.getFollowersUsersList = true;
            state.error.getFollowersUsersList = '';
        },
        followersGetFollowersUsersListFailure: (state, action: PayloadAction<string>) => {
            state.actions.getFollowersUsersList = false;
            if (action.payload) state.error.getFollowersUsersList = action.payload;
        },
        followersGetFollowersUsersListSuccess: (state, action: PayloadAction<IGetFollowersUsersListResponse>) => {
            const { data, index, maxIndex } = action.payload;
            let indexExists = false;

            state.followersUsersListMaxPage = maxIndex;

            const massagedData = state.followersUsersList?.map(item => {
                if (item.index === index) {
                    indexExists = true;
                    return {
                        index,
                        data,
                    };
                } return item;
            });

            if (!indexExists) massagedData.push({ index, data });

            state.followersUsersList = massagedData;
            state.actions.getFollowersUsersList = false;
        },
        followersResetFollowersUsersList: (state) => {
            state.followersUsersList = [];
            state.followersUsersListMaxPage = 0;
            state.followersUsersListIndex = 1;
            state.followersUsersListPage = 0;
        },
        followersSetFollowersUsersListPage: (state, action: PayloadAction<number>) => {
            state.followersUsersListPage = action.payload;
        },
        followersSetFollowersUsersListIndex: (state, action: PayloadAction<number>) => {
            state.followersUsersListIndex = action.payload;
        },
        followersExportFullFollowersListToExcelAttempt: (state, _action: PayloadAction<IExportFullFollowersListToExcelReduxParam>) => {
            state.actions.exportFullFollowersListToExcel = true;
            state.error.exportFullFollowersListToExcel = '';
        },
        followersExportFullFollowersListToExcelFailure: (state, action:PayloadAction<string>) => {
            state.actions.exportFullFollowersListToExcel = false;
            if (action.payload) state.error.exportFullFollowersListToExcel = action.payload;
        },
        followersExportFullFollowersListToExcelSuccess: (state) => {
            state.actions.exportFullFollowersListToExcel = false;
        },
        followersGetFollowersChartsDataAttempt: (state, _action:PayloadAction<IGetFollowersChartsDataReduxParam>) => {
            state.actions.getFollowersChartsData = true;
            state.error.getFollowersChartsData = '';
        },
        followersGetFollowersChartsDataFailure: (state, action: PayloadAction<string>) => {
            state.actions.getFollowersChartsData = false;
            if (action.payload) state.error.getFollowersChartsData = action.payload;
        },
        followersGetFollowersChartsDataSuccess: (state, action: PayloadAction<IFollowersChartsResponse>) => {
            const { followerSubscriberData, growthSubscriberData } = action.payload;
            const monthly = followerSubscriberData.map((item) => {
                return ({
                    name: `${dayjs().month(item.month - 1).format('MMMM')} ${item.year}`,
                    followers: item.followers,
                    unsubscribers: item.unsubscribers,
                });
            });
            const ytd = growthSubscriberData.map((item) => {
                return ({
                    name: dayjs(item.date).format('DD MMM YY'),
                    growthMonthly: item.growthMonthly,
                    growthYtd: item.growthYtd,
                });
            });

            const massagedData = {
                monthlySubscriberData: monthly,
                ytdSubscriberData: ytd,
            };

            state.actions.getFollowersChartsData = false;
            state.followersChartsData = massagedData;
        },
        followersResetFullFollowersUsersList: (state) => {
            state.fullFollowersUsersList = [];
            state.fullFollowersUsersListMaxPage = 0;
            state.fullFollowersUsersListIndex = 1;
            state.fullFollowersUsersListPage = 0;
        },
        followersGetFullFollowersUsersListAttempt: (state, _action:PayloadAction<IGetFullFollowersUsersListReduxParam>) => {
            state.actions.getFullFollowersUsersList = true;
            state.error.getFullFollowersUsersList = '';
        },
        followersGetFullFollowersUsersListFailure: (state, action:PayloadAction<string>) => {
            state.actions.getFullFollowersUsersList = false;
            if (action.payload) state.error.getFullFollowersUsersList = action.payload;
        },
        followersGetFullFollowersUsersListSuccess: (state, action: PayloadAction<IGetFullFollowersUsersListResponse>) => {
            const { data, index, maxIndex } = action.payload;
            let indexExists = false;

            state.fullFollowersUsersListMaxPage = maxIndex;

            const massagedData = state.followersUsersList?.map(item => {
                if (item.index === index) {
                    indexExists = true;
                    return {
                        index,
                        data,
                    };
                } return item;
            });

            if (!indexExists) massagedData.push({ index, data });

            state.actions.getFullFollowersUsersList = false;
            state.fullFollowersUsersList = massagedData;
        },
        followersSetFullFollowersUsersListPage: (state, action: PayloadAction<number>) => {
            state.fullFollowersUsersListPage = action.payload;
        },
        followersSetFullFollowersUsersListIndex: (state, action: PayloadAction<number>) => {
            state.fullFollowersUsersListIndex = action.payload;
        },
    },
});

export type FollowersState = typeof initialState;

export default {
    actions: followers.actions,
    reducers: followers.reducer,
};
