import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { connect } from 'react-redux';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { AppDispatch, RootState } from 'redux/store';

import Fonts from 'assets/themes/Fonts';

import { StatusTypeEnum } from 'entities/broadcast';

import RecurringPostTable from 'components/broadcast/RecurringPostTable';
import EditRecurringBroadcastModal from 'components/broadcast/EditRecurringBroadcastModal';

interface RecurringPostProps {
    setEditRecurringBroadcastModalIsOpen(data: boolean): void;

    getBroadcastId: string;
    getEditRecurringBroadcastModalIsOpen: boolean;
}

const RecurringPost: FunctionComponent<RecurringPostProps> = (props: RecurringPostProps) => {
    const {
        setEditRecurringBroadcastModalIsOpen,

        getBroadcastId,
        getEditRecurringBroadcastModalIsOpen,
    } = props;

    dayjs.extend(utc);

    const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false);
    const [recurringStatus, setRecurringStatus] = useState<StatusTypeEnum>(StatusTypeEnum.All);
    const [filter, setFilter] = useState<string>('Show all');

    const toggleEditRecurringModal = (isBroadcast: boolean) => {
        if (isBroadcast) {
            setEditRecurringBroadcastModalIsOpen(true);
        }
    };

    useEffect(() => {
        renderRecurringPostTable();
    }, [recurringStatus]);

    const renderRecurringPostTable = () => {
        return (
            <>
                <EmptyContainerForHashLink />

                <div style={{ width: '100%', padding: '0px 20px 30px 20px', marginBottom: '20pxs' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <TableName>
                            <StyledText>Recurring Post</StyledText>
                        </TableName>
                        <DropdownContainer>
                            <Dropdown isOpen={dropdownIsOpen} toggle={() => setDropdownIsOpen(!dropdownIsOpen)}>
                                <DropdownToggle caret style={styles.filterButton}>
                                    <div style={{ paddingLeft: '10px', marginRight: '10px', overflowX: 'hidden' }}>
                                        {filter}
                                    </div>
                                </DropdownToggle>
                                <DropdownMenu container='body' className='filter-menu-item' style={{ width: '180px', textAlign: 'center' }}>
                                    <DropdownItem onClick={() => [setRecurringStatus(StatusTypeEnum.All), setFilter('Show all')]}>
                                        <StyledText style={{ fontSize: '18px', fontFamily: `${Fonts.secondary}` }}>Show all</StyledText>
                                    </DropdownItem>
                                    <DropdownItem onClick={() => [setRecurringStatus(StatusTypeEnum.Active), setFilter('Active only')]}>
                                        <StyledText style={{ fontSize: '18px', fontFamily: `${Fonts.secondary}` }}>Active Post</StyledText>
                                    </DropdownItem>
                                    <DropdownItem onClick={() => [setRecurringStatus(StatusTypeEnum.Inactive), setFilter('Inactive only')]}>
                                        <StyledText style={{ fontSize: '18px', fontFamily: `${Fonts.secondary}` }}>Inactive Post</StyledText>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </DropdownContainer>
                    </div>

                    <RecurringPostTable getStatus={recurringStatus} editRecurringPost={() => toggleEditRecurringModal(true)} />

                    {getEditRecurringBroadcastModalIsOpen && (
                        <EditRecurringBroadcastModal
                            getStatus={recurringStatus}
                            broadcastEditId={getBroadcastId}
                        />
                    )}
                </div>
            </>
        );
    };

    return (
        renderRecurringPostTable()
    );
};

const EmptyContainerForHashLink = styled.div`
    height: 55px;
`;

const TableName = styled.h1`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    gap: 3%;

    height: 1.8rem;
    line-height: 1.8rem;
    padding-top: 14px;

    font-size: 1.8rem;
    font-weight: 500;
    font-family: ${Fonts.primary};
`;

const StyledText = styled.text`
    font-size: 20px;
    color: #27242E;
    font-family: ${Fonts.primary};
    font-weight: bold;
`;

const DropdownContainer = styled.div`
    width: 180px;
`;

const styles = {
    filterButton: {
        backgroundColor: '#F1F6F8',
        fontSize: '18',
        fontFamily: 'Fonts.primary',
        color: 'black',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',

        width: '183px',
        height: '46px',

        border: '1px solid black',
        borderRadius: '20px',
        cursor: 'pointer',
    },
};

const mapStateToProps = (state: RootState) => ({
    getBroadcastId: Selectors.getBroadcastBroadcastId(state),
    getEditRecurringBroadcastModalIsOpen: Selectors.getBroadcastEditRecurringBroadcastModalIsOpen(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setEditRecurringBroadcastModalIsOpen: (data: boolean) => dispatch(Actions.setEditRecurringBroadcastModalIsOpen(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecurringPost);
