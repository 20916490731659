import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import PNCMessageGateway from 'api/PNCMessage';
import { toast } from 'react-toastify';

export default function* watchGetPNCMessage(api: PNCMessageGateway): SagaWatcherReturnType {
    yield takeEvery('pncMessage/getPNCMessageAttempt', handleGetPNCMessage, api);
}

function* handleGetPNCMessage(api: PNCMessageGateway) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.getPNCMessage], {
        authToken,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.getPNCMessageFailure(response.message));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.getPNCMessageSuccess(response.data));
    }
}
