import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import BroadcastGateway from 'api/Broadcast';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { toast } from 'react-toastify';
import { GetHistoryPostExportParams } from 'redux/slices/broadcast/types';
import xlsx, { IContent } from 'json-as-xlsx';
import dayjs from 'dayjs';

export default function* watchGetHistoryPostExportStatus(api: BroadcastGateway): SagaWatcherReturnType {
    yield takeEvery('broadcast/getHistoryPostExportAttempt', handleGetHistoryPostExport, api);
}

function* handleGetHistoryPostExport(api: BroadcastGateway, data: GetHistoryPostExportParams) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const enumToStringConverter = (value: number) => {
        switch (value) {
            case 1: return 'Immediate';
            case 2: return 'Scheduled';
            case 3: return 'Recurring';
            default: return '';
        }
    };

    const response = yield* call([api, api.getHistoryPostExport], {
        authToken,
        broadcastType: data.payload.type,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.getHistoryPostExportFailure(response.message || ''));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        const massagedData = response.data.map((item) => {
            const currentBroadcastType = enumToStringConverter(Number(item.broadcastType));

            return {
                title: item.title,
                message: item.message,
                numOfRecipients: item.numOfRecipients,
                broadcastType: currentBroadcastType,
                failedRecipients: item.failedRecipients,
                sentDate: dayjs(item.sentDate).format('DD-MMM-YYYY'),
                successRate: Number(((item.numOfRecipients / (item.numOfRecipients + item.failedRecipients)) * 100).toFixed(1)) || 0,
                revokedDate: item.revokedDate || '-',
            };
        });

        interface StringDictionary {
            [key: string]: any;
        }

        const excelColumns = [{
            label: 'Title',
            value: 'title',
        }, {
            label: 'Message',
            value: 'message',
        }, {
            label: 'Posted On',
            value: 'sentDate',
        }, {
            label: 'Revoked Date',
            value: 'revokedDate',
        }, {
            label: 'Category',
            value: 'broadcastType',
        }, {
            label: 'Total Success Recipients',
            value: 'numOfRecipients',
        }, {
            label: 'Total Failed Recipients',
            value: 'failedRecipients',
        }, {
            label: 'Success Rate (%)',
            value: 'successRate',
        }];

        let broadcastHistoryPostToDownloadToExport: IContent[] = [];
        broadcastHistoryPostToDownloadToExport = massagedData as unknown as StringDictionary[];

        const broadcastHistoryPostToDownload = [{
            sheet: 'Post Data',
            columns: excelColumns,
            content: broadcastHistoryPostToDownloadToExport,
        }];

        xlsx(broadcastHistoryPostToDownload, { fileName: `Broadcast History Data (${data.payload.type})` });

        yield put(Actions.getHistoryPostExportSuccess(response.data));
    }
}
