export enum LanguageEnum {
    English = 1,
    Melayu = 2,
}

export enum PerformingPostsFilter {
    Views = 0,
    Engagement = 1,
}

export enum CategoryIdEnum {
    All = 0,
    CategoryMotivation = 1,
    Acquisition = 2,
    Retention = 3,
    Promotions = 4,
    ProductInfo = 5,
    BrandedEngagement = 6,
    NonBrandedEngagement = 7,
    Recruitment = 8,
    ProductSuperiority = 9,
    Announcement = 10,
    DeviceCare = 11,
    Contest = 12,
    Greeting =13,
}

export enum PostReportClaimsEnum {
    PostedOn = 0,
    Category = 1,
    Views = 2,
    ViewRate = 3,
    Engagement = 4,
    EngagementRate = 5,
    Reactions = 6,
    Responses = 7,
    Clicks = 8,
    Language = 9,
    Replies = 10,
}
export enum PostDaysClaimsEnum {
    Day3 = 0,
    Day7 = 1,
    Day14 = 2,
    CustomDays = 3,
}
export enum PostTopLeastPerformingClaimsEnum {
    PostedOn = 0,
    Category = 1,
    Views = 2,
    ViewRate = 3,
    Engagement = 4,
    EngagementRate = 5,
}
export enum PostDetailsModalClaimsEnum {
    PostedOn = 0,
    Views = 1,
    ViewRate = 2,
    Engagement = 3,
    EngagementRate = 4,
}
export enum ReportEnum {
    PostReport = 0,
    PostDays = 1,
    PostTopLeastPerforming = 2,
    PostDetailsModal = 3,
}

export interface PostsOverview {
    totalPostsMonth: number;
    totalPostsYtd: number;
    months: {
        month: string,
        posts: number,
    }[]
}

export interface RateByCategories {
    category: string;
    avgViewRate: number;
    avgEngRate: number;
}

export interface RateByPostingTime {
    hour: string;
    avgViewRate: number;
    avgEngRate: number;
}

export interface RateByPost {
    week: string;
    numOfPosts: number;
    avgViewRate: number;
    avgEngRate: number;
    dateFrom: string;
    dateTo: string;
}

export interface InformationPostModalInfo {
    index: number;
    maxIndex: number;
    data: {
        postId: string;
        title: string;
        mediaUrl: string;
        date: string;
        views: number;
        viewRate: number;
        engagement: number;
        engagementRate: number;
    }[]
}

export interface RateByPostModalInfo {
    title: string;
}

export interface GraphExportInfo {
    postId: string;
    title: string;
    mediaUrl: string;
    date: string;
    views: number;
    viewRate: number;
    engagement: number;
    engagementRate: number;
}

export interface PostsReport {
    index: number;
    maxIndex: number;
    data: {
        postId: string;
        title: string;
        mediaUrl: string;
        date: string;
        postCategory: CategoryIdEnum;
        views: number;
        viewRate: number;
        engagement: number;
        engagementRate: number;
        reactions: number;
        replies: number;
        responses: number;
        clicks: number;
        language: LanguageEnum;
    }[]
}

export interface PostsReportExport {
    postId: string;
    title: string;
    mediaUrl: string;
    date: string;
    postCategory: string;
    views: number;
    viewRate: number;
    engagement: number;
    engagementRate: number;
    reactions: number;
    responses: number;
    clicks: number;
    language: LanguageEnum;
}

export interface EditPostsReport {
    postId: string;
    postCategory?: CategoryIdEnum;
    clicks?: number;
    language?: LanguageEnum;
}

export interface PostsReportWithAmountOfDays {
    index: number;
    maxIndex: number;
    data: {
        postId: string;
        title: string;
        date: Date;
        day3: {
            viewRate: number;
            engagementRate: number;
        }
        day7: {
            viewRate: number;
            engagementRate: number;
        }
        day14: {
            viewRate: number;
            engagementRate: number;
        }
        customDay: {
            viewRate: number;
            engagementRate: number;
        }
        mediaUrl: string;
    }[]
}

export interface PerformingPosts {
    top: {
        postId: string;
        title: string;
        mediaUrl: string;
        date: string;
        postCategory: CategoryIdEnum;
        views: number;
        viewRate: number;
        engagement: number;
        engagementRate: number;
    }[]
    least: {
        postId: string;
        title: string;
        mediaUrl: string;
        date: string;
        postCategory: CategoryIdEnum;
        views: number;
        viewRate: number;
        engagement: number;
        engagementRate: number;
    }[]
}

export interface UserClaims {
    userRemovedColumnClaims: {
        postReportClaims: number[];
        postDaysClaims: number[];
        postTopLeastPerformingClaims: number[];
        postDetailsModal: number[];
    }
}

export interface EditToggleColumns {
    reportEnum: ReportEnum;
    column: number;
    remove: boolean;
}
