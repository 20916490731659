import { fork } from 'redux-saga/effects';

import { RootSagaReturnType } from 'sagas/types';

import ECommerceGateway from 'api/ECommerce';

import watchGetPromotionalMessage from './getPromotionalMessage';
import watchSetPromotionalMessage from './setPromotionalMessage';

import watchGetWelcomeMessage from './getWelcomeMessage';
import watchSetWelcomeMessage from './setWelcomeMessage';

import watchGetProductListingMessage from './getProductListingMessage';
import watchSetProductListingMessage from './setProductListingMessage';

import watchGetProductCategory from './getProductCategory';
import watchCreateProductCategory from './createProductCategory';
import watchEditProductCategory from './editProductCategory';
import watchDeleteProductCategory from './deleteProductCategory';

import watchGetProductList from './getProductList';
import watchCreateProductList from './createProductList';

import watchGetProductDetails from './getProductDetails';
import watchEditProductDetails from './editProductDetails';
import watchDeleteProductDetails from './deleteProductDetails';

import watchCreateProductVariant from './createProductVariant';
import watchEditProductVariant from './editProductVariant';
import watchDeleteProductVariant from './deleteProductVariant';
import watchGetCTAButtonDetails from './getCTAButtonDetails';
import watchSetCTAButtonDetails from './setCTAButtonDetails';

export default (api: ECommerceGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetPromotionalMessage, api);
        yield fork(watchSetPromotionalMessage, api);

        yield fork(watchGetWelcomeMessage, api);
        yield fork(watchSetWelcomeMessage, api);

        yield fork(watchGetProductListingMessage, api);
        yield fork(watchSetProductListingMessage, api);

        yield fork(watchGetProductCategory, api);
        yield fork(watchCreateProductCategory, api);
        yield fork(watchEditProductCategory, api);
        yield fork(watchDeleteProductCategory, api);

        yield fork(watchGetProductList, api);
        yield fork(watchCreateProductList, api);

        yield fork(watchGetProductDetails, api);
        yield fork(watchEditProductDetails, api);
        yield fork(watchDeleteProductDetails, api);

        yield fork(watchCreateProductVariant, api);
        yield fork(watchEditProductVariant, api);
        yield fork(watchDeleteProductVariant, api);

        yield fork(watchGetCTAButtonDetails, api);
        yield fork(watchSetCTAButtonDetails, api);
    }

    return {
        rootSaga,
    };
};
