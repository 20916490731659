import { fork } from 'redux-saga/effects';

import { RootSagaReturnType } from 'sagas/types';

import SummaryDashboardGateway from 'api/SummaryDashboard';
import watchGetSummaryDashboardDetailsStatus from './getSummaryDashboardDetails';
import watchGetSubscribersOverviewStatus from './getSubscribersOverview';

export default (api: SummaryDashboardGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetSummaryDashboardDetailsStatus, api);
        yield fork(watchGetSubscribersOverviewStatus, api);
    }

    return {
        rootSaga,
    };
};
