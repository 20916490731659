import { put, call, select, takeEvery } from 'typed-redux-saga/macro';
import dayjs from 'dayjs';
import xlsx, { IContent } from 'json-as-xlsx';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import FollowersGateway from 'api/Followers';
import { IChannelTypeEnum } from 'entities/contests';

import SpreadSheetColumns from 'lib/SpreadSheetColumns';
import { IExportFullFollowersListToExcelAPIParam } from 'entities/followers';
import { PayloadAction } from '@reduxjs/toolkit';

export default function* watchExportFullFollowersListToExcel(api: FollowersGateway): SagaWatcherReturnType {
    yield takeEvery('followers/followersExportFullFollowersListToExcelAttempt', handleExportFullFollowersListToExcel, api);
}

function* handleExportFullFollowersListToExcel(api: FollowersGateway, data:PayloadAction<IExportFullFollowersListToExcelAPIParam>) {
    const { dateFrom, dateTo } = data.payload;

    const authToken = yield* select(Selectors.getAuthAuthToken);

    const isLanu = yield* select(Selectors.getFaqGetIsLANU);
    const channelType = isLanu ? IChannelTypeEnum.LANU : IChannelTypeEnum.LAU;
    const channelName = isLanu ? 'LANU' : 'LAU';

    let fileName:string;
    let response;
    if (dateFrom && dateTo) {
        response = yield* call([api, api.exportFullFollowersListToExcel], { authToken, channelType, dateFrom, dateTo });
        fileName = `Followers - ${channelName} - (${dayjs(dateFrom).format('DD-MM-YY')}-${dayjs(dateTo).format('DD-MM-YY')})`;
    } else {
        response = yield* call([api, api.exportFullFollowersListToExcel], { authToken, channelType });
        fileName = `AllFollowers - ${channelName} - (${dayjs().format('DD-MM-YY')})`;
    }

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.followersExportFullFollowersListToExcelFailure(response.message || 'Failed to export'));
        return;
    }

    if (response.status === GatewayResponseStatus.Success) {
        const { FullFollowersData } = SpreadSheetColumns;

        const parsedFullFollowersData = response.data.map(item => {
            const { dateJoined, hasBlockedBot } = item;

            return {
                ...item,
                dateJoined: dayjs(dateJoined).format('DD-MMM-YYYY') || '-',
                hasBlockedBot: hasBlockedBot ? 'blocked' : '',
            };
        });

        const dataToExport = [
            {
                sheet: 'Full Followers List',
                columns: FullFollowersData,
                content: parsedFullFollowersData as IContent[],
            },
        ];

        xlsx(dataToExport, { fileName });

        yield put(Actions.followersExportFullFollowersListToExcelSuccess());
    }
}
