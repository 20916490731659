import { CategoryIdEnum, EditPostsReport, EditToggleColumns, GraphExportInfo, InformationPostModalInfo, PerformingPosts, PostsOverview, PostsReport, PostsReportExport, PostsReportWithAmountOfDays, RateByCategories, RateByPost, RateByPostingTime, UserClaims } from 'entities/posts';
import { SummaryDashboardDetails } from 'entities/summaryDashboard';
import { PostsState } from '.';

const getPostsOverviewAttempting = (state: PostsState): boolean => state.actions.postsOverview || false;
const getPostsOverviewFailure = (state: PostsState): string => state.error.postsOverview || '';
const getPostsOverview = (state: PostsState): PostsOverview => state.postsOverviewData;

const getRateByCategoriesAttempting = (state: PostsState): boolean => state.actions.rateByCategories || false;
const getRateByCategoriesFailure = (state: PostsState): string => state.error.rateByCategories || '';
const getRateByCategories = (state: PostsState): RateByCategories[] => state.rateByCategoriesData;

const getRateByCategoriesModalInfoAttempting = (state: PostsState): boolean => state.actions.rateByCategoriesModalInfo || false;
const getRateByCategoriesModalInfoFailure = (state: PostsState): string => state.error.rateByCategoriesModalInfo || '';
const getRateByCategoriesModalInfo = (state: PostsState): InformationPostModalInfo => state.rateByCategoriesModalInfoData;

const getRateByCategoriesExportAttempting = (state: PostsState): boolean => state.actions.rateByCategoriesExport || false;
const getRateByCategoriesExportFailure = (state: PostsState): string => state.error.rateByCategoriesExport || '';
const getRateByCategoriesExport = (state: PostsState): GraphExportInfo[] => state.rateByCategoriesExportData;

const getRateByPostingTimeAttempting = (state: PostsState): boolean => state.actions.rateByPostingTime || false;
const getRateByPostingTimeFailure = (state: PostsState): string => state.error.rateByPostingTime || '';
const getRateByPostingTime = (state: PostsState): RateByPostingTime[] => state.rateByPostingTimeData;

const getRateByPostingTimeModalInfoAttempting = (state: PostsState): boolean => state.actions.rateByPostingTimeModalInfo || false;
const getRateByPostingTimeModalInfoFailure = (state: PostsState): string => state.error.rateByPostingTimeModalInfo || '';
const getRateByPostingTimeModalInfo = (state: PostsState): InformationPostModalInfo => state.rateByPostingTimeModalInfoData;

const getRateByPostingTimeExportAttempting = (state: PostsState): boolean => state.actions.rateByPostingTimeExport || false;
const getRateByPostingTimeExportFailure = (state: PostsState): string => state.error.rateByPostingTimeExport || '';
const getRateByPostingTimeExport = (state: PostsState): GraphExportInfo[] => state.rateByPostingTimeExportData;

const getRateByPostAttempting = (state: PostsState): boolean => state.actions.rateByPost || false;
const getRateByPostFailure = (state: PostsState): string => state.error.rateByPost || '';
const getRateByPost = (state: PostsState): RateByPost[] => state.rateByPostData;

const getRateByPostModalInfoAttempting = (state: PostsState): boolean => state.actions.rateByPostModalInfo || false;
const getRateByPostModalInfoFailure = (state: PostsState): string => state.error.rateByPostModalInfo || '';
const getRateByPostModalInfo = (state: PostsState): InformationPostModalInfo => state.rateByPostModalInfoData;

const getRateByPostExportAttempting = (state: PostsState): boolean => state.actions.rateByPostExport || false;
const getRateByPostExportFailure = (state: PostsState): string => state.error.rateByPostExport || '';
const getRateByPostExport = (state: PostsState): GraphExportInfo[] => state.rateByPostModalExportData;

const getPostsReportAttempting = (state: PostsState): boolean => state.actions.postsReport || false;
const getPostsReportFailure = (state: PostsState): string => state.error.postsReport || '';
const getPostsReport = (state: PostsState): PostsReport => state.postsReportData;

const getPostsReportExportAttempting = (state: PostsState): boolean => state.actions.postsReportExport || false;
const getPostsReportExportFailure = (state: PostsState): string => state.error.postsReportExport || '';
const getPostsReportExport = (state: PostsState): PostsReportExport[] => state.postsReportExportData;

const editPostsReportAttempting = (state: PostsState): boolean => state.actions.editPostsReport || false;
const editPostsReportFailure = (state: PostsState): string => state.error.editPostsReport || '';
const editPostsReport = (state: PostsState): EditPostsReport => state.editPostsReportData;

const getPostsReportWithAmountOfDaysAttempting = (state: PostsState): boolean => state.actions.postsReportWithAmountOfDays || false;
const getPostsReportWithAmountOfDaysFailure = (state: PostsState): string => state.error.postsReportWithAmountOfDays || '';
const getPostsReportWithAmountOfDays = (state: PostsState): PostsReportWithAmountOfDays => state.postsReportWithAmountOfDaysData;

const getPerformingPostsAttempting = (state: PostsState): boolean => state.actions.performingPost || false;
const getPerformingPostsFailure = (state: PostsState): string => state.error.performingPost || '';
const getPerformingPosts = (state: PostsState): PerformingPosts => state.performingPostsData;

const getUserClaimsAttempting = (state: PostsState): boolean => state.actions.userClaims || false;
const getUserClaimsFailure = (state: PostsState): string => state.error.userClaims || '';
const getUserClaims = (state: PostsState): UserClaims => state.userClaimsData;

const editToggleColumnAttempting = (state: PostsState): boolean => state.actions.editToggleColumn || false;
const editToggleColumnFailure = (state: PostsState): string => state.error.editToggleColumn || '';
const editToggleColumn = (state: PostsState): EditToggleColumns => state.editToggleColumnData;

const getDateFrom = (state: PostsState): Date => state.dateFrom || new Date(new Date().setDate(new Date().getDate() - 30));
const getDateTo = (state: PostsState): Date => state.dateTo || new Date();

const getInformationPostModalIsOpen = (state: PostsState): boolean => state.informationPostModalIsOpen || false;
const getInformationPostIndex = (state: PostsState): number => state.informationPostIndex || 1;

const getPostsReportIndex = (state: PostsState): number => state.postsReportIndex || 1;
const getPostsReportWithAmountOfDaysIndex = (state: PostsState): number => state.postsReportWithAmountOfDaysIndex || 1;

const getCurrentGraphCategory = (state: PostsState): string | undefined => state.currentGraphCategory || '';
const getCurrentGraphWeek = (state: PostsState): string | undefined => state.currentGraphWeek || '';
const getCurrentGraphHour = (state: PostsState): string | undefined => state.currentGraphHour || '';

const getSelectedCategory = (state: PostsState): number => state.selectedCategory;

const getUserClaimsRedux = (state: PostsState): UserClaims => state.userClaimsRedux;

const getSummaryDashboardDetailsAttempt = (state: PostsState): boolean => state.actions.summaryDashboardDetails || false;
const getSummaryDashboardDetailsFalure = (state: PostsState): string => state.error.summaryDashboardDetails || '';
const getSummaryDashboardDetails = (state: PostsState): SummaryDashboardDetails => state.summaryDashboardDetailsData;

export default {
    getPostsOverviewAttempting,
    getPostsOverviewFailure,
    getPostsOverview,

    getRateByCategoriesAttempting,
    getRateByCategoriesFailure,
    getRateByCategories,

    getRateByCategoriesModalInfoAttempting,
    getRateByCategoriesModalInfoFailure,
    getRateByCategoriesModalInfo,

    getRateByCategoriesExportAttempting,
    getRateByCategoriesExportFailure,
    getRateByCategoriesExport,

    getRateByPostingTimeAttempting,
    getRateByPostingTimeFailure,
    getRateByPostingTime,

    getRateByPostingTimeModalInfoAttempting,
    getRateByPostingTimeModalInfoFailure,
    getRateByPostingTimeModalInfo,

    getRateByPostingTimeExportAttempting,
    getRateByPostingTimeExportFailure,
    getRateByPostingTimeExport,

    getRateByPostAttempting,
    getRateByPostFailure,
    getRateByPost,

    getRateByPostModalInfoAttempting,
    getRateByPostModalInfoFailure,
    getRateByPostModalInfo,

    getRateByPostExportAttempting,
    getRateByPostExportFailure,
    getRateByPostExport,

    getPostsReportAttempting,
    getPostsReportFailure,
    getPostsReport,

    getPostsReportExportAttempting,
    getPostsReportExportFailure,
    getPostsReportExport,

    editPostsReportAttempting,
    editPostsReportFailure,
    editPostsReport,

    getPostsReportWithAmountOfDaysAttempting,
    getPostsReportWithAmountOfDaysFailure,
    getPostsReportWithAmountOfDays,

    getPerformingPostsAttempting,
    getPerformingPostsFailure,
    getPerformingPosts,

    getUserClaimsAttempting,
    getUserClaimsFailure,
    getUserClaims,

    editToggleColumnAttempting,
    editToggleColumnFailure,
    editToggleColumn,

    getDateFrom,
    getDateTo,

    getInformationPostModalIsOpen,
    getInformationPostIndex,

    getPostsReportIndex,
    getPostsReportWithAmountOfDaysIndex,

    getCurrentGraphCategory,
    getCurrentGraphWeek,
    getCurrentGraphHour,

    getUserClaimsRedux,
    getSelectedCategory,

    getSummaryDashboardDetailsAttempt,
    getSummaryDashboardDetailsFalure,
    getSummaryDashboardDetails,
};
