import { ECommActivitiesReportData, ECommBuyNowClicksProductReportData, ECommBuyNowClicksReportData, ECommBuyNowClicksVariantReportData, ECommClicksProductReportData, ECommClicksReportData, ECommClicksVariantReportData, ECommFunnelReportData } from 'entities/ecommerceAnalytics';
import { ECommerceAnalyticsState } from '.';

const getUiDatePicker = (state: ECommerceAnalyticsState): { dateFrom: Date, dateTo: Date} => state.ui.datePicker;

const getClicksReportAttempting = (state: ECommerceAnalyticsState): boolean => state.actions.getClicksReport;
const getClicksReportError = (state: ECommerceAnalyticsState): string | null => state.error.getClicksReport;
const getClicksReportData = (state: ECommerceAnalyticsState): ECommClicksReportData | null => state.reports.clicksReport;

const getClicksProductReportAttempting = (state: ECommerceAnalyticsState): boolean => state.actions.getClicksProductReport;
const getClicksProductReportError = (state: ECommerceAnalyticsState): string | null => state.error.getClicksProductReport;
const getClicksProductReportData = (state: ECommerceAnalyticsState): ECommClicksProductReportData | null => state.reports.clicksProductReport;

const getClicksVariantReportAttempting = (state: ECommerceAnalyticsState): boolean => state.actions.getClicksVariantReport;
const getClicksVariantReportError = (state: ECommerceAnalyticsState): string | null => state.error.getClicksVariantReport;
const getClicksVariantReportData = (state: ECommerceAnalyticsState): ECommClicksVariantReportData[] | null => state.reports.clicksVariantReport;

const getBuyNowClicksReportAttempting = (state: ECommerceAnalyticsState): boolean => state.actions.getBuyNowClicksReport;
const getBuyNowClicksReportError = (state: ECommerceAnalyticsState): string | null => state.error.getBuyNowClicksReport;
const getBuyNowClicksReportData = (state: ECommerceAnalyticsState): ECommBuyNowClicksReportData | null => state.reports.buyNowClicksReport;

const getBuyNowClicksProductReportAttempting = (state: ECommerceAnalyticsState): boolean => state.actions.getBuyNowProductReport;
const getBuyNowClicksProductReportError = (state: ECommerceAnalyticsState): string | null => state.error.getBuyNowProductReport;
const getBuyNowClicksProductReportData = (state: ECommerceAnalyticsState): ECommBuyNowClicksProductReportData | null => state.reports.buyNowClicksProductReport;

const getBuyNowClicksVariantReportAttempting = (state: ECommerceAnalyticsState): boolean => state.actions.getBuyNowProductReport;
const getBuyNowClicksVariantReportError = (state: ECommerceAnalyticsState): string | null => state.error.getBuyNowProductReport;
const getBuyNowClicksVariantReportData = (state: ECommerceAnalyticsState): ECommBuyNowClicksVariantReportData[] | null => state.reports.buyNowClicksVariantReport;

const getActivitiesReportAttempting = (state: ECommerceAnalyticsState): boolean => state.actions.getActivitiesReport;
const getActivitiesReportError = (state: ECommerceAnalyticsState): string | null => state.error.getActivitiesReport;
const getActivitiesReportData = (state: ECommerceAnalyticsState): ECommActivitiesReportData | null => state.reports.activitiesReport;

const getFunnelReportAttempting = (state: ECommerceAnalyticsState): boolean => state.actions.getFunnelReport;
const getFunnelReportError = (state: ECommerceAnalyticsState): string | null => state.error.getFunnelReport;
const getFunnelReportData = (state: ECommerceAnalyticsState): ECommFunnelReportData | null => state.reports.funnelReport;

export default {
    getUiDatePicker,

    getClicksReportAttempting,
    getClicksReportError,
    getClicksReportData,

    getClicksProductReportAttempting,
    getClicksProductReportError,
    getClicksProductReportData,

    getClicksVariantReportAttempting,
    getClicksVariantReportError,
    getClicksVariantReportData,

    getBuyNowClicksReportAttempting,
    getBuyNowClicksReportError,
    getBuyNowClicksReportData,

    getBuyNowClicksProductReportAttempting,
    getBuyNowClicksProductReportError,
    getBuyNowClicksProductReportData,

    getBuyNowClicksVariantReportAttempting,
    getBuyNowClicksVariantReportError,
    getBuyNowClicksVariantReportData,

    getActivitiesReportAttempting,
    getActivitiesReportError,
    getActivitiesReportData,

    getFunnelReportAttempting,
    getFunnelReportError,
    getFunnelReportData,
};
