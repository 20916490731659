import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { GatewayResponseStatus } from 'api/types/types';
import GamesGateway from 'api/Games';

import { DeleteGamePayload } from 'redux/slices/games/types';

import { toast } from 'react-toastify';
import { ContestListTypesEnum, IChannelTypeEnum } from 'entities/contests';

export default function* watchDeleteGame(api: GamesGateway): SagaWatcherReturnType {
    yield takeEvery('games/deleteGameAttempting', handleDeleteGame, api);
}

function* handleDeleteGame(api: GamesGateway, data: DeleteGamePayload) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const isLanu = yield* select(Selectors.getFaqGetIsLANU);

    const response = yield* call([api, api.deleteGame], {
        data: { id: data.payload.id },
        authToken,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.deleteGameFailure(response.message));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        toast.success('Game deleted successfully');

        yield put(Actions.deleteGameSuccess());

        yield put(Actions.setActiveGamesListIndex(1));
        yield put(Actions.setInactiveGamesListIndex(1));
        const activeGameIndex = yield* select(Selectors.contestGetActiveGamesListIndex);
        const inactiveGameIndex = yield* select(Selectors.contestGetInactiveGamesListIndex);
        yield put(Actions.getActiveGamesListAttempt({ index: activeGameIndex }));
        yield put(Actions.getInactiveGamesListAttempt({ index: inactiveGameIndex }));
        yield put(Actions.contestSetDeleteQuizModalState({ id: '', isOpen: false, type: ContestListTypesEnum.Game }));
    }
}
