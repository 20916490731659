import { fork } from 'redux-saga/effects';

import ContestsGateway from 'api/Contests';

import { RootSagaReturnType } from 'sagas/types';

import watchGetContests from './getContests';
import watchCreateContest from './createContest';
import watchDeleteContest from './deleteContest';

import watchGetContestDetails from './getContestDetails';
import watchGetContestResponses from './getContestResponses';
import watchContestExportResponses from './exportResponses';
import watchEditContestDetails from './editContestDetails';
import watchGetActiveGamesList from './getActiveGamesList';
import watchGetActiveQuizList from './getActiveQuizList';
import watchGetInactiveGameList from './getInactiveGamesList';
import watchGetInactiveQuizList from './getInactiveQuizList';

export default (api: ContestsGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetContests, api);
        yield fork(watchCreateContest, api);
        yield fork(watchDeleteContest, api);
        yield fork(watchGetContestDetails, api);
        yield fork(watchGetContestResponses, api);
        yield fork(watchContestExportResponses, api);
        yield fork(watchEditContestDetails, api);

        yield fork(watchGetActiveGamesList, api);
        yield fork(watchGetActiveQuizList, api);
        yield fork(watchGetInactiveGameList, api);
        yield fork(watchGetInactiveQuizList, api);
    }

    return {
        rootSaga,
    };
};
