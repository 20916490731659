import React from 'react';
import { Row, Col, FormGroup, Spinner } from 'reactstrap';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import { connect } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

import { RootState, AppDispatch } from 'redux/store';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';

import Flex from 'components/Flex';
import IQOSModal from 'components/IQOSModal';
import IconTooltip from 'components/IconTooltip';
import ToggleSwitch from 'components/ToggleSwitch';

import Icons from 'assets/icons/Index';
import Colors from 'assets/themes/Colors';

import Utils from 'lib/Utils';

import { ContestListTypesEnum, IChannelTypeEnum, IEditQuizModalState } from 'entities/contests';
import { IEditGameParams, IGameDetails, IGameStatusEnum } from 'entities/games';

interface EditQuizModalProps {
    isLanu: boolean;
    modalState: IEditQuizModalState;
    editGameIsLoading: boolean;
    editGameError: string;
    gameDetails: IGameDetails;
    gameDetailsIsLoading: boolean;
    gameDetailsError: string;

    getGameDetails: (id: string, type: IChannelTypeEnum) => void;
    editGame: (param:IEditGameParams) => void;
    changeModalState: (param: IEditQuizModalState) => void;
}

const EditGameSchema = z.object({
    id: z.string(),
    name: z
        .string()
        .trim()
        .min(5, 'Minimum \'Contest Name\' length is 5'),
    status: z.nativeEnum(IGameStatusEnum),
});

export type EditGameType = z.infer<typeof EditGameSchema>;

const EditGameModal:React.FunctionComponent<EditQuizModalProps> = ({
    isLanu,
    modalState,
    editGameIsLoading,
    editGameError,
    gameDetails,

    gameDetailsIsLoading,
    gameDetailsError,

    getGameDetails,
    editGame,
    changeModalState,
}:EditQuizModalProps) => {
    const [isConfirmed, setIsConfirmed] = React.useState<boolean>(false);
    const [formData, setFormData] = React.useState<EditGameType>({
        id: gameDetails.id,
        name: gameDetails.name,
        status: gameDetails.status ? IGameStatusEnum.Active : IGameStatusEnum.Inactive,
    });

    const {
        register,
        handleSubmit,
        reset,
        watch,
        setValue,
        control,
        formState: { errors, isValid, isDirty },
    } = useForm<EditGameType>({
        resolver: zodResolver(EditGameSchema),
        defaultValues: formData,
    });

    React.useEffect(() => {
        if (modalState.isOpen === true && modalState.type === ContestListTypesEnum.Game) {
            reset(formData);
            getGameDetails(modalState.id, isLanu ? IChannelTypeEnum.LANU : IChannelTypeEnum.LAU);
        }

        if (isConfirmed) {
            setIsConfirmed(false);
        }
    }, [modalState.isOpen]);

    React.useEffect(() => {
        if (gameDetails) {
            setValue('id', gameDetails.id);
            setValue('name', gameDetails.name);
            setValue('status', gameDetails.status ? IGameStatusEnum.Active : IGameStatusEnum.Inactive);
        }
    }, [gameDetails]);

    const toggle = () => {
        changeModalState({
            isOpen: !modalState.isOpen,
            type: modalState.type,
            id: modalState.id,
        });
    };

    const onSubmit = handleSubmit((data) => {
        if (isValid && isDirty) {
            setIsConfirmed(true);
            setFormData(data);
        }
    });

    const cancelSubmit = () => {
        setIsConfirmed(false);
    };

    const checkStatus = (status:IGameStatusEnum) => {
        if (status === IGameStatusEnum.Active) return true;
        return false;
    };

    const convertStatus = (status : boolean) => {
        if (!status) return IGameStatusEnum.Inactive;
        return IGameStatusEnum.Active;
    };

    const header = isConfirmed
        ? `Confirm to edit '${gameDetails.name}' contest?`
        : `Edit ${Utils.ModifyString.capitalizeFirstLetter(ContestListTypesEnum.Game)} - '${gameDetails.name}'`;

    return (
        <IQOSModal
            header={header}
            isOpen={modalState.isOpen}
            toggle={toggle}
            size={isConfirmed ? 'sm' : 'lg'}
        >
            {!isConfirmed ? (
                <form onSubmit={onSubmit} autoComplete='off'>
                    <Row>
                        <StyledCol>
                            <FormGroup>
                                <StyledLabel>
                                    Contest Name
                                </StyledLabel>
                                <StyledInput error={errors.name && true} {...register('name', { valueAsNumber: false })} />
                            </FormGroup>
                        </StyledCol>
                    </Row>
                    <Row>
                        <StyledCol>
                            <FormGroup>
                                <Flex alignItems='center' justifyContent='flex-start' gap='0.3rem' margin='0 0 0.3rem 0'>
                                    <div>Enable/Disable Quiz</div>
                                    <IconTooltip
                                        tooltipId='quizStatus'
                                        iconSrc={Icons.QuestionMark}
                                        bgColor={Colors.iqosPrimary}
                                        fontColor={Colors.black}
                                    >
                                        When disabled, users will not be able to participate in the game.
                                    </IconTooltip>
                                </Flex>
                                <Controller
                                    control={control}
                                    name='status'
                                    render={({ field: { value, onChange } }) => (
                                        <ToggleSwitch
                                            isEnabled={checkStatus(value)}
                                            onClick={() => onChange(convertStatus(!value))}
                                        />
                                    )}
                                />
                            </FormGroup>
                        </StyledCol>
                    </Row>
                    <Flex gap='1rem' margin='1rem 0 0 0'>
                        <SubmitButton type='submit' value='Submit' disabled={!isDirty} />
                        <StyledButton type='button' onClick={() => toggle()}>Cancel</StyledButton>
                    </Flex>
                    {(errors.name) && (
                        <Flex flexDirection='column' padding='1rem 0 0 0' gap='1rem'>
                            {errors.name && <StyledError>{errors.name.message}</StyledError>}
                        </Flex>
                    )}
                </form>
            ) : (
                <Flex justifyContent='center' flexWrap='wrap' flexDirection='column' gap='1rem'>
                    {editGameError.length > 1 && (<StyledError>{editGameError}</StyledError>)}
                    <Flex gap='1rem'>
                        <StyledPrimaryButton
                            type='button'
                            onClick={
                                () => editGame(formData)
                            }
                        >
                            {editGameIsLoading ? <Spinner size='sm' /> : 'Save' }
                        </StyledPrimaryButton>
                        <StyledButton type='button' onClick={() => cancelSubmit()}>Cancel</StyledButton>
                    </Flex>
                </Flex>
            )}
        </IQOSModal>

    );
};

interface StyledCSSProps {
    css?: FlattenSimpleInterpolation;
}

const SubmitButton = styled.input<StyledCSSProps>`
    background-color: ${Colors.iqosPrimary};
    border: 1px solid ${Colors.iqosPrimary};
    color: ${Colors.white};
    padding: 0.5rem 1.6rem;
    border-radius:24px;
    min-width:90px;  

    &:disabled {
        background-color: ${Colors.lightGrey};
        border-color: ${Colors.lightGrey};
    }

    ${props => props.css}
`;

const StyledPrimaryButton = styled.button<StyledCSSProps>`
    background-color: ${Colors.iqosPrimary};
    border: 1px solid ${Colors.iqosPrimary};
    color: ${Colors.white};
    padding: 0.5rem 1.6rem;
    border-radius:24px;
    min-width:90px; 
`;

const StyledButton = styled.button<StyledCSSProps>`
    background-color: ${Colors.white};
    border: 1px solid ${Colors.iqosPrimary};
    padding: 0.5rem 1.6rem;
    border-radius:24px;
    min-width:90px;
    color: ${Colors.iqosPrimary};
    ${props => props.css}
`;

const StyledLabel = styled.div`
    margin-bottom: 0.3rem;
`;

const StyledCol = styled(Col)`
    color: ${Colors.lightGrey}FF;
`;

interface StyledInputProps {
    error?: boolean
}

const StyledInput = styled.input<StyledInputProps>`
    background-color: #F7F7F7;
    border-color: transparent;

    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-clip: padding-box;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    ${props => props.error && (`border: 1px solid ${Colors.red};`)}

    &:focus {
        background-color: #F7F7F7;
        ${props => props.error && (`border: 1px solid ${Colors.red};`
    )}
        outline:none;
    }
`;

const StyledError = styled.div`
    color: ${Colors.red};
`;

const mapStateToProps = (state: RootState) => ({
    isLanu: Selectors.getFaqGetIsLANU(state),

    modalState: Selectors.contestGetEditContestModalState(state),

    editGameIsLoading: Selectors.getEditGameAttempting(state),
    editGameError: Selectors.getEditGameFailure(state),

    gameDetails: Selectors.getGameDetails(state),
    gameDetailsIsLoading: Selectors.getGameDetailsAttempting(state),
    gameDetailsError: Selectors.getGameDetailsFailure(state),

});

const mapDispatchToProps = (dispatch: AppDispatch) => ({

    getGameDetails: (id: string, type: IChannelTypeEnum) => dispatch(Actions.getGameDetailsAttempting({ id, type })),

    editGame: (param: IEditGameParams) => dispatch(Actions.editGameAttempting(param)),

    changeModalState: (param: IEditQuizModalState) => dispatch(Actions.contestSetEditContestModalState(param)),

});

export default connect(mapStateToProps, mapDispatchToProps)(EditGameModal);
