import { put, call, select, takeEvery } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import PostsGateway from 'api/Posts';
import { PostsOverviewPayloadParams } from 'redux/slices/posts/types';

export default function* watchGetPostsOverview(api: PostsGateway): SagaWatcherReturnType {
    yield takeEvery('posts/getPostsOverviewAttempt', handleGetPostsOverview, api);
}

function* handleGetPostsOverview(api: PostsGateway, data: PostsOverviewPayloadParams) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.getPostsOverview], {
        authToken,
        channelType: data.payload.channelType,
        month: data.payload.month,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.getPostsOverviewFailure(response.message || ''));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.getPostsOverviewSuccess(response.data));
    }
}
