import React from 'react';

import dayjs from 'dayjs';

import { ECommClicksReportData } from 'entities/ecommerceAnalytics';

import Subtitle from 'components/Subtitle';
import GraphWithAverage, { GraphData } from 'components/analytics/common/GraphWithAverage';

interface ClicksReportGraphsProps {
    data: ECommClicksReportData | null;
}

const ClicksReportGraphs = (props: ClicksReportGraphsProps): JSX.Element | null => {
    const { data } = props;

    if (!data) return null;

    const renderMonthlyGraph = () => {
        const { averageClicks, averageUniqueClicks, data: monthlyData } = data.graphs.month;

        const clicksData = monthlyData.map((item) => ({
            label: dayjs().month(item.month - 1).format('MMM'),
            value: item.clicks,
            color: '#00D1D2',
        }));

        const uniqueClicksData = monthlyData.map((item) => ({
            label: dayjs().month(item.month - 1).format('MMM'),
            value: item.uniqueClicks,
            color: '#DB3624',
        }));

        const averageData = [{
            label: 'Average clicks',
            color: '#00D1D2',
            value: averageClicks,
        }, {
            label: 'Average unique clicks',
            color: '#DB3624',
            value: averageUniqueClicks,
        }];

        return (
            <div>
                <Subtitle>
                    Number of Clicks Across Months
                </Subtitle>
                <GraphWithAverage
                    data={{
                        dataName: 'Clicks',
                        data: clicksData,
                    }}
                    secondData={{
                        dataName: 'Unique clicks',
                        data: uniqueClicksData,
                    }}
                    averageData={averageData}
                />
            </div>
        );
    };

    const renderWeeklyGraph = () => {
        let clicksData: GraphData[] = [];
        let uniqueClicksData: GraphData[] = [];
        let averageData: GraphData[] = [];

        if (data.graphs.week) {
            const { averageClicks, averageUniqueClicks, data: weeklyData } = data.graphs.week;

            const prepareWeekString = (dateFrom: string, dateTo: string): string => {
                return `${dayjs(dateFrom).format('D-M-YY')} to ${dayjs(dateTo).format('D-M-YY')}`;
            };

            clicksData = weeklyData.map((item) => ({
                label: prepareWeekString(item.weekFrom, item.weekTo),
                value: item.clicks,
                color: '#00D1D2',
            }));

            uniqueClicksData = weeklyData.map((item) => ({
                label: prepareWeekString(item.weekFrom, item.weekTo),
                value: item.uniqueClicks,
                color: '#DB3624',
            }));

            averageData = [{
                label: 'Average clicks',
                color: '#00D1D2',
                value: averageClicks,
            }, {
                label: 'Average unique clicks',
                color: '#DB3624',
                value: averageUniqueClicks,
            }];
        }

        return (
            <div>
                <Subtitle>
                    Number of Clicks Across Weeks
                </Subtitle>
                <GraphWithAverage
                    data={{
                        dataName: 'Clicks',
                        data: clicksData,
                    }}
                    secondData={{
                        dataName: 'Unique clicks',
                        data: uniqueClicksData,
                    }}
                    averageData={averageData}
                />
            </div>
        );
    };

    const renderDayGraph = () => {
        const { averageClicks, averageUniqueClicks, data: dayData } = data.graphs.day;

        const prepareWeekString = (date: number): string => {
            return dayjs().day(date).format('ddd');
        };

        const clicksData = dayData.map((item) => ({
            label: prepareWeekString(item.day),
            value: item.clicks,
            color: '#00D1D2',
        }));

        const uniqueClicksData = dayData.map((item) => ({
            label: prepareWeekString(item.day),
            value: item.uniqueClicks,
            color: '#DB3624',
        }));

        const averageData = [{
            label: 'Average clicks',
            color: '#00D1D2',
            value: averageClicks,
        }, {
            label: 'Average unique clicks',
            color: '#DB3624',
            value: averageUniqueClicks,
        }];

        return (
            <div>
                <Subtitle>
                    Number of Clicks Across Days
                </Subtitle>
                <GraphWithAverage
                    data={{
                        dataName: 'Clicks',
                        data: clicksData,
                    }}
                    secondData={{
                        dataName: 'Unique clicks',
                        data: uniqueClicksData,
                    }}
                    averageData={averageData}
                />
            </div>
        );
    };

    return (
        <>
            {renderMonthlyGraph()}
            {renderWeeklyGraph()}
            {renderDayGraph()}
        </>
    );
};

export default ClicksReportGraphs;
