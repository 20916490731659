import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BroadcastHistoryPost, RecurringPost, SchedulePost, ICreateBroadcastPost, IEditBroadcastPost, BroadcastTypesEnum, RecipientListEnum, BroadcastOverview, HistoryDetails, StatusTypeEnum, BroadcastHistoryPostExport, HistoryDetailsExport, IBroadcastMediaUploadStatus } from 'entities/broadcast';
import { BroadcastReduxState, GetBroadcastOverviewParams, GetHistoryDetailsParams, GetHistoryPostParams, GetRecurringPostParams, GetSchedulePostParams, RecallBroadcastMessageParams, GetHistoryPostExportParams, GetHistoryDetailsExportParams } from './types';

const initialState: BroadcastReduxState = {
    actions: {
        broadcastOverview: false,
        recurringPost: false,
        schedulePost: false,
        historyPost: false,
        historyPostExport: false,
        historyDetails: false,
        historyDetailsExport: false,
        createBroadcastPost: false,
        editBroadcastPost: false,
        recallBroadcastMessage: false,
    },
    broadcastOverviewData: {
        month: new Date().getMonth() + 1,
        postsSent: 0,
        ytdPostsSent: 0,
    },
    recurringPostData: {
        index: 0,
        maxIndex: 0,
        data: [],
    },
    schedulePostData: {
        index: 0,
        maxIndex: 0,
        data: [],
    },
    historyPostData: {
        index: 0,
        maxIndex: 0,
        data: [],
    },
    historyPostExportData: [{
        id: '',
        broadcastId: '',
        title: '',
        message: '',
        messageType: 0,
        numOfRecipients: 0,
        broadcastType: '',
        revoked: false,
        failedRecipients: 0,
        revokedDate: '',
        sentDate: '',
        successRate: 0,
    }],
    historyDetailsData: {
        broadcastId: '',
        title: '',
        message: '',
        messageType: 1,
        recipientList: [],
        broadcastType: 0,
        interval: {
            startDate: '',
            frequency: 0,
            expiryDate: '',
        },
        isDeleted: false,
        status: StatusTypeEnum.All,
        recipientListEnum: RecipientListEnum.Custom,
        createdAt: '',
        updatedAt: '',
        mediaUrl: '',
        mediaUploadStatus: IBroadcastMediaUploadStatus.NoMedia,
        fileId: '',
    },
    historyDetailsExportData: [{
        messageId: 0,
        firstName: '',
        lastName: '',
        username: '',
        phoneNumber: '',
        recipientTelegramId: 0,
        sent: false,
        sentAt: '',
        errorMessage: '',
    }],
    broadcastType: 0,
    getRecipientList: RecipientListEnum.Custom,
    isRecurring: false,

    createBroadcastModalIsOpen: false,
    editRecurringBroadcastModalIsOpen: false,
    editScheduleBroadcastModalIsOpen: false,
    duplicateBroadcastModalIsOpen: false,
    recallBroadcastMessageModalIsOpen: false,
    broadcastDetailsModalIsOpen: false,

    recurringPage: 1,
    recurringPaginatorIndex: 1,

    schedulePage: 1,
    schedulePaginatorIndex: 1,

    historyPage: 1,
    historyPaginatorIndex: 1,

    maxIndex: 0,

    broadcastId: '',
    exportBroadcastDetailsId: '',
    error: {
        broadcastOverview: '',
        recurringPost: '',
        schedulePost: '',
        historyPost: '',
        historyPostExport: '',
        historyDetails: '',
        historyDetailsExport: '',
        createBroadcastPost: '',
        editBroadcastPost: '',
        recallBroadcastMessage: '',
    },
};

const broadcastSlice = createSlice({
    name: 'broadcast',
    initialState,
    reducers: {
        getBroadcastOverviewAttempt: (state, _action: GetBroadcastOverviewParams) => {
            state.actions.broadcastOverview = true;
            state.error.broadcastOverview = '';
        },
        getBroadcastOverviewSuccess: (state, action: PayloadAction<BroadcastOverview>) => {
            state.actions.broadcastOverview = false;

            if (action.payload) {
                state.broadcastOverviewData = action.payload;
            }

            state.error.broadcastOverview = '';
        },
        getBroadcastOverviewFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.broadcastOverview = false;

            if (action.payload) {
                state.error.broadcastOverview = action.payload;
            }
        },

        getRecurringPostAttempt: (state, _action: GetRecurringPostParams) => {
            state.actions.recurringPost = true;
            state.error.recurringPost = '';
        },
        getRecurringPostSuccess: (state, action: PayloadAction<RecurringPost>) => {
            state.actions.recurringPost = false;

            if (action.payload) {
                state.recurringPostData = action.payload;
            }

            state.error.recurringPost = '';
        },
        getRecurringPostFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.recurringPost = false;

            if (action.payload) {
                state.error.recurringPost = action.payload;
            }
        },

        getSchedulePostAttempt: (state, _action: GetSchedulePostParams) => {
            state.actions.schedulePost = true;
            state.error.schedulePost = '';
        },
        getSchedulePostSuccess: (state, action: PayloadAction<SchedulePost>) => {
            state.actions.schedulePost = false;

            if (action.payload) {
                state.schedulePostData = action.payload;
            }

            state.error.schedulePost = '';
        },
        getSchedulePostFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.schedulePost = false;

            if (action.payload) {
                state.error.schedulePost = action.payload;
            }
        },

        getHistoryPostAttempt: (state, _action: GetHistoryPostParams) => {
            state.actions.historyPost = true;
            state.error.historyPost = '';
        },
        getHistoryPostSuccess: (state, action: PayloadAction<BroadcastHistoryPost>) => {
            state.actions.historyPost = false;

            if (action.payload) {
                state.historyPostData = action.payload;
            }

            state.error.historyPost = '';
        },
        getHistoryPostFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.historyPost = false;

            if (action.payload) {
                state.error.historyPost = action.payload;
            }
        },

        getHistoryPostExportAttempt: (state, _action: GetHistoryPostExportParams) => {
            state.actions.historyPostExport = true;
            state.error.historyPostExport = '';
        },
        getHistoryPostExportSuccess: (state, action: PayloadAction<BroadcastHistoryPostExport[]>) => {
            state.actions.historyPostExport = false;

            if (action.payload) {
                state.historyPostExportData = action.payload;
            }

            state.error.historyPostExport = '';
        },
        getHistoryPostExportFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.historyPostExport = false;

            if (action.payload) {
                state.error.historyPostExport = action.payload;
            }
        },

        getHistoryDetailsAttempt: (state, _action: GetHistoryDetailsParams) => {
            state.actions.historyDetails = true;
            state.error.historyDetails = '';
        },
        getHistoryDetailsSuccess: (state, action: PayloadAction<HistoryDetails>) => {
            state.actions.historyDetails = false;

            if (action.payload) {
                state.historyDetailsData = action.payload;
            }

            state.error.historyDetails = '';
        },
        getHistoryDetailsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.historyDetails = false;

            if (action.payload) {
                state.error.historyDetails = '';
            }
        },

        getHistoryDetailsExportAttempt: (state, _action: GetHistoryDetailsExportParams) => {
            state.actions.historyDetailsExport = true;
            state.error.historyDetailsExport = '';
        },
        getHistoryDetailsExportSuccess: (state, action: PayloadAction<HistoryDetailsExport[]>) => {
            state.actions.historyDetailsExport = false;

            if (action.payload) {
                state.historyDetailsExportData = action.payload;
            }

            state.error.historyDetailsExport = '';
        },
        getHistoryDetailsExportFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.historyDetailsExport = false;

            if (action.payload) {
                state.error.historyDetailsExport = '';
            }
        },

        createBroadcastPostAttempt: (state, _action: PayloadAction<ICreateBroadcastPost>) => {
            state.actions.createBroadcastPost = true;
            state.error.createBroadcastPost = '';
        },
        createBroadcastPostSuccess: (state) => {
            state.actions.createBroadcastPost = false;
            state.error.createBroadcastPost = '';
        },
        createBroadcastPostFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.createBroadcastPost = false;

            if (action.payload) {
                state.error.createBroadcastPost = action.payload;
            }
        },

        editBroadcastPostAttempt: (state, _action: PayloadAction<IEditBroadcastPost>) => {
            state.actions.editBroadcastPost = true;
            state.error.editBroadcastPost = '';
        },
        editBroadcastPostSuccess: (state, action: PayloadAction<IEditBroadcastPost>) => {
            state.actions.editBroadcastPost = false;

            state.recurringPostData = {
                ...state.recurringPostData,
                ...action.payload,
            };

            state.error.editBroadcastPost = '';
        },
        editBroadcastPostFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.editBroadcastPost = false;

            if (action.payload) {
                state.error.editBroadcastPost = action.payload;
            }
        },

        recallBroadcastMessageAttempt: (state, _action: RecallBroadcastMessageParams) => {
            state.actions.recallBroadcastMessage = true;
            state.error.recallBroadcastMessage = '';
        },
        recallBroadcastMessageSuccess: (state) => {
            state.actions.recallBroadcastMessage = false;
            state.error.recallBroadcastMessage = '';
        },
        recallBroadcastMessageFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.recallBroadcastMessage = false;

            if (action.payload) {
                state.error.recallBroadcastMessage = action.payload;
            }
        },

        setRecipientList: (state, action: PayloadAction<RecipientListEnum>) => {
            state.getRecipientList = action.payload;
        },

        setIsRecurring: (state, action: PayloadAction<boolean>) => {
            state.isRecurring = action.payload;
        },

        setBroadcastType: (state, action: PayloadAction<BroadcastTypesEnum>) => {
            state.broadcastType = action.payload;
        },

        setCreateBroadcastModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.createBroadcastModalIsOpen = action.payload;
        },

        setEditRecurringBroadcastModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.editRecurringBroadcastModalIsOpen = action.payload;
        },

        setEditScheduleBroadcastModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.editScheduleBroadcastModalIsOpen = action.payload;
        },

        setDuplicateBroadcastModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.duplicateBroadcastModalIsOpen = action.payload;
        },

        setRecallBroadcastMessageModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.recallBroadcastMessageModalIsOpen = action.payload;
        },

        setBroadcastDetailsModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.broadcastDetailsModalIsOpen = action.payload;
        },

        setRecurringPage: (state, action: PayloadAction<number>) => {
            state.recurringPage = action.payload;
        },
        setRecurringBroadcastIndex: (state, action: PayloadAction<number>) => {
            state.recurringPaginatorIndex = action.payload;
        },

        setSchedulePage: (state, action: PayloadAction<number>) => {
            state.schedulePage = action.payload;
        },
        setScheduleBroadcastIndex: (state, action: PayloadAction<number>) => {
            state.schedulePaginatorIndex = action.payload;
        },

        setHistoryPage: (state, action: PayloadAction<number>) => {
            state.historyPage = action.payload;
        },
        setHistoryBroadcastPaginatorIndex: (state, action: PayloadAction<number>) => {
            state.historyPaginatorIndex = action.payload;
        },

        setBroadcastId: (state, action: PayloadAction<string>) => {
            state.broadcastId = action.payload;
        },

        setExportBroadcastDetailsId: (state, action: PayloadAction<string>) => {
            state.exportBroadcastDetailsId = action.payload;
        },
    },
});

export type BroadcastState = typeof initialState;

export default {
    actions: broadcastSlice.actions,
    reducers: broadcastSlice.reducer,
};
