import React, { useState, CSSProperties, FunctionComponent, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import SVG from 'react-inlinesvg';
import { toast } from 'react-toastify';
import throttle from 'lodash.throttle';
import { Spinner } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { AppDispatch, RootState } from 'redux/store';
import { connect } from 'react-redux';

import { ICreateFaqDTO } from 'redux/slices/faq/types';

import { IFaq, ISearchQuery } from 'entities/faq';

import Icons from 'assets/icons/Index';
import Colors from 'assets/themes/Colors';

import ContentWrapper from 'components/ContentWrapper';
import Input from 'components/Input';
import Icon from 'components/Icon';
import Button from 'components/Button';
import Text from 'components/Text';
import Chip from 'components/Chip';
import CustomDropdown from 'components/DropdownSearchbar';
import CustomModal from 'components/Modal';
import AddModal from 'components/AddModal';
import ErrorModal from 'components/ErrorModal';
import FaqList from 'components/faq/FaqList';
import NewWelcomeMessageWrapper from 'containers/home/faq/WelcomeMessage';
import AnalyticsHeader from 'components/analytics/AnalyticsHeader';
import Flex from 'components/Flex';
import EditCopyWriting from './faq/EditCopyWriting';
import EditFaqCardModal from './faq/EditFaqCardModal';
import CreateFaqModal from './faq/CreateFaqModal';
import DeleteFaqCardModal from './faq/DeleteFaqCardModal';

interface FaqViewProps {
    isLanu: boolean;
    loading: boolean;
    error: string;
    getFaqs(): void;
    deleteFaq(id: string): void;
    createFaq(data: ICreateFaqDTO): void;
    faqData: IFaq[];
    searchQuery: string;
    setSearchQuery(value: string): void;
    searchChipText: string;
    clearSearch(): void;
    loadingAutocomplete: boolean;
    getSearchAutocomplete(query: string): void;
    searchAutocompleteList: ISearchQuery[]
    clearSearchAutomplete(): void,
    searchedFaqId: string;
    openFaqs: string[];
    getAllFaqReset: () => void;
    getAllFaqs: () => void;
}

interface StylesDictionary {
    [Key: string]: CSSProperties;
}

const FaqView: FunctionComponent<FaqViewProps> = (props: FaqViewProps) => {
    const {
        isLanu,
        loading,
        error,
        faqData,
        getFaqs,
        deleteFaq,
        createFaq,
        searchQuery,
        setSearchQuery,
        searchChipText,
        clearSearch,
        loadingAutocomplete,
        getSearchAutocomplete,
        searchAutocompleteList,
        clearSearchAutomplete,
        searchedFaqId,
        openFaqs,
        getAllFaqs,
        getAllFaqReset,
    } = props;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isCopyWritingModalOpen, setIsCopyWritingModalOpen] = useState(false);
    const [idBeingDeleted, setIdBeingDeleted] = useState<string | null>(null);
    const [parentId, setParentId] = useState<string>('');

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const toggleAddModal = () => {
        setIsAddModalOpen(!isAddModalOpen);
    };

    const toggleCopyWritingModal = () => {
        setIsCopyWritingModalOpen(!isCopyWritingModalOpen);
    };

    useEffect(() => {
        getFaqs();
        getAllFaqReset();
        getAllFaqs();
    }, [isLanu]);

    const throttledSetSearchChip = useRef(throttle((searchWord: string) => {
        clearSearchAutomplete();
        getSearchAutocomplete(searchWord);
    }, 1200, { leading: false }));

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setSearchQuery(value);

        throttledSetSearchChip.current(value);
    };

    const onAddClicked = (id: string) => {
        setParentId(id);
        toggleAddModal();
    };

    const onAddConfirmed = (data: ICreateFaqDTO) => {
        if (parentId) {
            createFaq(data);
        } else {
            toast.error('Creation failed. Please try again.');
        }
    };

    const onDeleteClicked = (id: string) => {
        setIdBeingDeleted(id);
        toggleModal();
    };

    const onDeleteConfirmed = () => {
        if (idBeingDeleted) {
            deleteFaq(idBeingDeleted);
        } else {
            toast.error('Deletion failed. Please try again.');
        }
    };

    const sortedFaqData = faqData?.slice().sort((a, b) => a.orderId - b.orderId);

    const renderFaqCards = () => {
        if (loading) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', height: '100', width: '100%' }}>
                    <Skeleton count={7} height={30} width='100%' />
                </div>
            );
        }

        return (
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', height: '100', width: '100%' }}>
                {
                    faqData.length ? (
                        sortedFaqData.map((item, index) => {
                            const { id, title, messages, children } = item;
                            const sortedChildren = children?.slice().sort((a, b) => a.orderId - b.orderId);

                            return (
                                <div style={{ width: 'calc(100% - 160px)', position: 'relative' }}>

                                    <FaqList
                                        key={id}
                                        id={id}
                                        title={title}
                                        messages={messages[0].text}
                                        captions={messages[0].caption || ''}
                                        faqType={messages[0].type}
                                        onClickPlus={onAddClicked}
                                        onClickDelete={onDeleteClicked}
                                        index={(index + 1)}
                                        openFaqs={openFaqs}
                                        searchedFaqId={searchedFaqId}
                                    >
                                        {sortedChildren}
                                    </FaqList>
                                </div>
                            );
                        })
                    ) : <Text style={{ fontSize: 16 }}>You have No FAQs created. Create one now!</Text>
                }
            </div>
        );
    };

    return (
        <ContentWrapper>
            <AnalyticsHeader size='lg' header='Frequently Asked Questions' />
            <AnalyticsHeader size='md' header='Welcome Message' />
            <div style={containerStyles.whiteBarContainer}>
                <NewWelcomeMessageWrapper />
            </div>
            <Flex alignItems='center' width='calc(100% - 180px)' justifyContent='space-between' gap='1rem' flexWrap='wrap' style={{ position: 'relative' }}>
                <AnalyticsHeader size='md' header='Questions' />
                <div id='searchBarContainer' style={containerStyles.searchBarContainer}>
                    <div style={{ position: 'absolute', left: '3px', top: '6px' }}>

                        <Icon source={Icons.SearchIcon} style={{ color: 'black', height: '20px', width: '20px' }} />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', width: '215.25px' }}>
                        <Input
                            placeholder='Search...'
                            value={searchQuery}
                            onChange={handleSearchChange}
                            css={styles.inputField}
                        />
                        <CustomDropdown dropdownData={searchAutocompleteList} />
                    </div>

                    {loadingAutocomplete ? <Spinner size='sm' variant='light' style={{ margin: '0 auto' }} /> : ''}
                </div>
                <CreateParentFaqWrapper>
                    <button type='button' onClick={() => onAddClicked('0')}>
                        Add Category
                    </button>
                </CreateParentFaqWrapper>
            </Flex>
            {searchChipText && (
                <div style={{ display: 'flex', padding: 5, alignItems: 'center', width: '100%', borderTop: `1px solid ${Colors.primary}` }}>
                    <Chip text={searchChipText} onClick={clearSearch} />
                </div>
            )}

            {renderFaqCards()}
            <EditFaqCardModal />

            <EditCopyWriting />

            <DeleteFaqCardModal
                header='Are you sure you want to delete this?'
                isOpen={isModalOpen}
                toggle={toggleModal}
                onConfirmed={onDeleteConfirmed}
            />

            <CreateFaqModal
                header='Create a new FAQ card'
                isOpen={isAddModalOpen}
                toggle={toggleAddModal}
                onConfirmed={onAddConfirmed}
                id={parentId}
            />

            <ErrorModal
                bodyText={error}
                isOpen={error.length > 0 && !error.includes('Please try logging in again.')}
                onRetry={getFaqs}
            />
        </ContentWrapper>
    );
};

const CreateParentFaqWrapper = styled.div`
    position: absolute;
    right:-195px;

    button {
        padding: 0.5rem 2.5rem;
        border-radius:20px;
        border-style:solid;
        background-color: ${Colors.iqosPrimary};
        border: 1px solid ${Colors.iqosPrimary};
        color: ${Colors.white};

        &:hover {
            background-color: ${Colors.white};
            color: ${Colors.iqosPrimary};
        }

        &:disabled{
            background-color: ${Colors.lightGrey};
            border-color: ${Colors.lightGrey};
            cursor: not-allowed;

            &:hover {
                color: ${Colors.white};
            }
        }
    }

`;

const containerStyles: StylesDictionary = {
    whiteBarContainer: {
        position: 'relative',
        top: '-20px',
    },
    searchBarContainer: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: Colors.lightCream,
        borderRadius: '5px',
        position: 'relative',
        paddingRight: '1rem',
    },
    titleBar: {
        background: Colors.primary,
        color: 'white',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '5px',
        width: '100%',
    },
    welcomeMessageBar: {
        background: Colors.primary,
        color: 'white',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '5px',
        width: '100%',
        borderTop: `1px solid ${Colors.secondary}`,
        flexDirection: 'row',
    },
    welcomeMessageCardContainer: {
        height: '100%',
        width: '85%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

const styles = {
    addCategoryButton: css`
        margin-top: 5px;
        margin-bottom: 5px;
        width: 200px;
        font-size: 18px;
        display: flex;
        align-items: center;
        border-radius: 100px;
        padding: 5px;
        margin-right: 10px;
        background-color: #B7B4B4;
        height: 30px;

        &:hover {
            background-color: ${Colors.secondary};
            color: ${Colors.primary};
            SVG {
                color: ${Colors.primary};
            }
        }

        &:active {
            background-color: ${Colors.secondary};
        }
    `,

    inputField: css`;
        background-color: ${Colors.lightCream};
        color: black;
        padding-left:2.3rem;
        margin-top: 3px;
        margin-bottom: 3px;
        border: none;
        outline: none;
        transition: 0.3s;
        width: 100%;

        &:hover {
            background-color: ${Colors.lightCream}80;
            transition: 0.3s;
        }
    `,
};

const mapStateToProps = (state: RootState) => ({
    faqData: Selectors.getFaqFaqs(state),
    isLanu: Selectors.getFaqGetIsLANU(state),
    loading: Selectors.getFaqFaqsAttempting(state),
    error: Selectors.getFaqFaqsError(state),
    editing: Selectors.getFaqFaqsEditing(state),
    searchQuery: Selectors.getFaqSearchQuery(state),
    searchChipText: Selectors.getFaqSearchChipText(state),
    loadingAutocomplete: Selectors.getFaqSearchAutocompleteAttempt(state),
    searchAutocompleteList: Selectors.getFaqSearchAutocomplete(state),
    searchedFaqId: Selectors.getFaqSearchedFaqId(state),
    openFaqs: state.faq.openFaqs,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getFaqs: () => dispatch(Actions.getAttempt()),
    deleteFaq: (id: string) => dispatch(Actions.deleteAttempt({ id })),
    createFaq: (data: ICreateFaqDTO) => dispatch(Actions.createAttempt(data)),
    editFaq: (data: IFaq) => dispatch(Actions.updateAttempt(data)),
    reorderFaq: (id: string, direction: string) => dispatch(Actions.reorderAttempt({ id, direction })),
    setSearchQuery: (value: string) => dispatch(Actions.setSearchQuery(value)),
    clearSearch: () => dispatch(Actions.clearSearch()),
    getSearchAutocomplete: (query: string) => dispatch(Actions.getSearchAutocompleteAttempt({ query })),
    clearSearchAutomplete: () => dispatch(Actions.clearSearchAutomplete()),
    getAllFaqReset: () => dispatch(Actions.getReset()),
    getAllFaqs: () => dispatch(Actions.getAttempt()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FaqView);
