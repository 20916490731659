import { put, call, select, takeEvery } from 'typed-redux-saga/macro';
import { toast } from 'react-toastify';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import PostsGateway from 'api/Posts';
import { RateByCategoriesPayloadParams } from 'redux/slices/posts/types';

export default function* watchGetRateByCategories(api: PostsGateway): SagaWatcherReturnType {
    yield takeEvery('posts/getRateByCategoriesAttempt', handleRateByCategories, api);
}

function* handleRateByCategories(api: PostsGateway, data: RateByCategoriesPayloadParams) {
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.getRateByCategories], {
        authToken,
        channelType: data.payload.channelType,
        dateFrom: data.payload.dateFrom,
        dateTo: data.payload.dateTo,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.getRateByCategoriesFailure(response.message || ''));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.getRateByCategoriesSuccess(response.data));
    }
}
