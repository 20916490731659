import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';

import { ProductCategoryDetails } from 'entities/ecommerce';

import AnalyticsContainer from 'components/analytics/common/AnalyticsContainer';
import DatePicker from 'components/analytics/common/DatePicker';
import Title from 'components/Title';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import Colors from 'assets/themes/Colors';
import Icons from 'assets/icons/Index';

import Loading from 'components/analytics/common/Loading';
import Error from 'components/analytics/common/Error';
import FunnelReportTable from './components/FunnelReportTable';

interface FunnelReportProps {
    selectedDates: { dateFrom: Date; dateTo: Date };
    loading: boolean;
    error: string | null;

    productCategories: ProductCategoryDetails[];

    setDatePicker: (dateFrom: Date, dateTo: Date) => void;
    getProductCategories: () => void;
}

const FunnelReport = (props: FunnelReportProps) => {
    const { selectedDates, loading, error, productCategories, setDatePicker, getProductCategories } = props;

    const { dateFrom, dateTo } = selectedDates;

    const [selectedProductCategory, setSelectedProductCategory] = useState<ProductCategoryDetails | null>(null);

    useEffect(() => {
        getProductCategories();
    }, []);

    const renderCard = (productCategory: ProductCategoryDetails) => {
        const selected = productCategory._id === selectedProductCategory?._id;

        return (
            <Card isSelected={selected} onClick={() => setSelectedProductCategory(productCategory)}>
                <Row>
                    <CardText isSelected={selected}>{productCategory.categoryName}</CardText>

                    <GraphIconWrapper>
                        <SVG
                            style={{
                                width: '20px',
                                strokeWidth: '0.1px',
                            }}
                            src={selected ? Icons.AnalyticsGraphWhite : Icons.AnalyticsGraph}
                            id='analyticsGraph'
                        />
                    </GraphIconWrapper>
                </Row>
            </Card>
        );
    };

    const renderData = () => {
        if (loading) {
            return <Loading />;
        }

        if (error) {
            return <Error errorMessage={error} />;
        }

        return (
            <DisplayWrapper>
                <GridWrapper>
                    {productCategories.map((item) => {
                        return renderCard(item);
                    })}
                </GridWrapper>

                {selectedProductCategory && (
                    <FunnelReportTable
                        categoryId={selectedProductCategory._id}
                        categoryName={selectedProductCategory.categoryName}
                    />
                )}
            </DisplayWrapper>
        );
    };

    return (
        <AnalyticsContainer>
            <HeaderWrapper>
                <Title label='Funnel Report' />

                <DatePicker
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    onChange={setDatePicker}
                />
            </HeaderWrapper>

            {renderData()}

        </AnalyticsContainer>
    );
};

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-items: flex-end;

    width: 100%;

    gap: 20px;
`;

const DisplayWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    padding-top: 20px;

    gap: 20px;
`;

const GridWrapper = styled.div`
    display: grid;
    grid-template-columns: auto auto auto;
    
    gap: 20px;
`;

const Card = styled.button<{ isSelected: boolean }>`
    background-color: ${props => (props.isSelected ? Colors.iqosPrimary : 'white')};

    border: none;

    cursor: pointer;

    display: flex;
    border-radius: 10px;
    padding: 0px 15px;
    align-items: center;

    height: 56px;
`;

const CardText = styled.div<{ isSelected: boolean }>`
    color: ${props => (props.isSelected ? 'white' : 'black')};

    align-self: center;
    display: flex;
    text-align: left;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;

    width: 100%;

    justify-content: space-between;
    align-item: center;
`;

const GraphIconWrapper = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
`;

const mapStateToProps = (state: RootState) => ({
    selectedDates: Selectors.getECommerceAnalDatePicker(state),
    loading: Selectors.getECommerceGetProductCategoryAttempting(state),
    error: Selectors.getECommerceGetProductCategoryFailure(state),

    productCategories: Selectors.getECommerceGetProductCategory(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setDatePicker: (dateFrom: Date, dateTo: Date) => dispatch(Actions.ecommAnalSetDatePicker({ dateFrom, dateTo })),
    getProductCategories: () => dispatch(Actions.getProductCategoryAttempt()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FunnelReport);
