import { fork } from 'redux-saga/effects';

import config from 'config';

import api from 'api';
import mockApi from 'api/mocks';

import auth from './auth';
import faq from './faq';
import analytics from './analytics';
import followers from './followers';
import contests from './contests';
import games from './games';
import broadcast from './broadcast';
import posts from './posts';
import summaryDashboard from './summaryDashboard';
import pncMessage from './pncMessage';
import eCommerce from './eCommerce';
import eCommerceAnalytics from './eCommerceAnalytics';

import { SagaForkReturnType } from './types';

// let apiInstance = api;

const apiInstance = api;

if (config.useMockApi) {
    // apiInstance = mockApi;
}

export default function* root(): SagaForkReturnType {
    yield fork(auth(apiInstance.auth).rootSaga);
    yield fork(faq(apiInstance.faq, apiInstance.lanuFaq).rootSaga);
    yield fork(followers(apiInstance.followers).rootSaga);
    yield fork(analytics(apiInstance.analytics, apiInstance.lanuAnalytics).rootSaga);
    yield fork(contests(apiInstance.contests).rootSaga);
    yield fork(games(apiInstance.games).rootSaga);
    yield fork(broadcast(apiInstance.broadcast).rootSaga);
    yield fork(posts(apiInstance.posts).rootSaga);
    yield fork(summaryDashboard(apiInstance.summaryDashboard).rootSaga);
    yield fork(pncMessage(apiInstance.pncMessage).rootSaga);
    yield fork(eCommerce(apiInstance.eCommerce).rootSaga);
    yield fork(eCommerceAnalytics(apiInstance.eCommerceAnalytics).rootSaga);
}
