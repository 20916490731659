import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { toast } from 'react-toastify';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import ContestsGateway from 'api/Contests';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';
import { CreateContestPayload } from 'redux/slices/contests/types';
import { ContestListTypesEnum, IChannelTypeEnum, IContestStatusEnum } from 'entities/contests';

export default function* watchCreateContest(api: ContestsGateway): SagaWatcherReturnType {
    yield takeEvery('contests/createContestAttempt', handleCreateContest, api);
}

function* handleCreateContest(api: ContestsGateway, data: CreateContestPayload) {
    const isLanu = yield* select(Selectors.getFaqGetIsLANU);
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const response = yield* call([api, api.createContest], {
        data: data.payload,
        authToken,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.createContestFailure(response.message));
        toast.error(response.message);
        return;
    }

    if (response.status === GatewayResponseStatus.Success) {
        toast.success('New contest created');

        yield put(Actions.createContestSuccess());

        const activeQuizIndex = yield* select(Selectors.contestGetActiveQuizListIndex);
        const inactiveQuizIndex = yield* select(Selectors.contestGetInactiveQuizListIndex);
        yield put(Actions.getActiveQuizListAttempt({ index: activeQuizIndex }));
        yield put(Actions.getInactiveQuizListAttempt({ index: inactiveQuizIndex }));
        yield put(Actions.contestSetCreateQuizModalState({ isOpen: false, type: ContestListTypesEnum.Quiz }));
    }
}
