import React, { CSSProperties, FunctionComponent } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import SVG from 'react-inlinesvg';
import { StylesDictionary } from 'containers/home';

import Fonts from 'assets/themes/Fonts';
import Icons from 'assets/icons/Index';

import './StyledComponent.css';

export interface ButtonTextProps {
    label: string;
    isSelected?: boolean;
    onClick?(): void;
    css?: FlattenSimpleInterpolation;
    isYtd?: boolean;
    style?: StylesDictionary | CSSProperties;
}

const ButtonText: FunctionComponent<ButtonTextProps> = ({
    label,
    isSelected = false,
    onClick,
    isYtd,
    style,
    ...props
}: ButtonTextProps) => {
    return (
        <StyledButtonText
            type='button'
            onClick={onClick}
            isSelected={isSelected}
            style={style}
        >
            {(isYtd === true) ? (
                <>
                    <SVG
                        src={Icons.Tick}
                        id='tickIcon'
                        style={{ width: '20px', height: '15px', fill: 'white' }}
                        className='fade-in-svg'
                    />
                    {label}
                </>
            ) : (<div>{label}</div>)}
        </StyledButtonText>
    );
};

const StyledButtonText = styled.button<{isSelected?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;

    background-color: white;
    font-size: 17px;
    border: 1px solid #A2A2A2;
    font-family: ${Fonts.secondary};
    border-radius: 20px;
    color: black;
    width: 205px;

    ${props => (props.isSelected === true) && css`
        color: white;
        background-color: #00D1D2;
        border: 1px solid #00D1D2;
    `}

    @media (max-width: 1580px) {
        font-size: 14px;
        height: 30px;
        width: 150px;
    }
`;

export default ButtonText;

ButtonText.defaultProps = {
    isSelected: false,
};
