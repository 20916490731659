import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import dayjs from 'dayjs';

import { ECommActivitiesReportData, ECommBuyNowClicksProductReportData, ECommBuyNowClicksReportData, ECommBuyNowClicksVariantReportData, ECommClicksProductReportData, ECommClicksVariantReportData, ECommFunnelReportData } from 'entities/ecommerceAnalytics';
import {
    EcommerceAnalyticsReduxState,
    SetDatePickerParams,
    GetClicksReportParams,
    GetClicksProductReportParams,
    GetClicksVariantReportParams,
    GetBuyNowClicksProductReportParams,
    GetBuyNowClicksVariantReportParams,
    GetFunnelReportParams,
} from './types';

const initialState: EcommerceAnalyticsReduxState = {
    actions: {
        getClicksReport: false,
        getClicksProductReport: false,
        getClicksVariantReport: false,
        getBuyNowClicksReport: false,
        getBuyNowProductReport: false,
        getBuyNowVariantReport: false,
        getActivitiesReport: false,
        getFunnelReport: false,
    },
    reports: {
        clicksReport: null,
        clicksProductReport: null,
        clicksVariantReport: null,
        buyNowClicksReport: null,
        buyNowClicksProductReport: null,
        buyNowClicksVariantReport: null,
        activitiesReport: null,
        funnelReport: null,
    },
    ui: {
        datePicker: {
            dateFrom: dayjs().subtract(1, 'month').toDate(),
            dateTo: new Date(),
        },
    },
    error: {
        getClicksReport: null,
        getClicksProductReport: null,
        getClicksVariantReport: null,
        getBuyNowClicksReport: null,
        getBuyNowProductReport: null,
        getBuyNowVariantReport: null,
        getActivitiesReport: null,
        getFunnelReport: null,
    },
};

const ECommerceAnalyticsSlice = createSlice({
    name: 'eCommerceAnalytics',
    initialState,
    reducers: {
        ecommAnalSetDatePicker: (state, action: SetDatePickerParams) => {
            state.ui.datePicker = {
                dateFrom: action.payload.dateFrom,
                dateTo: action.payload.dateTo,
            };
        },

        ecommAnalGetClicksReportAttempt: (state) => {
            state.actions.getClicksReport = true;
            state.error.getClicksReport = null;
        },
        ecommAnalGetClicksReportSuccess: (state, action: GetClicksReportParams) => {
            state.actions.getClicksReport = false;
            state.reports.clicksReport = action.payload;
        },
        ecommAnalGetClicksReportFailure: (state, action) => {
            state.actions.getClicksReport = false;
            state.error.getClicksReport = action.payload;
        },

        ecommAnalGetClicksProductReportAttempt: (state, _action: GetClicksProductReportParams) => {
            state.actions.getClicksProductReport = true;
            state.error.getClicksProductReport = null;
        },
        ecommAnalGetClicksProductReportSuccess: (state, action: PayloadAction<ECommClicksProductReportData>) => {
            state.actions.getClicksProductReport = false;
            state.reports.clicksProductReport = action.payload;
        },
        ecommAnalGetClicksProductReportFailure: (state, action) => {
            state.actions.getClicksProductReport = false;
            state.error.getClicksProductReport = action.payload;
        },

        ecommAnalGetClicksVariantReportAttempt: (state, _action: GetClicksVariantReportParams) => {
            state.actions.getClicksVariantReport = true;
            state.error.getClicksVariantReport = null;
        },
        ecommAnalGetClicksVariantReportSuccess: (state, action: PayloadAction<ECommClicksVariantReportData[]>) => {
            state.actions.getClicksVariantReport = false;
            state.reports.clicksVariantReport = action.payload;
        },
        ecommAnalGetClicksVariantReportFailure: (state, action) => {
            state.actions.getClicksVariantReport = false;
            state.error.getClicksVariantReport = action.payload;
        },

        ecommAnalGetBuyNowClicksReportAttempt: (state) => {
            state.actions.getBuyNowClicksReport = true;
            state.error.getBuyNowClicksReport = null;
        },
        ecommAnalGetBuyNowClicksReportSuccess: (state, action: PayloadAction<ECommBuyNowClicksReportData>) => {
            state.actions.getBuyNowClicksReport = false;
            state.reports.buyNowClicksReport = action.payload;
        },
        ecommAnalGetBuyNowClicksReportFailure: (state, action) => {
            state.actions.getBuyNowClicksReport = false;
            state.error.getBuyNowClicksReport = action.payload;
        },

        ecommAnalGetBuyNowClicksProductReportAttempt: (state, _action: GetBuyNowClicksProductReportParams) => {
            state.actions.getBuyNowProductReport = true;
            state.error.getBuyNowProductReport = null;
        },
        ecommAnalGetBuyNowClicksProductReportSuccess: (state, action: PayloadAction<ECommBuyNowClicksProductReportData>) => {
            state.actions.getBuyNowProductReport = false;
            state.reports.buyNowClicksProductReport = action.payload;
        },
        ecommAnalGetBuyNowClicksProductReportFailure: (state, action) => {
            state.actions.getBuyNowProductReport = false;
            state.error.getBuyNowProductReport = action.payload;
        },

        ecommAnalGetBuyNowClicksVariantReportAttempt: (state, _action: GetBuyNowClicksVariantReportParams) => {
            state.actions.getBuyNowVariantReport = true;
            state.error.getBuyNowVariantReport = null;
        },
        ecommAnalGetBuyNowClicksVariantReportSuccess: (state, action: PayloadAction<ECommBuyNowClicksVariantReportData[]>) => {
            state.actions.getBuyNowVariantReport = false;
            state.reports.buyNowClicksVariantReport = action.payload;
        },
        ecommAnalGetBuyNowClicksVariantReportFailure: (state, action) => {
            state.actions.getBuyNowVariantReport = false;
            state.error.getBuyNowVariantReport = action.payload;
        },

        ecommAnalGetActivitiesReportAttempt: (state) => {
            state.actions.getActivitiesReport = false;
            state.error.getActivitiesReport = null;
        },
        ecommAnalGetActivitiesReportSuccess: (state, action: PayloadAction<ECommActivitiesReportData>) => {
            state.actions.getActivitiesReport = false;
            state.reports.activitiesReport = action.payload;
        },
        ecommAnalGetActivitiesReportFailure: (state, action) => {
            state.actions.getActivitiesReport = false;
            state.error.getActivitiesReport = action.payload;
        },

        ecommAnalGetFunnelReportAttempt: (state, _action: GetFunnelReportParams) => {
            state.actions.getFunnelReport = true;
            state.error.getFunnelReport = null;
        },
        ecommAnalGetFunnelReportSuccess: (state, action: PayloadAction<ECommFunnelReportData>) => {
            state.actions.getFunnelReport = false;
            state.reports.funnelReport = action.payload;
        },
        ecommAnalGetFunnelReportFailure: (state, action) => {
            state.actions.getFunnelReport = false;
            state.error.getFunnelReport = action.payload;
        },
    },
});

export type ECommerceAnalyticsState = typeof initialState;

export default {
    actions: ECommerceAnalyticsSlice.actions,
    reducers: ECommerceAnalyticsSlice.reducer,
};
