import React, { FunctionComponent } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import Colors from 'assets/themes/Colors';

export interface CollapsibleProps {
    title?: any;
    content?: any;
    isOpen: boolean;
    removeMessageContainer?: boolean;
}

const StyledDiv = styled.div<{ css?: FlattenSimpleInterpolation }>`
    border-radius: 8px;
    display: flex;

    ${props => props.css}
`;

const StyledCollapsibleDiv = styled.div<{ display: string }>`
    background-color: transparent;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 8px;
    margin-bottom: 12px;
    padding-right:1rem;
    
    ${props => props.display && css`
        display: ${props.display};
    `} 
`;

const InnerCollapsible: FunctionComponent<CollapsibleProps> = ({
    title,
    content,
    isOpen = false,
    removeMessageContainer = false,
}: CollapsibleProps) => {
    return (
        <StyledDiv
            css={isOpen ? styles.expandedDiv : styles.initialDiv}
        >
            {/* FAQ WRAPPER -  ENGLISH/MALAY */}
            <div style={{ width: '100%', display: 'flex', justifyContent: 'left', borderRadius: '10px', backgroundColor: removeMessageContainer ? 'transparent' : Colors.lightCream }}>
                <div style={{ width: '100%', height: '100%' }}>
                    <div style={{ width: '100%' }}>
                        {title}
                    </div>
                    <div>
                        {removeMessageContainer && (
                            <MessageHeader>
                                Message:
                            </MessageHeader>
                        )}
                        <StyledCollapsibleDiv display='flex'>
                            {content}
                        </StyledCollapsibleDiv>
                    </div>

                </div>
            </div>
        </StyledDiv>
    );
};

const MessageHeader = styled.div`
    display:flex;
    padding-left:5px;
    width:100%;
    position: relative;
    top: 16px;
    left: 4px;
`;

const styles = {
    chevronUp: css`
        background-color: transparent;
        SVG {
            transition: 0.3s;
            transform: rotate(0);

            &:hover{
                color: purple;
            }
        }
    `,
    chevronDown: css`
        background-color: transparent;
        SVG {
            transform: rotate(180deg);
            transition: 0.3s;

            &:hover{
                color: purple;
            }
        }
    `,
    initialDiv: css`
        height: 100%;
        width: 100%;
        transition: height 0.5s;
    `,
    expandedDiv: css`
        width: 100%;
        transition: height 0.5s;
    `,
};

export default InnerCollapsible;
