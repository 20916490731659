import React, { FunctionComponent, useEffect } from 'react';
import styled, { css } from 'styled-components';
import SVG from 'react-inlinesvg';
import dayjs from 'dayjs';

import { AppDispatch, RootState } from 'redux/store';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { connect } from 'react-redux';

import { Spinner } from 'reactstrap';
import Icons from 'assets/icons/Index';

import {
    BroadcastHistoryFilter,
    BroadcastHistoryPost,
    BroadcastTypesEnum,
    IBroadcastMediaUploadStatus,
} from 'entities/broadcast';
import Text from 'components/Text';
import IQOSPaginator from 'components/IQOSPaginator';
import 'components/StyledComponent.css';

interface getSchedulePostProps {
    isFailure: string;
    isLoading: boolean;

    getStatus: BroadcastHistoryFilter;

    historyPostData: BroadcastHistoryPost;
    historyIndex: number;
    detectOS: string;

    setHistoryIndex(index: number): void;
    setBroadcastId(data: string): void;
    setExportBroadcastDetailsId(data: string): void;
    setBroadcastType(data: BroadcastTypesEnum): void;

    getHistoryPost(index: number, status: BroadcastHistoryFilter): void;

    duplicatePost:() => void;
    recallBroadcastMessage:() => void;
    broadcastDetails:() => void;
}

const Table: FunctionComponent<getSchedulePostProps> = (props: getSchedulePostProps) => {
    const {
        isLoading,
        isFailure,

        getStatus,

        historyPostData,
        historyIndex,
        detectOS,

        setHistoryIndex,
        setBroadcastId,
        setExportBroadcastDetailsId,
        setBroadcastType,
        getHistoryPost,

        duplicatePost,
        recallBroadcastMessage,
        broadcastDetails,
    } = props;

    useEffect(() => {
        getHistoryPost(historyIndex, getStatus);
    }, [historyIndex]);

    useEffect(() => {
        setHistoryIndex(1);
        getHistoryPost(historyIndex, getStatus);
    }, [getStatus]);

    useEffect(() => {
        let checkInProgress = true;
        const interval = setInterval(() => {
            if (historyPostData && checkInProgress) {
                checkInProgress = historyPostData.data.some((item) => item.mediaUploadStatus === IBroadcastMediaUploadStatus.InProgress);
                if (checkInProgress) {
                    getHistoryPost(historyIndex, getStatus);
                }
            }
        }, 10000);

        return () => {
            clearInterval(interval);
        };
    }, [historyPostData, getStatus, historyIndex]);

    const renderMediaUploadStatus = (status: IBroadcastMediaUploadStatus) => {
        if (status === IBroadcastMediaUploadStatus.Success) {
            return 'Success';
        }
        if (status === IBroadcastMediaUploadStatus.Failed) {
            return 'Failed';
        }
        if (status === IBroadcastMediaUploadStatus.InProgress) {
            return 'In Progress';
        }
        return '';
    };

    const renderTableBody = () => {
        return (
            historyPostData && (
                historyPostData.data.map((item, index) => {
                    const { id, title, sentDate, broadcastType, broadcastId, numOfRecipients, revokedDate, failedRecipientList, mediaUploadStatus } = item;

                    return (
                        <StyledTr key={id}>
                            <StyledAbsoluteTd osName={detectOS}>
                                <StyledLink className={index % 2 === 0 ? 'even' : 'odd'} onClick={() => [broadcastDetails(), setBroadcastId(broadcastId), setExportBroadcastDetailsId(id)]}>
                                    {title}
                                </StyledLink>
                            </StyledAbsoluteTd>

                            <StyledTd style={{ minWidth: '150px', maxWidth: '150px' }}>
                                {renderMediaUploadStatus(mediaUploadStatus)}
                            </StyledTd>

                            <StyledTd style={{ minWidth: '150px', maxWidth: '150px' }}>
                                {dayjs(sentDate).format('DD-MM-YY h:mm')}
                            </StyledTd>

                            <StyledTd style={{ minWidth: '150px', maxWidth: '150px' }}>
                                {((broadcastType === BroadcastTypesEnum.Immediate) && 'Immediate')}
                                {((broadcastType === BroadcastTypesEnum.Recurring) && 'Recurring')}
                                {((broadcastType === BroadcastTypesEnum.Scheduled) && 'Schedule')}
                            </StyledTd>

                            <StyledTd style={{ minWidth: '150px', maxWidth: '150px' }}>
                                {numOfRecipients}
                            </StyledTd>

                            <StyledTd style={{ minWidth: '150px', maxWidth: '150px' }}>
                                0%
                            </StyledTd>

                            <StyledTd style={{ minWidth: '150px', maxWidth: '150px' }}>
                                {(numOfRecipients !== 0) ? `${((numOfRecipients / (numOfRecipients + ((failedRecipientList !== undefined) ? failedRecipientList.length : 0))) * 100).toFixed(1)}%` : '0%'}
                            </StyledTd>

                            <StyledTd style={{ minWidth: '150px', maxWidth: '150px' }}>
                                {(revokedDate !== undefined || null) ? dayjs(revokedDate).format('DD-MM-YY h:mm') : ''}
                            </StyledTd>

                            <StyledTd style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
                                <StyledIcon
                                    type='button'
                                    onClick={() => [broadcastDetails(), setBroadcastId(broadcastId), setExportBroadcastDetailsId(id)]}
                                >
                                    <SVG
                                        style={{
                                            width: '13px',
                                            height: '13px',
                                            fill: '#00D1D2',
                                            strokeWidth: '0.1px',
                                            transform: 'scale(1.8)',
                                        }}
                                        src={Icons.Details}
                                        id='detailsIcon'
                                    />
                                </StyledIcon>

                                {(dayjs(sentDate).isBefore(dayjs(new Date()).subtract(48, 'hour')) || revokedDate) ? (
                                    <StyledIcon
                                        type='button'
                                        disabled
                                    >
                                        <SVG
                                            style={{
                                                width: '13px',
                                                height: '13px',
                                                fill: 'lightgrey',
                                                cursor: 'not-allowed',
                                                strokeWidth: '0.1px',
                                                transform: 'scale(1.8)',
                                            }}
                                            src={Icons.Undo}
                                            id='undoIcon'
                                        />
                                    </StyledIcon>
                                ) : (
                                    <StyledIcon
                                        type='button'
                                        onClick={() => [recallBroadcastMessage(), setBroadcastId(id)]}
                                    >
                                        <SVG
                                            style={{
                                                width: '13px',
                                                height: '13px',
                                                fill: '#00D1D2',
                                                strokeWidth: '0.1px',
                                                transform: 'scale(1.8)',
                                            }}
                                            src={Icons.Undo}
                                            id='undoIcon'
                                        />
                                    </StyledIcon>
                                )}

                                <StyledIcon
                                    type='button'
                                    onClick={() => [duplicatePost(), setBroadcastId(broadcastId), setBroadcastType(broadcastType)]}
                                >
                                    <SVG
                                        style={{
                                            width: '13px',
                                            height: '13px',
                                            fill: '#00D1D2',
                                            strokeWidth: '0.1px',
                                            transform: 'scale(1.8)',
                                        }}
                                        src={Icons.Clipboard}
                                        id='clipboardIcon'
                                    />
                                </StyledIcon>
                            </StyledTd>
                        </StyledTr>
                    );
                })
            )
        );
    };

    return (
        <>
            <div style={{ position: 'relative' }}>
                {(isLoading && !isFailure && historyPostData.data.length > 0) && (
                    <div style={{ width: '100%', height: '500px', display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                        <Spinner size='xl' />
                    </div>
                )}
                {(isFailure && !isLoading && historyPostData.data.length <= 0) && (
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '30px', background: 'white', borderRadius: '10px' }}>
                        <tr>
                            <td colSpan={8} style={{ padding: '20px', fontSize: '18px' }}>
                                <div>
                                    <Text style={{ color: 'red', textAlign: 'center' }}>Something went wrong. Please try again.</Text>
                                </div>
                            </td>
                        </tr>
                    </div>
                )}
                {(historyPostData.data.length <= 0 && !isLoading && !isFailure) && (
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '30px', background: 'white', borderRadius: '10px' }}>
                        <tr>
                            <td colSpan={8} style={{ padding: '20px', fontSize: '18px' }}>
                                <div style={{ textAlign: 'center' }}>
                                    <Text style>There appears to be no data yet. </Text>
                                </div>
                            </td>
                        </tr>
                    </div>
                )}
                {!isLoading && !isFailure && historyPostData.data.length > 0 && (
                    <div className='broadcast-div'>
                        <StyledTable>
                            <tr style={{ borderBottom: '1px solid #A2A2A2' }}>
                                <StyledTh style={{ position: 'absolute', left: '0', top: 'auto', minWidth: '450px', maxWidth: '450px', background: 'white', borderBottom: '1px solid #959595' }}>Broadcast Tracking Name</StyledTh>
                                <StyledHeaderTd style={{ minWidth: '200px', maxWidth: '200px' }}>Media Upload Status</StyledHeaderTd>
                                <StyledHeaderTd>Posted On</StyledHeaderTd>
                                <StyledHeaderTd>Category</StyledHeaderTd>
                                <StyledHeaderTd>Receivers</StyledHeaderTd>
                                <StyledHeaderTd>Eng. Rate</StyledHeaderTd>
                                <StyledHeaderTd>Success Rate</StyledHeaderTd>
                                <StyledHeaderTd>Revoke Date</StyledHeaderTd>
                                <StyledHeaderTd>Actions</StyledHeaderTd>
                            </tr>
                            {renderTableBody()}
                        </StyledTable>
                    </div>
                )}
            </div>

            {historyPostData.data.length > 0 ? (
                <IQOSPaginator
                    maxIndex={historyPostData.maxIndex}
                    setIndex={setHistoryIndex}
                    currentIndex={historyIndex}
                    url='/broadcast'
                    containerId='#historyPostAnchor'
                />
            ) : (<> </>)}
        </>
    );
};

const StyledTable = styled.table`
    min-width: 100%;
    background-color: white;
    border-collapse: collapse;
    table-layout: fixed;
    
    margin-top: 20px;
    border-radius: 0px 10px 10px 10px !important;
`;

const StyledTh = styled.th`
    padding: 20px;
    border-bottom: 1px solid rgb(0,0,0,0,3);
    font-size: 15px;
    color: #959595;
    font-weight: lighter;

    &:first-of-type {
        text-align: left;
    }
`;

const StyledTr = styled.tr`
    &:last-of-type {
        border-bottom: 7px solid #ffffff !important;
    }

    &:nth-last-of-type(1){
        border-radius: 0px 0px 10px 0px !important;
    }

    &:nth-of-type(even){
        background-color: #F6FCFC;
        border-bottom: 1px solid #ffffff;
    }

    &:nth-of-type(odd){
        background-color: white;
        border-bottom: 7px solid #ffffff;
    }
`;

const StyledHeaderTd = styled.td`
    padding: 20px;
    font-size: 16px;
    max-width: 100%;
    whiteSpace: nowrap;
    overflow: hidden;
    text-Overflow: ellipsis;
    text-align: center;
    font-size: 15px;
    color: #959595;
`;

const StyledTd = styled.td`
    padding: 20px;
    font-size: 16px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
`;

const StyledAbsoluteTd = styled.td<{osName: string}>`
    padding: 0px;
    font-size: 16px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    position: absolute; 
    left: 0;
    min-width: 450px;
    max-width: 450px; 

    @-moz-document url-prefix() {
        ${(props) => (props.osName === 'Windows') && css`
            margin-top: -39px;
        `}
        ${(props) => (props.osName === 'MacOS') && css`
            margin-top: -59px;
        `}
        ${(props) => (props.osName === 'X11') && css`
            margin-top: -59px;
        `}
        ${(props) => (props.osName === 'Linux') && css`
            margin-top: -59px;
        `}
    }
`;

const StyledIcon = styled.button`
    background-color: transparent;
    color: inherit;
    border: none;
    transition: transform 0.2s;
    position: relative;
    z-index: 0;

    &:hover{
        transform: scale(1.1);
    }
`;

const StyledLink = styled.button`
    padding: 20px;
    
    width: 100%;
    height: 73.53px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    text-decoration: none;
    color: black;
    font-size: 16px;
    text-align: left;
    border: none;

    &:hover {
        color: #00D1D2;
        cursor: pointer;
    }
`;

const mapStateToProps = (state: RootState) => ({
    isFailure: Selectors.getBroadcastHistoryPostError(state),
    isLoading: Selectors.getBroadcastHistoryPostAttempting(state),

    historyPostData: Selectors.getBroadcastHistoryPost(state),
    historyIndex: Selectors.getBroadcastHistoryPaginatorIndex(state),
    detectOS: Selectors.getAuthGetDetectOS(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setBroadcastType: (data: BroadcastTypesEnum) => dispatch(Actions.setBroadcastType(data)),
    setBroadcastId: (data: string) => dispatch(Actions.setBroadcastId(data)),
    setExportBroadcastDetailsId: (data: string) => dispatch(Actions.setExportBroadcastDetailsId(data)),
    getHistoryPost: (index: number, status: BroadcastHistoryFilter) => dispatch(Actions.getHistoryPostAttempt({ index, status })),
    setHistoryIndex: (index: number) => dispatch(Actions.setHistoryBroadcastPaginatorIndex(index)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Table);
