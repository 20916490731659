import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';

import {
    IReorderSequence,
    LanguageEnum,
    ProductCategoryDetails,
    ProductListDetails,
    ProductListingMessageDetails,
    PromotionalMessageDetails,
    StatusTypeEnum,
    VariantsListDetails,
    WelcomeMessageDetails,
    CTAButtonDetails,
} from 'entities/ecommerce';

export interface authTokenAsParams {
    authToken: string;
}

export interface DeleteUsingIdParams {
    authToken: string;
    id: string;
}

export interface SetPromotionalMessageParams {
    authToken: string;
    title: string;
    message: string;
    file?: File;
    clearImage: string;
    language: LanguageEnum;
}

export interface SetWelcomeMessageParams {
    authToken: string;
    title: string;
    message: string;
    language: LanguageEnum;
}

export interface SetProductListingMessageParams {
    authToken: string;
    title: string;
    message: string;
    language: LanguageEnum;
}

export interface CreateProductCategoryParams {
    authToken: string;
    categoryName: string;
    status: StatusTypeEnum;
}

export interface EditProductCategoryParams {
    authToken: string;
    id: string;
    categoryName: string;
    status: StatusTypeEnum;
    reorderBy?: IReorderSequence;
    productListingMessageTitleEN?: string;
    productListingMessageTitleBM?: string;
    productListingMessageDescEN?: string;
    productListingMessageDescBM?: string;
}

export interface GetProductListingParams {
    authToken: string;
    categoryId: string;
}

export interface CreateProductListingParams {
    authToken: string;
    productName: string;
    productCategoryId: string;
}

export interface GetProductDetailsParams {
    authToken: string;
    productId: string;
}

export interface EditProductDetailsParams {
    authToken: string;
    id: string;
    productName: string;
    productTitleEN: string;
    productDescEN: string;
    productTitleBM: string;
    productDescBM: string;
    productPrice: string;
    promotionalPrice: string;
    productLinkEN: string;
    productLinkBM: string;
    status: StatusTypeEnum;
    reorderBy?: IReorderSequence;
}

export interface CreateProductVariantParams {
    authToken: string;
    productId: string;
    variantNameEN: string;
    variantNameBM: string;
    variantImage?: File;
}

export interface EditProductVariantParams {
    authToken: string;
    productId: string;
    variantId: string;
    variantNameEN: string;
    variantNameBM: string;
    variantImage: string;
    variantImageUpload?: File;
    defaultVariant: string;
}

export interface DeleteProductVariantParams {
    authToken: string;
    productId: string;
    variantId: string;
}

export interface SetCTAButtonParams {
    authToken: string;
    title: string;
    message: string;
    file?: File;
    clearImage: string;
    buttonText: string;
    link: string;
    language: LanguageEnum;
}

export abstract class IECommerceGateway extends Gateway {
    abstract getPromotionalMessage(params: authTokenAsParams): GatewayResponse<PromotionalMessageDetails[]>;

    abstract setPromotionalMessage(params: SetPromotionalMessageParams): GatewayResponse<null>;

    abstract getWelcomeMessage(params: authTokenAsParams): GatewayResponse<WelcomeMessageDetails[]>;

    abstract setWelcomeMessage(params: SetWelcomeMessageParams): GatewayResponse<null>;

    abstract getProductListingMessage(params: authTokenAsParams): GatewayResponse<ProductListingMessageDetails[]>;

    abstract setProductListingMessage(params: SetProductListingMessageParams): GatewayResponse<null>;

    abstract getProductCategory(params: authTokenAsParams): GatewayResponse<ProductCategoryDetails[]>;

    abstract createProductCategory(params: CreateProductCategoryParams): GatewayResponse<null>;

    abstract editProductCategory(params: EditProductCategoryParams): GatewayResponse<null>;

    abstract deleteProductCategory(params: DeleteUsingIdParams): GatewayResponse<null>;

    abstract getProductList(params: GetProductListingParams): GatewayResponse<ProductListDetails[]>;

    abstract createProductList(params: CreateProductListingParams): GatewayResponse<null>;

    abstract getProductDetails(params: GetProductDetailsParams): GatewayResponse<ProductListDetails[]>;

    abstract editProductDetails(params: EditProductDetailsParams): GatewayResponse<null>;

    abstract deleteProductDetails(params: DeleteUsingIdParams): GatewayResponse<null>;

    abstract getProductVariants(params: GetProductDetailsParams): GatewayResponse<VariantsListDetails[]>;

    abstract createProductVariant(params: CreateProductVariantParams): GatewayResponse<null>;

    abstract editProductVariant(params: EditProductVariantParams): GatewayResponse<null>;

    abstract deleteProductVariant(params: DeleteProductVariantParams): GatewayResponse<null>;

    abstract getCTAButtonDetails(params: authTokenAsParams): GatewayResponse<CTAButtonDetails[]>;

    abstract setCTAButtonDetails(params: SetCTAButtonParams): GatewayResponse<null>;
}
