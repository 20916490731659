import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { GatewayResponseStatus } from 'api/types/types';
import ContestsGateway from 'api/Contests';
import { GetContestTypePayload } from 'redux/slices/contests/types';
import { IChannelTypeEnum } from 'entities/contests';

export default function* watchGetContests(api: ContestsGateway): SagaWatcherReturnType {
    yield takeEvery('contests/getContestsAttempt', handleGetContests, api);
}

function* handleGetContests(api: ContestsGateway, data: GetContestTypePayload) {
    const { status } = data.payload;

    const authToken = yield* select(Selectors.getAuthAuthToken);
    const isLanu = yield* select(Selectors.getFaqGetIsLANU);
    const type = isLanu ? IChannelTypeEnum.LANU : IChannelTypeEnum.LAU;

    const response = yield* call([api, api.getContests], { authToken, type, status });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.getContestsFailure(response.message || ''));
    } if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.getContestsSuccess(response.data));
    }
}
