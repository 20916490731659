import { IGame, IGameDetails, IGameResponse } from 'entities/games';
import { GamesState } from '.';

const getGamesAttempting = (state: GamesState): boolean => state.actions.getGames || false;
const getGamesFailure = (state: GamesState): string => state.error.getGames || '';
const getGames = (state: GamesState): IGame[] => state.games || null;

const getCreateGameAttempting = (state: GamesState): boolean => state.actions.createGame || false;
const getCreateGameFailure = (state: GamesState): string => state.error.createGame || '';
const getCreateGame = (state: GamesState): IGame[] => state.games || null;

const getDeleteGameAttempting = (state: GamesState): boolean => state.actions.createGame || false;
const getDeleteGameFailure = (state: GamesState): string => state.error.deleteGame || '';
const getDeleteGame = (state: GamesState): IGame[] => state.games || null;

const getEditGameAttempting = (state: GamesState): boolean => state.actions.editGame || false;
const getEditGameFailure = (state: GamesState): string => state.error.editGame || '';

const getGameDetailsAttempting = (state: GamesState): boolean => state.actions.getGameDetails || false;
const getGameDetailsFailure = (state: GamesState): string => state.error.getGameDetails || '';
const getGameDetails = (state: GamesState): IGameDetails => state.gameDetails || null;

const getGameDetailsResponsesIndex = (state: GamesState): number => state.gameResponses.index || 1;
const getGameDetailsResponsesMaxIndex = (state: GamesState): number => state.gameResponses.maxIndex || 0;
const getGameDetailsResponsesAttempting = (state: GamesState): boolean => state.actions.getGameDetailsResponses || false;
const getGameDetailsResponsesFailure = (state: GamesState): string => state.error.getGameDetailsResponses || '';
const getGameDetailsResponses = (state: GamesState): IGameResponse[] => state.gameResponses.data || [];

const getGameDetailsResponsePage = (state: GamesState): number => state.page || 0;
const getGameDetailsPaginatorIndex = (state: GamesState): number => state.paginatorIndex || 1;

const getGameExportResponsesAttempting = (state: GamesState):boolean => state.actions.gameExportResponses || false;

export default {
    getGamesAttempting,
    getGamesFailure,
    getGames,

    getCreateGameAttempting,
    getCreateGameFailure,
    getCreateGame,

    getDeleteGameAttempting,
    getDeleteGameFailure,
    getDeleteGame,

    getEditGameAttempting,
    getEditGameFailure,

    getGameDetailsAttempting,
    getGameDetailsFailure,
    getGameDetails,

    getGameDetailsResponsesIndex,
    getGameDetailsResponsesMaxIndex,
    getGameDetailsResponsesAttempting,
    getGameDetailsResponsesFailure,
    getGameDetailsResponses,

    getGameDetailsResponsePage,
    getGameDetailsPaginatorIndex,

    getGameExportResponsesAttempting,
};
