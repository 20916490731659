import { IHourAnalytics, INewLanguagesAnalytics } from 'entities/analytics';
import { CategoryIdEnum } from 'entities/posts';
import { ISpreadsheetColumn } from './SpreadSheetColumns';

type Status = 'Success' | 'Failed';

const Auth = {
    storeAuthToken: (authToken: string): void => {
        localStorage.setItem('authToken', authToken);
    },
    getAuthToken: (): string | null => {
        return localStorage.getItem('authToken');
    },
    clearAuthToken: (): void => {
        localStorage.removeItem('authToken');
    },

    storeUserName: (userName: string): void => {
        localStorage.setItem('userName', userName);
    },
    getUserName: (): string | null => {
        return localStorage.getItem('UserName');
    },
    clearUserName: (): void => {
        localStorage.removeItem('userName');
    },
    storeExpiresAt: (expireAt: string): void => {
        localStorage.setItem('expireAt', expireAt);
    },
    getExpiresAt: (): string | null => {
        return localStorage.getItem('expireAt');
    },
    clearExpiresAt: (): void => {
        localStorage.removeItem('expireAt');
    },
    storeIsAuthTokenTemp: (isAuthTokenTemp: string): void => {
        localStorage.setItem('isAuthTokenTemp', isAuthTokenTemp);
    },
    getIsAuthTokenTemp: (): string | null => {
        return localStorage.getItem('isAuthTokenTemp');
    },
    clearIsAuthTokenTemp: (): void => {
        localStorage.removeItem('isAuthTokenTemp');
    },
};

const LocalStorage = {
    getItem<T>(key: string): T | null {
        const storageItem = localStorage.getItem(key);

        if (!storageItem) return null;

        return JSON.parse(storageItem);
    },
    setItem: (key: string, item: any | null): Status => {
        if (!item) return 'Failed';
        const parse = JSON.stringify(item);

        localStorage.setItem(key, parse);
        return 'Success';
    },
    removeItem: (key: string): void => {
        localStorage.removeItem(key);
    },
};

const Analytics = {
    calcLangPercentage(lang: string, total: string): string {
        return `(${Math.round((Number(lang) / Number(total)) * 100)}%)`;
    },

    prepareDynamicHeaders(data: INewLanguagesAnalytics[] | IHourAnalytics[]): string[] {
        if (!data.length) return [];

        const headers = Object.keys(data[0]).map(item => item[0].toUpperCase() + item.slice(1)); // make sure first letter of header is upper case

        return headers;
    },

    prepareLanguageData(data: INewLanguagesAnalytics[]): INewLanguagesAnalytics[] {
        if (!data.length) return [];

        let maxNumberOfKeys = {
            numberOfKeys: 0,
            keys: [''],
        };

        data.forEach((item, index) => {
            const allKeys = Object.keys(item);
            if (allKeys.length > maxNumberOfKeys.numberOfKeys) {
                maxNumberOfKeys = {
                    numberOfKeys: allKeys.length,
                    keys: allKeys,
                };
            }
        });

        const newData = data.map((item) => {
            let res = {
                date: item.data,
            };

            maxNumberOfKeys.keys.forEach((langKey) => {
                res = {
                    ...res,
                    [langKey]: item[langKey] || 0,
                };
            });

            return res;
        });

        return newData as INewLanguagesAnalytics[];
    },

    prepareDynamicDownloadHeaders(data: INewLanguagesAnalytics[] | IHourAnalytics[]): ISpreadsheetColumn[] {
        if (!data.length) return [];

        const headers = Object.keys(data[0]);

        const downloadHeaders = headers.map(item => {
            return {
                label: item[0].toUpperCase() + item.slice(1), // make sure first letter of sheet header is upper case
                value: item,
            };
        });

        return downloadHeaders;
    },
};

const ModifyString = {
    capitalizeFirstLetter(str:string):string {
        return str.charAt(0).toUpperCase() + str.slice(1);
    },
};

const Posts = {
    categoryEnumToStringConverter(category: CategoryIdEnum | string): string {
        switch (category) {
            case CategoryIdEnum.CategoryMotivation: return 'Category Motivation';
            case CategoryIdEnum.Acquisition: return 'Acquisition';
            case CategoryIdEnum.Retention: return 'Retention';
            case CategoryIdEnum.Promotions: return 'Promotions';
            case CategoryIdEnum.ProductInfo: return 'Product Info';
            case CategoryIdEnum.BrandedEngagement: return 'Branded Engagement';
            case CategoryIdEnum.NonBrandedEngagement: return 'Non Branded Engagement';
            case CategoryIdEnum.Recruitment: return 'Recruitment';
            case CategoryIdEnum.ProductSuperiority: return 'Product Superiority';
            case CategoryIdEnum.Announcement: return 'Announcement';
            case CategoryIdEnum.DeviceCare: return 'Device Care';
            case CategoryIdEnum.Contest: return 'Contest';
            case CategoryIdEnum.Greeting: return 'Greeting';
            default: return '';
        }
    },

    categoryStringToEnumConverter(category: string | undefined): number {
        switch (category) {
            case 'Category Motivation': return CategoryIdEnum.CategoryMotivation;
            case 'Acquisition': return CategoryIdEnum.Acquisition;
            case 'Retention': return CategoryIdEnum.Retention;
            case 'Promotions': return CategoryIdEnum.Promotions;
            case 'Product Info': return CategoryIdEnum.ProductInfo;
            case 'Branded Engagement': return CategoryIdEnum.BrandedEngagement;
            case 'Non Branded Engagement': return CategoryIdEnum.NonBrandedEngagement;
            case 'Recruitment': return CategoryIdEnum.Recruitment;
            case 'Product Superiority': return CategoryIdEnum.ProductSuperiority;
            case 'Announcement': return CategoryIdEnum.Announcement;
            case 'Device Care': return CategoryIdEnum.DeviceCare;
            case 'Contest': return CategoryIdEnum.Contest;
            case 'Greeting': return CategoryIdEnum.Greeting;
            default: return 0;
        }
    },

    hourNumberToStringConverter(hour: number): string {
        switch (hour) {
            case 1: return '1:00AM - 1:59AM (GMT +8)';
            case 2: return '2:00AM - 2:59AM (GMT +8)';
            case 3: return '3:00AM - 3:59AM (GMT +8)';
            case 4: return '4:00AM - 4:59AM (GMT +8)';
            case 5: return '5:00AM - 5:59AM (GMT +8)';
            case 6: return '6:00AM - 6:59AM (GMT +8)';
            case 7: return '7:00AM - 7:59AM (GMT +8)';
            case 8: return '8:00AM - 8:59AM (GMT +8)';
            case 9: return '9:00AM - 9:59AM (GMT +8)';
            case 10: return '10:00AM - 10:59AM (GMT +8)';
            case 11: return '11:00AM - 11:59AM (GMT +8)';
            case 12: return '12:00PM - 12:59PM (GMT +8)';
            case 13: return '1:00PM - 1:59PM (GMT +8)';
            case 14: return '2:00PM - 2:59PM (GMT +8)';
            case 15: return '3:00PM - 3:59PM (GMT +8)';
            case 16: return '4:00PM - 4:59PM (GMT +8)';
            case 17: return '5:00PM - 5:59PM (GMT +8)';
            case 18: return '6:00PM - 6:59PM (GMT +8)';
            case 19: return '7:00PM - 7:59PM (GMT +8)';
            case 20: return '8:00PM - 8:59PM (GMT +8)';
            case 21: return '9:00PM - 9:59PM (GMT +8)';
            case 22: return '10:00PM - 10:59PM (GMT +8)';
            case 23: return '11:00PM - 11:59PM (GMT +8)';
            case 24: return '12:00AM - 12:59AM (GMT +8)';
            default: return '';
        }
    },

    hourStringToNumberConverter(hour: string | undefined): number {
        switch (hour) {
            case '1:00AM - 1:59AM (GMT +8)': return 1;
            case '2:00AM - 2:59AM (GMT +8)': return 2;
            case '3:00AM - 3:59AM (GMT +8)': return 3;
            case '4:00AM - 4:59AM (GMT +8)': return 4;
            case '5:00AM - 5:59AM (GMT +8)': return 5;
            case '6:00AM - 6:59AM (GMT +8)': return 6;
            case '7:00AM - 7:59AM (GMT +8)': return 7;
            case '8:00AM - 8:59AM (GMT +8)': return 8;
            case '9:00AM - 9:59AM (GMT +8)': return 9;
            case '10:00AM - 10:59AM (GMT +8)': return 10;
            case '11:00AM - 11:59AM (GMT +8)': return 11;
            case '12:00PM - 12:59PM (GMT +8)': return 12;
            case '1:00PM - 1:59PM (GMT +8)': return 13;
            case '2:00PM - 2:59PM (GMT +8)': return 14;
            case '3:00PM - 3:59PM (GMT +8)': return 15;
            case '4:00PM - 4:59PM (GMT +8)': return 16;
            case '5:00PM - 5:59PM (GMT +8)': return 17;
            case '6:00PM - 6:59PM (GMT +8)': return 18;
            case '7:00PM - 7:59PM (GMT +8)': return 19;
            case '8:00PM - 8:59PM (GMT +8)': return 20;
            case '9:00PM - 9:59PM (GMT +8)': return 21;
            case '10:00PM - 10:59PM (GMT +8)': return 22;
            case '11:00PM - 11:59PM (GMT +8)': return 23;
            case '12:00AM - 12:59AM (GMT +8)': return 24;
            default: return 0;
        }
    },

    monthEnumToStringConverter(month: number): string {
        switch (month) {
            case 1: return 'January';
            case 2: return 'February';
            case 3: return 'March';
            case 4: return 'April';
            case 5: return 'May';
            case 6: return 'June';
            case 7: return 'July';
            case 8: return 'August';
            case 9: return 'September';
            case 10: return 'October';
            case 11: return 'November';
            case 12: return 'December';
            default: return '';
        }
    },

    rateConverter(value: number): string {
        const val = (Math.floor(value * 1000000) / 10000).toString().split('.');

        if (val.length > 1) {
            return `${val[0]}.${val[1].slice(0, 2)}`;
        }

        return val.toString();
    },
};

export default {
    Auth,
    LocalStorage,
    Analytics,
    ModifyString,
    Posts,
};
