import { fork } from 'redux-saga/effects';

import { RootSagaReturnType } from 'sagas/types';

import PNCMessageGateway from 'api/PNCMessage';

import watchGetPNCMessage from './getPNCMessage';
import watchSetPNCMessage from './setPNCMessage';

export default (api: PNCMessageGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetPNCMessage, api);
        yield fork(watchSetPNCMessage, api);
    }

    return {
        rootSaga,
    };
};
