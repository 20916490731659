import { ApiResponse } from 'apisauce';
import {
    ProductCategoryDetails,
    ProductListDetails,
    ProductListingMessageDetails,
    PromotionalMessageDetails,
    VariantsListDetails,
    WelcomeMessageDetails,
    CTAButtonDetails,
} from 'entities/ecommerce';
import {
    authTokenAsParams,
    CreateProductCategoryParams,
    CreateProductListingParams,
    CreateProductVariantParams,
    DeleteProductVariantParams,
    DeleteUsingIdParams,
    EditProductCategoryParams,
    EditProductDetailsParams,
    EditProductVariantParams,
    GetProductDetailsParams,
    GetProductListingParams,
    IECommerceGateway,
    SetProductListingMessageParams,
    SetPromotionalMessageParams,
    SetWelcomeMessageParams,
    SetCTAButtonParams,
} from './ECommerceBase';
import { GatewayResponse } from './types/types';

const getHeaders = (authToken: string) => ({
    headers: {
        Authorization: `Bearer ${authToken}`,
    },
});

export default class ECommerceGateway extends IECommerceGateway {
    async getPromotionalMessage(params: authTokenAsParams): GatewayResponse<PromotionalMessageDetails[]> {
        const response: ApiResponse<PromotionalMessageDetails[]> = await this.api.get('/v1/eCommerce/promotionalMessage', {}, getHeaders(params.authToken));

        return this.process(response);
    }

    async setPromotionalMessage(params: SetPromotionalMessageParams): GatewayResponse<null> {
        const { title, message, file, clearImage, language } = params;
        const form = new FormData();

        form.append('title', title);
        form.append('message', message);

        if (file) {
            form.append('file', file as Blob);
        }
        form.append('clearImage', clearImage);
        form.append('language', language.toString());

        const response: ApiResponse<null> = await this.api.post('/v1/eCommerce/promotionalMessage', form, getHeaders(params.authToken));
        return this.process(response);
    }

    async getWelcomeMessage(params: authTokenAsParams): GatewayResponse<WelcomeMessageDetails[]> {
        const response: ApiResponse<WelcomeMessageDetails[]> = await this.api.get('/v1/eCommerce/welcomeMessage', {}, getHeaders(params.authToken));

        return this.process(response);
    }

    async setWelcomeMessage(params: SetWelcomeMessageParams): GatewayResponse<null> {
        const { title, message, language } = params;

        const response: ApiResponse<null> = await this.api.post('/v1/eCommerce/welcomeMessage', { title, message, language }, getHeaders(params.authToken));
        return this.process(response);
    }

    async getProductListingMessage(params: authTokenAsParams): GatewayResponse<ProductListingMessageDetails[]> {
        const response: ApiResponse<ProductListingMessageDetails[]> = await this.api.get('/v1/eCommerce/productListingMessage', {}, getHeaders(params.authToken));

        return this.process(response);
    }

    async setProductListingMessage(params: SetProductListingMessageParams): GatewayResponse<null> {
        const { title, message, language } = params;

        const response: ApiResponse<null> = await this.api.post('/v1/eCommerce/productListingMessage', { title, message, language }, getHeaders(params.authToken));
        return this.process(response);
    }

    async getProductCategory(params: authTokenAsParams): GatewayResponse<ProductCategoryDetails[]> {
        const response: ApiResponse<ProductCategoryDetails[]> = await this.api.get('/v1/eCommerce/productCategory', {}, getHeaders(params.authToken));

        return this.process(response);
    }

    async createProductCategory(params: CreateProductCategoryParams): GatewayResponse<null> {
        const { categoryName, status } = params;

        const response: ApiResponse<null> = await this.api.post('/v1/eCommerce/productCategory', { categoryName, status }, getHeaders(params.authToken));
        return this.process(response);
    }

    async editProductCategory(params: EditProductCategoryParams): GatewayResponse<null> {
        const {
            id,
            categoryName,
            status,
            reorderBy,
            productListingMessageTitleEN,
            productListingMessageTitleBM,
            productListingMessageDescEN,
            productListingMessageDescBM,
        } = params;

        const response: ApiResponse<null> = await this.api.put('/v1/eCommerce/productCategory', {
            id,
            categoryName,
            status,
            reorderBy,
            productListingMessageTitleEN,
            productListingMessageTitleBM,
            productListingMessageDescEN,
            productListingMessageDescBM,
        }, getHeaders(params.authToken));
        return this.process(response);
    }

    async deleteProductCategory(params: DeleteUsingIdParams): GatewayResponse<null> {
        const data = {
            id: params.id,
        };

        const response: ApiResponse<null> = await this.api.delete('/v1/eCommerce/productCategory', {}, {
            ...getHeaders(params.authToken), data,
        });
        return this.process(response);
    }

    async getProductList(params: GetProductListingParams): GatewayResponse<ProductListDetails[]> {
        const { categoryId } = params;
        const response: ApiResponse<ProductListDetails[]> = await this.api.get(`/v1/eCommerce/productListing?categoryId=${categoryId}`, { }, getHeaders(params.authToken));

        return this.process(response);
    }

    async createProductList(params: CreateProductListingParams): GatewayResponse<null> {
        const { productName, productCategoryId } = params;

        const response: ApiResponse<null> = await this.api.post('/v1/eCommerce/productDetails', { productName, productCategoryId }, getHeaders(params.authToken));
        return this.process(response);
    }

    async getProductDetails(params: GetProductDetailsParams): GatewayResponse<ProductListDetails[]> {
        const { productId } = params;

        const response: ApiResponse<ProductListDetails[]> = await this.api.get(`/v1/eCommerce/productDetails?id=${productId}`, { }, getHeaders(params.authToken));
        return this.process(response);
    }

    async editProductDetails(params: EditProductDetailsParams): GatewayResponse<null> {
        const { id,
            productName,
            productTitleEN,
            productDescEN,
            productTitleBM,
            productDescBM,
            productPrice,
            promotionalPrice,
            productLinkEN,
            productLinkBM,
            status,
            reorderBy } = params;

        const response: ApiResponse<null> = await this.api.put('/v1/eCommerce/productDetails', { id, productName, productTitleEN, productDescEN, productTitleBM, productDescBM, productPrice, promotionalPrice, productLinkEN, productLinkBM, status, reorderBy }, getHeaders(params.authToken));
        return this.process(response);
    }

    async deleteProductDetails(params: DeleteUsingIdParams): GatewayResponse<null> {
        const data = {
            id: params.id,
        };
        const response: ApiResponse<null> = await this.api.delete('/v1/eCommerce/productDetails', {}, {
            ...getHeaders(params.authToken), data,
        });
        return this.process(response);
    }

    async getProductVariants(params: GetProductDetailsParams): GatewayResponse<VariantsListDetails[]> {
        const { productId } = params;

        const response: ApiResponse<VariantsListDetails[]> = await this.api.get(`v1/eCommerce/productVariant?productId=${productId}`, { }, getHeaders(params.authToken));
        return this.process(response);
    }

    async createProductVariant(params: CreateProductVariantParams): GatewayResponse<null> {
        const { productId, variantNameEN, variantNameBM, variantImage } = params;
        const form = new FormData();

        form.append('productId', productId);
        form.append('variantNameEN', variantNameEN);
        form.append('variantNameBM', variantNameBM);

        if (variantImage) {
            form.append('variantImage', variantImage as Blob);
        }

        const response: ApiResponse<null> = await this.api.post('/v1/eCommerce/productVariant', form, getHeaders(params.authToken));
        return this.process(response);
    }

    async editProductVariant(params: EditProductVariantParams): GatewayResponse<null> {
        const {
            productId,
            variantId,
            variantNameEN,
            variantNameBM,
            variantImage,
            variantImageUpload,
            defaultVariant,
        } = params;

        const form = new FormData();

        form.append('productId', productId);
        form.append('variantId', variantId);
        form.append('variantNameEN', variantNameEN);
        form.append('variantNameBM', variantNameBM);
        form.append('variantImage', variantImage);
        form.append('defaultVariant', defaultVariant);

        if (variantImageUpload) {
            form.append('variantImageUpload', variantImageUpload as Blob);
        }

        const response: ApiResponse<null> = await this.api.put('/v1/eCommerce/productVariant', form, getHeaders(params.authToken));
        return this.process(response);
    }

    async deleteProductVariant(params: DeleteProductVariantParams): GatewayResponse<null> {
        const data = {
            productId: params.productId,
            variantId: params.variantId,
        };
        const response: ApiResponse<null> = await this.api.delete('/v1/eCommerce/productVariant', {}, {
            ...getHeaders(params.authToken), data,
        });
        return this.process(response);
    }

    async getCTAButtonDetails(params: authTokenAsParams): GatewayResponse<CTAButtonDetails[]> {
        const response: ApiResponse<CTAButtonDetails[]> = await this.api.get('/v1/eCommerce/additionalCTAButton', {}, getHeaders(params.authToken));

        return this.process(response);
    }

    async setCTAButtonDetails(params: SetCTAButtonParams): GatewayResponse<null> {
        const { title, message, file, clearImage, buttonText, link, language } = params;
        const form = new FormData();

        form.append('title', title);
        form.append('message', message);

        if (file) {
            form.append('file', file as Blob);
        }
        form.append('clearImage', clearImage);
        form.append('buttonText', buttonText);
        form.append('link', link);
        form.append('language', language.toString());

        const response: ApiResponse<null> = await this.api.post('/v1/eCommerce/additionalCTAButton', form, getHeaders(params.authToken));
        return this.process(response);
    }
}
