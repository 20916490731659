import { put, call, select, takeEvery } from 'typed-redux-saga/macro';
import { PayloadAction } from '@reduxjs/toolkit';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import FollowersGateway from 'api/Followers';
import { IGetFollowersChartsDataReduxParam } from 'entities/followers';
import { IChannelTypeEnum } from 'entities/contests';

export default function* watchGetFollowersCharts(api: FollowersGateway): SagaWatcherReturnType {
    yield takeEvery('followers/followersGetFollowersChartsDataAttempt', handleGetFollowersChartsData, api);
}

function* handleGetFollowersChartsData(api: FollowersGateway, data:PayloadAction<IGetFollowersChartsDataReduxParam>) {
    const { dateFrom, dateTo } = data.payload;
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const isLanu = yield* select(Selectors.getFaqGetIsLANU);
    const channelType = isLanu ? IChannelTypeEnum.LANU : IChannelTypeEnum.LAU;

    const response = yield* call([api, api.getFollowersChartsData], { dateFrom, dateTo, authToken, channelType });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.followersGetFollowersChartsDataFailure(response.message || 'Unknown Error'));
        return;
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.followersGetFollowersChartsDataSuccess(response.data));
    }
}
