import config from 'config';

import AuthGateway from './Auth';
import FaqGateway from './Faq';
import LanuFaqGateway from './LanuFaq';
import AnalyticsGateway from './Analytics';
import LanuAnalyticsGateway from './LanuAnalytics';
import ContestsGateway from './Contests';
import GamesGateway from './Games';
import BroadcastGateway from './Broadcast';
import FollowersGateway from './Followers';
import PostsGateway from './Posts';
import SummaryDashboardGateway from './SummaryDashboard';
import PNCMessageGateway from './PNCMessage';
import ECommerceGateway from './ECommerce';
import ECommerceAnalyticsGateway from './ECommerceAnalytics';

const baseUrl = config.baseUrl as string;

const analytics = new AnalyticsGateway(baseUrl);
const auth = new AuthGateway(baseUrl);

const broadcast = new BroadcastGateway(baseUrl);

const contests = new ContestsGateway(baseUrl);

const faq = new FaqGateway(baseUrl);

const followers = new FollowersGateway(baseUrl);

const games = new GamesGateway(baseUrl);

const lanuAnalytics = new LanuAnalyticsGateway(baseUrl);
const lanuFaq = new LanuFaqGateway(baseUrl);

const posts = new PostsGateway(baseUrl);

const summaryDashboard = new SummaryDashboardGateway(baseUrl);

const pncMessage = new PNCMessageGateway(baseUrl);

const eCommerce = new ECommerceGateway(baseUrl);
const eCommerceAnalytics = new ECommerceAnalyticsGateway(`${baseUrl}/v1/eCommerce/analytics`);

export default {
    auth,
    analytics,
    broadcast,
    contests,
    faq,
    followers,
    games,
    lanuAnalytics,
    lanuFaq,
    posts,
    summaryDashboard,
    pncMessage,
    eCommerce,
    eCommerceAnalytics,
};
