import { put, call, select, takeEvery } from 'typed-redux-saga/macro';
import { PayloadAction } from '@reduxjs/toolkit';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import FollowersGateway from 'api/Followers';
import { IGetFollowersUsersListReduxParam } from 'entities/followers';
import { IChannelTypeEnum } from 'entities/contests';

export default function* watchGetFollowersUsersList(api: FollowersGateway): SagaWatcherReturnType {
    yield takeEvery('followers/followersGetFollowersUsersListAttempt', handleGetFollowersUsersList, api);
}

function* handleGetFollowersUsersList(api: FollowersGateway, data:PayloadAction<IGetFollowersUsersListReduxParam>) {
    const { index, dateFrom, dateTo } = data.payload;
    const authToken = yield* select(Selectors.getAuthAuthToken);

    const isLanu = yield* select(Selectors.getFaqGetIsLANU);
    const channelType = isLanu ? IChannelTypeEnum.LANU : IChannelTypeEnum.LAU;

    const response = yield* call([api, api.getFollowersUsersList], {
        index,
        channelType,
        authToken,
        dateFrom,
        dateTo,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.followersGetFollowersUsersListFailure(response.message || 'Unknown Error.'));
        return;
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.followersGetFollowersUsersListSuccess(response.data));
    }
}
