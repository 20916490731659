import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';
import { GatewayResponseStatus } from 'api/types/types';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { toast } from 'react-toastify';

import ECommerceGateway from 'api/ECommerce';
import { CreateProductListParams } from 'redux/slices/eCommerce/types';

export default function* watchCreateProductList(api: ECommerceGateway): SagaWatcherReturnType {
    yield takeEvery('eCommerce/createProductListAttempt', handleCreateProductList, api);
}

function* handleCreateProductList(api: ECommerceGateway, data: CreateProductListParams) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const { productName, productCategoryId } = data.payload;

    const response = yield* call([api, api.createProductList], {
        authToken,
        productName,
        productCategoryId,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.createProductListFailure(response.message));
        toast.error(response.message);
    }

    if (response.status === GatewayResponseStatus.Success) {
        const categoryId = yield* select(Selectors.getECommerceGetProductCategoryId);
        yield put(Actions.createProductListSuccess());
        yield put(Actions.getProductListAttempt({ categoryId }));
        yield put(Actions.setCreateProductModalIsOpen(false));
        toast.success('New Product created!');
    }
}
