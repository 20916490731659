import { BroadcastHistoryPost, BroadcastTypesEnum, RecurringPost, SchedulePost, RecipientListEnum, BroadcastOverview, HistoryDetails, BroadcastHistoryPostExport, HistoryDetailsExport } from 'entities/broadcast';
import { BroadcastState } from '.';

const getBroadcastOverviewAttempt = (state: BroadcastState): boolean => state.actions.broadcastOverview || false;
const getBroadcastOverviewFailure = (state: BroadcastState): string => state.error.broadcastOverview || '';
const getBroadcastOverview = (state: BroadcastState): BroadcastOverview => state.broadcastOverviewData;

const getRecurringPostAttempt = (state: BroadcastState): boolean => state.actions.recurringPost || false;
const getRecurringPostFailure = (state: BroadcastState): string => state.error.recurringPost || '';
const getRecurringPost = (state: BroadcastState): RecurringPost => state.recurringPostData;

const getSchedulePostAttempt = (state: BroadcastState): boolean => state.actions.schedulePost || false;
const getSchedulePostFailure = (state: BroadcastState): string => state.error.schedulePost || '';
const getSchedulePost = (state: BroadcastState): SchedulePost => state.schedulePostData;

const getHistoryPostAttempt = (state: BroadcastState): boolean => state.actions.historyPost || false;
const getHistoryPostFailure = (state: BroadcastState): string => state.error.historyPost || '';
const getHistoryPost = (state: BroadcastState): BroadcastHistoryPost => state.historyPostData;

const getHistoryPostExportAttempt = (state: BroadcastState): boolean => state.actions.historyPostExport || false;
const getHistoryPostExportFailure = (state: BroadcastState): string => state.error.historyPostExport || '';
const getHistoryPostExport = (state: BroadcastState): BroadcastHistoryPostExport[] => state.historyPostExportData;

const getHistoryDetailsAttempt = (state: BroadcastState): boolean => state.actions.historyDetails || false;
const getHistoryDetailsFailure = (state: BroadcastState): string => state.error.historyDetails || '';
const getHistoryDetails = (state: BroadcastState): HistoryDetails => state.historyDetailsData;

const getHistoryDetailsExportAttempt = (state: BroadcastState): boolean => state.actions.historyDetailsExport || false;
const getHistoryDetailsExportFailure = (state: BroadcastState): string => state.error.historyDetailsExport || '';
const getHistoryDetailsExport = (state: BroadcastState): HistoryDetailsExport[] => state.historyDetailsExportData;

const createBroadcastPostAttempt = (state: BroadcastState): boolean => state.actions.createBroadcastPost || false;
const createBroadcastPostFailure = (state: BroadcastState): string => state.error.createBroadcastPost || '';

const editBroadcastPostAttempt = (state: BroadcastState): boolean => state.actions.editBroadcastPost || false;
const editBroadcastPostFailure = (state: BroadcastState): string => state.error.editBroadcastPost || '';

const recallBroadcastMessageAttempt = (state: BroadcastState): boolean => state.actions.recallBroadcastMessage || false;
const recallBroadcastMessageFailure = (state: BroadcastState): string => state.error.recallBroadcastMessage || '';

const getBroadcastType = (state: BroadcastState): BroadcastTypesEnum => state.broadcastType || 1;
const getRecipientList = (state: BroadcastState): RecipientListEnum => state.getRecipientList || 0;
const getIsRecurring = (state: BroadcastState): boolean => state.isRecurring || false;

const getCreateBroadcastModalIsOpen = (state: BroadcastState): boolean => state.createBroadcastModalIsOpen || false;
const getEditRecurringBroadcastModalIsOpen = (state: BroadcastState): boolean => state.editRecurringBroadcastModalIsOpen || false;
const getEditScheduleBroadcastModalIsOpen = (state: BroadcastState): boolean => state.editScheduleBroadcastModalIsOpen || false;
const getDuplicateBroadcastModalIsOpen = (state: BroadcastState): boolean => state.duplicateBroadcastModalIsOpen || false;
const getRecallBroadcastMessageModalIsOpen = (state: BroadcastState): boolean => state.recallBroadcastMessageModalIsOpen || false;
const getBroadcastDetailsModalIsOpen = (state: BroadcastState): boolean => state.broadcastDetailsModalIsOpen || false;

const getRecurringPaginatorIndex = (state: BroadcastState): number => state.recurringPaginatorIndex || 1;
const getSchedulePaginatorIndex = (state: BroadcastState): number => state.schedulePaginatorIndex || 1;
const getHistoryPaginatorIndex = (state: BroadcastState): number => state.historyPaginatorIndex || 1;

const getRecurringPage = (state: BroadcastState): number => state.recurringPage || 0;
const getSchedulePage = (state: BroadcastState): number => state.schedulePage || 0;
const getHistoryPage = (state: BroadcastState): number => state.historyPage || 0;

const getMaxIndex = (state: BroadcastState): number => state.maxIndex || 0;

const getBroadcastId = (state: BroadcastState): string => state.broadcastId || '';
const getExportBroadcastDetailsId = (state: BroadcastState): string => state.exportBroadcastDetailsId || '';

export default {
    getBroadcastOverviewAttempt,
    getBroadcastOverviewFailure,
    getBroadcastOverview,

    getRecurringPostAttempt,
    getRecurringPostFailure,
    getRecurringPost,

    getSchedulePostAttempt,
    getSchedulePostFailure,
    getSchedulePost,

    getHistoryPostAttempt,
    getHistoryPostFailure,
    getHistoryPost,

    getHistoryPostExportAttempt,
    getHistoryPostExportFailure,
    getHistoryPostExport,

    getHistoryDetailsAttempt,
    getHistoryDetailsFailure,
    getHistoryDetails,

    getHistoryDetailsExportAttempt,
    getHistoryDetailsExportFailure,
    getHistoryDetailsExport,

    createBroadcastPostAttempt,
    createBroadcastPostFailure,

    editBroadcastPostAttempt,
    editBroadcastPostFailure,

    recallBroadcastMessageAttempt,
    recallBroadcastMessageFailure,

    getBroadcastType,
    getRecipientList,
    getIsRecurring,

    getCreateBroadcastModalIsOpen,
    getEditRecurringBroadcastModalIsOpen,
    getEditScheduleBroadcastModalIsOpen,
    getDuplicateBroadcastModalIsOpen,
    getRecallBroadcastMessageModalIsOpen,
    getBroadcastDetailsModalIsOpen,

    getRecurringPaginatorIndex,
    getSchedulePaginatorIndex,
    getHistoryPaginatorIndex,

    getRecurringPage,
    getSchedulePage,
    getHistoryPage,

    getMaxIndex,

    getBroadcastId,
    getExportBroadcastDetailsId,
};
