import React from 'react';
import styled from 'styled-components';
import { Spinner } from 'reactstrap';

interface LoadingProps {
    height?: number;
}

const Loading = (props: LoadingProps): JSX.Element => {
    const { height } = props;
    return (
        <Container height={height}>
            <Spinner />
        </Container>
    );
};

const Container = styled.div<{ height?: number }>`
    width: 100%;
    height: ${props => (props.height ? `${props.height}px` : '500px')};
    display: flex;

    justify-content: center;
    align-items: center;
`;

export default Loading;
