import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Spinner } from 'reactstrap';
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Line, ComposedChart, Label, ResponsiveContainer, ReferenceLine } from 'recharts';

import { connect } from 'react-redux';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { AppDispatch, RootState } from 'redux/store';

import 'components/StyledComponent.css';
import IQOSDateRangePicker from 'components/IQOSDateRangePicker';
import TextComponent from 'components/Text';
import { IChannelTypeEnum } from 'entities/contests';
import { RateByPost, InformationPostModalInfo } from 'entities/posts';
import Fonts from 'assets/themes/Fonts';
import { SummaryDashboardDetails } from 'entities/summaryDashboard';
import dayjs from 'dayjs';
import InformationPost from './InformationPost';

interface RateByNumberOfPostProps {
    isLanu: boolean;
    rateByPostData: RateByPost[];
    rateByPostModalInfoData: InformationPostModalInfo;
    isLoading: boolean;
    isFailure: string;
    getDateFrom: Date;
    getDateTo: Date;
    informationPostsIndex: number;
    getCurrentGraphWeek: string | undefined;
    summaryDashboardDetailsData: SummaryDashboardDetails;

    setCurrentGraphWeek(value: string | undefined): void;
    setInformationPostModalIsOpen(data: boolean): void;
    setDateFrom(date: Date | null): void;
    setDateTo(date: Date | null): void;
    getRateByPost(channelType: string, dateFrom: string, dateTo: string): void;
    getRateByPostModalInfo(week: number, channelType: string, dateFrom: string, dateTo: string, index: number): void;
    getSummaryDashboardDetails(type: string, dateFrom: string, dateTo: string, isYtd: string): void;
}

const CustomLineChartDot = ({ cy, cx, strokeColor }:any) => {
    return (
        <circle
            cy={cy}
            cx={cx}
            r={8} // circle size - to control marker size
            stroke={strokeColor}
            strokeWidth={3}
            fill='white'
        />

    );
};

const CustomLineLabel = ({ x, y, value, backgroundColor }:any) => {
    if (value) {
        return (
            <g fontSize={12}>
                <foreignObject x={x + 15} y={y - 40} width={50} height={40}>
                    <StyledLineChartLabel bgColor={backgroundColor}>{value}</StyledLineChartLabel>
                </foreignObject>
            </g>
        );
    }
    return null;
};

const RateByNumberOfPost: FunctionComponent<RateByNumberOfPostProps> = (props: RateByNumberOfPostProps) => {
    const {
        isLanu,
        rateByPostData,
        rateByPostModalInfoData,
        isLoading,
        isFailure,
        getDateFrom,
        getDateTo,
        informationPostsIndex,
        getCurrentGraphWeek,
        summaryDashboardDetailsData,

        setCurrentGraphWeek,
        setInformationPostModalIsOpen,
        setDateFrom,
        setDateTo,
        getRateByPost,
        getRateByPostModalInfo,
        getSummaryDashboardDetails,
    } = props;

    const apiDateFrom = rateByPostData.find((item) => item.week.substring(0, 8).replace(/\D+/g, '') === getCurrentGraphWeek?.substring(0, 8).replace(/\D+/g, ''))?.dateFrom;
    const apiDateTo = rateByPostData.find((item) => item.week.substring(0, 8).replace(/\D+/g, '') === getCurrentGraphWeek?.substring(0, 8).replace(/\D+/g, ''))?.dateTo;

    const [localDateFrom, setLocalDateFrom] = useState<Date | null>(getDateFrom);
    const [localDateTo, setLocalDateTo] = useState<Date | null>(getDateTo);

    const [callApiData, setCallApiData] = useState<boolean>(false);

    useEffect(() => {
        if (localDateFrom && localDateTo) {
            getRateByPost(`${(isLanu) ? IChannelTypeEnum.LANU : IChannelTypeEnum.LAU}`, localDateFrom.toISOString(), localDateTo.toISOString());
        }
    }, [isLanu, localDateTo]);

    useEffect(() => {
        if (callApiData && getCurrentGraphWeek && localDateFrom && localDateTo) {
            getRateByPostModalInfo(Number(getCurrentGraphWeek?.substring(0, 8).replace(/\D+/g, '')), `${(isLanu) ? IChannelTypeEnum.LANU : IChannelTypeEnum.LAU}`, localDateFrom.toISOString(), localDateTo.toISOString(), informationPostsIndex);
        }
    }, [getCurrentGraphWeek, isLanu, localDateTo, informationPostsIndex, callApiData]);

    useEffect(() => {
        const newDateFrom = dayjs(localDateFrom).format('YYYY-MM-DDT00:00:00');
        const newDateTo = dayjs(localDateTo).format('YYYY-MM-DDT00:00:00');
        if (localDateTo) {
            getSummaryDashboardDetails(`${(isLanu) ? IChannelTypeEnum.LANU : IChannelTypeEnum.LAU}`, newDateFrom, newDateTo, 'false');
        }
    }, [isLanu, localDateTo]);

    useEffect(() => {
        setDateFrom(localDateFrom);
        setDateTo(localDateTo);
    }, [localDateFrom, localDateTo]);

    const toggleInformationModal = (data: string | undefined) => {
        setCurrentGraphWeek(data);
        setInformationPostModalIsOpen(true);
    };

    const rateByPostGraph = () => {
        const { viewRateCurrentDate, engagementRateCurrentDate } = summaryDashboardDetailsData;
        const overallView = `Overall View Rate - ${(viewRateCurrentDate * 100).toFixed(2)}%`;
        const overallEng = `Overall Engagement Rate - ${(engagementRateCurrentDate * 100).toFixed(2)}%`;

        if (isLoading) {
            return (
                <div style={{ textAlign: 'center' }}>
                    <Spinner />
                </div>
            );
        }

        if (isFailure.length) {
            return (
                <div style={{ textAlign: 'center' }}>
                    <TextComponent style={{ color: 'red' }}>Something went wrong. Please try again.</TextComponent>
                </div>
            );
        }

        return (
            <ResponsiveContainer width='99%' height={(rateByPostData.length > 0) ? 550 : 'auto'}>
                {(rateByPostData.length > 0) ? (
                    <ComposedChart
                        width={1400}
                        height={550}
                        data={rateByPostData}
                        barCategoryGap={90}
                        barSize={80}
                        margin={{
                            top: 120,
                            right: 130,
                            left: 100,
                            bottom: 20,
                        }}
                        onClick={(data) => [setCallApiData(true), toggleInformationModal(data.activeLabel)]}
                    >
                        <CartesianGrid strokeDasharray='none' vertical={false} />
                        <XAxis axisLine={false} tickLine={false} fontSize='13px' dataKey='week' />
                        <YAxis axisLine={false} tickLine={false} fontSize='13px'>
                            <Label value={overallView} offset={30} position={{ x: 250, y: -90 }} />
                            <Label value={overallEng} offset={30} position={{ x: 295, y: -60 }} />
                        </YAxis>
                        <Tooltip />
                        <Legend
                            align='right'
                            verticalAlign='top'
                            width={600}
                            wrapperStyle={{ top: 30, right: 30, textAlign: 'end' }}
                            payload={
                                [
                                    { id: 'numOfPosts', value: 'Number of Posts', type: 'circle', color: '#FFD040' },
                                    { id: 'avgViewRate', value: 'Average View Rate', type: 'circle', color: '#00D1D2' },
                                    { id: 'avgEngRate', value: 'Average Engagement Rate', type: 'circle', color: '#DB3624' },
                                    { id: 'overallView', value: 'Overall View Rate', type: 'plainline', payload: { strokeDasharray: '6 5' }, color: '#00D1D2' },
                                    { id: 'overallEng', value: 'Overall Engagement Rate', type: 'plainline', payload: { strokeDasharray: '6 5' }, color: '#DB3624' },
                                ]
                            }
                        />
                        <Bar
                            name='Average View Rate'
                            dataKey='avgViewRate'
                            fill='#00D1D2'
                            label={<CustomLineLabel backgroundColor='#00D1D2' />}
                        />
                        <Bar
                            name='Average Engagement Rate'
                            dataKey='avgEngRate'
                            fill='#DB3624'
                            label={<CustomLineLabel backgroundColor='#DB3624' />}
                        />
                        <Line
                            name='Number of Posts'
                            dot={<CustomLineChartDot strokeColor='#FFD040' />}
                            type='monotone'
                            dataKey='numOfPosts'
                            stroke='#FFD040'
                            strokeWidth={2}
                        />
                        <ReferenceLine y={(viewRateCurrentDate * 100).toFixed(2)} stroke='#0480807d' strokeDasharray='14 14' strokeWidth={3} />
                        <ReferenceLine y={(engagementRateCurrentDate * 100).toFixed(2)} stroke='#882217a6' strokeDasharray='14 14' strokeWidth={3} />
                    </ComposedChart>
                ) : (
                    <div style={{ textAlign: 'center' }}>
                        <TextComponent>There appears to be no data yet.</TextComponent>
                    </div>
                )}
            </ResponsiveContainer>
        );
    };

    return (
        <TabBodyWrapper>
            <TabHeaderWrapper>
                <StyledText style={{ fontFamily: `${Fonts.secondary}`, fontSize: '22px' }}>
                    Average Rate Across Different Number Of Posts
                </StyledText>
                <MonthWrapper>
                    <DateDisplayText>
                        <IQOSDateRangePicker
                            dateFrom={localDateFrom}
                            dateTo={localDateTo}
                            setDateFrom={setLocalDateFrom}
                            setDateTo={setLocalDateTo}
                        />
                    </DateDisplayText>
                </MonthWrapper>
            </TabHeaderWrapper>
            <StyledGraphWrapper>
                {rateByPostGraph()}
            </StyledGraphWrapper>
            <InformationPost dataToDisplay={rateByPostModalInfoData} exportType={3} getWeekDateFrom={apiDateFrom} getWeekDateTo={apiDateTo} />
        </TabBodyWrapper>
    );
};

const TabBodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const MonthWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    min-width: 600px;
`;

const StyledText = styled.text`
    text-align: left;
    font-size: 18px;
`;

const TabHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 50px 20px 20px 40px;
`;

const DateDisplayText = styled.text`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    width: 100%;
    gap: 5px;
    padding-right: 15px;

    color: lightgrey;
    font-size: 18px;
    text-align: left;
`;

const StyledGraphWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 94%;
    min-height: 425px;
    margin-top: 1%;

    background-color: white;
`;

interface StyledLineChartLabelProps {
    bgColor: string;
}

const StyledLineChartLabel = styled.div<StyledLineChartLabelProps>`
    background-color: ${props => props.bgColor};
    padding:6px 8px;
    text-align:center;
    color: white;
    border-radius:8px;
`;

const mapStateToProps = (state: RootState) => ({
    isLanu: Selectors.getFaqGetIsLANU(state),
    rateByPostData: Selectors.getPostsGetRateByPost(state),
    rateByPostModalInfoData: Selectors.getPostsGetRateByPostModalInfo(state),
    isLoading: Selectors.getPostsGetRateByPostAttempting(state),
    isFailure: Selectors.getPostsGetRateByPostFailure(state),
    getDateFrom: Selectors.getPostsGetDateFrom(state),
    getDateTo: Selectors.getPostsGetDateTo(state),
    informationPostsIndex: Selectors.getPostsGetInformationPostIndex(state),
    getCurrentGraphWeek: Selectors.getPostsGetCurrentGraphWeek(state),
    summaryDashboardDetailsData: Selectors.getPostsGetSummaryDashboardDetails(state),

});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setCurrentGraphWeek: (value: string | undefined) => dispatch(Actions.setCurrentGraphWeek(value)),
    setInformationPostModalIsOpen: (data: boolean) => dispatch(Actions.setInformationPostModalIsOpen(data)),
    setDateFrom: (date: Date | null) => dispatch(Actions.setDateFrom(date)),
    setDateTo: (date: Date | null) => dispatch(Actions.setDateTo(date)),
    getRateByPost: (channelType: string, dateFrom: string, dateTo: string) => dispatch(Actions.getRateByPostAttempt({ channelType, dateFrom, dateTo })),
    getRateByPostModalInfo: (week: number, channelType: string, dateFrom: string, dateTo: string, index: number) => dispatch(Actions.getRateByPostModalInfoAttempt({ week, channelType, dateFrom, dateTo, index })),
    getSummaryDashboardDetails: (type: string, dateFrom: string, dateTo: string, isYtd: string) => dispatch(Actions.getSummaryDashboardDetailsForPostsAttempt({ type, dateFrom, dateTo, isYtd })),
});
export default connect(mapStateToProps, mapDispatchToProps)(RateByNumberOfPost);
