import { put, call, select, takeEvery } from 'typed-redux-saga/macro';
import { PayloadAction } from '@reduxjs/toolkit';

import { SagaWatcherReturnType } from 'sagas/types';

import Actions from 'redux/Actions';
import { GatewayResponseStatus } from 'api/types/types';

import Selectors from 'redux/Selectors';
import { toast } from 'react-toastify';
import { BroadcastHistoryFilter, IEditBroadcastPost, StatusTypeEnum } from 'entities/broadcast';
import BroadcastGateway from 'api/Broadcast';

export default function* watchEditBroadcastPost(api: BroadcastGateway): SagaWatcherReturnType {
    yield takeEvery('broadcast/editBroadcastPostAttempt', handleEditBroadcastPost, api);
}

function* handleEditBroadcastPost(api: BroadcastGateway, data: PayloadAction<IEditBroadcastPost>) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const getIsRecurring = yield* select(Selectors.getBroadcastGetIsRecurring);
    const recurringIndex = yield* select(Selectors.getBroadcastRecurringPaginatorIndex);
    const scheduledIndex = yield* select(Selectors.getBroadcastSchedulePaginatorIndex);
    const historyIndex = yield* select(Selectors.getBroadcastHistoryPaginatorIndex);

    const response = yield* call([api, api.editBroadcastPost], {
        data: data.payload,
        authToken,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.editBroadcastPostFailure('Failed to edit broadcast'));
        return;
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.editBroadcastPostSuccess(data.payload));
        yield put(Actions.getRecurringPostAttempt({ index: recurringIndex, status: StatusTypeEnum.All }));
        yield put(Actions.getSchedulePostAttempt({ index: scheduledIndex, status: StatusTypeEnum.All }));
        yield put(Actions.getHistoryPostAttempt({ index: historyIndex, status: BroadcastHistoryFilter.All }));

        if (getIsRecurring === true) {
            yield put(Actions.setEditRecurringBroadcastModalIsOpen(false));
        } else {
            yield put(Actions.setEditScheduleBroadcastModalIsOpen(false));
        }

        toast.success('Successfully edited!');
    }
}
